import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var External = function External(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'External__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1000,434.6V750c0,137.5-112.5,250-250,250H250C112.5,1000,0,887.5,0,750V250C0,112.5,112.5,0,250,0h415.6\tc-13,14-21.1,32.5-21.1,53.1V100H250c-82.7,0-150,67.3-150,150v500c0,82.7,67.3,150,150,150h500c82.7,0,150-67.3,150-150V534.6\tL1000,434.6z M1000,302.5c0-7.5-3.1-14.5-8.3-19.7L767.2,58.3C762,53.1,755,50,747.5,50c-15.3,0-28.1,12.7-28.1,28.1v112.2h-98.2\tc-143.8,0-322.7,25.4-383.6,176.7c-18.4,46.5-23.2,96.9-23.2,146c0,61.4,30.7,141.6,55.7,197.7c4.8,10.1,9.6,24.1,16.2,33.3\tc3.1,4.4,6.1,7.5,12.3,7.5c8.8,0,14-7,14-15.3c0-7-1.8-14.9-2.2-21.9c-1.3-18-2.2-35.9-2.2-53.9c0-209.1,124.1-245.5,313-245.5h98.2V527c0,15.3,12.7,28.1,28.1,28.1c7.5,0,14.5-3.1,19.7-8.3l224.4-224.4C996.9,317,1000,310,1000,302.5z' // eslint-disable-line max-len
    })
  );
};

External.propTypes = propTypes;

export default External;