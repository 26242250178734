import {
  GalleriesState,
  STATIC_GALLERY_ACTIONS,
  STATIC_GALLERY_CREATION,
  STATUS_TYPES,
} from './galleries.types';
import {
  GALLERIES_LOAD_TYPES,
  GALLERIES_SEARCH_TYPES,
} from '../../react/pages/galleries_list/galleries_list.types';
import {
  GALLERY_COPY_TYPES,
  GALLERY_DELETE_TYPES,
  GALLERY_GET_TYPES,
  GALLERY_UNDELETE_TYPES,
} from './galleries.actions';
import { AppActions } from '../../types';

export const initialStaticGalleryState = {
  activeStepIndex: 0,
  gallery: {},
};

export const initialState: Readonly<GalleriesState> = {
  data: [],
  staticGallery: initialStaticGalleryState,
  ids: [],
  searchResults: null,
  galleryToEdit: null,
  status: STATUS_TYPES.IDLE,
};

export const galleriesReducer = (
  state: Readonly<GalleriesState> = initialState,
  action: AppActions,
): GalleriesState => {
  switch (action.type) {
    case GALLERIES_SEARCH_TYPES.SEARCH: {
      const { data } = state;
      return {
        ...state,
        searchResults: data.filter((gallery) =>
          gallery.label
            .toLowerCase()
            .includes(action.payload.value.toLowerCase()),
        ),
      };
    }
    case GALLERIES_SEARCH_TYPES.CLEAR_SEARCH: {
      return {
        ...state,
        searchResults: action.payload,
      };
    }
    case GALLERIES_LOAD_TYPES.SUCCESS: {
      return {
        ...state,
        data: action.payload.galleries,
      };
    }
    case GALLERIES_LOAD_TYPES.FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case GALLERY_GET_TYPES.SUCCESS: {
      return {
        ...state,
        galleryToEdit: action.payload.gallery,
      };
    }
    case GALLERY_DELETE_TYPES.START: {
      return {
        ...state,
        status: STATUS_TYPES.DELETING,
      };
    }
    case GALLERY_DELETE_TYPES.SUCCESS: {
      const updatedGalleries = state.data.filter(
        (gallery) => gallery.id !== action.payload,
      );

      const updatedGalleryIds = state.ids.filter((id) => id !== action.payload);

      return {
        ...state,
        data: updatedGalleries,
        ids: updatedGalleryIds,
        status: STATUS_TYPES.IDLE,
      };
    }
    case GALLERY_DELETE_TYPES.FAILURE: {
      return {
        ...state,
        status: STATUS_TYPES.IDLE,
      };
    }
    case GALLERY_COPY_TYPES.START: {
      return {
        ...state,
        status: STATUS_TYPES.COPYING,
      };
    }
    case GALLERY_COPY_TYPES.SUCCESS: {
      return {
        ...state,
        status: STATUS_TYPES.IDLE,
      };
    }
    case GALLERY_COPY_TYPES.FAILURE: {
      return {
        ...state,
        status: STATUS_TYPES.IDLE,
      };
    }
    case GALLERY_UNDELETE_TYPES.START: {
      return {
        ...state,
        status: STATUS_TYPES.UNDELETING,
      };
    }
    case GALLERY_UNDELETE_TYPES.SUCCESS: {
      return {
        ...state,
        status: STATUS_TYPES.IDLE,
      };
    }
    case GALLERY_UNDELETE_TYPES.FAILURE: {
      return {
        ...state,
        status: STATUS_TYPES.IDLE,
      };
    }
    case STATIC_GALLERY_CREATION.SET_STATIC_GALLERY_CREATION_VALUE: {
      const staticGalleryCreation = { ...state.staticGallery };
      staticGalleryCreation.gallery = {
        ...staticGalleryCreation.gallery,
        ...action.payload,
      };
      return {
        ...state,
        staticGallery: {
          ...state.staticGallery,
          gallery: {
            ...state.staticGallery.gallery,
            ...action.payload,
          },
        },
      };
    }
    case STATIC_GALLERY_ACTIONS.ADD_STORY_CREATE: {
      const storyToAdd = action.payload;
      const storyIds = state.staticGallery.gallery.story_ids ?? [];
      const updatedStoryIds = [...storyIds, storyToAdd.uuid];
      const storyList = state.staticGallery.selectedStories ?? [];
      const updatedStoryList = [...storyList, storyToAdd];

      return {
        ...state,
        staticGallery: {
          ...state.staticGallery,
          selectedStories: updatedStoryList,
          gallery: {
            ...state.staticGallery.gallery,
            story_ids: updatedStoryIds,
          },
        },
      };
    }
    case STATIC_GALLERY_ACTIONS.REMOVE_STORY_CREATE: {
      const storyToRemove = action.payload;
      const storyIds = state.staticGallery.gallery.story_ids ?? [];
      const updatedStoryIds = storyIds.filter((uuid) => uuid !== storyToRemove);
      const storyList = state.staticGallery.selectedStories ?? [];
      const updatedStories = storyList.filter(
        (story) => story.uuid !== storyToRemove,
      );

      return {
        ...state,
        staticGallery: {
          ...state.staticGallery,
          selectedStories: updatedStories,
          gallery: {
            ...state.staticGallery.gallery,
            story_ids: updatedStoryIds,
          },
        },
      };
    }
    case STATIC_GALLERY_ACTIONS.ADD_STORY_EDIT: {
      const storyToAdd = action.payload;
      const storyIds =
        state.staticGallery.gallery.story_ids ??
        state.galleryToEdit?.story_ids ??
        [];
      const updatedStoryIds = [...storyIds, storyToAdd.uuid];
      const storyList =
        state.staticGallery.selectedStories ??
        state.galleryToEdit?.stories ??
        [];
      const updatedStoryList = [...storyList, storyToAdd];

      return {
        ...state,
        staticGallery: {
          ...state.staticGallery,
          selectedStories: updatedStoryList,
          gallery: {
            ...state.staticGallery.gallery,
            story_ids: updatedStoryIds,
          },
        },
      };
    }
    case STATIC_GALLERY_ACTIONS.REMOVE_STORY_EDIT: {
      const storyToRemove = action.payload;
      const storyIds =
        state.staticGallery.gallery.story_ids ??
        state.galleryToEdit?.story_ids ??
        [];
      const storyList =
        state.staticGallery.selectedStories ??
        state.galleryToEdit?.stories ??
        [];

      const updatedStoryIds = storyIds.filter((uuid) => uuid !== storyToRemove);
      const updatedStories = storyList.filter(
        (story) => story.uuid !== storyToRemove,
      );

      return {
        ...state,
        staticGallery: {
          ...state.staticGallery,
          selectedStories: updatedStories,
          gallery: {
            ...state.staticGallery.gallery,
            story_ids: updatedStoryIds,
          },
        },
      };
    }
    case STATIC_GALLERY_CREATION.SET_STATIC_GALLERY_CREATION_STEP: {
      return {
        ...state,
        staticGallery: {
          ...state.staticGallery,
          activeStepIndex: action.payload,
        },
      };
    }
    case STATIC_GALLERY_ACTIONS.RESET_GALLERY_STATE:
      return {
        ...state,
        staticGallery: initialStaticGalleryState,
      };

    case STATIC_GALLERY_ACTIONS.UPDATE_SELECTED_STATIC_GALLERY: {
      const sortedList = action.payload;
      const sortedIds = sortedList.map((story) => story.uuid);
      return {
        ...state,
        staticGallery: {
          ...state.staticGallery,
          selectedStories: sortedList,
          gallery: {
            ...state.staticGallery.gallery,
            story_ids: sortedIds,
          },
        },
      };
    }
    case STATIC_GALLERY_ACTIONS.GALLERY_UPDATE_LABEL: {
      const updatedGallery = {
        ...state.staticGallery.gallery,
        label: action.payload,
      };
      return {
        ...state,
        staticGallery: {
          ...state.staticGallery,
          gallery: updatedGallery,
        },
      };
    }
    case STATIC_GALLERY_ACTIONS.EDIT_STATIC_GALLERY_SUCCESS: {
      return {
        ...state,
        galleryToEdit: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
