import axios from 'axios';

const instance = axios.create({});

// Default settings that will be sent with every request using this instance of axios
instance.defaults.withCredentials = true;
instance.defaults.validateStatus = (status: number) => {
  return status >= 200 && status < 300;
};
// Every request response using this instance of axios is passed through this function
instance.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err) => {
    if (err.response.status === 401) {
      window.location.href = `${process.env.ID_URL}/?${encodeURIComponent(
        `redirect`,
      )}=${encodeURIComponent(`${process.env.APP_URL}`)}`;
    }
    return Promise.reject(err.response);
  },
);
export default instance;
