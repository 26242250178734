import _Number$isInteger from 'babel-runtime/core-js/number/is-integer';
import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Icon';

import { iconMap, legacyMap } from '../Icon/icon-map';

var propTypes = {
  iconType: PropTypes.string.isRequired,
  outOf: PropTypes.number.isRequired,
  outOfColor: PropTypes.string,
  rating: PropTypes.number,
  ratingColor: PropTypes.string,
  classNames: PropTypes.string,
  size: PropTypes.number,
  spacing: PropTypes.number,
  outOfOutline: PropTypes.bool
};

var propDescriptions = {
  iconType: 'The icon to be used to show the rating.',
  // eslint-disable-next-line max-len
  outOf: "The number of icons to display - what is this rating 'out of'? ex: rating/outOf",
  outOfColor: 'sets the color of the outOf',
  outOfOutline: 'boolean to define wether unfilled icons are filled or show the outline',
  rating: 'what is the rating of the current component? ex: rating/outOf',
  ratingColor: 'sets the color of the rating',
  classNames: 'additional class names for the component',
  size: 'size of the icons',
  spacing: 'the amount of space between the icons'
};

var defaultProps = {
  classNames: '',
  size: 14,
  rating: 3.5,
  spacing: 2,
  ratingColor: 'black',
  outOfColor: 'grey',
  outOfOutline: false
};

var Rating = function (_React$Component) {
  _inherits(Rating, _React$Component);

  function Rating(props) {
    _classCallCheck(this, Rating);

    var _this = _possibleConstructorReturn(this, (Rating.__proto__ || _Object$getPrototypeOf(Rating)).call(this, props));

    _this.getIconType = function (isRatingIcon) {
      // This function is to test if the passed icon type has an outline version
      // this way, if there is no outline, it just uses the icon as is, otherwise,
      // it will return the outline version
      var _this$props = _this.props,
          iconType = _this$props.iconType,
          outOfOutline = _this$props.outOfOutline;


      var iconWithOutline = iconType + '-outline';
      // If the current icon needs to be filled in, just return it as is
      if (isRatingIcon && outOfOutline || !outOfOutline) {
        return iconType;
      }

      // test if the passed icon exists with a -outline at the end
      var hasOutline = legacyMap[iconWithOutline] ? iconMap[legacyMap[iconWithOutline]] : iconMap[iconWithOutline];

      // If the passed icon has a version of the icon with the outline, use that icon, otherwise, just use the passed icon
      return hasOutline ? iconWithOutline : iconType;
    };

    _this.renderRatings = function () {
      var _this$props2 = _this.props,
          outOf = _this$props2.outOf,
          outOfColor = _this$props2.outOfColor,
          rating = _this$props2.rating,
          ratingColor = _this$props2.ratingColor,
          size = _this$props2.size,
          spacing = _this$props2.spacing;

      var ratings = [];
      // index of the last rated icon. If there is a decimal in the supplied rating,
      // this is the index that will have the half icon
      var maxRatingIdx = Math.ceil(rating) - 1;
      var maxIdx = outOf - 1;
      for (var x = 0; x < outOf; x += 1) {
        // should the current icon be half filled only
        var isHalfRating = x === maxRatingIdx && !_Number$isInteger(rating);
        var isRatingIcon = x <= maxRatingIdx;
        // if the rating is a whole number, just return a simple div with the icon
        // otherwise, prepare to enter math logic city
        if (!isHalfRating) {
          ratings.push(React.createElement(
            'div',
            {
              className: 'ratingWrapper',
              key: x,
              style: { marginRight: x !== maxIdx ? spacing : null }
            },
            React.createElement(Icon, {
              className: isRatingIcon ? 'icon__withSpacing' : '',
              fill: isRatingIcon ? ratingColor : outOfColor,
              type: _this.getIconType(isRatingIcon),
              size: size
            })
          )); // eslint-disable-line react/jsx-closing-tag-location
        } else {
          // should the icon be filled
          var wrapperStyles = {
            marginRight: x !== maxIdx ? spacing : null,
            width: size,
            paddingRight: x !== maxIdx ? spacing : 2
          };
          var halfDivProps = {
            className: 'half',
            style: { width: size / 2 }
          };

          ratings.push(React.createElement(
            'div',
            {
              className: 'ratingWrapper',
              key: x,
              style: wrapperStyles
            },
            React.createElement(
              'div',
              halfDivProps,
              React.createElement(Icon, {
                className: '' + (isRatingIcon ? 'icon__withSpacing' : ''),
                fill: isRatingIcon ? ratingColor : outOfColor,
                type: _this.getIconType(isRatingIcon),
                size: size,
                style: { width: size }
              })
            ),
            isHalfRating && React.createElement(
              'div',
              { className: 'half-bg' },
              React.createElement(Icon, {
                fill: outOfColor,
                type: _this.getIconType(false),
                size: size,
                style: { width: size }
              })
            )
          )); // eslint-disable-line react/jsx-closing-tag-location
        }
      }
      return ratings;
    };

    return _this;
  }

  _createClass(Rating, [{
    key: 'render',
    value: function render() {
      var classNames = this.props.classNames;

      return React.createElement(
        'div',
        null,
        React.createElement(
          'div',
          { className: 'ratingComponent ' + classNames },
          ' ',
          this.renderRatings(),
          ' '
        )
      );
    }
  }]);

  return Rating;
}(React.Component);

export default Rating;


Rating.propTypes = propTypes;
Rating.defaultProps = defaultProps;
Rating.propDescriptions = propDescriptions;