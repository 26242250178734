import _extends from 'babel-runtime/helpers/extends';
import _typeof from 'babel-runtime/helpers/typeof';
import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import FilterDropdownStyles from './FilterDropDownStyles';
import Icon from '../Icon/Icon';

var propTypes = {
  className: PropTypes.string,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isBlue: PropTypes.bool,
  isInline: PropTypes.bool,
  isRequired: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  noBorder: PropTypes.bool,
  noResultsText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onChange: PropTypes.func.isRequired,
  optionComponent: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  callbackFn: PropTypes.func,
  placeholder: PropTypes.string,
  requestFn: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  valueKey: PropTypes.string,
  debounceVal: PropTypes.number,
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.string,
  color: PropTypes.string,
  isGroupedFilterDropdown: PropTypes.bool,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  hideSelectedValue: PropTypes.bool,
  classNamePrefix: PropTypes.string
};

var propDescriptions = {
  className: 'Optional classname for the dropdown',
  isClearable: 'A boolean to determine if dropdown is clearable',
  isDisabled: 'Will render a read-only version of the dropdown',
  isBlue: 'To be used for app-related dropdowns. Will render a blue outline.',
  isInline: 'Will render the label inlimne with the dropdown',
  isRequired: 'Determines whether or not the dropdown is required',
  isSearchable: 'A boolean to determine if dropdown options are searchable',
  label: 'Label for the dropdown',
  labelKey: 'The value the dropdown will use to display the label.s',
  name: 'Form field name for the dropdown',
  noBorder: 'To be used to remove the border of the dropdown',
  noResultsText: 'Text that is displayed if query does not match any available options.',
  onChange: 'Callback called when the value changes, with the value as first arg',
  optionComponent: 'A react node that will replace the default option component',
  options: 'Array of options available in the dropdown. NOTE: Each option should have a label/value (required) and a disabled attribute (not required), which is used to make the option not clickable',
  placeholder: 'Dropdown text to appear before selection is made',
  requestFn: 'Returns a promise, which is the set of options to be used once the promise resolves.',
  value: 'Selected value of the dropdown.',
  valueKey: 'The value the dropdown will use to set and search the values of the dropdown.',
  callbackFn: 'Function that is called on success of Async search. Must return an array of objects',
  debounceVal: 'Determines how long to debounce for Async selector',
  maxMenuHeight: 'A number that determines the height of dropdown menu',
  menuPlacement: 'Determines whether the menu will dropdown or pop-up above the input',
  color: 'To be used if we want to override the default blue color of the dropdown',
  selectedOptions: 'An array of options selected that is only used when isGroupedFilterDropdown is enabled. NOTE: This will only affect the UI/display of the option within the menu and not sync\'d with value of the dropdown. HOC is responsible for managing those values appropriately.',
  isGroupedFilterDropdown: 'A boolean used to show the grouped data dropdown version',
  hideSelectedValue: 'A boolean that determines if the selected option will be shown in the placeholder'
};

var defaultProps = {
  className: '',
  name: '',
  placeholder: 'Select an option',
  value: '',
  // noResultsText should be specified in searchable dropdowns
  isClearable: false,
  isDisabled: false,
  isBlue: false,
  isRequired: false,
  isSearchable: false,
  color: '#007BA8',
  labelKey: 'label',
  noBorder: false,
  noResultsText: '',
  valueKey: 'value',
  debounceVal: 200,
  maxMenuHeight: 300,
  menuPlacement: 'bottom',
  selectedOptions: [],
  isGroupedFilterDropdown: false,
  hideSelectedValue: false
};

var FilterDropdown = function (_React$Component) {
  _inherits(FilterDropdown, _React$Component);

  function FilterDropdown(props) {
    _classCallCheck(this, FilterDropdown);

    var _this = _possibleConstructorReturn(this, (FilterDropdown.__proto__ || _Object$getPrototypeOf(FilterDropdown)).call(this, props));

    _this.handleLoadOptions = function (inputValue, callback) {
      var _this$props = _this.props,
          callbackFn = _this$props.callbackFn,
          requestFn = _this$props.requestFn,
          labelKey = _this$props.labelKey,
          valueKey = _this$props.valueKey;

      if (!inputValue) {
        callback([]);
      }

      requestFn(inputValue).then(function (optionsResponse) {
        if (callbackFn) {
          callback(callbackFn(optionsResponse, { labelKey: labelKey, valueKey: valueKey }));
        }
        if (optionsResponse) callback(optionsResponse);
        callback([]);
      }).catch(function () {
        return callback([]);
      });
    };

    _this.state = {
      menuIsOpen: false
    };
    return _this;
  }
  // This function is what is used to make calls to an external API
  // It sets the loading state to true, and then returns the options recieved
  //  from the function. If there is a callback function prop provided,
  //  it will also fire, with the response and what label and value keys are expected
  //  this can be used to do things like fire redux actions.


  _createClass(FilterDropdown, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          className = _props.className,
          isClearable = _props.isClearable,
          isDisabled = _props.isDisabled,
          isBlue = _props.isBlue,
          isInline = _props.isInline,
          isRequired = _props.isRequired,
          isSearchable = _props.isSearchable,
          label = _props.label,
          labelKey = _props.labelKey,
          name = _props.name,
          noBorder = _props.noBorder,
          noResultsText = _props.noResultsText,
          _onChange = _props.onChange,
          optionComponent = _props.optionComponent,
          options = _props.options,
          placeholder = _props.placeholder,
          requestFn = _props.requestFn,
          value = _props.value,
          valueKey = _props.valueKey,
          debounceVal = _props.debounceVal,
          maxMenuHeight = _props.maxMenuHeight,
          menuPlacement = _props.menuPlacement,
          color = _props.color,
          isGroupedFilterDropdown = _props.isGroupedFilterDropdown,
          selectedOptions = _props.selectedOptions,
          hideSelectedValue = _props.hideSelectedValue;
      // This is a polyfill. In Version 1 of the react-select plugin, the value prop was always
      //  a string. In v3, value is expected to have a shape of {value: '', label:''} unless labelKey and
      //  valuekey are specified. This is just to normalize any string/num/bool values

      var selectedValue = function selectedValue() {
        if (value === null) return '';
        if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) !== 'object') {
          var valOption = options.filter(function (option) {
            return option[valueKey] === value;
          });
          if (valOption.length) {
            return valOption[0];
          }
        }
        return value;
      };

      var Option = function Option(props) {
        var isSelected = selectedOptions && selectedOptions.includes(props.data.value);
        return React.createElement(
          'span',
          { className: classNames('filterDropdown__groupedVersion__optionContainer', {
              'filterDropdown__groupedVersion__optionContainer--selected': isSelected,
              'filterDropdown__groupedVersion__optionContainer--isDisabled': props.data.disabled // this is used to make the option not clickable
            })
          },
          React.createElement(components.Option, props),
          isSelected && React.createElement(Icon, { type: 'check', size: 10, fill: '#666', className: 'checkIcon' })
        );
      };

      var handleChange = function handleChange(option, keys) {
        var isSelected = selectedOptions && selectedOptions.includes(option.value);
        if (isSelected) {
          _this2.setState({
            menuIsOpen: true
          });
        } else {
          _onChange(option, keys);
          _this2.setState({
            menuIsOpen: false
          });
        }
      };

      var onMenuOpen = function onMenuOpen() {
        return _this2.setState({ menuIsOpen: true });
      };
      var onMenuClose = function onMenuClose() {
        return _this2.setState({ menuIsOpen: false });
      };

      var commonProps = _extends({}, this.props, {
        formatOptionLabel: optionComponent || null,
        isClearable: isClearable,
        isDisabled: isDisabled,
        isSearchable: isSearchable,
        color: color,
        name: name,
        noOptionsMessage: typeof noResultsText === 'string' ? function () {
          return noResultsText;
        } : function (inputVal) {
          return noResultsText(inputVal);
        },
        onChange: function onChange(option) {
          return isGroupedFilterDropdown ? handleChange(option || null, { labelKey: labelKey, valueKey: valueKey }) : _onChange(option || null, { labelKey: labelKey, valueKey: valueKey });
        },
        placeholder: placeholder,
        styles: FilterDropdownStyles(this.props),
        value: hideSelectedValue ? '' : selectedValue(),
        maxMenuHeight: maxMenuHeight,
        menuPlacement: menuPlacement
      });

      return React.createElement(
        'div',
        {
          className: classNames('filterDropdown', className, {
            blue: isBlue,
            inline: isInline,
            noBorder: noBorder
          })
        },
        label && React.createElement(
          'label',
          { className: 'filterDropdown__label', htmlFor: name },
          React.createElement(
            'span',
            { className: 'copy' },
            label
          ),
          isRequired ? React.createElement(
            'span',
            { className: 'required' },
            ' *'
          ) : null
        ),
        requestFn ? React.createElement(AsyncSelect, _extends({}, commonProps, {
          getOptionLabel: function getOptionLabel(data) {
            return data[labelKey];
          },
          getOptionValue: function getOptionValue(data) {
            return data[valueKey];
          },
          loadOptions: debounce(this.handleLoadOptions, debounceVal),
          blurInputOnSelect: true
        })) : React.createElement(Select, _extends({}, commonProps, {
          options: options,
          components: isGroupedFilterDropdown && { Option: Option },
          classNamePrefix: isGroupedFilterDropdown ? 'filterDropdown__groupedVersion' : this.props.classNamePrefix,
          onMenuOpen: onMenuOpen,
          onMenuClose: onMenuClose,
          menuIsOpen: this.state.menuIsOpen
        }))
      );
    }
  }]);

  return FilterDropdown;
}(React.Component);

export default FilterDropdown;


FilterDropdown.propTypes = propTypes;
FilterDropdown.defaultProps = defaultProps;
FilterDropdown.propDescriptions = propDescriptions;