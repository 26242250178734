import React, { useEffect, VoidFunctionComponent } from 'react';
import { useLocation } from 'react-router';
import { STORIES_LOAD_TYPES } from './stories_list.types';
import { Page } from '../../blocks/page/page.component';
import { StoryListModerationAndStatus } from '../../features/story_list/moderation_and_status';
import {
  getStoriesSearch,
  STORY_SEARCH_LOAD_TYPES,
} from '../../../http/stories_search.thunks';
import { LoadStateData } from '../../blocks/load_data/load_state_data.component';
import { LoadingGif } from '../../blocks/LoadingGif/LoadingGif.component';
import { PageTitle } from '../../blocks/pageTitle/pageTitle.component';
import { PageHeader } from '../../blocks/page_header/page_header.component';
import { Story } from '../../features/story/story.types';
import { getLocationState } from '../../../utils';
import './stories_list.scss';
import { storiesSearchStateToFilterParams } from '../../features/stories_search_form.component/stories_search_form.utils';
import { StoriesFilterTab } from '../../../redux/stories/stories.types';

export interface StoriesListPageProps {
  stories: Story[];
  defaultStoriesTab: StoriesFilterTab;
  updateStoriesFilterTab: (tab: StoriesFilterTab) => void;
}

export const StoriesListPageComponent: VoidFunctionComponent<StoriesListPageProps> = ({
  stories,
  defaultStoriesTab,
  updateStoriesFilterTab,
}) => {
  const location = useLocation();
  // When we delete a story we fetch story list data from ES before it is updated,
  // so we get the deleted story back in the list. This allows us to filter it out
  // from the list while we're waiting for ES to update.
  const deletedUUID = getLocationState(location.state, 'deletedUUID');

  useEffect(() => {
    // When stories list mounts, update the state to default
    // value of the StoriesFilterTab based on feature flag
    updateStoriesFilterTab(defaultStoriesTab);
  }, []);

  return (
    <Page className="storiesListPage">
      <LoadStateData
        actionTypes={STORIES_LOAD_TYPES}
        loading={<LoadingGif size="large" />}
        failure={<div>error</div>}
        thunk={getStoriesSearch({
          searchFilters: storiesSearchStateToFilterParams(
            undefined,
            undefined,
            defaultStoriesTab,
          ),
          pagination: { pageSize: 20 },
          loadingType: STORY_SEARCH_LOAD_TYPES.INIT,
          excludeUUID: typeof deletedUUID === 'string' ? deletedUUID : '',
        })}
      >
        <PageHeader>
          <PageTitle />
        </PageHeader>
        <StoryListModerationAndStatus />
      </LoadStateData>
    </Page>
  );
};
