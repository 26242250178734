import React, { useEffect } from 'react';
import classNames from 'classnames';
import { TranslateFunction } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import '../story_list.scss';
import { Search } from '../../../blocks/search/search.component';
import { Story } from '../../story/story.types';
import {
  getStoriesSearch,
  STORY_SEARCH_LOAD_TYPES,
} from '../../../../http/stories_search.thunks';
import { StoryListEmptyState } from '../story_list_empty_state/story_list_empty_state.component';
import { storiesSearchStateToFilterParams } from '../../stories_search_form.component/stories_search_form.utils';
import {
  clearStoriesSearchQuery,
  storiesClearSearchFiltersAction,
  storiesSearchQuery,
} from '../../../../redux/stories/stories.actions';
import { StoryList } from '../story_list.connect';
import {
  LSE_OWNER_MAX_SELECTABLE_STORIES,
  MAX_NUMBER_SELECTED_STORIES,
} from './story_list_selectable.constants';
import { StoryCardProps } from '../story_list.types';
import { OverloadedDetailedStorySelectable } from './overloaded_selectable_story_card';
import { PUBLISHED_STORIES_FILTER } from '../story_list.constants';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';

export interface StoryListSelectableProps {
  translate: TranslateFunction;
  stories: Story[];
  loadingType: STORY_SEARCH_LOAD_TYPES | null;
  selectedStoriesSource: Story[];
  addStory: (story: Story) => void;
  removeStory: (uuid: string) => void;
  handleScrollToTop: () => void;
  isLocalhoodOwner: boolean;
  scrollElement?: Element | typeof window;
}

export const StoryListSelectableComponent = ({
  translate,
  stories,
  loadingType,
  selectedStoriesSource,
  addStory,
  removeStory,
  handleScrollToTop,
  isLocalhoodOwner,
  scrollElement,
}: StoryListSelectableProps) => {
  const getTranslation = useTranslateStrings(translate);
  const maxSelectableStories = isLocalhoodOwner
    ? LSE_OWNER_MAX_SELECTABLE_STORIES
    : MAX_NUMBER_SELECTED_STORIES;

  const maxNumberStoriesSelected =
    selectedStoriesSource.length === maxSelectableStories;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storiesClearSearchFiltersAction());
  }, []);

  const handleClearSearch = () => {
    const searchFilters = storiesSearchStateToFilterParams();
    dispatch(clearStoriesSearchQuery());
    dispatch(
      getStoriesSearch({
        searchFilters,
        pagination: { pageSize: 20 },
        loadingType: STORY_SEARCH_LOAD_TYPES.SEARCH,
      })
    );
  };
  const handleSearch = (val: string) => {
    dispatch(storiesSearchQuery(val));
    dispatch(
      getStoriesSearch({
        searchFilters: storiesSearchStateToFilterParams(val),
        pagination: { pageSize: 20 },
        loadingType: STORY_SEARCH_LOAD_TYPES.SEARCH,
      })
    );
  };

  const showEmptyState =
    loadingType === STORY_SEARCH_LOAD_TYPES.INIT && stories.length === 0;

  return (
    <div
      className={classNames('story_list', {
        empty_state: showEmptyState,
      })}
    >
      {showEmptyState ? (
        <StoryListEmptyState
          className='story_list--selectable_empty_state'
          title={getTranslation('galleries.emptyState.galleryNoStoriesTitle')}
          description={getTranslation('galleries.emptyState.galleryNoStoriesDesc')}
        />
      ) : (
        <React.Fragment>
          <div className='story_list--actions'>
            <div className='story_list--search_actions'>
              <Search
                id='story_search'
                ariaLabel={`${translate('stories.search.ariaLabel')}`}
                placeholder={`${translate('stories.search.placeholder')}`}
                onClearSearch={handleClearSearch}
                onSearch={handleSearch}
              />
            </div>
          </div>
          <StoryList
            stories={stories}
            StoryCard={(props: StoryCardProps) => (
              <OverloadedDetailedStorySelectable
                {...props}
                maxNumberStoriesSelected={maxNumberStoriesSelected}
                addStory={addStory}
                removeStory={removeStory}
                selectedStories={selectedStoriesSource}
              />
            )}
            handleScrollToTop={handleScrollToTop}
            filterData={PUBLISHED_STORIES_FILTER}
            scrollElement={scrollElement}
          />
        </React.Fragment>
      )}
    </div>
  );
};
