import React, { FunctionComponent } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import {
  Modal,
  MODAL_TYPES,
  ModalButtons,
  ModalContent,
  ModalTitle,
} from '../../../blocks/modal';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
} from '../../../blocks/button';

export interface DirtyStateModalProps extends LocalizeContextProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: () => void;
}

export const DirtyStateModalComponent: FunctionComponent<DirtyStateModalProps> = ({
  translate,
  open,
  handleClose,
  handleAccept,
}) => {
  const getTranslation = useTranslateStrings(translate);

  return (
    <Modal open={open} className={MODAL_TYPES.BASIC}>
      <ModalTitle
        label={getTranslation('stories.modal.galleryDirtyModal.title')}
      />
      <ModalContent>
        <div className="static_gallery__dirty-modal-body">
          <p className="static_gallery__dirty-modal-desc">
            {getTranslation('stories.modal.galleryDirtyModal.description')}
          </p>
        </div>
      </ModalContent>
      <ModalButtons>
        <Button
          variant={BUTTON_VARIANT.OUTLINE}
          onClick={handleClose}
          color={BUTTON_COLOR_VARIANT.SECONDARY}
        >
          {getTranslation('stories.modal.galleryDirtyModal.reject')}
        </Button>
        <Button onClick={handleAccept}>
          {getTranslation('stories.modal.galleryDirtyModal.accept')}
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export const DirtyStateModal = withLocalize(DirtyStateModalComponent);
