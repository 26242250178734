import React, { VoidFunctionComponent } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import EmptySearchGraphic from '../../../assets/signpost-icon.jpg';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';
import { EmptyState } from '../../blocks/empty_state';

const SearchEmptyStateComponent: VoidFunctionComponent<LocalizeContextProps> = ({
  translate,
}) => {
  const getTranslation = useTranslateStrings(translate);
  return (
    <EmptyState
      title={getTranslation('general.search.emptyState.title')}
      description={getTranslation('general.search.emptyState.description')}
      graphic={EmptySearchGraphic}
      graphicAlt={getTranslation('general.search.emptyState.alt')}
    />
  );
};

export const SearchEmptyState = withLocalize(SearchEmptyStateComponent);
