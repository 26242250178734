import { Location } from '../../../redux/locations/locations.types';
import { GlobalTag } from '../../../redux/global_tags/global_tags.types';
import { NetworkTag } from '../../../redux/network_tags/network_tags.types';
import { GALLERY_TYPE } from '../../../redux/galleries/galleries.types';

/**
 * Returns the correct display value based on gallery type and tags
 * @param galleryType types of galleries
 * @param tags gallery tags
 * @param defaultText default text if no tags are present
 *
 */
export const displayGalleryTags = (
  galleryType: GALLERY_TYPE | undefined,
  tags: (GlobalTag | NetworkTag | Location)[],
  defaultText: string,
) => {
  switch (galleryType) {
    case GALLERY_TYPE.STATIC:
      break;
    case GALLERY_TYPE.DYNAMIC:
      if (tags.length) {
        return tags.map((tag) => tag.label).join(', ');
      }
      return defaultText;
  }
};

/**
 * Copies provided text to the device clipboard.
 * @param text The text to copy.
 * @param onSuccess A callback that runs if copying is successful.
 * @param onFailure A callback that runs if copying fails.
 */
export const copyToClipboard = (
  text: string | null,
  onSuccess?: () => void,
  onFailure?: () => void,
) => {
  const textArea: HTMLTextAreaElement = document.createElement('textarea');

  // Set non-editable to avoid focus and move outside of view
  textArea.setAttribute('readonly', '');
  textArea.setAttribute('style', 'position: absolute; left: -9999px');
  document.body.appendChild(textArea);
  if (text) {
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
  }

  try {
    document.execCommand('copy');
    onSuccess?.();
  } catch {
    onFailure?.();
  }
  document.body.removeChild(textArea);
};
