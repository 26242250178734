import React from 'react';
import { PageTitle } from '../../blocks/pageTitle/pageTitle.component';
import { Page } from '../../blocks/page/page.component';
import { PageHeader } from '../../blocks/page_header/page_header.component';
import { AdminForm } from '../../features/admin_form/admin_form.component';

import './admin.scss';

export const AdminPage = () => {
  return (
    <Page className="admin_page">
      <PageHeader>
        <PageTitle />
      </PageHeader>
      <AdminForm />
    </Page>
  );
};

AdminPage.displayName = 'adminPage';
