import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import React from 'react';
import { store } from './redux/store';

interface AppProvidersProps {
  children: React.ReactChild;
}

export const AppProviders = ({ children }: AppProvidersProps) => {
  return (
    <Provider store={store}>
      <LocalizeProvider store={store}>{children}</LocalizeProvider>
    </Provider>
  );
};
