import { TEXT_VARIANTS } from '../text';

export interface RadioOption {
  label: string;
  value?: string;
  isDisabled?: boolean;
}

export interface MappedRadioOption {
  id: string;
  label: string;
  value: string;
  isDisabled?: boolean;
}

export interface RadioGroupProps {
  name: string;
  value?: string;
  label: string;
  options: RadioOption[];
  labelAs?: TEXT_VARIANTS;
  onChange?: (value: string) => void;
}

export enum KeyCodes {
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  SPACEBAR = ' ',
}
