import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  color: PropTypes.string
};

var LineSolid = function LineSolid(_ref) {
  var color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'area-chart-legend-solid',
      xmlns: 'http://www.w3.org/2000/svg',
      width: '24',
      height: '16',
      viewBox: '0 0 24 16',
      fill: 'currentColor',
      'aria-hidden': 'true'
    },
    React.createElement('line', { y1: '1', x2: '24', y2: '1', stroke: color, strokeWidth: '2' })
  );
};

LineSolid.propTypes = propTypes;

export default LineSolid;