import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { AccountEditForm } from '../../features/account_settings/acount_edit_form';
import { PageTitle } from '../../blocks/pageTitle/pageTitle.component';
import { Page } from '../../blocks/page/page.component';
import { PageHeader } from '../../blocks/page_header/page_header.component';
import {
  ACCOUNT_FIELD_MASK,
  PublisherUpdateDetails,
} from './account_settings.types';
import { GET_PUBLISHER_TYPES, Publisher } from '../../../redux/user/user.types';
import './account_settings.scss';
import { LoadStateData } from '../../blocks/load_data/load_state_data.component';
import { LoadingGif } from '../../blocks/LoadingGif/LoadingGif.component';
import { getPublisher } from '../../../http/publisher_get.thunk';
import { BUTTON_COLOR_VARIANT, Button } from '../../blocks/button';
import { ErrorPage } from '../../blocks/error_page';
import LinkBroken from '../../../assets/link_broken.svg';
import { getErrorMessage } from '../../../utils';
import { AccountUpdateErrorMessages } from '../../../http/account_update.thunk';
import { useToast } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../blocks/toast';

export interface AccountSettingsComponentProps extends LocalizeContextProps {
  publisher: Publisher;
  updateAccountSettings: (
    data: PublisherUpdateDetails,
    fieldMask: ACCOUNT_FIELD_MASK[],
  ) => Promise<void>;
}

const AccountSettingsComponent = ({
  translate,
  updateAccountSettings,
  publisher,
}: AccountSettingsComponentProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const navigate = useNavigate();
  const { showToast } = useToast();

  const handleRedirect = () => {
    navigate(-1);
  };

  const handleFormSubmit = async (data: PublisherUpdateDetails) => {
    setIsSaving(true);

    const fieldMask: ACCOUNT_FIELD_MASK[] = [];
    Object.values(ACCOUNT_FIELD_MASK).forEach((field) => {
      if (data[field]) {
        fieldMask.push(field);
      }
    });

    try {
      await updateAccountSettings(data, fieldMask);
      showToast({
        message: `${translate('settings.snackbar.success')}`,
        type: TOAST_TYPES.SUCCESS,
      });
      handleRedirect();
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      const imageUploadFailed = errorMessage.includes(
        AccountUpdateErrorMessages.UploadFailed,
      );
      const publisherUpdateFailed = errorMessage.includes(
        AccountUpdateErrorMessages.UpdateFailed,
      );

      if (imageUploadFailed && publisherUpdateFailed) {
        showToast({
          description: `${translate(
            'settings.snackbar.submitFailed.errorBody',
          )}`,
          type: TOAST_TYPES.ERROR,
          message: `${translate('settings.snackbar.changesNotSaved')}`,
        });
      } else if (imageUploadFailed) {
        showToast({
          description: `${translate(
            'settings.snackbar.imageUpload.errorBody',
          )}`,
          type: TOAST_TYPES.ERROR,
          message: `${translate('settings.snackbar.notAllChangesSaved')}`,
        });
      } else {
        showToast({
          description: `${translate(
            'settings.snackbar.updateFailed.errorBody',
          )}`,
          type: TOAST_TYPES.ERROR,
          message: `${translate('settings.snackbar.notAllChangesSaved')}`,
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Page className="account_settings__page">
      <LoadStateData
        actionTypes={GET_PUBLISHER_TYPES}
        loading={<LoadingGif size="large" />}
        failure={
          <ErrorPage
            errorImg={<LinkBroken />}
            title={`${translate('settings.error.title')}`}
            description={`${translate('settings.error.body')}`}
            CTA={[
              <Button
                key="accounts-error-reject"
                onClick={() =>
                  window.open(
                    'https://support.crowdriff.com/hc/en-us/requests/new',
                  )
                }
                color={BUTTON_COLOR_VARIANT.SECONDARY}
              >
                {translate('settings.error.rejectCTA')}
              </Button>,
              <Button
                key="accounts-error-accept"
                onClick={handleRedirect}
                color={BUTTON_COLOR_VARIANT.PRIMARY}
              >
                {translate('settings.error.acceptCTA')}
              </Button>,
            ]}
          />
        }
        thunk={getPublisher}
      >
        <PageHeader>
          <PageTitle />
        </PageHeader>
        <AccountEditForm
          isSaving={isSaving}
          onSubmit={handleFormSubmit}
          onReject={handleRedirect}
          publisher={publisher}
        />
      </LoadStateData>
    </Page>
  );
};

export const AccountSettingsPage = withLocalize(AccountSettingsComponent);
