import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var CopyV2 = function CopyV2(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M14 6.84037V4C14 3.44772 13.5523 3 13 3H5C4.44772 3 4 3.44772 4 4V16.5211C4 17.0734 4.44772 17.5211 5 17.5211H9.55556M11 21.67H19C19.5523 21.67 20 21.2223 20 20.67V8.1489C20 7.59661 19.5523 7.1489 19 7.1489H11C10.4477 7.1489 10 7.59661 10 8.1489V20.67C10 21.2223 10.4477 21.67 11 21.67Z',
      stroke: color,
      strokeWidth: '1.5'
    })
  );
};

CopyV2.propTypes = propTypes;

export default CopyV2;