import { UploadFilesResponse } from '../../http/account_update.thunk';
import { GetPublisherResponse } from '../../http/publisher_get.thunk';
import { FEATURE_FLAGS } from '../../react/blocks/authwrapper/auth_wrapper.types';
import { PUBLISHER_ROLES } from '../../react/features/publisher_label/publisher_label.types';

export interface UserState {
  isPendoInitialized: boolean;
  acceptedToS: boolean;
  userId: string;
  userType: string;
  currentPublisher: Publisher;
  publisherIds: string[];
  data: {
    [networkId: string]: {
      publishers: Publisher[];
    };
  };
}

export enum PUBLISHER_TYPES {
  ORGANIZATION = 'organization',
  LOCALHOOD = 'individual',
}

export interface NetworkPublisher {
  networkId: string;
  publisherId: string;
  role?: PUBLISHER_ROLES;
  publisherType: PUBLISHER_TYPES;
}

export interface Publisher {
  publisherId: string;
  collectionId?: string;
  networkId?: string;
  role?: PUBLISHER_ROLES;
  label?: string;
  avatarUrl?: string;
  faviconUrl?: string;
  featureFlags?: FEATURE_FLAGS | FEATURE_FLAGS[];
  publisherType?: PUBLISHER_TYPES;
  gallery_domain?: string;
  name?: string;
  isCreatorServices?: boolean;
}

export interface Network {
  label?: string;
  networkId: string;
  publishers?: Publisher[];
}

export interface PublisherResponse {
  network_id?: string;
  publisher_id: string;
  role: PUBLISHER_ROLES;
  label?: string;
  publisher_type: PUBLISHER_TYPES;
}

export interface CurrentPublisherResponse {
  id: string;
  network_id: string;
  role?: PUBLISHER_ROLES;
  label?: string;
  avatar_url: string;
  feature_flags: FEATURE_FLAGS | FEATURE_FLAGS[];
  publisher_type: PUBLISHER_TYPES;
}

export interface NetworkResponse {
  network_id: string;
  publishers: PublisherResponse[];
}

export interface UserResponse {
  user_id: string;
  user_type: string;
  networks: NetworkResponse[];
  publishers: PublisherResponse[];
  current_publisher: CurrentPublisherResponse;
  error?: string;
}

export interface GetUserResponse {
  userId: string;
  networks: Network[];
  publishers: NetworkPublisher[];
  currentPublisher: Publisher;
  publisherIds: string[];
  userType: string;
}
export enum GET_USER_TYPES {
  START = 'USER/START',
  SUCCESS = 'USER/SUCCESS',
  FAILURE = 'USER/FAILURE',
}

export enum GET_PUBLISHER_TYPES {
  START = 'PUBLISHER/START',
  SUCCESS = 'PUBLISHER/SUCCESS',
  FAILURE = 'PUBLISHER/FAILURE',
}

export enum GET_PUBLISHERS_TYPES {
  START = 'PUBLISHERS/START',
  SUCCESS = 'PUBLISHERS/SUCCESS',
  FAILURE = 'PUBLISHERS/FAILURE',
}

export enum UPDATE_PUBLISHER_TYPES {
  START = 'UPDATE_USER/START',
  SUCCESS = 'UPDATE_USER/SUCCESS',
  FAILURE = 'UPDATE_USER/FAILURE',
}

export enum GET_NP_FEATURE_FLAGS {
  START = 'NP_FEATURE_FLAGS/START',
  SUCCESS = 'NP_FEATURE_FLAGS/SUCCESS',
  FAILURE = 'NP_FEATURE_FLAGS/FAILURE',
}

export enum PUBLISHER_SWITCHING {
  UPDATE_PUBLISHER = 'PUBLISHER_SWITCHING/UPDATE_PUBLISHER',
}

export enum INIT_PENDO {
  SUCCESS = 'INIT_PENDO/SUCCESS',
}

export enum USER_TYPE {
  INTERNAL = 'internal',
}

export enum GET_ACCEPTANCE {
  START = 'ACCEPTANCE/START',
  SUCCESS = 'ACCEPTANCE/SUCCESS',
  FAILURE = 'ACCEPTANCE/FAILURE',
}

export enum SET_ACCEPTANCE {
  START = 'ACCEPTANCE/START',
  SUCCESS = 'ACCEPTANCE/SUCCESS',
  FAILURE = 'ACCEPTANCE/FAILURE',
}

export enum UPDATE_LOGO_FAVICON_TYPES {
  START = 'UPDATE_LOGO_FAVICON/START',
  SUCCESS = 'UPDATE_LOGO_FAVICON/SUCCESS',
  FAILURE = 'UPDATE_LOGO_FAVICON/FAILURE',
}
export interface UserAuthenticatedAction {
  type: GET_USER_TYPES.SUCCESS;
  payload: GetUserResponse;
}

export interface UserNotAuthenticatedAction {
  type: GET_USER_TYPES.FAILURE;
  payload: {};
}
export interface UpdateCurrentPublisherAction {
  type: PUBLISHER_SWITCHING.UPDATE_PUBLISHER;
  payload: Publisher;
}

export interface GetPublisherActionStart {
  type: GET_PUBLISHER_TYPES.START;
  payload: string;
}
export interface GetPublisherActionSuccess {
  type: GET_PUBLISHER_TYPES.SUCCESS;
  payload: GetPublisherResponse;
}
export interface GetPublisherActionFailure {
  type: GET_PUBLISHER_TYPES.FAILURE;
  payload: {};
}

export interface GetPublishersActionStart {
  type: GET_PUBLISHERS_TYPES.START;
  payload: {};
}
export interface GetPublishersActionSuccess {
  type: GET_PUBLISHERS_TYPES.SUCCESS;
  payload: Publisher[];
}
export interface GetPublishersActionFailure {
  type: GET_PUBLISHERS_TYPES.FAILURE;
  payload: {};
}
export interface UpdatePublisherActionStart {
  type: UPDATE_PUBLISHER_TYPES.START;
  payload: {};
}
export interface UpdatePublisherActionSuccess {
  type: UPDATE_PUBLISHER_TYPES.SUCCESS;
  payload: Publisher;
}
export interface UpdatePublisherActionFailure {
  type: UPDATE_PUBLISHER_TYPES.FAILURE;
  payload: {};
}
export interface GetNetworkPublishersFeatureFlagsActionStart {
  type: GET_NP_FEATURE_FLAGS.START;
  payload: {};
}
export interface GetNetworkPublishersFeatureFlagsActionSuccess {
  type: GET_NP_FEATURE_FLAGS.SUCCESS;
  payload: FEATURE_FLAGS | FEATURE_FLAGS[];
}
export interface GetNetworkPublishersFeatureFlagsActionFailure {
  type: GET_NP_FEATURE_FLAGS.FAILURE;
  payload: Error;
}
export interface InitPendoSuccessAction {
  type: INIT_PENDO.SUCCESS;
  payload: boolean;
}

export interface GetUserAcceptanceActionStart {
  type: GET_ACCEPTANCE.START;
  payload: {};
}

export interface GetUserAcceptanceActionSuccess {
  type: GET_ACCEPTANCE.SUCCESS;
  payload: boolean;
}

export interface GetUserAcceptanceActionFailure {
  type: GET_ACCEPTANCE.FAILURE;
  payload: Error;
}

export interface SetUserAcceptanceActionStart {
  type: SET_ACCEPTANCE.START;
  payload: {};
}

export interface SetUserAcceptanceActionSuccess {
  type: SET_ACCEPTANCE.SUCCESS;
  payload: {};
}

export interface SetUserAcceptanceActionFailure {
  type: SET_ACCEPTANCE.FAILURE;
  payload: Error;
}

export interface UpdateLogoandFaviconActionStart {
  type: UPDATE_LOGO_FAVICON_TYPES.START;
  payload: {};
}
export interface UpdateLogoandFaviconActionSuccess {
  type: UPDATE_LOGO_FAVICON_TYPES.SUCCESS;
  payload: UploadFilesResponse;
}
export interface UpdateLogoandFaviconActionFailure {
  type: UPDATE_LOGO_FAVICON_TYPES.FAILURE;
  payload: {};
}

export type UserActions =
  | UserAuthenticatedAction
  | UserNotAuthenticatedAction
  | UpdateCurrentPublisherAction
  | GetPublisherActionStart
  | GetPublisherActionFailure
  | GetPublisherActionSuccess
  | GetPublishersActionStart
  | GetPublishersActionFailure
  | GetPublishersActionSuccess
  | UpdatePublisherActionStart
  | UpdatePublisherActionSuccess
  | UpdatePublisherActionFailure
  | GetNetworkPublishersFeatureFlagsActionStart
  | GetNetworkPublishersFeatureFlagsActionSuccess
  | GetNetworkPublishersFeatureFlagsActionFailure
  | InitPendoSuccessAction
  | GetUserAcceptanceActionStart
  | GetUserAcceptanceActionSuccess
  | GetUserAcceptanceActionFailure
  | SetUserAcceptanceActionStart
  | SetUserAcceptanceActionSuccess
  | SetUserAcceptanceActionFailure
  | UpdateLogoandFaviconActionStart
  | UpdateLogoandFaviconActionSuccess
  | UpdateLogoandFaviconActionFailure;
