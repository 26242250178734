import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Login = function Login(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'login_icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1200 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M910.2,535.2l-425,425c-9.4,9.4-21.9,14.8-35.2,14.8c-27.3,0-50-22.7-50-50V700H50c-27.3,0-50-22.7-50-50V350c0-27.3,22.7-50,50-50h350V75c0-27.3,22.7-50,50-50c13.3,0,25.8,5.5,35.2,14.8l425,425c9.4,9.4,14.8,21.9,14.8,35.2S919.5,525.8,910.2,535.2z M1200,775c0,124.2-100.8,225-225,225H725c-13.3,0-25-11.7-25-25c0-21.9-10.2-75,25-75h250c68.8,0,125-56.3,125-125V225c0-68.7-56.2-125-125-125H750c-19.5,0-50,3.9-50-25c0-21.9-10.2-75,25-75h250c124.2,0,225,100.8,225,225V775z' // eslint-disable-line max-len
    })
  );
};

Login.propTypes = propTypes;

export default Login;