import { ThunkDispatch } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { selectCurrentNetworkId } from '../redux/user/user.selectors';
import { AppState, AppActions } from '../types';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import {
  hideStoryStarted,
  hideStorySucceeded,
  hideStoryFailed,
} from '../redux/stories/stories.actions';

export interface StoryHideResponse {
  error?: Error;
}

export const hideStory = (storyId: string) => async (
  dispatch: ThunkDispatch<AppState, {}, AppActions>,
  getState: () => AppState,
): Promise<StoryHideResponse> => {
  const state = getState();
  const networkId = selectCurrentNetworkId(state);

  if (!storyId) {
    throw new Error('No story Id provided');
  }

  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.STORY_HIDE,
    params: {
      networkId,
      uuid: storyId,
    },
  });

  try {
    dispatch(hideStoryStarted);
    const res: AxiosResponse<StoryHideResponse> = await axios.get<
      StoryHideResponse
    >(url);

    dispatch(hideStorySucceeded(storyId));
    return res.data;
  } catch (e) {
    dispatch(hideStoryFailed(e));
    throw new Error(
      `Failed to hide story: ${e instanceof Error ? e.message : e}`,
    );
  }
};
