import { createSelector } from 'reselect';
import {
  selectCurrentNetworkId,
  selectCurrentPublisherId,
  selectCurrentPublisherRole,
} from '../user/user.selectors';
import { PUBLISHER_ROLES } from '../../react/features/publisher_label/publisher_label.types';

// NOTE: This is a temporary solution to generate report links until
// we have a fully flushed out analytics data pipeline [SNET-677]
export enum TEMP_VALID_NETWORKS {
  // Staging
  N1 = '2e226a9c-56db-425d-bcc0-ba69d0dd9457',
  N2 = '3f4b1a9f-cc10-4ed5-aba8-5c0b2cc50a2f',
  N3 = 'b228906c-3f1c-429c-9fa8-e504507fe473',
  N4 = '8b82d977-3003-425f-ab5b-cf4acc3fafb7',
  N6 = '176c90dd-0c57-426a-a613-4ea27927d6fc',
  SANDBOX_OWNER = '9a960073-7625-4e42-bc19-b60635742e1d',
  // Prod
  CROWDRIFF = '950221a6-fc48-4526-b8b5-cfd56eec4bdd',
  CITY_TRAINING_OWNER = 'af591791-aa51-49bf-9824-760968823ffc',
  DEMO = '7be9aa9d-0590-4a0d-adcd-e13edaac9583',
  DBC = '71884f97-7d27-4d71-8bde-2ad3443eed83',
  ETC = '5722202c-9b43-413c-bbac-946191e4b7d5',
  LOT = 'f0d08a6c-3347-4d46-8847-abe24d55ce6d',
  VCA = '87d6df5e-75e8-4b8b-96ca-46619f44be7b',
  CTO = '84bcfe9a-f179-4ab3-a9d8-60c11db19380',
  VATC = 'dfa97a5e-7807-4ced-95f7-84f471c96fbf',
  MOT = '1fef9454-27db-45cd-a732-65572a19ca48',
  BRANSON = 'c147a9d2-a92f-4af7-ac72-2ce85cd01634',
  CLEVELAND = '1cc180e5-6346-4c2e-9b23-356488d5a777',
  LINCOLN = '69bc42c6-2ad8-4917-b02b-9e171ff04bcc',
  ASHEVILLE = '68461f82-0aee-4c5f-815b-db4a419d3b90',
  JACKSON = '3a628f7e-146f-4c1f-a88f-ea67e150c689',
  MARQUETTE = 'a396ac02-861c-4591-b78e-2f3255f02ca5',
  IOWA = 'a4e3e09f-b831-4b3f-8575-d459c3e65996',
  ILLINOIS = '90892f30-d64d-4db4-884c-0584a3ab4acb',
  PHOENIX = '4b45d2d1-7ed1-41aa-b0a8-9350aba72873',
  JACKSONVILLE = 'b2d3d15e-6e3d-4ac6-a986-8a3286c715c5',
  OKLAHOMA = '54ebca18-6542-44ce-a37b-b2cc593cc23d',
  UPPER_PENINSULA = '1422e856-8c18-430e-9c46-5a54386dba47',
  GALVESTON = 'e615cfae-3d50-4056-873a-94f6cff2f8c5',
  KALAMAZOO = '79db0c8f-1233-4e02-8f4b-281a461db7fd',
  ORANGE_BEACH = 'a42a7b4c-0f3f-4d62-aa85-6be7345793d5',
  DUTCHESS = '67d687a7-54d5-48d3-a21e-5bd552ffc52f',
  MINNEAPOLIS = 'd96529c3-31bf-4722-8d08-222db8ed0881',
  CITY_EAU_CLAIRE_WI = '348e1957-6512-402d-95e8-e3b115364feb',
  CITY_MIAMI_FL = 'fb9b911e-de59-431e-9867-28c7ea25744a',
  CITY_OURAY_CO = '884fe74d-95da-4206-aafd-71933351d563',
  CITY_TORONTO_ON = 'cc3f6248-eb1c-47a7-9f92-57614784fadc',
  OREGON = '0c7523c8-95a4-4481-aa3c-436c2f590ab1',
  FLORIDA = 'efcd1b53-0eb5-4516-9e5f-d43f71512083',
  CITY_BRAND_USA = '36d343c3-5bab-49eb-af57-f5ac3ef67bab',
  CITY_MANCHESTER_UK = '796661bf-94d0-4a61-933b-2b194da342a6',
  CULINARY_TOURISM_ALLIANCE = 'e2b4c613-c065-4687-a0c9-713dd5ec526a',
  DRUMHELLER = '0f9fd353-f24d-40d3-90ce-cb27932aefcf',
  CHEYENNE = '1587a708-6c96-4e33-9314-27c8a14c2b49',
  CORPUS_CHRISTI = 'a5b6feab-2608-49cb-9576-7eef01eb593b',
  ALAMOSA = '2cc0f382-2ed9-4e79-bfcd-b5fc0704d7db',
  HOUSE_TRAVEL = 'ae3034b4-5cab-4ca6-9ae4-d8d9deb400c9',
  CITY_ANN_ARBOR = '40c40918-3a6d-47bc-be10-3bbadefc36af',
  LEXINGTON_KY = '4c959a8f-4b36-462d-818d-ba1e9c52e758',
  CLACKAMAS = '4555b31f-fa49-4c66-9cf8-831f0f08e21e',
  DAYTONA = 'a14868e5-8c57-424b-91a2-ebc6b5584e2b',
  CHICAGO_NORTHWEST = '3e1103ee-3209-4aa5-83a8-a818d0c4a40b',
  TENNESSEE = '1c29ef57-2a37-4c11-8bff-40202d8286f8',
  VISIT_MESA = '69cc3b5b-9acc-40aa-9c55-036b93a95a02',
  PROVIDENCE_WARWICK = 'b1ca65cf-99ae-4c97-87f6-5e434d2c6243',
  GEORGETOWN_KENTUCKY = '15ca01f7-775d-434b-9642-5e43c1daf068',
  STEVENS_POINT = '84c02af9-3840-4558-8f1f-98517bf03338',
  SARASOTA = '0e1d274a-76b0-41d9-8315-6441071b738c',
  VISIT_PENSACOLA = '7f2dceea-712d-4f8e-9ecd-408e439b0aa2',
  GATLINBURG_CONVENTION = 'fc45618b-263c-46f6-ad85-54bb40da7957',
  KENTUCKY = '145d0624-06de-48cd-8fe0-a8c0cb473292',
  VISIT_MANITOBA = 'c2623e92-34a3-41a4-8eca-a496a36751fc',
  SAN_MARCOS = '12cd12e2-9066-45f1-8150-4697a6de66da',
  CAYUGA_COUNTY_CVB = '184e4246-f674-4c8e-94ed-a4b95dfc0041',
  YORK_COUNTY_CVB = 'cb735687-a9c1-40f4-b746-34ed4fd1497c',
  VISIT_WHITE_MOUNTAINS = '9a6d0891-a048-4c3d-9545-810b55ad8823',
  WASHINGTON_COUNTY_PA = '0c2e95f0-2ca5-4c84-9596-cc1bd64bc412',
  VISIT_ORLANDO = 'da8323e5-d311-4319-a493-430074bcf322',
  ONEIDA_COUNTY = '0ad55371-4794-4c7a-bb2d-8f066feef236',
  VISIT_IDAHO = 'b04137a3-8338-4fae-ab95-c4ca0181a445',
  DESTINATION_MADISON = '49924d75-8207-422f-89a6-1d0dacf9bd89',
  TRAVEL_ALASKA = 'a62affb3-02bb-4b36-a34c-c8f3a6a91055',
  VISIT_DETROIT = '214a6861-99da-49ef-8e55-4dfcbb2dac54',
  DISCOVER_LONG_ISLAND = '163f78b4-e860-496a-9e5a-92c5bd4977b2',
  DISCOVER_NEWPORT = 'faace794-ad50-4611-a98c-052a53a57367',
  ULSTER_COUNTY = 'f909a61f-2f80-460d-9b42-12b6e6aa86c1',
  CITY_OF_COLUMBIA_TENNESSEE = '9ab5ef54-2a03-4109-b9e3-755e777f9ea1',
  QUEBEC_CITY = '5f9656b7-de3a-420b-a17f-63fbb4602c5d',
  VISIT_SANDY_SPRINGS = '032c3815-5157-423b-b649-21bff28d2efe',
  CEDAR_PARK = '70495be4-40da-4888-80bd-2a2e5d481aac',
  PURE_MICHIGAN = 'b4c80f2a-b9df-4b82-a73a-48aaac56980f',
  VISIT_TUCSON = 'e230c4fa-d536-4c32-b21d-1ffa3c1d693a',
  ABIGAILS_HOTEL = '0196b367-6226-45a1-8728-8138264e84dd',
  DESTINATION_BRYAN = 'ddc8e3a5-4dbc-4e4f-9c38-a049069613a6',
}

type tempOwnerReportIdMap = {
  [_ in TEMP_VALID_NETWORKS]: string;
};

type tempPartnerReportIdMap = {
  [_ in TEMP_VALID_NETWORKS]: string;
};

const tempOwnerReportIdMap: tempOwnerReportIdMap = {
  [TEMP_VALID_NETWORKS.N1]: '1SYwhV3CzC-n8oTaL-5WD_2PErLhzr7i3',
  [TEMP_VALID_NETWORKS.N2]: '74894181-32b9-47fe-ba71-acf4cc06d73d',
  [TEMP_VALID_NETWORKS.N3]: '39b6e8de-3df7-427f-9196-37971eb1f725',
  [TEMP_VALID_NETWORKS.N4]: '335d3659-5179-47b9-a449-3883784a0b1f',
  [TEMP_VALID_NETWORKS.N6]: 'b26b0792-cb07-4e42-9c68-b3b65ac4ad56',
  [TEMP_VALID_NETWORKS.SANDBOX_OWNER]: '32803624-4a14-407f-8653-20ebd29c8431',
  [TEMP_VALID_NETWORKS.CROWDRIFF]: 'ecad770a-53dc-4e60-8552-51f96cd3b977',
  [TEMP_VALID_NETWORKS.CITY_TRAINING_OWNER]:
    '5688d6e4-5321-4faa-b1d7-59cdb716aa30',
  [TEMP_VALID_NETWORKS.DEMO]: 'e7292b10-aa11-4391-80f9-4a959a7d54e3',
  [TEMP_VALID_NETWORKS.DBC]: '1SYwhV3CzC-n8oTaL-5WD_2PErLhzr7i3',
  [TEMP_VALID_NETWORKS.ETC]: '74894181-32b9-47fe-ba71-acf4cc06d73d',
  [TEMP_VALID_NETWORKS.LOT]: '39b6e8de-3df7-427f-9196-37971eb1f725',
  [TEMP_VALID_NETWORKS.VCA]: '335d3659-5179-47b9-a449-3883784a0b1f',
  [TEMP_VALID_NETWORKS.CTO]: 'b26b0792-cb07-4e42-9c68-b3b65ac4ad56',
  [TEMP_VALID_NETWORKS.VATC]: '5d58e0bb-c175-476e-b193-de6c5879f51b',
  [TEMP_VALID_NETWORKS.MOT]: 'f91ccc73-6322-4d26-83df-fbc3f5e80254',
  [TEMP_VALID_NETWORKS.BRANSON]: 'a897014c-f31f-47b1-a5ac-ff9e54958ce3',
  [TEMP_VALID_NETWORKS.CLEVELAND]: 'd666d01a-e7b4-4ef6-a366-eb105ec4da0c',
  [TEMP_VALID_NETWORKS.LINCOLN]: '1cc29c84-3a28-4134-9afa-5c43f106503f',
  [TEMP_VALID_NETWORKS.ASHEVILLE]: '4435087f-e6c9-494e-84a0-8f5bc3872afa',
  [TEMP_VALID_NETWORKS.JACKSON]: 'd20affc8-6229-461b-83cf-80a665de398c',
  [TEMP_VALID_NETWORKS.MARQUETTE]: '14b4122c-5230-448f-9609-323609747708',
  [TEMP_VALID_NETWORKS.IOWA]: '91bc07a6-c887-4ed0-936f-6641f521e900',
  [TEMP_VALID_NETWORKS.ILLINOIS]: '5eb1628b-7d14-4fae-9f5a-2b7de99abc6a',
  [TEMP_VALID_NETWORKS.PHOENIX]: '69341933-9998-494f-a483-15184b7b274f',
  [TEMP_VALID_NETWORKS.JACKSONVILLE]: 'dd4b5bb5-c090-4e3a-952c-30dc6561a0f5',
  [TEMP_VALID_NETWORKS.OKLAHOMA]: 'f8c15043-a622-4d93-8053-ea14d3f1e682',
  [TEMP_VALID_NETWORKS.UPPER_PENINSULA]: '7b3bc560-599c-468f-9efb-0246d35aec37',
  [TEMP_VALID_NETWORKS.GALVESTON]: 'b87ca952-0b53-4fa2-87f2-b19528b14e56',
  [TEMP_VALID_NETWORKS.KALAMAZOO]: '5a597d68-ba53-4461-83e2-04a8e5e2581c',
  [TEMP_VALID_NETWORKS.ORANGE_BEACH]: 'cb840bd5-adf0-4767-a0e5-a8b3cfecd1dc',
  [TEMP_VALID_NETWORKS.DUTCHESS]: '3aca95fa-6dc4-45ba-8dd5-f3d3bb86e282',
  [TEMP_VALID_NETWORKS.MINNEAPOLIS]: '5d464dcc-8197-47d3-93e9-421463221f86',
  [TEMP_VALID_NETWORKS.CITY_EAU_CLAIRE_WI]:
    '45924b53-3d1b-4b47-a534-89141fa1c44a',
  [TEMP_VALID_NETWORKS.CITY_MIAMI_FL]: '9bd49b5c-3525-41cf-85f0-a1c717022220',
  [TEMP_VALID_NETWORKS.CITY_OURAY_CO]: '9d4aed36-93dc-4d66-9c68-342504217ab0',
  [TEMP_VALID_NETWORKS.CITY_TORONTO_ON]: '0186642e-7dea-4cc8-a246-3239f4f4ea28',
  [TEMP_VALID_NETWORKS.OREGON]: 'f80bcdcf-e7c9-4d68-818d-64d835b65c23',
  [TEMP_VALID_NETWORKS.FLORIDA]: 'e474a582-9a86-4370-b563-7ddb124f7192',
  [TEMP_VALID_NETWORKS.CITY_BRAND_USA]: '61ec8c3b-7fc0-478c-b2e5-508cdb96a4c2',
  [TEMP_VALID_NETWORKS.CITY_MANCHESTER_UK]:
    '1773999b-3d9a-46ca-b6ab-fabb964ec8a7',
  [TEMP_VALID_NETWORKS.CULINARY_TOURISM_ALLIANCE]:
    '31aa7dc4-429e-4535-b4cb-521301c89baa',
  [TEMP_VALID_NETWORKS.DRUMHELLER]: '39538c80-e4cb-4f94-9756-6974e0613a86',
  [TEMP_VALID_NETWORKS.CHEYENNE]: '7582ba3b-a0e9-4d83-ad1f-c2d6ef8a5d2d',
  [TEMP_VALID_NETWORKS.CORPUS_CHRISTI]: '84aed7a9-849a-45dc-8a23-d8366f8e7942',
  [TEMP_VALID_NETWORKS.ALAMOSA]: '269c468f-dcce-464d-a0aa-91b5489118e4',
  [TEMP_VALID_NETWORKS.HOUSE_TRAVEL]: '4f6829a5-c37d-46bb-a174-b6c5bbaa2b1f',
  [TEMP_VALID_NETWORKS.CITY_ANN_ARBOR]: 'c48f7777-d337-4143-95d2-9d1c7d191fd3',
  [TEMP_VALID_NETWORKS.LEXINGTON_KY]: 'a719d153-e500-4a94-97f5-538ba39bc76e',
  [TEMP_VALID_NETWORKS.CLACKAMAS]: '4e6aaaca-5a85-48dd-b2fa-e111f479b4d0',
  [TEMP_VALID_NETWORKS.DAYTONA]: 'af78bd21-6977-4c25-8a92-0b3c5caa38a6',
  [TEMP_VALID_NETWORKS.CHICAGO_NORTHWEST]:
    '0580c031-a616-4722-a460-51e91ba15b55',
  [TEMP_VALID_NETWORKS.TENNESSEE]: 'ee2954b4-c78b-4706-b46c-3fe1fe85d515',
  [TEMP_VALID_NETWORKS.VISIT_MESA]: '7ef4e5a6-eef0-43fc-9319-a56c7bbf57c1',
  [TEMP_VALID_NETWORKS.PROVIDENCE_WARWICK]:
    '8e8a0068-d4c4-415a-b1e2-58c9e1d2f946',
  [TEMP_VALID_NETWORKS.GEORGETOWN_KENTUCKY]:
    '98fd4c6d-ad59-4ba2-a022-c01a14bfeccd',
  [TEMP_VALID_NETWORKS.STEVENS_POINT]: '72baaa3f-f8fa-4e2d-a4f4-bbf68905cbc0',
  [TEMP_VALID_NETWORKS.SARASOTA]: 'c576202a-1848-43a1-9b63-c64dbefcadd8',
  [TEMP_VALID_NETWORKS.VISIT_PENSACOLA]: 'bfa1fab6-6f4f-438a-b340-de827d85339d',
  [TEMP_VALID_NETWORKS.GATLINBURG_CONVENTION]:
    '2ba3166f-715c-4bda-b508-48cfec068acb',
  [TEMP_VALID_NETWORKS.KENTUCKY]: '178a9222-1bd4-46c0-aa9e-1c62efa82545',
  [TEMP_VALID_NETWORKS.VISIT_MANITOBA]: '5a72d3cf-75da-4aa9-bb6e-ff579fb03673',
  [TEMP_VALID_NETWORKS.SAN_MARCOS]: 'd509bd0b-0cb6-4760-ba0e-3321c54ab270',
  [TEMP_VALID_NETWORKS.CAYUGA_COUNTY_CVB]:
    '9e043ea7-2cd9-40d0-bf2f-f062c8bb3049',
  [TEMP_VALID_NETWORKS.YORK_COUNTY_CVB]: '7a13c09c-61b0-4167-b830-9763ff231ce7',
  [TEMP_VALID_NETWORKS.VISIT_WHITE_MOUNTAINS]:
    '6940491f-712b-4aac-99f9-a2a835dc1f78',
  [TEMP_VALID_NETWORKS.WASHINGTON_COUNTY_PA]:
    '7c467be0-8e82-415b-bca8-4a9d47c5a66b',
  [TEMP_VALID_NETWORKS.VISIT_ORLANDO]: 'edc1d251-2396-4f33-afb9-d62156b6b107',
  [TEMP_VALID_NETWORKS.ONEIDA_COUNTY]: '043621d5-91cc-43cc-8ae5-331040162418',
  [TEMP_VALID_NETWORKS.VISIT_IDAHO]: '3e6abf72-830c-45c0-be98-0b6a417cd1e7',
  [TEMP_VALID_NETWORKS.DESTINATION_MADISON]:
    '6147a455-fdec-4eab-94fe-4fbab6a67df0',
  [TEMP_VALID_NETWORKS.TRAVEL_ALASKA]: 'dfe0fbad-3b73-4e57-ac01-0b409964fddb',
  [TEMP_VALID_NETWORKS.VISIT_DETROIT]: '175dd431-8046-435f-8ca2-5814a6fa394b',
  [TEMP_VALID_NETWORKS.DISCOVER_LONG_ISLAND]:
    '8fb28bc0-43a4-4151-ade6-820148b995f1',
  [TEMP_VALID_NETWORKS.DISCOVER_NEWPORT]:
    '3dbdcbce-e3ec-4805-adad-90f022d3811a',
  [TEMP_VALID_NETWORKS.ULSTER_COUNTY]: '076b2da6-2657-4f16-a141-db8604fb81a2',
  [TEMP_VALID_NETWORKS.CITY_OF_COLUMBIA_TENNESSEE]:
    'df879e1d-9469-41b2-9564-4ef9df422e07',
  [TEMP_VALID_NETWORKS.QUEBEC_CITY]: '688a7c78-a6a9-4ed2-901b-28b3f96279d8',
  [TEMP_VALID_NETWORKS.VISIT_SANDY_SPRINGS]:
    '06a2a0f8-0f05-40bf-8fcc-465796f57e76',
  [TEMP_VALID_NETWORKS.CEDAR_PARK]: 'a905af01-62c8-4b79-8ab1-b142d5f5e74d',
  [TEMP_VALID_NETWORKS.PURE_MICHIGAN]: 'cbae44c9-a15d-4901-9f45-3e1762620756',
  [TEMP_VALID_NETWORKS.VISIT_TUCSON]: 'a79412bc-de5f-4530-a301-f10f4c1f3d82',
  [TEMP_VALID_NETWORKS.ABIGAILS_HOTEL]: 'a10afe24-06bf-4e90-b286-2ee0af4ab4aa',
  [TEMP_VALID_NETWORKS.DESTINATION_BRYAN]: 'eb0dc34e-d203-4c02-8e8a-796563ba1deb',

};

const tempPartnerReportIdMap: tempOwnerReportIdMap = {
  [TEMP_VALID_NETWORKS.N1]: 'fdbad3f5-12e0-4434-b7c2-5c7cb037d0e5',
  [TEMP_VALID_NETWORKS.N2]: '9a425c08-eec2-44e8-b87a-bea73b7f8894',
  // N3 & N4 partners not applicable and will not receive access to reports
  [TEMP_VALID_NETWORKS.N3]: 'c27b61a3-ffe0-4cf2-b925-9465e89b64b1',
  [TEMP_VALID_NETWORKS.N4]: '0cdf2761-132e-47d0-9520-f94964f0131d',
  [TEMP_VALID_NETWORKS.N6]: 'a12248e8-e72d-4ece-ada8-49253d955da9',
  [TEMP_VALID_NETWORKS.SANDBOX_OWNER]: '32803624-4a14-407f-8653-20ebd29c8431',
  [TEMP_VALID_NETWORKS.CROWDRIFF]: 'ecad770a-53dc-4e60-8552-51f96cd3b977',
  [TEMP_VALID_NETWORKS.CITY_TRAINING_OWNER]:
    '5688d6e4-5321-4faa-b1d7-59cdb716aa30',
  [TEMP_VALID_NETWORKS.DEMO]: 'e7292b10-aa11-4391-80f9-4a959a7d54e3',
  [TEMP_VALID_NETWORKS.DBC]: 'afd3d208-aca2-47dc-910a-4d9a3290332c',
  [TEMP_VALID_NETWORKS.ETC]: '658d704e-716e-488d-a76a-e9b857e8c2ae',
  // LOT & LOT partners not applicable and will not receive access to reports
  [TEMP_VALID_NETWORKS.LOT]: '37983c92-f983-451b-a77d-3e0fff8245f3',
  [TEMP_VALID_NETWORKS.VCA]: '99260af3-165d-4e12-a2a9-a8c992ceb785',
  [TEMP_VALID_NETWORKS.CTO]: 'a12248e8-e72d-4ece-ada8-49253d955da9',
  [TEMP_VALID_NETWORKS.VATC]: 'c33845dc-26e4-40c9-aabc-3ddd157c75ab',
  [TEMP_VALID_NETWORKS.MOT]: 'b5e6a42e-cc1a-491f-a00e-6be9da0789f2',
  [TEMP_VALID_NETWORKS.BRANSON]: 'a897014c-f31f-47b1-a5ac-ff9e54958ce3',
  [TEMP_VALID_NETWORKS.CLEVELAND]: 'd666d01a-e7b4-4ef6-a366-eb105ec4da0c',
  [TEMP_VALID_NETWORKS.LINCOLN]: '1cc29c84-3a28-4134-9afa-5c43f106503f',
  [TEMP_VALID_NETWORKS.ASHEVILLE]: '4435087f-e6c9-494e-84a0-8f5bc3872afa',
  [TEMP_VALID_NETWORKS.JACKSON]: 'd20affc8-6229-461b-83cf-80a665de398c',
  [TEMP_VALID_NETWORKS.MARQUETTE]: '14b4122c-5230-448f-9609-323609747708',
  [TEMP_VALID_NETWORKS.IOWA]: 'e2282cfe-f644-48ca-9980-ded1138dc89a',
  [TEMP_VALID_NETWORKS.ILLINOIS]: '602aa0f3-1d51-46ec-8604-72d813316c74',
  [TEMP_VALID_NETWORKS.PHOENIX]: '69341933-9998-494f-a483-15184b7b274f',
  [TEMP_VALID_NETWORKS.JACKSONVILLE]: 'dd4b5bb5-c090-4e3a-952c-30dc6561a0f5',
  [TEMP_VALID_NETWORKS.OKLAHOMA]: 'f8c15043-a622-4d93-8053-ea14d3f1e682',
  [TEMP_VALID_NETWORKS.UPPER_PENINSULA]: '7b3bc560-599c-468f-9efb-0246d35aec37',
  [TEMP_VALID_NETWORKS.GALVESTON]: 'b87ca952-0b53-4fa2-87f2-b19528b14e56',
  [TEMP_VALID_NETWORKS.KALAMAZOO]: '5a597d68-ba53-4461-83e2-04a8e5e2581c',
  [TEMP_VALID_NETWORKS.ORANGE_BEACH]: 'cb840bd5-adf0-4767-a0e5-a8b3cfecd1dc',
  [TEMP_VALID_NETWORKS.DUTCHESS]: '3aca95fa-6dc4-45ba-8dd5-f3d3bb86e282',
  [TEMP_VALID_NETWORKS.MINNEAPOLIS]: '5d464dcc-8197-47d3-93e9-421463221f86',
  [TEMP_VALID_NETWORKS.CITY_EAU_CLAIRE_WI]:
    '45924b53-3d1b-4b47-a534-89141fa1c44a',
  [TEMP_VALID_NETWORKS.CITY_MIAMI_FL]: '9bd49b5c-3525-41cf-85f0-a1c717022220',
  [TEMP_VALID_NETWORKS.CITY_OURAY_CO]: '9d4aed36-93dc-4d66-9c68-342504217ab0',
  [TEMP_VALID_NETWORKS.CITY_TORONTO_ON]: '0186642e-7dea-4cc8-a246-3239f4f4ea28',
  [TEMP_VALID_NETWORKS.OREGON]: 'f80bcdcf-e7c9-4d68-818d-64d835b65c23',
  [TEMP_VALID_NETWORKS.FLORIDA]: 'e474a582-9a86-4370-b563-7ddb124f7192',
  [TEMP_VALID_NETWORKS.CITY_BRAND_USA]: '61ec8c3b-7fc0-478c-b2e5-508cdb96a4c2',
  [TEMP_VALID_NETWORKS.CITY_MANCHESTER_UK]:
    '1773999b-3d9a-46ca-b6ab-fabb964ec8a7',
  [TEMP_VALID_NETWORKS.CULINARY_TOURISM_ALLIANCE]:
    '31aa7dc4-429e-4535-b4cb-521301c89baa',
  [TEMP_VALID_NETWORKS.DRUMHELLER]: '39538c80-e4cb-4f94-9756-6974e0613a86',
  [TEMP_VALID_NETWORKS.CHEYENNE]: '7582ba3b-a0e9-4d83-ad1f-c2d6ef8a5d2d',
  [TEMP_VALID_NETWORKS.CORPUS_CHRISTI]: '84aed7a9-849a-45dc-8a23-d8366f8e7942',
  [TEMP_VALID_NETWORKS.ALAMOSA]: '269c468f-dcce-464d-a0aa-91b5489118e4',
  [TEMP_VALID_NETWORKS.HOUSE_TRAVEL]: '4f6829a5-c37d-46bb-a174-b6c5bbaa2b1f',
  [TEMP_VALID_NETWORKS.CITY_ANN_ARBOR]: 'c48f7777-d337-4143-95d2-9d1c7d191fd3',
  [TEMP_VALID_NETWORKS.LEXINGTON_KY]: 'a719d153-e500-4a94-97f5-538ba39bc76e',
  [TEMP_VALID_NETWORKS.CLACKAMAS]: '4e6aaaca-5a85-48dd-b2fa-e111f479b4d0',
  [TEMP_VALID_NETWORKS.DAYTONA]: 'af78bd21-6977-4c25-8a92-0b3c5caa38a6',
  [TEMP_VALID_NETWORKS.CHICAGO_NORTHWEST]:
    '0580c031-a616-4722-a460-51e91ba15b55',
  [TEMP_VALID_NETWORKS.TENNESSEE]: 'ee2954b4-c78b-4706-b46c-3fe1fe85d515',
  [TEMP_VALID_NETWORKS.VISIT_MESA]: '7ef4e5a6-eef0-43fc-9319-a56c7bbf57c1',
  [TEMP_VALID_NETWORKS.PROVIDENCE_WARWICK]:
    '8e8a0068-d4c4-415a-b1e2-58c9e1d2f946',
  [TEMP_VALID_NETWORKS.GEORGETOWN_KENTUCKY]:
    '98fd4c6d-ad59-4ba2-a022-c01a14bfeccd',
  [TEMP_VALID_NETWORKS.STEVENS_POINT]: '72baaa3f-f8fa-4e2d-a4f4-bbf68905cbc0',
  [TEMP_VALID_NETWORKS.SARASOTA]: 'c576202a-1848-43a1-9b63-c64dbefcadd8',
  [TEMP_VALID_NETWORKS.VISIT_PENSACOLA]: 'bfa1fab6-6f4f-438a-b340-de827d85339d',
  [TEMP_VALID_NETWORKS.GATLINBURG_CONVENTION]:
    '2ba3166f-715c-4bda-b508-48cfec068acb',
  [TEMP_VALID_NETWORKS.KENTUCKY]: '178a9222-1bd4-46c0-aa9e-1c62efa82545',
  [TEMP_VALID_NETWORKS.VISIT_MANITOBA]: '5a72d3cf-75da-4aa9-bb6e-ff579fb03673',
  [TEMP_VALID_NETWORKS.SAN_MARCOS]: 'd509bd0b-0cb6-4760-ba0e-3321c54ab270',
  [TEMP_VALID_NETWORKS.CAYUGA_COUNTY_CVB]:
    '9e043ea7-2cd9-40d0-bf2f-f062c8bb3049',
  [TEMP_VALID_NETWORKS.YORK_COUNTY_CVB]: '7a13c09c-61b0-4167-b830-9763ff231ce7',
  [TEMP_VALID_NETWORKS.VISIT_WHITE_MOUNTAINS]:
    '6940491f-712b-4aac-99f9-a2a835dc1f78',
  [TEMP_VALID_NETWORKS.WASHINGTON_COUNTY_PA]:
    '7c467be0-8e82-415b-bca8-4a9d47c5a66b',
  [TEMP_VALID_NETWORKS.VISIT_ORLANDO]: 'edc1d251-2396-4f33-afb9-d62156b6b107',
  [TEMP_VALID_NETWORKS.ONEIDA_COUNTY]: '043621d5-91cc-43cc-8ae5-331040162418',
  [TEMP_VALID_NETWORKS.VISIT_IDAHO]: '3e6abf72-830c-45c0-be98-0b6a417cd1e7',
  [TEMP_VALID_NETWORKS.DESTINATION_MADISON]:
    '6147a455-fdec-4eab-94fe-4fbab6a67df0',
  [TEMP_VALID_NETWORKS.TRAVEL_ALASKA]: 'dfe0fbad-3b73-4e57-ac01-0b409964fddb',
  [TEMP_VALID_NETWORKS.VISIT_DETROIT]: '175dd431-8046-435f-8ca2-5814a6fa394b',
  [TEMP_VALID_NETWORKS.DISCOVER_LONG_ISLAND]:
    '8fb28bc0-43a4-4151-ade6-820148b995f1',
  [TEMP_VALID_NETWORKS.DISCOVER_NEWPORT]:
    '3dbdcbce-e3ec-4805-adad-90f022d3811a',
  [TEMP_VALID_NETWORKS.ULSTER_COUNTY]: '076b2da6-2657-4f16-a141-db8604fb81a2',
  [TEMP_VALID_NETWORKS.CITY_OF_COLUMBIA_TENNESSEE]:
    'df879e1d-9469-41b2-9564-4ef9df422e07',
  [TEMP_VALID_NETWORKS.QUEBEC_CITY]: '688a7c78-a6a9-4ed2-901b-28b3f96279d8',
  [TEMP_VALID_NETWORKS.VISIT_SANDY_SPRINGS]:
    '06a2a0f8-0f05-40bf-8fcc-465796f57e76',
  [TEMP_VALID_NETWORKS.CEDAR_PARK]: 'a905af01-62c8-4b79-8ab1-b142d5f5e74d',
  [TEMP_VALID_NETWORKS.PURE_MICHIGAN]: 'cbae44c9-a15d-4901-9f45-3e1762620756',
  [TEMP_VALID_NETWORKS.VISIT_TUCSON]: 'a79412bc-de5f-4530-a301-f10f4c1f3d82',
  [TEMP_VALID_NETWORKS.ABIGAILS_HOTEL]: 'a10afe24-06bf-4e90-b286-2ee0af4ab4aa',
  [TEMP_VALID_NETWORKS.DESTINATION_BRYAN]: 'eb0dc34e-d203-4c02-8e8a-796563ba1deb',
};

export const selectReportUrl = createSelector(
  selectCurrentPublisherId,
  selectCurrentNetworkId,
  selectCurrentPublisherRole,
  (
    publisherId: string,
    networkId: string,
    role?: PUBLISHER_ROLES,
  ): string | undefined => {
    const id = networkId as TEMP_VALID_NETWORKS;
    let reportUrl;
    const dataStudioUrl = process.env.DATA_STUDIO_URL;
    const encodedPubId = encodeURIComponent(publisherId);

    // Special case for tourism-ucluelet, whose publisher slug contains a trailing newline that breaks URL-based report filtering.
    // No publisher besides them should ever need this special case, because new publisher creation includes slug sanitization.
    if (encodedPubId === 'tourism-ucluelet%0A') {
      // Report ID not added to the map, because this isn't a network.
      return `${dataStudioUrl}/reporting/c05ea1d5-b362-4270-a50e-5f8752e44a72`;
    }

    switch (role) {
      case PUBLISHER_ROLES.OWNER:
        reportUrl = tempOwnerReportIdMap[id]
          ? `${dataStudioUrl}/reporting/${tempOwnerReportIdMap[id]}`
          : undefined;
        break;
      case PUBLISHER_ROLES.MEMBER:
        reportUrl = tempPartnerReportIdMap[id]
          ? `${dataStudioUrl}/reporting/${tempPartnerReportIdMap[id]}/?params={"df126":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${encodedPubId}","df127":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${encodedPubId}","df128":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${encodedPubId}","df129":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${encodedPubId}","df164":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${encodedPubId}"}`
          : undefined;
        break;
      default:
        reportUrl = undefined;
        break;
    }

    return reportUrl;
  },
);
