import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var MegaPhoneV2 = function MegaPhoneV2(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'megaPhoneV2__icon',
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M14 13.9996V5.9996M14 13.9996L20.1023 17.4866C20.5023 17.7152 21 17.4264 21 16.9657V3.03351C21 2.57281 20.5023 2.28399 20.1023 2.51256L14 5.9996M14 13.9996H7C4.79086 13.9996 3 12.2087 3 9.9996C3 7.79046 4.79086 5.9996 7 5.9996H14',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M7.75716 19.3001L7 14H11L11.6772 18.7401C11.8476 19.9329 10.922 21 9.71716 21C8.73186 21 7.8965 20.2755 7.75716 19.3001Z',
      stroke: color,
      strokeWidth: '1.5'
    })
  );
};

MegaPhoneV2.propTypes = propTypes;

export default MegaPhoneV2;