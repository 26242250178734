import React from 'react';
import { IconProps } from '../icon.types';

export const Search = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_639_524)">
        <path d="M2.39014 8.44629C2.39014 12.0986 5.36182 15.0703 9.01416 15.0703C10.4585 15.0703 11.7783 14.6055 12.8657 13.8252L16.9497 17.9175C17.1406 18.1084 17.3896 18.1997 17.6553 18.1997C18.2197 18.1997 18.6099 17.7764 18.6099 17.2202C18.6099 16.9546 18.5103 16.7139 18.3359 16.5396L14.2769 12.4556C15.1318 11.3433 15.6382 9.95703 15.6382 8.44629C15.6382 4.79395 12.6665 1.82227 9.01416 1.82227C5.36182 1.82227 2.39014 4.79395 2.39014 8.44629ZM3.80957 8.44629C3.80957 5.57422 6.14209 3.2417 9.01416 3.2417C11.8862 3.2417 14.2188 5.57422 14.2188 8.44629C14.2188 11.3184 11.8862 13.6509 9.01416 13.6509C6.14209 13.6509 3.80957 11.3184 3.80957 8.44629Z" />
      </g>
      <defs>
        <clipPath id="clip0_639_524">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
