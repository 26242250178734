import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Graph = function Graph(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Graph__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1000,928.6v71.4H0V0h71.4v928.6H1000z M361.9,474.1l214.5,143L1000,193.5v-101L567.4,525.1l-214.5-143l-210,210v86.2l14.9,0L361.9,474.1z' // eslint-disable-line max-len
    })
  );
};

Graph.propTypes = propTypes;

export default Graph;