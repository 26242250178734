/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Circle = function Circle(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon',
      viewBox: '0 0 100 100',
      id: 'Circle__icon'
    },
    React.createElement('circle', { cx: '50', cy: '50', r: '50' })
  );
};

Circle.propTypes = propTypes;

export default Circle;