import _slicedToArray from 'babel-runtime/helpers/slicedToArray';
import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../Button/Button';
import { tooltipPlacements } from './DynamicTooltipV2.constants';
import Icon from '../Icon/Icon';

var propTypes = {
  allowContainerOverflow: PropTypes.bool,
  triggerTooltipComponent: PropTypes.node.isRequired,
  type: PropTypes.string,
  buttonColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disableFocusListener: PropTypes.bool,
  disableHoverListener: PropTypes.bool,
  disableTouchListener: PropTypes.bool,
  isInteractive: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  openOnClick: PropTypes.bool,
  placement: PropTypes.string,
  showCloseButton: PropTypes.bool,
  ariaLabel: PropTypes.string
};

var propDescriptions = {
  allowContainerOverflow: 'This render with fixed position that can allow the tooltip to overflow outside of the container it is in',
  triggerTooltipComponent: 'This is the element that triggeres the tooltip',
  children: 'The content to be rendered in the tooltip',
  type: 'Type of tooltip styles. options: "dark", "light"',
  buttonColor: '(only for click tooltips) specifies the color prop of the tooltip trigger',
  className: 'classnames to add to the root tooltip node',
  disableFocusListener: 'disable the tooltip trigger on focus',
  disableHoverListener: 'disable the tooltip trigger on hover',
  disableTouchListener: 'disable the tooltip trigger on touch',
  isInteractive: 'if this is enabled the tooltip will remain open when hovered',
  openOnClick: 'Open the tooltip when the child content is clicked. Overrides focus, touch and hover listeners',
  placement: 'where to place the tooltip relative to the child content'

};

var defaultProps = {
  type: 'dark',
  buttonColor: 'blue',
  placement: 'top'
};

/*
This component requires access to a ref to spread event handlers
to the child component. Class components need to spread ...rest
props on the top level element to handle hover/focus/touch events.
Functional components need to be wrapped in React.forwardRef in order
to handle a ref and the event props.
see https://material-ui.com/components/tooltips/#custom-child-element
& https://material-ui.com/guides/composition/#caveat-with-refs
for more information
*/

var Title = function Title(_ref) {
  var children = _ref.children;

  return React.createElement(
    'div',
    { className: 'cr-tooltipV2__title' },
    children
  );
};

var List = function List(_ref2) {
  var children = _ref2.children,
      title = _ref2.title;

  return React.createElement(
    'div',
    { className: 'cr-tooltipV2__list' },
    title && React.createElement(
      'div',
      { className: 'cr-tooltipV2__list__title' },
      title
    ),
    React.createElement(
      'ul',
      { className: 'cr-tooltipV2__list__items' },
      children.map(function (l, index) {
        return React.createElement(
          'li',
          { key: index },
          l
        );
      })
    )
  );
};

var Link = function Link(_ref3) {
  var children = _ref3.children,
      href = _ref3.href;

  return React.createElement(
    'a',
    {
      className: 'cr-tooltipV2__link',
      href: href,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    children,
    React.createElement(Icon, {
      className: 'cr-tooltipV2__textWithLink__link__icon',
      type: 'external-link',
      color: 'blue',
      size: 12
    })
  );
};

var Description = function Description(_ref4) {
  var children = _ref4.children;

  return React.createElement(
    'div',
    { className: 'cr-tooltipV2__description' },
    children
  );
};

// TextWithLink will render a text block with a link appended to the end
var TextWithLink = function TextWithLink(_ref5) {
  var text = _ref5.text,
      linkText = _ref5.linkText,
      href = _ref5.href;

  return React.createElement(
    'span',
    { className: 'cr-tooltipV2__textWithLink' },
    text,
    React.createElement(
      'a',
      {
        className: 'cr-tooltipV2__textWithLink__link',
        href: href,
        target: '_blank',
        rel: 'noopener noreferrer'
      },
      linkText,
      React.createElement(Icon, {
        className: 'cr-tooltipV2__textWithLink__link__icon',
        type: 'external-link',
        color: 'blue',
        size: 12
      })
    )
  );
};

var buildPopperOptions = function buildPopperOptions(allowContainerOverflow) {
  var popperOptions = {
    positionFixed: true
  };

  if (allowContainerOverflow) {
    popperOptions.modifiers = {
      preventOverflow: {
        enabled: true,
        boundariesElement: 'window' // where "window" is the boundary
      }
    };
  }

  return popperOptions;
};

var DynamicTooltipV2 = function DynamicTooltipV2(props) {
  var type = props.type,
      buttonColor = props.buttonColor,
      children = props.children,
      className = props.className,
      disableFocusListener = props.disableFocusListener,
      disableHoverListener = props.disableHoverListener,
      disableTouchListener = props.disableTouchListener,
      isInteractive = props.isInteractive,
      openOnClick = props.openOnClick,
      placement = props.placement,
      showCloseButton = props.showCloseButton,
      ariaLabel = props.ariaLabel,
      triggerTooltipComponent = props.triggerTooltipComponent,
      allowContainerOverflow = props.allowContainerOverflow;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isOpen = _useState2[0],
      setIsOpen = _useState2[1];

  var handleTooltipClose = function handleTooltipClose() {
    var onClose = props.onClose;

    if (onClose) onClose();
    setIsOpen(false);
  };

  var handleTooltipOpen = function handleTooltipOpen() {
    var onOpen = props.onOpen;

    if (onOpen) onOpen();
    setIsOpen(true);
  };

  var tooltipPopperClasses = classNames('cr-tooltipV2', className, {
    'cr-tooltipV2--light': type === 'light'
  });
  var tooltipClasses = classNames('cr-tooltipV2__tooltip', 'cr-tooltipV2__caret--' + placement, {
    'cr-tooltipV2__tooltip--hasClose': showCloseButton
  });
  if (process.env.NODE_ENV === 'development' && tooltipPlacements.indexOf(placement) === -1) {
    console.warn('Invalid flock tooltip placement: ' + placement);
    return null;
  }
  var childrenWithProps = React.isValidElement(children) ? React.Children.map(children, function (child) {
    return React.cloneElement(child, { handleTooltipClose: handleTooltipClose });
  }) : children;

  var tooltipContent = showCloseButton ? React.createElement(
    'div',
    { className: 'cr-tooltipV2__content' },
    React.createElement(
      Button,
      {
        buttonStyle: 'link',
        className: 'cr-tooltipV2__close',
        color: type === 'light' ? 'grey' : 'white',
        onClick: handleTooltipClose,
        iconPosition: 'center'
      },
      React.createElement(Icon, { type: 'close', size: 12 })
    ),
    childrenWithProps
  ) : childrenWithProps;

  return openOnClick ? React.createElement(
    ClickAwayListener,
    { onClickAway: handleTooltipClose },
    React.createElement(
      'div',
      { className: 'cr-tooltipV2__buttonWrapper' },
      React.createElement(
        Tooltip,
        {
          classes: {
            popper: tooltipPopperClasses,
            tooltip: tooltipClasses
          },
          disableFocusListener: true,
          disableHoverListener: true,
          disableTouchListener: true,
          PopperProps: {
            disablePortal: true,
            popperOptions: buildPopperOptions(allowContainerOverflow)
          },
          interactive: showCloseButton || isInteractive,
          onClose: handleTooltipClose,
          onOpen: handleTooltipOpen,
          placement: placement,
          title: tooltipContent,
          open: isOpen
        },
        React.createElement(
          Button,
          {
            ariaLabel: ariaLabel,
            buttonStyle: 'link',
            color: buttonColor,
            onClick: isOpen ? handleTooltipClose : handleTooltipOpen
          },
          triggerTooltipComponent
        )
      )
    )
  ) : React.createElement(
    Tooltip,
    {
      classes: {
        popper: tooltipPopperClasses,
        tooltip: tooltipClasses
      },
      disableFocusListener: disableFocusListener,
      disableHoverListener: disableHoverListener,
      disableTouchListener: disableTouchListener,
      interactive: showCloseButton || isInteractive,
      onClose: handleTooltipClose,
      onOpen: handleTooltipOpen,
      placement: placement,
      title: tooltipContent,
      open: isOpen
    },
    triggerTooltipComponent
  );
};

DynamicTooltipV2.propTypes = propTypes;
DynamicTooltipV2.propDescriptions = propDescriptions;
DynamicTooltipV2.defaultProps = defaultProps;

DynamicTooltipV2.Title = Title;
DynamicTooltipV2.Description = Description;
DynamicTooltipV2.Link = Link;
DynamicTooltipV2.List = List;
DynamicTooltipV2.TextWithLink = TextWithLink;

export default DynamicTooltipV2;

export { Title, Description, Link, List, TextWithLink };