import React, { useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import clsx from 'clsx';
import { DetailedStory } from '../detailed_story.component';
import CloseIcon from '../../../../assets/close-icon.svg';
import DragIcon from '../../../../assets/drag-icon.svg';
import './detailed_story_sortable.scss';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { DetailedStorySortableProps } from './detailed_story_sortable.types';

const DetailedStorySortableComponent = (props: DetailedStorySortableProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.story.uuid });
  const [isStatOpen, setIsStatOpen] = useState(false);
  const { translate } = props;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const getTranslation = useTranslateStrings(translate);

  return (
    <li
      className={clsx('detailed_story__list_item', {
        'detailed_story__list_item--menu_open': isStatOpen,
      })}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className="detailed_story--static_wrapper">
        <button
          {...listeners}
          className="detailed_story--static__btn--drag"
          aria-label={getTranslation('stories.storyCard.drag')}
          type="button"
        >
          <DragIcon className="navbar__drag--icon" />
        </button>
        <button
          className="detailed_story--static__btn--delete"
          aria-label={getTranslation('stories.storyCard.close')}
          onClick={() => {
            props.onRemove(props.story.uuid);
          }}
          type="button"
        >
          <CloseIcon className="navbar__close--icon" />
        </button>
      </div>
      <DetailedStory
        {...props}
        includeDetailsContextMenu={false}
        onStatOpen={() => setIsStatOpen(true)}
        onStatClose={() => setIsStatOpen(false)}
      />
    </li>
  );
};

export const DetailedStorySortable = withLocalize(
  DetailedStorySortableComponent,
);
