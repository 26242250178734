import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  color: PropTypes.string
};
var LineDashed = function LineDashed(_ref) {
  var color = _ref.color;

  return React.createElement(
    'svg',
    { width: '24', height: '2', viewBox: '0 0 24 2', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M4.5 2H0V0H4.5V2ZM16.5 2H7.5V0H16.5V2ZM24 2H19.5V0H24V2Z',
      fill: color
    }),
    's'
  );
};

LineDashed.propTypes = propTypes;
export default LineDashed;