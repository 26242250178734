import React, { useEffect } from 'react';
import { DynamicTooltip } from '@crowdriff/flock/lib/DynamicTooltip';
import { Icon } from '@crowdriff/flock/lib/Icon';
import { Translate } from 'react-localize-redux';
import './stories_search_filters.scss';
import { useDispatch } from 'react-redux';
import { getGlobalTags } from '../../../http/global_tags_list';
import { getNetworkTags } from '../../../http/network_tags_list';
import { StoriesSearchForm } from '../stories_search_form.component/stories_search_form.connect';
import { Button, BUTTON_VARIANT } from '../../blocks/button';
import {
  storiesClearSearchFiltersAction,
  storiesSearchQuery,
} from '../../../redux/stories/stories.actions';
import {
  getStoriesSearch,
  STORY_SEARCH_LOAD_TYPES,
} from '../../../http/stories_search.thunks';
import { getNetworkPublishersList } from '../../../http/network_publishers_list.thunks';
import { storiesSearchStateToFilterParams } from '../stories_search_form.component/stories_search_form.utils';
import { StoriesFilterTab } from '../../../redux/stories/stories.types';

interface StoriesSearchFiltersComponentProps {
  filterCount: number;
  showClearFilterButtons: boolean;
  queryValue: string;
  selectedFilterTab: StoriesFilterTab;
  isNetworkOwner: boolean;
}
export const StoriesSearchFiltersComponent = ({
  filterCount,
  showClearFilterButtons,
  queryValue,
  selectedFilterTab,
  isNetworkOwner,
}: StoriesSearchFiltersComponentProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGlobalTags());
    dispatch(getNetworkTags());
    if (isNetworkOwner) {
      dispatch(getNetworkPublishersList);
    }
  }, [dispatch]);

  return (
    <div className="stories_search_filters">
      <DynamicTooltip
        content={<StoriesSearchForm />}
        openOnClick={true}
        placement="bottom-start"
      >
        <Icon size={12} type="filter" />
        <p>
          {filterCount === 0 ? (
            <Translate id="stories.search.filter" />
          ) : (
            <Translate
              id="stories.search.filtersApplied"
              data={{
                count: filterCount,
                plural: filterCount > 1 ? 's' : '',
              }}
            />
          )}
        </p>
      </DynamicTooltip>
      {showClearFilterButtons && (
        <Button
          onClick={() => {
            dispatch(storiesClearSearchFiltersAction());
            dispatch(storiesSearchQuery(queryValue));
            // Build params with empty filter selection data
            const searchStateFiltersParams = storiesSearchStateToFilterParams(
              queryValue,
              undefined,
              selectedFilterTab,
            );
            dispatch(
              getStoriesSearch({
                searchFilters: searchStateFiltersParams,
                pagination: { pageSize: 20 },
                loadingType: STORY_SEARCH_LOAD_TYPES.SEARCH,
              }),
            );
          }}
          variant={BUTTON_VARIANT.GHOST}
        >
          <Translate id="stories.search.clearFilters" />
        </Button>
      )}
    </div>
  );
};

StoriesSearchFiltersComponent.displayName = 'StoriesSearchFilters';
