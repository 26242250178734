import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

var propDescriptions = {
  onChange: 'Callback called when the value changes, with the value as first arg',
  value: 'Boolean value of the toggle.',
  id: 'HTML id to link label & input',
  label: 'Optional label above the toggle',
  marker: 'Optional text to appear next to the toggle'
};

var propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  marker: PropTypes.string
};

var defaultProps = {
  label: '',
  marker: ''
};

var Toggle = function (_React$Component) {
  _inherits(Toggle, _React$Component);

  function Toggle(props) {
    _classCallCheck(this, Toggle);

    var _this = _possibleConstructorReturn(this, (Toggle.__proto__ || _Object$getPrototypeOf(Toggle)).call(this, props));

    _this.handleChange = _this.handleChange.bind(_this);
    return _this;
  }

  _createClass(Toggle, [{
    key: 'handleChange',
    value: function handleChange(e) {
      this.props.onChange(e.target.checked);
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          value = _props.value,
          id = _props.id,
          label = _props.label,
          marker = _props.marker;

      return React.createElement(
        'div',
        { className: 'toggle__container' },
        label ? React.createElement(
          'p',
          { className: 'toggle__label' },
          label
        ) : null,
        React.createElement(
          'div',
          { className: 'toggle__wrapper' },
          React.createElement('input', {
            type: 'checkbox',
            onChange: this.handleChange,
            id: id,
            className: 'toggle__input',
            checked: this.props.value
          }),
          React.createElement(
            'label',
            {
              htmlFor: id,
              className: classNames('toggle', { 'toggle--checked': value }),
              'data-pendo': value ? id + '-valueTrue' : id + '-valueFalse'
            },
            value && React.createElement(Icon, {
              type: 'check',
              color: 'blue',
              className: classNames('toggle__icon', { 'toggle__icon--checked': value })
            })
          ),
          marker ? React.createElement(
            'span',
            { className: 'toggle__marker' },
            marker
          ) : null
        )
      );
    }
  }]);

  return Toggle;
}(React.Component);

export default Toggle;


Toggle.propTypes = propTypes;
Toggle.defaultProps = defaultProps;
Toggle.propDescriptions = propDescriptions;