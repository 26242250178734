import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Gear = function Gear(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'gear',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1000,429v144.5c0,5.2-1.7,10.2-5.2,15c-3.5,4.8-7.8,7.6-13,8.4l-120.5,18.2c-8.3,23.5-16.7,43.2-25.4,59.2 c15.2,21.7,38.4,51.6,69.7,89.9c4.3,5.2,6.5,10.6,6.5,16.3c0,5.6-2,10.6-5.9,14.9c-11.7,16.1-33.2,39.5-64.4,70.3 c-31.3,30.8-51.6,46.2-61.2,46.2c-5.2,0-10.9-1.9-16.9-5.9l-89.8-70.3c-19.1,10-38.8,18.2-59.2,24.7c-6.9,59-13.2,99.4-18.9,121.1 c-3,12.2-10.9,18.2-23.4,18.2H427.7c-6.1,0-11.4-1.8-16-5.5c-4.6-3.7-7.1-8.4-7.5-14l-18.2-119.8c-21.3-7-40.8-15-58.6-24.1 l-91.8,69.7c-4.4,3.9-9.8,5.9-16.3,5.9c-6.1,0-11.5-2.4-16.3-7.2c-54.7-49.4-90.5-85.9-107.4-109.4c-3-4.3-4.6-9.3-4.6-14.9 c0-5.2,1.7-10.2,5.2-15c6.5-9.1,17.6-23.5,33.2-43.3c15.6-19.7,27.3-35,35.2-45.9C153,654.7,144.1,633.3,138,612L18.9,594.4 c-5.6-0.9-10.2-3.6-13.7-8.1C1.7,581.7,0,576.6,0,571V426.4c0-5.2,1.7-10.2,5.2-14.9c3.5-4.8,7.6-7.6,12.4-8.5l121.1-18.2 c6.1-19.9,14.5-39.9,25.4-59.9c-17.4-24.7-40.6-54.7-69.7-89.9c-4.4-5.2-6.5-10.4-6.5-15.6c0-4.3,2-9.3,5.9-15 c11.3-15.6,32.6-39,64.1-70c31.5-31,52-46.5,61.5-46.5c5.6,0,11.3,2.2,16.9,6.5l89.8,69.6c19.1-10,38.8-18.2,59.2-24.7 c6.9-59,13.2-99.4,18.9-121.1C407.3,6.1,415.1,0,427.7,0h144.5c6.1,0,11.4,1.9,16,5.6c4.6,3.7,7,8.4,7.5,14l18.2,119.8 c21.3,7,40.8,15,58.6,24.1L765,93.7c3.9-3.9,9.1-5.8,15.6-5.8c5.6,0,11.1,2.2,16.3,6.5c56,51.6,91.8,88.5,107.4,110.6 c3,3.5,4.6,8.3,4.6,14.3c0,5.2-1.7,10.2-5.2,15c-6.5,9.1-17.6,23.5-33.2,43.3c-15.6,19.7-27.3,35.1-35.2,45.9 c11.3,21.7,20.2,43,26.7,63.8l119.1,18.2c5.6,0.9,10.2,3.6,13.7,8.2C998.3,418.3,1000,423.4,1000,429z M617.8,617.8 c32.5-32.5,48.8-71.8,48.8-117.8c0-46-16.3-85.3-48.8-117.9c-32.6-32.5-71.8-48.8-117.8-48.8c-46,0-85.3,16.3-117.8,48.8 S333.3,454,333.3,500c0,46,16.3,85.3,48.8,117.8c32.5,32.6,71.8,48.9,117.8,48.9C546,666.7,585.3,650.4,617.8,617.8z' // eslint-disable-line max-len
    })
  );
};

Gear.propTypes = propTypes;

export default Gear;