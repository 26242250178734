{
  "copyFailed": "Unable to copy",
  "navigation": {
    "createGalleries": "Create Gallery",
    "editGalleries": "Edit Gallery",
    "galleries": "Galleries",
    "logoFor": "Logo for",
    "logout": "Log out",
    "manageStories": "Manage Story",
    "network": "Network",
    "reports": "Reporting",
    "stories": "Stories",
    "support": "Support",
    "supportLabel": "Support. Opens in a new tab.",
    "search": "Search creators",
    "accountSettings": "Account Settings"
  },
  "snackbar": {
    "close": "Close Alert",
    "defaultSuccessTitle": "Success!",
    "defaultErrorTitle": "Uh-oh, something went wrong.",
    "defaultWarningTitle": "Alert!",
    "defaultInfoTitle": "Hey there!"
  },
  "loadingGif": {
    "loading": "Please wait, page is loading"
  },
  "localHood": "Localhood",
  "crowdRiffAdmin": "CrowdRiff Admin",
  "manual": "Manual",
  "automatic": "Automatic",
  "buttons": {
    "close": "Close",
    "back": "Back"
  },
  "search": {
    "emptyState": {
      "title": "No results found",
      "description": "Try adjusting your search or filter to find what you’re looking for.",
      "alt": "Signpost"
    }
  },
  "error": {
    "title": "You don’t have access to this Localhood account.",
    "body": "Please contact support to request user access.",
    "cta": "Contact Support"
  }
}
