import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var DesktopColumn = function DesktopColumn(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'DesktopColumn__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1282 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M692.2,230.9V0H987v230.9H692.2z M640.9,256.4V0H384.6v256.4H640.9z M333.3,307.6V0H0v307.6H333.3z M987,718V282.2H692.2\tV718H987z M987,1000V769.3H692.2V1000H987z M640.9,461.5V307.7H384.6v153.8H640.9z M333.3,589.7V358.9H0v230.7H333.3z M640.9,846\tV512.8H384.6V846H640.9z M1281.8,333.3V0h-243.4v333.3H1281.8z M1281.8,564V384.6h-243.4V564H1281.8z M1281.8,871.7V615.3h-243.4v256.4H1281.8z M333.3,948.5V640.9H0v307.6H333.3z' // eslint-disable-line max-len
    })
  );
};

DesktopColumn.propTypes = propTypes;

export default DesktopColumn;