import React from 'react';
import { Translate } from 'react-localize-redux';
import './admin_form.scss';
import { Link } from 'react-router-dom';
import { Button, BUTTON_TYPES } from '../../blocks/button';

// -----NOTE-----
// This component is largely just a placeholder (as you'll see by the placeholder() function
// and the link to nowhere on line 25). When it comes time to make the admin page functional,
// give this component a once-over and adjust the structure and styling if necessary.

function placeholder(): void {
  throw new Error('Function not implemented.');
}

export const AdminForm = () => {
  return (
    <div className="admin_form">
      <div className="admin_form__container">
        <div className="admin_form__add_partner">
          <h4>
            <Translate id="admin.form.addPartner" />
          </h4>
          <p>
            {/* TO-DO: ADD LINK! */}
            <Link to="???">
              <Translate id="admin.form.downloadCSVTemplate" />
            </Link>
          </p>
          <p>
            <Translate id="admin.form.supportedFormats" />
          </p>
          <Button onClick={placeholder}>
            <Translate id="admin.form.button.uploadCSV" />
          </Button>
        </div>
        <div className="admin_form__create_partner_button">
          <Button type={BUTTON_TYPES.SUBMIT}>
            <Translate id="admin.form.button.createPartner" />
          </Button>
        </div>
      </div>
    </div>
  );
};

AdminForm.displayName = 'AdminForm';
