import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Filter = function Filter(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'feed',
      width: size,
      height: size,
      viewBox: '0 0 14 14',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon',
      'aria-hidden': true
    },
    React.createElement('path', { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M1.5 14V0H2.5V14H1.5Z', fill: color }),
    React.createElement('path', { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M6.5 14V0H7.5V14H6.5Z', fill: color }),
    React.createElement('path', { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M11.5 14V0H12.5V14H11.5Z', fill: color }),
    React.createElement('circle', { cx: '2', cy: '5', r: '2', fill: color }),
    React.createElement('circle', { cx: '7', cy: '10', r: '2', fill: color }),
    React.createElement('circle', { cx: '12', cy: '5', r: '2', fill: color })
  );
};

Filter.propTypes = propTypes;

export default Filter;