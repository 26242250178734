import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var ExternalLink = function ExternalLink(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'external_link',
      height: size,
      viewBox: '0 0 1000 1000',
      fill: color,
      'aria-hidden': true,
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M953.579 0H633.792c-25.641 0-46.421 20.698-46.421 46.237 0 25.54 20.78 46.238 46.421 46.238h212.875L391.107 546.23c-18.138 18.055-18.138 47.337 0 65.392 9.064 9.027 20.947 13.547 32.82 13.547 11.873 0 23.757-4.52 32.821-13.547l450.409-448.638v201.761c0 25.54 20.781 46.237 46.422 46.237 25.64 0 46.421-20.697 46.421-46.237V46.237C1000 20.698 979.219 0 953.579 0z' // eslint-disable-line max-len
    }),
    React.createElement('path', {
      d: 'M793.016 465.673c-25.641 0-46.421 20.698-46.421 46.237v364.791c0 16.711-14.169 30.825-30.947 30.825H123.79c-16.778 0-30.948-14.114-30.948-30.825V287.186c0-16.711 14.17-30.825 30.948-30.825h367.534c25.64 0 46.421-20.698 46.421-46.237s-20.781-46.237-46.421-46.237H123.79C55.53 163.887 0 219.196 0 287.186v589.515C0 944.691 55.53 1000 123.79 1000h591.858c68.26 0 123.789-55.309 123.789-123.299V511.91c0-25.528-20.78-46.237-46.421-46.237z' // eslint-disable-line max-len
    })
  );
};

ExternalLink.propTypes = propTypes;

export default ExternalLink;