import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Volume = function Volume(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Volume__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1157.9 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M631.6,947.4c0,28.8-23.8,52.6-52.6,52.6c-14,0-27.1-5.8-37-15.6L268.1,710.5H52.6C23.8,710.5,0,686.7,0,657.9V342.1c0-28.8,23.8-52.6,52.6-52.6h215.5L541.9,15.6c9.9-9.9,23-15.6,37-15.6c28.8,0,52.6,23.8,52.6,52.6V947.4z M819.9,693.3\t\tc-6.6,3.3-14,4.1-20.6,4.1c-28.8,0-52.6-23-52.6-52.6c0-62.5,95.4-45.2,95.4-144.7s-95.4-82.2-95.4-144.7\t\tc0-29.6,23.8-52.6,52.6-52.6c6.6,0,14,0.8,20.6,4.1c77.3,30.4,127.5,111,127.5,193.3S897.2,662,819.9,693.3z M903,887.3\t\tc-6.6,2.5-14,4.1-20.6,4.1c-29.6,0-53.5-23.8-53.5-52.6c0-23,13.2-38.7,32.1-48.5c22.2-11.5,42.8-21.4,62.5-36.2\t\tc81.4-59.2,129.1-153.8,129.1-254.1s-47.7-194.9-129.1-254.1c-19.7-14.8-40.3-24.7-62.5-36.2c-18.9-9.9-32.1-25.5-32.1-48.5\tc0-28.8,23.8-52.6,52.6-52.6c7.4,0,14.8,1.6,21.4,4.1c154.6,65,254.9,220.4,254.9,387.3S1057.6,822.4,903,887.3z' // eslint-disable-line max-len
    })
  );
};

Volume.propTypes = propTypes;

export default Volume;