import { connect } from 'react-redux';
import { getGallery } from '../../../http/gallery_get.thunk';
import { GalleryEditPage } from './gallery_edit.component';
import { AppThunkDispatch } from '../../../types';

export const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getGallery: (id: string) => dispatch(getGallery(id)),
});

export const GalleryEditPageConnect = connect(
  null,
  mapDispatchToProps,
)(GalleryEditPage);
