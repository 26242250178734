// hyphens are allowed.
/* eslint-disable quote-props */
export var legacyMap = {
  'advanced-rights': 'rights-advanced',
  'arrow-thin': 'arrow-left',
  'circle-close': 'close-circle',
  'connect': 'share',
  'cta': 'megaphone',
  'edit-photo': 'paint-roller',
  'external': 'arrow-diagonal',
  'fullscreen-base': 'gallery-fullscreen-base',
  'fullscreen-detail': 'gallery-fullscreen-detail',
  'landscape': 'photo-landscape',
  'large-outline': 'photo-large',
  'logo': 'cr-logo',
  'medium-outline': 'photo-medium',
  'open-link': 'arrow-diagonal',
  'person-in-circle': 'person-circle',
  'person-reverse': 'person',
  'photo-rectangle-broken': 'photo-rectangle-broken-border',
  'photo-wall-screen': 'photo-wall-grid',
  'picker': 'cursor-click',
  'pop-out': 'logout',
  'portrait': 'photo-portrait',
  'settings': 'gears',
  'shield': 'rights-hashtag',
  'small-outline': 'photo-small',
  'square': 'photo-square',
  'tags': 'keywords',
  'team': 'people'
};

export var iconMap = {
  'album': 'Album',
  'albums': 'Albums',
  'angle-down': 'AngleDown',
  'angle-down-double': 'AngleDownDouble',
  'angle-left': 'AngleLeft',
  'angle-left-double': 'AngleLeftDouble',
  'angle-right': 'AngleRight',
  'angle-right-double': 'AngleRightDouble',
  'angle-up': 'AngleUp',
  'angle-up-double': 'AngleUpDouble',
  'app': 'App',
  'apps': 'App',
  'approve': 'Approve',
  'arrow-chunky': 'ArrowChunky',
  'arrow-diagonal': 'ArrowDiagonal',
  'arrow-down-to-line': 'ArrowDownToLine',
  'arrow-left': 'ArrowLeft',
  'arrow-up-from-line': 'ArrowUpFromLine',
  'arrow-up-right-arrow-down-left': 'ArrowUpRightArrowDownLeft',
  'at': 'At',
  'at-V2': 'AtV2',
  'ban': 'Ban',
  'box-stack': 'BoxStack',
  'briefcase': 'Briefcase',
  'buffer': 'Buffer',
  'camera': 'Camera',
  'camera-outline': 'CameraOutline',
  'camera-plus': 'CameraPlus',
  'check': 'Check',
  'check-v2': 'CheckV2',
  'circle': 'Circle',
  'circle-arrow-up-from-line': 'CircleArrowUpFromLine',
  'circle-overlap': 'CircleOverlap',
  'clock': 'Clock',
  'clock-outline': 'ClockOutline',
  'clock-thin': 'ClockThin',
  'close': 'Close',
  'close-circle': 'CloseCircle',
  'code': 'Code',
  'collector': 'Collector',
  'conversations': 'Conversations',
  'copy': 'Copy',
  'copy-v2': 'CopyV2',
  'cr-logo': 'CrLogo',
  'cursor-click': 'CursorClick',
  'desktop-column': 'DesktopColumn',
  'desktop-grid': 'DesktopGrid',
  'dollar-sign': 'DollarSign',
  'dollar-sign-new': 'DollarSignNew',
  'download': 'Download',
  'eye': 'Eye',
  'edit': 'Edit',
  'ellipsis': 'Ellipsis',
  'envelope': 'Envelope',
  'exclamation': 'Exclamation',
  'expand': 'Expand',
  'external-link': 'ExternalLink',
  'eye-empty': 'EyeEmpty',
  'facebook': 'Facebook',
  'facebook-v2': 'FacebookV2',
  'facebook-comment': 'FacebookComment',
  'facebook-like': 'FacebookLike',
  'facebook-share': 'FacebookShare',
  'feed': 'Feed',
  'filter': 'Filter',
  'flag': 'Flag',
  'folder': 'Folder',
  'folder-open': 'FolderOpen',
  'followed-accounts': 'FollowedAccounts',
  'fullscreen': 'Fullscreen',
  'gallery': 'Gallery',
  'gallery-fullscreen-base': 'GalleryFullscreenBase',
  'gallery-fullscreen-detail': 'GalleryFullscreenDetail',
  'gear': 'Gear',
  'gears': 'Gears',
  'google': 'Google',
  'googleG': 'GoogleG',
  'graph': 'Graph',
  'grid-add': 'GridAdd',
  'hashtag': 'Hashtag',
  'historic-shield': 'HistoricShield',
  'home': 'Home',
  'home-alt': 'HomeAlt',
  'info': 'Info',
  'info-i': 'InfoI',
  'instagram': 'Instagram',
  'instagram-v2': 'InstagramV2',
  'instagram-comment': 'InstagramComment',
  'instagram-like': 'InstagramLike',
  'journal-flipped': 'JournalFlipped',
  'keywords': 'Keywords',
  'layout': 'Layout',
  'light-bulb-on': 'LightBulbOn',
  'line-dashed': 'LineDashed',
  'line-solid': 'LineSolid',
  'link': 'Link',
  'location': 'Location',
  'lock': 'Lock',
  'login': 'Login',
  'logout': 'Logout',
  'media-image': 'MediaImage',
  'media-image-landscape': 'MediaImageLandscape',
  'media-image-list': 'MediaImageList',
  'media-image-portrait': 'MediaImagePortrait',
  'media-reels': 'MediaReels',
  'megaphone': 'Megaphone',
  'megaphone-outline': 'MegaphoneOutline',
  'megaphone-v2': 'MegaPhoneV2',
  'menu': 'Menu',
  'menu-v2': 'MenuV2',
  'minus': 'Minus',
  'mobile-column': 'MobileColumn',
  'mobile-grid': 'MobileGrid',
  'network': 'Network',
  'network-alt': 'NetworkAlt',
  'open-new-window': 'OpenNewWindow',
  'outbound-arrow': 'OutboundArrow',
  'pause': 'Pause',
  'pause-fill': 'PauseFill',
  'paint-brush': 'PaintBrush',
  'paint-roller': 'PaintRoller',
  'people': 'People',
  'person': 'Person', // icon has recently switched
  'person-circle': 'PersonCircle',
  'person-cutout': 'PersonCutout',
  'photo': 'Photo',
  'photos': 'Photos',
  'photo-landscape': 'PhotoLandscape',
  'photo-large': 'PhotoLarge',
  'photo-medium': 'PhotoMedium',
  'photo-portrait': 'PhotoPortrait',
  'photo-rectangle': 'PhotoRectangle',
  'photo-rectangle-bent-corner': 'PhotoRectangleBentCorner',
  'photo-rectangle-broken-border': 'PhotoRectangleBrokenBorder',
  'photo-small': 'PhotoSmall',
  'photo-square': 'PhotoSquare',
  'photo-wall': 'PhotoWall',
  'photo-wall-grid': 'PhotoWallGrid',
  'play': 'Play',
  'play-button': 'PlayButton',
  'play-button-stack': 'PlayButtonStack',
  'play-fill': 'PlayFill',
  'playlist': 'Playlist',
  'plus': 'Plus',
  'point-left': 'PointLeft',
  'public': 'Public',
  'pulse-circle': 'PulseCircle',
  'pulse': 'Pulse',
  'question-mark': 'QuestionMark',
  'question-mark-circle': 'QuestionMarkCircle',
  'question-mark-circle-v2': 'QuestionMarkCircleV2',
  'quote-left': 'QuoteLeft',
  'quote-right': 'QuoteRight',
  'reload': 'Reload',
  'reports': 'Reports',
  'return': 'Return',
  'rights-advanced': 'RightsAdvanced',
  'rights-expired': 'RightsExpired',
  'rights-external': 'RightsExternal',
  'rights-manual': 'RightsManual',
  'rights-failed': 'RightsFailed',
  'rights-hashtag': 'RightsHashtag',
  'rights-sent': 'RightsSent',
  'save-floppy-disk': 'SaveFloppyDisk',
  'screen': 'Screen',
  'search': 'Search',
  'share': 'Share',
  'share-gallery': 'ShareGallery',
  'shield-check': 'ShieldCheck',
  'shield-hashtag': 'ShieldHashtag',
  'shield-plus': 'ShieldPlus',
  'sidekick': 'Sidekick',
  'sidekick-box': 'SidekickBox',
  'similar-photos': 'SimilarPhotos',
  'slideshow': 'Slideshow',
  'speech-bubble': 'SpeechBubble',
  'speaker-xmark-fill': 'SpeakerXmarkFill',
  'speaker-2-wave-fill': 'Speaker2WaveFill',
  'square-arrow-up-right': 'SquareArrowUpRight',
  'square-photo-collage': 'SquarePhotoCollage',
  'square-photo-collage-link': 'SquarePhotoCollageLink',
  'star': 'Star',
  'star-outline': 'StarOutline',
  'star-bubble': 'StarBubble',
  'story-network': 'StoryNetwork',
  'tag': 'Tag',
  'text-box': 'Textbox',
  'thumbtack': 'Thumbtack',
  'thumbs-up': 'ThumbsUp',
  'thumbs-up-v2': 'ThumbsUpV2',
  'trash': 'Trash',
  'trash-v2': 'TrashV2',
  'twitter': 'Twitter',
  'twitter-v2': 'TwitterV2',
  'twitter-like': 'TwitterLike',
  'twitter-reply': 'TwitterReply',
  'twitter-retweet': 'TwitterRetweet',
  'twitter-share': 'TwitterShare',
  'upload': 'Upload',
  'upload-circled': 'UploadCircled',
  'upload-v2': 'UploadV2',
  'user-scan': 'UserScan',
  'verified-badge': 'VerifiedBadge',
  'video': 'Video',
  'video-camera': 'VideoCamera',
  'volume': 'Volume',
  'volume-mute': 'VolumeMute',
  'warning': 'Warning'
};