import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var AngleRightDouble = function AngleRightDouble(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Anglerightdouble__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 968 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M573.1,523L106.3,990c-6,6-15.1,10-23.1,10s-17-4-23.1-10L10,939.9c-6-6-10-15-10-23c0-8,4-17,10-23.1L403.8,500L10,106.2c-6-6-10-15-10-23.1c0-8,4-17,10-23L60.1,10c6-6,15.1-10,23.1-10s17,4,23.1,10l466.9,467c6,6,10,15,10,23S579.2,517,573.1,523zM957.9,523L491,990c-6,6-15.1,10-23.1,10c-8,0-17-4-23.1-10l-50.1-50.1c-6-6-10-15-10-23c0-8,4-17,10-23.1L788.6,500L394.8,106.2c-6-6-10-15-10-23.1c0-8,4-17,10-23L444.9,10c6-6,15.1-10,23.1-10c8,0,17,4,23.1,10l466.9,467c6,6,10,15,10,23S964,517,957.9,523z' // eslint-disable-line max-len
    })
  );
};

AngleRightDouble.propTypes = propTypes;

export default AngleRightDouble;