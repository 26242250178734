import { GalleryPreviewResponse } from '../../../http/gallery_preview.thunks';

export enum GALLERY_PREVIEW_STORIES {
  MAX_MATCHED = 4,
}

export enum GALLERY_PREVIEW_TYPES {
  START = 'GALLERY_PREVIEW_TYPES/START',
  SUCCESS = 'GALLERY_PREVIEW_TYPES/SUCCESS',
  FAILURE = 'GALLERY_PREVIEW_TYPES/FAILURE',
}
export interface GalleryPreviewStartAction {
  type: GALLERY_PREVIEW_TYPES.START;
  payload: {};
}
export interface GalleryPreviewSuccessAction {
  type: GALLERY_PREVIEW_TYPES.SUCCESS;
  payload: GalleryPreviewResponse;
}

export interface GalleryPreviewFailureAction {
  type: GALLERY_PREVIEW_TYPES.FAILURE;
  payload: string;
}

export type GalleryPreviewActions =
  | GalleryPreviewStartAction
  | GalleryPreviewSuccessAction
  | GalleryPreviewFailureAction;
