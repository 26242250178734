import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
// react's useCallback does not provide any semantic guarantees that the memoized
// value will not change, this does. This package should be used sparingly though,
// there are often better ways to solve your problem than just memoizing things
// to prevent re-renders. The React useCallback is preferred when a semantic guarantee
// is not required.
import { useCallback } from 'use-memo-one';

import { ConnectedModal, ModalProps } from '../../blocks/connected_modal';
import { CallbackRef } from '../useCallbackRef';

export const ModalContext = React.createContext({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggle: () => {},
});

export interface UseModalProps {
  ref?: CallbackRef<HTMLElement>;
}

export type HookModal = FunctionComponent<ModalProps>;

export const useModal = (
  { ref }: UseModalProps = {},
): [HookModal, () => void, boolean] => {
  const navbar = document.getElementById('navigation');

  const [isOpen, setOpen] = useState(false);

  const handleOnEnter = useCallback(() => {
    if (!navbar) {
      return;
    }
    navbar.classList.add('no-overflow');
  }, [navbar]);

  const handleExit = useCallback(() => {
    if (!navbar) {
      return;
    }
    navbar.classList.remove('no-overflow');
  }, [navbar]);

  const toggle = useCallback(() => {
    handleExit();
    setOpen(!isOpen);
  }, [handleExit, setOpen, isOpen]);

  const Modal = useCallback(
    ({ children, ...otherProps }: PropsWithChildren<ModalProps>) => {
      return (
        <ModalContext.Provider value={{ isOpen, toggle }}>
          <ConnectedModal
            {...otherProps}
            onEnter={handleOnEnter}
            ref={ref}
          >
            {children}
          </ConnectedModal>
        </ModalContext.Provider>
      );
    },
    [isOpen, toggle, handleOnEnter],
  );
  return [Modal, toggle, isOpen];
};
