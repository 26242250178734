export const AVATAR_UPLOAD_REQ = {
  height: 400,
  width: 400,
  sizeBytes: 10000000,
};

export const FAVICON_UPLOAD_REQ = {
  height: 400,
  width: 400,
  sizeBytes: 10000000,
};

export const isDisplayNameEnabled = () =>
  process.env.ENABLE_ACCOUNT_SETTINGS_DISPLAY_NAME === 'true';

export const isDisplayAvatarUploadEnabled = () =>
  process.env.ENABLE_ACCOUNT_SETTINGS_AVATAR_UPLOAD === 'true';

export const isFaviconUploadEnabled = () =>
  process.env.ENABLE_ACCOUNT_SETTINGS_FAVICON_UPLOAD === 'true';
