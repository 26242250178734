import React, { useEffect, useState } from 'react';
import { Icon } from '@crowdriff/flock';
import { Translate, withLocalize } from 'react-localize-redux';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { ModalButtons, ModalContent, ModalTitle } from '../../../blocks/modal';
import { TEXT_VARIANTS, Text } from '../../../blocks/text';
import { Checkbox } from '../../../blocks/checkbox';
import {
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
  Button,
} from '../../../blocks/button';
import { StoryExportModalProps } from '.';
import './story_export_modal.scss';
import { RadioGroup } from '../../../blocks/radio_group';
import { DOWNLOAD_LOCATION_TYPES } from '../../../../redux/exports/exports.types';

export const StoryExportModalComponent = ({
  translate,
  Modal,
  story,
  isChallengeStory,
  loading,
  onConfirm,
  onClose,
  downloadLocation,
  onUpdateDownloadLocation,
}: StoryExportModalProps) => {
  const getTranslation = useTranslateStrings(translate);
  const [includeText, setIncludeText] = useState<boolean>(true);
  const [includeLocation, setIncludeLocation] = useState<boolean>(true);
  const [includeVisualCredit, setIncludeVisualCredit] = useState<boolean>(true);
  const [downloadIndividualPages, setDownloadIndividualPages] = useState<
    boolean
  >(false);
  const { uuid, title, image = undefined } = story;
  useEffect(() => {
    setIncludeLocation(includeText);
    setIncludeVisualCredit(includeText);
  }, [includeText]);

  const modalBodyContent = (
    <div className="story_export_modal__container">
      <div className="story_export_modal__poster-wrapper">
        {image?.url ? (
          <img
            src={image?.url}
            alt="Story thumbnail"
            className="story_export_modal__poster"
          />
        ) : (
          <Icon size={44} type="photo-rectangle" className="photo-empty" />
        )}
        {title && (
          <Text
            variant={TEXT_VARIANTS.B2}
            className="story_export_modal__poster-text"
          >
            {title}
          </Text>
        )}
      </div>
      <div>
        <div className="story_export_modal__radio-wrapper">
          <RadioGroup
            name="story_export_modal__radio-group"
            label={getTranslation(
              'stories.modal.storyExport.downloadLocationHeader',
            )}
            options={[
              {
                label: getTranslation(
                  'stories.modal.storyExport.locationThisDevice',
                ),
                value: DOWNLOAD_LOCATION_TYPES.CURRENT_DEVICE,
              },
              {
                label: getTranslation(
                  'stories.modal.storyExport.locationMobileDevice',
                ),
                value: DOWNLOAD_LOCATION_TYPES.EXTERNAL,
              },
            ]}
            value={downloadLocation}
            onChange={onUpdateDownloadLocation}
            labelAs={TEXT_VARIANTS.B22}
          />
        </div>
        <Text variant={TEXT_VARIANTS.B22}>
          <Translate id="stories.modal.storyExport.overlayHeader" />
        </Text>
        <div className="story_export_modal__checkbox-wrapper">
          <div className="story_export_modal__include-wrapper">
            <Checkbox
              id="story_export_modal__text"
              name="story_export_modal_text"
              isChecked={includeText}
              onChange={() => setIncludeText((prev) => !prev)}
              label={getTranslation('stories.modal.storyExport.detailsOverlay')}
            />
            {includeText && (
              <div>
                <Checkbox
                  id="story_export_modal__location"
                  name="story_export_modal_location"
                  isChecked={includeLocation}
                  className="story_export_modal__sub-include-checkbox"
                  onChange={() => setIncludeLocation((prev) => !prev)}
                  label={getTranslation(
                    'stories.modal.storyExport.detailsLocation',
                  )}
                />
                <Checkbox
                  id="story_export_modal__credit"
                  name="story_export_modal_credit"
                  isChecked={includeVisualCredit}
                  className="story_export_modal__sub-include-checkbox"
                  onChange={() => setIncludeVisualCredit((prev) => !prev)}
                  label={getTranslation(
                    'stories.modal.storyExport.detailsCredit',
                  )}
                />
              </div>
            )}
          </div>
          {downloadLocation === DOWNLOAD_LOCATION_TYPES.CURRENT_DEVICE && (
            <div>
              <Checkbox
                id="story_export_modal__individuals"
                name="story_export_modal_individuals"
                className="story_export_modal__individuals"
                isChecked={downloadIndividualPages}
                onChange={() => setDownloadIndividualPages((prev) => !prev)}
                label={getTranslation(
                  'stories.modal.storyExport.detailsIndividual',
                )}
              />
              {downloadIndividualPages && (
                <Text
                  variant={TEXT_VARIANTS.B23}
                  className="story_export_modal__individuals-alert"
                >
                  <Translate id="stories.modal.storyExport.detailsIndividualAlert" />
                </Text>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
  const modalButtons = (
    <>
      <Button
        variant={BUTTON_VARIANT.OUTLINE}
        color={BUTTON_COLOR_VARIANT.SECONDARY}
        onClick={onClose}
      >
        <Translate id="stories.modal.cancel" />
      </Button>
      <Button
        loading={loading}
        onClick={() => {
          onConfirm(
            {
              storyId: uuid,
              includeText,
              includeLocation,
              includeVisualCredit,
              isChallengeStory,
              source:
                downloadLocation === DOWNLOAD_LOCATION_TYPES.CURRENT_DEVICE
                  ? 'story-network-app'
                  : 'story-network-app-mobile',
            },
            downloadIndividualPages,
          );
        }}
      >
        <Translate id="stories.modal.storyExport.acceptAction" />
      </Button>
    </>
  );
  return (
    <Modal className="story_export_modal">
      <ModalTitle label={<Translate id="stories.modal.storyExport.title" />} />
      <ModalContent>{modalBodyContent}</ModalContent>
      <ModalButtons>{modalButtons}</ModalButtons>
    </Modal>
  );
};

export const StoryExportModal = withLocalize(StoryExportModalComponent);
