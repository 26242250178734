import React from 'react';
import { IconProps } from '../icon.types';

export const Speaker = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.42139 13.3853H4.80371C4.87842 13.3853 4.94482 13.4102 5.00293 13.46L8.74658 16.8135C9.14502 17.1704 9.45215 17.3364 9.85059 17.3364C10.3818 17.3364 10.7886 16.9463 10.7886 16.4067V3.67334C10.7886 3.13379 10.3818 2.69385 9.83398 2.69385C9.43555 2.69385 9.17822 2.87646 8.74658 3.2666L5.00293 6.58691C4.94482 6.63672 4.87842 6.66162 4.80371 6.66162H2.42139C1.29248 6.66162 0.736328 7.23438 0.736328 8.43799V11.6172C0.736328 12.8208 1.30078 13.3853 2.42139 13.3853ZM16.6655 15.7095C16.9727 15.917 17.3545 15.8423 17.5786 15.5269C18.6411 14.0659 19.2637 12.0571 19.2637 10.0068C19.2637 7.95654 18.6494 5.93115 17.5786 4.48682C17.3545 4.17139 16.9727 4.09668 16.6655 4.3042C16.3584 4.51172 16.3086 4.90186 16.5493 5.24219C17.4541 6.52051 17.9771 8.23877 17.9771 10.0068C17.9771 11.7749 17.4375 13.4766 16.5493 14.7715C16.3169 15.1118 16.3584 15.502 16.6655 15.7095ZM2.47949 12.1733C2.17236 12.1733 2.03125 12.0322 2.03125 11.7251V8.33008C2.03125 8.01465 2.17236 7.87354 2.47949 7.87354H5.13574C5.35156 7.87354 5.51758 7.83203 5.7085 7.65771L9.24463 4.44531C9.28613 4.40381 9.32764 4.37891 9.38574 4.37891C9.44385 4.37891 9.49365 4.42041 9.49365 4.49512V15.5269C9.49365 15.6016 9.44385 15.6514 9.38574 15.6514C9.34424 15.6514 9.29443 15.6265 9.24463 15.585L5.7085 12.3892C5.51758 12.2231 5.35156 12.1733 5.13574 12.1733H2.47949ZM13.5942 13.543C13.8682 13.7339 14.2583 13.6758 14.4824 13.3521C15.1216 12.5137 15.5034 11.2769 15.5034 10.0068C15.5034 8.73682 15.1216 7.5083 14.4824 6.66162C14.2583 6.33789 13.8682 6.27148 13.5942 6.4707C13.2539 6.70312 13.2041 7.11816 13.4614 7.4585C13.9429 8.12256 14.2168 9.04395 14.2168 10.0068C14.2168 10.9697 13.9346 11.8828 13.4614 12.5552C13.2124 12.9038 13.2539 13.3022 13.5942 13.543Z" />
    </svg>
  );
};
