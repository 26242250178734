import * as React from 'react';
import Stepper from '@material-ui/core/Stepper';
import './stepper.scss';
import classNames from 'classnames';

export interface CustomClasses {
  root?: string;
  container?: string;
}

export interface HorizontalStepperProps {
  currentStepIndex: number;
  classes?: CustomClasses;
  label?: string;
  onClick?: () => void;
}

export const HorizontalStepper: React.FunctionComponent<HorizontalStepperProps> = ({
  classes = {},
  currentStepIndex = 0,
  children,
}) => {
  return (
    <div className={classNames('stepper', classes.container)}>
      <Stepper
        nonLinear={true}
        activeStep={currentStepIndex}
        className={classNames('stepper__root--default', classes.root)}
      >
        {children}
      </Stepper>
    </div>
  );
};
