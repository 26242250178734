/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Ban = function Ban(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'ban',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 14 14',
      height: size,
      fill: 'none',
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('circle', { xmlns: 'http://www.w3.org/2000/svg', cx: '7', cy: '7', r: '6.25', stroke: color, strokeWidth: '1.5' }),
    React.createElement('path', { xmlns: 'http://www.w3.org/2000/svg', d: 'M11.5 2.5L2.5 11.5', stroke: color, strokeWidth: '1.5' })
  );
};

Ban.propTypes = propTypes;

export default Ban;