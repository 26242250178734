import React from 'react';
import './page.scss';

interface PageProps {
  children?: React.ReactNode;
  className?: string;
}

export const Page = ({
  children,
  className = '',
}: PageProps): React.ReactElement => {
  return (
    <div className={`page ${className}`}>
      <div className="page__content">{children}</div>
    </div>
  );
};

Page.displayName = 'Page';
