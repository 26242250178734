import { connect } from 'react-redux';
import { StoriesSearchFormComponent } from './stories_search_form.component';
import { selectShowClearFilterButtons } from '../stories_search_filters/stories_search_filters.selectors';
import {
  selectStoriesSearchStaticFilterMap,
  selectStoriesSearchDynamicFilterMap,
  selectStoriesSearchQuery,
  selectStoriesFilterTab,
} from '../../../redux/stories/stories.selectors';
import { AppState, AppThunkDispatch } from '../../../types';
import {
  selectCurrentPublisherFeatureFlags,
  selectIsUserNetworkOwner,
} from '../../../redux/user/user.selectors';

import {
  filterDynamicFiltersByPermission,
  storiesSearchDFTypes,
} from './stories_search_form.utils';
import { STORIES_SEARCH_FILTER } from './stories_search_form.types';
import { createTypeOption } from '../gallery_form/gallery_form.utils';
import {
  selectGlobalTagsFormData,
  selectCampaignTagsFormData,
  selectPersonaTagsFormData,
} from '../../../redux/galleries/gallery_form.selectors';
import { selectCurrentPublisherOptions } from '../../../redux/network/network.selectors';
import {
  storiesClearSearchFiltersAction,
  storiesSearchFiltersAction,
  storiesSearchQuery,
} from '../../../redux/stories/stories.actions';
import {
  getStoriesSearch,
  PaginationParams,
  SearchFilterReqParams,
  STORY_SEARCH_LOAD_TYPES,
} from '../../../http/stories_search.thunks';
import { SearchFilters } from '../../../redux/stories/stories.types';
import { DynamicFilterOptions } from '../stories_search_dynamic_filters/stories_search_dynamic_filters.types';

const mapStateToProps = (state: AppState) => {
  const isNetworkOwner = selectIsUserNetworkOwner(state);
  const featureFlags = selectCurrentPublisherFeatureFlags(state);

  const globalTags = selectGlobalTagsFormData(state);
  const campaignTags = selectCampaignTagsFormData(state);
  const personaTags = selectPersonaTagsFormData(state);
  const publisherOptions = selectCurrentPublisherOptions(state);

  // partners CAN NOT filter by publisher
  const networkPartnerDFTypes = storiesSearchDFTypes.filter(
    (df) => df !== STORIES_SEARCH_FILTER.PUBLISHERS,
  );
  const dfTypeList: STORIES_SEARCH_FILTER[] = isNetworkOwner
    ? storiesSearchDFTypes.filter((type) =>
        filterDynamicFiltersByPermission(type, featureFlags),
      )
    : networkPartnerDFTypes.filter((type) =>
        filterDynamicFiltersByPermission(type, featureFlags),
      );

  const dynamicFilterOptions: DynamicFilterOptions = {
    [STORIES_SEARCH_FILTER.CAMPAIGNS]: campaignTags,
    [STORIES_SEARCH_FILTER.PUBLISHERS]: publisherOptions,
    [STORIES_SEARCH_FILTER.PERSONAS]: personaTags,
    [STORIES_SEARCH_FILTER.THEMES]: globalTags,
  };

  return {
    queryValue: selectStoriesSearchQuery(state),
    selectedFilterTab: selectStoriesFilterTab(state),
    staticFilterMap: selectStoriesSearchStaticFilterMap(state),
    dynamicFilterMap: selectStoriesSearchDynamicFilterMap(state),
    showClearFilterButtons: selectShowClearFilterButtons(state),
    filterTypeOptions: dfTypeList.map((type) => createTypeOption(type)),
    dynamicFilterOptions,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  storiesClearSearchFiltersAction: () =>
    dispatch(storiesClearSearchFiltersAction()),
  getStoriesSearch: (
    searchFilters?: SearchFilterReqParams | null,
    pagination?: PaginationParams,
    loadingType?: STORY_SEARCH_LOAD_TYPES,
  ) => dispatch(getStoriesSearch({ searchFilters, pagination, loadingType })),
  storiesSearchQuery: (queryValue?: string) =>
    dispatch(storiesSearchQuery(queryValue)),
  storiesSearchFiltersAction: (filters: SearchFilters) =>
    dispatch(storiesSearchFiltersAction(filters)),
});

export const StoriesSearchForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StoriesSearchFormComponent);
