import axios from './axios_config';
import { CRThunkDispatch, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { AppState } from '../types';
import {
  getNetworkPublishersFeatureFlagsStartAction,
  getNetworkPublishersFeatureFlagsSuccessAction,
  getNetworkPublishersFeatureFlagsFailureAction,
} from '../redux/user/user.actions';
import {
  selectCurrentNetworkId,
  selectCurrentPublisherId,
} from '../redux/user/user.selectors';
import { getErrorMessage } from '../utils';

interface GetNetworkPublisherFeatureFlags {
  flags: string[];
}

export const getNetworkPublisherFeatureFlags = async (
  dispatch: CRThunkDispatch,
  getState: () => AppState,
): Promise<void> => {
  const state: AppState = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const publisherId: string = selectCurrentPublisherId(state);
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.PUBLISHERS_FEATURE_FLAGS,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
    },
  });
  dispatch(getNetworkPublishersFeatureFlagsStartAction());
  try {
    const res = await axios.get<GetNetworkPublisherFeatureFlags>(url, {
      params: { page_size: '100' },
    });

    dispatch(
      getNetworkPublishersFeatureFlagsSuccessAction(res.data.flags ?? []),
    );
  } catch (err) {
    dispatch(getNetworkPublishersFeatureFlagsFailureAction(err));
    throw new Error(`Failed to get feature flags: ${getErrorMessage(err, '')}`);
  }
};
