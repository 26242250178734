import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

var propDescriptions = {
  graphData: 'The data to be displayed on the graph',
  firstSeries: 'The first set of data for the primary area',
  secondSeries: 'An optional icon to show to the left of the metric label',
  startDate: 'the starting date for the data',
  timeIncrement: 'the increment of time in the data set',
  yAxisUnit: 'The unit to display along the Y axis (optional)',
  tooltipFormatFn: 'a function to format the tooltip',
  xTickFormatFn: 'optional function to format the ticks on the X axis',
  yTickFormatFn: 'a function to format the ticks on the Y axis',
  labelFormatFn: 'optional function to format the label of the tooltip',
  separator: 'Optional string to choose what separates the tooltip label from its value.'
};

var propTypes = {
  graphData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.number)).isRequired,
  firstSeries: PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  }).isRequired,
  secondSeries: PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  }),
  startDate: PropTypes.string.isRequired,
  timeIncrement: PropTypes.string.isRequired,
  yAxisUnit: PropTypes.string,
  tooltipFormatFn: PropTypes.func,
  xTickFormatFn: PropTypes.func,
  yTickFormatFn: PropTypes.func,
  labelFormatFn: PropTypes.func,
  separator: PropTypes.string
};

var defaultProps = {};

var colors = {
  darkGrey: '#2A2F35',
  grey: '#8A8C90',
  lightBlue: '#10b5f5',
  paleBlue: '#19B6F3',
  magenta: '#C739A8',
  purple: '#A455FF'
};

export var ResponsiveAreaChart = function ResponsiveAreaChart(_ref) {
  var graphData = _ref.graphData,
      firstSeries = _ref.firstSeries,
      secondSeries = _ref.secondSeries,
      startDate = _ref.startDate,
      timeIncrement = _ref.timeIncrement,
      _ref$yAxisUnit = _ref.yAxisUnit,
      yAxisUnit = _ref$yAxisUnit === undefined ? '' : _ref$yAxisUnit,
      tooltipFormatFn = _ref.tooltipFormatFn,
      xTickFormatFn = _ref.xTickFormatFn,
      yTickFormatFn = _ref.yTickFormatFn,
      labelFormatFn = _ref.labelFormatFn,
      separator = _ref.separator;

  var defaultLabelFormatter = function defaultLabelFormatter(label) {
    return moment(startDate).add(label, timeIncrement).format('dddd, MMM D, YYYY');
  };

  var defaultXTickFormatter = function defaultXTickFormatter(tick) {
    return moment(startDate).add(tick, timeIncrement).format('MMM D');
  };
  return React.createElement(
    ResponsiveContainer,
    { width: '100%', height: 160 },
    React.createElement(
      AreaChart,
      { data: graphData, className: 'cr-chart' },
      React.createElement(Tooltip, {
        separator: separator || ': ',
        labelFormatter: labelFormatFn || defaultLabelFormatter,
        formatter: tooltipFormatFn || null,
        labelStyle: {
          color: '' + colors.darkGrey,
          marginBottom: 4
        },
        itemStyle: {
          padding: '8px 0 0 0'
        },
        cursor: false
      }),
      React.createElement(XAxis, {
        interval: 'preserveStartEnd',
        minTickGap: 120,
        axisLine: false,
        tickLine: false,
        tickSize: 8,
        tickFormatter: xTickFormatFn || defaultXTickFormatter,
        tick: {
          fontSize: 12,
          color: '' + colors.darkGrey
        }
      }),
      React.createElement(YAxis, {
        interval: 1,
        allowDecimals: false,
        tickFormatter: yTickFormatFn || null,
        unit: yAxisUnit,
        orientation: 'right',
        axisLine: false,
        tickLine: false,
        tick: {
          fontSize: 12,
          color: '' + colors.darkGrey,
          textAnchor: 'end'
        },
        width: 0.5
      }),
      React.createElement(Area, {
        type: 'monotone',
        dataKey: firstSeries.dataKey,
        name: firstSeries.name,
        unit: yAxisUnit,
        strokeWidth: 2,
        stroke: colors[firstSeries.color],
        fill: colors[firstSeries.color],
        fillOpacity: 0.2
      }),
      secondSeries && React.createElement(Area, {
        type: 'monotone',
        dataKey: secondSeries.dataKey,
        name: secondSeries.name,
        unit: yAxisUnit,
        strokeWidth: 2,
        strokeDasharray: '8 4',
        stroke: colors[secondSeries.color],
        fill: colors[secondSeries.color],
        fillOpacity: 0.2
      })
    )
  );
};

ResponsiveAreaChart.propTypes = propTypes;
ResponsiveAreaChart.defaultProps = defaultProps;
ResponsiveAreaChart.propDescriptions = propDescriptions;

export default ResponsiveAreaChart;