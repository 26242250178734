import React from 'react';
import { IconProps } from '../icon.types';

export const Lock = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.19824 17.9507H13.8018C15.0469 17.9507 15.6611 17.3281 15.6611 15.9751V10.2559C15.6611 9.07715 15.188 8.45459 14.2251 8.32178V6.31299C14.2251 3.18359 12.1748 1.67285 10 1.67285C7.8252 1.67285 5.7749 3.18359 5.7749 6.31299V8.32178C4.81201 8.45459 4.33887 9.07715 4.33887 10.2559V15.9751C4.33887 17.3281 4.95312 17.9507 6.19824 17.9507ZM7.11133 6.13867C7.11133 4.05518 8.44775 2.95117 10 2.95117C11.5522 2.95117 12.8887 4.05518 12.8887 6.13867V8.29688H7.11133V6.13867ZM6.24805 16.7056C5.86621 16.7056 5.67529 16.5312 5.67529 16.0664V10.1729C5.67529 9.70801 5.86621 9.54199 6.24805 9.54199H13.7603C14.1421 9.54199 14.3247 9.70801 14.3247 10.1729V16.0664C14.3247 16.5312 14.1421 16.7056 13.7603 16.7056H6.24805Z" />
    </svg>
  );
};
