import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Photo = function Photo(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Photo__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M0,0v1000h1000V0H0z M935.2,64.8v731.4L750,611L645.8,715.1L312.5,381.8L64.8,629.5V64.8H935.2z' // eslint-disable-line max-len
    })
  );
};

Photo.propTypes = propTypes;

export default Photo;