import {
  STORIES_SEARCH_FILTER,
  StoriesSearchFilterParams,
  StaticFilterMap,
  StaticFilterDisplayData,
} from './stories_search_form.types';
import { STORY_STATUS, STORY_VISIBILITY } from '../story/story.types';
import { PUBLISHER_ROLES } from '../publisher_label/publisher_label.types';
import { DynamicFilterType } from '../stories_search_dynamic_filters/stories_search_dynamic_filters.types';
import { SearchFilterReqParams } from '../../../http/stories_search.thunks';
import { isNotNull, isGenericObject } from '../../../types/typeguards';
import { userHasAccess } from '../../blocks/authwrapper/auth_wrapper.utils';
import { FEATURE_FLAGS } from '../../blocks/authwrapper/auth_wrapper.types';
import {
  SearchFilters,
  StoriesFilterTab,
} from '../../../redux/stories/stories.types';
import { SelectValue } from '../../blocks/select';

export const storiesSearchStaticFilterMap: StaticFilterMap = {
  [STORIES_SEARCH_FILTER.STATUS]: [
    {
      id: `${STORIES_SEARCH_FILTER.STATUS}-${STORY_STATUS.PUBLISHED}`,
      label: STORY_STATUS.PUBLISHED,
      iconType: 'check',
      value: false,
    },
    {
      id: `${STORIES_SEARCH_FILTER.STATUS}-${STORY_STATUS.DRAFT}`,
      label: STORY_STATUS.DRAFT,
      iconType: 'edit',
      value: false,
    },
  ],
  [STORIES_SEARCH_FILTER.VISIBILITY]: [
    {
      id: `${STORIES_SEARCH_FILTER.VISIBILITY}-${STORY_VISIBILITY.SHOWN}`,
      label: STORY_VISIBILITY.SHOWN,
      iconType: 'approve',
      value: false,
    },
    {
      id: `${STORIES_SEARCH_FILTER.VISIBILITY}-${STORY_VISIBILITY.HIDDEN}`,
      label: STORY_VISIBILITY.HIDDEN,
      iconType: 'ban',
      value: false,
    },
  ],
  [STORIES_SEARCH_FILTER.CREATOR_TYPE]: [
    {
      id: `${STORIES_SEARCH_FILTER.CREATOR_TYPE}-${PUBLISHER_ROLES.OWNER}`,
      label: PUBLISHER_ROLES.OWNER,
      iconType: 'person',
      value: false,
    },
    {
      id: `${STORIES_SEARCH_FILTER.CREATOR_TYPE}-${PUBLISHER_ROLES.PARTNER}`,
      label: PUBLISHER_ROLES.PARTNER,
      iconType: 'people',
      value: false,
    },
  ],
};

export const storiesSearchDFTypes: STORIES_SEARCH_FILTER[] = [
  STORIES_SEARCH_FILTER.PUBLISHERS,
  STORIES_SEARCH_FILTER.THEMES,
  STORIES_SEARCH_FILTER.CAMPAIGNS,
  STORIES_SEARCH_FILTER.PERSONAS,
];

export const storiesSearchFormDataToFilterParams = (
  data: DynamicFilterType[],
  status: StaticFilterDisplayData[],
  visibility: StaticFilterDisplayData[],
) => {
  let staticFilters: StoriesSearchFilterParams = {};

  const staticFiltersArray: STORY_STATUS[] = [];

  for (let i = 0; i < status.length; i += 1) {
    if (status[i].value === true) {
      staticFiltersArray.push(status[i].label as STORY_STATUS);
    }
  }

  if (staticFiltersArray.length) {
    staticFilters = {
      ...staticFilters,
      [STORIES_SEARCH_FILTER.STATUS]: staticFiltersArray,
    };
  }

  // VISIBILITY ARRAY

  const visibilityStaticFiltersArray: STORY_VISIBILITY[] = [];

  for (let i = 0; i < visibility.length; i += 1) {
    if (visibility[i].value === true) {
      visibilityStaticFiltersArray.push(
        visibility[i].label as STORY_VISIBILITY,
      );
    }
  }

  if (visibilityStaticFiltersArray.length) {
    staticFilters = {
      ...staticFilters,
      // Visibility is a toggle so there will only ever be 1 selected value
      is_hidden:
        (visibilityStaticFiltersArray[0] as STORY_VISIBILITY) ===
        STORY_VISIBILITY.HIDDEN,
    };
  }

  const dynamicFilterParams = (data ?? []).reduce(
    (params: StoriesSearchFilterParams, filter) => {
      const currentTagType = filter.filterType?.label;
      const currentTags = filter.filterTags;
      const tagIds: string[] =
        currentTags instanceof Array
          ? currentTags.map(({ value }) => value).filter(isNotNull)
          : [];

      if (tagIds.length) {
        switch (currentTagType) {
          case STORIES_SEARCH_FILTER.THEMES: {
            params.global_tag_ids = tagIds;
            break;
          }
          case STORIES_SEARCH_FILTER.CAMPAIGNS:
          case STORIES_SEARCH_FILTER.PERSONAS:
            if (!params.network_tag_ids) {
              params.network_tag_ids = [];
            }
            params.network_tag_ids = params.network_tag_ids.concat(tagIds);
            break;
          case STORIES_SEARCH_FILTER.PUBLISHERS:
            params.publishers = tagIds;
            break;
          default:
            return params;
        }
      }
      return params;
    },
    {},
  );

  const filterParams: StoriesSearchFilterParams = {
    ...staticFilters,
    ...dynamicFilterParams,
  };

  return filterParams;
};

export const deepCopy = (obj: unknown) => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }
  if (obj instanceof Array) {
    return obj.reduce((arr, item, i) => {
      arr[i] = deepCopy(item);
      return arr;
    }, []);
  }
  if (isGenericObject(obj)) {
    return Object.keys(obj).reduce((newObj: Record<string, unknown>, key) => {
      newObj[key] = deepCopy(obj[key]);
      return newObj;
    }, {});
  }
  return obj;
};

export const sfMapToStaticFilterParams = (sfMap: StaticFilterMap) => {
  const staticMapTransformation = Object.entries(sfMap);
  let staticFilterParams = {};
  staticMapTransformation.forEach(([sfType, staticFilters]) => {
    if (staticFilters) {
      let filter = {};
      const selectedSf = staticFilters.filter((sf) => sf.value === true);
      const selectedFilters = selectedSf.map((sf) => sf.label);
      filter =
        sfType === STORIES_SEARCH_FILTER.VISIBILITY
          ? {
              // Visibility is a toggle so there will only ever be 1 selected value
              is_hidden:
                (selectedFilters[0] as STORY_VISIBILITY) ===
                STORY_VISIBILITY.HIDDEN,
            }
          : {
              [sfType]: selectedFilters,
            };

      staticFilterParams = {
        ...staticFilterParams,
        ...filter,
      };
    }
  });
  return staticFilterParams;
};

export const getValuesFromSelectOptions = (options: SelectValue<true>) => {
  if (options) {
    return options.map((option) => option.value);
  }
  return options;
};

export const dfMapToDynamicFilterParams = (dfMap: DynamicFilterType[]) =>
  dfMap.reduce((acc: SearchFilterReqParams, currentDf: DynamicFilterType) => {
    const currentDfType = currentDf.filterType
      ? currentDf.filterType.value
      : null;
    const currentTags = currentDf.filterTags;
    const tagIds = currentTags?.map((tag) => tag.value) ?? [];
    if (tagIds.length && tagIds) {
      switch (currentDfType) {
        case STORIES_SEARCH_FILTER.THEMES: {
          acc.global_tag_ids = tagIds;
          break;
        }
        case STORIES_SEARCH_FILTER.CAMPAIGNS:
        case STORIES_SEARCH_FILTER.PERSONAS:
          if (acc.network_tag_ids && acc.network_tag_ids.length) {
            acc.network_tag_ids = acc.network_tag_ids.concat(tagIds);
          } else {
            acc.network_tag_ids = tagIds;
          }
          break;
        case STORIES_SEARCH_FILTER.PUBLISHERS:
          acc.publishers = tagIds;
          break;
        default:
          return acc;
      }
    }
    return {
      ...acc,
    };
  }, {});

const mapStoriesFilterTabValue: { [key: string]: boolean | undefined } = {
  [StoriesFilterTab.ALL]: undefined,
  [StoriesFilterTab.CREATOR_STORIES]: true,
  [StoriesFilterTab.MY_NETWORK]: false,
};

export const storiesSearchStateToFilterParams = (
  query?: string,
  filterData?: SearchFilters,
  selectedTab?: StoriesFilterTab,
) => {
  const sfMap = filterData?.staticFilterMap;
  const dfMap = filterData?.dynamicFilterMap;

  const staticFilters = sfMap ? sfMapToStaticFilterParams(sfMap) : {};
  const dynamicFilters = dfMap ? dfMapToDynamicFilterParams(dfMap) : {};

  const filterParams: StoriesSearchFilterParams = {
    [STORIES_SEARCH_FILTER.QUERY]: query ?? undefined,
    [STORIES_SEARCH_FILTER.IS_CHALLENGE_STORY]: selectedTab
      ? mapStoriesFilterTabValue[selectedTab]
      : undefined,
    ...staticFilters,
    ...dynamicFilters,
  };

  return filterParams;
};

export const filterDynamicFiltersByPermission = (
  type: string,
  featureFlags: FEATURE_FLAGS | FEATURE_FLAGS[],
  // eslint-disable-next-line consistent-return
) => {
  switch (type) {
    case STORIES_SEARCH_FILTER.PUBLISHERS:
      if (userHasAccess(featureFlags, FEATURE_FLAGS.PUBLISHER_FIELD)) {
        return type;
      }
      break;
    case STORIES_SEARCH_FILTER.PERSONAS:
      if (userHasAccess(featureFlags, FEATURE_FLAGS.GALLERY_PERSONA_FIELD)) {
        return type;
      }
      break;
    case STORIES_SEARCH_FILTER.CAMPAIGNS:
      if (userHasAccess(featureFlags, FEATURE_FLAGS.GALLERY_CAMPAIGN_FIELD)) {
        return type;
      }
      break;
    case STORIES_SEARCH_FILTER.THEMES:
      if (userHasAccess(featureFlags, FEATURE_FLAGS.GALLERY_THEME_FIELD)) {
        return type;
      }
      break;
    default:
      return type;
  }
};
