import React from 'react';
import { Translate } from 'react-localize-redux';
import './taxonomy.scss';
import { Story } from '../story/story.types';
import { GLOBAL_TAG_TYPES } from '../../../redux/global_tags/global_tags.types';
import { NETWORK_TAG_TYPES } from '../../../redux/network_tags/network_tags.types';
import { TAXONOMY_FEATURE_FLAGS } from '../../blocks/authwrapper/auth_wrapper.constants';
import {
  FEATURE_FLAG_COMPARISON_TYPES,
  FEATURE_FLAGS,
} from '../../blocks/authwrapper/auth_wrapper.types';
import { AuthWrapper } from '../../blocks/authwrapper/auth_wrapper.connect';
import { TaxonomyContainer } from './taxonomy_container.component';

interface TaxonomySectionProps {
  story: Story;
}

export const TaxonomySection = ({ story }: TaxonomySectionProps) => {
  return (
    <AuthWrapper
      featureFlags={TAXONOMY_FEATURE_FLAGS}
      comparisonType={FEATURE_FLAG_COMPARISON_TYPES.LOOSE}
    >
      <div className="taxonomy">
        <h2>
          <Translate id="stories.form.taxonomy.title" />
        </h2>
        <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_CAMPAIGN_FIELD}>
          {/* CAMPAIGN */}
          <div className="taxonomy__section">
            <div>
              <p className="taxonomy__section--tagType">
                <Translate id="stories.form.taxonomy.campaignLabel" />
              </p>
            </div>
            {story.networkTags && (
              <TaxonomyContainer
                taxonomyTags={story.networkTags}
                tagtype={NETWORK_TAG_TYPES.CAMPAIGN}
              />
            )}
          </div>
        </AuthWrapper>
        {/* THEME */}
        <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_THEME_FIELD}>
          <div className="taxonomy__section">
            <div>
              <p className="taxonomy__section--tagType">
                <Translate id="stories.form.taxonomy.themeLabel" />
              </p>
            </div>
            {story.globalTags && (
              <TaxonomyContainer
                taxonomyTags={story.globalTags}
                tagtype={GLOBAL_TAG_TYPES.THEME}
              />
            )}
          </div>
        </AuthWrapper>

        <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_PERSONA_FIELD}>
          {/* PERSONA */}
          <div className="taxonomy__section">
            <div>
              <p className="taxonomy__section--tagType">
                <Translate id="stories.form.taxonomy.personaLabel" />
              </p>
            </div>
            {story.networkTags && (
              <TaxonomyContainer
                taxonomyTags={story.networkTags}
                tagtype={NETWORK_TAG_TYPES.PERSONA}
              />
            )}
          </div>
        </AuthWrapper>
        {/* SEASON */}
        <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_THEME_FIELD}>
          <div className="taxonomy__section">
            <div>
              <p className="taxonomy__section--tagType">
                <Translate id="stories.form.taxonomy.seasonLabel" />
              </p>
            </div>
            {story.globalTags && (
              <TaxonomyContainer
                taxonomyTags={story.globalTags}
                tagtype={GLOBAL_TAG_TYPES.SEASON}
              />
            )}
          </div>
        </AuthWrapper>
      </div>
    </AuthWrapper>
  );
};
