import { isEmpty, isEqual } from 'lodash';
import { Story } from '../story/story.types';

export const isStoryListPristine = (
  tempStories: Story[],
  selectedStories: Story[],
) => {
  const tempStoryIds = tempStories.map((story) => story.uuid).sort();
  const selectedStoryIds = selectedStories.map((story) => story.uuid).sort();
  return isEmpty(tempStories) || isEqual(tempStoryIds, selectedStoryIds);
};
