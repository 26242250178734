import React from 'react';
import { IconProps } from '../icon.types';

export const Link = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.89209 13.3604L10.9961 12.2397C9.90039 12.1567 9.18652 11.8247 8.64697 11.2852C7.19434 9.83252 7.20264 7.77393 8.63867 6.33789L11.3447 3.63184C12.7974 2.1875 14.8394 2.1792 16.292 3.63184C17.7446 5.08447 17.728 7.13477 16.292 8.5708L14.665 10.1978C14.8975 10.729 14.9473 11.3433 14.8643 11.8828L17.3047 9.45068C19.2886 7.4585 19.3052 4.64453 17.2964 2.62744C15.2793 0.610352 12.457 0.626953 10.4648 2.61914L7.63428 5.45801C5.64209 7.4502 5.62549 10.2725 7.64258 12.2812C8.16553 12.8042 8.82959 13.1777 9.89209 13.3604ZM10.0996 6.52051L8.99561 7.64111C10.0913 7.73242 10.8052 8.05615 11.3447 8.5957C12.7974 10.0483 12.7891 12.1069 11.353 13.543L8.63867 16.249C7.19434 17.6934 5.15234 17.7017 3.69971 16.2573C2.24707 14.7964 2.25537 12.7544 3.69971 11.3101L5.32666 9.68311C5.09424 9.16016 5.03613 8.5376 5.12744 7.99805L2.68701 10.4302C0.703125 12.4224 0.686523 15.2446 2.69531 17.2534C4.7124 19.2705 7.53467 19.2539 9.51855 17.27L12.3574 14.4229C14.3496 12.4307 14.3662 9.6084 12.3491 7.59961C11.8262 7.07666 11.1621 6.70312 10.0996 6.52051Z" />
    </svg>
  );
};
