import React from 'react';
import { IconProps } from '../icon.types';

export const SquareStack = ({
  className,
  size,
  color = 'black',
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.97412 1.45703H14.0259C13.9761 0.751465 13.5693 0.38623 12.8057 0.38623H7.18604C6.42236 0.38623 6.02393 0.751465 5.97412 1.45703ZM4.52979 3.75635H15.4619C15.3374 3.00098 14.9805 2.58594 14.1504 2.58594H5.84131C5.01123 2.58594 4.6543 3.00098 4.52979 3.75635ZM5.32666 19.6523H14.9224C16.4082 19.6523 17.2798 18.7891 17.2798 17.0791V7.66602C17.2798 5.95605 16.3999 5.09277 14.6733 5.09277H5.32666C3.5918 5.09277 2.72021 5.94775 2.72021 7.66602V17.0791C2.72021 18.7891 3.5918 19.6523 5.32666 19.6523ZM5.34326 18.3159C4.51318 18.3159 4.05664 17.8677 4.05664 17.0044V7.73242C4.05664 6.87744 4.51318 6.4292 5.34326 6.4292H14.6484C15.4702 6.4292 15.9434 6.87744 15.9434 7.73242V17.0044C15.9434 17.8677 15.4702 18.3159 14.8975 18.3159H5.34326Z"
        fill="black"
      />
    </svg>
  );
};
