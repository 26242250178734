import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.number,
  iconStyle: PropTypes.string,
  fillColor: PropTypes.string
};
/* eslint-disable max-len */
var Postcards = function Postcards(_ref) {
  var size = _ref.size,
      iconStyle = _ref.iconStyle,
      fillColor = _ref.fillColor;

  return React.createElement(
    'svg',
    { height: size, viewBox: '0 0 200 200', fill: 'none', style: { enableBackground: 'new 0 0 124 124' }, xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement('path', { d: 'M61.3063 66.8226L43.1742 62.0291L24 134.453L135.768 164.037L146.884 122.018', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M33.9461 124.982L124.734 149.019L131.913 121.937L61.6537 121.902L61.4106 76.4328L47.7594 72.8203L33.9461 124.982Z', fill: iconStyle === 'fill' ? fillColor : '#FFF' }),
    React.createElement('path', { d: 'M61.3296 76.6412L51.233 73.9781L37.4197 126.14L128.208 150.177L135.699 121.902', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M177 47H61.3875V121.914H177V47Z', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M166.66 57.0388H148.077V78.4824H166.66V57.0388Z', fill: iconStyle === 'fill' ? fillColor : '#FFF' }),
    React.createElement('path', { d: 'M168.976 54.723H150.392V76.1666H168.976V54.723Z', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M119.188 58.7059V110.208', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M71.2408 80.4739H109.89', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M71.2408 97.3208H109.89', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M71.2408 88.8915H109.89', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M71.2408 63.6271L88.0646 63.6155', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M98.7169 105.287H109.89', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M71.2408 72.0562H102.191', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M125.255 68.3626C126.271 67.6208 127.452 67.1363 128.696 66.9508C129.94 66.7653 131.211 66.8842 132.399 67.2974C134.194 67.9574 135.873 69.0342 137.656 69.5436C138.802 69.85 140 69.9051 141.169 69.7053C142.338 69.5054 143.45 69.0553 144.429 68.3858L144.568 68.3047', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M125.255 62.9207C126.271 62.1789 127.452 61.6944 128.696 61.5089C129.94 61.3234 131.211 61.4423 132.399 61.8555C134.194 62.5155 135.873 63.5923 137.656 64.1018C138.801 64.4104 140 64.4667 141.17 64.2668C142.339 64.0669 143.451 63.6155 144.429 62.9439L144.568 62.8628', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement('path', { d: 'M125.255 57.4787C126.271 56.7369 127.452 56.2524 128.696 56.0669C129.94 55.8814 131.211 56.0003 132.399 56.4135C134.194 57.0735 135.873 58.1503 137.656 58.6597C138.801 58.9747 140.001 59.0343 141.171 58.8342C142.342 58.6341 143.454 58.1793 144.429 57.5019L144.568 57.4208', stroke: iconStyle === 'outline-white' ? '#FFF' : '#2A3134', strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' })
  );
};

Postcards.propTypes = propTypes;

export default Postcards;