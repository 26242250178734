import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Location = function Location(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Layer_1',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 754 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M0 377c0-104 36.6-193 110-267S272.3-.7 377 0c104.6.7 193.7 37.4 267 110s110 161.7 110 267c0 82-17.7 146.3-53 193l-324 430L53 570C17.7 523.3 0 459 0 377zm219 0c0 44 15.3 81.3 46 112 30.7 30.7 68 46 112 46s81.3-15.3 112-46c30.7-30.6 46-68 46-112s-15.3-81.3-46-112c-30.7-30.6-68-46-112-46s-81.3 15.4-112 46c-30.7 30.7-46 68-46 112z' // eslint-disable-line max-len
    })
  );
};

Location.propTypes = propTypes;

export default Location;