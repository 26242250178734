import React from 'react';
import { IconProps } from '../icon.types';

export const Pencil = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      stroke={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1483 5.82843L15.9767 3L20.9264 7.94975L18.098 10.7781M13.1483 5.82843L3.54161 15.435C3.35408 15.6225 3.24872 15.8769 3.24872 16.1421V20.6776H7.78425C8.04947 20.6776 8.30382 20.5723 8.49136 20.3847L18.098 10.7781M13.1483 5.82843L18.098 10.7781"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
