import { Dispatch, SetStateAction } from 'react';
import { TranslateFunction } from 'react-localize-redux';
import { StoryHideResponse } from '../../../http/story_hide.thunk';
import { StoryShowResponse } from '../../../http/story_show.thunk';
import { Story } from '../story/story.types';
import { UseToastProps } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../blocks/toast';

export const handleHideStory = async (
  story: Story,
  setIsUpdatingStoryVisibility: Dispatch<SetStateAction<boolean>>,
  showToast: (props: UseToastProps) => void,
  onHideStory: (story_uuid: string) => Promise<StoryHideResponse>,
  translate: TranslateFunction,
) => {
  const story_uuid = story.uuid;
  setIsUpdatingStoryVisibility(true);

  try {
    await onHideStory(story_uuid);
    showToast({
      type: TOAST_TYPES.SUCCESS,
      message: `${translate(
        story.title
          ? 'stories.form.hideStorySnackbar.success'
          : 'stories.form.hideStorySnackbar.successWithoutStoryTitle',
        {
          title: story.title,
        },
      )}`,
    });
    setIsUpdatingStoryVisibility(false);
  } catch {
    setIsUpdatingStoryVisibility(false);
    showToast({
      type: TOAST_TYPES.ERROR,
      message: `${translate('stories.form.hideStorySnackbar.fail')}`,
    });
  }
};

export const handleShowStory = async (
  story: Story,
  setIsUpdatingStoryVisibility: Dispatch<SetStateAction<boolean>>,
  showToast: (props: UseToastProps) => void,
  onShowStory: (story_uuid: string) => Promise<StoryShowResponse>,
  translate: TranslateFunction,
) => {
  const story_uuid = story.uuid;
  setIsUpdatingStoryVisibility(true);

  try {
    await onShowStory(story_uuid);
    showToast({
      message: `${translate(
        story.title
          ? 'stories.form.showStorySnackbar.success'
          : 'stories.form.showStorySnackbar.successWithoutStoryTitle',
        {
          title: story.title,
        },
      )}`,
      type: TOAST_TYPES.SUCCESS,
    });
    setIsUpdatingStoryVisibility(false);
  } catch {
    setIsUpdatingStoryVisibility(false);
    showToast({
      message: `${translate('stories.form.showStorySnackbar.fail', {
        title: story.title,
      })}`,
      type: TOAST_TYPES.ERROR,
    });
  }
};
