import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var CrLogo = function CrLogo(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'CrLogo__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 866 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M866,498.3l-286.7,500L3,1000l286.7-500L0,1.7L576.4,0L866,498.3z' // eslint-disable-line max-len
    })
  );
};

CrLogo.propTypes = propTypes;

export default CrLogo;