import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var SimilarPhotos = function SimilarPhotos(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'similar_photos',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 18 12',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M11.244.044c-.91 0-1.8.2-2.577.578A5.85 5.85 0 0 0 6.089.044a5.956 5.956 0 0 0 0 11.912c.911 0 1.8-.2 2.578-.578a5.85 5.85 0 0 0 2.577.578 5.956 5.956 0 0 0 0-11.912zm-5.155 10.4c-2.445 0-4.445-2-4.445-4.444s2-4.444 4.445-4.444c.355 0 .711.044 1.044.133A5.973 5.973 0 0 0 5.29 6c0 1.689.711 3.222 1.867 4.311a4.383 4.383 0 0 1-1.067.133zm5.155 0c-.355 0-.71-.044-1.044-.133C11.333 9.222 12.067 7.69 12.067 6A5.907 5.907 0 0 0 10.2 1.689a4.078 4.078 0 0 1 1.044-.133c2.445 0 4.445 2 4.445 4.444s-2 4.444-4.445 4.444z' // eslint-disable-line max-len
    })
  );
};

SimilarPhotos.propTypes = propTypes;

export default SimilarPhotos;