import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import axios from './axios_config';
import { AppState, AppActions } from '../types';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import {
  DOWNLOAD_ASSETS_BINDING_TYPES,
  ExportsDownloadResponse,
} from '../redux/exports/exports.types';
import { urlBuilder } from './util/url_builder';
import { getErrorMessage } from '../utils';
import {
  exportsStoriesStartAction,
  exportsStoriesSuccessAction,
  exportsStoriesFailureAction,
} from '../redux/exports/exports.actions';

export type StoryDownloadResponse = AxiosResponse<ExportsDownloadResponse>;

export const downloadStories = (
  storyUuid: string,
  exportId: string | null,
  bindType: DOWNLOAD_ASSETS_BINDING_TYPES | null,
) => async (
  dispatch: ThunkDispatch<AppState, {}, AppActions>,
): Promise<StoryDownloadResponse> => {
  if (!exportId) {
    throw new Error('[FAILED] Story download due to missing export ID.');
  }

  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.STORY_DOWNLOAD,
    params: {
      exportId,
    },
  });

  dispatch(exportsStoriesStartAction({ storyUuid, exportId, bindType }));
  try {
    const downloadData = await axios.get<ExportsDownloadResponse>(url);
    const { data } = downloadData;
    dispatch(
      exportsStoriesSuccessAction({
        exportId: data.export_id,
        status: data.status,
        pageCount: data.page_count,
        pageCompleted: data.page_completed,
        pagesExportUrl: data.pages_export_url,
        storyExportUrl: data.story_export_url,
        storyUuid,
      }),
    );
    return downloadData;
  } catch (e) {
    dispatch(exportsStoriesFailureAction({ storyUuid }));
    const errorMessage = getErrorMessage(e, 'Failed to get export id.');
    throw new Error(errorMessage);
  }
};
