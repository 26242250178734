import React, { useContext, forwardRef, PropsWithChildren } from 'react';
import clsx from 'clsx';

import { Modal as BaseModal, MODAL_TYPES } from '../modal';
import { MODAL_TITLE_ID } from './connected_modal_title.component';
import { ModalContext } from '../../hooks/use_modal/use_modal';

export interface ModalProps {
  type?: MODAL_TYPES;
  // Note that this className will
  // be applied to the base component
  // which is the container div that contains
  // the overlay as well as the modal
  className?: string;
  onEnter?: () => void;
  id?: string;
  disableEscapeKeyDown?: boolean;
}

export const ConnectedModal = forwardRef<
  HTMLElement,
  PropsWithChildren<ModalProps>
>((props, ref) => {
  const {
    type = MODAL_TYPES.BASIC,
    children,
    className = '',
    onEnter,
    id,
    disableEscapeKeyDown,
  } = props;
  const { isOpen, toggle } = useContext(ModalContext);

  return (
    <BaseModal
      id={id}
      onEnter={onEnter}
      onClose={toggle}
      aria-labelledby={MODAL_TITLE_ID}
      open={isOpen}
      fullWidth={type === MODAL_TYPES.FULL_WIDTH}
      fullScreen={type === MODAL_TYPES.FULL_SCREEN}
      className={clsx(className, type)}
      PaperProps={{ ref }}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {children}
    </BaseModal>
  );
});
