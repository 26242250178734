import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../types';
import { selectCurrentPublisher } from '../../../redux/user/user.selectors';
import { updateAccountSettings } from '../../../http/account_update.thunk';
import { AccountSettingsPage } from './account_settings.component';
import {
  ACCOUNT_FIELD_MASK,
  PublisherUpdateDetails,
} from './account_settings.types';

const mapStateToProps = (state: AppState) => ({
  publisher: selectCurrentPublisher(state),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  updateAccountSettings: async (
    data: Partial<PublisherUpdateDetails>,
    fieldMask: ACCOUNT_FIELD_MASK[],
  ) => {
    await dispatch(updateAccountSettings(data, fieldMask));
  },
});
export const AccountSettingsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSettingsPage);
