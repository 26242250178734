import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon/Icon';

var propTypes = {
  item: PropTypes.object,
  onRemoveItem: PropTypes.func
};

var Item = function Item(_ref) {
  var item = _ref.item,
      onRemoveItem = _ref.onRemoveItem;

  return React.createElement(
    'div',
    { className: 'cr-selectedItemList__item' },
    item.label,
    React.createElement(Icon, {
      className: 'cr-selectedItemList__item--icon',
      type: 'close',
      size: 10,
      onClick: function onClick(e) {
        return onRemoveItem(item, e);
      }
    })
  );
};

Item.propTypes = propTypes;

export default Item;