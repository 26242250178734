import {
  HIDE_STORY_TYPES,
  SHOW_STORY_TYPES,
  StoriesFilterTab,
  StoriesState,
} from './stories.types';
import {
  STORIES_LOAD_TYPES,
  STORIES_SEARCH_TYPES,
  STORY_LOAD_TYPES,
} from '../../react/pages/stories_list/stories_list.types';

import { AppActions } from '../../types';
import { STORY_SEARCH_LOAD_TYPES } from '../../http/stories_search.thunks';
import { Story } from '../../react/features/story/story.types';

const initialState: Readonly<StoriesState> = {
  storyToManage: null,
  data: [],
  searchFilters: {
    staticFilterMap: null,
    dynamicFilterMap: null,
  },
  isLoading: false,
  loadingType: null,
  nextPageToken: null,
  searchQuery: '',
  storiesFilterTab: StoriesFilterTab.ALL,
};

export const storiesReducer = (
  state: Readonly<StoriesState> = initialState,
  action: AppActions,
): StoriesState => {
  switch (action.type) {
    case STORIES_LOAD_TYPES.START: {
      return {
        ...state,
        isLoading: true,
        loadingType: action.payload.loadingType,
      };
    }
    case STORIES_LOAD_TYPES.SUCCESS: {
      const { data, loadingType } = state;
      let searchResults;
      if (loadingType === STORY_SEARCH_LOAD_TYPES.LAZY_LOAD) {
        searchResults = data.length
          ? [...data, ...action.payload.stories]
          : action.payload.stories;
      } else {
        searchResults = action.payload.stories;
      }

      return {
        ...state,
        data: searchResults,
        nextPageToken: action.payload.nextPageToken,
        isLoading: false,
      };
    }
    case STORIES_LOAD_TYPES.FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }

    case STORIES_SEARCH_TYPES.SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload,
      };
    }
    case STORIES_SEARCH_TYPES.CLEAR_FILTERS: {
      return {
        ...state,
        searchFilters: {
          staticFilterMap: null,
          dynamicFilterMap: null,
        },
      };
    }
    case STORIES_SEARCH_TYPES.SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload,
      };
    }
    case STORIES_SEARCH_TYPES.CLEAR_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: '',
      };
    }

    case STORIES_SEARCH_TYPES.UPDATE_FILTER_TAB: {
      return {
        ...state,
        storiesFilterTab: action.payload,
      };
    }

    case STORIES_SEARCH_TYPES.RESET_FILTER_TAB: {
      return {
        ...state,
        storiesFilterTab: initialState.storiesFilterTab,
      };
    }

    case STORY_LOAD_TYPES.START:
    case STORY_LOAD_TYPES.FAILURE: {
      return {
        ...state,
        storyToManage: null,
      };
    }
    case STORY_LOAD_TYPES.SUCCESS: {
      return {
        ...state,
        storyToManage: {
          ...state.storyToManage,
          ...action.payload,
          is_hidden: action.payload?.isHidden ?? false,
        },
      };
    }

    case HIDE_STORY_TYPES.SUCCESS: {
      let updatedState = state;

      const hiddenStoryIndex = state.data.findIndex(
        (story) => story.uuid === action.payload,
      );

      if (hiddenStoryIndex !== -1) {
        const updatedStory = {
          ...state.data.at(hiddenStoryIndex),
          is_hidden: true,
        } as Story;

        const updatedData = Object.assign([], state.data);
        updatedData.splice(hiddenStoryIndex, 1, updatedStory);

        updatedState = {
          ...state,
          data: updatedData,
          storyToManage:
            state.storyToManage?.uuid === updatedStory.uuid
              ? updatedStory
              : state.storyToManage,
        };
      }

      return updatedState;
    }

    case SHOW_STORY_TYPES.SUCCESS: {
      let updatedState = state;

      const shownStoryIndex = state.data.findIndex(
        (story) => story.uuid === action.payload,
      );

      if (shownStoryIndex !== -1) {
        const updatedStory = {
          ...state.data.at(shownStoryIndex),
          is_hidden: false,
        } as Story;

        const updatedData = Object.assign([], state.data);
        updatedData.splice(shownStoryIndex, 1, updatedStory);

        updatedState = {
          ...state,
          data: updatedData,
          storyToManage:
            state.storyToManage?.uuid === updatedStory.uuid
              ? updatedStory
              : state.storyToManage,
        };
      }

      return updatedState;
    }

    case HIDE_STORY_TYPES.START:
    case HIDE_STORY_TYPES.FAILURE:
    case SHOW_STORY_TYPES.START:
    case SHOW_STORY_TYPES.FAILURE:
    default: {
      return state;
    }
  }
};
