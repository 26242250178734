import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Twitter = function Twitter(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'twitter__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1026 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      fillOpacity: '1',
      d: 'M920.5 291c.6 9.1.6 18.3.6 27.4 0 278-211.5 598.2-598.2 598.2-119.1 0-229.8-34.5-322.9-94.4 16.9 2 33.2 2.6 50.8 2.6 98.3 0 188.8-33.2 261-89.8-92.4-2-169.9-62.5-196.6-145.8 13 2 26 3.3 39.7 3.3 18.9 0 37.8-2.6 55.3-7.1-96.3-19.7-168.5-104.3-168.5-206.5v-2.6c28 15.6 60.5 25.4 95.1 26.7C80.1 365.2 43 300.8 43 227.9c0-39 10.4-74.8 28.6-106.1 103.5 127.6 259.1 210.9 433.6 220.1-3.3-15.6-5.2-31.9-5.2-48.2 0-115.9 93.8-210.3 210.3-210.3 60.5 0 115.2 25.4 153.7 66.4 47.5-9.1 93.1-26.7 133.4-50.8-15.6 48.8-48.8 89.8-92.4 115.9 42.3-4.6 83.3-16.3 121.1-32.6-28.8 41.7-64.6 78.8-105.6 108.7z' // eslint-disable-line max-len
    })
  );
};

Twitter.propTypes = propTypes;

export default Twitter;