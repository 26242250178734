import React from 'react';
import { IconProps } from '../icon.types';

export const ASquare = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66667 1.33333H13.3333C16.555 1.33333 19.1667 3.94501 19.1667 7.16667V13.8333C19.1667 17.055 16.555 19.6667 13.3333 19.6667H6.66667C3.44501 19.6667 0.833333 17.055 0.833333 13.8333V7.16667C0.833333 3.94501 3.44501 1.33333 6.66667 1.33333ZM0 7.16667C0 3.48477 2.98477 0.5 6.66667 0.5H13.3333C17.0152 0.5 20 3.48477 20 7.16667V13.8333C20 17.5152 17.0152 20.5 13.3333 20.5H6.66667C2.98477 20.5 0 17.5152 0 13.8333V7.16667ZM7.71647 14.4795C7.61068 14.7806 7.44792 14.9108 7.1875 14.9108C6.87012 14.9108 6.66667 14.7318 6.66667 14.4388C6.66667 14.3086 6.69108 14.1784 6.73177 14.0645L9.47428 6.89486C9.61263 6.51237 9.86491 6.33333 10.2148 6.33333C10.5811 6.33333 10.8333 6.52051 10.9717 6.89486L13.7061 14.0645C13.7549 14.1947 13.7874 14.3167 13.7874 14.4388C13.7874 14.7236 13.5677 14.9108 13.2666 14.9108C12.9899 14.9108 12.8353 14.7887 12.7295 14.4795L11.8994 12.1602H8.54655L7.71647 14.4795ZM10.1579 7.62728L8.83952 11.3301H11.6064L10.2881 7.62728H10.1579Z"
        fill={color}
      />
    </svg>
  );
};
