import React from 'react';
import { IconProps } from '../icon.types';

export const Trash = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      aria-hidden={ariaHidden}
      role={role}
      stroke={color}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1276 7V20.16C19.1276 20.624 18.859 21 18.5276 21H5.72756C5.39619 21 5.12756 20.624 5.12756 20.16V7"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1276 17V11"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1276 17V11"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.1276 7H16.1276M3.12756 7H8.12756M8.12756 7V3.6C8.12756 3.26863 8.39619 3 8.72756 3H15.5276C15.859 3 16.1276 3.26863 16.1276 3.6V7M8.12756 7H16.1276"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
