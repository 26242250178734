import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PhotoLarge = function PhotoLarge(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'PhotoLarge__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      null,
      React.createElement(
        'g',
        null,
        React.createElement('path', {
          d: 'M0,0v1000h1000V0H0z M55.6,55.6h888.9V744l-105-94L600.8,894.2L514,805.4L395.8,944.4H55.6V55.6z' // eslint-disable-line max-len
        })
      ),
      React.createElement(
        'g',
        null,
        React.createElement('path', {
          d: 'M436.5,422.9c0-45.9-36.4-83.2-81.3-83.2c-44.9,0-81.3,37.3-81.3,83.2c0,46,36.4,83.2,81.3,83.2C400.2,506.1,436.5,468.9,436.5,422.9z' // eslint-disable-line max-len
        })
      )
    )
  );
};

PhotoLarge.propTypes = propTypes;

export default PhotoLarge;