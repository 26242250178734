import { isObject } from 'lodash';
// A typeguard is a runtime check that allows us to satisfy type definitions.
// "JavaScript doesn't have very rich runtime introspection support built in.
// When you are using just plain JavaScript Objects (using structural typing to your advantage), you do not even have access to instanceof or typeof.
// For these cases you can create User Defined Type Guard functions. These are just functions that return someArgumentName is SomeType."
// https://basarat.gitbook.io/typescript/type-system/typeguard#user-defined-type-guards

import axios, { AxiosError } from 'axios';

export const isNotNull = <T>(x: T): x is NonNullable<T> => !!x;
export const isString = (x: unknown): x is string => typeof x === 'string';

// Currently, Typescript returns all errors in a try catch block as unknown
// in order to access the properties on an error, use the instanceofError typeguards
export const isInstanceOfAxiosError = (error: unknown): error is AxiosError =>
  axios.isAxiosError(error);

export const isInstanceOfError = (error: unknown): error is Error => {
  return error instanceof Error;
};

export const isInstanceOfObjectError = (
  error: unknown,
): error is Record<string, unknown> => {
  return isObject(error);
};

export const isGenericObject = (
  value: unknown,
): value is Record<string, unknown> => {
  return isObject(value);
};
