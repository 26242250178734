import { createSelector } from 'reselect';
import { getTranslate } from 'react-localize-redux';
import { selectGalleriesToEdit } from './galleries.selectors';
import { GALLERY_FORM_FIELDS } from '../../react/features/gallery_form/gallery_form.types';
import {
  createOption,
  createTypeOption,
  createRadioOption,
  createSortedOptions,
  createOptions,
  createFilteredAndSortedOptions,
} from '../../react/features/gallery_form/gallery_form.utils';
import { AppState } from '../../types';
import { NETWORK_TAG_TYPES } from '../network_tags/network_tags.types';
import { GLOBAL_TAG_TYPES } from '../global_tags/global_tags.types';
import { selectDefaultLocationType } from '../locations/locations.selectors';
import { Publisher } from '../user/user.types';
import { selectCurrentPublisher } from '../user/user.selectors';
import { SelectGroupedOptions } from '../../react/blocks/select';

export enum GALLERY_FORM_DEFAULTS {
  PUBLISHER_SIGNALS = '-1',
}

export const selectCreateFormConfig = createSelector(
  selectDefaultLocationType,
  (defaultLocationType) => {
    return {
      [GALLERY_FORM_FIELDS.LOCATION_TYPES]: createTypeOption(
        defaultLocationType,
      ),
      [GALLERY_FORM_FIELDS.PUBLISHER_SIGNALS]:
        GALLERY_FORM_DEFAULTS.PUBLISHER_SIGNALS,
    };
  },
);

export const selectEditFormConfigById = createSelector(
  selectGalleriesToEdit,
  selectDefaultLocationType,
  (galleryToEdit, defaultLocationType) => {
    const campaign = galleryToEdit?.network_tags.find(
      (tags) => tags.type === NETWORK_TAG_TYPES.CAMPAIGN,
    );
    return {
      [GALLERY_FORM_FIELDS.NAME]: galleryToEdit?.label,
      [GALLERY_FORM_FIELDS.PUBLISHER]: galleryToEdit?.publisher_label,
      [GALLERY_FORM_FIELDS.CAMPAIGN]: campaign
        ? createOption(campaign)
        : undefined,
      [GALLERY_FORM_FIELDS.THEMES]: createOptions(
        galleryToEdit?.global_tags ?? [],
      ),
      [GALLERY_FORM_FIELDS.LOCATIONS]: createOptions(
        galleryToEdit?.locations ?? [],
      ),
      [GALLERY_FORM_FIELDS.PERSONAS]: createFilteredAndSortedOptions(
        galleryToEdit?.network_tags ?? [],
        NETWORK_TAG_TYPES.PERSONA,
      ),
      [GALLERY_FORM_FIELDS.LOCATION_TYPES]: createTypeOption(
        galleryToEdit?.locations[0]?.type ?? defaultLocationType,
      ),
      [GALLERY_FORM_FIELDS.PUBLISHER_SIGNALS]:
        galleryToEdit?.publisher_signals &&
        galleryToEdit?.publisher_signals.length > 0
          ? galleryToEdit?.publisher_signals[0]
          : GALLERY_FORM_DEFAULTS.PUBLISHER_SIGNALS,
    };
  },
);

export const selectTaxonomyState = (state: AppState) => state.taxonomy;

export const selectLocationsTypeFormData = createSelector(
  selectTaxonomyState,
  (taxonomyState) => createSortedOptions(taxonomyState.locations.types ?? []),
);

export const selectLocationsFormData = createSelector(
  selectTaxonomyState,
  (taxonomyState) => createSortedOptions(taxonomyState.locations.data),
);

// Themes/global tags are sorted as such:
// Seasons should be grouped together - not grouped alphabetically
// Durations should be grouped together - sorted in ascending order
export const selectGlobalTagsFormData = createSelector(
  selectTaxonomyState,
  (state: AppState) => getTranslate(state.localize),
  (taxonomyState, translate): SelectGroupedOptions => {
    const { data } = taxonomyState.globalTags;

    const seasons = createFilteredAndSortedOptions(
      data,
      GLOBAL_TAG_TYPES.SEASON,
    );
    const durations = createFilteredAndSortedOptions(
      data,
      GLOBAL_TAG_TYPES.DURATION,
    );
    const themes = createFilteredAndSortedOptions(data, GLOBAL_TAG_TYPES.THEME);
    const markets = createFilteredAndSortedOptions(
      data,
      GLOBAL_TAG_TYPES.MARKETS,
    );

    let sortedOptions: SelectGroupedOptions = [];

    if (themes.length) {
      sortedOptions = sortedOptions.concat([
        {
          label: translate('galleries.form.themesLabel') as string,
          options: themes,
        },
      ]);
    }
    if (seasons.length) {
      sortedOptions = sortedOptions.concat([
        {
          label: translate('galleries.form.seasonsLabel') as string,
          options: seasons,
        },
      ]);
    }

    if (durations.length) {
      sortedOptions = sortedOptions.concat([
        {
          label: translate('galleries.form.durationsLabel') as string,
          options: durations,
        },
      ]);
    }

    if (markets.length) {
      sortedOptions = sortedOptions.concat([
        {
          label: translate('galleries.form.marketsLabel') as string,
          options: markets,
        },
      ]);
    }

    return sortedOptions;
  },
);

export const selectNetworkTagData = createSelector(
  selectTaxonomyState,
  (taxonomyState) => taxonomyState.networkTags,
);

export const selectPersonaTagsFormData = createSelector(
  selectNetworkTagData,
  (taxonomyState) =>
    createFilteredAndSortedOptions(
      taxonomyState.data,
      NETWORK_TAG_TYPES.PERSONA,
    ),
);

export const selectCampaignTagsFormData = createSelector(
  selectNetworkTagData,
  (taxonomyState) =>
    createFilteredAndSortedOptions(
      taxonomyState.data,
      NETWORK_TAG_TYPES.CAMPAIGN,
    ),
);

export const selectPublisherSignals = createSelector(
  selectCurrentPublisher,
  (currentPublisher) => {
    const allSignals: Publisher[] = [
      {
        publisherId: GALLERY_FORM_DEFAULTS.PUBLISHER_SIGNALS,
      },
    ];

    if (currentPublisher) {
      allSignals.push(currentPublisher);
    }

    return allSignals;
  },
);

export const selectPublisherSignalsFormData = createSelector(
  selectPublisherSignals,
  (state: AppState) => getTranslate(state.localize),
  (publisherSignals, translate) =>
    publisherSignals.map((p) => createRadioOption(p, translate)),
);
