import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { Tab, Tabs } from '@material-ui/core';
import { TabsBarProps } from './tab_bar.types';
import './tabs_bar.scss';

export const TabsBar: FunctionComponent<TabsBarProps> = ({
  options,
  selectedValue,
  underline = true,
}) => (
  <div
    className={clsx('tabs_bar', {
      tabs_bar__underline: underline,
    })}
  >
    <Tabs
      value={selectedValue}
      TabIndicatorProps={{
        className: 'tabs_bar__indicator',
      }}
    >
      {options.map((option) => (
        <Tab
          value={option.value}
          onClick={option.onClick}
          data-text={option.label}
          label={
            <span data-text={option.label} className="tabs_bar__tab_wrapper">
              {option.label}
            </span>
          }
          key={option.label}
          disableRipple={true}
        />
      ))}
    </Tabs>
  </div>
);
