import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { CRThunk, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { Story, STORY_STATUS } from '../react/features/story/story.types';
import Err from '../types/err';
import {
  selectCurrentNetworkId,
  selectCurrentPublisherId,
} from '../redux/user/user.selectors';
import {
  storiesFailureAction,
  storiesStartAction,
  storiesSuccessAction,
} from '../redux/stories/stories.actions';
import { getErrorMessage } from '../utils';

interface GetStoriesSearchResponse {
  stories: Story[];
  next_page_token?: string;
}

export interface GetStoriesSearchThunkRes {
  stories: Story[];
  nextPageToken?: string | null;
}

export enum STORIES_SEARCH_FILTER_PARAMS {
  SEARCH_QUERY = 'query',
  PUBLISHERS = 'publishers',
  GLOBAL_TAGS = 'global_tag_ids',
  NETWORK_TAGS = 'network_tag_ids',
  STATUS = 'status',
  IS_HIDDEN = 'visibility',
  IS_CHALLENGE_STORY = 'is_challenge_story',
}

export interface SearchFilterReqParams {
  [STORIES_SEARCH_FILTER_PARAMS.SEARCH_QUERY]?: string | null;
  [STORIES_SEARCH_FILTER_PARAMS.PUBLISHERS]?: string[] | null;
  [STORIES_SEARCH_FILTER_PARAMS.GLOBAL_TAGS]?: string[] | null;
  [STORIES_SEARCH_FILTER_PARAMS.NETWORK_TAGS]?: string[] | null;
  [STORIES_SEARCH_FILTER_PARAMS.STATUS]?: STORY_STATUS[] | null;
  [STORIES_SEARCH_FILTER_PARAMS.IS_HIDDEN]?: boolean;
  [STORIES_SEARCH_FILTER_PARAMS.IS_CHALLENGE_STORY]?: boolean;
}

export enum PAGINATION_PARAMS {
  PAGE_SIZE = 'pageSize',
  PAGE_TOKEN = 'pageToken',
}

export interface PaginationParams {
  [PAGINATION_PARAMS.PAGE_TOKEN]?: string | number;
  [PAGINATION_PARAMS.PAGE_SIZE]?: string | number;
}

export enum STORY_SEARCH_LOAD_TYPES {
  INIT = 'initialize',
  LAZY_LOAD = 'lazyload',
  SEARCH = 'search', // search by name or search filters
}

export interface GetStoriesSearchData {
  searchFilters?: SearchFilterReqParams | null;
  pagination?: PaginationParams;
  loadingType?: STORY_SEARCH_LOAD_TYPES;
  excludeUUID?: string;
}

export type GetStoriesSearchThunk = CRThunk<
  GetStoriesSearchData,
  GetStoriesSearchThunkRes
>;

export const getStoriesSearch: GetStoriesSearchThunk = ({
  searchFilters,
  pagination,
  loadingType,
  excludeUUID,
}) => async (dispatch, getState) => {
  const state = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const publisherId: string = selectCurrentPublisherId(state);
  const pageSize = pagination ? pagination[PAGINATION_PARAMS.PAGE_SIZE] : '';
  const pageToken = pagination ? pagination[PAGINATION_PARAMS.PAGE_TOKEN] : '';

  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.STORIES_SEARCH,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
    },
  });

  const urlWithPagination: string = `${url}?page_size=${pageSize}${
    pageToken ? `&page_token=${pageToken}` : ''
  }`;

  const reqBody = searchFilters ?? {};
  dispatch(storiesStartAction({ loadingType }));

  try {
    const response: AxiosResponse<GetStoriesSearchResponse> = await axios.post<
      GetStoriesSearchResponse
    >(urlWithPagination, {
      filters: reqBody,
    });

    const storiesSearchSuccessPayload = {
      stories: response.data.stories ?? [],
      nextPageToken: response.data.next_page_token ?? null,
    };

    if (excludeUUID) {
      storiesSearchSuccessPayload.stories = storiesSearchSuccessPayload.stories.filter(
        (story) => story.uuid !== excludeUUID,
      );
    }

    if (searchFilters != null) {
      dispatch(storiesSuccessAction(storiesSearchSuccessPayload));
    }
    return storiesSearchSuccessPayload;
  } catch (err) {
    dispatch(storiesFailureAction('Failed to get stories with filters'));
    throw new Err(
      `Failed to get stories: ${getErrorMessage(err)}`,
      'stories not found.',
    );
  }
};
