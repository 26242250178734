import './menu.scss';
import React, { useEffect, useState, Fragment } from 'react';
import { ClickAwayListener, List, ListItem } from '@material-ui/core';
import { MenuItem, MenuProps } from '.';
import { useDebounce } from '../../hooks/useDebounce';
import { Icon, ICON_NAMES, ICON_SIZES } from '../icon';

const MenuList = ({
  list,
  isOpen = false,
  onClose,
  placeholder,
  isSearchable = false,
}: Omit<MenuProps, 'children'>) => {
  const [listItems, setListItems] = useState<MenuItem[]>(list);
  const [inputValue, setInputValue] = useState<string>('');

  // if the original list changes, reset list items
  useEffect(() => {
    setListItems(list);
  }, [list]);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.target.value);
  };

  const filterList = (val: string) => {
    setListItems(
      val ? list.filter((item: MenuItem) => item.text.includes(val)) : list,
    );
  };

  const debounceSearchTerm = useDebounce(filterList, 200);

  useEffect(() => {
    debounceSearchTerm(inputValue);
  }, [inputValue]);

  const createMenuItem = (item: MenuItem) => {
    let menuItem: React.ReactNode = <Fragment />;
    const itemClassName = item.args?.className ?? '';
    if (item.url) {
      menuItem = (
        <a
          {...item.args}
          className={`menu-item__link ${itemClassName}`}
          href={item.url}
        >
          {item.text}
          {item.iconKey && (
            <Icon
              iconKey={item.iconKey}
              size={ICON_SIZES.MED}
              ariaHidden={true}
              className="menu-item__icon"
            />
          )}
          {item.description && (
            <span className="menu-item__description">{item.description}</span>
          )}
        </a>
      );
    } else if (item.action) {
      menuItem = (
        <button
          {...item.args}
          type="button"
          className={`menu-item__btn ${itemClassName}`}
          onClick={() => {
            setInputValue('');
            item.action?.();
            onClose?.();
          }}
        >
          {item.text}
          {item.iconKey && (
            <Icon
              iconKey={item.iconKey}
              size={ICON_SIZES.SMALL}
              ariaHidden={true}
              className="menu-item__icon"
            />
          )}
          {item.description && (
            <span className="menu-item__description">{item.description}</span>
          )}
        </button>
      );
    }
    return menuItem;
  };

  return (
    <div className="menu_list_wrapper">
      <List className="menu menu--fixed" hidden={!isOpen} disablePadding={true}>
        {isSearchable && (
          <li className="menu_searchable__input_wrapper">
            <Icon iconKey={ICON_NAMES.SEARCH} size={ICON_SIZES.SMALL} />
            <input
              type="text"
              placeholder={placeholder}
              value={inputValue}
              className="menu_searchable__input_box"
              onChange={handleInputChange}
            />
            <hr className="menu_searchable__input_divider" />
          </li>
        )}
        {listItems.map((item) => (
          <ListItem key={item.id} className="menu-item" disableGutters={true}>
            {createMenuItem(item)}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

/**
 * HOC of Menu, enabling ClickAwayListener to be a wrapper
 *  for Menu and for whichever element is clicked to open
 *  that Menu (e.g. button).
 *
 * @param props same props as Menu, with the addition of:
 *  children - can be used to pass element that opens menu
 */
export const Menu = (props: MenuProps) => {
  const {
    children,
    className = '',
    list = [],
    isOpen = false,
    onClose,
    placeholder,
    isSearchable = false,
  } = props;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={`menu_container ${className}`}>
        {children}
        <MenuList
          list={list}
          isOpen={isOpen}
          onClose={onClose}
          placeholder={placeholder}
          isSearchable={isSearchable}
        />
      </div>
    </ClickAwayListener>
  );
};
