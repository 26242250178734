/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var RightsFailed = function RightsFailed(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      'aria-hidden': true,
      height: size,
      viewBox: '0 0 1000 1000',
      fill: color,
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon rightsFailed',
      id: 'rightsFailed__icon'
    },
    React.createElement('path', { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M516.953 996.1C533.598 989 927.629 815.8 927.629 541.7H927.835V41.6997C927.835 18.8997 908.417 0 884.99 0H114.394C90.9677 0 71.5487 18.8997 71.5487 41.6997V541.7C71.5487 815.7 465.478 988.9 482.225 996.1C487.567 998.7 493.527 1000 499.589 1000C505.548 1000 511.61 998.7 516.953 996.1ZM668.668 204.888L733.758 270.043L566.998 436.97L733.761 603.899L668.671 669.054L501.908 502.125L335.147 669.054L270.057 603.899L436.819 436.97L270.059 270.044L335.149 204.889L501.908 371.815L668.668 204.888Z' })
  );
};

RightsFailed.propTypes = propTypes;

export default RightsFailed;