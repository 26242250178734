import { AxiosResponse } from 'axios';
import Axios from './axios_config';
import { Story } from '../react/features/story/story.types';
import { selectCurrentNetworkId } from '../redux/user/user.selectors';
import { AppState } from '../types';
import { CRThunkDispatch, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { getErrorMessage } from '../utils';

export interface GetStoryResponse {
  story: Story;
}
type GetStoryThunk = (
  uuid: string,
) => (
  dispatch: CRThunkDispatch,
  getState: () => AppState,
) => Promise<GetStoryResponse>;

export const getStory: GetStoryThunk = (uuid: string) => async (
  dispatch: CRThunkDispatch,
  getState: () => AppState,
): Promise<GetStoryResponse> => {
  const state: AppState = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const url: string = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.STORY,
    params: {
      networkId,
      uuid,
    },
  });
  try {
    const res: AxiosResponse<GetStoryResponse> = await Axios.get<
      GetStoryResponse
    >(url);
    return res.data;
  } catch (err) {
    throw new Error(`Failed to get story: ${getErrorMessage(err)}`);
  }
};
