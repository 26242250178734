import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Eye = function Eye(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'eye',
      height: size,
      viewBox: '0 0 1000 1000',
      fill: color,
      'aria-hidden': true,
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M500 214.286c-210.87 0-394.565 126.857-500 318.857C104.348 725.143 289.13 852 500 852s394.565-128 500-318.857c-105.435-192-289.13-318.857-500-318.857zM500 772c-126.087 0-227.174-107.429-227.174-238.857C272.826 400.571 375 294.286 500 294.286c126.087 0 227.174 107.428 227.174 238.857C727.174 665.714 626.087 772 500 772z' // eslint-disable-line max-len
    }),
    React.createElement('path', {
      d: 'M500.002 687.425c81.042 0 146.739-69.076 146.739-154.286 0-85.209-65.697-154.285-146.739-154.285S353.263 447.93 353.263 533.139c0 85.21 65.697 154.286 146.739 154.286z' // eslint-disable-line max-len
    })
  );
};

Eye.propTypes = propTypes;

export default Eye;