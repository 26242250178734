import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var FolderOpen = function FolderOpen(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'FolderOpen__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1250 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1250,500l-250,500H0l250-500H1250z M187.5,375H1000V166.7H416.7L291.7,0H0v791.7L187.5,375z' // eslint-disable-line max-len
    })
  );
};

FolderOpen.propTypes = propTypes;

export default FolderOpen;