import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var GalleryFullscreenBase = function GalleryFullscreenBase(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'GalleryFullscreenBase__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M0,0v833.2h1000V0H0z M50,50H950v533.2l-200.2-175l-275.2,275l-100.1-100l-174.3,200H50V50z M412.6,339.6\tc0,51.7-42,93.6-93.7,93.6s-93.7-41.9-93.7-93.6s42-93.6,93.7-93.6S412.6,287.9,412.6,339.6z M1000,1000H0V900h1000V1000z' // eslint-disable-line max-len
    })
  );
};

GalleryFullscreenBase.propTypes = propTypes;

export default GalleryFullscreenBase;