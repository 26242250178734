import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../../types';
import { selectCurrentPublisherId } from '../../../../redux/user/user.selectors';
import { UseStorySection } from './use_story.component';

const mapStateToProps = (state: AppState) => {
  return {
    currentPublisherId: selectCurrentPublisherId(state),
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export const UseStoryConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UseStorySection);
