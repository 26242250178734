import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.number,
  iconStyle: PropTypes.string,
  fillColor: PropTypes.string
};
/* eslint-disable max-len */
var Bus = function Bus(_ref) {
  var size = _ref.size,
      iconStyle = _ref.iconStyle,
      fillColor = _ref.fillColor;

  return React.createElement(
    'svg',
    {
      version: '1.1',
      xmlns: 'http://www.w3.org/2000/svg',
      x: '0px',
      y: '0px',
      viewBox: '0 0 124 124',
      style: { enableBackground: 'new 0 0 124 124' },
      height: size,
      fill: iconStyle !== 'outline-white' ? '#2A2F35' : '#FFF'
    },
    React.createElement(
      'g',
      null,
      iconStyle === 'fill' ? React.createElement(
        'g',
        { fill: fillColor },
        React.createElement('path', { d: 'M26.4,11.6c-1.4-1.9,7.9,0.9,7.9,7.4h-7C27.3,19,28.9,15,26.4,11.6z' }),
        React.createElement('circle', { cx: '19.8', cy: '100', r: '9' }),
        React.createElement('circle', { cx: '87.8', cy: '100', r: '9' }),
        React.createElement('path', {
          d: 'M74.8,47c0-1.1,0.9-2,2-2H103l-3.4-5.1c-0.4-0.6-1-0.9-1.7-0.9H6.5L3.6,63h71.2L74.8,47z M50.8,55 c0,1.1-0.9,2-2,2h-8c-1.1,0-2-0.9-2-2v-8c0-1.1,0.9-2,2-2h8c1.1,0,2,0.9,2,2V55z M68.8,55c0,1.1-0.9,2-2,2h-8c-1.1,0-2-0.9-2-2v-8 c0-1.1,0.9-2,2-2h8c1.1,0,2,0.9,2,2V55z'
        })
      ) : null,
      React.createElement(
        'g',
        null,
        React.createElement('path', {
          className: 'st3',
          d: 'M21.8,90.3c-5.4,0-9.8,4.4-9.8,9.8s4.4,9.8,9.8,9.8s9.8-4.4,9.8-9.8S27.2,90.3,21.8,90.3z M21.8,108.3 c-4.5,0-8.2-3.7-8.2-8.2s3.7-8.2,8.2-8.2S30,95.5,30,100S26.3,108.3,21.8,108.3z'
        }),
        React.createElement('path', {
          className: 'st3',
          d: 'M89.8,90.3c-5.4,0-9.8,4.4-9.8,9.8s4.4,9.8,9.8,9.8s9.8-4.4,9.8-9.8S95.2,90.3,89.8,90.3z M89.8,108.3 c-4.5,0-8.2-3.7-8.2-8.2s3.7-8.2,8.2-8.2S98,95.5,98,100S94.3,108.3,89.8,108.3z'
        }),
        React.createElement('path', {
          className: 'st3',
          d: 'M120,78.2h-2.3V67c0-1.8-1-3.3-2.5-4.1c0.2-0.2,0.3-0.4,0.4-0.6c0.5-0.9,0.4-2-0.1-2.8v0l-13.2-19.7h3.5 c1,0,1.8-0.5,2.3-1.3c0.5-0.8,0.5-1.8,0.1-2.7l-2-4c-0.5-0.9-1.4-1.5-2.5-1.5H81.3v-4.5H100c0.1,0,0.2,0,0.4,0 c2.5,0,13.5-0.1,15.8-2.5c0.5-0.5,0.6-1,0.6-1.3s-0.1-0.8-0.6-1.3c-2.5-2.5-14.8-2.5-16.2-2.5l-63,0c-0.4-4.1-4.2-6.9-6.9-7.7 c-1.2-0.4-2-0.3-2.4,0.2c-0.1,0.2-0.4,0.7,0.1,1.3c1.7,2.4,1.2,5.1,0.9,6.2h-8.8c-1.5,0-2.8,1.2-2.8,2.8v2c0,1.5,1.2,2.8,2.8,2.8 H40v4.5H13.1c-2.4,0-4.4,1.8-4.7,4.2L1.3,92.5c-0.2,1.3,0.3,2.7,1.2,3.7c0.9,1,2.2,1.6,3.6,1.6h4.8l0.1-0.6 c1.3-5,5.8-8.4,10.9-8.4s9.6,3.5,10.9,8.4l0.1,0.6h46l0.1-0.6c1.3-5,5.8-8.4,10.9-8.4s9.6,3.5,10.9,8.4l0.1,0.6H113 c2.6,0,4.8-2.1,4.8-4.8v-3.3h2.3c1.5,0,2.8-1.2,2.8-2.8v-6C122.8,79.5,121.5,78.2,120,78.2z M32.7,13.7c1.1,0.9,2.5,2.5,2.7,4.6 h-5.2c0.3-1.4,0.6-3.9-0.8-6.4C30.3,12.1,31.6,12.7,32.7,13.7z M114.3,60.3L114.3,60.3c0.3,0.4,0.3,0.9,0.1,1.3s-0.6,0.7-1.1,0.7 H113H77.5l0-15.3c0-0.7,0.6-1.2,1.2-1.2h25.8L114.3,60.3z M75.8,25.8v4.5H45.5v-4.5H75.8z M79.8,30.2h-2.5v-4.5h2.5V30.2z M18.7,23v-2c0-0.7,0.6-1.2,1.2-1.2l80.1,0c5.1,0,13.6,0.5,15.1,2c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.2 c-1.6,1.5-10.1,2.1-15.1,2H19.9C19.2,24.2,18.7,23.7,18.7,23z M41.5,25.8H44v4.5h-2.5V25.8z M9.8,34.6L9.8,34.6 c0.2-1.6,1.6-2.8,3.2-2.8h90.8c0.5,0,0.9,0.3,1.1,0.7l2,4c0.2,0.4,0.2,0.8-0.1,1.2c-0.2,0.4-0.6,0.6-1.1,0.6H9.4L9.8,34.6z M99.9,39.8c0.4,0,0.8,0.2,1,0.6l2.6,3.9H78.8h0c-1.5,0-2.8,1.2-2.8,2.8l0,15.3H6.5l2.7-22.5H99.9z M116.2,93 c0,1.8-1.5,3.2-3.2,3.2h-11c-1.6-5.3-6.6-9-12.2-9s-10.5,3.7-12.2,9H34c-1.6-5.3-6.6-9-12.2-9s-10.5,3.7-12.2,9H6 c-0.9,0-1.8-0.4-2.4-1.1c-0.6-0.7-0.9-1.6-0.8-2.5l3.5-28.9l106.7,0c1.8,0,3.3,1.5,3.3,3.2V93z M121.3,87c0,0.7-0.6,1.2-1.2,1.2 h-2.3v-8.5h2.3c0.7,0,1.2,0.6,1.2,1.2V87z'
        }),
        React.createElement('path', { className: 'st3', d: 'M76.2,106.2h-8c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h8c0.4,0,0.8-0.3,0.8-0.8S76.6,106.2,76.2,106.2z' }),
        React.createElement('path', {
          className: 'st3',
          d: 'M63,107c0-0.4-0.3-0.8-0.8-0.8h-16c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h16C62.6,107.8,63,107.4,63,107z'
        }),
        React.createElement('path', { className: 'st3', d: 'M42.2,112.3h-4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h4c0.4,0,0.8-0.3,0.8-0.8S42.6,112.3,42.2,112.3z' }),
        React.createElement('path', { className: 'st3', d: 'M74.2,112.3h-26c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h26c0.4,0,0.8-0.3,0.8-0.8S74.6,112.3,74.2,112.3z' }),
        React.createElement('path', { className: 'st3', d: 'M4,107.8h4c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H4c-0.4,0-0.8,0.3-0.8,0.8S3.6,107.8,4,107.8z' }),
        React.createElement('path', { className: 'st3', d: 'M16,112.3H4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h12c0.4,0,0.8-0.3,0.8-0.8S16.4,112.3,16,112.3z' }),
        React.createElement('path', {
          className: 'st3',
          d: 'M60.8,57.7h8c1.5,0,2.8-1.2,2.8-2.8v-8c0-1.5-1.2-2.8-2.8-2.8h-8c-1.5,0-2.8,1.2-2.8,2.8v8 C58,56.5,59.3,57.7,60.8,57.7z M59.5,47c0-0.7,0.6-1.2,1.2-1.2h8c0.7,0,1.2,0.6,1.2,1.2v8c0,0.7-0.6,1.2-1.2,1.2h-8 c-0.7,0-1.2-0.6-1.2-1.2V47z'
        }),
        React.createElement('path', {
          className: 'st3',
          d: 'M42.8,57.7h8c1.5,0,2.8-1.2,2.8-2.8v-8c0-1.5-1.2-2.8-2.8-2.8h-8c-1.5,0-2.8,1.2-2.8,2.8v8 C40,56.5,41.3,57.7,42.8,57.7z M41.5,47c0-0.7,0.6-1.2,1.2-1.2h8c0.7,0,1.2,0.6,1.2,1.2v8c0,0.7-0.6,1.2-1.2,1.2h-8 c-0.7,0-1.2-0.6-1.2-1.2V47z'
        })
      )
    )
  );
};

Bus.propTypes = propTypes;

export default Bus;