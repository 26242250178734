import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Envelope = function Envelope(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'envelope',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1229.3 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1112,297.7c-107,74.1-214.7,148.2-321,222.9c-44.6,30.9-120.1,94-175.6,94h-0.7h-0.7c-55.5,0-131-63.1-175.6-94C332,445.9,224.3,371.8,118,297.7C69.3,264.8,0,187.3,0,124.8C0,57.6,36.4,0,109.7,0h1009.8c59.7,0,109.7,49.4,109.7,109.7C1229.3,186.6,1172.3,255.9,1112,297.7z M1229.3,890.2c0,60.4-49.4,109.8-109.7,109.8H109.7C49.4,1000,0,950.6,0,890.2V345.6c20.6,22.6,43.9,42.5,69.3,59.7c113.9,77.5,229.1,155,341,236.6c57.6,42.5,128.9,94.7,203.7,94.7h0.7h0.7c74.8,0,146.1-52.1,203.7-94.7c111.8-80.9,227.1-159.1,341.6-236.6c24.7-17.2,48-37.1,68.6-59.7V890.2z' // eslint-disable-line max-len
    })
  );
};

Envelope.propTypes = propTypes;

export default Envelope;