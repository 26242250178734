import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../types';
import { StoryManagePage } from './story_manage.component';
import {
  selectStoryToManage,
  selectStoryToManageDefaultVals,
} from '../../../redux/stories/stories.selectors';
import { getStory } from '../../../http/story_get.thunks';
import { selectCurrentPublisherId } from '../../../redux/user/user.selectors';
import { hideStory } from '../../../http/story_hide.thunk';
import { showStory } from '../../../http/story_show.thunk';
import { deleteStory } from '../../../http/story_delete.thunk';
import { Story } from '../../features/story/story.types';

const mapStateToProps = (state: AppState) => {
  return {
    story: selectStoryToManage(state),
    defaultFormValues: selectStoryToManageDefaultVals(state),
    currentPublisherId: selectCurrentPublisherId(state),
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getStory: (story_uuid: string) => dispatch(getStory(story_uuid)),
  onHideStory: (story_uuid: string) => dispatch(hideStory(story_uuid)),
  onShowStory: (story_uuid: string) => dispatch(showStory(story_uuid)),
  onDeleteStory: (story: Story | null) => dispatch(deleteStory({ story })),
});
export const StoryManageConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StoryManagePage);
