import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var DollarSignNew = function DollarSignNew(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'dollarSignNew__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      vectorEffect: 'non-scaling-stroke',
      d: 'M454.958 999.991V883.033C420.65 879.132 384.393 869.776 346.187 854.964C308.76 839.367 276.401 819.091 249.111 794.146L323.965 678.355C353.594 700.967 381.665 718.121 408.175 729.816C435.466 740.733 463.146 746.191 491.216 746.191C523.964 746.191 547.746 739.563 562.56 726.308C578.155 713.052 585.952 692.78 585.952 665.49C585.952 645.217 578.155 628.063 562.56 614.028C547.746 599.993 528.642 586.738 505.251 574.263C482.638 561.007 458.077 548.142 431.567 535.667C405.056 522.411 380.105 506.817 356.713 488.883C334.101 470.95 314.998 449.897 299.403 425.726C284.588 401.555 277.181 371.926 277.181 336.838C277.181 279.139 292.776 231.576 323.965 194.15C355.934 155.944 399.598 131.383 454.958 120.467V0H568.408V118.127C605.835 123.585 638.584 134.892 666.654 152.045C694.724 169.199 720.065 189.862 742.677 214.033L656.127 312.277C635.075 292.785 614.802 278.36 595.309 269.003C575.816 258.867 553.594 253.798 528.642 253.798C500.572 253.798 479.13 259.646 464.315 271.342C449.501 283.038 442.093 302.141 442.093 328.651C442.093 347.365 449.501 363.349 464.315 376.604C479.91 389.079 499.013 401.165 521.625 412.861C545.017 424.557 569.968 436.643 596.478 449.118C622.989 461.593 647.55 476.798 670.162 494.731C693.554 512.665 712.658 534.497 727.472 560.228C743.067 585.179 750.864 616.367 750.864 653.794C750.864 709.934 735.659 758.276 705.25 798.824C674.841 838.59 629.227 865.097 568.408 878.355V999.991H454.958Z'
    })
  );
};

DollarSignNew.propTypes = propTypes;

export default DollarSignNew;