import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import Err from '../types/err';
import { getErrorMessage } from '../utils';

export interface AssignStoryRequest {
  story_id: string;
  challenge_id: string;
}

export const assignStory = async ({
  story_id,
  challenge_id,
}: AssignStoryRequest) => {
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.ASSIGN_STORY,
    params: {
      uuid: story_id,
      challengeId: challenge_id,
    },
  });

  try {
    const response: AxiosResponse = await axios.post(url);
    return response;
  } catch (err) {
    throw new Err(
      `Failed to assign story to challenge: ${getErrorMessage(err)}`,
      `Failed to assign`,
    );
  }
};
