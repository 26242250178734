import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Briefcase = function Briefcase(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Briefcase__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1166.7 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M187.5,1000h-41.7C65.8,1000,0,934.2,0,854.2V312.5c0-80.1,65.8-145.8,145.8-145.8h41.7V1000z M916.7,1000H250V166.7h83.3\t\tV62.5c0-34.5,28-62.5,62.5-62.5h375c34.5,0,62.5,28,62.5,62.5v104.2h83.3V1000z M750,166.7V83.3H416.7v83.3H750z M1166.7,854.2\tc0,80.1-65.7,145.8-145.8,145.8h-41.6V166.7h41.6c80.1,0,145.8,65.7,145.8,145.8V854.2z' // eslint-disable-line max-len
    })
  );
};

Briefcase.propTypes = propTypes;

export default Briefcase;