import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { AppState, AppActions } from '../types';
import Err from '../types/err';
import { HydratedGallery } from '../redux/galleries/galleries.types';
import {
  selectCurrentNetworkId,
  selectCurrentPublisherId,
} from '../redux/user/user.selectors';
import { galleriesLoadSuccess } from '../redux/galleries/galleries.actions';
import { getErrorMessage } from '../utils';

export type GetGalleriesThunkAction = ThunkAction<
  Promise<GetGalleriesResponse>,
  AppState,
  {},
  AppActions
>;

export type GetGalleriesResponse = {
  galleries: HydratedGallery[];
};

export const getGalleries: GetGalleriesThunkAction = async (
  dispatch,
  getState,
) => {
  const state: AppState = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const publisherId = selectCurrentPublisherId(state);
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.GALLERIES,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
    },
  });
  try {
    const response: AxiosResponse<GetGalleriesResponse> = await axios.get<
      GetGalleriesResponse
    >(url, {
      params: { publisher_ids: publisherId, page_size: '100' },
    });
    dispatch(galleriesLoadSuccess(response.data.galleries));
    return response.data;
  } catch (err) {
    throw new Err(
      `Failed to get galleries: ${getErrorMessage(err)}`,
      'Gallery not found.',
    );
  }
};
