import React, { FunctionComponent } from 'react';
import { TranslateFunction, Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { HydratedGallery } from '../../../../redux/galleries/galleries.types';
import './gallery_list_grid_card_delete_modal.scss';
import { AppState } from '../../../../types';
import {
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
  Button,
} from '../../../blocks/button';
import { deleteGallery } from '../../../../http/gallery_delete.thunk';
import { triggerHydrateAction } from '../../../pages/galleries_list/galleries_list.actions';
import { ModalButtons, ModalContent } from '../../../blocks/modal';
import { undeleteGallery } from '../../../../http/gallery_undelete.thunk';
import {
  ModalProps,
  ConnectedModalButton as ModalButton,
  ConnectedModalTitle as ModalTitle,
} from '../../../blocks/connected_modal';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { useToast } from '../../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../../blocks/toast';

export interface GalleryListGridCardDeleteModalComponentProps {
  translate: TranslateFunction;
  gallery: HydratedGallery;
  toggle: () => void;
  Modal: FunctionComponent<ModalProps>;
}

export const GalleryListGridCardDeleteModalComponent = ({
  gallery,
  translate,
  toggle,
  Modal,
}: GalleryListGridCardDeleteModalComponentProps) => {
  const dispatch = useDispatch<ThunkDispatch<AppState, {}, AnyAction>>();
  const { showToast } = useToast();
  const getTranslation = useTranslateStrings(translate);

  return (
    <Modal className="gallery_list_grid_card_delete_modal">
      <ModalTitle label={translate('galleries.modal.deleteGalleryTitle')} />
      <ModalContent>
        <p>
          {translate(`galleries.modal.deleteGalleryContentDescription`, {
            galleryLabel: gallery.label,
          })}
        </p>
        <p>{translate(`galleries.modal.deleteGalleryContentListIntro`)}</p>
        <ul className="gallery_list_grid_card_delete_modal--list">
          <li>{translate(`galleries.modal.deleteGalleryContentListItem1`)}</li>
          <li>{translate(`galleries.modal.deleteGalleryContentListItem2`)}</li>
          <li>{translate(`galleries.modal.deleteGalleryContentListItem3`)}</li>
        </ul>
      </ModalContent>
      <ModalButtons>
        <ModalButton
          onClick={toggle}
          variant={BUTTON_VARIANT.OUTLINE}
          color={BUTTON_COLOR_VARIANT.SECONDARY}
        >
          <Translate id="galleries.modal.deleteGalleryKeepButton" />
        </ModalButton>
        <ModalButton
          onClick={async () => {
            try {
              await dispatch(deleteGallery(gallery.id));
              showToast({
                message: getTranslation(`galleries.embed.deletedGallery`, {
                  galleryLabel: gallery.label,
                }),
                type: TOAST_TYPES.SUCCESS,
                customActionCTA: (
                  <Button
                    onClick={() => {
                      dispatch(undeleteGallery(gallery.id));
                    }}
                    variant={BUTTON_VARIANT.GHOST}
                  >
                    {getTranslation('galleries.embed.deletedGalleryUndo')}
                  </Button>
                ),
              });
              dispatch(triggerHydrateAction());
            } catch (err) {
              showToast({
                message: `${getTranslation('galleries.embed.error')}`,
                type: TOAST_TYPES.ERROR,
              });
            }
          }}
          color={BUTTON_COLOR_VARIANT.DESTRUCTIVE}
        >
          <Translate id="galleries.modal.deleteGalleryConfirmButton" />
        </ModalButton>
      </ModalButtons>
    </Modal>
  );
};
