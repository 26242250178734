import React from 'react';
import { IconProps } from '../icon.types';

export const PlusCircleFill = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 18.4736C14.6318 18.4736 18.4668 14.6304 18.4668 10.0068C18.4668 5.375 14.6235 1.54004 9.9917 1.54004C5.36816 1.54004 1.5332 5.375 1.5332 10.0068C1.5332 14.6304 5.37646 18.4736 10 18.4736ZM5.94922 10.0151C5.94922 9.5835 6.24805 9.28467 6.67969 9.28467H9.27783V6.68652C9.27783 6.25488 9.56836 5.94775 9.9917 5.94775C10.4233 5.94775 10.7222 6.25488 10.7222 6.68652V9.28467H13.3286C13.752 9.28467 14.0591 9.5835 14.0591 10.0151C14.0591 10.4385 13.752 10.7207 13.3286 10.7207H10.7222V13.3271C10.7222 13.7588 10.4233 14.0576 9.9917 14.0576C9.56836 14.0576 9.27783 13.7505 9.27783 13.3271V10.7207H6.67969C6.24805 10.7207 5.94922 10.4385 5.94922 10.0151Z" />
    </svg>
  );
};
