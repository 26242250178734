import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../../types';
import { StoryListModerationAndStatus as StoryListModerationAndStatusComponent } from './story_list_moderation_and_status.component';
import {
  selectAllStories,
  selectStoriesFilterTab,
  selectStoriesLoadingType,
  selectStoriesSearchFilters,
  selectStoriesSearchQuery,
} from '../../../../redux/stories/stories.selectors';
import { selectIsLocalhoodOwner } from '../../../../redux/user/user.selectors';
import { hideStory } from '../../../../http/story_hide.thunk';
import { showStory } from '../../../../http/story_show.thunk';

const mapStateToProps = (state: AppState) => ({
  stories: selectAllStories(state),
  loadingType: selectStoriesLoadingType(state),
  isChallengeModerator: selectIsLocalhoodOwner(state),
  mainListFilterData: selectStoriesSearchFilters(state) ?? {},
  searchQuery: selectStoriesSearchQuery(state),
  selectedFilterTab: selectStoriesFilterTab(state),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onHideStory: (story_uuid: string) => dispatch(hideStory(story_uuid)),
  onShowStory: (story_uuid: string) => dispatch(showStory(story_uuid)),
});

export const StoryListModerationAndStatus = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StoryListModerationAndStatusComponent);
