import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Play = function Play(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Play__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 906.7 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M36.1,995.5c-20,11-36.1,1.3-36.1-21.3V25.8C0,3.2,16.1-6.4,36.1,4.5L891.7,480c20,11,20,29,0,39.9L36.1,995.5z' // eslint-disable-line max-len
    })
  );
};

Play.propTypes = propTypes;

export default Play;