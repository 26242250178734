import React from 'react';
import { IconProps } from '../icon.types';

export const Crop = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.894043 5.81494C0.894043 6.21338 1.15137 6.4458 1.5498 6.4458H4.88672V14.7051C4.88672 15.1035 5.14404 15.3608 5.54248 15.3608H13.7686V18.6562C13.7686 19.0713 14.0342 19.3535 14.4326 19.3535C14.8394 19.3535 15.105 19.0713 15.105 18.6562V15.3608H18.4336C18.8403 15.3608 19.0977 15.1201 19.0977 14.7383C19.0977 14.3481 18.8403 14.1074 18.4336 14.1074H15.105V5.85645C15.105 5.45801 14.8477 5.19238 14.4492 5.19238H6.22314V1.89697C6.22314 1.48193 5.95752 1.19971 5.55078 1.19971C5.15234 1.19971 4.88672 1.48193 4.88672 1.89697V5.19238H1.5498C1.15137 5.19238 0.894043 5.43311 0.894043 5.81494ZM6.46387 14.1074C6.28125 14.1074 6.22314 14.0493 6.22314 13.8667V6.4458H13.5278C13.7104 6.4458 13.7686 6.51221 13.7686 6.68652V14.1074H6.46387Z" />
    </svg>
  );
};
