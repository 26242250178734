import { STORIES_SEARCH_TYPES } from '../../react/pages/stories_list/stories_list.types';
import { Story } from '../../react/features/story/story.types';
import { StaticFilterMap } from '../../react/features/stories_search_form.component/stories_search_form.types';
import { DynamicFilterType } from '../../react/features/stories_search_dynamic_filters/stories_search_dynamic_filters.types';
import { STORY_SEARCH_LOAD_TYPES } from '../../http/stories_search.thunks';

export enum HIDE_STORY_TYPES {
  START = 'HIDE_STORY/START',
  SUCCESS = 'HIDE_STORY/SUCCESS',
  FAILURE = 'HIDE_STORY/FAILURE',
}

export enum SHOW_STORY_TYPES {
  START = 'SHOW_STORY/START',
  SUCCESS = 'SHOW_STORY/SUCCESS',
  FAILURE = 'SHOW_STORY/FAILURE',
}

export enum DOWNLOAD_STORY_TYPES {
  START = 'DOWNLOAD_STORY/START',
  SUCCESS = 'DOWNLOAD_STORY/SUCCESS',
  FAILURE = 'DOWNLOAD_STORY/FAILURE',
}

export interface SearchFilters {
  // This should reflect what will be used to rerender filter form default values
  publishers?: string[];
  global_tag_ids?: string[];
  network_tag_ids?: string[];
  status?: string[];
  visibility?: string[];
  staticFilterMap?: StaticFilterMap | null;
  dynamicFilterMap?: DynamicFilterType[] | null;
  is_challenge_story?: boolean;
}

export enum StoriesFilterTab {
  MY_NETWORK = 'MY_NETWORK',
  CREATOR_STORIES = 'CREATOR_STORIES',
  ALL = 'ALL',
}

export interface StoriesState {
  storyToManage: Story | null;
  data: Story[];
  searchFilters: SearchFilters;
  error?: string;
  isLoading: boolean;
  loadingType: STORY_SEARCH_LOAD_TYPES | null;
  nextPageToken: string | null;
  searchQuery: string;
  storiesFilterTab: StoriesFilterTab;
}

export interface StoryStat {
  story_uuid: string;
  publisher_slug: string;
  views: number;
  completions: number;
  swipe_ups: number;
  page_views: number;
  learn_more_clicks: number;
  book_clicks: number;
  total_time: number;
  web_impressions: number | null;
  discover_impressions: number | null;
  stats_date: string;
  impressions_date: string | null;
}

export interface StoriesSearchFiltersAction {
  type: STORIES_SEARCH_TYPES.SEARCH_FILTERS;
  payload: SearchFilters;
}
export interface StoriesClearSearchFiltersAction {
  type: STORIES_SEARCH_TYPES.CLEAR_FILTERS;
  payload: {};
}

export interface StoriesUpdateFilterTabAction {
  type: STORIES_SEARCH_TYPES.UPDATE_FILTER_TAB;
  payload: StoriesFilterTab;
}

export interface StoriesResetFilterTabAction {
  type: STORIES_SEARCH_TYPES.RESET_FILTER_TAB;
}

export interface HideStoryStartAction {
  type: HIDE_STORY_TYPES.START;
  payload: {};
}
export interface HideStorySuccessAction {
  type: HIDE_STORY_TYPES.SUCCESS;
  payload: string;
}
export interface HideStoryFailureAction {
  type: HIDE_STORY_TYPES.FAILURE;
  payload: Error;
}

export interface ShowStoryStartAction {
  type: SHOW_STORY_TYPES.START;
  payload: {};
}
export interface ShowStorySuccessAction {
  type: SHOW_STORY_TYPES.SUCCESS;
  payload: string;
}
export interface ShowStoryFailureAction {
  type: SHOW_STORY_TYPES.FAILURE;
  payload: Error;
}

export interface DownloadStoryStartAction {
  type: DOWNLOAD_STORY_TYPES.START;
  payload: {};
}
export interface DownloadStorySuccessAction {
  type: DOWNLOAD_STORY_TYPES.SUCCESS;
  payload: {
    storyId: string;
    url: string;
  };
}
export interface DownloadStoryFailureAction {
  type: DOWNLOAD_STORY_TYPES.FAILURE;
  payload: string;
}

export type StoriesActions =
  | StoriesSearchFiltersAction
  | StoriesClearSearchFiltersAction
  | HideStoryStartAction
  | HideStorySuccessAction
  | HideStoryFailureAction
  | ShowStoryStartAction
  | ShowStorySuccessAction
  | ShowStoryFailureAction
  | DownloadStoryStartAction
  | DownloadStorySuccessAction
  | DownloadStoryFailureAction
  | StoriesUpdateFilterTabAction
  | StoriesResetFilterTabAction;
