import React from 'react';
import { IconProps } from '../icon.types';

export const Refresh = ({
  className,
  size,
  color = 'black',
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        d="M14.1119 5.3335C13.0831 2.97896 10.7337 1.3335 7.99993 1.3335C4.54306 1.3335 1.70069 3.96462 1.36621 7.3335"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3335 5.33333H14.2668C14.4878 5.33333 14.6668 5.15425 14.6668 4.93333V2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.9209 10.6665C2.94965 13.021 5.29909 14.6665 8.03286 14.6665C11.4897 14.6665 14.3321 12.0354 14.6666 8.6665"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.69954 10.6665H1.76621C1.5453 10.6665 1.36621 10.8456 1.36621 11.0665V13.9998"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
