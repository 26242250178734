import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState, AppActions } from '../../../types';
import { GalleryForm } from './gallery_form.component';
import {
  GALLERY_FORM_TYPES,
  DynamicGalleryFormSubmitData,
} from './gallery_form.types';
import { editDynamicGallery } from '../../../http/gallery_edit.thunk';
import { createDynamicGallery } from '../../../http/gallery_create.thunks';
import {
  selectCampaignTagsFormData,
  selectEditFormConfigById,
  selectGlobalTagsFormData,
  selectLocationsFormData,
  selectPersonaTagsFormData,
  selectLocationsTypeFormData,
  selectCreateFormConfig,
  selectPublisherSignalsFormData,
} from '../../../redux/galleries/gallery_form.selectors';
import { getGlobalTags as globalTagsThunk } from '../../../http/global_tags_list';
import { getNetworkTags as networkTagsThunk } from '../../../http/network_tags_list';

import { triggerHydrateAction } from '../../pages/galleries_list/galleries_list.actions';
import { getLocations } from '../../../http/locations_list.thunks';
import { LOCATION_TYPE } from '../../../redux/locations/locations.types';

const mapStateToProps = (
  state: AppState,
  ownProps: { id?: string; type: GALLERY_FORM_TYPES },
) => {
  const { id, type } = ownProps;
  const isCreateForm = type === GALLERY_FORM_TYPES.CREATE;

  return {
    defaultValues: id
      ? selectEditFormConfigById(state)
      : selectCreateFormConfig(state),
    locationOptions: selectLocationsFormData(state),
    locationTypeOptions: selectLocationsTypeFormData(state),
    globalTagOptions: selectGlobalTagsFormData(state),
    personaTagOptions: selectPersonaTagsFormData(state),
    campaignTagOptions: selectCampaignTagsFormData(state),
    buttonCopy: isCreateForm ? 'createButton' : 'updateButton',
    isCreateForm,
    publisherSignals: selectPublisherSignalsFormData(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, {}, AppActions>,
  ownProps: { type: string },
) => ({
  getGlobalTags: () => dispatch(globalTagsThunk()),
  getNetworkTags: () => dispatch(networkTagsThunk()),
  getLocations: (locationType: LOCATION_TYPE) =>
    dispatch(getLocations(locationType)),
  handleGalleryFormSubmit: (formData?: DynamicGalleryFormSubmitData) =>
    dispatch(
      ownProps.type === GALLERY_FORM_TYPES.CREATE
        ? createDynamicGallery(formData)
        : editDynamicGallery(formData),
    ),
  triggerGetGalleries: () => {
    dispatch(triggerHydrateAction());
  },
});
export const GalleryFormConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GalleryForm);
