import { createSelector } from 'reselect';
import { AppState } from '../../types';
import { Publisher } from '../user/user.types';
import { SelectOption } from '../../react/blocks/select';

export const selectNetworkPublishers = (state: AppState) => {
  return state.network.publishers;
};

export const selectNetworkPublishersArray = createSelector(
  selectNetworkPublishers,
  (networkPublishers: { [id: string]: Publisher }) =>
    Object.keys(networkPublishers).map(
      (publisherId) => networkPublishers[publisherId],
    ) ?? [],
);

export const selectCurrentPublisherOptions = createSelector(
  selectNetworkPublishersArray,
  (currentPublishers: Publisher[]): SelectOption[] => {
    return currentPublishers.map((publisher) => ({
      label: publisher.label ?? '',
      value: publisher.publisherId ?? '',
    }));
  },
);

export const selectNetworkSettings = (state: AppState) =>
  state.network.settings;
