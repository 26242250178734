import React from 'react';
import PropTypes from 'prop-types';
import Item from './components/Item';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';

var propDescriptions = {
  itemList: 'Array of data that should show in the table',
  onRemoveAll: 'A function to be fired on remove all button click',
  onRemoveItem: 'A function to be fired on item remove icon click',
  hideRemoveAllButton: 'Boolean specifying if remove all button should be shown',
  removeAllText: 'String specifiying the text showing for remove all button'
};

var propTypes = {
  itemList: PropTypes.arrayOf(PropTypes.object),
  onRemoveAll: PropTypes.func,
  onRemoveItem: PropTypes.func,
  hideRemoveAllButton: PropTypes.bool,
  removeAllText: PropTypes.string
};

var defaultProps = {
  itemList: [],
  hideRemoveAllButton: false,
  removeAllText: 'Remove All'
};

var SelectedItemList = function SelectedItemList(props) {
  var itemList = props.itemList,
      onRemoveItem = props.onRemoveItem,
      onRemoveAll = props.onRemoveAll,
      hideRemoveAllButton = props.hideRemoveAllButton,
      removeAllText = props.removeAllText;


  return React.createElement(
    'div',
    { className: 'cr-selectedItemList' },
    itemList.map(function (item) {
      return React.createElement(Item, { key: item.value, item: item, onRemoveItem: onRemoveItem });
    }),
    !hideRemoveAllButton && React.createElement(
      Button,
      {
        buttonStyle: 'link',
        className: 'cr-selectedItemList__removeAllButton',
        color: 'blue',
        onClick: onRemoveAll,
        iconPosition: 'left',
        type: 'button'
      },
      React.createElement(Icon, { type: 'close', size: 10 }),
      ' ',
      removeAllText
    )
  );
};
SelectedItemList.propDescriptions = propDescriptions;
SelectedItemList.propTypes = propTypes;
SelectedItemList.defaultProps = defaultProps;

export default SelectedItemList;