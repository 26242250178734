import React from 'react';
import { PropTypes } from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var SaveFloppyDisk = function SaveFloppyDisk(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'SaveFloppyDisk__icon',
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M3 19V5C3 3.89543 3.89543 3 5 3H16.1716C16.702 3 17.2107 3.21071 17.5858 3.58579L20.4142 6.41421C20.7893 6.78929 21 7.29799 21 7.82843V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19Z',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M8.6 9H15.4C15.7314 9 16 8.73137 16 8.4V3.6C16 3.26863 15.7314 3 15.4 3H8.6C8.26863 3 8 3.26863 8 3.6V8.4C8 8.73137 8.26863 9 8.6 9Z',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M6 13.6V21H18V13.6C18 13.2686 17.7314 13 17.4 13H6.6C6.26863 13 6 13.2686 6 13.6Z',
      stroke: color,
      strokeWidth: '1.5'
    })
  );
};

SaveFloppyDisk.propTypes = propTypes;

export default SaveFloppyDisk;