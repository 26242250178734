import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PhotoRectangle = function PhotoRectangle(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'PhotoRectangle__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1249 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M0,0v1000h1249V0H0z M62.5,62.5h1124v625l-250-218.8L592.8,812.5l-125-125L250,937.5H62.5V62.5z M515.3,383c0,64.6-52.4,117-117,117s-117-52.4-117-117s52.4-117,117-117S515.3,318.3,515.3,383z' // eslint-disable-line max-len
    })
  );
};

PhotoRectangle.propTypes = propTypes;

export default PhotoRectangle;