import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { AppState, AppThunkDispatch } from '../../../types';
import { StoryList as StoryListComponent } from './story_list.component';
import {
  selectStoriesIsLoading,
  selectStoriesListNextPageToken,
  selectStoriesLoadingType,
  selectStoriesSearchQuery,
} from '../../../redux/stories/stories.selectors';
import {
  selectCurrentPublisherId,
  selectCurrentNetworkId,
} from '../../../redux/user/user.selectors';
import { hideStory } from '../../../http/story_hide.thunk';
import { showStory } from '../../../http/story_show.thunk';

const mapStateToProps = (state: AppState) => ({
  isLoading: selectStoriesIsLoading(state),
  loadingType: selectStoriesLoadingType(state),
  nextPageToken: selectStoriesListNextPageToken(state),
  currentPublisherId: selectCurrentPublisherId(state),
  networkId: selectCurrentNetworkId(state),
  translate: getTranslate(state.localize),
  searchQuery: selectStoriesSearchQuery(state),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onHideStory: (story_uuid: string) => dispatch(hideStory(story_uuid)),
  onShowStory: (story_uuid: string) => dispatch(showStory(story_uuid)),
});

export const StoryList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StoryListComponent);
