import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

var propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  text: PropTypes.node,
  position: PropTypes.oneOf(['right', 'left', 'topCenter', 'bottomCenter', 'topRight', 'topLeft']),
  hideText: PropTypes.bool
};

var propDescriptions = {
  className: 'Optional classname for the tooltip',
  children: 'The relative component (ie a button) where the tooltip will be positioned',
  text: 'The text that will appear when the button is hovered',
  position: 'The position of the tooltip.',
  hideText: 'To be used if the tooltip needs to hide'
};

var defaultProps = {
  position: 'right'
};

var Tooltip = function (_React$Component) {
  _inherits(Tooltip, _React$Component);

  function Tooltip(props) {
    _classCallCheck(this, Tooltip);

    var _this = _possibleConstructorReturn(this, (Tooltip.__proto__ || _Object$getPrototypeOf(Tooltip)).call(this, props));

    _this.handleShow = _this.handleShow.bind(_this);
    _this.state = {
      isOpen: false
    };
    return _this;
  }

  _createClass(Tooltip, [{
    key: 'handleShow',
    value: function handleShow() {
      if (this.props.hideText) return;
      this.setState({
        isOpen: true
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          className = _props.className,
          position = _props.position,
          text = _props.text,
          hideText = _props.hideText;
      var isOpen = this.state.isOpen;

      var tooltipClasses = classNames('tooltip', className, position, hideText, {
        isOpen: isOpen
      });
      return (
        // onFocus and onBlur is called from the child and not the tooltip itself
        React.createElement(
          'div',
          { // eslint-disable-line jsx-a11y/mouse-events-have-key-events
            className: tooltipClasses,
            onMouseOver: function onMouseOver() {
              return _this2.handleShow();
            },
            onMouseLeave: function onMouseLeave() {
              return _this2.setState({ isOpen: false });
            }
          },
          isOpen ? React.createElement(
            'div',
            {
              className: 'tooltip__textWrapper',
              onMouseEnter: function onMouseEnter() {
                return _this2.setState({ isOpen: false });
              }
            },
            React.createElement(
              'p',
              { className: 'tooltip__text' },
              text
            )
          ) : null,
          this.props.children
        )
      );
    }
  }]);

  return Tooltip;
}(React.Component);

export default Tooltip;


Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;
Tooltip.propDescriptions = propDescriptions;