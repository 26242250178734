import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var ThumbsUp = function ThumbsUp(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'thumbsUp__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 16 14',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      { stroke: 'none', strokeWidth: '1', fillRule: 'evenodd' },
      React.createElement(
        'g',
        { transform: 'translate(-485.000000, -83.000000)', fillRule: 'nonzero' },
        React.createElement(
          'g',
          { id: 'Group-3', transform: 'translate(463.000000, 83.000000)' },
          React.createElement(
            'g',
            { id: 'icon-thumbsup', transform: 'translate(22.000000, 0.000000)' },
            React.createElement('path', {
              d: 'M0.674698795,13.8987952 L2.93493976,13.8987952 C3.27228916,13.8987952 3.55903614,13.6120482 3.55903614,13.2746988 L3.55903614,5.7686747 C3.55903614,5.4313253 3.27228916,5.14457831 2.93493976,5.14457831 L0.674698795,5.14457831 C0.337349398,5.14457831 0.0506024096,5.4313253 0.0506024096,5.7686747 L0.0506024096,13.2746988 C0.0506024096,13.6120482 0.320481928,13.8987952 0.674698795,13.8987952 Z M15.4674699,6.19036145 C15.4674699,5.41445783 14.8433735,4.79036145 14.0674699,4.79036145 L10.2216867,4.79036145 C9.91807229,4.79036145 9.69879518,4.52048193 9.74939759,4.21686747 C9.74939759,4.21686747 10.913253,1.33253012 10.913253,0.961445783 C10.913253,0.590361446 10.7783133,0.337349398 10.4746988,0.269879518 C10.1710843,0.202409639 9.54698795,0.0674698795 9.54698795,0.0674698795 C9.27710843,0.0168674699 9.0746988,0.0674698795 8.93975904,0.202409639 L4.67228916,4.50361446 C4.41927711,4.75662651 4.25060241,5.11084337 4.25060241,5.49879518 L4.25060241,13.3590361 C4.25060241,13.6626506 4.50361446,13.8987952 4.79036145,13.8987952 L11.9590361,13.8987952 C12.5325301,13.8987952 12.9373494,13.4939759 13.2409639,13.0385542 L15.1807229,10.4746988 C15.2987952,10.3228916 15.4506024,9.98554217 15.4506024,9.68192771 L15.4506024,6.25783133 L15.4674699,6.19036145 Z' // eslint-disable-line max-len
            })
          )
        )
      )
    )
  );
};

ThumbsUp.propTypes = propTypes;

export default ThumbsUp;