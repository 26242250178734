import React from 'react';
import { IconProps } from '../icon.types';

export const Eye = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      aria-hidden={ariaHidden}
      role={role}
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      viewBox="0 0 24 24"
    >
      <path
        d="M12.1276 14C13.2322 14 14.1276 13.1046 14.1276 12C14.1276 10.8954 13.2322 10 12.1276 10C11.023 10 10.1276 10.8954 10.1276 12C10.1276 13.1046 11.023 14 12.1276 14Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.1276 12C19.239 14.991 15.8459 18 12.1276 18C8.40926 18 5.01612 14.991 3.12756 12C5.42611 9.15825 8.11919 6 12.1276 6C16.136 6 18.8291 9.1582 21.1276 12Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
