import React, { useContext } from 'react';
import Icon from '@crowdriff/flock/lib/Icon';
import { LocalizedElement, LocalizedElementMap } from 'react-localize-redux';
import { ModalContext } from '../../hooks/use_modal/use_modal';
import { ModalTitle } from '../modal/modal_title.component';
import '../modal/modal.scss';

export interface ModalTitleProps {
  label: string | LocalizedElement | LocalizedElementMap;
  id?: string;
  enableClose?: boolean;
}

export const MODAL_TITLE_ID = 'modal-title';
export const ConnectedModalTitle = ({
  label,
  id,
  enableClose = true,
}: ModalTitleProps) => {
  const { toggle } = useContext(ModalContext);
  return (
    <div className="modal__header">
      <ModalTitle id={id ?? MODAL_TITLE_ID} label={label} />
      {enableClose && (
        <button className="modal__close-button" type="button" onClick={toggle}>
          <Icon type="close" size={10} />
        </button>
      )}
    </div>
  );
};
