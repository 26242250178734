import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import './gallery_edit.scss';
import { Page } from '../../blocks/page/page.component';
import { LoadStateData } from '../../blocks/load_data/load_state_data.component';
import { LoadingGif } from '../../blocks/LoadingGif/LoadingGif.component';
import { GALLERY_GET_TYPES } from '../../../redux/galleries/galleries.actions';
import { GalleryGetResponse } from '../../../http/gallery_get.thunk';
import { GalleryEditContent } from '../../features/gallery_edit_content';

export interface GalleryEditPageProps {
  getGallery: (id: string) => Promise<GalleryGetResponse>;
}
export const GalleryEditPage = ({
  getGallery,
}: GalleryEditPageProps): ReactElement => {
  const { id } = useParams();
  if (!id) {
    throw new Error('Missing gallery data.');
  }
  return (
    <Page className="gallery_edit_page">
      <LoadStateData
        actionTypes={GALLERY_GET_TYPES}
        loading={<LoadingGif size="large" />}
        failure={<div>failed</div>}
        thunk={() => getGallery(id)}
      >
        <GalleryEditContent />
      </LoadStateData>
    </Page>
  );
};

GalleryEditPage.displayName = 'GalleryEditPage';
