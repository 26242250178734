import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// file naming: first letter is captial, no hyphens or camel casing
import * as Rifficons from './rifficons';
// list of availble rifficons
import { rifficonList, rifficonStyles } from './types';

var propDescriptions = {
  size: 'Number that dictates the height of an icon in pixels. Minimum of 60',
  iconStyle: 'String that indicates the iconStyle of the div. One of outline, fill, or white', // eslint-disable-line max-len
  className: 'String that will be used as a classname on the wrapping div.', // eslint-disable-line max-len
  type: 'string specifying which icon should be displayed',
  fillColor: 'string (any valid css color) for what the fill color should be',
  isAnimated: 'Boolean describing whether or not the image jumps around'
};

var propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.number,
  isAnimated: PropTypes.bool,
  iconStyle: PropTypes.oneOf(rifficonStyles),
  className: PropTypes.string,
  fillColor: PropTypes.string
};

var defaultProps = {
  size: 60,
  fillColor: '#1DB4E6',
  isAnimated: false
};

var RifficonWrapper = function (_PureComponent) {
  _inherits(RifficonWrapper, _PureComponent);

  function RifficonWrapper() {
    _classCallCheck(this, RifficonWrapper);

    return _possibleConstructorReturn(this, (RifficonWrapper.__proto__ || _Object$getPrototypeOf(RifficonWrapper)).apply(this, arguments));
  }

  _createClass(RifficonWrapper, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          type = _props.type,
          size = _props.size,
          iconStyle = _props.iconStyle,
          className = _props.className,
          isAnimated = _props.isAnimated,
          fillColor = _props.fillColor;

      var Rifficon = Rifficons[type];

      // Warn on invalid icon type in development
      if (process.env.NODE_ENV === 'development' && rifficonList.indexOf(type) === -1) {
        console.warn('Invalid flock rifficon type: ' + type);
        return null;
      }

      // Warning for size in development
      if (size < 60 && process.env.NODE_ENV === 'development') {
        console.warn('Rifficons should not be smaller than 60px');
      }

      return React.createElement(
        'div',
        {
          className: classNames('rifficonWrapper', {
            animate: isAnimated
          }, className, type)
        },
        React.createElement(Rifficon, {
          size: size,
          iconStyle: iconStyle,
          fillColor: fillColor
        })
      );
    }
  }]);

  return RifficonWrapper;
}(PureComponent);

export default RifficonWrapper;


RifficonWrapper.propTypes = propTypes;
RifficonWrapper.defaultProps = defaultProps;
RifficonWrapper.propDescriptions = propDescriptions;