import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../../types';
import { EditStoriesSection } from './edit_stories_section.component';
import { createStaticGallery } from '../../../../http/gallery_create.thunks';
import {
  selectStaticGallery,
  selectStaticGalleryStoriesForEdit,
} from '../../../../redux/galleries/galleries.selectors';
import {
  updateStaticGallerySelectedList,
  setManualGalleryCreationStep,
} from '../../../../redux/galleries/galleries.actions';
import { getGalleries } from '../../../../http/galleries_list.thunks';
import { Story } from '../../story/story.types';

const mapStateToProps = (state: AppState) => ({
  gallery: selectStaticGallery(state),
  selectedStories: selectStaticGalleryStoriesForEdit(state) ?? [],
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  saveGallery: () => dispatch(createStaticGallery()),
  updateGalleryList: () => dispatch(getGalleries),
  updateStaticGallerySelectedStories: (sortedList: Story[]) =>
    dispatch(updateStaticGallerySelectedList(sortedList)),
  setManualGalleryCreationStep: (step: number) =>
    dispatch(setManualGalleryCreationStep(step)),
});

export const EditStoriesSectionConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditStoriesSection);
