import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PhotoRectangleBrokenBorder = function PhotoRectangleBrokenBorder(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'PhotoRectangleBrokenBorder__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1249 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M523.2,521.6c0-48.5,39.3-87.8,87.8-87.8c48.5,0,87.8,39.3,87.8,87.8c0,48.5-39.3,87.8-87.8,87.8C562.5,609.4,523.2,570.1,523.2,521.6z M999,0v62.5h187.5V250h62.5V0H999z M1186.5,736.3l-171.9-150.4L756.8,843.8L663.1,750L500,937.5l0,62.5h749V499.8h-62.5V736.3z M62.5,750H0v250h250l0-62.5H62.5V750z M62.5,62.5H250V0H0v250h62.5V62.5z' // eslint-disable-line max-len
    })
  );
};

PhotoRectangleBrokenBorder.propTypes = propTypes;

export default PhotoRectangleBrokenBorder;