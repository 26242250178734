import React from 'react';
import clsx from 'clsx';
import './helperText.scss';

interface HelperTextProps {
  id: string;
  description: string;
  className?: string;
}

export const HelperText = ({
  id,
  description,
  className = '',
  ...otherProps
}: HelperTextProps) => {
  return (
    <p id={id} className={clsx('help-text', className)} {...otherProps}>
      {description}
    </p>
  );
};
