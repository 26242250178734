import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Menu = function Menu(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'menu_icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1142.9 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      null,
      React.createElement('rect', { y: '0', width: '1142.9', height: '142.9' }),
      React.createElement('rect', { y: '857.1', width: '1142.9', height: '142.9' }),
      React.createElement('rect', { y: '428.6', width: '1000', height: '142.9' })
    )
  );
};

Menu.propTypes = propTypes;

export default Menu;