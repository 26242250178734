import { HydratedGallery } from '../../../redux/galleries/galleries.types';

export enum GALLERIES_LOAD_TYPES {
  START = 'GALLERIES/START',
  SUCCESS = 'GALLERIES/SUCCESS',
  FAILURE = 'GALLERIES/FAILURE',
  TRIGGER_HYDRATE = 'GALLERIES/TRIGGER_HYDRATE',
}

export enum GALLERIES_SEARCH_TYPES {
  SEARCH = 'GALLERIES/SEARCH',
  CLEAR_SEARCH = 'GALLERIES/CLEAR_SEARCH',
}

export interface GalleriesStartAction {
  type: GALLERIES_LOAD_TYPES.START;
  payload: {};
}

export interface GalleriesSuccessAction {
  type: GALLERIES_LOAD_TYPES.SUCCESS;
  payload: {
    galleries: HydratedGallery[];
  };
}

export interface GalleriesFailureAction {
  type: GALLERIES_LOAD_TYPES.FAILURE;
  payload: string;
}

export interface GalleriesTriggerHydrateAction {
  type: GALLERIES_LOAD_TYPES.TRIGGER_HYDRATE;
  payload: {};
}

export interface GalleriesSearchAction {
  type: GALLERIES_SEARCH_TYPES.SEARCH;
  payload: {
    value: string;
  };
}

export interface GalleriesClearSearchAction {
  type: GALLERIES_SEARCH_TYPES.CLEAR_SEARCH;
  payload: null;
}

export type GalleriesActions =
  | GalleriesStartAction
  | GalleriesSuccessAction
  | GalleriesFailureAction
  | GalleriesTriggerHydrateAction
  | GalleriesSearchAction
  | GalleriesClearSearchAction;
