import _extends from 'babel-runtime/helpers/extends';
import _objectWithoutProperties from 'babel-runtime/helpers/objectWithoutProperties';
import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { buttonStyles } from './button-enums';

var propDescriptions = {
  buttonStyle: 'A string specifiying the style of the button',
  children: 'A node specifying the contents of the button',
  className: 'A string of any additional classes to be added to the button',
  color: 'String that will be used as a classname on the wrapping div - matches a color variable',
  iconPosition: 'String indicating the position of an icon in the button children',
  isDisabled: 'Boolean specifying if button is Disabled',
  isNegative: 'Boolean specifying if button should be a negative colour button',
  isSquare: 'Boolean specifying if button is square shaped (for single icon)',
  disableVerticalAlign: 'Boolean specifying if button contents are vertically centered',
  onClick: 'A function to be fired on button click',
  styles: 'an Object containing any inline styles to be attached to the button',
  type: 'A string specifying if it is a button button or submit button'
};

var propTypes = {
  buttonStyle: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  iconPosition: PropTypes.string,
  isDisabled: PropTypes.bool,
  isNegative: PropTypes.bool,
  isSquare: PropTypes.bool,
  disableVerticalAlign: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  type: PropTypes.string,
  ariaLabel: PropTypes.string
};

var defaultProps = {
  buttonStyle: 'fill',
  type: 'button',
  iconPosition: 'left'
};

var Button = function (_Component) {
  _inherits(Button, _Component);

  function Button(props) {
    _classCallCheck(this, Button);

    var _this = _possibleConstructorReturn(this, (Button.__proto__ || _Object$getPrototypeOf(Button)).call(this, props));

    _this.handleClick = function (e) {
      var _this$props = _this.props,
          onClick = _this$props.onClick,
          type = _this$props.type;
      // if it's a submit button, let the form submit handle it.

      if (type === 'submit') return;
      if (!onClick) return;
      onClick(e);
    };

    return _this;
  }

  _createClass(Button, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          buttonStyle = _props.buttonStyle,
          children = _props.children,
          className = _props.className,
          color = _props.color,
          iconPosition = _props.iconPosition,
          isDisabled = _props.isDisabled,
          isNegative = _props.isNegative,
          isSquare = _props.isSquare,
          disableVerticalAlign = _props.disableVerticalAlign,
          ariaLabel = _props.ariaLabel,
          rest = _objectWithoutProperties(_props, ['buttonStyle', 'children', 'className', 'color', 'iconPosition', 'isDisabled', 'isNegative', 'isSquare', 'disableVerticalAlign', 'ariaLabel']);

      var buttonClasses = classNames('cr__button', className, color, buttonStyle, {
        disabled: isDisabled,
        negative: isNegative,
        square: isSquare,
        disableVerticalAlign: disableVerticalAlign,
        'icon-left': iconPosition === 'left',
        'icon-right': iconPosition === 'right'
      });
      if (process.env.NODE_ENV === 'development' && buttonStyles.indexOf(buttonStyle) === -1) {
        console.warn('Invalid flock button buttonStyle: ' + buttonStyle);
        return null;
      }
      return React.createElement(
        'button',
        _extends({
          className: buttonClasses,
          disabled: isDisabled,
          onClick: this.handleClick,
          'aria-label': ariaLabel
        }, rest),
        children
      );
    }
  }]);

  return Button;
}(Component);

export default Button;


Button.propDescriptions = propDescriptions;
Button.propTypes = propTypes;
Button.defaultProps = defaultProps;