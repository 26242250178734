import React from 'react';
import { IconProps } from '../icon.types';

export const Camera = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    // TODO: Make the viewbox be the same as other icons once we have a design solution
    // for stroke widths as icons scale
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        d="M2.16602 16.0042V7.6709C2.16602 6.75042 2.91221 6.00423 3.83268 6.00423H4.24935C4.77394 6.00423 5.26792 5.75724 5.58268 5.33756L7.43268 2.8709C7.52711 2.745 7.67531 2.6709 7.83268 2.6709H13.166C13.3234 2.6709 13.4716 2.745 13.566 2.8709L15.416 5.33756C15.7308 5.75724 16.2248 6.00423 16.7493 6.00423H17.166C18.0865 6.00423 18.8327 6.75042 18.8327 7.6709V16.0042C18.8327 16.9247 18.0865 17.6709 17.166 17.6709H3.83268C2.91221 17.6709 2.16602 16.9247 2.16602 16.0042Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4993 14.3376C12.3403 14.3376 13.8327 12.8451 13.8327 11.0042C13.8327 9.16331 12.3403 7.6709 10.4993 7.6709C8.6584 7.6709 7.16602 9.16331 7.16602 11.0042C7.16602 12.8451 8.6584 14.3376 10.4993 14.3376Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
