import { UseControllerProps } from 'react-hook-form';
import { ReactChild } from 'react';
import { SelectGroupedOptions, SelectOption } from '../../blocks/select';
import { GALLERY_TYPE } from '../../../redux/galleries/galleries.types';
import { LOCATION_TYPE } from '../../../redux/locations/locations.types';
import { RadioOption } from '../../blocks/radio_group';
import { GalleryCreateEditResponse } from '../../../http/gallery_create.thunks';

export enum GALLERY_FORM_TYPES {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum GALLERY_FORM_FIELDS {
  NAME = 'galleryName',
  PUBLISHER = 'galleryPublisher',
  CAMPAIGN = 'galleryCampaigns',
  THEMES = 'galleryThemes',
  LOCATIONS = 'galleryLocations',
  LOCATION_TYPES = 'galleryLocationTypes',
  PERSONAS = 'galleryPersonas',
  PUBLISHER_SIGNALS = 'galleryPublisherSignals',
}

export interface DynamicGalleryFormSubmitData {
  [GALLERY_FORM_FIELDS.NAME]: string;
  // These are optional params because form returns
  // undefined if input dropdowns are pristine
  // and empty array if input is dirty but dropdowns have no options selected
  [GALLERY_FORM_FIELDS.CAMPAIGN]?: SelectOption;
  [GALLERY_FORM_FIELDS.THEMES]?: SelectOption[];
  [GALLERY_FORM_FIELDS.LOCATIONS]?: SelectOption[];
  [GALLERY_FORM_FIELDS.PERSONAS]?: SelectOption[];
  [GALLERY_FORM_FIELDS.PUBLISHER_SIGNALS]: string;
}

export interface TransformedGalleryFormData {
  label: string;
  publisher_label: string;
  global_tag_ids: (string | null | undefined)[];
  network_tag_ids: (string | null | undefined)[];
  location_ids: (string | null | undefined)[];
  publisher_signals?: string[];
  type: GALLERY_TYPE.DYNAMIC;
}
export interface GalleryFormSignals {
  global_tag_ids: (string | null | undefined)[];
  network_tag_ids: (string | null | undefined)[];
  location_ids: (string | null | undefined)[];
  publisher_signals?: string[];
}

export interface GalleryFormProps {
  isCreateForm: boolean;
  id?: string;
  buttonCopy: string;
  // TODO update this typing when RHF updates their definitions
  defaultValues?: UseControllerProps['defaultValue'];
  defaultLocationType: LOCATION_TYPE;
  locationOptions: SelectOption[];
  locationTypeOptions: SelectOption[];
  globalTagOptions: SelectGroupedOptions;
  personaTagOptions: SelectOption[];
  campaignTagOptions: SelectOption[];
  publisherSignals: RadioOption[];
  handleGalleryFormSubmit: (
    formData?: DynamicGalleryFormSubmitData | undefined,
  ) => Promise<GalleryCreateEditResponse>;
  getGlobalTags: () => void;
  getNetworkTags: () => void;
  getLocations: (location: LOCATION_TYPE) => void;
  triggerGetGalleries: () => void;
  children?: ReactChild;
}
