import React, {
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Translate, withLocalize } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { List, AutoSizer, WindowScroller } from 'react-virtualized';
import { useCallbackRef } from '../../hooks/useCallbackRef';
import './story_list.scss';
import { STORY_STATUS } from '../story/story.types';
import { ScrollToTopButton } from '../../blocks/ScrollToTopButton/ScrollToTopButton';
import {
  getStoriesSearch,
  GetStoriesSearchThunk,
  STORY_SEARCH_LOAD_TYPES,
} from '../../../http/stories_search.thunks';
import { STORY_DIMENSIONS, StoryListProps } from './story_list.types';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
} from '../../blocks/button';
import { SearchEmptyState } from '../search';
import { StoriesSearchFilterParams } from '../stories_search_form.component/stories_search_form.types';
import { CRThunkDispatch } from '../../../http/http.types';

const StoryListComponent: VoidFunctionComponent<StoryListProps> = ({
  translate,
  stories,
  nextPageToken,
  networkId,
  currentPublisherId,
  onHideStory,
  onShowStory,
  StoryCard,
  handleScrollToTop,
  filterData,
  scrollElement = window,
}) => {
  const dispatch = useDispatch<CRThunkDispatch>();
  const [targetElement, setTargetElement] = useState<Element | null>(null);
  const storyCount = stories.length;
  const [observer, setObserverRef] = useCallbackRef<IntersectionObserver>();

  const getDispatchStoriesSearch = useRef(
    (reduxDispatch: CRThunkDispatch, getStories: GetStoriesSearchThunk) => (
      data: StoriesSearchFilterParams,
      nextPage: string,
    ) =>
      reduxDispatch(
        getStories({
          searchFilters: data,
          pagination: { pageSize: 20, pageToken: nextPage },
          loadingType: STORY_SEARCH_LOAD_TYPES.LAZY_LOAD,
        }),
      ),
  );

  const getHandleIntersect = useRef(
    (
      token: string | null,
      data: StoriesSearchFilterParams,
      dispatchStories: ReturnType<typeof getDispatchStoriesSearch.current>,
    ) => {
      return async (
        entries: IntersectionObserverEntry[],
        currentObserver: IntersectionObserver,
      ) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting) {
          if (token) {
            try {
              currentObserver.unobserve(firstEntry.target);
              await dispatchStories(data, token);
            } catch (err) {
              // eslint-disable-next-line no-console
              console.error(err);
            }
          }
        }
      };
    },
  );

  useEffect(() => {
    if (targetElement) {
      setObserverRef(
        new IntersectionObserver(
          getHandleIntersect.current(
            nextPageToken,
            filterData,
            getDispatchStoriesSearch.current(dispatch, getStoriesSearch),
          ),
          {
            threshold: 0.1,
            root: null,
          },
        ),
      );
    }
  }, [nextPageToken, filterData, targetElement, dispatch, getStoriesSearch]);

  useEffect(() => {
    const currentElement: Element | null = targetElement;
    const currentObserver = observer;

    if (nextPageToken === null) {
      return currentObserver?.disconnect();
    }

    if (currentElement) {
      currentObserver?.observe(currentElement);
    }

    return () => {
      currentObserver?.disconnect();
    };
  }, [targetElement, observer]);

  return stories.length === 0 ? (
    <div className="story_list__grid_wrapper">
      <SearchEmptyState />
    </div>
  ) : (
    <WindowScroller scrollElement={scrollElement}>
      {({ height, isScrolling, registerChild, scrollTop }) => (
        <div className="story_list__grid_wrapper">
          <div
            className="story_list__grid"
            style={{ width: '100%' }}
            ref={registerChild}
          >
            <AutoSizer>
              {({ width }) => {
                const itemsPerRow = Math.floor(width / STORY_DIMENSIONS.WIDTH);
                const rowCount = Math.ceil(storyCount / itemsPerRow);
                return (
                  <ScrollToTopButton width={width}>
                    <List
                      tabIndex={-1}
                      ref={registerChild}
                      // window scroller props
                      autoHeight={true}
                      isScrolling={isScrolling}
                      scrollTop={scrollTop}
                      // misc.
                      className="story_list__virtualized"
                      width={width}
                      height={height}
                      rowHeight={STORY_DIMENSIONS.HEIGHT}
                      rowCount={nextPageToken ? rowCount : rowCount + 1}
                      // overscanRowCount attempts to render an extra row to prevent the virtualized list from interrupting the intersectionObserver
                      overscanRowCount={1}
                      rowRenderer={({ key, index, style }) => {
                        const items = [];
                        const rowStartIndex = index * itemsPerRow;
                        const rowEndIndex = Math.min(
                          rowStartIndex + itemsPerRow,
                          storyCount,
                        );

                        for (let i = rowStartIndex; i < rowEndIndex; i += 1) {
                          const story = stories[i];

                          const baseUrl =
                            story.status === STORY_STATUS.DRAFT
                              ? story.storyData?.canonicalUrl
                              : story.storyData?.url;

                          const previewSrc = baseUrl
                            ? baseUrl.concat('?networkUuid=').concat(networkId)
                            : '';

                          items.push(
                            <div
                              className={classNames(
                                'story_list__story_wrapper',
                                {
                                  story_list__story_row_start:
                                    i === rowStartIndex,
                                  story_list__story_row_end:
                                    i === rowEndIndex - 1,
                                },
                              )}
                              key={uuidv4()}
                            >
                              <StoryCard
                                key={uuidv4()}
                                story={story}
                                previewSrc={previewSrc}
                                currentPublisherId={currentPublisherId}
                                onHideStory={onHideStory}
                                onShowStory={onShowStory}
                              />
                            </div>,
                          );
                        }

                        const thresholdRow = rowCount - 1;
                        switch (index) {
                          case thresholdRow:
                            return (
                              <div
                                className="story_list__row"
                                key={key}
                                style={style}
                                ref={setTargetElement}
                                id="story_list__row--infinite_scroll_threshold"
                              >
                                {items}
                              </div>
                            );

                          case rowCount: {
                            return !nextPageToken ? (
                              <div
                                className="story_list__row"
                                key={key}
                                style={style}
                                ref={setTargetElement}
                                id="story_list__row--back_to_top"
                              >
                                <div className="story_list__footer">
                                  <p>
                                    <Translate id="stories.search.bottomOfList" />
                                  </p>
                                  <Button
                                    ariaLabel={`${translate(
                                      'stories.search.backToTop',
                                    )}`}
                                    onClick={handleScrollToTop}
                                    variant={BUTTON_VARIANT.OUTLINE}
                                    color={BUTTON_COLOR_VARIANT.SECONDARY}
                                  >
                                    <Translate id="stories.search.backToTop" />
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="story_list__row"
                                key={key}
                                style={style}
                                ref={setTargetElement}
                                id="story_list__row--loading_skeleton"
                              >
                                {/* TO-DO: Add skeleton loading component here [SNET-449] */}
                              </div>
                            );
                          }

                          default:
                            return (
                              <div
                                className="story_list__row"
                                key={key}
                                style={style}
                              >
                                {items}
                              </div>
                            );
                        }
                      }}
                    />
                  </ScrollToTopButton>
                );
              }}
            </AutoSizer>
          </div>
        </div>
      )}
    </WindowScroller>
  );
};

export const StoryList = withLocalize(StoryListComponent);
