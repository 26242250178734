import React from 'react';
import './page_header.scss';

interface PageHeaderProps {
  children?: React.ReactNode;
  className?: string;
}

export const PageHeader = ({ children, className = '' }: PageHeaderProps) => {
  return <div className={`page-header ${className}`}>{children}</div>;
};
