import { GALLERY_DETAILS_LANGUAGE_BASE } from './gallery_edit_content.constants';
import { EditError } from './gallery_edit_content.types';

export const staticGalleryErrors = (isPristine: boolean, label?: string) => {
  const error: EditError = {
    translateId: '',
  };
  if (!isPristine && !label) {
    error.translateId = `${GALLERY_DETAILS_LANGUAGE_BASE}.requiredNameError`;
    return error;
  }

  return null;
};
