import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var SidekickBox = function SidekickBox(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'SidekickBox__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M899.999 200V799.999H735.258L681.34 899.999H999.999V99.9995H681.34L735.258 200H899.999Z' // eslint-disable-line max-len
    }),
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M475.401 99.9995H0L237.7 499.999L475.401 99.9995ZM475.402 99.9995L713.102 499.999H237.701L475.402 99.9995ZM0 900H475.401H475.402L713.102 500H237.701H237.7L0 900Z' // eslint-disable-line max-len
    })
  );
};

SidekickBox.propTypes = propTypes;

export default SidekickBox;