import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var VerifiedBadge = function VerifiedBadge(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'VerifiedBadge__icon',
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M11.6058 2.34187C11.8315 2.14581 12.1671 2.14581 12.3928 2.34187L14.3411 4.03448C14.4639 4.14114 14.6247 4.19339 14.7867 4.17927L17.3579 3.95513C17.6557 3.92916 17.9272 4.12643 17.9946 4.41771L18.5759 6.93227C18.6126 7.09072 18.7119 7.22751 18.8513 7.31132L21.0632 8.64126C21.3194 8.79532 21.4231 9.1145 21.3063 9.38973L20.2987 11.7657C20.2352 11.9155 20.2352 12.0845 20.2987 12.2343L21.3063 14.6103C21.4231 14.8855 21.3194 15.2047 21.0632 15.3588L18.8513 16.6887C18.7119 16.7725 18.6126 16.9093 18.5759 17.0678L17.9946 19.5823C17.9272 19.8736 17.6557 20.0709 17.3579 20.0449L14.7867 19.8208C14.6247 19.8066 14.4639 19.8589 14.3411 19.9655L12.3928 21.6582C12.1671 21.8542 11.8315 21.8542 11.6058 21.6582L9.65745 19.9655C9.53468 19.8589 9.37386 19.8066 9.21184 19.8208L6.64071 20.0449C6.34288 20.0709 6.07137 19.8736 6.00403 19.5823L5.42267 17.0678C5.38604 16.9093 5.28665 16.7725 5.14727 16.6887L2.93544 15.3588C2.67922 15.2047 2.57551 14.8855 2.69224 14.6103L3.69994 12.2343C3.76343 12.0845 3.76343 11.9155 3.69994 11.7657L2.69224 9.38973C2.57551 9.1145 2.67922 8.79532 2.93544 8.64126L5.14727 7.31132C5.28665 7.22751 5.38604 7.09072 5.42267 6.93227L6.00403 4.41771C6.07137 4.12643 6.34288 3.92916 6.64071 3.95513L9.21184 4.17927C9.37386 4.19339 9.53468 4.14114 9.65745 4.03448L11.6058 2.34187Z',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M9 13L11 15L16 10',
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    })
  );
};

VerifiedBadge.propTypes = propTypes;

export default VerifiedBadge;