import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var ArrowLeft = function ArrowLeft(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'arrow_thin',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1424.6 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('polygon', {
      points: '1142.9,428.6 273.5,428.6 555.6,146.4 454.6,45.4 0,500 454.6,954.6 555.6,853.6 273.5,571.4 1142.9,571.4 ' // eslint-disable-line max-len
    })
  );
};

ArrowLeft.propTypes = propTypes;

export default ArrowLeft;