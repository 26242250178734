import { FocusEventHandler } from 'react';
import { ControlError } from '../controls.types';
import { ICON_NAMES } from '../icon';

export enum INPUT_TYPES {
  'TEXT' = 'text',
}

export interface InputProps {
  name: string;
  type?: INPUT_TYPES;
  icon?: ICON_NAMES;
  label?: string;
  placeholder: string;
  description?: string;
  value?: string;
  invalid?: boolean;
  // TODO: Inject translated 'required' text into the components and make 'required' prop boolean
  required?: boolean | string;
  isDisabled?: boolean;
  error?: ControlError;
  onBlur?: FocusEventHandler<HTMLElement>;
  onFocus?: FocusEventHandler<HTMLElement>;
  // TODO: Make value generic when we add more types of inputs (number, date etc.)
  onChange?: (value: string) => void;
}
