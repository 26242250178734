import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { v4 as uuidv4 } from 'uuid';
import { ToggleButton } from '../../blocks/toggle_button/toggle_button.component';
import './stories_search_form.scss';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_TYPES,
  BUTTON_VARIANT,
} from '../../blocks/button';
import { DynamicFilter } from '../stories_search_dynamic_filters/stories_search_dynamic_filters.component';
import {
  STORIES_SEARCH_FILTER,
  StaticFilterDisplayData,
  StaticFilterMap,
} from './stories_search_form.types';
import {
  DynamicFilterField,
  DynamicFilterOptions,
  DynamicFilterType,
} from '../stories_search_dynamic_filters/stories_search_dynamic_filters.types';
import {
  storiesSearchStaticFilterMap,
  storiesSearchFormDataToFilterParams,
  deepCopy,
  storiesSearchStateToFilterParams,
} from './stories_search_form.utils';
import {
  GetStoriesSearchThunkRes,
  PaginationParams,
  SearchFilterReqParams,
  STORY_SEARCH_LOAD_TYPES,
} from '../../../http/stories_search.thunks';
import { SelectOptions, SelectValue } from '../../blocks/select';
import { AuthWrapper } from '../../blocks/authwrapper/auth_wrapper.connect';
import { FEATURE_FLAGS } from '../../blocks/authwrapper/auth_wrapper.types';
import {
  SearchFilters,
  StoriesFilterTab,
} from '../../../redux/stories/stories.types';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../blocks/icon';
import { TOAST_TYPES } from '../../blocks/toast';
import { useToast } from '../../hooks/use_toast/use_toast';

export interface StoriesSearchFormProps {
  queryValue: string;
  selectedFilterTab: StoriesFilterTab;
  showClearFilterButtons: boolean;
  staticFilterMap: StaticFilterMap | null | undefined;
  filterTypeOptions: SelectOptions;
  dynamicFilterOptions: DynamicFilterOptions;
  getStoriesSearch: (
    searchFilters?: SearchFilterReqParams | null,
    pagination?: PaginationParams,
    loadingType?: STORY_SEARCH_LOAD_TYPES,
  ) => Promise<GetStoriesSearchThunkRes>;
  storiesClearSearchFiltersAction: () => void;
  storiesSearchFiltersAction: (filters: SearchFilters) => void;
  storiesSearchQuery: (queryValue?: string) => void;
}

export const StoriesSearchFormComponent = ({
  queryValue,
  selectedFilterTab,
  staticFilterMap,
  filterTypeOptions,
  dynamicFilterOptions,
  showClearFilterButtons,
  getStoriesSearch,
  storiesSearchFiltersAction,
  storiesSearchQuery,
  storiesClearSearchFiltersAction,
}: StoriesSearchFormProps) => {
  const methods = useForm();
  // STATIC FILTERS
  const staticFiltersInit = staticFilterMap ?? storiesSearchStaticFilterMap;
  const [staticFilters, setStaticFilter] = useState(staticFiltersInit);
  const { status, visibility } = staticFilters;
  const updateStaticFilters = (
    i: number,
    type:
      | STORIES_SEARCH_FILTER.STATUS
      | STORIES_SEARCH_FILTER.VISIBILITY
      | STORIES_SEARCH_FILTER.CREATOR_TYPE,
  ) => {
    const staticFiltersByType = deepCopy(staticFilters[type]);
    staticFiltersByType[i] = {
      ...staticFiltersByType[i],
      value: !staticFiltersByType[i].value,
    };

    const selectedSF = staticFiltersByType[i];
    // set other filter in category as false
    // so that only one is filter can be applied at a time
    if (staticFiltersByType[i].value === true) {
      const otherSfArray = staticFiltersByType.filter(
        (sf: StaticFilterDisplayData) =>
          staticFiltersByType[i].label !== sf.label,
      );
      otherSfArray[0].value = false;
      otherSfArray.concat([selectedSF]);
    }
    const updatedStaticFilters = {
      ...staticFilters,
      [type]: staticFiltersByType,
    };
    setStaticFilter(updatedStaticFilters);
  };
  // DYNAMIC FILTERS
  const [dynamicFilterFields, setDynamicFilterFields] = useState<
    DynamicFilterField[]
  >([]);
  const [typeOptions, setTypeOptions] = useState(filterTypeOptions);

  const addDynamicFilter = () => {
    const updateFields = [...dynamicFilterFields];
    updateFields.push({
      name: `dfType-${uuidv4()}`,
      options: typeOptions,
    });
    setDynamicFilterFields(updateFields);
  };

  const removeDynamicFilter = (filterName: string) => {
    const updateFields = [...dynamicFilterFields];
    const indexToRemove = updateFields.findIndex((f) => f.name === filterName);
    updateFields.splice(indexToRemove, 1);

    setDynamicFilterFields(updateFields);
  };

  const resetDynamicFilters = () => {
    setDynamicFilterFields([]);
  };

  const selectFilterType = (filterName: string, val: SelectValue<false>) => {
    const updateFields = [...dynamicFilterFields];
    const indexToUpdate = updateFields.findIndex((f) => f.name === filterName);
    const updatedField = {
      ...dynamicFilterFields[indexToUpdate],
      value: val,
      selectedFilters: undefined,
    };
    updateFields.splice(indexToUpdate, 1, updatedField);

    setDynamicFilterFields(updateFields);
  };

  const selectFilter = (filterName: string, val: SelectValue<true>) => {
    const updateFields = [...dynamicFilterFields];
    const indexToUpdate = updateFields.findIndex((f) => f.name === filterName);
    const updatedField = {
      ...dynamicFilterFields[indexToUpdate],
      selectedFilters: val,
    };
    updateFields.splice(indexToUpdate, 1, updatedField);

    setDynamicFilterFields(updateFields);
  };

  useEffect(() => {
    if (dynamicFilterFields.length === 0) {
      addDynamicFilter();
    }

    const selectedFilterTypes = dynamicFilterFields
      .map((field) => field.value)
      .filter((val) => val !== undefined);

    setTypeOptions(
      filterTypeOptions.filter((o) => !selectedFilterTypes.includes(o)),
    );
  }, [dynamicFilterFields]);

  const { showToast } = useToast();

  const onSubmit = async (translate: TranslateFunction) => {
    const dynamicFilters: DynamicFilterType[] =
      dynamicFilterFields.map((field) => {
        return {
          filterType: field.value,
          filterTags: field.selectedFilters,
        };
      }) ?? [];

    // Tech debt SNET-1450: Build request with form data
    const searchFormFiltersParams: SearchFilterReqParams = storiesSearchFormDataToFilterParams(
      dynamicFilters,
      status,
      visibility,
    );
    // Build request with state values
    const searchStateFiltersParams: SearchFilterReqParams = storiesSearchStateToFilterParams(
      queryValue,
      undefined,
      selectedFilterTab,
    );
    try {
      await getStoriesSearch(
        // Merge state params with filter params
        {
          ...searchStateFiltersParams,
          ...searchFormFiltersParams,
        },
        { pageSize: 20 },
        STORY_SEARCH_LOAD_TYPES.SEARCH,
      );
      storiesSearchFiltersAction({
        staticFilterMap: staticFilters,
        dynamicFilterMap: [...dynamicFilters],
      });
      storiesSearchQuery(queryValue);
    } catch {
      showToast({
        message: `${translate('stories.errors.searchWithFilters')}`,
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  return (
    <Translate>
      {({ translate }) => {
        return (
          <FormProvider {...methods}>
            <form
              key={`df-${uuidv4()}`}
              className="stories_search_form"
              onSubmit={methods.handleSubmit(() => onSubmit(translate))}
              aria-label={`${translate(
                'stories.search.filterDropdownAriaLabel',
              )}`}
            >
              <div className="stories_search_form--status">
                <h5>
                  <Translate id="stories.search.status" />
                </h5>
                {status.map((sf: StaticFilterDisplayData, i: number) => {
                  const SFName = `staticFilters.${
                    STORIES_SEARCH_FILTER.STATUS
                  }-${uuidv4()}`;
                  return (
                    <ToggleButton
                      label={sf.label}
                      register={methods.register}
                      id={sf.id}
                      labelKey={<Translate id={`stories.status.${sf.label}`} />}
                      iconType={sf.iconType}
                      handleChange={() => {
                        updateStaticFilters(i, STORIES_SEARCH_FILTER.STATUS);
                      }}
                      value={sf.value}
                      key={`static_filter-${uuidv4()}`}
                      name={SFName}
                    />
                  );
                })}
              </div>
              <AuthWrapper featureFlags={FEATURE_FLAGS.ENABLE_MODERATION}>
                <div className="stories_search_form--visibility">
                  <h5>
                    <Translate id="stories.search.visibility" />
                  </h5>
                  {visibility.map((sf: StaticFilterDisplayData, i: number) => {
                    const SFName = `staticFilters.${
                      STORIES_SEARCH_FILTER.VISIBILITY
                    }-${uuidv4()}`;
                    return (
                      <ToggleButton
                        label={sf.label}
                        register={methods.register}
                        id={sf.id}
                        labelKey={
                          <Translate id={`stories.visibility.${sf.label}`} />
                        }
                        iconType={sf.iconType}
                        handleChange={() => {
                          updateStaticFilters(
                            i,
                            STORIES_SEARCH_FILTER.VISIBILITY,
                          );
                        }}
                        value={sf.value}
                        key={`static_filter-${uuidv4()}`}
                        name={SFName}
                      />
                    );
                  })}
                </div>
              </AuthWrapper>
              {filterTypeOptions.length ? (
                <div className="stories_search_form--dynamic-filters">
                  <h5>
                    <Translate id="stories.search.filterBy" />
                  </h5>
                  {dynamicFilterFields.map((field, i: number) => {
                    const filterOptions = field.value
                      ? dynamicFilterOptions[
                          field.value.value as keyof DynamicFilterOptions
                        ]
                      : [];
                    return (
                      <DynamicFilter
                        field={field}
                        index={i}
                        filterOptions={filterOptions}
                        onSelectFilterType={selectFilterType}
                        onSelectFilter={selectFilter}
                        onClearFilter={removeDynamicFilter}
                      />
                    );
                  })}
                  {typeOptions.length > 0 &&
                    dynamicFilterFields.filter(
                      (f) =>
                        f.selectedFilters === undefined ||
                        f.selectedFilters?.length === 0,
                    ).length === 0 && (
                      <Button
                        variant={BUTTON_VARIANT.GHOST}
                        className="stories_search_form--add_filters"
                        onClick={addDynamicFilter}
                      >
                        <Icon
                          size={ICON_SIZES.LARGE}
                          color="#007BA8"
                          iconKey={ICON_NAMES.PLUS}
                        />
                        <Translate id="stories.search.addFilter" />
                      </Button>
                    )}
                </div>
              ) : (
                ''
              )}

              <div className="stories_search_form--buttons">
                {showClearFilterButtons && (
                  <Button
                    className="stories_search_form--clear_button"
                    onClick={() => {
                      setStaticFilter(storiesSearchStaticFilterMap);
                      resetDynamicFilters();
                      storiesClearSearchFiltersAction();
                      // Build params without any of the filter selection data
                      const searchStateFiltersParams = storiesSearchStateToFilterParams(
                        queryValue,
                        undefined,
                        selectedFilterTab,
                      );
                      return getStoriesSearch(
                        searchStateFiltersParams,
                        { pageSize: 20 },
                        STORY_SEARCH_LOAD_TYPES.SEARCH,
                      );
                    }}
                    variant={BUTTON_VARIANT.OUTLINE}
                    color={BUTTON_COLOR_VARIANT.SECONDARY}
                  >
                    <Translate id="stories.search.clearFilters" />
                  </Button>
                )}
                <Button type={BUTTON_TYPES.SUBMIT}>
                  <Translate id="stories.search.applyFilters" />
                </Button>
              </div>
            </form>
          </FormProvider>
        );
      }}
    </Translate>
  );
};

StoriesSearchFormComponent.displayName = 'StoriesSearchForm';
