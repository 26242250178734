import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var InstagramComment = function InstagramComment(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'InstagramComment__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M500,62.5c241.2,0,437.5,196.3,437.5,437.5c0,72.3-17.2,141.4-51,205.2l-10.8,20.3l4.9,22.4L916,908.7l-136.6-42l-27-8.3\tl-24.1,14.8c-68.5,42.1-147.4,64.3-228.2,64.3C258.8,937.5,62.5,741.2,62.5,500S258.8,62.5,500,62.5 M500,0C223.8,0,0,223.8,0,500s223.8,500,500,500c95.7,0,185-26.9,261-73.6l239,73.6l-58.3-265.5c37.1-70,58.3-149.8,58.3-234.5C1000,223.8,776.2,0,500,0L500,0z' // eslint-disable-line max-len
    })
  );
};

InstagramComment.propTypes = propTypes;

export default InstagramComment;