import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PhotoRectangleBentCorner = function PhotoRectangleBentCorner(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'PhotoRectangleBentCorner__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1249 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M611.2,609.4c-48.5,0-87.8-39.3-87.8-87.8c0-48.5,39.3-87.8,87.8-87.8c48.5,0,87.8,39.3,87.8,87.8\tC699,570.1,659.7,609.4,611.2,609.4z M1249,0v1000H0V224L224,0H1249z M249.9,62.5L62.5,249.8h187.4V62.5z M500,937.5L663.3,750l93.8,93.8l257.8-257.8l171.6,150.2V62.5H312.3v249.8H62.5l0,0v625.2H500z' // eslint-disable-line max-len
    })
  );
};

PhotoRectangleBentCorner.propTypes = propTypes;

export default PhotoRectangleBentCorner;