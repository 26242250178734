import React, { useEffect, useReducer } from 'react';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { DetailedStorySortable } from '../detailed_story/detailed_story_sortable';
import { Story, STORY_STATUS } from '../story/story.types';
import './gallery_sortable_grid.scss';

export interface GallerySortableGridProps {
  storyList: Story[];
  currentPublisherId: string;
  currentNetworkId: string;
  updateGalleryStories: (sortedList: Story[]) => void;
}

export enum ACTION_TYPES {
  'REMOVE' = 'remove',
  'REORDER' = 'reorder',
  'REINSTANTIATE' = 're-instantiate',
}
export interface DispatchActionType {
  type: string;
  active?: {
    id: string;
  };
  over?: {
    id: string;
  } | null;
  storyList?: Story[];
  id?: string;
}

const reducer = (
  state: Story[],
  {
    type = '',
    active = { id: '' },
    over = { id: '' },
    id = '',
    storyList = [],
  }: DispatchActionType,
) => {
  const currentStories = state;
  switch (type) {
    case ACTION_TYPES.REORDER: {
      const oldIndex = currentStories.findIndex(
        (story) => story.uuid === active?.id,
      );
      const newIndex = currentStories.findIndex(
        (story) => story.uuid === over?.id,
      );
      return arrayMove(currentStories, oldIndex, newIndex);
    }
    case ACTION_TYPES.REMOVE: {
      return currentStories.filter((story) => story.uuid !== id);
    }
    case ACTION_TYPES.REINSTANTIATE:
      return storyList;
    default:
      return state;
  }
};

export const GallerySortableGrid = ({
  storyList,
  currentPublisherId,
  currentNetworkId,
  updateGalleryStories,
}: GallerySortableGridProps) => {
  // Redux store value is added to a local state to achieve the expected animation
  // If the local state is remove then the state will re-render with every move and
  // will cause of problem with animations and animaiton performance.
  const [stories, dispatch] = useReducer(reducer, storyList);
  const sensors = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    dispatch({ type: ACTION_TYPES.REINSTANTIATE, storyList });
  }, [storyList]);

  useEffect(() => {
    updateGalleryStories(stories);
  }, [stories]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      dispatch({ type: ACTION_TYPES.REORDER, active, over });
    }
  };

  const handleRemove = (id: string) => {
    dispatch({ type: ACTION_TYPES.REMOVE, id });
  };

  const createStory = (story: Story) => {
    const baseUrl =
      story.status === STORY_STATUS.DRAFT
        ? story.storyData?.canonicalUrl
        : story.storyData?.url;

    const previewSrc = baseUrl
      ? `${baseUrl}?networkUuid=${currentNetworkId}`
      : '';
    return (
      <DetailedStorySortable
        key={story.uuid}
        story={story}
        previewSrc={previewSrc}
        currentPublisherId={currentPublisherId}
        onRemove={handleRemove}
      />
    );
  };

  return (
    <ul className="gallery-sortable__grid">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={stories.map((story) => story.uuid)}>
          {stories.map((story) => createStory(story))}
        </SortableContext>
      </DndContext>
    </ul>
  );
};
