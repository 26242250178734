import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var StarOutline = function StarOutline(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'StarOutline__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 14 14',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M7.00997 1.70982L8.46533 4.65555L8.68882 5.10926L9.1883 5.18196L12.4316 5.65587L10.0809 7.9446L9.7201 8.29733L9.80492 8.79412L10.3542 12.0293L7.45291 10.5039L7.00458 10.2683L6.55761 10.5039L3.6563 12.032L4.2056 8.79278L4.29041 8.29599L3.9296 7.94325L1.57355 5.64644L4.82086 5.18062L5.32034 5.10792L5.54518 4.65555L7.00997 1.70982ZM7.01131 0C6.8713 0 6.74474 0.0794325 6.68416 0.20464L4.68354 4.22877L0.371294 4.84808C0.20839 4.87231 0.0737582 4.98675 0.0212519 5.14157C-0.029908 5.29775 0.0131741 5.47007 0.130303 5.58451L3.25644 8.63257L2.51463 13.0014C2.48905 13.1494 2.54963 13.3002 2.67214 13.3877C2.74081 13.4376 2.82158 13.4631 2.90371 13.4631C2.96699 13.4631 3.03161 13.447 3.0895 13.416L7.00324 11.3548L10.925 13.416C10.9816 13.4443 11.0422 13.4578 11.1028 13.4578C11.1849 13.4578 11.267 13.4322 11.3357 13.381C11.4555 13.2922 11.5161 13.1454 11.4918 13L10.75 8.63122L13.8708 5.5899C13.9879 5.47411 14.0296 5.30313 13.9785 5.14696C13.9273 4.99079 13.7927 4.8777 13.6311 4.85346L9.32563 4.22608L7.33981 0.20464C7.27923 0.0794325 7.15133 0 7.01131 0Z' // eslint-disable-line max-len
    })
  );
};

StarOutline.propTypes = propTypes;

export default StarOutline;