/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Approve = function Approve(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'approve',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 16 16',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M5.96485 1.01359C6.07107 0.985139 6.18381 1.00175 6.27826 1.05976L8.1268 2.19518L9.81867 1.2255C9.91474 1.17044 10.0279 1.15733 10.1333 1.18906C10.2386 1.22079 10.3276 1.29476 10.3805 1.3947L11.402 3.32336L13.3197 3.54806C13.5467 3.57466 13.7099 3.78762 13.6844 4.02373L13.4458 6.22677L14.8794 7.72341C15.0406 7.89173 15.0401 8.16413 14.8783 8.33184L13.4393 9.82337L13.6296 12.0508C13.6393 12.1645 13.6052 12.2775 13.5348 12.365C13.4644 12.4525 13.3635 12.5073 13.2543 12.5174L11.4844 12.681L10.2882 14.7885C10.2324 14.8868 10.1414 14.958 10.0352 14.9864C9.92893 15.0149 9.81619 14.9983 9.72174 14.9402L7.8732 13.8048L6.18133 14.7745C5.98127 14.8892 5.72974 14.8134 5.61952 14.6053L4.59799 12.6766L2.68031 12.4519C2.57131 12.4392 2.47166 12.3819 2.40327 12.2927C2.33487 12.2035 2.30335 12.0896 2.31563 11.9763L2.55418 9.77323L1.12061 8.27659C0.959387 8.10827 0.959858 7.83587 1.12166 7.66816L2.56066 6.17663L2.37039 3.94917C2.35017 3.71249 2.51822 3.50358 2.74573 3.48255L4.51561 3.31895L5.71185 1.21148C5.76762 1.11323 5.85863 1.04204 5.96485 1.01359ZM6.21383 2.01943L5.11989 3.94668C5.05211 4.06609 4.93315 4.14419 4.80037 4.15647L3.2309 4.30154L3.40171 6.30113C3.41257 6.42827 3.3686 6.55387 3.28167 6.64396L1.99846 7.97402L3.28037 9.31232C3.36902 9.40487 3.41244 9.53431 3.39838 9.66414L3.18388 11.6451L4.90325 11.8465C5.03663 11.8622 5.15442 11.9441 5.21919 12.0664L6.14441 13.8133L7.68071 12.9327C7.8088 12.8593 7.96464 12.8617 8.09057 12.9391L9.78617 13.9806L10.8801 12.0533C10.9479 11.9339 11.0668 11.8558 11.1996 11.8435L12.7691 11.6985L12.5983 9.69888C12.5874 9.57173 12.6314 9.44613 12.7183 9.35604L14.0015 8.02598L12.7196 6.68768C12.631 6.59513 12.5876 6.46569 12.6016 6.33586L12.8161 4.35494L11.0968 4.15347C10.9634 4.13785 10.8456 4.05586 10.7808 3.93357L9.8556 2.18674L8.31929 3.06726C8.1912 3.14067 8.03536 3.13826 7.90943 3.06091L6.21383 2.01943Z'
    }),
    React.createElement('path', { xmlns: 'http://www.w3.org/2000/svg', d: 'M10.722 6.55294L7.12093 10.2989L5.2569 8.35987L5.84178 7.75145L7.12093 9.08208L10.1371 5.94452L10.722 6.55294Z', fill: color, stroke: color, strokeWidth: '0.5' })
  );
};

Approve.propTypes = propTypes;

export default Approve;