import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PlayButtonStack = function PlayButtonStack(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      height: size,
      viewBox: '0 0 32 32',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg'
    },
    React.createElement('path', {
      d: 'M27.9999 10.1335V27.2002C27.9999 27.4123 27.9156 27.6158 27.7656 27.7658C27.6156 27.9159 27.4121 28.0002 27.1999 28.0002H10.1333C9.92108 28.0002 9.7176 27.9159 9.56757 27.7658C9.41754 27.6158 9.33325 27.4123 9.33325 27.2002V10.1335C9.33325 9.92132 9.41754 9.71784 9.56757 9.56781C9.7176 9.41778 9.92108 9.3335 10.1333 9.3335H27.1999C27.4121 9.3335 27.6156 9.41778 27.7656 9.56781C27.9156 9.71784 27.9999 9.92132 27.9999 10.1335Z',
      stroke: color,
      'stroke-width': '1.94667',
      'stroke-linecap': 'round',
      'stroke-linejoin': 'round'
    }),
    React.createElement('path', {
      d: 'M23.9999 5.3335H6.13325C5.92108 5.3335 5.7176 5.41778 5.56757 5.56781C5.41754 5.71784 5.33325 5.92132 5.33325 6.1335V24.0002M17.2119 15.3935C17.0905 15.3205 16.9518 15.2811 16.8101 15.2793C16.6685 15.2775 16.5288 15.3134 16.4056 15.3832C16.2823 15.453 16.1798 15.5544 16.1085 15.6768C16.0372 15.7993 15.9998 15.9385 15.9999 16.0802V21.2535C15.9998 21.3952 16.0372 21.5344 16.1085 21.6568C16.1798 21.7793 16.2823 21.8806 16.4056 21.9505C16.5288 22.0203 16.6685 22.0562 16.8101 22.0543C16.9518 22.0525 17.0905 22.0131 17.2119 21.9402L21.5226 19.3535C21.6414 19.2825 21.7397 19.1819 21.8079 19.0616C21.8762 18.9412 21.9121 18.8052 21.9121 18.6668C21.9121 18.5285 21.8762 18.3925 21.8079 18.2721C21.7397 18.1517 21.6414 18.0512 21.5226 17.9802L17.2119 15.3935Z',
      stroke: color,
      'stroke-width': '1.94667',
      'stroke-linecap': 'round',
      'stroke-linejoin': 'round'
    })
  );
};

PlayButtonStack.propTypes = propTypes;

export default PlayButtonStack;