export enum TEXT_VARIANTS {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  B1 = 'b1',
  B12 = 'b12',
  B2 = 'b2',
  B22 = 'b22',
  B23 = 'b23',
  C1 = 'c1',
  C12 = 'c12',
}

export enum TEXT_ELEMENTS {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  P = 'p',
  LABEL = 'label',
  SPAN = 'span',
  LIST = 'li',
  UNORDERED_LIST = 'ul',
}
