import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../../types';
import { NameGallerySection } from './name_gallery_section.component';
import { updateStaticGalleryCreationValue } from '../../../../redux/galleries/galleries.actions';
import { UnhydratedGallery } from '../../../../redux/galleries/galleries.types';
import { selectUnhydratedGalleryLabel } from '../../../../redux/galleries/galleries.selectors';

const mapStateToProps = (state: AppState) => ({
  currentGalleryName: selectUnhydratedGalleryLabel(state),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  updateStaticGalleryCreationValue: (val: Partial<UnhydratedGallery>) =>
    dispatch(updateStaticGalleryCreationValue(val)),
});

export const NameGallerySectionConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NameGallerySection);
