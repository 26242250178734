import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Close = function Close(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Layer_1',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M500 381.1L856.5 24.6c32.8-32.8 86-32.8 118.8 0s32.8 86 0 118.8L618.9 500l356.5 356.5c32.8 32.8 32.8 86 0 118.9-32.8 32.8-86 32.8-118.8 0L500 618.8 143.5 975.4c-32.8 32.8-86 32.8-118.8 0s-32.8-86 0-118.8L381.1 500 24.6 143.5c-32.8-32.8-32.8-86 0-118.8s86-32.8 118.9 0L500 381.1z' // eslint-disable-line max-len
    })
  );
};

Close.propTypes = propTypes;

export default Close;