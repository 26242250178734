import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Personreverse = function Personreverse(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Personreverse__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 933.8 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M212,254.9C212,114.2,326.2,0,466.9,0s254.9,114.2,254.9,254.9S607.6,509.7,466.9,509.7S212,395.6,212,254.9z M466.9,1000\tc179.4,0,343-67.6,466.8-178.6c-4.1-149.2-45.8-354.2-229.3-354.2c-20.6,0-107.5,92.3-237.6,92.3s-217-92.3-237.6-92.3C45.8,467.3,4.1,672.2,0,821.4l0,0C123.9,932.4,287.5,1000,466.9,1000z' // eslint-disable-line max-len
    })
  );
};

Personreverse.propTypes = propTypes;

export default Personreverse;