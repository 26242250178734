import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var MediaImageList = function MediaImageList(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'mediaImageList__icon',
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M21 7.6V20.4C21 20.7314 20.7314 21 20.4 21H7.6C7.26863 21 7 20.7314 7 20.4V7.6C7 7.26863 7.26863 7 7.6 7H20.4C20.7314 7 21 7.26863 21 7.6Z',
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    }),
    React.createElement('path', {
      d: 'M18 4H4.6C4.26863 4 4 4.26863 4 4.6V18',
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    }),
    React.createElement('path', {
      d: 'M7 16.8L12.4444 15L21 18',
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    }),
    React.createElement('path', {
      d: 'M16.5 13C15.6716 13 15 12.3284 15 11.5C15 10.6716 15.6716 10 16.5 10C17.3284 10 18 10.6716 18 11.5C18 12.3284 17.3284 13 16.5 13Z',
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    })
  );
};

MediaImageList.propTypes = propTypes;

export default MediaImageList;