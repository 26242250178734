import _Object$keys from 'babel-runtime/core-js/object/keys';
import _slicedToArray from 'babel-runtime/helpers/slicedToArray';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { stableSort, getSorting } from './table-sort/PaginatedTable.helpers';
import { rowsPerPageOptions, DATA_CELL_TYPE } from './PaginatedTable.constants';
import TableHeader from './components/TableHeader';
import EnhancedTableToolbar from './components/TableToolbar';
import LinkCell from './components/LinkCell';
import DateCell from './components/DateCell';

var propDescriptions = {
  rows: 'Array of data that should show in the table',
  headCells: 'Array of header data',
  tableWidth: 'Table width',
  tableHeadColor: 'Font color of header cells',
  tableHeadFontSize: 'Font size of header cells',
  showSelectRowOption: 'Show the option of selecting rows',
  showAlternateRowColor: 'Alternate row color',
  alternateRowColor: 'Color of the alternate row',
  showDensePaddingOption: 'Set the padding applied to cell',
  showRowsPerPageOptions: 'Show rows per page component to select number of rows showing per page',
  showSortableColumnsOption: 'Show the option to sort columns',
  rowsPerPageToShow: 'How many rows per page are showing in the table'
};

var propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  headCells: PropTypes.arrayOf(PropTypes.object),
  tableWidth: PropTypes.string,
  tableHeadColor: PropTypes.string,
  tableHeadFontSize: PropTypes.string,
  showSelectRowOption: PropTypes.bool,
  showAlternateRowColor: PropTypes.bool,
  alternateRowColor: PropTypes.string,
  showDensePaddingOption: PropTypes.bool,
  showRowsPerPageOptions: PropTypes.bool,
  showSortableColumnsOption: PropTypes.bool,
  rowsPerPageToShow: PropTypes.number
};

var defaultProps = {
  tableWidth: '100%',
  tableHeadColor: '#666',
  tableHeadFontSize: '14px',
  showAlternateRowColor: true,
  alternateRowColor: 'rgba(138, 140, 144, 0.05)',
  showSelectRowOption: false,
  showDensePaddingOption: false,
  showRowsPerPageOptions: false,
  showSortableColumnsOption: false,
  rowsPerPageToShow: 0
};

var StyledTableRow = withStyles(function () {
  return {
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: function backgroundColor(props) {
          return props.backgroundcolor || 'rgba(138, 140, 144, 0.05)';
        }
      },
      '&:hover': {
        backgroundColor: 'rgba(197, 200, 202, 0.3) !important'
      }
    }
  };
})(TableRow);

var StyledTableCell = withStyles(function () {
  return {
    body: {
      color: '#666',
      fontSize: '14px',
      lineHeight: '20px'
    }
  };
})(TableCell);

var StyledTablePagination = withStyles(function () {
  return {
    root: {
      overflowX: 'auto',
      marginLeft: 'auto'
    },
    caption: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#666',
      fontFamily: 'Lato, Helvetica Neue, Helvetica, Arial, sans-serif'
    },
    actions: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#666'
    }
  };
})(TablePagination);

var useStyles = makeStyles(function () {
  return {
    paper: {
      boxShadow: 'none'
    },
    table: {
      minWidth: 750
    },
    tableWrapper: {
      overflowX: 'auto'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    }
  };
});

export default function PaginatedTable(props) {
  var rows = props.rows,
      rowsPerPageToShow = props.rowsPerPageToShow,
      tableWidth = props.tableWidth,
      showSortableColumnsOption = props.showSortableColumnsOption,
      showAlternateRowColor = props.showAlternateRowColor,
      headCells = props.headCells,
      alternateRowColor = props.alternateRowColor,
      tableHeadColor = props.tableHeadColor,
      tableHeadFontSize = props.tableHeadFontSize,
      showSelectRowOption = props.showSelectRowOption,
      showDensePaddingOption = props.showDensePaddingOption,
      showRowsPerPageOptions = props.showRowsPerPageOptions;


  var calculatedRowsPerPage = rows.length > 5 ? 5 : rows.length;
  var rowsToShow = rowsPerPageToShow > 0 ? rowsPerPageToShow : calculatedRowsPerPage;

  var classes = useStyles();

  var _useState = useState('asc'),
      _useState2 = _slicedToArray(_useState, 2),
      order = _useState2[0],
      setOrder = _useState2[1];

  var _useState3 = useState(headCells[0].id),
      _useState4 = _slicedToArray(_useState3, 2),
      orderBy = _useState4[0],
      setOrderBy = _useState4[1];

  var _useState5 = useState([]),
      _useState6 = _slicedToArray(_useState5, 2),
      selected = _useState6[0],
      setSelected = _useState6[1];

  var _useState7 = useState(0),
      _useState8 = _slicedToArray(_useState7, 2),
      page = _useState8[0],
      setPage = _useState8[1];

  var _useState9 = useState(false),
      _useState10 = _slicedToArray(_useState9, 2),
      dense = _useState10[0],
      setDense = _useState10[1];

  var _useState11 = useState(rowsToShow),
      _useState12 = _slicedToArray(_useState11, 2),
      rowsPerPage = _useState12[0],
      setRowsPerPage = _useState12[1];

  useEffect(function () {
    setRowsPerPage(rowsToShow);
  }, [rowsPerPageToShow]);

  var handleRequestSort = function handleRequestSort(event, property) {
    var isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  var handleSelectAllClick = function handleSelectAllClick(event) {
    if (event.target.checked) {
      var newSelecteds = rows.map(function (n) {
        return n.name;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  var handleClick = function handleClick(event, name) {
    var selectedIndex = selected.indexOf(name);
    var newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  var handleChangePage = function handleChangePage(event, newPage) {
    setPage(newPage);
  };

  var handleChangeRowsPerPage = function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  var handleChangeDense = function handleChangeDense(event) {
    setDense(event.target.checked);
  };

  var isSelected = function isSelected(name) {
    return selected.indexOf(name) !== -1;
  };

  var emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  var getDataCellTypeByKey = function getDataCellTypeByKey(key) {
    var headCellConfig = headCells.find(function (headCell) {
      return headCell.id === key;
    });
    return headCellConfig ? headCellConfig.type : null;
  };

  var totalRows = showSortableColumnsOption ? stableSort(rows, getSorting(order, orderBy, getDataCellTypeByKey(orderBy))) : rows;

  var TableRowToShow = showAlternateRowColor ? StyledTableRow : TableRow;
  return React.createElement(
    'div',
    { className: classes.root, style: { width: tableWidth } },
    React.createElement(
      Paper,
      { className: classes.paper },
      React.createElement(
        'div',
        { className: classes.tableWrapper },
        React.createElement(
          Table,
          {
            className: classes.table,
            'aria-labelledby': 'tableTitle',
            size: dense ? 'small' : 'medium'
          },
          React.createElement(TableHeader, {
            classes: classes,
            numSelected: selected.length,
            order: order,
            orderBy: orderBy,
            onSelectAllClick: handleSelectAllClick,
            onRequestSort: handleRequestSort,
            rowCount: rows.length,
            headCells: headCells,
            areColumnsSortable: showSortableColumnsOption,
            showSelectRowOption: showSelectRowOption,
            rows: rows,
            tableHeadColor: tableHeadColor,
            tableHeadFontSize: tableHeadFontSize
          }),
          React.createElement(
            TableBody,
            null,
            totalRows
            // eslint-disable-next-line no-mixed-operators
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(function (row, index) {
              var isItemSelected = isSelected(row.name);
              var labelId = 'enhanced-table-checkbox-' + index;

              return React.createElement(
                TableRowToShow,
                {
                  hover: true,
                  onClick: function onClick(event) {
                    return showSelectRowOption && handleClick(event, row.name);
                  },
                  role: 'checkbox',
                  'aria-checked': isItemSelected,
                  tabIndex: -1,
                  key: index,
                  selected: isItemSelected,
                  backgroundcolor: alternateRowColor
                },
                showSelectRowOption && React.createElement(
                  TableCell,
                  { padding: 'checkbox' },
                  React.createElement(Checkbox, {
                    checked: isItemSelected,
                    inputProps: { 'aria-labelledby': labelId }
                  })
                ),
                _Object$keys(row).map(function (key, rowIndex) {
                  var type = getDataCellTypeByKey(key);
                  switch (type) {
                    case DATA_CELL_TYPE.LINK:
                      return React.createElement(
                        LinkCell,
                        { key: rowIndex, href: row[key].url },
                        row[key].text
                      );
                    case DATA_CELL_TYPE.DATE:
                      return React.createElement(DateCell, { key: rowIndex, date: row[key] });
                    case DATA_CELL_TYPE.TRUNCATED:
                      return React.createElement(
                        StyledTableCell,
                        { key: rowIndex },
                        React.createElement(
                          'div',
                          { title: row[key], className: 'cr-table__truncatedTextCell' },
                          row[key]
                        )
                      );
                    default:
                      return React.createElement(
                        StyledTableCell,
                        { key: rowIndex },
                        row[key]
                      );
                  }
                })
              );
            }),
            emptyRows > 0 && React.createElement(
              TableRow,
              { style: { height: 69 * emptyRows } },
              React.createElement(TableCell, { colSpan: showSelectRowOption ? headCells.length + 1 : headCells.length })
            )
          )
        )
      ),
      React.createElement(
        'div',
        { style: { display: 'flex', justifyContent: 'space-between' } },
        selected && !!selected.length && React.createElement(EnhancedTableToolbar, { numSelected: selected.length }),
        React.createElement(StyledTablePagination, {
          rowsPerPageOptions: showRowsPerPageOptions ? rowsPerPageOptions : [],
          component: 'div',
          count: rows.length,
          rowsPerPage: rowsPerPage,
          page: page,
          backIconButtonProps: {
            'aria-label': 'previous page'
          },
          nextIconButtonProps: {
            'aria-label': 'next page'
          },
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage
        })
      )
    ),
    showDensePaddingOption && React.createElement(FormControlLabel, {
      control: React.createElement(Switch, { checked: dense, onChange: handleChangeDense }),
      label: 'Dense padding'
    })
  );
}
PaginatedTable.propDescriptions = propDescriptions;
PaginatedTable.propTypes = propTypes;
PaginatedTable.defaultProps = defaultProps;