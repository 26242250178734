import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var RightsExternal = function RightsExternal(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'RightsExternal_icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M517.079 995.971C533.741 988.871 928.166 815.67 928.166 541.57H928.372V41.5704C928.372 18.7704 908.934 -0.129272 885.484 -0.129272H114.117C90.6674 -0.129272 71.229 18.7704 71.229 41.5704V541.57C71.229 815.57 465.552 988.771 482.316 995.971C487.664 998.571 493.629 999.871 499.698 999.871C505.663 999.871 511.731 998.571 517.079 995.971ZM287.241 692.731L214.085 619.575L608.525 225.135L377.991 226.006L378.37 122.766L785.516 121.302L784.052 528.449L680.81 528.825L681.681 298.291L287.241 692.731Z' // eslint-disable-line max-len
    })
  );
};

RightsExternal.propTypes = propTypes;

export default RightsExternal;