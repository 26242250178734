import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  linkLabel: PropTypes.node,
  assetsToDisplay: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string
  })).isRequired,
  width: PropTypes.number.isRequired,
  clickHandler: PropTypes.func,
  ariaLabel: PropTypes.string
};

var propDescriptions = {
  assetsToDisplay: 'An array of up to 5 assets with a url property',
  className: 'A string that is used on the containing div as a classname',
  label: 'A string displayed at the top left corner of the image strip',
  linkLabel: 'A react componenet displayed at the top right corner of the image strip',
  width: 'A number that will be used as the width and height of the image',
  ariaLabel: 'Optional prop to add more context to the button element for screen readers'
};

var defaultProps = {};

var PhotoStrip = function (_Component) {
  _inherits(PhotoStrip, _Component);

  function PhotoStrip(props) {
    _classCallCheck(this, PhotoStrip);

    return _possibleConstructorReturn(this, (PhotoStrip.__proto__ || _Object$getPrototypeOf(PhotoStrip)).call(this, props));
  }

  _createClass(PhotoStrip, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          assetsToDisplay = _props.assetsToDisplay,
          width = _props.width,
          clickHandler = _props.clickHandler,
          className = _props.className,
          ariaLabel = _props.ariaLabel;

      var assetsArray = assetsToDisplay.length > 5 ? assetsToDisplay.slice(0, 5) : assetsToDisplay;
      // if there are less than 5 images - add some placeholders
      if (assetsArray.length < 5) {
        var toAdd = 5 - assetsArray.length;
        for (var i = 0; i < toAdd; i += 1) {
          assetsArray.push({ url: null });
        }
      }
      return React.createElement(
        'button',
        {
          onClick: function onClick() {
            return clickHandler();
          },
          className: 'photoStrip ' + className,
          'aria-label': ariaLabel
        },
        React.createElement(
          'div',
          { className: 'photoStrip__containTitles' },
          React.createElement(
            'h2',
            null,
            this.props.label
          ),
          React.createElement(
            'div',
            { className: 'photoStrip__link' },
            this.props.linkLabel
          )
        ),
        React.createElement(
          'div',
          { className: 'photoStrip__containImgs' },
          assetsArray.map(function (asset, i) {
            var isPlaceholder = !asset.url;
            var style = {
              width: width,
              height: width
            };
            if (!isPlaceholder) {
              style.backgroundImage = 'url(' + asset.url + ')';
            }
            return React.createElement(
              'div',
              {
                style: style,
                className: 'photoStrip__backgroundImg',
                key: asset.url + '_' + i
              },
              React.createElement('div', { className: 'photoStrip__hoverOverlay' })
            );
          })
        )
      );
    }
  }]);

  return PhotoStrip;
}(Component);

export default PhotoStrip;


PhotoStrip.propTypes = propTypes;
PhotoStrip.defaultProps = defaultProps;
PhotoStrip.propDescriptions = propDescriptions;