import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../../types';
import { selectIsLocalhoodOwner } from '../../../../redux/user/user.selectors';
import { DownloadStory as DownloadStoryComponent } from './download_story.component';

const mapStateToProps = (state: AppState) => ({
  isChallengeModerator: selectIsLocalhoodOwner(state),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});
export const DownloadStoryConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadStoryComponent);
