import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var QuoteRight = function QuoteRight(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'quoteRight__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1181.8 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M545.5,636.4c0,200.3-163.4,363.6-363.6,363.6h-45.5c-24.8,0-45.5-20.6-45.5-45.5v-90.9c0-24.8,20.6-45.5,45.5-45.5h45.5 c100.2,0,181.8-81.7,181.8-181.8v-22.7c0-37.7-30.5-68.2-68.2-68.2H136.4C61.1,545.5,0,484.4,0,409.1V136.4C0,61.1,61.1,0,136.4,0 h272.7c75.3,0,136.4,61.1,136.4,136.4V636.4z M1181.8,636.4c0,200.3-163.4,363.6-363.6,363.6h-45.5c-24.8,0-45.5-20.6-45.5-45.5 v-90.9c0-24.8,20.6-45.5,45.5-45.5h45.5c100.2,0,181.8-81.7,181.8-181.8v-22.7c0-37.7-30.5-68.2-68.2-68.2H772.7 c-75.3,0-136.4-61.1-136.4-136.4V136.4C636.4,61.1,697.5,0,772.7,0h272.7c75.3,0,136.4,61.1,136.4,136.4V636.4z' // eslint-disable-line max-len
    })
  );
};

QuoteRight.propTypes = propTypes;

export default QuoteRight;