import React from 'react';
import { PropTypes } from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var NetworkAlt = function NetworkAlt(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'NetworkAlt__icon',
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M9.4 22H3.6C3.26863 22 3 21.7314 3 21.4V17.6C3 17.2686 3.26863 17 3.6 17H9.4C9.73137 17 10 17.2686 10 17.6V21.4C10 21.7314 9.73137 22 9.4 22Z',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M14.9 7H9.1C8.76863 7 8.5 6.73137 8.5 6.4V2.6C8.5 2.26863 8.76863 2 9.1 2H14.9C15.2314 2 15.5 2.26863 15.5 2.6V6.4C15.5 6.73137 15.2314 7 14.9 7Z',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M20.4 22H14.6C14.2686 22 14 21.7314 14 21.4V17.6C14 17.2686 14.2686 17 14.6 17H20.4C20.7314 17 21 17.2686 21 17.6V21.4C21 21.7314 20.7314 22 20.4 22Z',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M6.5 17V13.5C6.5 12.3954 7.39543 11.5 8.5 11.5H15.5C16.6046 11.5 17.5 12.3954 17.5 13.5V17',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M12 11.5V7',
      stroke: color,
      strokeWidth: '1.5'
    })
  );
};

NetworkAlt.propTypes = propTypes;

export default NetworkAlt;