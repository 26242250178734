import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { withController } from '../../../../hooks/withController';
import { Text, TEXT_ELEMENTS, TEXT_VARIANTS } from '../../../../blocks/text';
import {
  FileUpload,
  FileUploadProps,
  FILE_TYPES,
} from '../../../../blocks/file_upload/file_upload.component';
import { WebPreview } from '../../web_preview';
import { useTranslateStrings } from '../../../../hooks/useTranslateStrings';
import { isUploadedImageValid } from '../../account_settings.utils';
import { FAVICON_UPLOAD_REQ } from '../../account_settings.constants';
import { WebSettingsSectionProps } from '../../../../pages/account_settings/account_settings.types';

const ControlledFileUpload = withController<File[], FileUploadProps>(
  FileUpload,
);

const WebSettingsSectionComponent = ({
  translate,
  faviconUrl,
  faviconImg,
  isFaviconLoading = false,
}: WebSettingsSectionProps) => {
  const getTranslation = useTranslateStrings(translate);
  return (
    <div className="account_edit__web">
      <Text variant={TEXT_VARIANTS.H3}>
        {getTranslation('settings.webSettings.title')}
      </Text>
      <WebPreview faviconUrl={faviconUrl} />
      <div>
        <Text
          className="account_edit__appearance--avatar_title"
          variant={TEXT_VARIANTS.B1}
        >
          {getTranslation('settings.webSettings.favicon.title')}
        </Text>
        <Text
          className="account_edit__appearance--avatar_description"
          variant={TEXT_VARIANTS.C1}
        >
          {getTranslation('settings.webSettings.favicon.description')}
        </Text>
        <Text
          className="account_edit__appearance--avatar_body"
          element={TEXT_ELEMENTS.UNORDERED_LIST}
        >
          <Text element={TEXT_ELEMENTS.LIST} variant={TEXT_VARIANTS.C1}>
            {translate('settings.webSettings.favicon.req1', undefined)}
          </Text>
          <Text element={TEXT_ELEMENTS.LIST} variant={TEXT_VARIANTS.C1}>
            {translate('settings.webSettings.favicon.req2', undefined)}
          </Text>
          <Text element={TEXT_ELEMENTS.LIST} variant={TEXT_VARIANTS.C1}>
            {translate('settings.webSettings.favicon.req3', undefined)}
          </Text>
        </Text>
        <ControlledFileUpload
          name="favicon"
          title={
            faviconImg
              ? `${translate('settings.formActions.replaceIMG')}`
              : `${translate('settings.formActions.uploadIMG')}`
          }
          fileType={`${FILE_TYPES.PNG}, ${FILE_TYPES.FAVICON}`}
          file={faviconImg}
          isLoading={isFaviconLoading}
          validationRules={{
            validate: async (files) => {
              return (
                !files || isUploadedImageValid(files?.[0], FAVICON_UPLOAD_REQ)
              );
            },
          }}
        />
      </div>
    </div>
  );
};

export const WebSettingsSection = withLocalize(WebSettingsSectionComponent);
