import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Minus = function Minus(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'minus_icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 14 14',
      height: size,
      fill: color,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M6.125 6.12488C6.65625 6.1251 6.517 6.12476 7 6.12476C7.483 6.12476 7.2109 6.12476 7.875 6.12488L13.125 6.12488C13.608 6.12488 14 6.51688 14 6.99988C14 7.48288 13.608 7.87488 13.125 7.87488L7.875 7.87488C7.17188 7.87488 7.483 7.87488 7 7.87488C6.517 7.87488 6.66016 7.87488 6.125 7.87488L0.875 7.87488C0.392 7.87488 0 7.48288 0 6.99988C0 6.51688 0.392 6.12488 0.875 6.12488L6.125 6.12488Z' // eslint-disable-line max-len
    })
  );
};

Minus.propTypes = propTypes;

export default Minus;