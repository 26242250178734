import axios from './axios_config';
import { CRThunk, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { getErrorMessage } from '../utils';
import { urlBuilder } from './util/url_builder';
import {
  setUserAcceptanceFailureAction,
  setUserAcceptanceStartAction,
  setUserAcceptanceSuccessAction,
} from '../redux/user/user.actions';

export interface TermsAcceptanceUpdateResponse {
  error?: Error;
}
/**
 * TOSUserAcceptanceUpdate Fn calls TOS acceptance call from gateway
 * @returns - empty object
 */
export const TOSUserAcceptanceUpdate: CRThunk<
  void,
  TermsAcceptanceUpdateResponse
> = () => async (dispatch) => {
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.USER_ACCEPTANCE,
  });
  dispatch(setUserAcceptanceStartAction());
  try {
    const response = await axios.put<TermsAcceptanceUpdateResponse>(url);
    dispatch(setUserAcceptanceSuccessAction(response));
    return response.data;
  } catch (e) {
    dispatch(setUserAcceptanceFailureAction(e));
    throw new Error(
      getErrorMessage(e, 'Failed to update terms of acceptance.'),
    );
  }
};
