import React, { ChangeEvent, FocusEvent, useState } from 'react';
import './input.scss';
import clsx from 'clsx';
import { HelperText } from '../helper/helperText.component';
import { ICON_SIZES } from '../icon/icon.types';
import { Icon } from '../icon/icon.component';
import { INPUT_TYPES, InputProps } from './input.types';
import { iconKeyMap } from '../icon';

const rootClass = 'input';

export const Input = ({
  name,
  type = INPUT_TYPES.TEXT,
  icon,
  label,
  placeholder,
  description,
  value = '',
  invalid,
  required = false,
  isDisabled = false,
  error,
  onFocus,
  onBlur,
  onChange,
}: InputProps) => {
  const [focused, setFocused] = useState(false);
  const showIcon = icon && iconKeyMap[icon];
  const labelId = `${name}-label`;
  const animated = focused || !!value;
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  const handleFocus = (e: FocusEvent<HTMLElement>) => {
    setFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: FocusEvent<HTMLElement>) => {
    setFocused(false);
    onBlur?.(e);
  };

  return (
    <div
      className={clsx(
        rootClass,
        invalid && `${rootClass}--invalid`,
        isDisabled && `${rootClass}--disabled`,
        focused && `${rootClass}--is-focused`,
      )}
    >
      {label && (
        <label
          id={labelId}
          htmlFor={name}
          className={clsx(
            `${rootClass}__label`,
            animated && `${rootClass}__label--is-animated`,
            required && `${rootClass}__label--required`,
            showIcon && `${rootClass}__label--has-icon`,
          )}
        >
          {label}
        </label>
      )}
      <div className={clsx(`${rootClass}__input_container`)}>
        {icon && iconKeyMap[icon] && (
          <Icon
            iconKey={icon}
            size={ICON_SIZES.LARGE}
            className={`${rootClass}__icon`}
          />
        )}
        <input
          id={name}
          // when inputs don't have a label don't add aria-labelledby
          // use placeholder as aria-label instead
          aria-labelledby={label ? labelId : undefined}
          aria-label={label ? undefined : placeholder}
          aria-invalid={invalid}
          aria-required={Boolean(required)}
          className={`${rootClass}__input-element`}
          placeholder={placeholder}
          disabled={isDisabled}
          type={type}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>

      {/* REQ: Override the 'required' text with error message for invalid inputs */}
      {required && typeof required === 'string' && !invalid && (
        <HelperText id={`${name}-required`} description={required} />
      )}

      {invalid && error?.message && (
        <HelperText
          id={`${name}-error`}
          className={`${rootClass}__error-message`}
          description={error.message}
        />
      )}

      {description && (
        <HelperText
          id={`${name}-help-text`}
          className={`${rootClass}__help-text`}
          description={description}
        />
      )}
    </div>
  );
};
