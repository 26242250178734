export enum GALLERY_EDIT_TYPES {
  START = 'GALLERY_EDIT/START',
  SUCCESS = 'GALLERY_EDIT/SUCCESS',
  FAILURE = 'GALLERY_EDIT/FAILURE',
}

export interface GalleryEditStartAction {
  type: GALLERY_EDIT_TYPES.START;
  payload: {};
}

export interface GalleryEditSuccessAction {
  type: GALLERY_EDIT_TYPES.SUCCESS;
  payload: {};
}

export interface GalleryEditFailureAction {
  type: GALLERY_EDIT_TYPES.FAILURE;
  payload: Error;
}

export type GalleryEditActions =
  | GalleryEditStartAction
  | GalleryEditSuccessAction
  | GalleryEditFailureAction;
