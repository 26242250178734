import React, { FunctionComponent, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useForm } from 'react-hook-form';
import './terms_of_service.scss';
import { Modal, ModalButtons } from '../../blocks/modal';
import { ConnectedModalButton as ModalButton } from '../../blocks/connected_modal';
import { Button, BUTTON_VARIANT } from '../../blocks/button';
import { Checkbox } from '../../blocks/checkbox';
import { Header } from '../../blocks/full_screen_modal/header.component';
import { useToggle } from '../../hooks/useToggle';
import { logout } from '../../../http/logout.thunk';
import { Link } from '../../blocks/link/link.component';
import { LINK_VARIANTS } from '../../blocks/link/link.types';
import { TermsAcceptanceUpdateResponse } from '../../../http/tos-acceptance-set.thunks';
import { withController } from '../../hooks/withController';
import { CheckboxProps } from '../../blocks/checkbox/checkbox.types';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';
import { LocalhoodLogoBlack } from '../../../react/blocks/icon/icons/localhoodLogoBlack.component';
import { useToast } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../blocks/toast';

export interface TermsOfServiceModalProps extends LocalizeContextProps {
  onAcceptTOS: () => Promise<TermsAcceptanceUpdateResponse>;
}

export const TermsOfServiceModalComponent: FunctionComponent<TermsOfServiceModalProps> = ({
  translate,
  onAcceptTOS,
}) => {
  const getTranslation = useTranslateStrings(translate);

  const { toggleState, SetStateToggle } = useToggle(false);
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
  });
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const tosTranslateRoot = 'termsOfService.modal';

  const acceptTerms = handleSubmit(async () => {
    setLoading(true);
    try {
      await onAcceptTOS();
    } catch (err) {
      showToast({
        message: `${getTranslation(`${tosTranslateRoot}.errors.500snackbar`)}`,
        type: TOAST_TYPES.ERROR,
      });
    } finally {
      setLoading(false);
    }
  });
  const ControlledCheckbox = withController<boolean, CheckboxProps>(Checkbox);

  return (
    <Modal open={true} fullScreen={true}>
      <Header className="terms_of_service__modal-header">
        <div className="terms_of_service__modal-header-content">
          <LocalhoodLogoBlack className="terms_of_service__modal-header-logo" />
          <ModalButton variant={BUTTON_VARIANT.GHOST} onClick={logout}>
            {translate(`${tosTranslateRoot}.buttons.logout`)}
          </ModalButton>
        </div>
      </Header>
      <div className="terms_of_service__modal-container">
        <div className="terms_of_service__modal-content">
          <h2 className="terms_of_service__modal-title">
            {translate(`${tosTranslateRoot}.title`)}
          </h2>
          <div className="terms_of_service__modal-body">
            <p>{translate(`${tosTranslateRoot}.description.line1`)}</p>
            <p>
              {translate(`${tosTranslateRoot}.description.line2`)}
              <Link
                href="https://localhood.com/terms"
                target="_blank"
                linkContent={translate(
                  `${tosTranslateRoot}.description.urlLink`,
                )}
                variant={LINK_VARIANTS.PRIMARY}
              />
            </p>
            <p>{translate(`${tosTranslateRoot}.description.line3`)}</p>
            <ul className="terms_of_service__modal-terms">
              <li>{translate(`${tosTranslateRoot}.terms.1`)}</li>
              <li>{translate(`${tosTranslateRoot}.terms.2`)}</li>
              <li>{translate(`${tosTranslateRoot}.terms.3`)}</li>
            </ul>
            <Link
              className="terms_of_service__modal-read-terms-link"
              variant={LINK_VARIANTS.PRIMARY}
              href="https://localhood.com/terms"
              target="_blank"
              linkContent={translate(`${tosTranslateRoot}.links.readFullTerms`)}
            />
            <ControlledCheckbox
              control={control}
              className="terms_of_service__modal-checkbox"
              id="terms-of-service-modal__agree"
              name="terms-of-service-modal_agree"
              isChecked={toggleState}
              onCheckboxClick={SetStateToggle}
              label={getTranslation(`${tosTranslateRoot}.checkbox.agree`)}
              validationRules={{
                required: getTranslation(
                  `${tosTranslateRoot}.errors.mustAgree`,
                ),
              }}
            />
          </div>
          <ModalButtons>
            <Button loading={loading} onClick={acceptTerms}>
              {getTranslation(`${tosTranslateRoot}.buttons.submit`)}
            </Button>
          </ModalButtons>
        </div>
      </div>
    </Modal>
  );
};

export const TermsOfServiceModal = withLocalize(TermsOfServiceModalComponent);
