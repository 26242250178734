import { ThunkDispatch } from 'redux-thunk';
import axios from './axios_config';
import { getErrorMessage } from '../utils';
import { urlBuilder } from './util/url_builder';
import { AppState, AppActions } from '../types';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import {
  exportsPrepareFailureAction,
  exportsPrepareStartAction,
  exportsPrepareSuccessAction,
} from '../redux/exports/exports.actions';
import { selectCurrentNetworkId } from '../redux/user/user.selectors';

export const getStoryExportId = (
  storyId: string,
  includePageOverlay: boolean,
  includeLocation: boolean,
  includeVisualCredit: boolean,
  isChallengeStory: boolean,
  source: string,
) => async (
  dispatch: ThunkDispatch<AppState, {}, AppActions>,
  getState: () => AppState,
): Promise<string> => {
  const state = getState();
  const networkId = selectCurrentNetworkId(state);

  if (!storyId) {
    throw new Error('[FAILED] Story download due to missing story ID.');
  }

  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.STORY_EXPORT,
    params: {
      networkId,
      uuid: storyId,
    },
  });

  dispatch(exportsPrepareStartAction(storyId));
  try {
    const { data: exportId } = await axios.post<string>(url, {
      includePageOverlay,
      includeLocation,
      includeVisualCredit,
      isChallengeStory,
      source,
    });
    dispatch(exportsPrepareSuccessAction(exportId));
    return exportId;
  } catch (e) {
    dispatch(exportsPrepareFailureAction());
    const errorMessage = getErrorMessage(e, 'Failed to get export id.');
    throw new Error(errorMessage);
  }
};
