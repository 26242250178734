import React from 'react';
import CrowdriffStoryGallery from 'crowdriff-story-network-gallery-react';
import { useSelector } from 'react-redux';
import { getNetworkSettings } from '../../../http/network_settings.thunks';
import { NETWORK_SETTINGS } from '../../../redux/network/network.types';
import { LoadingGif } from '../../blocks/LoadingGif/LoadingGif.component';
import { LoadStateData } from '../../blocks/load_data/load_state_data.component';
import { selectNetworkSettings } from '../../../redux/network/network.selectors';
import './network_gallery.scss';

export const NetworkGallery = () => {
  const settings = useSelector(selectNetworkSettings);
  return (
    <LoadStateData
      actionTypes={NETWORK_SETTINGS}
      loading={<LoadingGif size="large" />}
      failure={<div />}
      thunk={getNetworkSettings}
    >
      <div className="network-gallery">
        {settings?.gallery_id && (
          <CrowdriffStoryGallery galleryId={settings?.gallery_id} />
        )}
      </div>
    </LoadStateData>
  );
};
