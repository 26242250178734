import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Camera = function Camera(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'camera__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1332.3 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M998.9,167.6L915.6,0H416.7l-83.3,167.6H0V1000h1332.3V167.6H998.9z M666.1,833.6c-138,0-249.8-111.8-249.8-249.8S528.2,334,666.1,334s249.8,111.8,249.8,249.8S804.1,833.6,666.1,833.6z' // eslint-disable-line max-len
    })
  );
};

Camera.propTypes = propTypes;

export default Camera;