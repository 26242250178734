import React from 'react';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { connect } from 'react-redux';
import { AppState, AppActions, AppThunkDispatch } from '../../../types';
import { STATUS_TYPES, AsyncActionTypes } from './load_data.types';
import Err from '../../../types/err';
import { selectIsLocalhoodCreator } from '../../../redux/user/user.selectors';
import { isInstanceOfObjectError } from '../../../types/typeguards';

export interface DispatchProps {
  dispatch: ThunkDispatch<AppState, {}, AppActions>;
}

export interface OwnProps {
  actionTypes: AsyncActionTypes;
  thunk: ThunkAction<Promise<unknown>, AppState, {}, AppActions>;
  loading: React.ReactElement;
  failure?: React.ReactElement;
  children: React.ReactNode;
}

interface ConnectProps {
  isLocalhoodUser: boolean;
}

export type LoadStateDataProps = DispatchProps & OwnProps & ConnectProps;

export interface LoadStateDataState {
  status: STATUS_TYPES;
  message?: string;
}

class LoadStateDataComponent extends React.PureComponent<
  LoadStateDataProps,
  LoadStateDataState
> {
  isComponentMounted: boolean;

  constructor(props: LoadStateDataProps) {
    super(props);
    this.state = {
      status: STATUS_TYPES.LOADING,
    };
    this.isComponentMounted = false;
  }

  async componentDidMount(): Promise<void> {
    const { actionTypes, dispatch, thunk } = this.props;
    this.isComponentMounted = true;

    dispatch({
      type: actionTypes.START,
      payload: {},
    } as AppActions);

    try {
      const p = await dispatch(thunk);
      dispatch({
        type: actionTypes.SUCCESS,
        payload: p,
      } as AppActions);

      if (!this.isComponentMounted) {
        return;
      }

      this.setState({
        status: STATUS_TYPES.SUCCESS,
      });
    } catch (err) {
      let payload = err;
      if (err instanceof Err) {
        payload = err?.friendly ?? err.message;
      }

      if (isInstanceOfObjectError(err) && err.payload) {
        // why not `err instanceof ReduxError` here like above?
        // see: https://github.com/Microsoft/TypeScript/issues/22585
        payload = err.payload;
      }
      dispatch({
        type: actionTypes.FAILURE,
        payload,
      } as AppActions);

      if (!this.isComponentMounted) {
        return;
      }

      this.setState({
        status: STATUS_TYPES.FAILURE,
      });
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  render(): React.ReactElement {
    const { loading, failure, children, isLocalhoodUser } = this.props;
    const { status } = this.state;
    return (
      <React.Fragment>
        {status === STATUS_TYPES.LOADING && loading}
        {/* REQ: Localhood users shouldn't have access to SNET-APP */}
        {status === STATUS_TYPES.SUCCESS &&
          (isLocalhoodUser
            ? window.location.replace(`${process.env.STORY_CREATOR_URL}`)
            : children)}
        {status === STATUS_TYPES.FAILURE && failure}
      </React.Fragment>
    );
  }
}

export const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  dispatch,
});

const mapStateToProps = (state: AppState) => {
  return { isLocalhoodUser: selectIsLocalhoodCreator(state) };
};

export const LoadStateData = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoadStateDataComponent);
