import axios from './axios_config';
import {
  galleryUndeleteFailed,
  galleryUndeleteStarted,
  galleryUndeleteSucceeded,
} from '../redux/galleries/galleries.actions';
import {
  selectCurrentNetworkId,
  selectCurrentPublisherId,
} from '../redux/user/user.selectors';
import { CRThunk, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { getGalleries } from './galleries_list.thunks';
import { GALLERIES_LOAD_TYPES } from '../react/pages/galleries_list/galleries_list.types';
import { getErrorMessage } from '../utils';

export const undeleteGallery: CRThunk<string, void> = (galleryId) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const networkId = selectCurrentNetworkId(state);
  const publisherId = selectCurrentPublisherId(state);

  if (!galleryId) {
    throw new Error('Failed to undelete gallery, no gallery id provided');
  }

  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.GALLERIES_UNDELETE,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
      galleryId,
    },
  });

  try {
    dispatch(galleryUndeleteStarted);
    await axios.patch(url);
    const galleries = await dispatch(getGalleries);
    dispatch({
      type: GALLERIES_LOAD_TYPES.SUCCESS,
      payload: galleries,
    });
    dispatch(galleryUndeleteSucceeded(galleryId));
  } catch (e) {
    dispatch(
      galleryUndeleteFailed(getErrorMessage(e, 'Gallery undelete failed.')),
    );
  }
};
