import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var AngleDown = function AngleDown(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'angledown__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M990,314.6L523.1,781.5c-6,6-15,10-23.1,10c-8,0-17-4-23-10L10,314.6c-6-6-10-15-10-23c0-8,4-17,10-23.1l50.1-50.1c6-6,14.1-10,23.1-10c8,0,17,4,23,10L500,612.2l393.8-393.8c6-6,15-10,23.1-10c8,0,17,4,23,10l50.1,50.1c6,6,10,15,10,23.1C1000,299.6,996,308.6,990,314.6z' // eslint-disable-line max-len
    })
  );
};

AngleDown.propTypes = propTypes;

export default AngleDown;