import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Fullscreen = function Fullscreen(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Fullscreen__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M485,619.1L268.9,835.3l93.8,93.8c7.8,7.8,12.4,18.2,12.4,29.3c0,22.8-18.9,41.7-41.7,41.7H41.7\t\t\tC18.9,1000,0,981.1,0,958.3V666.7C0,643.9,18.9,625,41.7,625c11.1,0,21.5,4.6,29.3,12.4l93.8,93.8L380.9,515\t\t\tc3.9-3.9,9.8-6.5,15-6.5s11.1,2.6,15,6.5l74.2,74.2c3.9,3.9,6.5,9.8,6.5,15C491.5,609.4,488.9,615.2,485,619.1z M1000,333.3\t\t\tc0,22.8-18.9,41.7-41.7,41.7c-11.1,0-21.5-4.6-29.3-12.4l-93.7-93.8L619.1,485c-3.9,3.9-9.8,6.5-15,6.5c-5.2,0-11.1-2.6-15-6.5\t\t\tL515,410.8c-3.9-3.9-6.5-9.8-6.5-15c0-5.2,2.6-11.1,6.5-15l216.1-216.1L637.4,71c-7.8-7.8-12.4-18.2-12.4-29.3\t\tC625,18.9,643.9,0,666.7,0h291.7c22.8,0,41.7,18.9,41.7,41.7V333.3z' // eslint-disable-line max-len
    })
  );
};

Fullscreen.propTypes = propTypes;

export default Fullscreen;