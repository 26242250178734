import React from 'react';
import { IconProps } from '../icon.types';

export const ExclamationCircleFill = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 18.4736C14.6318 18.4736 18.4668 14.6304 18.4668 10.0068C18.4668 5.375 14.6235 1.54004 9.9917 1.54004C5.36816 1.54004 1.5332 5.375 1.5332 10.0068C1.5332 14.6304 5.37646 18.4736 10 18.4736ZM10 11.5508C9.56836 11.5508 9.32764 11.3101 9.31934 10.8701L9.21143 6.34619C9.20312 5.90625 9.52686 5.59082 9.9917 5.59082C10.4482 5.59082 10.7886 5.91455 10.7803 6.35449L10.6641 10.8701C10.6558 11.3184 10.415 11.5508 10 11.5508ZM10 14.3315C9.50195 14.3315 9.07031 13.9331 9.07031 13.4434C9.07031 12.9536 9.49365 12.5469 10 12.5469C10.5063 12.5469 10.9297 12.9453 10.9297 13.4434C10.9297 13.9414 10.498 14.3315 10 14.3315Z" />
    </svg>
  );
};
