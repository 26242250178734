import { TaxonomyTag } from '../../react/features/taxonomy_section/taxonomy.types';

export enum NETWORK_TAG_TYPES {
  CAMPAIGN = 'campaign',
  PERSONA = 'persona',
}

export enum NETWORK_TAG_ASYNC_TYPES {
  START = 'NETWORK_TAGS/START',
  SUCCESS = 'NETWORK_TAGS/SUCCESS',
  FAILURE = 'NETWORK_TAGS/FAILURE',
}

export interface NetworkTag extends TaxonomyTag {
  type: NETWORK_TAG_TYPES;
  network_id: string;
}
export interface NetworkTagState {
  data: NetworkTag[];
  ids: string[];
}

export interface GetNetworkTagsStart {
  type: NETWORK_TAG_ASYNC_TYPES.START;
  payload: {};
}
export interface GetNetworkTagsSuccess {
  type: NETWORK_TAG_ASYNC_TYPES.SUCCESS;
  payload: NetworkTag[];
}
export interface GetNetworkTagsFailure {
  type: NETWORK_TAG_ASYNC_TYPES.FAILURE;
  payload: string;
}

export type NetworkTagActions =
  | GetNetworkTagsStart
  | GetNetworkTagsSuccess
  | GetNetworkTagsFailure;
