import React from 'react';
import { IconProps } from '../icon.types';

export const Photo = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 22 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.28027 14.5723H4.77441V15.8423C4.77441 17.5605 5.6377 18.4155 7.38086 18.4155H18.7197C20.4463 18.4155 21.3262 17.5522 21.3262 15.8423V8.01465C21.3262 6.30469 20.4463 5.44141 18.7197 5.44141H17.1343V4.17139C17.1343 2.46143 16.2544 1.59814 14.5278 1.59814H3.28027C1.53711 1.59814 0.673828 2.46143 0.673828 4.17139V11.999C0.673828 13.7173 1.53711 14.5723 3.28027 14.5723ZM3.29688 13.2358C2.4668 13.2358 2.01025 12.7959 2.01025 11.9326V4.24609C2.01025 3.38281 2.4668 2.93457 3.29688 2.93457H14.5029C15.3247 2.93457 15.7979 3.38281 15.7979 4.24609V5.44141H7.38086C5.6377 5.44141 4.77441 6.30469 4.77441 8.01465V13.2358H3.29688ZM6.11084 8.08936C6.11084 7.22607 6.56738 6.77783 7.39746 6.77783H18.6948C19.5166 6.77783 19.9897 7.22607 19.9897 8.08936V14.5557L16.8853 11.6255C16.5615 11.3267 16.1631 11.1772 15.7646 11.1772C15.3496 11.1772 14.9844 11.3101 14.644 11.6172L11.2905 14.5889L9.9541 13.3853C9.63867 13.0947 9.29834 12.9619 8.94141 12.9619C8.61768 12.9619 8.31055 13.0947 7.98682 13.3687L6.11084 15.0039V8.08936ZM10.6929 11.8081C11.6558 11.8081 12.4443 11.0029 12.4443 10.0317C12.4443 9.07715 11.6558 8.27197 10.6929 8.27197C9.72168 8.27197 8.93311 9.07715 8.93311 10.0317C8.93311 11.0029 9.72168 11.8081 10.6929 11.8081Z" />
    </svg>
  );
};
