/**
 * Scrolls the given element to the top in a repeatable way
 * @param element The DOM element to affect the scroll of
 */
import { Method, AxiosResponse, AxiosError } from 'axios';
import axios from '../app/http/axios_config';
import {
  isGenericObject,
  isInstanceOfAxiosError,
  isInstanceOfError,
} from './types/typeguards';

export const scrollToTopGenerator = (element?: HTMLElement | Window) => {
  return () => element?.scrollTo({ top: 0, behavior: 'smooth' });
};

/**
 * Parses an axios error object to get back the error message.
 * @param error The axios error message
 * @param fallback Fallback text in case the message is not found
 *
 * @returns message A human-readable message describing the error
 */
export const getAxiosErrorMessage = (
  error: unknown,
  fallback: string,
): string => {
  if (isInstanceOfAxiosError(error)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = error?.response?.data as any;
    return data?.error?.message || fallback;
  }
  return fallback;
};

export const getErrorMessage = (error: unknown, fallback?: string) => {
  const axiosErrorMessage = getAxiosErrorMessage(error, '');
  if (axiosErrorMessage) {
    return axiosErrorMessage;
  }
  if (isInstanceOfError(error)) {
    return error.message;
  }
  return fallback ?? '';
};

export const getLocationState = (state: unknown, field: string) => {
  if (isGenericObject(state)) {
    return state[field];
  }
  return '';
};

export const urlValidationRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

export const specialCharacterValidationRegex = /^[-a-zA-Z0-9_.,'[\]() ]*$/g;

interface GetAxiosPromiseProps<T> {
  url: string;
  method: Method;
  data?: unknown;
  onSuccess?: (response: AxiosResponse<T>) => unknown;
  onReject?: (e: AxiosError) => unknown;
}
/**
 * Returns an axios request as promise
 * @param url The request url
 * @param method HTTP method for the request
 * @param onSuccess Success callback
 * @param onReject Error callback
 *
 * @returns message A human-readable message describing the error
 */
export const getAxiosRequestPromise = <T>({
  url,
  method,
  data,
  onSuccess,
  onReject,
}: GetAxiosPromiseProps<T>) => {
  return axios
    .request({
      url,
      method,
      data,
    })
    .then(onSuccess)
    .catch(onReject);
};
