import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PersonCircle = function PersonCircle(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'personCircle__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      null,
      React.createElement('path', {
        d: 'M499.8,44.7c-250.9,0.2-454.9,204-455.1,455.1c-0.2,251.3,204.4,455.9,455.5,455.5c251.5-0.4,455.5-204.8,455.1-455.9C954.9,248.2,750.7,44.5,499.8,44.7L499.8,44.7z M1000,499.6c1.6,274.5-222.2,499.2-498.4,500.4C225.3,1001.2,1,778.2,0,501C-1,225.3,222,1.2,498.8,0C774.9-1.2,998.4,221.6,1000,499.6L1000,499.6z M682.2,741.3H317.5c10.7-72.8,37.5-135.7,100.2-178.3c35.9-24.4,75.6-32,118.1-21.3c35.3,9,63.8,28.9,87.1,56.4C657.8,639,675.7,687.4,682.2,741.3L682.2,741.3z M618.7,379.7c-0.2,64.8-53.9,118.3-118.1,118.1c-66-0.2-119.7-53.7-119.1-119.3c0.4-65.8,53.9-118.3,120.3-118.1C565.8,260.6,618.9,314.7,618.7,379.7z' // eslint-disable-line max-len
      })
    )
  );
};

PersonCircle.propTypes = propTypes;

export default PersonCircle;