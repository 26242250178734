import { uniqueId } from 'lodash';
import React from 'react';
import { LocalizedElement, LocalizedElementMap } from 'react-localize-redux';
import './text.scss';
import { TEXT_VARIANTS, TEXT_ELEMENTS } from './text.types';

export interface TextProps {
  element?: TEXT_ELEMENTS;
  variant?: TEXT_VARIANTS;
  className?: string;
  children?:
    | string
    | number
    | JSX.Element[]
    | LocalizedElement
    | LocalizedElementMap;
}

const getTextElement = (variant: TEXT_VARIANTS) => {
  switch (variant) {
    case TEXT_VARIANTS.H1:
      return TEXT_ELEMENTS.H1;
    case TEXT_VARIANTS.H2:
      return TEXT_ELEMENTS.H2;
    case TEXT_VARIANTS.H3:
      return TEXT_ELEMENTS.H3;
    case TEXT_VARIANTS.H4:
      return TEXT_ELEMENTS.H4;
    default:
      return TEXT_ELEMENTS.P;
  }
};

export const Text = (props: TextProps) => {
  const {
    variant = TEXT_VARIANTS.B1,
    element,
    className = '',
    children,
  } = props;
  const Component = element || getTextElement(variant);
  return (
    <Component
      id={uniqueId(`text-${variant}`)}
      className={`text-${variant} ${className}`}
    >
      {children}
    </Component>
  );
};
