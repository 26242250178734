{
  "navigation": {
    "admin": "Admin"
  },
  "form": {
    "addPartner": "Add Partner",
    "onboardingCSV": "Onboarding CSV",
    "downloadCSVTemplate": "Download CSV Template",
    "supportedFormats": "Supported formats: .csv",
    "button": {
      "uploadCSV": "Upload CSV",
      "createPartner": "Create Partner"
    }
  }
}
