import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.number,
  iconStyle: PropTypes.string,
  fillColor: PropTypes.string
};
/* eslint-disable max-len */
var Wayfinding = function Wayfinding(_ref) {
  var size = _ref.size,
      iconStyle = _ref.iconStyle,
      fillColor = _ref.fillColor;

  return React.createElement(
    'svg',
    {
      version: '1.1',
      xmlns: 'http://www.w3.org/2000/svg',
      x: '0px',
      y: '0px',
      viewBox: '0 0 124 124',
      style: { enableBackground: 'new 0 0 124 124' },
      height: size,
      fill: iconStyle !== 'outline-white' ? '#2A2F35' : '#FFF'
    },
    React.createElement(
      'g',
      null,
      iconStyle === 'fill' ? React.createElement(
        'g',
        { fill: fillColor },
        React.createElement('path', {
          className: 'st2',
          d: 'M33.3,91.8L30.1,84c-0.2-0.5-0.2-1,0-1.5l3.2-8.2c0.3-0.8,1-1.3,1.9-1.3h49.3c0.8,0,1.5,0.5,1.9,1.2l3.2,7.8c0.2,0.5,0.2,1,0,1.5l-3.2,8.2c-0.3,0.8-1,1.3-1.9,1.3H35.2C34.3,93,33.6,92.5,33.3,91.8z'
        }),
        React.createElement('path', {
          className: 'st2',
          d: 'M19.3,31.3l-6.2-7c-0.7-0.8-0.7-1.9,0-2.7l6.2-7c0.4-0.4,0.9-0.7,1.5-0.7h69.1c1.1,0,2,0.9,2,2v14c0,1.1-0.9,2-2,2H20.8C20.2,32,19.7,31.8,19.3,31.3z'
        })
      ) : null,
      React.createElement(
        'g',
        null,
        React.createElement('path', { className: 'st3', d: 'M48.1,58.6l-10,0.9c-0.4,0-0.7,0.4-0.7,0.8c0,0.4,0.4,0.7,0.7,0.7c0,0,0,0,0.1,0l10-0.9c0.4,0,0.7-0.4,0.7-0.8S48.5,58.5,48.1,58.6z' }),
        React.createElement('path', {
          className: 'st3',
          d: 'M32.1,60l-19.6,1.7c-0.4,0-0.7,0.4-0.7,0.8c0,0.4,0.4,0.7,0.7,0.7c0,0,0,0,0.1,0l19.6-1.7c0.4,0,0.7-0.4,0.7-0.8C32.9,60.2,32.6,59.9,32.1,60z'
        }),
        React.createElement('path', {
          className: 'st3',
          d: 'M42.8,80.8h8c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-8c-0.4,0-0.8,0.3-0.8,0.8S42.4,80.8,42.8,80.8z'
        }),
        React.createElement('path', {
          className: 'st3',
          d: 'M56.8,80.8h16c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-16c-0.4,0-0.8,0.3-0.8,0.8S56.4,80.8,56.8,80.8z'
        }),
        React.createElement('path', {
          className: 'st3',
          d: 'M76.8,86.8h4c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-4c-0.4,0-0.8,0.3-0.8,0.8S76.4,86.8,76.8,86.8z'
        }),
        React.createElement('path', {
          className: 'st3',
          d: 'M70.8,85.3h-26c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h26c0.4,0,0.8-0.3,0.8-0.8S71.2,85.3,70.8,85.3z'
        }),
        React.createElement('path', {
          className: 'st3',
          d: 'M85.9,25.3h-4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h4c0.4,0,0.8-0.3,0.8-0.8S86.3,25.3,85.9,25.3z'
        }),
        React.createElement('path', {
          className: 'st3',
          d: 'M87.9,19.3h-14c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h14c0.4,0,0.8-0.3,0.8-0.8S88.3,19.3,87.9,19.3z'
        }),
        React.createElement('path', {
          className: 'st3',
          d: 'M121.6,45.4l-10.7-8.6c-0.6-0.4-1.3-0.7-2-0.6l-42.3,3.7v-7.1h25.4c1.5,0,2.8-1.2,2.8-2.8V16c0-1.5-1.2-2.8-2.8-2.8H66.6V10c0-1.5-1.2-2.8-2.8-2.8h-4c-1.5,0-2.8,1.2-2.8,2.8v3.3H22.8c-0.8,0-1.5,0.3-2.1,0.9l-6.2,7c-0.9,1-0.9,2.6,0,3.7l6.2,7l0,0c0.5,0.6,1.3,0.9,2.1,0.9h34.2v8L3.9,45.4C3,45.5,2.2,46,1.8,46.7c-0.4,0.8-0.5,1.7-0.1,2.5l3.6,7.9c0.1,0.3,0.1,0.5,0.1,0.8l-2.1,8.4c-0.2,0.9,0,1.8,0.6,2.5c0.5,0.6,1.3,1,2.1,1c0.1,0,0.2,0,0.2,0l51-4.5v7H37.2C36,72.3,35,73,34.6,74l-3.2,8.2c-0.3,0.7-0.3,1.4,0,2l3.2,7.8v0c0.4,1,1.4,1.7,2.5,1.7h19.9V114c0,1.5,1.2,2.8,2.8,2.8h4c1.5,0,2.8-1.2,2.8-2.8V93.8h19.9c1.1,0,2.1-0.7,2.6-1.7l3.2-8.2c0.3-0.7,0.3-1.4,0-2L89,74c-0.4-1-1.4-1.7-2.5-1.7H66.6v-7.8l44.5-3.9c0.7-0.1,1.4-0.4,1.8-0.9l9.1-10.3c0.5-0.6,0.7-1.3,0.7-2.1C122.5,46.5,122.2,45.9,121.6,45.4z M58.6,10c0-0.7,0.6-1.2,1.2-1.2h4c0.7,0,1.2,0.6,1.2,1.2v3.3h-6.5V10z M21.9,30.8L21.9,30.8l-6.2-7c-0.4-0.5-0.4-1.2,0-1.7l6.2-7c0.2-0.3,0.6-0.4,0.9-0.4h69.1c0.7,0,1.2,0.6,1.2,1.2v14c0,0.7-0.6,1.2-1.2,1.2H22.8C22.5,31.3,22.1,31.1,21.9,30.8z M58.6,32.8h6.5V40l-6.5,0.6V32.8z M65.1,114c0,0.7-0.6,1.2-1.2,1.2h-4c-0.7,0-1.2-0.6-1.2-1.2V93.8h6.5V114z M87.6,74.6l3.2,7.8c0.1,0.3,0.1,0.6,0,0.9l-3.2,8.2c-0.2,0.5-0.6,0.8-1.2,0.8H37.2c-0.5,0-1-0.3-1.2-0.8l-3.2-7.8c-0.1-0.3-0.1-0.6,0-0.9l3.2-8.2c0.2-0.5,0.6-0.8,1.2-0.8h49.3C87,73.8,87.4,74.1,87.6,74.6z M65.1,72.3h-6.5v-7.1l6.5-0.6V72.3z M120.8,48.4l-9.1,10.3c-0.2,0.2-0.5,0.4-0.8,0.4l-105,9.2c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.7-0.3-1.1l2.1-8.4c0.2-0.6,0.1-1.2-0.2-1.8L3,48.6c-0.2-0.4-0.1-0.8,0.1-1.1c0.2-0.4,0.6-0.6,1-0.6l105-9.2c0.3,0,0.6,0.1,0.9,0.3l10.7,8.6c0.3,0.2,0.4,0.5,0.5,0.9C121.1,47.8,121,48.1,120.8,48.4z'
        })
      )
    )
  );
};

Wayfinding.propTypes = propTypes;

export default Wayfinding;