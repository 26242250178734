import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Screen = function Screen(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      height: size,
      viewBox: '0 0 32 32',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg'
    },
    React.createElement('path', {
      d: 'M9.33341 28H22.6667M2.66675 21.8667V4.8C2.66675 4.35817 3.02492 4 3.46675 4H28.5334C28.9753 4 29.3334 4.35817 29.3334 4.8V21.8667C29.3334 22.3085 28.9753 22.6667 28.5334 22.6667H3.46675C3.02492 22.6667 2.66675 22.3085 2.66675 21.8667Z',
      stroke: color,
      'stroke-width': '2',
      'stroke-linecap': 'round'
    })
  );
};

Screen.propTypes = propTypes;

export default Screen;