import _extends from 'babel-runtime/helpers/extends';
var FilterDropdownStyles = function FilterDropdownStyles(props) {
  var noBorder = props.noBorder,
      isBlue = props.isBlue,
      isInline = props.isInline,
      valueKey = props.valueKey,
      color = props.color,
      isGroupedFilterDropdown = props.isGroupedFilterDropdown,
      selectedOptions = props.selectedOptions;

  var blue = color || '#007BA8';
  var offWhite = '#f5f7f9';
  var grey = '#dfe0e1';
  var darkGrey = isGroupedFilterDropdown ? '#2A2F35' : '#333';
  var blueOrGrey = isBlue ? blue : grey;
  return {
    control: function control(provided, state) {
      return _extends({}, provided, {
        color: darkGrey,
        fontWeight: 300,
        fontSize: '16px',
        border: noBorder ? 'none' : null,
        borderColor: state.menuIsOpen ? blueOrGrey : grey,
        boxShadow: 'none',
        '&:hover': { borderColor: state.menuIsOpen ? blueOrGrey : grey },
        cursor: 'text'
      });
    },
    menu: function menu(provided) {
      return _extends({}, provided, {
        border: '1px solid',
        borderColor: blueOrGrey,
        boxShadow: 'none',
        '&:hover': { border: '1px solid ' + blueOrGrey },
        zIndex: 2,
        marginTop: '4px'
      });
    },
    indicatorSeparator: function indicatorSeparator(provided) {
      return _extends({}, provided, {
        display: noBorder ? 'none' : 'initial'
      });
    },
    dropdownIndicator: function dropdownIndicator(provided, state) {
      return _extends({}, provided, {
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        color: blueOrGrey,
        cursor: 'pointer'
      });
    },
    clearIndicator: function clearIndicator(provided) {
      return _extends({}, provided, {
        cursor: 'pointer'
      });
    },
    option: function option(provided, state) {
      var hasValue = state.selectProps.value !== '';
      var SelectPropsValue = state.selectProps.value.value;

      var baseOptionStyles = _extends({}, provided, {
        background: state.isFocused || state.isSelected || hasValue && SelectPropsValue === state.data[valueKey] ? offWhite : null,
        color: (state.isFocused || state.isSelected || hasValue && SelectPropsValue === state.data[valueKey]) && !state.selectProps.isGroupedFilterDropdown ? blue : darkGrey,
        fontWeight: 300,
        fontSize: '16px',
        '&:hover': { color: blue },
        '&:active': {
          background: state.isFocused || state.isSelected || hasValue && SelectPropsValue === state.data[valueKey] ? offWhite : null
        },
        cursor: 'pointer'
      });

      if (state.data.disabled) {
        return _extends({}, baseOptionStyles, {
          pointerEvents: 'none'
        });
      }
      if (state.selectProps.isGroupedFilterDropdown) {
        var isSelected = selectedOptions && selectedOptions.includes(state.data.value);
        return _extends({}, baseOptionStyles, {
          '&:hover': {
            color: isSelected && state.isFocused ? darkGrey : blue
          }
        });
      }
      return baseOptionStyles;
    },
    container: function container(provided) {
      return _extends({}, provided, {
        width: isInline && '100%'
      });
    },
    groupHeading: function groupHeading(provided) {
      return _extends({}, provided, {
        color: '#2A2F35',
        fontSize: '16px',
        lineHeight: '20px',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        paddingTop: '4px',
        paddingLeft: '16px',
        marginBottom: 0,
        paddingBottom: '5px'
      });
    },
    noOptionsMessage: function noOptionsMessage(provided) {
      return _extends({}, provided, {
        color: '#2A2F35',
        fontSize: '16px',
        textAlign: 'left',
        marginLeft: '12px'
      });
    }
  };
};
export default FilterDropdownStyles;