import React, { useEffect, useReducer } from 'react';
import { ClickAwayListener, Tooltip as MuiToolTip } from '@material-ui/core';
import clsx from 'clsx';
import './tool_tip.scss';
import {
  OPEN_ACTION_TYPE,
  TOOLTIP_ACTIONS,
  TOOLTIP_PLACEMENT,
  TOOLTIP_VARIANT,
} from './tool_tip.types';
import {
  getExtraButtonProps,
  getExtraTooltipProps,
  openReducer,
} from './tool_tip.utils';

interface TooltipProps {
  placement: TOOLTIP_PLACEMENT;
  content: React.ReactChild | string;
  children: React.ReactNode;
  id: string;
  variant?: TOOLTIP_VARIANT;
  triggerAction?: TOOLTIP_ACTIONS;
  className?: string;
  onOpen?: () => void;
  onClose?: () => void;
  arrow?: boolean;
}

export const Tooltip = ({
  placement,
  content,
  className,
  children,
  id,
  variant = TOOLTIP_VARIANT.TEXT,
  triggerAction = TOOLTIP_ACTIONS.CLICK,
  onOpen,
  onClose,
  arrow = true,
}: TooltipProps) => {
  const [open, openDispatch] = useReducer(openReducer, false);
  const onToggle = (state: boolean) => (state ? onOpen?.() : onClose?.());
  const interactive = triggerAction === TOOLTIP_ACTIONS.HOVER;
  const isVariantGif =
    variant === TOOLTIP_VARIANT.GIF_DESKTOP ||
    variant === TOOLTIP_VARIANT.GIF_MOBILE;

  // If we are handling opening and closing manually, the built-in onOpen and onClose
  // props aren't firing properly, so we handle it here manually.
  useEffect(() => {
    if (interactive) {
      return undefined;
    }
    if (open) {
      return onOpen?.();
    }
    return onClose?.();
  }, [open]);

  const tooltipContent = () => {
    if (isVariantGif && typeof content === 'string') {
      return (
        <div className="tooltip_gif__container">
          <video
            className="tooltip_gif__video"
            autoPlay={true}
            loop={false}
            muted={true}
            playsInline={true}
          >
            <source src={content} type="video/mp4" />
          </video>
        </div>
      );
    }

    if (variant === TOOLTIP_VARIANT.TEXT) {
      return content;
    }
    return '';
  };

  return (
    <ClickAwayListener onClickAway={() => openDispatch(OPEN_ACTION_TYPE.CLOSE)}>
      <MuiToolTip
        {...getExtraTooltipProps(interactive, open, onOpen, onClose)}
        classes={{
          tooltip: clsx({
            'tooltip__tooltip-body': variant === TOOLTIP_VARIANT.TEXT,
            'tooltip__tooltip-gif': isVariantGif,
            'tooltip__tooltip-gif-desktop':
              variant === TOOLTIP_VARIANT.GIF_DESKTOP,
            'tooltip__tooltip-gif-mobile':
              variant === TOOLTIP_VARIANT.GIF_MOBILE,
          }),
          arrow: 'tooltip__arrow',
        }}
        className={className}
        placement={placement}
        title={tooltipContent()}
        disableTouchListener={true}
        disableFocusListener={!interactive}
        interactive={true}
        PopperProps={{ disablePortal: true }}
        id={id}
        arrow={arrow}
      >
        <button
          {...getExtraButtonProps(interactive, onToggle, openDispatch)}
          type="button"
          aria-describedby={id}
          className={clsx(
            { 'tooltip__container--tooltip_open': open },
            'tooltip__container',
          )}
        >
          {children}
        </button>
      </MuiToolTip>
    </ClickAwayListener>
  );
};
