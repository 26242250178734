import _slicedToArray from 'babel-runtime/helpers/slicedToArray';
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

var propDescriptions = {
  children: 'A node specifying the expandable contents of the panel',
  className: 'A string of any class name to be added to the panel',
  label: 'A string specifying the label of the panel',
  iconType: 'A string specifying the icon to display in the label',
  collapseIconType: 'A string specifying which icon to display when the panel is expanded. If this is omitted, the iconType will instead be used when expanded'
};

var propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  iconType: PropTypes.string,
  collapseIconType: PropTypes.string
};

var defaultProps = {
  className: '',
  iconType: 'plus',
  label: 'Label'
};

// css overrides for default material ui accordion styles
var StyledAccordion = withStyles({
  root: {
    backgroundColor: 'transparent'
  }
})(Accordion);

// css overrides for default material ui accordion summary
var StyledAccordionSummary = withStyles({
  root: {
    minHeight: 20,
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 20,
      padding: 0
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0
    }
  }
})(AccordionSummary);

// css overrides for default material ui accordion details
var StyledAccordionDetails = withStyles({
  root: {
    padding: '8px 24px',
    flexDirection: 'column',
    '&.Mui-expanded': {
      padding: '8px 24px'
    }
  }
})(AccordionDetails);

export default function ExpansionPanel(_ref) {
  var children = _ref.children,
      className = _ref.className,
      iconType = _ref.iconType,
      collapseIconType = _ref.collapseIconType,
      label = _ref.label;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      expanded = _React$useState2[0],
      setExpanded = _React$useState2[1];

  var renderIcon = function renderIcon() {
    // Display either the expandIcon or collapseIcon depending on state and which icons are supplied via props
    if (iconType && collapseIconType) {
      return React.createElement(Icon, { type: expanded ? collapseIconType : iconType });
    } else if (iconType && !collapseIconType) {
      return React.createElement(Icon, { type: iconType });
    }
    return null;
  };

  return React.createElement(
    'div',
    { className: classNames('cr-expansionPanel', className) },
    React.createElement(
      StyledAccordion,
      {
        onChange: function onChange() {
          setExpanded(!expanded);
        },
        elevation: 0,
        square: true
      },
      React.createElement(
        StyledAccordionSummary,
        null,
        renderIcon(),
        React.createElement(
          'span',
          { className: 'cr-expansionPanel__label' },
          '' + label
        )
      ),
      React.createElement(
        StyledAccordionDetails,
        { className: 'cr-expansionPanel__content' },
        children
      )
    )
  );
}

ExpansionPanel.propDescriptions = propDescriptions;
ExpansionPanel.propTypes = propTypes;
ExpansionPanel.defaultProps = defaultProps;