import { connect } from 'react-redux';
import { AppState } from '../../../types';
import { GallerySortableGrid } from './gallery_sortable_grid.component';
import {
  selectCurrentPublisherId,
  selectCurrentNetworkId,
} from '../../../redux/user/user.selectors';

const mapStateToProps = (state: AppState) => ({
  currentPublisherId: selectCurrentPublisherId(state),
  currentNetworkId: selectCurrentNetworkId(state),
});

export const GallerySortableGridConnect = connect(mapStateToProps)(
  GallerySortableGrid,
);
