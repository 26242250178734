import React, { useCallback, useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { StoryExportModal } from './story_export_modal';
import { StoryExportProps } from '.';
import {
  DOWNLOAD_ASSETS_BINDING_TYPES,
  DOWNLOAD_LOCATION_TYPES,
  DOWNLOAD_RESPONSE_TYPES,
  EXPORTS_PREPARE_RESPONSE_TYPES,
} from '../../../redux/exports/exports.types';
import { STATUS_CHECK_TIME_INTERVAL } from './story_export.constants';
import { useToast } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES, TOAST_VARIANTS } from '../../blocks/toast';
import { Spinner } from '../../blocks/spinner/spinner.component';
import { ICON_NAMES, ICON_SIZES, Icon } from '../../blocks/icon';
import { DownloadToMobileModal } from './download_to_mobile_modal';
import { useModal } from '../../hooks/use_modal/use_modal';

const statusCheckTimer: { [id: string]: number } = {};

const StoryExportComponent = ({
  translate,
  story,
  isChallengeStory,
  Modal,
  toggle,
  isOpen,
  onConfirm,
  onClose,
  prepareStatus,
  storyId,
  exportId,
  exportType,
  download,
  onExportPrepare,
  onDownloadStart,
  onDownloadReset,
  downloadLocation,
  onUpdateDownloadLocation,
}: StoryExportProps) => {
  const [isExportsPreparing, setIsExportsPreparing] = useState(false);
  const { showToast } = useToast();
  const [QRCodeModal, toggleQRCodeModal] = useModal();

  const showErrorToast = useCallback(
    (key: string) => {
      showToast({
        key,
        type: TOAST_TYPES.ERROR,
        message: `${translate('stories.toast.storyExport.errorTitle')}`,
      });
    },
    [exportId, showToast],
  );

  const showExportToast = useCallback(
    (
      storyUuid: string,
      inProgress: boolean = true,
      pageCount?: number,
      pageCompleted?: number,
      onClose?: () => void,
    ) => {
      showToast({
        key: storyUuid,
        variant: TOAST_VARIANTS.BANNER,
        imgUrl: story.image?.url,
        message: `${
          inProgress
            ? `${translate('stories.toast.storyExport.pendingTitle')}`
            : `${translate('stories.toast.storyExport.successTitle')}`
        } ${story.title}`,
        description: inProgress
          ? `${translate('stories.toast.storyExport.pendingDescription')}`
          : `${translate('stories.toast.storyExport.successDescription')}`,
        autoClose: inProgress ? false : undefined,
        closeText: inProgress
          ? `${translate('stories.toast.storyExport.pendingCancelCta')}`
          : undefined,
        status: inProgress ? (
          <>
            <Spinner />
            {translate('stories.toast.storyExport.pendingStatus', {
              pageCompleted,
              pageCount,
            })}
          </>
        ) : (
          <>
            <Icon
              iconKey={ICON_NAMES.CHECKMARK_CIRCLE_FILLED}
              size={ICON_SIZES.XL}
              color="#008334"
            />
            {translate('stories.toast.storyExport.successStatus')}
          </>
        ),
        onClose,
      });
    },
    [exportId, story, onDownloadReset, showToast],
  );

  const handleClearInterval = (storyUuid: string) => {
    clearInterval(statusCheckTimer[storyUuid]);
    delete statusCheckTimer[storyUuid];
  };

  useEffect(() => {
    if (story.uuid === storyId) {
      switch (prepareStatus) {
        case EXPORTS_PREPARE_RESPONSE_TYPES.START: {
          setIsExportsPreparing(true);
          break;
        }
        case EXPORTS_PREPARE_RESPONSE_TYPES.SUCCESS: {
          toggle();
          setIsExportsPreparing(false);
          downloadLocation === DOWNLOAD_LOCATION_TYPES.CURRENT_DEVICE
            ? onDownloadStart(storyId, exportId, exportType)
            : toggleQRCodeModal();
          break;
        }
        case EXPORTS_PREPARE_RESPONSE_TYPES.FAILURE: {
          toggle();
          showErrorToast(story.uuid);
          setIsExportsPreparing(false);
          break;
        }
        default:
          break;
      }
    }
  }, [prepareStatus, setIsExportsPreparing, onDownloadStart]);

  useEffect(() => {
    if (download[story.uuid]) {
      const {
        exportId,
        storyUuid,
        status,
        pageCount,
        pageCompleted,
        pagesExportUrl,
        storyExportUrl,
      } = download[story.uuid];
      switch (status) {
        case DOWNLOAD_RESPONSE_TYPES.START: {
          handleClearInterval(storyUuid);
          showExportToast(
            storyUuid,
            true,
            pageCount ?? undefined,
            pageCompleted ?? undefined,
            () => {
              handleClearInterval(storyUuid);
              onDownloadReset(storyUuid);
            },
          );
          statusCheckTimer[storyUuid] = window.setInterval(() => {
            onDownloadStart(storyUuid, exportId, exportType);
          }, STATUS_CHECK_TIME_INTERVAL);
          break;
        }
        case DOWNLOAD_RESPONSE_TYPES.SUCCESS: {
          clearInterval(statusCheckTimer[storyUuid]);
          showExportToast(
            storyUuid,
            false,
            pageCount ?? undefined,
            pageCompleted ?? undefined,
          );
          if (exportType === DOWNLOAD_ASSETS_BINDING_TYPES.INDIVIDUAL) {
            pagesExportUrl?.map((url) => url && window.open(url));
          } else {
            storyExportUrl && window.location.assign(storyExportUrl);
          }
          onDownloadReset(storyUuid);
          break;
        }
        case DOWNLOAD_RESPONSE_TYPES.FAILURE: {
          handleClearInterval(storyUuid);
          showErrorToast(storyUuid);
          onDownloadReset(storyUuid);
          break;
        }
        default: {
          handleClearInterval(storyUuid);
          break;
        }
      }
    }
  }, [download]);

  return (
    <>
      {isOpen && (
        <StoryExportModal
          story={story}
          Modal={Modal}
          isChallengeStory={isChallengeStory}
          loading={isExportsPreparing}
          onConfirm={(...props) => {
            onExportPrepare(...props);
            onConfirm?.();
          }}
          onClose={() => {
            onClose?.();
            toggle();
          }}
          downloadLocation={downloadLocation}
          onUpdateDownloadLocation={onUpdateDownloadLocation}
        />
      )}
      <DownloadToMobileModal
        Modal={QRCodeModal}
        onClose={() => {
          onDownloadReset(story.uuid);
          toggleQRCodeModal();
        }}
        exportId={exportId}
      />
    </>
  );
};

export const StoryExport = withLocalize(StoryExportComponent);
