import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Public = function Public(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Layer_1',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      null,
      React.createElement(
        'g',
        null,
        React.createElement('path', {
          d: 'M62.5,500.4V62.5h197.9l125,166.7h552.1v708.3H749.8v62.5H1000V166.7H416.7L291.7,0H0v500.4H62.5z M703,716.5l-221,221l-44.2-44.2l145.5-145.5H62.5v-62.5h520.8L437.9,539.7l44.2-44.2L703,716.5z' // eslint-disable-line max-len
        })
      )
    )
  );
};

Public.propTypes = propTypes;

export default Public;