import { ThunkDispatch } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import axios from './axios_config';
import {
  galleryCopyFailed,
  galleryCopyStarted,
  galleryCopySucceeded,
} from '../redux/galleries/galleries.actions';
import {
  selectCurrentNetworkId,
  selectCurrentPublisherId,
} from '../redux/user/user.selectors';
import { AppState, AppActions } from '../types';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { GALLERIES_LOAD_TYPES } from '../react/pages/galleries_list/galleries_list.types';
import { getGalleries } from './galleries_list.thunks';
import { getErrorMessage } from '../utils';

export interface GalleryCopyResponse {
  gallery_id: string;
}

export const copyGallery = (galleryId: string) => async (
  dispatch: ThunkDispatch<AppState, {}, AppActions>,
  getState: () => AppState,
): Promise<GalleryCopyResponse> => {
  const state = getState();
  const networkId = selectCurrentNetworkId(state);
  const publisherId = selectCurrentPublisherId(state);

  if (!galleryId) {
    throw new Error('Failed to copy gallery, no gallery id provided');
  }

  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.GALLERIES_COPY,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
      galleryId,
    },
  });

  try {
    dispatch(galleryCopyStarted);
    const res: AxiosResponse<GalleryCopyResponse> = await axios.post<
      GalleryCopyResponse
    >(url);
    const galleries = await dispatch(getGalleries);
    dispatch({
      type: GALLERIES_LOAD_TYPES.SUCCESS,
      payload: galleries,
    } as AppActions);
    dispatch(galleryCopySucceeded(galleryId));
    return res.data;
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    dispatch(galleryCopyFailed(errorMessage ?? 'Failed to copy gallery'));
    throw new Error(`Failed to copy gallery: ${errorMessage}`);
  }
};
