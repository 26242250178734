import React from 'react';
import './vertical_story_stat.scss';
import { Text, TEXT_VARIANTS } from '../../../blocks/text';
import { Tooltip } from '../../../blocks/tooltip/tool_tip.component';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../../blocks/icon';
import { TOOLTIP_PLACEMENT } from '../../../blocks/tooltip/tool_tip.types';
import { statFormatterFn } from '../story_stats.utils';

interface VerticalStoryStatContentProps {
  title: string;
  data: string;
  tooltipContent?: string[];
  onOpen?: () => void;
  onClose?: () => void;
}

export const VerticalStoryStatContent = ({
  data,
  title,
  tooltipContent,
  onOpen,
  onClose,
}: VerticalStoryStatContentProps) => {
  const openHandlerProps = {
    onOpen,
    onClose,
  };
  return (
    <div>
      <Text
        variant={tooltipContent?.length ? TEXT_VARIANTS.H2 : TEXT_VARIANTS.H4}
      >
        {data}
      </Text>
      <div className="story_stats__label">
        <Text
          variant={tooltipContent?.length ? TEXT_VARIANTS.B2 : TEXT_VARIANTS.C1}
        >
          {title}
        </Text>
        {tooltipContent?.length && (
          <Tooltip
            {...openHandlerProps}
            id={`${title.toLowerCase()}_tooltip`}
            placement={TOOLTIP_PLACEMENT.LEFT}
            content={
              <div className="story_stats__tooltip">
                <Text variant={TEXT_VARIANTS.B12}>{title}</Text>
                {tooltipContent?.map((content) => (
                  <Text variant={TEXT_VARIANTS.B2}>{content}</Text>
                ))}
              </div>
            }
          >
            <Icon
              className="story_stats__tooltip_icon"
              iconKey={ICON_NAMES.QUESTION_MARK_CIRCLE}
              size={ICON_SIZES.SMALL}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};
