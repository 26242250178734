import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var CloseCircle = function CloseCircle(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'close__circle__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon',
      viewBox: '0 0 1000 1000'
    },
    React.createElement(
      'g',
      null,
      React.createElement('path', {
        d: 'M500,1000C224,1000,0,776,0,500S224,0,500,0s500,224,500,500S776,1000,500,1000z M735.7,617.8L617.8,500l117.8-117.8 c7.8-7.8,12.4-18.2,12.4-29.3c0-11-4.6-22.1-12.4-29.9l-58.6-58.6c-7.8-7.8-18.9-12.4-29.9-12.4c-11.1,0-21.5,4.6-29.3,12.4 L500,382.2L382.2,264.3c-7.8-7.8-18.2-12.4-29.3-12.4c-11.1,0-22.1,4.6-29.9,12.4l-58.6,58.6c-7.8,7.8-12.4,18.9-12.4,29.9 c0,11.1,4.6,21.5,12.4,29.3L382.2,500L264.3,617.8c-7.8,7.8-12.4,18.2-12.4,29.3c0,11,4.6,22.1,12.4,29.9l58.6,58.6 c7.8,7.8,18.9,12.4,29.9,12.4c11.1,0,21.5-4.6,29.3-12.4L500,617.8l117.8,117.8c7.8,7.8,18.2,12.4,29.3,12.4 c11.1,0,22.1-4.6,29.9-12.4l58.6-58.6c7.8-7.8,12.4-18.9,12.4-29.9C748.1,636.1,743.5,625.7,735.7,617.8z' // eslint-disable-line max-len
      })
    )
  );
};

CloseCircle.propTypes = propTypes;

export default CloseCircle;