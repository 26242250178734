import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var RightsManual = function RightsManual(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'RightsManual_icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M517.632 997.053C534.294 989.953 928.719 816.753 928.719 542.653H928.925V42.6527C928.925 19.8526 909.487 0.952942 886.037 0.952942H114.67C91.2208 0.952942 71.7823 19.8526 71.7823 42.6527V542.653C71.7823 816.653 466.105 989.853 482.869 997.053C488.218 999.653 494.183 1000.95 500.251 1000.95C506.216 1000.95 512.284 999.653 517.632 997.053ZM713.582 213.466L778.737 278.621L401.687 655.671L401.496 655.479L394.694 662.281L213.216 480.803L278.371 415.648L394.886 532.163L713.582 213.466Z' // eslint-disable-line max-len
    })
  );
};

RightsManual.propTypes = propTypes;

export default RightsManual;