import React from 'react';
import { IconProps } from '../icon.types';

export const CursorArrowClick = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.48584 10.0068C1.48584 13.186 3.32031 15.9917 5.82715 17.3696C6.69873 17.8594 7.4209 16.6973 6.45801 16.1411C4.37451 14.979 2.90527 12.6714 2.90527 10.0068C2.90527 6.08887 6.02637 2.95117 9.94434 2.95117C13.8623 2.95117 17.0083 6.08887 17.0083 10.0068C17.0083 10.5049 17.3901 10.7456 17.7554 10.7456C18.0957 10.7456 18.4194 10.4966 18.4194 10.0068C18.4194 5.375 14.5762 1.54004 9.94434 1.54004C5.3208 1.54004 1.48584 5.375 1.48584 10.0068ZM9.62891 16.0166C9.62061 16.3403 10.0024 16.4482 10.21 16.2407L11.48 14.9707L12.8994 18.4736C12.9658 18.6313 13.1318 18.7061 13.2812 18.6479L14.103 18.3159C14.2524 18.2495 14.3105 18.0752 14.2358 17.9175L12.75 14.4644L14.5347 14.3979C14.8501 14.3813 14.9995 14.0742 14.7754 13.8418L10.2432 9.17676C10.0273 8.96094 9.71191 9.08545 9.70361 9.39258L9.62891 16.0166Z" />
    </svg>
  );
};
