import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var OutboundArrow = function OutboundArrow(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'outboundArrow',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 714.6 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M154.4,304l-50.6-50.5L306.8,50.5L357.3,0l50.5,50.5L610,252.6l-50.5,50.5L393.1,136.8v577.9h-71.5V136.8L154.4,304z M643.1,571.2v357.3H71.5V571.2H0V1000h714.6V571.2H643.1z' // eslint-disable-line max-len
    })
  );
};

OutboundArrow.propTypes = propTypes;

export default OutboundArrow;