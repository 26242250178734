
import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Reload = function Reload(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'reload__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M983.1,608.7C927.7,839.2,737.6,1000,497.4,1000c-127,0-250-50.1-342.4-138l-84,84c-7.8,7.8-18.2,12.4-29.3,12.4C18.9,958.3,0,939.4,0,916.7V625c0-22.8,18.9-41.7,41.7-41.7h291.7c22.8,0,41.7,18.9,41.7,41.7c0,11.1-4.6,21.5-12.4,29.3l-89.2,89.2c61.2,57.3,142.6,89.9,226.6,89.9c115.9,0,223.3-59.9,283.9-158.9c15.6-25.4,23.4-50.1,34.5-76.2c3.3-9.1,9.7-15,19.5-15h125c11.7,0,20.8,9.7,20.8,20.8C983.7,605.4,983.7,607.4,983.1,608.7z M1000,375c0,22.8-18.9,41.7-41.7,41.7H666.7c-22.8,0-41.7-18.9-41.7-41.7c0-11.1,4.6-21.5,12.4-29.3l89.9-89.9C665.4,198.6,584,166.7,500,166.7c-115.9,0-223.3,59.9-283.9,158.9c-15.6,25.4-23.4,50.1-34.5,76.2c-3.3,9.1-9.7,15-19.5,15H32.6c-11.7,0-20.8-9.8-20.8-20.8c0-1.3,0-3.3,0-4.6C67.7,160.1,259.8,0,500,0c127.6,0,252,50.8,344.4,138L929,54c7.8-7.8,18.2-12.4,29.3-12.4c22.8,0,41.7,18.9,41.7,41.7V375z' // eslint-disable-line max-len
    })
  );
};

Reload.propTypes = propTypes;

export default Reload;