/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Flag = function Flag(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon',
      viewBox: '0 0 14 14',
      id: 'playlist__icon'
    },
    React.createElement('path', { d: 'M9.19905 13.9727C9.08877 14.0387 9 13.9805 9 13.8449V8.15469C9 8.01969 9.08877 7.96149 9.19905 8.02689L13.9173 10.8798C14.0276 10.9458 14.0276 11.0538 13.9173 11.1192L9.19905 13.9727Z' }),
    React.createElement('path', { d: 'M14 1.75H0V0.25H14V1.75Z' }),
    React.createElement('path', { d: 'M14 6.75H0V5.25H14V6.75Z' }),
    React.createElement('path', { d: 'M7 11.75H0V10.25H7V11.75Z' })
  );
};

Flag.propTypes = propTypes;

export default Flag;