import { useCallback, useState } from 'react';

export type CallbackRef<T> = (node: T) => void;

/**
 * A Typescript safe version of the React useRef hook which causes re-renders
 * when the ref value is updated. You would want this when re-assigning the ref
 * value needs to cause something to update.
 * See https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 */
export const useCallbackRef = <T>() => {
  const [ref, setRef] = useState<T>();
  const setCallbackRef: CallbackRef<T> = useCallback((node: T) => {
    if (node !== null) {
      setRef(node);
    }
  }, []);
  return [ref, setCallbackRef] as const;
};
