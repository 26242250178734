import { connect } from 'react-redux';
import { selectCurrentPublisherFeatureFlags } from '../../../redux/user/user.selectors';
import { AppState } from '../../../types';
import { GalleryListGridCard } from './gallery_list_grid_card.component';

const mapStateToProps = (state: AppState) => ({
  currentPublisherFeatureFlags: selectCurrentPublisherFeatureFlags(state),
});

export const GalleryListGridCardConnect = connect(mapStateToProps)(
  GalleryListGridCard,
);
