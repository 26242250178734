import { Publisher } from '../user/user.types';

export interface NetworkState {
  publishers: { [id: string]: Publisher };
  settings: NetworkSettings | null;
}
export interface NetworkSettings {
  id: string;
  gallery_id: string;
  network_id: string;
  gallery_recommendation_count: number;
}
export enum NETWORK_PUBLISHERS_TYPES {
  START = 'NETWORK_PUBLISHERS/START',
  SUCCESS = 'NETWORK_PUBLISHERS/SUCCESS',
  FAILURE = 'NETWORK_PUBLISHERS/FAILURE',
  CLEAR = 'NETWORK_PUBLISHERS/CLEAR',
}

export enum NETWORK_SETTINGS {
  START = 'NETWORK_SETTINGS/START',
  SUCCESS = 'NETWORK_SETTINGS/SUCCESS',
  FAILURE = 'NETWORK_SETTINGS/FAILURE',
}

export interface GetNetworkPublishersListStartAction {
  type: NETWORK_PUBLISHERS_TYPES.START;
  payload: {};
}
export interface GetNetworkPublishersListClearAction {
  type: NETWORK_PUBLISHERS_TYPES.CLEAR;
  payload: {};
}
export interface GetNetworkPublishersListSuccessAction {
  type: NETWORK_PUBLISHERS_TYPES.SUCCESS;
  payload: { [id: string]: Publisher };
}
export interface GetNetworkPublishersListFailureAction {
  type: NETWORK_PUBLISHERS_TYPES.FAILURE;
  payload: {};
}

export interface GetNetworkSettingsSuccessAction {
  type: NETWORK_SETTINGS.SUCCESS;
  payload: NetworkSettings;
}

export type NetworkActions =
  | GetNetworkPublishersListStartAction
  | GetNetworkPublishersListSuccessAction
  | GetNetworkPublishersListFailureAction
  | GetNetworkPublishersListClearAction
  | GetNetworkSettingsSuccessAction;
