import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var VolumeMute = function VolumeMute(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'VolumeMute__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1157.9 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M631.6,52.6v894.7c0,28.8-23.8,52.6-52.6,52.6c-14,0-27.1-5.8-37-15.6L268.1,710.5H52.6C23.8,710.5,0,686.7,0,657.9V342.1c0-28.8,23.8-52.6,52.6-52.6h215.5L541.9,15.6c9.9-9.9,23-15.6,37-15.6C607.7,0,631.6,23.8,631.6,52.6z' // eslint-disable-line max-len
    })
  );
};

VolumeMute.propTypes = propTypes;

export default VolumeMute;