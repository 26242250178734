import './account_edit_form.scss';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withLocalize } from 'react-localize-redux';
import { withController } from '../../../hooks/withController';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_TYPES,
  BUTTON_VARIANT,
} from '../../../blocks/button';
import { Input, InputProps } from '../../../blocks/input';
import { urlValidationRegex } from '../../../../utils';
import {
  AccountEditFormProps,
  FORM_INPUTS,
  FORM_INPUT_NAMES,
  PublisherUpdateDetails,
} from '../../../pages/account_settings/account_settings.types';
import { StoryAppearanceSection } from './story_appearance_section/story_appearance_section.component';
import { WebSettingsSection } from './web_settings_section/web_settings_section.component';
import {
  isDisplayNameEnabled,
  isDisplayAvatarUploadEnabled,
  isFaviconUploadEnabled,
} from '../account_settings.constants';
import { useToast } from '../../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../../blocks/toast';

const ControlledInput = withController<string, InputProps>(Input);

const AccountEditFormComponent = ({
  translate,
  isSaving,
  publisher,
  onSubmit,
  onReject,
}: AccountEditFormProps) => {
  const { showToast } = useToast();

  const [avatarImg, setAvatarImg] = useState<File | undefined>();
  const [avatarUrl, setAvatarUrl] = useState<string>(publisher.avatarUrl ?? '');

  const [faviconImg, setFaviconImg] = useState<File | undefined>();
  const [faviconUrl, setFaviconUrl] = useState<string>(
    publisher.faviconUrl ?? '',
  );

  const methods = useForm<FORM_INPUTS>({
    mode: 'onSubmit',
    defaultValues: {
      gallery_domain: publisher.gallery_domain,
      name: publisher.name,
    },
  });

  const {
    watch,
    formState: { isDirty, dirtyFields },
    handleSubmit,
    trigger,
  } = methods;

  const watchDisplayName = watch(FORM_INPUT_NAMES.NAME, publisher.name); // watch display name
  const watchAvatar = watch(FORM_INPUT_NAMES.AVATAR);
  const watchFavicon = watch(FORM_INPUT_NAMES.FAVICON);

  useEffect(() => {
    trigger(FORM_INPUT_NAMES.AVATAR).then((isValid) => {
      if (isValid) {
        setAvatarImg(watchAvatar?.[0]);
      } else {
        setAvatarImg(undefined);
        showToast({
          type: TOAST_TYPES.ERROR,
          message: `${translate('settings.snackbar.imageInvalid.errorTitle')}`,
          description: `${translate(
            'settings.snackbar.imageInvalid.errorBody',
          )}`,
        });
      }
    });
  }, [watchAvatar]);

  useEffect(() => {
    trigger(FORM_INPUT_NAMES.FAVICON).then((isValid: boolean) => {
      if (isValid) {
        setFaviconImg(watchFavicon?.[0]);
      } else {
        setFaviconImg(undefined);
        showToast({
          type: TOAST_TYPES.ERROR,
          message: `${translate('settings.snackbar.imageInvalid.errorTitle')}`,
          description: `${translate(
            'settings.snackbar.imageInvalid.errorBody',
          )}`,
        });
      }
    });
  }, [watchFavicon]);

  // update avatar preview in real time
  useEffect(() => {
    if (!avatarImg) {
      setAvatarUrl(publisher.avatarUrl ?? '');
    } else {
      URL.revokeObjectURL(avatarUrl);
      setAvatarUrl(URL.createObjectURL(avatarImg));
    }

    return () => URL.revokeObjectURL(avatarUrl);
  }, [avatarImg]);

  // update favicon preview in real time
  useEffect(() => {
    if (!faviconImg) {
      setFaviconUrl(publisher.faviconUrl ?? '');
    } else {
      URL.revokeObjectURL(faviconUrl);
      setFaviconUrl(URL.createObjectURL(faviconImg));
    }

    return () => URL.revokeObjectURL(faviconUrl);
  }, [faviconImg]);

  return (
    <FormProvider {...methods}>
      <form
        id="account-edit-form"
        className="account_edit__form"
        onSubmit={handleSubmit(
          (data: PublisherUpdateDetails) => {
            const updatedData = Object.keys(dirtyFields).reduce<
              Record<string, string | File[] | undefined>
            >((acc, field) => {
              if (
                field === FORM_INPUT_NAMES.NAME ||
                field === FORM_INPUT_NAMES.WEBSITE
              ) {
                acc[field] = data[field]?.trim();
              } else {
                acc[field] = data[field as keyof PublisherUpdateDetails];
              }
              return acc;
            }, {});
            onSubmit(updatedData);
          },
          () => {
            showToast({
              type: TOAST_TYPES.ERROR,
              message: `${translate('settings.snackbar.changesNotSaved')}`,
              description: `${translate(
                'settings.snackbar.formInvalid.errorBody',
              )}`,
            });
          },
        )}
      >
        <fieldset className="account_edit__inputs" disabled={isSaving}>
          <ControlledInput
            name="gallery_domain"
            label={`${translate('settings.website.label')}`}
            placeholder={`${translate('settings.website.label')}`}
            validationRules={{
              pattern: {
                value: urlValidationRegex,
                message: `${translate('settings.website.invalid')}`,
              },
            }}
          />
          {(isDisplayNameEnabled() || isDisplayAvatarUploadEnabled()) && (
            <StoryAppearanceSection
              avatarUrl={avatarUrl}
              avatarImg={avatarImg}
              isAvatarLoading={isSaving && dirtyFields.avatar?.[0]}
              displayName={watchDisplayName}
            />
          )}

          {isFaviconUploadEnabled() && (
            <WebSettingsSection
              faviconUrl={faviconUrl}
              faviconImg={faviconImg}
              isFaviconLoading={isSaving && dirtyFields.favicon?.[0]}
            />
          )}
        </fieldset>

        <div className="account_edit__actions">
          <Button
            color={BUTTON_COLOR_VARIANT.SECONDARY}
            variant={BUTTON_VARIANT.OUTLINE}
            onClick={onReject}
            isDisabled={isSaving}
          >
            {translate('settings.formActions.cancel')}
          </Button>
          <Button
            color={BUTTON_COLOR_VARIANT.PRIMARY}
            type={BUTTON_TYPES.SUBMIT}
            isDisabled={isSaving || !isDirty}
            loading={isSaving}
          >
            {translate('settings.formActions.save')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export const AccountEditForm = withLocalize(AccountEditFormComponent);
