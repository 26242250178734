import React, { ReactElement } from 'react';
import './carousel.scss';
import { CarouselImage } from './carousel.types';
import { Story } from '../../features/story/story.component';

export interface CarouselProps {
  imageList: CarouselImage[];
  showTitle?: boolean;
}

const textStyles = {
  fontSize: '1rem',
  lineHeight: '1.4rem',
  fontWeight: 700,
};

export const Carousel = ({
  imageList,
  showTitle = true,
}: CarouselProps): ReactElement => {
  return (
    <ul className="carousel">
      {imageList.map((img) => {
        return (
          <li key={`${img.src}`}>
            <figure>
              <Story
                publisher={img.publisher}
                storySrc={img.src}
                title={img.title}
                showTitle={showTitle}
                previewSrc={img.previewSrc}
                textStyles={textStyles}
              />
            </figure>
          </li>
        );
      })}
    </ul>
  );
};
