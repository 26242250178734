import React, { VoidFunctionComponent } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import './story_embed.scss';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
} from '../../../blocks/button';
import { generateEmbedCode } from './story_embed.utils';
import { copyToClipboard } from '../../gallery_list_grid_card/gallery_list_grid_card.utils';
import { TEXT_VARIANTS, Text } from '../../../blocks/text';
import { Tooltip } from '../../../blocks/tooltip/tool_tip.component';
import {
  TOOLTIP_ACTIONS,
  TOOLTIP_PLACEMENT,
  TOOLTIP_VARIANT,
} from '../../../blocks/tooltip/tool_tip.types';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../../blocks/icon';
import CopyEmbedCodeGif from '../../../../assets/gif_copyEmbedCode.mp4';
import { useToast } from '../../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../../blocks/toast';

interface StoryEmbedProps extends LocalizeContextProps {
  storyId: string;
  isDraft: boolean;
}
const StoryEmbedComponent: VoidFunctionComponent<StoryEmbedProps> = ({
  storyId,
  isDraft = false,
  translate,
}) => {
  const getTranslation = useTranslateStrings(translate);
  const { showToast } = useToast();

  const embedCode = generateEmbedCode(storyId);
  const copyStoryId = {
    onSuccess: () =>
      showToast({
        message: `${getTranslation('stories.embed.copied')}`,
        type: TOAST_TYPES.SUCCESS,
      }),
    onFailure: () =>
      showToast({
        message: `${getTranslation('general.copyFailed')}`,
        type: TOAST_TYPES.ERROR,
      }),
  };

  return (
    <div className="story_embed">
      <div className="story_embed__title_container">
        <Text className="story_embed__title" variant={TEXT_VARIANTS.B1}>
          {getTranslation('stories.embed.title')}
        </Text>
        <Tooltip
          id="story_embed__tooltip"
          placement={TOOLTIP_PLACEMENT.BOTTOM}
          variant={TOOLTIP_VARIANT.GIF_DESKTOP}
          content={CopyEmbedCodeGif}
          triggerAction={TOOLTIP_ACTIONS.HOVER}
          arrow={false}
        >
          <Icon
            className="story_embed__tooltip_icon"
            iconKey={ICON_NAMES.INFO_CIRCLE}
            size={ICON_SIZES.LARGE}
          />
        </Tooltip>
      </div>
      <Text className="story_embed__description" variant={TEXT_VARIANTS.B2}>
        {getTranslation('stories.embed.description')}
      </Text>
      {isDraft && (
        <Text className="story_embed__draft" variant={TEXT_VARIANTS.B22}>
          {getTranslation('stories.embed.draft')}
        </Text>
      )}
      <Button
        variant={BUTTON_VARIANT.OUTLINE}
        color={BUTTON_COLOR_VARIANT.SECONDARY}
        onClick={() =>
          copyToClipboard(
            embedCode,
            copyStoryId.onSuccess,
            copyStoryId.onFailure,
          )
        }
        ariaLabel={getTranslation('stories.embed.ariaLabel')}
        isDisabled={isDraft}
      >
        {getTranslation('stories.embed.copy')}
      </Button>
    </div>
  );
};

export const StoryEmbed = withLocalize(StoryEmbedComponent);
