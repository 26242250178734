import React from 'react';
import { IconProps } from '../icon.types';

export const ArrowUp = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 17.6954C10.3075 17.6954 10.5283 17.4746 10.5283 17.175V6.22168L10.481 4.53413L12.6023 6.81311L14.9838 9.17883C15.0784 9.27346 15.2204 9.32078 15.3623 9.32078C15.6541 9.32078 15.8749 9.09998 15.8749 8.80032C15.8749 8.65837 15.8276 8.5322 15.7093 8.41392L10.3943 3.10681C10.276 2.98853 10.1419 2.92544 10 2.92544C9.85017 2.92544 9.71611 2.98853 9.59783 3.10681L4.28284 8.41392C4.17244 8.5322 4.11724 8.65837 4.11724 8.80032C4.11724 9.09998 4.33804 9.32078 4.62981 9.32078C4.77175 9.32078 4.92158 9.27346 5.00833 9.17883L7.39771 6.81311L9.51897 4.52625L9.46377 6.22168V17.175C9.46377 17.4746 9.68457 17.6954 10 17.6954Z" />
    </svg>
  );
};
