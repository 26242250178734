import React from 'react';
import PropTypes from 'prop-types';

import IconComponent from '../Icon';

var propDescriptions = {
  value: 'The value of the metric',
  label: 'The text directly below the metric',
  icon: 'An optional icon to show to the left of the metric label'
};

var propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element
};

var defaultProps = {};

var Metric = function Metric(_ref) {
  var value = _ref.value,
      label = _ref.label,
      icon = _ref.icon;

  return React.createElement(
    'div',
    { className: 'cr__metric' },
    React.createElement(
      'div',
      { className: 'cr__metric__value' },
      value
    ),
    React.createElement(
      'div',
      { className: 'cr__metric__label' },
      icon && React.createElement(
        'div',
        { className: 'cr__metric__label__icon' },
        icon
      ),
      React.createElement(
        'div',
        { className: 'cr__metric__label__text' },
        label
      )
    )
  );
};

Metric.propTypes = propTypes;
Metric.defaultProps = defaultProps;
Metric.propDescriptions = propDescriptions;

export default Metric;