import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Keywords = function Keywords(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Keywords',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1253.5 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M975.6,650.8L651.5,975.6c-15.8,15.2-37.6,24.4-60.1,24.4c-22.4,0-44.2-9.2-59.4-24.4L60.1,503C26.4,470,0,406,0,359.1 V84.5C0,38.3,38.3,0,84.5,0h274.6C406,0,470,26.4,503.6,60.1l472,471.3c15.2,15.8,24.4,37.6,24.4,60.1 C1000,613.9,990.8,635.6,975.6,650.8z M211.2,126.7c-46.9,0-84.5,37.6-84.5,84.5s37.6,84.5,84.5,84.5s84.5-37.6,84.5-84.5 S258.1,126.7,211.2,126.7z M1229.1,650.8L905,975.6c-15.8,15.2-37.6,24.4-60.1,24.4c-34.3,0-51.5-15.8-73.9-38.9l310.2-310.2 c15.2-15.2,24.4-37,24.4-59.4c0-22.5-9.2-44.2-24.4-60.1L609.3,60.1C575.6,26.4,511.5,0,464.7,0h147.9 c46.9,0,110.9,26.4,144.5,60.1l472,471.3c15.2,15.8,24.4,37.6,24.4,60.1C1253.5,613.9,1244.2,635.6,1229.1,650.8z' // eslint-disable-line max-len
    })
  );
};

Keywords.propTypes = propTypes;

export default Keywords;