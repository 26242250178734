import React from 'react';
import './modal.scss';

export interface ModalContentProps {
  children: React.ReactNode;
}

export const ModalContent = ({ children }: ModalContentProps) => {
  return <div className="modal__content-container">{children}</div>;
};
