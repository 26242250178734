import React, { Fragment, VoidFunctionComponent } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import './select_stories_section.scss';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { Tooltip } from '../../../blocks/tooltip/tool_tip.component';
import { SectionActionBar } from '../section_action_bar';
import { StoryListSelectable } from '../../story_list/selectable';
import { Story } from '../../story/story.types';
import {
  TOOLTIP_ACTIONS,
  TOOLTIP_PLACEMENT,
} from '../../../blocks/tooltip/tool_tip.types';
import { Icon, ICON_NAMES } from '../../../blocks/icon';
import { useToast } from '../../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../../blocks/toast';

export interface SelectStoriesSectionProps extends LocalizeContextProps {
  handleNextStep: () => void;
  actionButtonName: string;
  description: string;
  selectedStoriesSource: Story[];
  addStory: (story: Story) => void;
  removeStory: (uuid: string) => void;
  handleScrollToTop: () => void;
  actionDisabled?: boolean;
  scrollElement?: Element | typeof window;
}

export interface SelectStoriesDescriptionComponentProps
  extends LocalizeContextProps {
  description: string;
}

const SelectStoriesDescriptionComponent: VoidFunctionComponent<SelectStoriesDescriptionComponentProps> = ({
  translate,
  description,
}) => {
  const getTranslation = useTranslateStrings(translate);
  const rootClass = 'select_story_tooltip';

  return (
    <Fragment>
      <p>{description}</p>
      <Tooltip
        id="select_story_tooltip"
        className="select_story_tooltip"
        placement={TOOLTIP_PLACEMENT.BOTTOM}
        triggerAction={TOOLTIP_ACTIONS.HOVER}
        content={
          <div>
            <p className={`${rootClass}--content`}>
              {getTranslation(
                'galleries.manualGalleries.selectStoriesSection.toolTipContent',
              )}
            </p>
          </div>
        }
      >
        <Icon
          iconKey={ICON_NAMES.INFO_CIRCLE_FILLED}
          size="20px"
          color="#30B0C7"
        />
        <p className={`${rootClass}--description`}>
          {getTranslation(
            'galleries.manualGalleries.selectStoriesSection.toolTipDescription',
          )}
        </p>
      </Tooltip>
    </Fragment>
  );
};

export const SelectStoriesDescription = withLocalize(
  SelectStoriesDescriptionComponent,
);

export interface SelectStoriesExtraActionProps extends LocalizeContextProps {
  isStoriesSelected: boolean;
  numberSelectedStories: number;
}

const SelectStoriesExtraActionContentComponent: VoidFunctionComponent<SelectStoriesExtraActionProps> = ({
  isStoriesSelected,
  numberSelectedStories,
  translate,
}) => {
  const getTranslation = useTranslateStrings(translate);

  return (
    <Fragment>
      {isStoriesSelected && (
        <p className="select-stories-section__stories-count">
          <span>{`${numberSelectedStories} `}</span>
          {getTranslation(
            'galleries.manualGalleries.selectStoriesSection.storiesSelected',
          )}
        </p>
      )}
    </Fragment>
  );
};

export const SelectStoriesExtraActionContent = withLocalize(
  SelectStoriesExtraActionContentComponent,
);

const SelectStoriesSectionComponent = ({
  handleNextStep,
  translate,
  selectedStoriesSource,
  actionButtonName,
  description,
  addStory,
  removeStory,
  handleScrollToTop,
  actionDisabled,
  scrollElement,
}: SelectStoriesSectionProps) => {
  const { showToast } = useToast();
  const numOfSelectedStories = selectedStoriesSource.length;
  const isStoriesSelected = numOfSelectedStories > 0;
  const getTranslation = useTranslateStrings(translate);

  const handleOnClick = () => {
    if (numOfSelectedStories < 2) {
      return showToast({
        message: `${getTranslation(
          'galleries.manualGalleries.selectStoriesSection.storiesSelectedErrorTooFew',
        )}`,
        type: TOAST_TYPES.WARNING,
      });
    }
    return handleNextStep();
  };

  return (
    <div className="select-stories-section">
      <SectionActionBar
        title={getTranslation(
          'galleries.manualGalleries.selectStoriesSection.headline',
        )}
        descriptionContent={
          <SelectStoriesDescription description={description} />
        }
        actionName={actionButtonName}
        onActionClick={handleOnClick}
        isActionDisabled={actionDisabled}
        extraActionContent={
          <SelectStoriesExtraActionContent
            isStoriesSelected={isStoriesSelected}
            numberSelectedStories={numOfSelectedStories}
          />
        }
      />
      <StoryListSelectable
        selectedStoriesSource={selectedStoriesSource}
        addStory={addStory}
        removeStory={removeStory}
        handleScrollToTop={handleScrollToTop}
        scrollElement={scrollElement}
      />
    </div>
  );
};

export const SelectStoriesSection = withLocalize(SelectStoriesSectionComponent);
