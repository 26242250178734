import React, { Fragment } from 'react';
import { Translate } from 'react-localize-redux';
import { Select } from '../../blocks/select';
import './stories_search_dynamic_filters.scss';
import { DynamicFilterProps } from './stories_search_dynamic_filters.types';

export const DynamicFilter = ({
  onSelectFilter,
  onSelectFilterType,
  onClearFilter,
  index,
  field,
  filterOptions,
}: DynamicFilterProps) => {
  return (
    <div className="dynamic_filters">
      <Translate>
        {({ translate }) => {
          return (
            <Fragment key={index}>
              <Select<false>
                name={field.name}
                options={field.options}
                placeholder={`${translate(
                  'stories.search.dynamicFilterTypesPlaceholder',
                )}`}
                onChange={(val) => onSelectFilterType(field.name, val)}
                value={field.value}
                isClearable={false}
                isMulti={false}
              />

              {field.value && (
                <Fragment>
                  <Select<true>
                    options={filterOptions}
                    isMulti={true}
                    name={field.value.value}
                    placeholder={`${translate(
                      'stories.search.dynamicFilterTypesPlaceholder',
                    )}`}
                    onChange={(val) => onSelectFilter?.(field.name, val)}
                    value={field.selectedFilters}
                  />
                  <button
                    type="button"
                    className="dynamic_filters--clear"
                    onClick={() => onClearFilter?.(field.name)}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.6333 16.3818C3.92383 16.6641 4.40527 16.6641 4.6875 16.3818L10 11.0693L15.3125 16.3818C15.5947 16.6641 16.0845 16.6724 16.3667 16.3818C16.6489 16.0913 16.6489 15.6182 16.3667 15.3359L11.0542 10.0151L16.3667 4.70264C16.6489 4.42041 16.6572 3.93896 16.3667 3.65674C16.0762 3.36621 15.5947 3.36621 15.3125 3.65674L10 8.96924L4.6875 3.65674C4.40527 3.36621 3.91553 3.35791 3.6333 3.65674C3.35107 3.94727 3.35107 4.42041 3.6333 4.70264L8.9458 10.0151L3.6333 15.3359C3.35107 15.6182 3.34277 16.0996 3.6333 16.3818Z"
                        fill="black"
                      />
                    </svg>
                  </button>
                </Fragment>
              )}
            </Fragment>
          );
        }}
      </Translate>
    </div>
  );
};
