import * as Icons from './icons';
import { ICON_TYPE } from './icon.types';

export const iconKeyMap: ICON_TYPE = {
  AddToGrid: Icons.AddToGrid,
  ArrowCircle: Icons.ArrowCircle,
  ArrowClockwise: Icons.ArrowClockwise,
  ArrowDown: Icons.ArrowDown,
  ArrowRight: Icons.ArrowRight,
  ArrowUp: Icons.ArrowUp,
  ASquare: Icons.ASquare,
  ASquareFilled: Icons.ASquareFilled,
  Camera: Icons.Camera,
  Checkmark: Icons.CheckMark,
  CheckmarkCircle: Icons.CheckmarkCircle,
  CheckmarkCircleFilled: Icons.CheckmarkCircleFilled,
  ChevronDown: Icons.ChevronDown,
  ChevronLeft: Icons.ChevronLeft,
  ChevronRight: Icons.ChevronRight,
  Circle: Icons.Circle,
  Close: Icons.Close,
  CloseCircle: Icons.CloseCircle,
  Crop: Icons.Crop,
  CursorClick: Icons.CursorArrowClick,
  Ellipsis: Icons.Ellipsis,
  ExclamationCircle: Icons.ExclamationCircle,
  ExclamationCircleFilled: Icons.ExclamationCircleFill,
  ExclamationTriangleFilled: Icons.ExclamationTriangleFilled,
  ExternalLink: Icons.ExternalLink,
  Eye: Icons.Eye,
  Filters: Icons.Filters,
  Flag: Icons.Flag,
  HandCursor: Icons.HandCursor,
  House: Icons.House,
  InfoCircle: Icons.InfoCircle,
  InfoCircleFilled: Icons.InfoCircleFilled,
  LightBulbCircle: Icons.LightBulbCircle,
  Link: Icons.Link,
  LocalhoodLogoBlack: Icons.LocalhoodLogoBlack,
  Location: Icons.Location,
  LocationFilled: Icons.LocationFill,
  Lock: Icons.Lock,
  PauseFilled: Icons.PauseFilled,
  Pencil: Icons.Pencil,
  Person: Icons.Person,
  Photo: Icons.Photo,
  Plus: Icons.Plus,
  PlusCircle: Icons.PlusCircle,
  PlusCircleFilled: Icons.PlusCircleFill,
  QrCodeScan: Icons.QrCodeScan,
  QuestionMarkCircle: Icons.QuestionMarkCircle,
  Refresh: Icons.Refresh,
  Search: Icons.Search,
  ShareFilled: Icons.ShareFilled,
  SlashCircle: Icons.SlashCircle,
  SoundOff: Icons.SoundOff,
  Speaker: Icons.Speaker,
  SquareStack: Icons.SquareStack,
  Star: Icons.Star,
  TextAlignCenter: Icons.TextAlignCenter,
  TextAlignJustify: Icons.TextAlignJustify,
  TextAlignLeft: Icons.TextAlignLeft,
  TextAlignRight: Icons.TextAlignRight,
  TextFormat: Icons.TextFormat,
  Trash: Icons.Trash,
  Warning: Icons.Warning,
};
