import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Download = function Download(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'download',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      null,
      React.createElement('rect', { x: '0', y: '875', width: '1000', height: '125' }),
      React.createElement('polygon', { points: '625,0 375,0 375,312.5 187.5,312.5 500,625 812.5,312.5 625,312.5' })
    )
  );
};

Download.propTypes = propTypes;

export default Download;