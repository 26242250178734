/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Pulse = function Pulse(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Pulse_icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 34 34',
      height: size,
      fill: 'transparent',
      className: 'cr__icon'
    },
    React.createElement('path', { d: 'M4.3335 19H7.49883C10.4402 19 10.1855 16.5107 12.3428 6.15735C12.4072 5.84817 12.6797 5.62659 12.9955 5.62659C13.3113 5.62659 13.5838 5.84817 13.6482 6.15735L17.7308 26.0667C17.7937 26.3727 18.0606 26.594 18.3729 26.5992C18.6853 26.6043 18.9593 26.3918 19.0322 26.088L21.8442 14.372C21.9115 14.0891 22.1544 13.8825 22.4445 13.8615C22.7345 13.8405 23.0047 14.0098 23.1122 14.28C24.1028 16.7573 24.4988 19.0013 26.8055 19.0013H29.6668', stroke: color, strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' })
  );
};
Pulse.propTypes = propTypes;

export default Pulse;