import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var App = function App(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'app',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M869.2,130.8l130.8,488L642.8,975.9L512,488l-488-130.8L381.3,0L869.2,130.8z M348.5,622L0,970.5l29.5,29.5L378,651.5L348.5,622z M427.5,820.8L309.7,938.7l29.5,29.5L457,850.3L427.5,820.8z M149.7,543L31.9,660.9l29.5,29.5l117.9-117.9L149.7,543z' // eslint-disable-line max-len
    })
  );
};

App.propTypes = propTypes;

export default App;