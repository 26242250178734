import React from 'react';
import { IconProps } from '../icon.types';

export const LocalhoodLogoBlack = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 81 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 13.1883H3.04504V0.611267H0.5V13.1883Z" />
      <path d="M9.27978 13.3887C12.1037 13.3887 13.9079 11.5846 13.9079 8.50785C13.9079 5.47473 12.0776 3.64439 9.27978 3.64439C6.48198 3.64439 4.65165 5.48344 4.65165 8.50785C4.65165 11.5758 6.45583 13.3887 9.27978 13.3887ZM9.27978 11.4451C8.02469 11.4451 7.23155 10.3905 7.23155 8.51657C7.23155 6.66009 8.04212 5.58803 9.27978 5.58803C10.5174 5.58803 11.3193 6.66009 11.3193 8.51657C11.3193 10.3905 10.5261 11.4451 9.27978 11.4451Z" />
      <path d="M23.9428 7.32249C23.8295 5.20453 22.2257 3.64439 19.6981 3.64439C16.9003 3.64439 15.1136 5.50959 15.1136 8.50785C15.1136 11.5584 16.9003 13.3887 19.7156 13.3887C22.1734 13.3887 23.8207 11.9681 23.9515 9.76294H21.5808C21.4239 10.7827 20.7615 11.3928 19.7417 11.3928C18.4779 11.3928 17.6935 10.3469 17.6935 8.50785C17.6935 6.70367 18.4779 5.64033 19.733 5.64033C20.7789 5.64033 21.4326 6.3376 21.5808 7.32249H23.9428Z" />
      <path d="M29.0183 11.4974C28.1554 11.4974 27.5802 11.0616 27.5802 10.3643C27.5802 9.70193 28.1206 9.27485 29.0706 9.20512L31.0404 9.0831V9.76294C31.0404 10.774 30.1252 11.4974 29.0183 11.4974ZM28.1816 13.3364C29.3495 13.3364 30.5087 12.7525 31.0317 11.7589H31.084V13.1883H33.5418V6.76468C33.5418 4.88205 31.9817 3.64439 29.5848 3.64439C27.1095 3.64439 25.5668 4.89076 25.4709 6.70367H27.7981C27.9201 6.01511 28.5215 5.54445 29.4715 5.54445C30.4303 5.54445 31.0404 6.04997 31.0404 6.92156V7.54039L28.6871 7.67984C26.36 7.8193 25.0526 8.80419 25.0526 10.5038C25.0526 12.186 26.4035 13.3364 28.1816 13.3364Z" />
      <path d="M35.4797 13.1883H38.0247V0.611267H35.4797V13.1883Z" />
      <path d="M40.0322 13.1883H42.5773V7.85416C42.5773 6.62522 43.2833 5.77978 44.486 5.77978C45.6453 5.77978 46.2379 6.51192 46.2379 7.71471V13.1883H48.783V7.17432C48.783 5.01278 47.5976 3.67054 45.5232 3.67054C44.1025 3.67054 43.0828 4.33295 42.586 5.50959H42.5337V0.611267H40.0322V13.1883Z" />
      <path d="M54.9306 13.3887C57.7545 13.3887 59.5587 11.5846 59.5587 8.50785C59.5587 5.47473 57.7284 3.64439 54.9306 3.64439C52.1328 3.64439 50.3024 5.48344 50.3024 8.50785C50.3024 11.5758 52.1066 13.3887 54.9306 13.3887ZM54.9306 11.4451C53.6755 11.4451 52.8823 10.3905 52.8823 8.51657C52.8823 6.66009 53.6929 5.58803 54.9306 5.58803C56.1682 5.58803 56.9701 6.66009 56.9701 8.51657C56.9701 10.3905 56.1769 11.4451 54.9306 11.4451Z" />
      <path d="M65.3925 13.3887C68.2164 13.3887 70.0206 11.5846 70.0206 8.50785C70.0206 5.47473 68.1903 3.64439 65.3925 3.64439C62.5947 3.64439 60.7644 5.48344 60.7644 8.50785C60.7644 11.5758 62.5686 13.3887 65.3925 13.3887ZM65.3925 11.4451C64.1374 11.4451 63.3443 10.3905 63.3443 8.51657C63.3443 6.66009 64.1548 5.58803 65.3925 5.58803C66.6301 5.58803 67.432 6.66009 67.432 8.51657C67.432 10.3905 66.6389 11.4451 65.3925 11.4451Z" />
      <path d="M75.0351 13.3364C76.4123 13.3364 77.493 12.613 77.9463 11.5846H77.9898V13.1883H80.5V0.611267H77.955V5.44858H77.9027C77.4494 4.41139 76.4035 3.69669 75.0526 3.69669C72.708 3.69669 71.2263 5.53574 71.2263 8.49914C71.2263 11.4887 72.6993 13.3364 75.0351 13.3364ZM75.898 5.7362C77.1618 5.7362 77.9724 6.81697 77.9724 8.51657C77.9724 10.2249 77.1618 11.2882 75.898 11.2882C74.6168 11.2882 73.8324 10.2336 73.8324 8.51657C73.8324 6.80826 74.6168 5.7362 75.898 5.7362Z" />
    </svg>
  );
};
