import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var ClockThin = function ClockThin(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      width: size,
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg'
    },
    React.createElement('path', {
      d: 'M6.87255 13.0588H11.9902C12.1863 13.0588 12.3497 12.9935 12.4804 12.8627C12.6111 12.732 12.6765 12.5686 12.6765 12.3725V5.76471C12.6765 5.57516 12.6111 5.41503 12.4804 5.28431C12.3497 5.15359 12.1863 5.08824 11.9902 5.08824C11.8007 5.08824 11.6405 5.15359 11.5098 5.28431C11.3791 5.41503 11.3137 5.57516 11.3137 5.76471V11.6961H6.87255C6.67647 11.6961 6.51307 11.7614 6.38235 11.8922C6.25163 12.0229 6.18627 12.183 6.18627 12.3725C6.18627 12.5686 6.25163 12.732 6.38235 12.8627C6.51307 12.9935 6.67647 13.0588 6.87255 13.0588ZM12 22C13.366 22 14.6503 21.7386 15.8529 21.2157C17.0621 20.6928 18.1275 19.9706 19.049 19.049C19.9706 18.1275 20.6928 17.0654 21.2157 15.8627C21.7386 14.6536 22 13.366 22 12C22 10.634 21.7386 9.34967 21.2157 8.14706C20.6928 6.93791 19.9706 5.87255 19.049 4.95098C18.1275 4.02941 17.0621 3.30719 15.8529 2.78431C14.6438 2.26144 13.3562 2 11.9902 2C10.6242 2 9.3366 2.26144 8.12745 2.78431C6.92484 3.30719 5.86275 4.02941 4.94118 4.95098C4.02614 5.87255 3.30719 6.93791 2.78431 8.14706C2.26144 9.34967 2 10.634 2 12C2 13.366 2.26144 14.6536 2.78431 15.8627C3.30719 17.0654 4.02941 18.1275 4.95098 19.049C5.87255 19.9706 6.93464 20.6928 8.13726 21.2157C9.34641 21.7386 10.634 22 12 22ZM12 20.3333C10.8431 20.3333 9.76144 20.1176 8.7549 19.6863C7.74837 19.2549 6.86275 18.6601 6.09804 17.902C5.33987 17.1373 4.7451 16.2516 4.31373 15.2451C3.88889 14.2386 3.67647 13.1569 3.67647 12C3.67647 10.8431 3.88889 9.76144 4.31373 8.7549C4.7451 7.74837 5.33987 6.86275 6.09804 6.09804C6.85621 5.33333 7.73856 4.73856 8.7451 4.31373C9.75163 3.88235 10.8333 3.66667 11.9902 3.66667C13.1471 3.66667 14.2288 3.88235 15.2353 4.31373C16.2418 4.73856 17.1275 5.33333 17.8922 6.09804C18.6569 6.86275 19.2549 7.74837 19.6863 8.7549C20.1176 9.76144 20.3333 10.8431 20.3333 12C20.3333 13.1569 20.1176 14.2386 19.6863 15.2451C19.2614 16.2516 18.6667 17.1373 17.902 17.902C17.1438 18.6601 16.2582 19.2549 15.2451 19.6863C14.2386 20.1176 13.1569 20.3333 12 20.3333Z',
      fill: color
    })
  );
};

ClockThin.propTypes = propTypes;

export default ClockThin;