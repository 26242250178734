import { AppState } from '../../types';

export const selectExportsState = (state: AppState) => state.exports;

export const selectStoryId = (state: AppState) => state.exports.storyId;

export const selectExportId = (state: AppState) => state.exports.exportId;

export const selectExportPrepareStatus = (state: AppState) =>
  state.exports.status;

export const selectExportType = (state: AppState) => state.exports.type;

export const selectDownload = (state: AppState) => state.exports.download;

export const selectDownloadLocation = (state: AppState) =>
  state.exports.location;
