import React, { Fragment, FunctionComponent, useState } from 'react';
import './create_gallery_block.scss';
import {
  withLocalize,
  LocalizeContextProps,
  Translate,
} from 'react-localize-redux';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';
import { Navbar } from '../../blocks/full_screen_modal/navbar.component';
import { isGalleryStatePristine } from '../static_galleries_create';
import { MODAL_TYPES } from '../../blocks/modal';
import {
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
  Button,
} from '../../blocks/button';
import { useModal } from '../../hooks/use_modal/use_modal';
import { NameGallerySection } from '../static_galleries_create/name_gallery_section';
import { SelectStoriesSection } from '../static_galleries/select_stories_section';
import { EditStoriesSection } from '../static_galleries_create/edit_stories_section';
import { STEP_TITLE_IDS } from '../static_galleries_create/static_galleries_create.constants';
import { LoadStateData } from '../../blocks/load_data/load_state_data.component';
import { STORIES_LOAD_TYPES } from '../../pages/stories_list/stories_list.types';
import { STEP_DIRECTIONS } from '../static_galleries_create/static_galleries.types';
import { LoadingGif } from '../../blocks/LoadingGif/LoadingGif.component';
import {
  getStoriesSearch,
  STORY_SEARCH_LOAD_TYPES,
} from '../../../http/stories_search.thunks';
import { GalleryCreateEditResponse } from '../../../http/gallery_create.thunks';
import { GetGalleriesResponse } from '../../../http/galleries_list.thunks';
import { generateEmbedCode } from '../gallery_embed/gallery_embed.utils';
import { LANGUAGE_BASE } from '../static_galleries/static_galleries.constants';
import { CopyEmbedButton } from '../static_galleries_create/edit_stories_section/copy_embed_button';
import { EditableStaticGalleryFields } from '../../../redux/galleries/galleries.types';
import { useCustomPrompt } from '../../hooks/useCustomPrompt';
import { DirtyStateModal } from '../static_galleries/dirty_state_modal';
import { Story } from '../story/story.types';
import { scrollToTopGenerator } from '../../../utils';
import { useCallbackRef } from '../../hooks/useCallbackRef';
import { PUBLISHED_STORIES_FILTER } from '../story_list/story_list.constants';
import { AuthWrapper } from '../../blocks/authwrapper/auth_wrapper.connect';
import { FEATURE_FLAGS } from '../../blocks/authwrapper/auth_wrapper.types';
import { EXTERNAL_ROUTES, ROUTES } from '../../../types';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../blocks/icon';
import { Menu } from '../../blocks/menu';
import { useToast } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../blocks/toast';

export interface CreateGalleryBlockProps extends LocalizeContextProps {
  className?: string;
  activeStepIndex: number;
  label?: string;
  gallery: Partial<EditableStaticGalleryFields>;
  numberOfStories: number;
  hasSearchQuery: boolean;
  setManualGalleryCreationStep: (step: number) => void;
  selectedStories: Story[];
  addStory: (story: Story) => void;
  removeStory: (uuid: string) => void;
  saveGallery: () => Promise<GalleryCreateEditResponse>;
  updateGalleryList: () => Promise<GetGalleriesResponse>;
  resetGalleryState: () => void;
}

const CreateGalleryBlockComponent: FunctionComponent<CreateGalleryBlockProps> = ({
  translate,
  className,
  activeStepIndex = 0,
  selectedStories,
  label = '',
  gallery,
  numberOfStories,
  hasSearchQuery,
  setManualGalleryCreationStep,
  addStory,
  removeStory,
  saveGallery,
  updateGalleryList,
  resetGalleryState,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const [modalRefValue, setModalRef] = useCallbackRef<HTMLElement>();
  const getTranslation = useTranslateStrings(translate);
  const [Modal, toggle] = useModal({ ref: setModalRef });
  const { showToast } = useToast();
  const [showDirtyModal, setShowDirtyModal] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const titles = STEP_TITLE_IDS.map((id) => getTranslation(id, undefined));

  /* if a search was made the network has stories, since we hide 
      the searchbar on empty state screens */
  const networkHasStories = numberOfStories > 0 || hasSearchQuery;
  const shouldShowDirtyModal =
    networkHasStories && !isGalleryStatePristine(gallery);

  useCustomPrompt(
    getTranslation(`${LANGUAGE_BASE}.general.preventNavigation.description`),
    shouldShowDirtyModal,
  );

  const handleToggle = () => {
    toggle();
    resetGalleryState();
  };

  const handleClose = () => {
    if (!shouldShowDirtyModal) {
      return handleToggle();
    }
    return setShowDirtyModal(true);
  };

  const handleConfirmModalAccept = () => {
    setShowDirtyModal(false);
    handleToggle();
  };

  const setNewStepValue = (val: string) => {
    const newStepIndex =
      val === STEP_DIRECTIONS.NEXT ? activeStepIndex + 1 : activeStepIndex - 1;
    setManualGalleryCreationStep(newStepIndex);
  };

  const handleNextStep = () => {
    if (activeStepIndex >= STEP_TITLE_IDS.length - 1) return;
    setNewStepValue(STEP_DIRECTIONS.NEXT);
  };

  const handlePreviousStep = () => {
    if (activeStepIndex <= 0) return;
    setNewStepValue(STEP_DIRECTIONS.PREVIOUS);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const newGallery = await saveGallery();
      const embedCode = generateEmbedCode(newGallery.gallery.id);
      await updateGalleryList();
      setIsSaving(false);
      handleToggle();
      showToast({
        message: getTranslation(`${LANGUAGE_BASE}.general.createSuccess`, {
          galleryName: label,
        }),
        type: TOAST_TYPES.SUCCESS,
        customActionCTA: (
          <CopyEmbedButton
            embedCode={embedCode}
            variant={BUTTON_VARIANT.GHOST}
            color={BUTTON_COLOR_VARIANT.PRIMARY}
          />
        ),
      });
    } catch (error) {
      setIsSaving(false);
      showToast({
        message: `${getTranslation(`${LANGUAGE_BASE}.general.createFailure`)}`,
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  const ManualGallerySteps = (stepIndex: number) => {
    let stepByIndex;
    switch (stepIndex) {
      case 1:
        stepByIndex = (
          <SelectStoriesSection
            handleNextStep={handleNextStep}
            actionButtonName={getTranslation(
              'galleries.manualGalleries.selectStoriesSection.nextStepButton',
            )}
            actionDisabled={!networkHasStories}
            description={getTranslation(
              'galleries.manualGalleries.selectStoriesSection.description',
            )}
            selectedStoriesSource={selectedStories}
            addStory={addStory}
            removeStory={removeStory}
            handleScrollToTop={scrollToTopGenerator(modalRefValue)}
            scrollElement={modalRefValue}
          />
        );
        break;
      case 2:
        stepByIndex = (
          <EditStoriesSection isSaving={isSaving} handleSave={handleSave} />
        );
        break;
      default:
        stepByIndex = <NameGallerySection handleNextStep={handleNextStep} />;
        break;
    }
    return stepByIndex;
  };

  return (
    <Fragment>
      <AuthWrapper featureFlags={FEATURE_FLAGS.DYNAMIC_GALLERY}>
        <AuthWrapper featureFlags={FEATURE_FLAGS.STATIC_GALLERY} exclude={true}>
          <Button
            className={className}
            onClick={() => window.location.assign(ROUTES.GALLERIES_CREATE)}
          >
            {getTranslation('galleries.button.createGallery')}
          </Button>
        </AuthWrapper>
      </AuthWrapper>
      <AuthWrapper featureFlags={FEATURE_FLAGS.STATIC_GALLERY}>
        <AuthWrapper
          featureFlags={FEATURE_FLAGS.DYNAMIC_GALLERY}
          exclude={true}
        >
          <Button onClick={handleToggle}>
            <Translate id="galleries.button.createGallery" />
          </Button>
        </AuthWrapper>
      </AuthWrapper>
      <AuthWrapper
        featureFlags={[
          FEATURE_FLAGS.DYNAMIC_GALLERY,
          FEATURE_FLAGS.STATIC_GALLERY,
        ]}
      >
        <Menu
          list={[
            {
              id: 1,
              text: getTranslation('galleries.createMenu.staticGallery'),
              description: getTranslation(
                'galleries.createMenu.staticGalleryDescription',
              ),
              action: handleToggle,
            },
            {
              id: 2,
              text: getTranslation('galleries.createMenu.dynamicGallery'),
              description: getTranslation(
                'galleries.createMenu.dynamicGalleryDescription',
              ),
              url: ROUTES.GALLERIES_CREATE,
            },
            {
              id: 3,
              args: {
                className: 'create-gallery-menu-container__support-link',
                target: '_blank',
              },
              text: getTranslation(
                'galleries.createMenu.supportLinkDescription',
              ),
              iconKey: ICON_NAMES.ARROW_CIRCLE,
              url: EXTERNAL_ROUTES.GALLERY_SUPPORT_LINK,
            },
          ]}
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          className="create-gallery-menu-container"
        >
          <Button className={className} onClick={toggleMenu}>
            {getTranslation('galleries.button.createGallery')}
            <Icon
              ariaHidden={true}
              iconKey={ICON_NAMES.CHEVRON_DOWN}
              size={ICON_SIZES.SMALL}
              color="#ffffff"
            />
          </Button>
        </Menu>
      </AuthWrapper>
      <Modal
        type={MODAL_TYPES.FULL_SCREEN}
        className="static_gallery"
        disableEscapeKeyDown={true}
      >
        <LoadStateData
          actionTypes={STORIES_LOAD_TYPES}
          loading={<LoadingGif size="large" />}
          failure={<div>error</div>}
          thunk={getStoriesSearch({
            searchFilters: PUBLISHED_STORIES_FILTER,
            pagination: { pageSize: 20 },
            loadingType: STORY_SEARCH_LOAD_TYPES.INIT,
          })}
        >
          <Navbar
            titlesForEachStep={titles}
            numberOfSteps={titles.length}
            handleClose={handleClose}
            currentStepIndex={activeStepIndex}
            handleBack={handlePreviousStep}
          />
          <div className="static_gallery__content">
            {ManualGallerySteps(activeStepIndex)}
          </div>
          <DirtyStateModal
            open={showDirtyModal}
            handleClose={() => setShowDirtyModal(false)}
            handleAccept={handleConfirmModalAccept}
          />
        </LoadStateData>
      </Modal>
    </Fragment>
  );
};

export const CreateGalleryBlock = withLocalize(CreateGalleryBlockComponent);
