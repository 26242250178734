import React, { FunctionComponent } from 'react';
import './LoadingGif.scss';
import { Translate } from 'react-localize-redux';
import vanGif from '../../../assets/van.gif';

export interface LoaderProps {
  size: string;
}

export const LoadingGif: FunctionComponent<LoaderProps> = ({ size }) => {
  return (
    <Translate>
      {({ translate }) => {
        return (
          <div>
            <img
              className={size}
              src={vanGif}
              alt={`${translate('general.loadingGif.loading')}`}
            />
          </div>
        );
      }}
    </Translate>
  );
};
