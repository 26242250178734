import { STORY_LOAD_TYPES } from '../stories_list/stories_list.types';
import { Story } from '../../features/story/story.types';
import { SelectOptions } from '../../blocks/select';

export enum STORIES_MANAGE_TYPES {
  START = 'STORIES_MANAGE/START',
  SUCCESS = 'STORIES_MANAGE/SUCCESS',
  FAILURE = 'STORIES_MANAGE/FAILURE',
}

export interface StoriesManageStartAction {
  type: STORIES_MANAGE_TYPES.START;
  payload: {};
}

export interface StoriesManageSuccessAction {
  type: STORIES_MANAGE_TYPES.SUCCESS;
  payload: {};
}

export interface StoriesManageFailureAction {
  type: STORIES_MANAGE_TYPES.FAILURE;
  payload: Error;
}

export interface StoryStartAction {
  type: STORY_LOAD_TYPES.START;
  payload: {};
}

export interface StorySuccessAction {
  type: STORY_LOAD_TYPES.SUCCESS;
  payload: Story;
}

export interface StoryFailureAction {
  type: STORY_LOAD_TYPES.FAILURE;
  payload: string;
}

export type StoriesManageActions =
  | StoriesManageStartAction
  | StoriesManageSuccessAction
  | StoriesManageFailureAction
  | StoryStartAction
  | StorySuccessAction
  | StoryFailureAction;

export enum STORY_MANAGE_FIELDS {
  CAMPAIGN = 'storyManageCampaign',
  THEMES = 'storyManageThemes',
  SEASONS = 'storyManageSeasons',
  PERSONAS = 'storyManagePersonas',
  EXPIRATION = 'storyManageExpiration',
}

export interface DefaultFormVals {
  [STORY_MANAGE_FIELDS.CAMPAIGN]: SelectOptions;
  [STORY_MANAGE_FIELDS.PERSONAS]: SelectOptions;
  [STORY_MANAGE_FIELDS.THEMES]: SelectOptions;
  [STORY_MANAGE_FIELDS.SEASONS]: SelectOptions;
  [STORY_MANAGE_FIELDS.EXPIRATION]: string | null;
}

export enum STORY_STATUS {
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish',
  DRAFT = 'draft',
}
