import React from 'react';
import { Translate } from 'react-localize-redux';
import { BUTTON_COLOR_VARIANT, BUTTON_VARIANT } from '../../blocks/button';
import { ModalButtons, ModalContent } from '../../blocks/modal';
import {
  ModalProps,
  ConnectedModalButton as ModalButton,
  ConnectedModalTitle as ModalTitle,
} from '../../blocks/connected_modal';
import { Story } from '../story/story.types';
import { StoryPreviewComponent } from '../story_preview/story_preview.component';
import './story_moderation_modal.scss';

export interface StoryModerationModalProps {
  Modal: React.FunctionComponent<ModalProps>;
  onAcceptClick: () => Promise<void>;
  story: Story;
  type: string;
}

export enum STORY_MODERATION_MODAL_TYPES {
  HIDE = 'hideStoryModal',
  SHOW = 'showStoryModal',
}

export const StoryModerationModal = ({
  Modal,
  story,
  onAcceptClick,
  type,
}: StoryModerationModalProps) => {
  return (
    <Modal>
      <ModalTitle label={<Translate id={`stories.form.${type}.title`} />} />
      <ModalContent>
        <div className="story_moderation_modal__content">
          <div className="story_moderation_modal__content--left">
            <p>
              <Translate
                id={`stories.form.${type}.description`}
                data={{
                  title: story.title,
                }}
              />
            </p>
            <p>
              <Translate id={`stories.form.${type}.detailsIntro`} />
            </p>
            <Translate
              id={`stories.form.${type}.details`}
              data={{
                publisher: story.publisher.slug,
              }}
            />
          </div>
          <StoryPreviewComponent
            story={story}
            className="story_moderation_modal__content__story_preview"
          />
        </div>
      </ModalContent>
      <ModalButtons>
        <ModalButton
          variant={BUTTON_VARIANT.OUTLINE}
          color={BUTTON_COLOR_VARIANT.SECONDARY}
        >
          <Translate id={`stories.form.${type}.reject`} />
        </ModalButton>
        <ModalButton onClick={onAcceptClick}>
          <Translate id={`stories.form.${type}.accept`} />
        </ModalButton>
      </ModalButtons>
    </Modal>
  );
};
