import _extends from 'babel-runtime/helpers/extends';
import { colours } from '../styles/colourVariables';

var neutral0 = colours.neutral0,
    neutral50 = colours.neutral50,
    neutral200 = colours.neutral200,
    neutral500 = colours.neutral500,
    neutral600 = colours.neutral600,
    neutral700 = colours.neutral700,
    primary50 = colours.primary50,
    primary800 = colours.primary800,
    error500 = colours.error500;


var FilterDropdownV2Styles = function FilterDropdownV2Styles(props) {
  var isInline = props.isInline,
      isRightAligned = props.isRightAligned,
      isTransparent = props.isTransparent,
      hasError = props.hasError,
      isSearchable = props.isSearchable,
      isGroupedFilterDropdown = props.isGroupedFilterDropdown,
      selectedOptions = props.selectedOptions;


  return {
    control: function control(baseStyles, _ref) {
      var selectProps = _ref.selectProps;

      var deriveBackgroundColor = function deriveBackgroundColor(isHovering) {
        // isTransparent will always take precedence
        if (isTransparent) return 'transparent';
        if (isInline) {
          if (isHovering) return neutral50;
          return selectProps.menuIsOpen ? neutral50 : neutral0;
        }
        return neutral0;
      };

      var baseControlStyles = _extends({}, baseStyles, {
        color: selectProps.menuIsOpen ? primary800 : neutral500,
        fontWeight: 400,
        fontSize: '16px',
        borderRadius: '8px',
        border: 'none',
        outline: selectProps.menuIsOpen ? '2px solid' : '1px solid',
        outlineColor: selectProps.menuIsOpen ? primary800 : neutral500,
        outlineOffset: '-1px',
        boxShadow: 'none',
        minHeight: isInline ? '32px' : '44px',
        '&:hover': {
          color: primary800,
          outlineColor: primary800
        },
        '&:focus-within': {
          color: primary800,
          outline: '2px solid',
          outlineColor: primary800
        },
        cursor: isSearchable ? 'text' : 'pointer'
      });
      if (isInline) {
        return _extends({}, baseControlStyles, {
          outline: 'none',
          backgroundColor: deriveBackgroundColor(),
          '&:hover': {
            backgroundColor: deriveBackgroundColor(true)
          },
          '&:focus-within': {
            outline: selectProps.menuIsOpen ? 'none' : '2px solid',
            outlineColor: primary800,
            backgroundColor: deriveBackgroundColor()
          }
        });
      }
      if (hasError) {
        return _extends({}, baseControlStyles, {
          outline: '2px solid',
          outlineColor: error500,
          '&:hover': {
            color: neutral500,
            outlineColor: error500
          },
          '&:focus-within': {
            color: neutral500,
            outline: '2px solid',
            outlineColor: primary800
          }
        });
      }
      return baseControlStyles;
    },
    menu: function menu(baseStyles) {
      return _extends({}, baseStyles, {
        minWidth: isInline && 'fit-content',
        right: isRightAligned && '0',
        overflow: 'overlay',
        border: '1px solid',
        borderRadius: '8px',
        borderColor: neutral200,
        boxShadow: '0px 2px 8px 0px #00000029',
        zIndex: 2,
        marginTop: '4px'
      });
    },
    menuList: function menuList(baseStyles) {
      return _extends({}, baseStyles, {
        overflow: 'overlay',
        padding: '0px',
        '::-webkit-scrollbar': {
          display: 'block',
          width: '16px'
        },
        '::-webkit-scrollbar-button': {
          display: 'none'
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: 'transparent'
        },
        '::-webkit-scrollbar-track-piece': {
          backgroundColor: 'transparent'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent',
          border: '5px solid transparent',
          borderRadius: '24px',
          boxShadow: '4px 0px 0px 4px #00000040 inset',
          cursor: 'pointer'
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#00000040',
          border: '0px solid transparent',
          boxShadow: 'none'
        }
      });
    },
    placeholder: function placeholder(baseStyles, _ref2) {
      var selectProps = _ref2.selectProps;
      return _extends({}, baseStyles, {
        display: selectProps.menuIsOpen && isSearchable ? 'none' : 'initial',
        position: 'relative',
        transform: 'none',
        color: neutral500,
        margin: '0',
        fontSize: '16px',
        lineHeight: '24px'
      });
    },
    valueContainer: function valueContainer(baseStyles) {
      return _extends({}, baseStyles, {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: isInline ? '4px 0 4px 8px' : '10px 0 10px 16px',
        overflow: 'hidden'
      });
    },
    input: function input(baseStyles) {
      return _extends({}, baseStyles, {
        position: 'absolute',
        left: '15px'
      });
    },
    singleValue: function singleValue(baseStyles) {
      return _extends({}, baseStyles, {
        position: 'relative',
        whiteSpace: 'nowrap',
        transform: 'none',
        maxWidth: 'calc(100% - 5px)',
        color: neutral600
      });
    },
    indicatorSeparator: function indicatorSeparator(baseStyles) {
      return _extends({}, baseStyles, {
        display: 'none'
      });
    },
    indicatorsContainer: function indicatorsContainer(baseStyles, _ref3) {
      var selectProps = _ref3.selectProps;
      return _extends({}, baseStyles, {
        transition: 'all .2s ease',
        transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
      });
    },
    dropdownIndicator: function dropdownIndicator(baseStyles, _ref4) {
      var selectProps = _ref4.selectProps,
          isFocused = _ref4.isFocused;

      var baseDropdownIndicatorStyles = _extends({}, baseStyles, {
        color: 'inherit',
        padding: '8px 16px',
        '&:hover': {
          color: 'inherit'
        }
      });
      if (hasError && !isInline) {
        return _extends({}, baseDropdownIndicatorStyles, {
          color: selectProps.menuIsOpen || isFocused ? 'inherit' : error500,
          '&:hover': {
            color: selectProps.menuIsOpen || isFocused ? 'inherit' : error500
          }
        });
      }
      return baseDropdownIndicatorStyles;
    },
    clearIndicator: function clearIndicator(baseStyles) {
      return _extends({}, baseStyles, {
        cursor: 'pointer'
      });
    },
    option: function option(baseStyles, _ref5) {
      var isFocused = _ref5.isFocused,
          isSelected = _ref5.isSelected,
          data = _ref5.data;

      var baseOptionStyles = _extends({}, baseStyles, {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: isSelected ? 700 : 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: neutral600,
        background: (isFocused || isSelected) && neutral0,
        outline: isFocused && '1px solid ' + primary800,
        outlineOffset: '-1px',
        borderRadius: '6px',
        padding: '12px 24px',
        cursor: 'pointer',
        whiteSpace: isInline && 'nowrap',
        '&:hover': { color: primary800, background: primary50, outline: 'none' }
      });
      if (data.disabled) {
        return _extends({}, baseOptionStyles, {
          pointerEvents: 'none'
        });
      }
      if (isGroupedFilterDropdown) {
        var isSelectedOption = selectedOptions && selectedOptions.includes(data.value);
        return _extends({}, baseOptionStyles, {
          fontWeight: isSelectedOption ? 700 : 400
        });
      }
      return baseOptionStyles;
    },
    container: function container(baseStyles) {
      return _extends({}, baseStyles);
    },
    group: function group(baseStyles) {
      return _extends({}, baseStyles, {
        padding: '0'
      });
    },
    groupHeading: function groupHeading(baseStyles) {
      return _extends({}, baseStyles, {
        color: neutral700,
        fontSize: '16px',
        lineHeight: '24px',
        textTransform: 'capitalize',
        fontWeight: '700',
        margin: '0',
        padding: '16px 16px 4px'
      });
    },
    noOptionsMessage: function noOptionsMessage(baseStyles) {
      return _extends({}, baseStyles, {
        color: neutral700,
        fontSize: '16px',
        textAlign: 'left',
        marginLeft: '12px'
      });
    }
  };
};

export default FilterDropdownV2Styles;