import { Dispatch } from 'react';
import { Action } from 'redux';
import { isPendoInitializedSuccessAction } from '../redux/user/user.actions';

/* eslint-disable */
const injectPendoScript = (key: string): void => {
  (function(p, e) {
    const n = 'script';
    const d = 'pendo';
    let o: Record<string, any> = {};
    let v: string[];
    let w;
    let x;
    let y;
    let z;
    o = p[d] = p[d] || {};
    o._q = [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
    for (w = 0, x = v.length; w < x; ++w) {
      (function(m) {
        o[m] =
          o[m] ||
          function() {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(arguments, 0)),
            );
          };
      })(v[w]);
    }
    y = e.createElement(n);
    y.async = !0;
    y.src = `https://cdn.pendo.io/agent/static/${key}/pendo.js`;
    z = e.getElementsByTagName(n)[0];
    z.parentNode?.insertBefore(y, z);
  })(window, document);
};

interface PendoConfig {
  visitor: { id: string; email?: string };
  account: { id: string };
  events?: { ready?: () => void };
}

declare global {
  interface Window {
    pendo: {
      initialize: (config: PendoConfig) => void;
      identify: (config: PendoConfig) => void;
      getAccountId: () => string | null | undefined;
    };
  }
}

interface UserConfig {
  id: string;
  email?: string;
  collectionId: string;
}

interface UserInitConfig extends UserConfig {
  dispatch: Dispatch<Action>;
}

export const initAnalytics = (): void => {
  const KEY = process.env.REACT_APP_PENDO_KEY;
  if (!KEY) return;

  injectPendoScript(KEY);
};

export const identifyUser = ({ id, collectionId }: UserConfig): void => {
  if (!window.pendo) {
    return;
  }

  window.pendo.identify({
    visitor: { id },
    account: { id: collectionId },
  });
};

export const initializeUser = ({ id, collectionId, dispatch }: UserInitConfig) => {
  if (!window.pendo) {
    initAnalytics();
  }

  // If for some reason pendo agent failed to be injected
  // on first try (lines 80-82),
  // don't attempt to identify user
  if (!window.pendo) {
    return;
  }

  window.pendo.initialize({
    visitor: { id },
    account: { id: collectionId },
    events: {
      ready: () => {
        dispatch(isPendoInitializedSuccessAction(true));
      }
    }
  });
}
