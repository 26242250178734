import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Sidekick = function Sidekick(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg'
    },
    React.createElement('path', {
      d: 'M11.0887 5H3.20898L7.14886 12L3.20898 19H11.0887L15.0286 12L11.0887 5Z',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M15 4.25C14.5858 4.25 14.25 4.58579 14.25 5C14.25 5.41421 14.5858 5.75 15 5.75V4.25ZM15 18.25C14.5858 18.25 14.25 18.5858 14.25 19C14.25 19.4142 14.5858 19.75 15 19.75V18.25ZM15 5.75H20V4.25H15V5.75ZM20.25 6V18H21.75V6H20.25ZM20 18.25H15V19.75H20V18.25ZM20.25 18C20.25 18.1381 20.1381 18.25 20 18.25V19.75C20.9665 19.75 21.75 18.9665 21.75 18H20.25ZM20 5.75C20.1381 5.75 20.25 5.86193 20.25 6H21.75C21.75 5.0335 20.9665 4.25 20 4.25V5.75Z',
      fill: color
    })
  );
};

Sidekick.propTypes = propTypes;

export default Sidekick;