{
  "emptyState": {
    "title": "Share Stories with Galleries",
    "description": "Create a gallery to share the stories you've created. Once created, you can embed the gallery code in your website to show it off.",
    "graphicAlt": "A gallery showing four stories: iced coffee with cream being added, a cafe desk with writing implements and coffee, a cafe serving counter with a customer mid order, and a close-up of a small table with coffee, cake and a small cactus. ",
    "staticGalleryTitle": "No stories left!",
    "staticGalleryDesc": "Galleries must be a minimum of 2 stories.",
    "staticGalleryButton": "Add Stories",
    "galleryNoStoriesTitle": "No Stories, Yet!",
    "galleryNoStoriesDesc": "You'll need to have a few stories in order to create your gallery. Start with creating a story; you can use a mix of video, photography, text, and actionable links."
  },
  "button": {
    "createGallery": "Create Gallery"
  },
  "createMenu": {
    "staticGallery": "Manual Gallery",
    "staticGalleryDescription": "Manually select specific Stories and set their order of appearance.",
    "dynamicGallery": "Automatic Gallery",
    "dynamicGalleryDescription": "Automatically display Stories that match the tags set on the gallery.",
    "supportLinkDescription": "Learn more about gallery types"
  },
  "searchLabel": "Start typing to filter the gallery list and return search results",
  "searchAriaLabel": "Search for galleries by name. Press enter to submit.",
  "searchPlaceholder": "Search for gallery by name",
  "listGrid": {
    "name": "Gallery name",
    "nameAriaLabel": "Edit gallery named '${name}'",
    "campaign": "Campaign",
    "theme": "Theme",
    "location": "Location",
    "persona": "Persona",
    "any": "Any",
    "none": "None",
    "editGallery": "Edit",
    "copyEmbedCode": "Copy embed code",
    "deleteGallery": "Delete",
    "copyGallery": "Duplicate"
  },
  "form": {
    "nameLabel": "Name",
    "namePlaceholder": "Name gallery",
    "publisherLabel": "Publisher",
    "publisherPlaceholder": "Publisher",
    "creatorLabel": "Creator",
    "creatorPlaceholder": "Creator",
    "configureGallery": "Tags",
    "configureGalleryDescription": "Stories will show up in your gallery based on the tags selected below. When no tags are selected, any tag will match.",
    "campaignLabel": "Campaign",
    "campaignPlaceholder": "Select a campaign",
    "themeLabel": "Themes",
    "themePlaceholder": "Select up to four themes",
    "locationTypesLabel": "Location Type",
    "locationTypesPlaceholder": "Select a location type",
    "locationLabel": "Location",
    "locationPlaceholder": "Select up to one hundred locations",
    "personaLabel": "Persona",
    "marketsLabel": "Markets",
    "personaPlaceholder": " Select up to four personas",
    "newTabLabel": "Open gallery in a new tab",
    "createButton": "Create Gallery",
    "updateButton": "Update Gallery",
    "createSaveSuccess": "Gallery successfully ${type}",
    "failedSave": "There was an issue with the gallery, please try again",
    "failedCreate": "Something went wrong, please try again",
    "failedValidation": "Please fix errors in form to submit changes",
    "seasonsLabel": "Season",
    "themesLabel": "Theme",
    "durationsLabel": "Duration",
    "campaignDescription": "When selected, this gallery will only show stories tagged with that campaign.",
    "themeDescription": "Stories tagged with these themes will show up in this gallery. Stories that match more than one selected theme are more likely to appear.",
    "locationDescription": "Stories matching any of these locations will show up in this gallery.",
    "personaDescription": "Stories tagged with these personas will show up in this gallery. Stories that match more than one selected persona are more likely to appear.",
    "publisherSignalLabel": "Include stories created by:",
    "publisherSignalAnyLabel": "Any story creator in the network",
    "publisherSignalCurrentPublisherLabel": "${currentPublisher} only"
  },
  "preview": {
    "title": "Preview",
    "description": "Generate a sample group of stories that fit the selected configuration. The contents of the gallery are dynamic and each time it is loaded the stories algorithm may show different stories that fit your selection.",
    "clickGenerate": "Click Generate Preview button to generate gallery preview",
    "generate": "Generate Preview",
    "refresh": "Refresh Preview",
    "noResults": "There are no stories that match the current configuration",
    "failedToGenerate": "Failed to get gallery preview. Please try again.",
    "matchedStories": "Showing <b>${displayedCount}</b> of <b>${totalCount}</b> total ${matching} stories"
  },
  "embed": {
    "title": "Embed code",
    "description": "Insert the following code into your website where you would like this gallery to appear.",
    "descriptionVerbose": " Unsure of which code to copy? Most websites will require use of the full embed code. Ask your developer or IT person if they need the full embed code or just the gallery ID.",
    "copy": "Copy Embed Code",
    "copied": "Gallery embed code copied to clipboard",
    "copyId": "Copy gallery ID",
    "copyIdAriaLabel": "Click on button to copy the generated gallery id to clipboard",
    "copiedId": "Gallery ID copied to clipboard",
    "deleteGalleryAriaLabel": "Click on button to delete the gallery",
    "deletedGallery": "Gallery \"${galleryLabel}\" successfully deleted. ",
    "deletedGalleryUndo": "Undo",
    "copyGalleryAriaLabel": "Click on button to duplicate the gallery",
    "copiedGallery": "Gallery \"${galleryLabel}\" duplicated. ",
    "copiedGalleryURL": "Edit duplicated gallery",
    "error": "Something went wrong, please try again",
    "ariaLabel": "Embed code to be copied. Button to copy is below input."
  },
  "errors": {
    "oops": "Oops!",
    "generalMessage": "Something went wrong.",
    "galleryNotFound": "Gallery not found.",
    "refresh": "Please refresh the page and try again.",
    "noSearchResults": "Your search returned 0 results",
    "fail": "failed"
  },
  "menu": "gallery menu",
  "modal": {
    "deleteGalleryTitle": "Delete Gallery",
    "deleteGalleryContentDescription": "You're about to delete the gallery <strong>${galleryLabel}</strong>.",
    "deleteGalleryContentListIntro": "Once deleted:",
    "deleteGalleryContentListItem1": "The gallery will no longer display stories",
    "deleteGalleryContentListItem2": "It won't take up any space on pages where it's embedded",
    "deleteGalleryContentListItem3": "Its embed code will stay in the source code on any page it's embedded on until manually removed",
    "deleteGalleryKeepButton": "Keep Gallery",
    "deleteGalleryConfirmButton": "Delete Gallery"
  },
  "manualGalleries": {
    "general": {
      "previewTooltip": "What does a gallery with <b>${numStories} stories</b> look like?",
      "previewTooltipAriaLabel": "tooltip for previewing gallery layouts",
      "createSuccess": "The gallery ${galleryName} is created.",
      "createFailure": "Please try again.",
      "updateSuccess": "The changes to '${galleryName}' have been made.",
      "updateFailure": "The changes to '${galleryName}' were not saved. Please try again.",
      "preventNavigation": {
        "acceptText": "Leave",
        "cancelText": "Stay",
        "title": "Leave this page?",
        "description": "The gallery will not get saved if you leave before completing it. Be sure, this action cannot be reversed."
      }
    },
    "nameGallerySection": {
      "headline": "Let's get started",
      "description": "The name is for internal use only, to keep your galleries organized.",
      "inputPlaceHolder": "Name Gallery",
      "inputLabel": "Gallery Name",
      "inputAriaLabel": "set gallery name",
      "nextStepButton": "Select Stories",
      "maxLengthErrorMsg": "Error: Max character length is 60",
      "requiredErrorMsg": "Galleries must have a name.",
      "title": "Name Gallery"
    },
    "selectStoriesSection": {
      "headline": "Select Stories",
      "description": "Only published stories are listed below.",
      "nextStepButton": "Review Selection",
      "toolTipDescription": "Did you know?",
      "toolTipContent": "Selecting a minimum of 4 stories is optimal for the gallery's appearance and may increase index performance.",
      "toolTipAriaLabel": "tip for selecting stories",
      "storiesSelected": "stories selected",
      "storiesSelectedErrorTooMany": "Select a maximum of ${numStories} stories to create a gallery.",
      "storiesSelectedErrorTooFew": "Select a minimum of 2 stories to create a gallery.",
      "requiredErrorMsg": "Error: Gallery name is required",
      "title": "Select Stories"
    },
    "editStoriesSection": {
      "title": "Review Selection",
      "headline": "Review Selection",
      "nextStepButton": "Complete Gallery",
      "actionDescription": "Rearrange the order, remove, or add different stories from the previous step.",
      "copyEmbedButton": "Copy Embed Code",
      "copyEmbedButtonSuccess": "Copied!"
    },
    "galleryDetails": {
      "updateButton": "Update Gallery",
      "nameLabel": "Gallery Name",
      "requiredNameError": "Galleries must have a name.",
      "updateGalleryStories": {
        "confirmButton": "Save Changes",
        "description": "Select a maximum of ${numStories} stories to include in your gallery."
      },
      "storyListTitle": "Story Selection",
      "storyListDescription": "Rearrange the order, remove, or add different stories from the previous step.",
      "storyListActionName": "Edit Story Selection",
      "minimumStoryWarning": "Galleries must be a minimum of two stories."
    }
  }
}
