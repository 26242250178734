import { ThunkAction } from 'redux-thunk';
import axios from './axios_config';
import { GalleryCreateEditResponse } from './gallery_create.thunks';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import {
  DynamicGalleryFormSubmitData,
  TransformedGalleryFormData,
} from '../react/features/gallery_form/gallery_form.types';
import {
  galleryEditStartAction,
  galleryEditSuccessAction,
  galleryEditFailedAction,
} from '../redux/galleries/gallery_form.actions';
import { transformGalleryFormData } from '../react/features/gallery_form/gallery_form.utils';
import { AppState, AppActions } from '../types';
import {
  selectCurrentNetworkId,
  selectCurrentPublisherId,
} from '../redux/user/user.selectors';
import {
  selectGalleriesToEdit,
  selectStaticGalleryLabelForEdit,
  selectStaticGalleryStoriesForEdit,
} from '../redux/galleries/galleries.selectors';
import {
  staticGalleryEditFailureAction,
  staticGalleryEditStartAction,
  staticGalleryEditSuccessAction,
} from '../redux/galleries/galleries.actions';
import { getErrorMessage } from '../utils';

type EditGalleryThunkAction = ThunkAction<
  Promise<GalleryCreateEditResponse>,
  AppState,
  {},
  AppActions
>;

type EditDynamicGalleryThunk = (
  formData: DynamicGalleryFormSubmitData | undefined,
) => EditGalleryThunkAction;

type EditStaticGalleryThunk = () => EditGalleryThunkAction;

export const getGalleryEditUrl = (state: AppState) => {
  const networkId = selectCurrentNetworkId(state);
  const publisherId = selectCurrentPublisherId(state);
  const galleryId = selectGalleriesToEdit(state)?.id ?? '';
  return urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.GALLERIES,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
      galleryId,
    },
  });
};

export const editDynamicGallery: EditDynamicGalleryThunk = (
  formData: DynamicGalleryFormSubmitData | undefined,
) => async (dispatch, getState) => {
  if (typeof formData === 'undefined') {
    throw new Error(`Failed to edit gallery: Problem with formData`);
  }
  const state: AppState = getState();
  const publisherId = selectCurrentPublisherId(state);
  const reqBody: { gallery: TransformedGalleryFormData } = {
    gallery: {
      ...transformGalleryFormData(formData, publisherId),
    },
  };
  const url = getGalleryEditUrl(state);
  dispatch(galleryEditStartAction());
  try {
    const res = await axios.put<GalleryCreateEditResponse>(url, reqBody);

    dispatch(galleryEditSuccessAction(res.data));
    return res.data;
  } catch (err) {
    dispatch(galleryEditFailedAction(err));
    throw new Error(`Failed to edit gallery: ${getErrorMessage(err)}`);
  }
};

export const editStaticGallery: EditStaticGalleryThunk = () => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const id = selectGalleriesToEdit(state)?.id ?? '';
  const url = getGalleryEditUrl(state);
  const galleryEdits = {
    label: selectStaticGalleryLabelForEdit(state),
    story_ids: selectStaticGalleryStoriesForEdit(state)?.map(
      (story) => story.uuid,
    ),
  };

  dispatch(staticGalleryEditStartAction());
  try {
    const res = await axios.put<GalleryCreateEditResponse>(url, {
      gallery: {
        id,
        ...galleryEdits,
      },
    });

    dispatch(staticGalleryEditSuccessAction(res.data.gallery));
    return res.data;
  } catch (err) {
    dispatch(staticGalleryEditFailureAction(err));
    throw new Error(`Failed to edit gallery: ${getErrorMessage(err)}`);
  }
};
