import React, { VoidFunctionComponent } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { selectCurrentPublisherId } from '../../../../redux/user/user.selectors';
import {
  EmptyState,
  EmptyStateStaticProps,
} from '../../../blocks/empty_state/empty_state.component';
import { BUTTON_VARIANT } from '../../../blocks/button';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import exampleStoriesImageSrc from '../../../../assets/example-stories.jpg';

export interface StoryListEmptyStateProps extends LocalizeContextProps {
  className?: string;
  title: string;
  description: string;
}
const StoryListEmptyStateComponent: VoidFunctionComponent<StoryListEmptyStateProps> = ({
  className,
  title,
  description,
  translate,
}) => {
  const getTranslation = useTranslateStrings(translate);
  const publisherId = useSelector(selectCurrentPublisherId);
  const url: string = `${
    process.env.STORY_CREATOR_URL
  }/editor/new?publisher-slug=${encodeURIComponent(publisherId)}`;

  const emptyStateCommonProps: EmptyStateStaticProps = {
    title,
    description,
    className,
    graphic: exampleStoriesImageSrc,
    graphicAlt: getTranslation('stories.emptyState.graphicAlt'),
    actionVariant: BUTTON_VARIANT.SOLID,
    buttonText: getTranslation('stories.emptyState.button'),
    onClick: () => window.location.assign(url),
  };

  return <EmptyState {...emptyStateCommonProps} />;
};

export const StoryListEmptyState = withLocalize(StoryListEmptyStateComponent);
