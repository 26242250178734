import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Network = function Network(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'network',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('polygon', {
      points: '618.7,685.4 561.4,471.8 717.8,315.4 800.8,398.3 1000,199.2 800.8,0 601.7,199.2 684.6,282.2 528.2,438.6 314.6,381.3 345,267.9 72.9,195 0,467.1 272.1,540 302.5,426.7 516.1,483.9 573.3,697.5 460,727.9 532.9,1000 805,927.1 732.1,655' // eslint-disable-line max-len
    })
  );
};

Network.propTypes = propTypes;

export default Network;