import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { AppState } from '../../../../types';
import { GalleryListGridCardDeleteModalComponent } from './gallery_list_grid_card_delete_modal.component';

const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.localize),
});

export const GalleryListGridCardDeleteModal = connect(mapStateToProps)(
  GalleryListGridCardDeleteModalComponent,
);
