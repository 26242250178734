import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var ShareGallery = function ShareGallery(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'ShareGallery__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 14 16',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M9.625 4.375V0.875H13.125V4.375H9.625ZM14 0H8.75V5.25H14V0ZM5.25 0H0V5.25H5.25V0ZM13.812 12.0926L10.718 15.1866L10.0991 14.5678L12.1362 12.5308H0V11.6558H12.1362L10.0991 9.61877L10.718 9L13.812 12.0926Z' // eslint-disable-line max-len
    })
  );
};

ShareGallery.propTypes = propTypes;

export default ShareGallery;