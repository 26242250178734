import { createSelector } from 'reselect';
import { STORY_SEARCH_LOAD_TYPES } from '../../http/stories_search.thunks';
import { createOptions } from '../../react/features/gallery_form/gallery_form.utils';
import { DynamicFilterType } from '../../react/features/stories_search_dynamic_filters/stories_search_dynamic_filters.types';
import { StaticFilterMap } from '../../react/features/stories_search_form.component/stories_search_form.types';
import { Story, STORY_STATUS } from '../../react/features/story/story.types';
import {
  DISPLAY_DATE_TYPES,
  displayDate,
} from '../../react/pages/stories_manage/story_manage.utils';
import {
  DefaultFormVals,
  STORY_MANAGE_FIELDS,
} from '../../react/pages/stories_manage/story_manage.types';
import { AppState } from '../../types';
import { GLOBAL_TAG_TYPES } from '../global_tags/global_tags.types';
import { NETWORK_TAG_TYPES } from '../network_tags/network_tags.types';
import { StoriesState, SearchFilters, StoriesFilterTab } from './stories.types';
import { FEATURE_FLAGS } from '../../react/blocks/authwrapper/auth_wrapper.types';
import { userHasAccess } from '../../react/blocks/authwrapper/auth_wrapper.utils';
import { selectCurrentPublisherFeatureFlags } from '../user/user.selectors';

export const getStoriesState = (state: AppState): StoriesState => state.stories;

export const selectStoriesIsLoading = (state: AppState): boolean =>
  state.stories.isLoading;

export const selectStoriesLoadingType = (
  state: AppState,
): STORY_SEARCH_LOAD_TYPES | null => state.stories.loadingType;

export const selectStoriesSearchStaticFilterMap = (
  state: AppState,
): StaticFilterMap | null | undefined =>
  state.stories.searchFilters?.staticFilterMap ?? null;

export const selectStoriesSearchDynamicFilterMap = (
  state: AppState,
): DynamicFilterType[] | null =>
  state.stories.searchFilters?.dynamicFilterMap ?? null;

export const selectStoriesSearchFilters = (
  state: AppState,
): SearchFilters | null => state.stories.searchFilters ?? null;

export const selectAllStories = createSelector(
  getStoriesState,
  (storiesState): Story[] => storiesState.data,
);

export const selectAllPublishedStories = createSelector(
  getStoriesState,
  (storiesState) =>
    storiesState.data.filter((story) => {
      return story.status === STORY_STATUS.PUBLISHED;
    }),
);

export const selectStoriesListNextPageToken = (
  state: AppState,
): string | null => state.stories.nextPageToken;

export const selectStoriesSearchQuery = (state: AppState): string =>
  state.stories.searchQuery ?? '';

export const selectStoryToManage = (state: AppState): Story | null =>
  state.stories.storyToManage;

export const selectStoryToManageDefaultVals = createSelector(
  selectStoryToManage,
  (story): DefaultFormVals => {
    return {
      [STORY_MANAGE_FIELDS.CAMPAIGN]: createOptions(
        (story?.networkTags ?? []).filter(
          (tag) => tag.type === NETWORK_TAG_TYPES.CAMPAIGN,
        ),
      ),
      [STORY_MANAGE_FIELDS.PERSONAS]: createOptions(
        (story?.networkTags ?? []).filter(
          (tag) => tag.type === NETWORK_TAG_TYPES.PERSONA,
        ),
      ),
      [STORY_MANAGE_FIELDS.THEMES]: createOptions(
        (story?.globalTags ?? []).filter(
          (tag) => tag.type !== GLOBAL_TAG_TYPES.SEASON,
        ),
      ),
      [STORY_MANAGE_FIELDS.SEASONS]: createOptions(
        (story?.globalTags ?? []).filter(
          (tag) => tag.type === GLOBAL_TAG_TYPES.SEASON,
        ),
      ),
      [STORY_MANAGE_FIELDS.EXPIRATION]: story?.expiredAt
        ? `${displayDate(story.expiredAt, DISPLAY_DATE_TYPES.TIMEZONE)}`
        : null,
    };
  },
);

export const selectStoriesFilterTab = createSelector(
  getStoriesState,
  (storiesState) => storiesState.storiesFilterTab,
);

export const selectStoriesFilterTabDefaultValue = createSelector(
  selectCurrentPublisherFeatureFlags,
  (currentPublisherFeatureFlags) => {
    const showCreatorContent = userHasAccess(
      currentPublisherFeatureFlags,
      FEATURE_FLAGS.SHOW_CREATOR_CONTENT,
    );

    return showCreatorContent
      ? StoriesFilterTab.MY_NETWORK
      : StoriesFilterTab.ALL;
  },
);
