import { connect } from 'react-redux';
import { AppState } from '../../../../types';
import { SelectStoriesSection } from './select_stories_section.component';
import { selectCurrentGalleryStories } from '../../../../redux/galleries/galleries.selectors';

const mapStateToProps = (state: AppState) => ({
  selectedStories: selectCurrentGalleryStories(state) ?? [],
});

const mapDispatchToProps = () => ({});

export const SelectStoriesSectionConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectStoriesSection);
