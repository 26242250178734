import React, { FunctionComponent } from 'react';
import { Spinner } from '@crowdriff/flock';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import clsx from 'clsx';
import './detailed_story.scss';
import { Story as StoryComponent } from '../story/story.component';
import { PublisherLabel } from '../publisher_label/publisher_label';
import { PUBLISHER_ROLES } from '../publisher_label/publisher_label.types';
import { Story } from '../story/story.types';
import { DetailedStoryMenu } from './detailed_story_menu';
import { StoryStats } from '../story_stats/story_stats.component';
import { HOVER_POSITIONS } from '../story_list/story_list.types';
import { MenuItem } from '../../blocks/menu';

export interface DetailedStoryProps extends LocalizeContextProps {
  story: Story;
  previewSrc: string;
  currentPublisherId: string;
  includeDetailsContextMenu?: boolean;
  onHover?: (mousePlacement: string) => void;
  extraMenuItems?: MenuItem[];
  loading?: boolean;
  onStatOpen?: () => void;
  onStatClose?: () => void;
}

export const DetailedStoryComponent: FunctionComponent<DetailedStoryProps> = ({
  children,
  story,
  previewSrc,
  currentPublisherId,
  includeDetailsContextMenu = true,
  onHover,
  extraMenuItems,
  loading = false,
  onStatOpen,
  onStatClose,
}) => {
  const { publisher, image, title, status } = story;

  return (
    <div
      className={clsx('detailed_story', {
        disable_story_click: loading,
      })}
    >
      {loading && <Spinner color="blue" />}
      <div
        className={clsx('detailed_story__visual', {
          detailed_story__loading_story_state: loading,
        })}
      >
        <StoryComponent
          storySrc={image.url}
          title={title}
          publisher={{
            logo: publisher.logoSrc,
            name: publisher.name,
          }}
          previewSrc={previewSrc}
          status={status}
          onHover={onHover}
          linkClass="detailed_story__story_component"
        >
          {children}
        </StoryComponent>
      </div>

      <div
        className="detailed_story--content"
        onMouseEnter={() => onHover?.(HOVER_POSITIONS.IN)}
        onMouseLeave={() => onHover?.(HOVER_POSITIONS.OUT)}
      >
        <div className="detailed_story--content--publisher">
          <PublisherLabel
            avatarSrc={publisher.logoSrc}
            name={publisher.name}
            type={PUBLISHER_ROLES.PUBLISHER}
          />
          {includeDetailsContextMenu && (
            <DetailedStoryMenu
              story={story}
              previewSrc={previewSrc}
              currentPublisherId={currentPublisherId}
              publisherId={publisher.slug}
              status={story.status}
              extraMenuItems={extraMenuItems}
              isChallengeStory={story.is_challenge_story ?? false}
            />
          )}
        </div>
        <StoryStats
          orientation="horizontal"
          story={story}
          onStatOpen={onStatOpen}
          onStatClose={onStatClose}
        />
      </div>
    </div>
  );
};

export const DetailedStory = withLocalize(DetailedStoryComponent);
