/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var RightsSent = function RightsSent(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 858 1000',
      fill: color,
      height: size,
      'aria-hidden': true,
      className: 'cr__icon rightsSent',
      id: 'rightsSent__icon'
    },
    React.createElement('path', { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M714.508 398.759H714.714V142.774H143.286V541.616C143.286 608.997 194.522 683.735 287.493 756.181C330.11 789.39 374.066 816.179 408.817 835.205C416.024 839.151 422.761 842.723 428.924 845.91C435.065 842.735 441.778 839.178 448.958 835.249C483.695 816.238 527.649 789.457 570.269 756.251C663.242 683.812 714.508 609.049 714.508 541.616V398.759ZM857.365 541.616H857.571V41.6162C857.571 18.8162 838.133 -0.0835571 814.683 -0.0835571H43.3164C19.8669 -0.0835571 0.428467 18.8162 0.428467 41.6162V541.616C0.428467 815.616 394.751 988.817 411.516 996.017C416.864 998.617 422.829 999.916 428.897 999.916C434.862 999.916 440.93 998.617 446.279 996.017C462.94 988.917 857.365 815.716 857.365 541.616Z' })
  );
};

RightsSent.propTypes = propTypes;

export default RightsSent;