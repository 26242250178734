import './story_embed_text_area.scss';
import React, { useRef, VoidFunctionComponent } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { Text, TEXT_VARIANTS } from '../../../../blocks/text';
import { useTranslateStrings } from '../../../../hooks/useTranslateStrings';
import { generateEmbedCode } from '../story_embed.utils';

interface StoryEmbedTextProps extends LocalizeContextProps {
  storyId: string;
  isDraft: boolean;
}
const StoryEmbedTextAreaComponent: VoidFunctionComponent<StoryEmbedTextProps> = ({
  storyId,
  isDraft = false,
  translate,
}) => {
  const getTranslation = useTranslateStrings(translate);
  const embedCode = generateEmbedCode(storyId);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <>
      {!isDraft ? (
        <div className="story_embed_text_area">
          <Text
            className="story_embed_text_area__title"
            variant={TEXT_VARIANTS.H3}
          >
            {getTranslation('stories.embed.textarea.title')}
          </Text>
          <Text
            className="story_embed_text_area__description"
            variant={TEXT_VARIANTS.B2}
          >
            {getTranslation('stories.embed.textarea.description')}
          </Text>
          <textarea
            aria-label={getTranslation('stories.embed.ariaLabel')}
            ref={textAreaRef}
            readOnly={true}
            rows={26}
            value={embedCode}
          />
        </div>
      ) : null}
    </>
  );
};

export const StoryEmbedTextArea = withLocalize(StoryEmbedTextAreaComponent);
