import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.oneOf(['medium', 'large', 'small'])
};

var propDescriptions = {
  size: "The size of the loader logo. Will accept: 'small', 'medium', or 'large'"
};

var defaultProps = {
  size: 'medium'
};

var Loader = function (_PureComponent) {
  _inherits(Loader, _PureComponent);

  function Loader(props) {
    _classCallCheck(this, Loader);

    var _this = _possibleConstructorReturn(this, (Loader.__proto__ || _Object$getPrototypeOf(Loader)).call(this, props));

    _this.setColour = _this.setColour.bind(_this);
    _this.state = {
      colour: 'pink',
      stage: 'clean'
    };
    return _this;
  }

  _createClass(Loader, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      this.loadColour = setInterval(this.setColour, 200);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      clearInterval(this.loadColour);
      this.loadColour = false;
    }
  }, {
    key: 'getSize',
    value: function getSize(size) {
      var sizeMap = {
        medium: 'scale(1, 1)',
        large: 'scale(1.5, 1.5)',
        small: 'scale(0.5, 0.5)'
      };
      return sizeMap[size];
    }
  }, {
    key: 'getNewColour',
    value: function getNewColour(oldColour) {
      var colourMap = {
        pink: 'blue',
        blue: 'green',
        green: 'pink'
      };
      return colourMap[oldColour];
    }
  }, {
    key: 'setColour',
    value: function setColour() {
      var opacity = window.getComputedStyle(this.top).opacity.opacity;
      var stage = this.state.stage;

      if (this.top && opacity === '0' && stage === 'clean') {
        var newColour = this.getNewColour(this.state.colour);
        this.setState({
          colour: newColour,
          stage: 'unclean'
        });
      } else if (stage === 'unclean' && opacity !== '0') {
        this.setState({
          stage: 'clean'
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var svgSize = this.getSize(this.props.size);
      var svgColour = this.state.colour;
      return React.createElement(
        'div',
        { className: 'logoWrap ' + this.props.size },
        React.createElement(
          'svg',
          { className: 'logo ' + svgColour, height: '100%', width: '100%' },
          React.createElement(
            'g',
            { transform: svgSize },
            React.createElement('polygon', {
              points: '0,0 50.8,0 25.4,44',
              className: 'loader__1'
            }),
            React.createElement('polygon', {
              points: '25.4,44 76.2,44 50.8,0',
              className: 'loader__2'
            }),
            React.createElement('polygon', {
              points: '0,88 50.8,88 25.4,44',
              className: 'loader__4',
              ref: function ref(c) {
                _this2.top = c;
              }
            }),
            React.createElement('polygon', {
              points: '25.4,44 76.2,44 50.8,88',
              className: 'loader__3'
            })
          )
        )
      );
    }
  }]);

  return Loader;
}(PureComponent);

export default Loader;


Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;
Loader.propDescriptions = propDescriptions;