import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var MediaImageLandscape = function MediaImageLandscape(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg'
    },
    React.createElement('path', {
      d: 'M2 18.4V5.6C2 5.26863 2.26863 5 2.6 5H21.4C21.7314 5 22 5.26863 22 5.6V18.4C22 18.7314 21.7314 19 21.4 19H2.6C2.26863 19 2 18.7314 2 18.4Z',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M2.5 16.5L10 13L21 18',
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    }),
    React.createElement('path', {
      d: 'M17 12C15.8954 12 15 11.1046 15 10C15 8.89543 15.8954 8 17 8C18.1046 8 19 8.89543 19 10C19 11.1046 18.1046 12 17 12Z',
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    })
  );
};

MediaImageLandscape.propTypes = propTypes;

export default MediaImageLandscape;