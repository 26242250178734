import { ThunkDispatch } from 'redux-thunk';
import { LocalizeState } from 'react-localize-redux';
import {
  GalleriesState,
  StaticGalleryActions,
} from '../redux/galleries/galleries.types';
import { GalleriesActions } from '../react/pages/galleries_list/galleries_list.types';
import { UserActions, UserState } from '../redux/user/user.types';
import { GalleryCreateActions } from '../react/pages/galleries_create/gallery_create.types';
import { GalleryEditActions } from '../react/pages/galleries_edit/gallery_edit.types';
import {
  GalleryDeleteActions,
  GalleryUndeleteActions,
  GalleryGetActions,
  GalleryCopyActions,
} from '../redux/galleries/galleries.actions';
import {
  LocationState,
  LocationActions,
} from '../redux/locations/locations.types';
import {
  GlobalTagState,
  GlobalTagActions,
} from '../redux/global_tags/global_tags.types';
import {
  NetworkTagState,
  NetworkTagActions,
} from '../redux/network_tags/network_tags.types';
import { GalleryPreviewActions } from '../react/features/gallery_preview/gallery_preview.types';
import { StoriesState, StoriesActions } from '../redux/stories/stories.types';
import { StoriesListActions } from '../react/pages/stories_list/stories_list.types';
import { StoriesManageActions } from '../react/pages/stories_manage/story_manage.types';
import { NetworkActions, NetworkState } from '../redux/network/network.types';
import { ExportsState, ExportsActions } from '../redux/exports/exports.types';

export enum ROUTES {
  HOME = '/',
  GALLERIES_LIST = '/galleries',
  GALLERIES_CREATE = '/galleries/create',
  GALLERIES_EDIT = '/galleries/:id',
  STORIES_LIST = '/stories',
  STORIES_MANAGE = '/stories/:story_uuid',
  ADMIN = '/admin',
  NOT_FOUND = '*',
  ACCOUNT_SETTINGS = '/account',
}

export enum EXTERNAL_ROUTES {
  GALLERY_SUPPORT_LINK = 'https://support.crowdriff.com/hc/en-us/articles/6444660534807',
}

export interface AppState {
  galleries: GalleriesState;
  localize: LocalizeState;
  network: NetworkState;
  taxonomy: {
    globalTags: GlobalTagState;
    locations: LocationState;
    networkTags: NetworkTagState;
  };
  user: UserState;
  stories: StoriesState;
  exports: ExportsState;
}
export type AppActions =
  | GalleriesActions
  | GalleryCreateActions
  | GalleryEditActions
  | GalleryCopyActions
  | GalleryGetActions
  | GalleryPreviewActions
  | GalleryDeleteActions
  | GalleryUndeleteActions
  | GlobalTagActions
  | StaticGalleryActions
  | LocationActions
  | NetworkActions
  | NetworkTagActions
  | StoriesActions
  | StoriesListActions
  | StoriesManageActions
  | UserActions
  | ExportsActions;

export type AppThunkDispatch = ThunkDispatch<AppState, {}, AppActions>;
