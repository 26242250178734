import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import Err from '../types/err';
import { getErrorMessage } from '../utils';
import { ChallengeStatus } from '../react/features/challenges/challenges.constants';

export interface Challenge {
  id: string;
  label: string;
  status: string;
}

export interface ListChallengesRequest {
  networkId: string;
  userInternal: boolean;
  status?: ChallengeStatus;
  include_creator_limit_reached?: boolean;
}

/**
 * retrieves all challenges pertaining to a network. If the user is internal, it will
 * retrieve all challenges regardless of network.
 * @param networkId Id of the network
 * @param userInternal Boolean that tells if the user is internal or not
 * @param include_creator_limit_reached Boolean that tells if should include full challenges
 * @returns List of challenges
 */
export const listChallenges = async ({
  networkId,
  userInternal,
  status,
  include_creator_limit_reached = true,
}: ListChallengesRequest) => {
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.CHALLENGES_LIST,
    params: {
      networkId: userInternal ? '-' : networkId,
    },
  });
  try {
    const response: AxiosResponse = await axios.get(url, {
      params: {
        page_size: '100',
        status,
        include_creator_limit_reached,
      },
    });
    return response.data.challenges.map((challenge: Challenge) => {
      const challengeRes: Challenge = {
        id: challenge.id,
        label: challenge.label,
        status: challenge.status,
      };

      return challengeRes;
    });
  } catch (err) {
    throw new Err(
      `Failed to get challenges:  ${getErrorMessage(err)}`,
      'Challenges not found.',
    );
  }
};
