import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var MobileColumn = function MobileColumn(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'MobileColumn__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 589.7 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M359,358.9V0h230.7v358.9H359z M307.7,256.4V0H0v256.4H307.7z M307.7,604.2V307.7H0v296.5H307.7z M589.7,690.5V410.2H359v280.3H589.7z M589.7,945.1V741.8H359v203.3H589.7z M307.7,1000V655.5H0V1000H307.7z' // eslint-disable-line max-len
    })
  );
};

MobileColumn.propTypes = propTypes;

export default MobileColumn;