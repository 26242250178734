import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Slideshow = function Slideshow(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Slideshow__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1533.3 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1533.3,1000h-466.7V0h466.7V1000z M1000,0v1000H0V0H1000z M33.3,966.7H200l178.8-214.7l100.1,100l275.2-275L966.7,750\t\tV33.3H33.3V966.7z M323.1,414.7c-51.8,0-93.7,41.9-93.7,93.6s42,93.6,93.7,93.6s93.7-41.9,93.7-93.6S374.9,414.7,323.1,414.7z' // eslint-disable-line max-len
    })
  );
};

Slideshow.propTypes = propTypes;

export default Slideshow;