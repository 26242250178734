import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired
};

var propDescriptions = {
  options: 'An array of objects containing "id", "label", and "value" for each radio toggle.',
  onChange: 'Function to pass the selected option back up to the parent component',
  className: 'Optional classname for the radio list',
  name: 'Unique name to group the radio buttons'
};

var defaultProps = {
  className: ''
};

var RadioToggle = function (_React$Component) {
  _inherits(RadioToggle, _React$Component);

  function RadioToggle(props) {
    _classCallCheck(this, RadioToggle);

    var _this = _possibleConstructorReturn(this, (RadioToggle.__proto__ || _Object$getPrototypeOf(RadioToggle)).call(this, props));

    _this.handleChange = _this.handleChange.bind(_this);
    return _this;
  }

  _createClass(RadioToggle, [{
    key: 'handleChange',
    value: function handleChange(e) {
      this.props.onChange(e.target.value);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          className = _props.className,
          options = _props.options,
          name = _props.name,
          value = _props.value;


      return React.createElement(
        'div',
        { className: 'radioToggle ' + className },
        options.map(function (option) {
          return React.createElement(
            'div',
            { className: 'radioToggle__toggle', key: option.id },
            React.createElement('input', {
              type: 'radio',
              id: option.id,
              onChange: _this2.handleChange,
              name: name,
              value: option.value,
              className: 'radioToggle__input',
              checked: option.value === value
            }),
            React.createElement(
              'label',
              {
                className: 'radioToggle__label',
                htmlFor: option.id
              },
              option.label
            )
          );
        })
      );
    }
  }]);

  return RadioToggle;
}(React.Component);

export default RadioToggle;


RadioToggle.propTypes = propTypes;
RadioToggle.defaultProps = defaultProps;
RadioToggle.propDescriptions = propDescriptions;