import { MouseEventHandler } from 'react';
import { ControlError } from '../controls.types';

export interface CheckboxProps {
  variant?: string;
  label?: string;
  id: string;
  name: string;
  isChecked: boolean;
  className?: string;
  isDisabled?: boolean;
  onCheckboxClick?: MouseEventHandler<HTMLInputElement>;
  alternateHoverState?: boolean;
  required?: string | boolean;
  invalid?: boolean;
  error?: ControlError;
  description?: string;
  onChange?: (e: unknown) => void;
}

export enum CHECKBOX_VARIANTS {
  SQUARE = 'square',
  CIRCLE = 'circle',
}
