import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Share = function Share(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'share__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1000,791.7c0,115.2-93.1,208.3-208.3,208.3s-208.3-93.1-208.3-208.3c0-7.2,0.7-15,1.3-22.1L350.3,652.3c-37.1,34.5-87.2,56-141.9,56C93.1,708.3,0,615.2,0,500s93.1-208.3,208.3-208.3c54.7,0,104.8,21.5,141.9,56l234.4-117.2c-0.7-7.2-1.3-15-1.3-22.1C583.3,93.1,676.4,0,791.7,0S1000,93.1,1000,208.3s-93.1,208.3-208.3,208.3c-54.7,0-104.8-21.5-141.9-56L415.4,477.9c0.7,7.2,1.3,15,1.3,22.1s-0.7,15-1.3,22.1l234.4,117.2c37.1-34.5,87.2-56,141.9-56C906.9,583.3,1000,676.4,1000,791.7z' // eslint-disable-line max-len
    })
  );
};

Share.propTypes = propTypes;

export default Share;