import { useReducer } from 'react';

export enum ACTION_TYPES {
  'TOGGLE' = 'toggle',
  'SET_TRUE' = 'setTrue',
  'SET_FALSE' = 'setFalse',
}

interface ToggleAction {
  type: ACTION_TYPES;
}

export const useToggle = (initialState: boolean) => {
  const toggleReducer = (state: boolean, action: ToggleAction) => {
    switch (action.type) {
      case ACTION_TYPES.TOGGLE:
        return !state;
      case ACTION_TYPES.SET_TRUE:
        return true;
      case ACTION_TYPES.SET_FALSE:
        return false;
      default:
        throw new Error();
    }
  };

  const [toggleState, dispatch] = useReducer(toggleReducer, initialState);

  const SetStateToggle = () => dispatch({ type: ACTION_TYPES.TOGGLE });

  const SetStateTrue = () => dispatch({ type: ACTION_TYPES.SET_TRUE });

  const SetStateFalse = () => dispatch({ type: ACTION_TYPES.SET_FALSE });

  return { toggleState, SetStateToggle, SetStateTrue, SetStateFalse };
};
