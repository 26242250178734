import React from 'react';
import { IconProps } from '../icon.types';

export const CheckmarkCircleFilled = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 18.4736C14.6318 18.4736 18.4668 14.6304 18.4668 10.0068C18.4668 5.375 14.6235 1.54004 9.9917 1.54004C5.36816 1.54004 1.5332 5.375 1.5332 10.0068C1.5332 14.6304 5.37646 18.4736 10 18.4736ZM9.07031 14.0659C8.78809 14.0659 8.55566 13.9497 8.33984 13.6592L6.25635 11.1025C6.13184 10.9365 6.05713 10.7539 6.05713 10.563C6.05713 10.1895 6.34766 9.88232 6.72119 9.88232C6.96191 9.88232 7.14453 9.95703 7.35205 10.231L9.03711 12.4058L12.5815 6.71143C12.7393 6.4624 12.9551 6.32959 13.1709 6.32959C13.5361 6.32959 13.8765 6.57861 13.8765 6.96875C13.8765 7.15137 13.7686 7.34229 13.6689 7.5166L9.76758 13.6592C9.59326 13.9331 9.35254 14.0659 9.07031 14.0659Z" />
    </svg>
  );
};
