import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Theme = function Theme(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Theme__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 998.9 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M394,577c14.5,28.3,34.3,52.5,59.4,72.5c25.1,20.1,53.1,34.2,84,42.4l0.6,39.6c1.5,79.2-22.6,143.8-72.3,193.6\t\tC416,975.1,351.2,1000,271.2,1000c-45.8,0-86.3-8.6-121.7-25.9c-35.3-17.3-63.7-41-85.1-71.1C43.1,872.8,27,838.7,16.2,800.8\t\tC5.4,762.8,0,721.9,0,678c2.6,1.9,10.2,7.4,22.9,16.7c12.6,9.3,24.2,17.6,34.6,24.8c10.4,7.3,21.4,14,32.9,20.4\t\tc11.5,6.3,20.1,9.5,25.7,9.5c15.2,0,25.5-6.9,30.7-20.6c9.3-24.6,20-45.5,32.1-62.8c12.1-17.3,25-31.4,38.8-42.4\t\tc13.8-11,30.1-19.8,49.1-26.5c19-6.7,38.1-11.4,57.5-14.2C343.6,580.1,366.8,578.1,394,577z M901.2,0c26,0,48.8,8.6,68.4,25.9\t\tc19.5,17.3,29.3,39,29.3,65c0,23.4-8.4,51.5-25.1,84.3c-123.5,234-210,373.9-259.5,419.6c-36.1,33.9-76.6,50.8-121.7,50.8\tc-46.9,0-87.1-17.2-120.8-51.6c-33.7-34.4-50.5-75.2-50.5-122.5c0-47.6,17.1-87.1,51.3-118.3l356-323.1C850.6,10,874.8,0,901.2,0z' // eslint-disable-line max-len
    })
  );
};

Theme.propTypes = propTypes;

export default Theme;