import { ThunkDispatch } from 'redux-thunk';
import { AppActions, AppState } from '../types';

export enum HTTP_STATUS {
  OK = 'OK',
}

export enum STORY_NETWORK_GATEWAY_URLS {
  GALLERIES = '/v1/networks/:networkId/publishers/:publisherId/galleries/:galleryId',
  GALLERIES_COPY = '/v1/networks/:networkId/publishers/:publisherId/galleries/:galleryId/copy',
  GALLERIES_UNDELETE = '/v1/networks/:networkId/publishers/:publisherId/galleries/:galleryId/undelete',
  GALLERIES_PREVIEW = '/v3/networks/:networkId/publishers/-/galleries/-/stories:preview',
  GLOBAL_TAGS = '/v1/networks/:networkId/globalTags',
  LOCATIONS = '/v1/networks/:networkId/locations',
  LOCATIONS_TYPES = '/v1/networks/:networkId/locations:listTypes',
  LOGOUT = '/v1/users/-/logout',
  ME = '/v1/me',
  NETWORK_TAGS = '/v1/networks/:networkId/networkTags',
  NETWORK_PUBLISHERS_LIST = '/v1/networks/:networkId/publishers',
  NETWORKS_LIST = '/v1/networks',
  PUBLISHERS = '/v2/networks/-/publishers:batchGet',
  PUBLISHERS_FEATURE_FLAGS = '/v1/networks/:networkId/publishers/:publisherId/feature-flags',
  PUBLISHER = '/v1/networks/:networkId/publishers/:publisherId',
  STORIES_SEARCH = '/v1/networks/:networkId/publishers/:publisherId/stories:search',
  STORY = '/v1/networks/:networkId/publishers/-/stories/:uuid',
  STORY_HIDE = '/v1/networks/:networkId/stories/:uuid/hide',
  STORY_SHOW = '/v1/networks/:networkId/stories/:uuid/show',
  SETTINGS = '/v1/networks/:networkId/settings',
  STORY_EXPORT = '/v1/networks/:networkId/stories/:uuid:export',
  STORY_DOWNLOAD = '/v1/exports/:exportId',
  STORY_DELETE = '/v1/networks/:networkId/stories/:uuid',
  CHALLENGES_LIST = '/v1/networks/:networkId/challenges',
  ASSIGN_STORY = '/v1/networks/-/challenges/:challengeId/stories/:uuid',
  USER_ACCEPTANCE = '/v1/terms-of-service/acceptance',
  ACCOUNT_SETTINGS = '/v1/networks/:networkId/publishers/:publisherId',
  UPLOAD_LOGO_FAVICON = '/v1/networks/:networkId/publishers/:publisherId:upload',
}

export type CRThunkDispatch = ThunkDispatch<AppState, {}, AppActions>;

export type CRThunk<T, R> = (
  data: T,
) => (dispatch: CRThunkDispatch, getState: () => AppState) => Promise<R>;
