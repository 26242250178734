import React, { useEffect } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useForm } from 'react-hook-form';
import './name_gallery_section.scss';
import { Button, BUTTON_TYPES } from '../../../blocks/button';
import { Input, InputProps } from '../../../blocks/input';
import { UnhydratedGallery } from '../../../../redux/galleries/galleries.types';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { withController } from '../../../hooks/withController';

export interface NameGallerySectionProps extends LocalizeContextProps {
  updateStaticGalleryCreationValue: (val: Partial<UnhydratedGallery>) => void;
  handleNextStep: () => void;
  currentGalleryName: string | undefined;
}

const NameGallerySectionComponent = ({
  handleNextStep,
  updateStaticGalleryCreationValue,
  currentGalleryName,
  translate,
}: NameGallerySectionProps) => {
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });
  const CHARACTER_LIMIT = 60;
  const getTranslation = useTranslateStrings(translate);
  useEffect(() => {
    setValue('name-gallery-section__input', currentGalleryName ?? '');
  }, [currentGalleryName]);

  const handleFormSubmit = (data: Record<string, string>) => {
    updateStaticGalleryCreationValue({
      label: data['name-gallery-section__input'],
    });
    handleNextStep();
  };

  const ControlledInput = withController<string, InputProps>(Input);

  const validationRules = {
    maxLength: {
      value: CHARACTER_LIMIT,
      message: getTranslation(
        'galleries.manualGalleries.nameGallerySection.maxLengthErrorMsg',
      ),
    },
    required: getTranslation(
      'galleries.manualGalleries.nameGallerySection.requiredErrorMsg',
    ),
  };

  return (
    <div className="name-gallery-section">
      <div className="name-gallery-section__content">
        <h2>
          {getTranslation(
            'galleries.manualGalleries.nameGallerySection.headline',
          )}
        </h2>
        <p className="name-gallery-section__content--description">
          {getTranslation(
            'galleries.manualGalleries.nameGallerySection.description',
          )}
        </p>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="name-gallery-section__form"
        >
          <ControlledInput
            name="name-gallery-section__input"
            control={control}
            label={getTranslation(
              'galleries.manualGalleries.nameGallerySection.inputLabel',
            )}
            placeholder={getTranslation(
              'galleries.manualGalleries.nameGallerySection.inputPlaceHolder',
            )}
            validationRules={validationRules}
          />
          <Button
            type={BUTTON_TYPES.SUBMIT}
            className="name-gallery-Section__next-step-button"
          >
            {getTranslation(
              'galleries.manualGalleries.nameGallerySection.nextStepButton',
            )}
          </Button>
        </form>
      </div>
    </div>
  );
};

export const NameGallerySection = withLocalize(NameGallerySectionComponent);
