import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Buffer = function Buffer(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Buffer__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 978.7 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M521.6,5.8c145.4,67.5,293.3,137.1,438.5,205c7.7,3.6,17.9,6.1,17.9,17.1c0,11-10.1,13.4-17.9,17.1\tc-145.8,68.2-291.7,136.3-437.6,204.3c-22.2,10.3-45.1,10.2-67.3-0.2C309.1,380.9,162.9,312.7,16.8,244.3c-7.5-3.5-17-6.2-16.7-16.9\tc0.3-10,9.3-12.6,16.6-16C163.4,142.9,311.6,73.3,458.6,5.1C474.1-2.1,505.5-1.6,521.6,5.8L521.6,5.8z M488.9,1000\tc-10.6,0-18.5-4-33.6-9.7c-146-67.7-291.8-135.8-437.6-204c-7.7-3.6-17.8-6.2-17.7-17.2c0.1-11,10.1-13.4,17.9-17.1\tc24.2-11.5,48.5-22.7,72.8-34c24.3-11.3,48.4-11.1,72.7,0.3c96.7,45.3,193.4,90.4,290.1,135.6c24,11.2,47.8,11,71.8-0.2\tc97-45.4,194-90.7,291-136c22.9-10.7,46.1-11,69.1-0.6c25.9,11.7,51.6,23.9,77.3,36c3.8,1.8,7.7,3.8,10.9,6.5c6.9,5.8,7,13.6,0,19.2\tc-4.3,3.4-9.4,5.9-14.4,8.3c-144.6,67.6-289.2,135.2-434,202.3C513.9,994.7,499.5,1000,488.9,1000L488.9,1000z M488.4,729.2\tc-4.8,0-23.6-5.1-34.3-10.1C308.7,651.6,163.5,583.7,18.3,515.8c-7.8-3.6-18-6-18.2-16.9c-0.2-11.5,10.4-13.8,18.5-17.7\tc24.5-11.6,49-23.2,73.6-34.5c23.3-10.7,46.7-10.5,70,0.4c97.6,45.6,195.3,91.2,292.9,136.7c22.6,10.5,45.6,10.4,68.2-0.1\tc98-45.6,195.9-91.4,293.9-137c22.6-10.6,45.5-10.6,68.2-0.2c25.8,11.8,51.5,23.9,77.2,36c3.5,1.7,7.1,3.4,10.2,5.8\tc7.8,6.2,7.7,14.4-0.2,20.6c-3,2.4-6.6,4.2-10.2,5.8c-146.7,68.6-293.4,137.2-440.2,205.4C512.1,724.8,493.2,729.2,488.4,729.2L488.4,729.2z' // eslint-disable-line max-len
    })
  );
};

Buffer.propTypes = propTypes;

export default Buffer;