import { ActionCreator } from 'redux';
import { GalleryGetResponse } from '../../http/gallery_get.thunk';
import { GALLERIES_LOAD_TYPES } from '../../react/pages/galleries_list/galleries_list.types';
import {
  STATIC_GALLERY_CREATION,
  SetNextManualGalleryCreationStepAction,
  UpdateStaticGalleryCreationValue,
  HydratedGallery,
  UnhydratedGallery,
  STATIC_GALLERY_ACTIONS,
  SaveStaticGalleryFailureAction,
  SaveStaticGallerySuccessAction,
  StaticGalleryCreateAddStoryAction,
  StaticGalleryCreateRemoveStoryAction,
  GalleriesLoadSuccessAction,
  UpdateSelectedStaticGallery,
  EditStaticGalleryLabelAction,
  SaveStaticGalleryStartAction,
  StaticEditGalleryAddStoryAction,
  StaticEditGalleryRemoveStoryAction,
  ResetStaticGalleryStateAction,
  StaticGalleryEditSuccessAction,
  StaticGalleryEditFailureAction,
  StaticGalleryEditStartAction,
} from './galleries.types';
import { Story } from '../../react/features/story/story.types';

export enum GALLERY_GET_TYPES {
  START = 'GALLERY_GET/START',
  SUCCESS = 'GALLERY_GET/SUCCESS',
  FAILURE = 'GALLERY_GET/FAILURE',
}

export interface GalleryGetStartAction {
  type: GALLERY_GET_TYPES.START;
  payload: {};
}

export interface GalleryGetSuccessAction {
  type: GALLERY_GET_TYPES.SUCCESS;
  payload: GalleryGetResponse;
}

export interface GalleryGetFailureAction {
  type: GALLERY_GET_TYPES.FAILURE;
  payload: Error;
}

export const galleryGetSuccessAction = (
  response: GalleryGetResponse,
): GalleryGetSuccessAction => ({
  type: GALLERY_GET_TYPES.SUCCESS,
  payload: response,
});

export const galleryGetFailedAction = (
  error: Error,
): GalleryGetFailureAction => ({
  type: GALLERY_GET_TYPES.FAILURE,
  payload: error,
});

export type GalleryGetActions =
  | GalleryGetStartAction
  | GalleryGetSuccessAction
  | GalleryGetFailureAction;

export enum GALLERY_DELETE_TYPES {
  START = 'GALLERY_DELETE/START',
  SUCCESS = 'GALLERY_DELETE/SUCCESS',
  FAILURE = 'GALLERY_DELETE/FAILURE',
}

export interface GalleryDeleteStartAction {
  type: GALLERY_DELETE_TYPES.START;
}
export interface GalleryDeleteSuccessAction {
  type: GALLERY_DELETE_TYPES.SUCCESS;
  payload: string;
}

export interface GalleryDeleteFailureAction {
  type: GALLERY_DELETE_TYPES.FAILURE;
  payload: string;
}

export const galleryDeleteStarted = (): GalleryDeleteStartAction => ({
  type: GALLERY_DELETE_TYPES.START,
});
export const galleryDeleteSucceeded = (
  galleryId: string,
): GalleryDeleteSuccessAction => ({
  type: GALLERY_DELETE_TYPES.SUCCESS,
  payload: galleryId,
});
export const galleryDeleteFailed = (
  error: string,
): GalleryDeleteFailureAction => ({
  type: GALLERY_DELETE_TYPES.FAILURE,
  payload: error,
});

export type GalleryDeleteActions =
  | GalleryDeleteStartAction
  | GalleryDeleteSuccessAction
  | GalleryDeleteFailureAction;

export enum GALLERY_COPY_TYPES {
  START = 'GALLERY_COPY/START',
  SUCCESS = 'GALLERY_COPY/SUCCESS',
  FAILURE = 'GALLERY_COPY/FAILURE',
}

export interface GalleryCopyStartAction {
  type: GALLERY_COPY_TYPES.START;
}
export interface GalleryCopySuccessAction {
  type: GALLERY_COPY_TYPES.SUCCESS;
  payload: string;
}

export interface GalleryCopyFailureAction {
  type: GALLERY_COPY_TYPES.FAILURE;
  payload: string;
}

export const galleryCopyStarted = (): GalleryCopyStartAction => ({
  type: GALLERY_COPY_TYPES.START,
});
export const galleryCopySucceeded = (
  galleryId: string,
): GalleryCopySuccessAction => ({
  type: GALLERY_COPY_TYPES.SUCCESS,
  payload: galleryId,
});
export const galleryCopyFailed = (error: string): GalleryCopyFailureAction => ({
  type: GALLERY_COPY_TYPES.FAILURE,
  payload: error,
});

export type GalleryCopyActions =
  | GalleryCopyStartAction
  | GalleryCopySuccessAction
  | GalleryCopyFailureAction;
export enum GALLERY_UNDELETE_TYPES {
  START = 'GALLERY_UNDELETE/START',
  SUCCESS = 'GALLERY_UNDELETE/SUCCESS',
  FAILURE = 'GALLERY_UNDELETE/FAILURE',
}

export interface GalleryUndeleteStartAction {
  type: GALLERY_UNDELETE_TYPES.START;
}
export interface GalleryUndeleteSuccessAction {
  type: GALLERY_UNDELETE_TYPES.SUCCESS;
  payload: string;
}

export interface GalleryUndeleteFailureAction {
  type: GALLERY_UNDELETE_TYPES.FAILURE;
  payload: string;
}

export const galleryUndeleteStarted = (): GalleryUndeleteStartAction => ({
  type: GALLERY_UNDELETE_TYPES.START,
});
export const galleryUndeleteSucceeded = (
  galleryId: string,
): GalleryUndeleteSuccessAction => ({
  type: GALLERY_UNDELETE_TYPES.SUCCESS,
  payload: galleryId,
});
export const galleryUndeleteFailed = (
  error: string,
): GalleryUndeleteFailureAction => ({
  type: GALLERY_UNDELETE_TYPES.FAILURE,
  payload: error,
});

export type GalleryUndeleteActions =
  | GalleryUndeleteStartAction
  | GalleryUndeleteSuccessAction
  | GalleryUndeleteFailureAction;

export const galleriesLoadSuccess: ActionCreator<GalleriesLoadSuccessAction> = (
  galleries: HydratedGallery[],
) => ({
  type: GALLERIES_LOAD_TYPES.SUCCESS,
  payload: { galleries },
});

// Manual Gallery Creation Actions

export const updateStaticGalleryCreationValue = (
  val: Partial<UnhydratedGallery>,
): UpdateStaticGalleryCreationValue => ({
  type: STATIC_GALLERY_CREATION.SET_STATIC_GALLERY_CREATION_VALUE,
  payload: val,
});

export const addStoryCreateStaticGallery = (
  story: Story,
): StaticGalleryCreateAddStoryAction => ({
  type: STATIC_GALLERY_ACTIONS.ADD_STORY_CREATE,
  payload: story,
});

export const removeStoryCreateStaticGallery = (
  uuid: string,
): StaticGalleryCreateRemoveStoryAction => ({
  type: STATIC_GALLERY_ACTIONS.REMOVE_STORY_CREATE,
  payload: uuid,
});

export const addStoryGalleryEdit = (
  story: Story,
): StaticEditGalleryAddStoryAction => ({
  type: STATIC_GALLERY_ACTIONS.ADD_STORY_EDIT,
  payload: story,
});

export const removeStoryGalleryEdit = (
  uuid: string,
): StaticEditGalleryRemoveStoryAction => ({
  type: STATIC_GALLERY_ACTIONS.REMOVE_STORY_EDIT,
  payload: uuid,
});

export const setManualGalleryCreationStep = (
  step: number,
): SetNextManualGalleryCreationStepAction => ({
  type: STATIC_GALLERY_CREATION.SET_STATIC_GALLERY_CREATION_STEP,
  payload: step,
});

export const saveStaticGalleryStart = (): SaveStaticGalleryStartAction => ({
  type: STATIC_GALLERY_ACTIONS.SAVE_STATIC_GALLERY_START,
  payload: undefined,
});

export const saveStaticGallerySuccess = (
  gallery: HydratedGallery,
): SaveStaticGallerySuccessAction => ({
  type: STATIC_GALLERY_ACTIONS.SAVE_STATIC_GALLERY_SUCCESS,
  payload: gallery,
});

export const saveStaticGalleryFailure = (
  errorMessage: string,
): SaveStaticGalleryFailureAction => ({
  type: STATIC_GALLERY_ACTIONS.SAVE_STATIC_GALLERY_FAILURE,
  payload: errorMessage,
});

export const updateStaticGallerySelectedList = (
  sortedStoryList: Story[],
): UpdateSelectedStaticGallery => ({
  type: STATIC_GALLERY_ACTIONS.UPDATE_SELECTED_STATIC_GALLERY,
  payload: sortedStoryList,
});

export const editStaticGalleryLabel = (
  label: string,
): EditStaticGalleryLabelAction => ({
  type: STATIC_GALLERY_ACTIONS.GALLERY_UPDATE_LABEL,
  payload: label,
});

export const resetStaticGalleryState: ActionCreator<ResetStaticGalleryStateAction> = () => ({
  type: STATIC_GALLERY_ACTIONS.RESET_GALLERY_STATE,
  payload: undefined,
});

export const staticGalleryEditStartAction: ActionCreator<StaticGalleryEditStartAction> = () => ({
  type: STATIC_GALLERY_ACTIONS.EDIT_STATIC_GALLERY_START,
  payload: undefined,
});

export const staticGalleryEditFailureAction: ActionCreator<StaticGalleryEditFailureAction> = (
  error: string,
) => ({
  type: STATIC_GALLERY_ACTIONS.EDIT_STATIC_GALLERY_FAILURE,
  payload: error,
});

export const staticGalleryEditSuccessAction: ActionCreator<StaticGalleryEditSuccessAction> = (
  gallery: HydratedGallery,
) => ({
  type: STATIC_GALLERY_ACTIONS.EDIT_STATIC_GALLERY_SUCCESS,
  payload: gallery,
});
