import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Clock = function Clock(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'clock__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon',
      viewBox: '0 0 1000 1000'
    },
    React.createElement(
      'g',
      null,
      React.createElement('path', {
        d: 'M933,249c44.7,76.6,67,160.3,67,251c0,90.7-22.3,174.4-67,251c-44.7,76.6-105.4,137.3-182,181.9c-76.6,44.7-160.3,67.1-251,67.1c-90.7,0-174.4-22.3-251-67.1c-76.6-44.7-137.3-105.4-182-181.9C22.3,674.4,0,590.7,0,500c0-90.7,22.3-174.3,67.1-251c44.7-76.6,105.4-137.3,182-182C325.6,22.4,409.3,0,500,0c90.7,0,174.4,22.4,251,67.1C827.6,111.8,888.2,172.4,933,249z M806.7,677.7c31.7-54.2,47.5-113.5,47.5-177.7c0-64.2-15.8-123.5-47.5-177.7C775,268,732,225,677.8,193.4c-54.3-31.7-113.5-47.5-177.7-47.5c-64.2,0-123.5,15.9-177.7,47.5C268,225,225,268,193.4,322.3c-31.7,54.3-47.5,113.5-47.5,177.7c0,64.2,15.8,123.5,47.5,177.7C225,732,268,775,322.3,806.6c54.2,31.7,113.5,47.5,177.7,47.5c64.2,0,123.5-15.8,177.7-47.5C732,775,775,732,806.7,677.7z M583.3,270.8v291.7c0,6.1-1.9,11.1-5.9,15c-3.9,3.9-8.9,5.9-15,5.9H354.2c-6.1,0-11.1-1.9-15-5.9c-3.9-3.9-5.9-8.9-5.9-15v-41.6c0-6.1,2-11.1,5.9-15c3.9-3.9,8.9-5.9,15-5.9H500V270.8c0-6.1,1.9-11.1,5.9-15s8.9-5.9,15-5.9h41.7c6.1,0,11.1,2,15,5.9C581.4,259.8,583.3,264.8,583.3,270.8z' // eslint-disable-line max-len
      })
    )
  );
};

Clock.propTypes = propTypes;

export default Clock;