import './assign_story_modal.scss';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { withController } from '../../hooks/withController';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
} from '../../blocks/button';
import {
  ModalProps,
  ConnectedModalButton as ModalButton,
  ConnectedModalTitle as ModalTitle,
} from '../../blocks/connected_modal';
import { ModalButtons, ModalContent } from '../../blocks/modal';
import { SelectValue, Select, SelectProps } from '../../blocks/select';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';
import { listChallenges, Challenge } from '../../../http/challenges_list';
import { getErrorMessage } from '../../../utils';
import Err from '../../../types/err';
import { assignStory } from '../../../http/assign_story';
import {
  selectCurrentNetworkId,
  selectIsUserInternal,
} from '../../../redux/user/user.selectors';
import { ChallengeStatus } from './challenges.constants';
import { useToast } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../blocks/toast';

export interface AssignStoryModalProps extends LocalizeContextProps {
  Modal: React.FunctionComponent<ModalProps>;
  toggle: () => void;
  storyId: string;
  isOpen: boolean;
}
const AssignStoryModalComponent = ({
  Modal,
  toggle,
  storyId,
  translate,
  isOpen,
}: AssignStoryModalProps) => {
  const getTranslation = useTranslateStrings(translate);
  const ControlledSelect = withController<
    SelectValue<false>,
    SelectProps<false>
  >(Select);
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
  });
  const { showToast } = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const networkId = useSelector(selectCurrentNetworkId);
  const userInternal = useSelector(selectIsUserInternal);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    try {
      listChallenges({
        networkId,
        userInternal,
        status: ChallengeStatus.OPEN,
      }).then((fetchedChallenges) => {
        setChallenges(fetchedChallenges);
      });
    } catch (err) {
      throw new Err(
        `Failed to get challenges:  ${getErrorMessage(err)}`,
        'Networks not found.',
      );
    }
  }, [isOpen]);

  const openChallengeOptions = challenges.map((challenge) => {
    return {
      label: challenge.label,
      value: challenge.id,
    };
  });

  const assignStoryFn = handleSubmit(async (data) => {
    setLoading(true);
    try {
      await assignStory({
        story_id: storyId,
        challenge_id: data.challenges.value,
      });
      showToast({
        message: `${getTranslation('stories.challenges.assignStory.success')}`,
        type: TOAST_TYPES.SUCCESS,
      });
      toggle();
    } catch (e) {
      showToast({
        message: `${getTranslation('stories.challenges.assignStory.fail')}`,
        type: TOAST_TYPES.ERROR,
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal className="assign_story_modal">
      <div className="assign_story_modal__content">
        <ModalTitle
          label={getTranslation('stories.challenges.assignStory.assignTitle')}
        />
        <ModalContent>
          <ControlledSelect
            control={control}
            isMulti={false}
            options={openChallengeOptions}
            name="challenges"
            label={getTranslation('stories.challenges.challenge')}
            placeholder={getTranslation('stories.challenges.challenge')}
            validationRules={{
              required: getTranslation('stories.challenges.requiredField'),
            }}
          />
        </ModalContent>
        <ModalButtons>
          <ModalButton
            variant={BUTTON_VARIANT.OUTLINE}
            color={BUTTON_COLOR_VARIANT.SECONDARY}
          >
            {getTranslation('stories.challenges.cancel')}
          </ModalButton>
          <Button loading={loading} onClick={assignStoryFn}>
            {getTranslation('stories.challenges.assignStory.submitStory')}
          </Button>
        </ModalButtons>
      </div>
    </Modal>
  );
};

export const AssignStoryModal = withLocalize(AssignStoryModalComponent);
