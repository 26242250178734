import {
  TranslateFunction,
  TranslatePlaceholderData,
} from 'react-localize-redux';
import { isString } from '../../types/typeguards';

/**
 * Returns a function which will give a translation AS A TYPESAFE STRING given a translation ID
 * @param translate An instance of the react-redux-localize translate function
 */
export const useTranslateStrings = (translate: TranslateFunction) => {
  const getTranslatedString = (
    id: string,
    data?: TranslatePlaceholderData,
  ): string => {
    const translatedText = translate(id, data, { renderInnerHtml: false });
    return isString(translatedText) ? translatedText : '';
  };
  return getTranslatedString;
};
