import { ThunkDispatch } from 'redux-thunk';
import axios from './axios_config';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { AppState, AppActions } from '../types';
import {
  selectCurrentNetworkId,
  selectCurrentPublisherId,
} from '../redux/user/user.selectors';
import { getErrorMessage } from '../utils';

export interface PublisherResponse {
  name?: string;
  gallery_domain?: string;
  logo_src?: string;
  favicon_src?: string;
}
export interface GetPublisherResponse {
  publisher: PublisherResponse;
}

export const getPublisher = async (
  dispatch: ThunkDispatch<AppState, {}, AppActions>,
  getState: () => AppState,
) => {
  const state = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const publisherId: string = selectCurrentPublisherId(state);
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.PUBLISHER,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
    },
  });
  try {
    const res = await axios.get<GetPublisherResponse>(url);
    const publisherRes = res.data.publisher;
    const shapedResponse = {
      ...publisherRes,
      avatarUrl: publisherRes.logo_src,
      faviconUrl: publisherRes.favicon_src,
    };
    return shapedResponse;
  } catch (err) {
    throw new Error(`Failed to get publisher: ${getErrorMessage(err)}`);
  }
};
