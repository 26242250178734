import React, { ReactChild, ReactNode } from 'react';
import { Text, TEXT_ELEMENTS, TEXT_VARIANTS } from '../text';
import './toast.scss';

interface ToastBannerProps {
  imgUrl?: string;
  message?: string;
  description?: string;
  status?: string | ReactChild;
  customActionCTA?: ReactNode;
}

export const Toast = ({
  imgUrl,
  message,
  description,
  status,
  customActionCTA,
}: ToastBannerProps) => {
  if (!message && !description && !status) {
    throw new Error(
      'Either one of message, description or status is required to show toast',
    );
  }
  const toastBodyCLassName = 'Toastify__toast-body__container';
  return (
    <div className={toastBodyCLassName}>
      {imgUrl && (
        <img
          src={imgUrl}
          className={`${toastBodyCLassName}__banner-img`}
          alt="Notification banner"
        />
      )}
      <div className={`${toastBodyCLassName}__message-wrapper`}>
        {message && (
          <Text
            variant={TEXT_VARIANTS.H4}
            className={`${toastBodyCLassName}__message-wrapper__message`}
          >
            {message}
          </Text>
        )}
        {description && (
          <Text
            variant={TEXT_VARIANTS.B2}
            className={`${toastBodyCLassName}__message-wrapper__description`}
          >
            {description}
          </Text>
        )}
        {status && (
          <Text
            variant={TEXT_VARIANTS.B22}
            element={TEXT_ELEMENTS.SPAN}
            className={`${toastBodyCLassName}__message-wrapper__status`}
          >
            {status}
          </Text>
        )}
      </div>
      {customActionCTA}
    </div>
  );
};
