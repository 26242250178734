{
  "formActions": {
    "cancel": "Cancel",
    "save": "Save Account Settings",
    "uploadIMG": "Upload Image",
    "replaceIMG": "Replace Image"
  },
  "website": {
    "label": "Website",
    "invalid": "Website format is invalid."
  },
  "storyAppearance": {
    "title": "Story Appearance",
    "avatar": {
      "title":"Avatar",
      "description":"This image appears on your Stories as your profile avatar.",
      "req1": "Maximum file size: <b>10MB</b>",
      "req2": "Maximum image dimensions: <b>400px by 400px</b>",
      "req3": "Accepted file type: <b>.png</b>"
    },
    "displayName": {
      "label": "Display name",
      "required": "*Required. This name will appear on your Stories as your profile handle.",
      "condition": "This name will appear on your Stories as your profile handle.",
      "invalid": "Display name contains invalid characters. Valid characters include: letters, numbers, spaces, periods, commas, dashes, underscores, apostrophes, round or square brackets."
    }
  },
  "webSettings": {
    "title": "Web Settings",
    "favicon": {
      "title": "Favicon",
      "description": "This image will appear in the browser tab when your Stories are viewed on desktop.",
      "req1": "Maximum file size: <b>10MB</b>",
      "req2": "Maximum image dimensions: <b>400px by 400px</b>",
      "req3": "Accepted file type: <b>.png, .ico</b>"
    }
  },
  "snackbar": {
    "success": "Your profile settings were successfully saved.",
    "changesNotSaved": "Your changes weren’t saved",
    "notAllChangesSaved": "Not all of your changes were saved",
    "formInvalid": {
      "errorBody": "Please review the form for errors."
    },
    "imageInvalid": {
      "errorTitle": "Image upload failed",
      "errorBody": "Please check size, dimensions, and file type and try again."
    },
    "submitFailed": {
      "errorBody": "Please try submitting the form again or contact support if the problem persists."
    },
    "updateFailed": {
      "errorBody": "Please try submitting your display name or website again, or contact support if the problem persists."
    },
    "imageUpload": {
      "errorBody": "Please try submitting your avatar or favicon images again, or contact support if the problem persists."
    }
  },
  "error": {
    "title": "Something went wrong.",
    "body": "Sorry for the interruption! If this issue keeps happening, please let us know.",
    "acceptCTA": "Return to Localhood",
    "rejectCTA": "Contact Support"
  }
}
