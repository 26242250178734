import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Logout = function Logout(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      version: '1.1',
      id: 'Logout_icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1225 1000',
      height: size,
      fill: color,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M475,1000H225C100.8,1000,0,899.2,0,775V225C0,100.8,100.8,0,225,0h250c13.3,0,25,11.7,25,25c0,21.9,10.2,75-25,75H225c-68.7,0-125,56.2-125,125v550c0,68.8,56.3,125,125,125h225c19.5,0,50-3.9,50,25C500,946.9,510.2,1000,475,1000z M1210.2,535.2l-425,425c-9.4,9.4-21.9,14.8-35.2,14.8c-27.3,0-50-22.7-50-50V700H350c-27.3,0-50-22.7-50-50V350c0-27.3,22.7-50,50-50h350V75c0-27.3,22.7-50,50-50c13.3,0,25.8,5.5,35.2,14.8l425,425c9.4,9.4,14.8,21.9,14.8,35.2S1219.5,525.8,1210.2,535.2z' // eslint-disable-line max-len
    })
  );
};

Logout.propTypes = propTypes;

export default Logout;