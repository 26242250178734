import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

var propTypes = {
  classes: PropTypes.object,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headCells: PropTypes.array,
  numSelected: PropTypes.number,
  areColumnsSortable: PropTypes.bool,
  showSelectRowOption: PropTypes.bool,
  tableHeadColor: PropTypes.string,
  tableHeadFontSize: PropTypes.string
};

var TableHeader = function TableHeader(props) {
  var classes = props.classes,
      onSelectAllClick = props.onSelectAllClick,
      order = props.order,
      orderBy = props.orderBy,
      numSelected = props.numSelected,
      rowCount = props.rowCount,
      onRequestSort = props.onRequestSort,
      headCells = props.headCells,
      showSelectRowOption = props.showSelectRowOption,
      areColumnsSortable = props.areColumnsSortable,
      tableHeadColor = props.tableHeadColor,
      tableHeadFontSize = props.tableHeadFontSize;

  var createSortHandler = function createSortHandler(property) {
    return function (event) {
      onRequestSort(event, property);
    };
  };

  var StyledHeaderTableCell = withStyles(function () {
    return {
      head: {
        fontWeight: 'bold',
        fontSize: tableHeadFontSize || '14px',
        lineHeight: '16px',
        color: tableHeadColor || '#666'
      }
    };
  })(TableCell);

  return React.createElement(
    TableHead,
    null,
    React.createElement(
      TableRow,
      null,
      showSelectRowOption && React.createElement(
        TableCell,
        { padding: 'checkbox' },
        React.createElement(Checkbox, {
          indeterminate: numSelected > 0 && numSelected < rowCount,
          checked: numSelected === rowCount,
          onChange: onSelectAllClick,
          inputProps: { 'aria-label': 'select all' }
        })
      ),
      headCells.map(function (headCell) {
        return React.createElement(
          StyledHeaderTableCell,
          {
            key: headCell.id,
            sortDirection: orderBy === headCell.id ? order : false
          },
          areColumnsSortable ? React.createElement(
            TableSortLabel,
            {
              active: orderBy === headCell.id,
              direction: order,
              onClick: createSortHandler(headCell.id)
            },
            headCell.label,
            orderBy === headCell.id ? React.createElement(
              'span',
              { className: classes.visuallyHidden },
              order === 'desc' ? 'sorted descending' : 'sorted ascending'
            ) : null
          ) : headCell.label
        );
      })
    )
  );
};

TableHeader.propTypes = propTypes;

export default TableHeader;