import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};
// Play button always has a shadow
var Plus = function Plus(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'plus_icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M437.5,437.5v-375C437.5,28,465.5,0,500,0h0c34.5,0,62.5,28,62.5,62.5v375h375c34.5,0,62.5,28,62.5,62.5v0c0,34.5-28,62.5-62.5,62.5h-375l0,375c0,34.5-28,62.5-62.5,62.5h0c-34.5,0-62.5-28-62.5-62.5l0-375h-375C28,562.5,0,534.5,0,500v0c0-34.5,28-62.5,62.5-62.5H437.5z' // eslint-disable-line max-len
    })
  );
};

Plus.propTypes = propTypes;

export default Plus;