import { Action, ActionCreator } from 'redux';
import {
  EXPORTS_TYPES,
  ExportsResetAction,
  EXPORT_PREPARE_TYPES,
  ExportsPrepareActionStart,
  ExportsPrepareActionSuccess,
  ExportsPrepareActionFailure,
  ExportsBindingAction,
  ExportsStoryActionStart,
  EXPORT_STORY_TYPES,
  ExportsStoryActionSuccess,
  ExportsStoryActionFailure,
  ExportsDownload,
  DOWNLOAD_ASSETS_BINDING_TYPES,
  UpdateDownloadLocationAction,
} from './exports.types';

export const exportsBindingAction: ActionCreator<Action> = (
  payload: boolean,
): ExportsBindingAction => ({
  type: EXPORTS_TYPES.EXPORT_BINDING,
  payload,
});

export const exportsPrepareStartAction: ActionCreator<Action> = (
  payload: string,
): ExportsPrepareActionStart => ({
  type: EXPORT_PREPARE_TYPES.START,
  payload,
});

export const exportsPrepareSuccessAction: ActionCreator<Action> = (
  payload: string,
): ExportsPrepareActionSuccess => ({
  type: EXPORT_PREPARE_TYPES.SUCCESS,
  payload,
});

export const exportsPrepareFailureAction: ActionCreator<Action> = (): ExportsPrepareActionFailure => ({
  type: EXPORT_PREPARE_TYPES.FAILURE,
});

export const exportsStoriesStartAction: ActionCreator<Action> = (payload: {
  storyUuid: string;
  exportId: string;
  bindType: DOWNLOAD_ASSETS_BINDING_TYPES;
}): ExportsStoryActionStart => ({
  type: EXPORT_STORY_TYPES.START,
  payload,
});

export const exportsStoriesSuccessAction: ActionCreator<Action> = (
  payload: ExportsDownload,
): ExportsStoryActionSuccess => ({
  type: EXPORT_STORY_TYPES.SUCCESS,
  payload,
});

export const exportsStoriesFailureAction: ActionCreator<Action> = (
  payload: string,
): ExportsStoryActionFailure => ({
  type: EXPORT_STORY_TYPES.FAILURE,
  payload,
});

export const exportsResetAction: ActionCreator<Action> = (
  payload: string,
): ExportsResetAction => ({
  type: EXPORTS_TYPES.EXPORT_RESET,
  payload,
});

export const updateDownloadLocation: ActionCreator<Action> = (
  payload: string,
): UpdateDownloadLocationAction => ({
  type: EXPORTS_TYPES.UPDATE_DOWNLOAD_LOCATION,
  payload,
});
