import React from 'react';
import { IconProps } from '../icon.types';

export const TextFormat = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.18457 16.1328C1.62451 16.1328 1.84033 15.9668 1.99805 15.502L3.06885 12.5718H7.96631L9.03711 15.502C9.19482 15.9668 9.41064 16.1328 9.84229 16.1328C10.2822 16.1328 10.5645 15.8672 10.5645 15.4521C10.5645 15.311 10.5396 15.1782 10.4731 15.0039L6.58008 4.63623C6.38916 4.12988 6.04883 3.87256 5.51758 3.87256C5.00293 3.87256 4.6543 4.12158 4.47168 4.62793L0.578613 15.0122C0.512207 15.1865 0.487305 15.3193 0.487305 15.4604C0.487305 15.8755 0.75293 16.1328 1.18457 16.1328ZM3.47559 11.3101L5.49268 5.72363H5.53418L7.55127 11.3101H3.47559ZM15.1382 16.1909C16.4165 16.1909 17.6118 15.4937 18.1265 14.4146H18.1597V15.5103C18.1597 15.9253 18.4419 16.1992 18.832 16.1992C19.2305 16.1992 19.5127 15.9253 19.5127 15.4771V9.94043C19.5127 8.13086 18.1846 6.96045 16.0762 6.96045C14.5156 6.96045 13.2124 7.64941 12.7393 8.72021C12.6479 8.92773 12.5815 9.12695 12.5815 9.30127C12.5815 9.6665 12.8555 9.90723 13.2124 9.90723C13.4697 9.90723 13.6689 9.80762 13.7935 9.5752C14.2583 8.6123 14.9639 8.16406 16.043 8.16406C17.3379 8.16406 18.1099 8.88623 18.1099 10.0483V10.7622L15.4287 10.9116C13.3286 11.0278 12.1582 11.999 12.1582 13.543C12.1582 15.1284 13.3784 16.1909 15.1382 16.1909ZM15.4785 15.0371C14.3579 15.0371 13.5942 14.4229 13.5942 13.5098C13.5942 12.6299 14.2998 12.0405 15.603 11.9575L18.1099 11.7998V12.6797C18.1099 13.9995 16.9395 15.0371 15.4785 15.0371Z" />
    </svg>
  );
};
