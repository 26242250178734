import React from 'react';
import { IconProps } from '../icon.types';

export const Filters = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14.2506"
        cy="7.1161"
        r="1.45482"
        stroke="black"
        strokeWidth="1.32256"
      />
      <path d="M12.5224 7.77737L13.1837 7.77737L13.1837 6.45481L12.5224 6.45481L12.5224 7.77737ZM4.05804 6.45481C3.69283 6.45481 3.39676 6.75087 3.39676 7.11609C3.39676 7.4813 3.69283 7.77737 4.05804 7.77737L4.05804 6.45481ZM12.5224 6.45481L4.05804 6.45481L4.05804 7.77737L12.5224 7.77737L12.5224 6.45481Z" />
      <circle
        cx="5.1161"
        cy="12.4064"
        r="1.45482"
        transform="rotate(-180 5.1161 12.4064)"
        stroke="black"
        strokeWidth="1.32256"
      />
      <path d="M7.2322 11.7451H6.57092V13.0677H7.2322V11.7451ZM15.6966 13.0677C16.0618 13.0677 16.3579 12.7716 16.3579 12.4064C16.3579 12.0412 16.0618 11.7451 15.6966 11.7451V13.0677ZM7.2322 13.0677H15.6966V11.7451H7.2322V13.0677Z" />
    </svg>
  );
};
