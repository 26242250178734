class Err extends Error {
  friendly: string;

  constructor(message: string, friendly: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.friendly = friendly;
  }
}

export default Err;
