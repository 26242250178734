import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var CameraOutline = function CameraOutline(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'cameraOutline__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M360.492 81.5635C350.661 81.7495 343.593 88.2815 339.78 94.2815L287.818 197.843H104.678C47.1036 197.843 0.0268555 244.919 0.0268555 302.494V814.122C0.0268555 871.696 47.1036 918.773 104.678 918.773H895.376C952.95 918.773 1000.03 871.696 1000.03 814.122V302.494C1000.03 244.919 952.95 197.843 895.376 197.843H712.236L660.273 94.2815C656.411 86.6825 648.086 81.5713 639.562 81.5635H360.492ZM374.664 128.075H625.39L676.99 231.273C680.751 239.006 689.101 244.279 697.701 244.354H895.376C927.987 244.354 953.515 269.882 953.515 302.494V814.122C953.515 846.733 927.987 872.261 895.376 872.261H104.678C72.0664 872.261 46.5385 846.733 46.5385 814.122V302.494C46.5385 269.882 72.0664 244.354 104.678 244.354H302.352C310.952 244.284 319.302 239.006 323.064 231.273L374.664 128.075V128.075ZM500.027 325.75C371.865 325.75 267.469 430.145 267.469 558.308C267.469 686.471 371.865 790.866 500.027 790.866C628.191 790.866 732.585 686.471 732.585 558.308C732.585 430.145 628.191 325.75 500.027 325.75V325.75ZM500.027 372.261C603.054 372.261 686.073 455.282 686.073 558.308C686.073 661.333 603.054 744.354 500.027 744.354C397.002 744.354 313.98 661.333 313.98 558.308C313.98 455.282 397.002 372.261 500.027 372.261Z' // eslint-disable-line max-len
    })
  );
};

CameraOutline.propTypes = propTypes;

export default CameraOutline;