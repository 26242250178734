import _typeof from 'babel-runtime/helpers/typeof';
import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import _extends from 'babel-runtime/helpers/extends';
import _objectWithoutProperties from 'babel-runtime/helpers/objectWithoutProperties';
import React from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import FilterDropdownV2Styles from './FilterDropDownV2Styles';
import Icon from '../Icon/Icon';

var propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInline: PropTypes.bool,
  isTransparent: PropTypes.bool,
  description: PropTypes.string,
  showCount: PropTypes.bool,
  isRequired: PropTypes.bool,
  isRightAligned: PropTypes.bool,
  isSearchable: PropTypes.bool,
  hasError: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  noResultsText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onChange: PropTypes.func.isRequired,
  optionComponent: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  callbackFn: PropTypes.func,
  placeholder: PropTypes.string,
  requestFn: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  valueKey: PropTypes.string,
  debounceVal: PropTypes.number,
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.string,
  isGroupedFilterDropdown: PropTypes.bool,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  hideSelectedValue: PropTypes.bool,
  classNamePrefix: PropTypes.string
};

var propDescriptions = {
  className: 'Optional classname for the dropdown',
  isDisabled: 'Will render a read-only version of the dropdown',
  isInline: 'Will render the label inline with the dropdown control',
  isTransparent: 'A boolean to determine if the dropdown background is transparent',
  isRightAligned: 'Will align the dropdown menu on the right side of its parent container',
  isRequired: 'Determines whether or not the dropdown is required (will not appear if isInline is true)',
  isSearchable: 'A boolean to determine if dropdown options are searchable',
  hasError: 'A boolean to represent if the dropdown is in an error state',
  errorText: 'A string to display under the dropdown when hasError is true. (will not appear if hasError is false)',
  label: 'Label for the dropdown',
  labelKey: 'The value the dropdown will use to display the labels',
  description: 'Description for the dropdown (will not appear if isInline is true)',
  showCount: 'A boolean to determine if we show the number of selected options (will not appear if isInline is true)',
  name: 'Form field name for the dropdown',
  noResultsText: 'Text that is displayed if query does not match any available options.',
  onChange: 'Callback called when the value changes, with the value as first arg',
  optionComponent: 'A react node that will replace the default option component',
  options: 'Array of options available in the dropdown. NOTE: Each option should have a label/value (required) and a disabled attribute (not required), which is used to make the option not clickable',
  placeholder: 'Dropdown text to appear before selection is made',
  requestFn: 'Returns a promise, which is the set of options to be used once the promise resolves.',
  value: 'Selected value of the dropdown.',
  valueKey: 'The value the dropdown will use to set and search the values of the dropdown.',
  callbackFn: 'Function that is called on success of Async search. Must return an array of objects',
  debounceVal: 'Determines how long to debounce for Async selector',
  maxMenuHeight: 'A number that determines the max height of dropdown menu',
  menuPlacement: 'Determines whether the menu will dropdown or pop-up above the input',
  selectedOptions: 'An array of options selected that is only used when isGroupedFilterDropdown is enabled. NOTE: This will only affect the UI/display of the option within the menu and not sync\'d with value of the dropdown. HOC is responsible for managing those values appropriately.',
  isGroupedFilterDropdown: 'A boolean used to show the grouped data dropdown version',
  hideSelectedValue: 'A boolean that determines if the selected option will be shown in the placeholder'
};

var defaultProps = {
  className: '',
  name: '',
  placeholder: 'Select an option',
  value: '',
  // noResultsText should be specified in searchable dropdowns
  isDisabled: false,
  isRequired: false,
  isSearchable: false,
  hasError: false,
  errorText: 'Error Text',
  labelKey: 'label',
  noResultsText: '',
  valueKey: 'value',
  debounceVal: 200,
  maxMenuHeight: 300,
  menuPlacement: 'bottom',
  selectedOptions: [],
  isGroupedFilterDropdown: false,
  hideSelectedValue: false
};

var CustomValueContainer = function CustomValueContainer(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, ['children']);

  var selectProps = props.selectProps;

  return React.createElement(
    components.ValueContainer,
    props,
    children,
    selectProps.hasError && React.createElement(Icon, { type: 'warning', size: 14, className: 'filterDropdownV2__warningIcon' })
  );
};

var CustomOption = function CustomOption(props) {
  var isSelected = props.isSelected,
      data = props.data,
      selectProps = props.selectProps;

  var isOptionSelected = selectProps.isGroupedFilterDropdown ? selectProps.selectedOptions && selectProps.selectedOptions.includes(data.value) : isSelected;
  return React.createElement(
    components.Option,
    _extends({}, props, { isDisabled: data.disabled }),
    data.label,
    isOptionSelected && React.createElement(Icon, { type: 'check', size: 14, className: 'filterDropdownV2__checkIcon' })
  );
};

var CustomDropdownIndicator = function CustomDropdownIndicator(props) {
  return React.createElement(
    components.DropdownIndicator,
    props,
    React.createElement(Icon, { type: 'angle-down', size: 14 })
  );
};

var FilterDropdownV2 = function (_React$Component) {
  _inherits(FilterDropdownV2, _React$Component);

  function FilterDropdownV2(props) {
    _classCallCheck(this, FilterDropdownV2);

    var _this = _possibleConstructorReturn(this, (FilterDropdownV2.__proto__ || _Object$getPrototypeOf(FilterDropdownV2)).call(this, props));

    _this.handleLoadOptions = function (inputValue, callback) {
      var _this$props = _this.props,
          callbackFn = _this$props.callbackFn,
          requestFn = _this$props.requestFn,
          labelKey = _this$props.labelKey,
          valueKey = _this$props.valueKey;

      if (!inputValue) {
        callback([]);
      }

      requestFn(inputValue).then(function (optionsResponse) {
        if (callbackFn) {
          callback(callbackFn(optionsResponse, { labelKey: labelKey, valueKey: valueKey }));
        }
        if (optionsResponse) callback(optionsResponse);
        callback([]);
      }).catch(function () {
        return callback([]);
      });
    };

    _this.state = {
      menuIsOpen: false
    };
    return _this;
  }
  // This is a workaround to prevent the dropdown from automatically focusing the first option in the menu


  _createClass(FilterDropdownV2, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (this.selectRef) {
        this.selectRef.select.getNextFocusedOption = function () {
          return null;
        };
      }
    }
    // This function is what is used to make calls to an external API
    // It sets the loading state to true, and then returns the options recieved
    //  from the function. If there is a callback function prop provided,
    //  it will also fire, with the response and what label and value keys are expected
    //  this can be used to do things like fire redux actions.

  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          className = _props.className,
          isDisabled = _props.isDisabled,
          isInline = _props.isInline,
          isRequired = _props.isRequired,
          isSearchable = _props.isSearchable,
          hasError = _props.hasError,
          errorText = _props.errorText,
          label = _props.label,
          labelKey = _props.labelKey,
          description = _props.description,
          showCount = _props.showCount,
          name = _props.name,
          noResultsText = _props.noResultsText,
          _onChange = _props.onChange,
          optionComponent = _props.optionComponent,
          options = _props.options,
          placeholder = _props.placeholder,
          requestFn = _props.requestFn,
          value = _props.value,
          valueKey = _props.valueKey,
          debounceVal = _props.debounceVal,
          maxMenuHeight = _props.maxMenuHeight,
          menuPlacement = _props.menuPlacement,
          isGroupedFilterDropdown = _props.isGroupedFilterDropdown,
          selectedOptions = _props.selectedOptions,
          hideSelectedValue = _props.hideSelectedValue;
      // This is a polyfill. In Version 1 of the react-select plugin, the value prop was always
      //  a string. In v3, value is expected to have a shape of {value: '', label:''} unless labelKey and
      //  valuekey are specified. This is just to normalize any string/num/bool values

      var selectedValue = function selectedValue() {
        if (value === null) return '';
        if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) !== 'object') {
          var valOption = options.filter(function (option) {
            return option[valueKey] === value;
          });
          if (valOption.length) {
            return valOption[0];
          }
        }
        return value;
      };

      var handleChange = function handleChange(option, keys) {
        var isSelected = selectedOptions.includes(option.value);
        if (isSelected) {
          _this2.setState({
            menuIsOpen: true
          });
        } else {
          _onChange(option, keys);
          _this2.setState({
            menuIsOpen: false
          });
        }
      };

      var renderCount = function renderCount() {
        // Get the total length of all dropdown options that are not disabled
        var optionsCount = isGroupedFilterDropdown ? options.map(function (optionArray) {
          return optionArray.options.filter(function (option) {
            return option.disabled !== true;
          }).length;
        }).reduce(function (totalCount, currentOption) {
          return totalCount + currentOption;
        }) : options.filter(function (option) {
          return option.disabled !== true;
        }).length;
        if (optionsCount === 0) return null;
        return React.createElement(
          'div',
          { className: 'filterDropdownV2__count' },
          selectedOptions.length + '/' + optionsCount
        );
      };

      var renderLabelContainer = function renderLabelContainer() {
        // If we are using the inline variant, display only the label if included.
        if (isInline) {
          if (label) {
            return React.createElement(
              'div',
              { className: 'filterDropdownV2__label' },
              label
            );
          }
        }
        return React.createElement(
          'div',
          { className: 'filterDropdownV2__labelContainer' },
          React.createElement(
            'div',
            { className: 'filterDropdownV2__labelRow' },
            React.createElement(
              'div',
              { className: 'filterDropdownV2__label' },
              label
            ),
            showCount && renderCount(),
            isRequired && React.createElement(
              'span',
              { className: 'filterDropdownV2__required' },
              '*Required'
            )
          ),
          description && React.createElement(
            'div',
            { className: 'filterDropdownV2__description' },
            description
          )
        );
      };

      var onMenuOpen = function onMenuOpen() {
        return _this2.setState({ menuIsOpen: true });
      };
      var onMenuClose = function onMenuClose() {
        return _this2.setState({ menuIsOpen: false });
      };

      var commonProps = _extends({}, this.props, {
        formatOptionLabel: optionComponent || null,
        isDisabled: isDisabled,
        isSearchable: isSearchable,
        name: name,
        noOptionsMessage: typeof noResultsText === 'string' ? function () {
          return noResultsText;
        } : function (inputVal) {
          return noResultsText(inputVal);
        },
        onChange: function onChange(option) {
          return isGroupedFilterDropdown ? handleChange(option || null, { labelKey: labelKey, valueKey: valueKey }) : _onChange(option || null, { labelKey: labelKey, valueKey: valueKey });
        },
        placeholder: placeholder,
        styles: FilterDropdownV2Styles(this.props),
        value: hideSelectedValue ? '' : selectedValue(),
        maxMenuHeight: maxMenuHeight,
        menuPlacement: menuPlacement
      });

      var hasLabelContent = isRequired || showCount || label || description;

      return React.createElement(
        'div',
        {
          className: classNames('filterDropdownV2', className, {
            'filterDropdownV2--isInline': isInline
          })
        },
        hasLabelContent && renderLabelContainer(),
        requestFn ? React.createElement(AsyncSelect, _extends({}, commonProps, {
          ref: function ref(_ref2) {
            _this2.selectRef = _ref2;
          },
          components: {
            Option: optionComponent || CustomOption,
            ValueContainer: CustomValueContainer,
            DropdownIndicator: CustomDropdownIndicator
          },
          getOptionLabel: function getOptionLabel(data) {
            return data[labelKey];
          },
          getOptionValue: function getOptionValue(data) {
            return data[valueKey];
          },
          loadOptions: debounce(this.handleLoadOptions, debounceVal),
          blurInputOnSelect: true
        })) : React.createElement(Select, _extends({}, commonProps, {
          ref: function ref(_ref3) {
            _this2.selectRef = _ref3;
          },
          components: {
            Option: optionComponent || CustomOption,
            ValueContainer: CustomValueContainer,
            DropdownIndicator: CustomDropdownIndicator
          },
          isOptionDisabled: function isOptionDisabled(option) {
            return option.disabled;
          },
          onMenuOpen: onMenuOpen,
          onMenuClose: onMenuClose,
          menuIsOpen: this.state.menuIsOpen,
          blurInputOnSelect: true
        })),
        hasError && errorText && !isInline && React.createElement(
          'span',
          { className: 'filterDropdownV2__errorText' },
          errorText
        )
      );
    }
  }]);

  return FilterDropdownV2;
}(React.Component);

export default FilterDropdownV2;


FilterDropdownV2.propTypes = propTypes;
FilterDropdownV2.defaultProps = defaultProps;
FilterDropdownV2.propDescriptions = propDescriptions;