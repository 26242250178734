import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PhotoPortrait = function PhotoPortrait(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'PhotoPortrait__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      null,
      React.createElement(
        'g',
        null,
        React.createElement('ellipse', { cx: '468.2', cy: '780.1', rx: '40.8', ry: '41.7' })
      ),
      React.createElement(
        'g',
        null,
        React.createElement('path', {
          d: 'M250,0v1000h224.6H750V0H250z M291.7,41.7h416.7v816.1l-52.7-47.2L535.9,933.2l-43.5-44.5l-59.3,69.7H291.7V41.7z' // eslint-disable-line max-len
        })
      )
    )
  );
};

PhotoPortrait.propTypes = propTypes;

export default PhotoPortrait;