import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { useNavigate } from 'react-router-dom';
import { Input, InputProps } from '../../blocks/input';
import { Select, SelectProps, SelectValue } from '../../blocks/select';
import { Button, BUTTON_TYPES } from '../../blocks/button';
import './gallery_form.scss';
import { GALLERY_FORM_FIELDS, GalleryFormProps } from './gallery_form.types';
import { AuthWrapper } from '../../blocks/authwrapper/auth_wrapper.connect';
import {
  FEATURE_FLAGS,
  FEATURE_FLAG_COMPARISON_TYPES,
} from '../../blocks/authwrapper/auth_wrapper.types';
import { CONTENT_CATEGORY_FEATURE_FLAGS } from '../../blocks/authwrapper/auth_wrapper.constants';
import { RadioGroup, RadioGroupProps } from '../../blocks/radio_group';
import { submitGalleryFormData } from './gallery_form.utils';
import { withController } from '../../hooks/withController';
import { GALLERY_FORM_MAX_TAGS } from './gallery_form.constants';
import { useToast } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../blocks/toast';
import { TEXT_VARIANTS } from '../../blocks/text';

export const GalleryForm = ({
  globalTagOptions,
  personaTagOptions,
  campaignTagOptions,
  locationTypeOptions,
  locationOptions,

  getGlobalTags,
  getNetworkTags,
  getLocations,
  triggerGetGalleries,
  handleGalleryFormSubmit,

  buttonCopy,
  defaultValues,
  isCreateForm,
  children,
  publisherSignals,
}: GalleryFormProps) => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const methods = useForm({ mode: 'onTouched', defaultValues });

  const [locationType, setLocationType] = useState(
    defaultValues[GALLERY_FORM_FIELDS.LOCATION_TYPES],
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getGlobalTags();
    getNetworkTags();
  }, []);

  useEffect(() => {
    getLocations(locationType?.value);
  }, [locationType]);

  const ControlledSelect = withController<
    SelectValue<false>,
    SelectProps<false>
  >(Select);
  const ControlledMultiSelect = withController<
    SelectValue<true>,
    SelectProps<true>
  >(Select);
  const ControlledInput = withController<string, InputProps>(Input);
  const ControlledRadioGroup = withController<string, RadioGroupProps>(
    RadioGroup,
  );

  const validateMaxLengthTags = (
    val: SelectValue<boolean>,
    fieldName:
      | GALLERY_FORM_FIELDS.CAMPAIGN
      | GALLERY_FORM_FIELDS.THEMES
      | GALLERY_FORM_FIELDS.LOCATIONS
      | GALLERY_FORM_FIELDS.PERSONAS,
    translate: TranslateFunction,
  ) => {
    if (!val) {
      return true;
    }
    const maxVal = GALLERY_FORM_MAX_TAGS[fieldName];
    return val.length > maxVal
      ? `${translate('forms.validation.maxSelectableTags', {
          maxSelectableTags: maxVal,
          type: fieldName.replace('gallery', '').toLowerCase(),
        })}`
      : true;
  };

  return (
    <Translate>
      {({ translate }) => {
        return (
          <FormProvider {...methods}>
            <div className="gallery_form">
              <form
                onSubmit={methods.handleSubmit(
                  (data) => {
                    return submitGalleryFormData(
                      data,
                      setIsSubmitting,
                      showToast,
                      handleGalleryFormSubmit,
                      translate,
                      isCreateForm,
                      triggerGetGalleries,
                      navigate,
                    );
                  },
                  () => {
                    showToast({
                      message: `${translate(
                        'galleries.form.failedValidation',
                      )}`,
                      type: TOAST_TYPES.ERROR,
                    });
                  },
                )}
              >
                <ControlledInput
                  name={GALLERY_FORM_FIELDS.NAME}
                  label={`${translate('galleries.form.nameLabel')}`}
                  placeholder={`${translate('galleries.form.namePlaceholder')}`}
                  validationRules={{
                    required: `${translate('forms.validation.requiredName')}`,
                  }}
                />

                <div className="gallery_form__radio-group">
                  <ControlledRadioGroup
                    name={GALLERY_FORM_FIELDS.PUBLISHER_SIGNALS}
                    label={`${translate(
                      'galleries.form.publisherSignalLabel',
                    )}`}
                    labelAs={TEXT_VARIANTS.H3}
                    options={publisherSignals}
                  />
                </div>

                <AuthWrapper
                  featureFlags={CONTENT_CATEGORY_FEATURE_FLAGS}
                  comparisonType={FEATURE_FLAG_COMPARISON_TYPES.LOOSE}
                >
                  <h2>{translate('galleries.form.configureGallery')}</h2>
                  <p className="gallery_form--configure-gallery">
                    {translate('galleries.form.configureGalleryDescription')}
                  </p>

                  <AuthWrapper
                    featureFlags={FEATURE_FLAGS.GALLERY_CAMPAIGN_FIELD}
                  >
                    <ControlledSelect
                      options={campaignTagOptions}
                      label={`${translate('galleries.form.campaignLabel')}`}
                      name={GALLERY_FORM_FIELDS.CAMPAIGN}
                      description={`${translate(
                        'galleries.form.campaignDescription',
                      )}`}
                      placeholder={`${translate(
                        'galleries.form.campaignPlaceholder',
                      )}`}
                      isMulti={false}
                    />
                  </AuthWrapper>
                  <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_THEME_FIELD}>
                    <ControlledMultiSelect
                      options={globalTagOptions}
                      label={`${translate('galleries.form.themeLabel')}`}
                      name={GALLERY_FORM_FIELDS.THEMES}
                      isMulti={true}
                      validationRules={{
                        validate: (value: SelectValue<true>) =>
                          validateMaxLengthTags(
                            value,
                            GALLERY_FORM_FIELDS.THEMES,
                            translate,
                          ),
                      }}
                      description={`${translate(
                        'galleries.form.themeDescription',
                      )}`}
                      placeholder={`${translate(
                        'galleries.form.themePlaceholder',
                      )}`}
                    />
                  </AuthWrapper>
                  <AuthWrapper
                    featureFlags={FEATURE_FLAGS.GALLERY_PERSONA_FIELD}
                  >
                    <ControlledMultiSelect
                      options={personaTagOptions}
                      label={`${translate('galleries.form.personaLabel')}`}
                      name={GALLERY_FORM_FIELDS.PERSONAS}
                      isMulti={true}
                      validationRules={{
                        validate: (value: SelectValue<true>) =>
                          validateMaxLengthTags(
                            value,
                            GALLERY_FORM_FIELDS.PERSONAS,
                            translate,
                          ),
                      }}
                      description={`${translate(
                        'galleries.form.personaDescription',
                      )}`}
                      placeholder={`${translate(
                        'galleries.form.personaPlaceholder',
                      )}`}
                    />
                  </AuthWrapper>
                  <AuthWrapper
                    featureFlags={FEATURE_FLAGS.GALLERY_LOCATION_FIELD}
                  >
                    <h2>{translate('galleries.form.locationLabel')}</h2>
                    <p className="gallery_form--configure-gallery">
                      {translate('galleries.form.configureGalleryDescription')}
                    </p>
                    <ControlledSelect
                      options={locationTypeOptions}
                      label={`${translate(
                        'galleries.form.locationTypesLabel',
                      )}`}
                      name={GALLERY_FORM_FIELDS.LOCATION_TYPES}
                      onChange={(selectedLocationType) => {
                        setLocationType(selectedLocationType);
                        methods.setValue(GALLERY_FORM_FIELDS.LOCATIONS, []);
                      }}
                      placeholder={`${translate(
                        'galleries.form.locationTypesPlaceholder',
                      )}`}
                    />
                    <ControlledMultiSelect
                      options={locationOptions}
                      label={`${translate('galleries.form.locationLabel')}`}
                      description={`${translate(
                        'galleries.form.locationDescription',
                      )}`}
                      name={GALLERY_FORM_FIELDS.LOCATIONS}
                      isMulti={true}
                      validationRules={{
                        validate: (value: SelectValue<true>) =>
                          validateMaxLengthTags(
                            value,
                            GALLERY_FORM_FIELDS.LOCATIONS,
                            translate,
                          ),
                      }}
                      placeholder={`${translate(
                        'galleries.form.locationPlaceholder',
                      )}`}
                    />
                  </AuthWrapper>
                </AuthWrapper>
                <div className="gallery_form__footer">
                  <Button type={BUTTON_TYPES.SUBMIT} isDisabled={isSubmitting}>
                    {translate(`galleries.form.${buttonCopy}`)}
                  </Button>
                </div>
              </form>
            </div>
            {children}
          </FormProvider>
        );
      }}
    </Translate>
  );
};
