import React, { FunctionComponent } from 'react';
import './chip.scss';

interface ChipProp {
  isDark?: boolean;
}

export const Chip: FunctionComponent<ChipProp> = ({ children, isDark }) => {
  const dark = isDark ? 'dark' : '';
  return <div className={`chip ${dark}`}>{children}</div>;
};
