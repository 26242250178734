import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Check = function Check(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'check__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1288.8 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1259,29.9c-39.8-39.8-104.4-39.8-144.2,0l-724,724L174.1,537.1c-39.8-39.8-104.4-39.8-144.2,0c-39.8,39.8-39.8,104.4,0,144.2l288.8,288.8c19.9,19.9,46,29.9,72.1,29.9c6.5,0,13-0.6,19.5-1.9c12.9-2.5,25.3-7.5,36.6-14.9c5.7-3.7,11-8.1,16-13.1L1259,174.1C1298.8,134.3,1298.8,69.7,1259,29.9z' // eslint-disable-line max-len
    })
  );
};

Check.propTypes = propTypes;

export default Check;