import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var TwitterShare = function TwitterShare(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'twitterLike__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 14 12',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M11.7 0H1.5C.7 0 0 .7 0 1.5v9c0 .8.7 1.5 1.5 1.5h10.2c.8 0 1.5-.7 1.5-1.5v-9C13.1.7 12.5 0 11.7 0zM1.5 1h10.2c.3 0 .5.2.5.5v.8L6.9 5.8c-.2.1-.4.1-.6 0L1 2.3v-.8c0-.3.2-.5.5-.5zm10.2 10H1.5c-.3 0-.5-.2-.5-.5v-7l4.8 3.2c.3.2.5.3.8.3.3 0 .6-.1.8-.3l4.8-3.2v7c0 .2-.3.5-.5.5z' // eslint-disable-line max-len
    })
  );
};

TwitterShare.propTypes = propTypes;

export default TwitterShare;