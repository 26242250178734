import React, {
  PureComponent,
  ReactElement,
  useEffect,
  VoidFunctionComponent,
} from 'react';
import ReactDOM from 'react-dom';
import {
  withLocalize,
  LocalizeContextProps,
  Translate,
} from 'react-localize-redux';
import { BrowserRouter as Router, Navigate, useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { getLanguage, initializePayload } from './copy/copy.utils';
import { LANGUAGE_CODE } from './copy/copy.types';
import { translations } from './copy/languages/fr/translations';
import { ROUTES } from './types';
import './index.scss';
import '@crowdriff/flock/lib/main.css';
import { Navigation } from './react/blocks/navigation/navigation.component';
import { getUser } from './http/user.thunks';
import { LoadStateData } from './react/blocks/load_data/load_state_data.component';
import { GET_USER_TYPES } from './redux/user/user.types';
import { LoadingGif } from './react/blocks/LoadingGif/LoadingGif.component';
import { GalleryEditPageConnect } from './react/pages/galleries_edit/gallery_edit.connect';
import { AppProviders } from './appProviders';
import { GalleryCreatePage } from './react/pages/galleries_create/gallery_create.component';
import { StoriesListPage } from './react/pages/stories_list';
import { GalleryListPage } from './react/pages/galleries_list';
import { StoryManageConnect } from './react/pages/stories_manage/story_manage.connect';
import { NetworkPage } from './react/pages/network/network.component';
import { AdminPage } from './react/pages/admin/admin.component';
import { AccountSettingsPage } from './react/pages/account_settings';
import {
  selectCurrentPublisherFeatureFlags,
  selectIsAccountSettingsEnabled,
  selectIsUserInternal,
  selectUserAcceptance,
} from './redux/user/user.selectors';
import { userHasAccess } from './react/blocks/authwrapper/auth_wrapper.utils';
import {
  FEATURE_FLAGS,
  FEATURE_FLAG_COMPARISON_TYPES,
} from './react/blocks/authwrapper/auth_wrapper.types';
import { TOSUserAcceptanceCheck } from './http/tos-acceptance.thunk';
import { TermsOfService } from './react/features/terms_of_service';
import { ErrorPage } from './react/blocks/error_page/error_page.component';
import NoAccess from './assets/no_access.svg';
import { Button, BUTTON_COLOR_VARIANT } from './react/blocks/button';
import { SNACKBAR_LIMIT } from './react/blocks/toast/toast.types';

export const routeMapping = (
  featureFlags: FEATURE_FLAGS | FEATURE_FLAGS[],
  isUserInternal: boolean,
  isAccountSettingsEnabled: boolean,
) => {
  return [
    {
      path: ROUTES.HOME,
      element: userHasAccess(featureFlags, FEATURE_FLAGS.LANDING_PAGE) ? (
        <NetworkPage />
      ) : (
        <Navigate to={ROUTES.STORIES_LIST} />
      ),
    },
    {
      path: ROUTES.GALLERIES_LIST,
      element: userHasAccess(
        featureFlags,
        [FEATURE_FLAGS.STATIC_GALLERY, FEATURE_FLAGS.DYNAMIC_GALLERY],
        FEATURE_FLAG_COMPARISON_TYPES.LOOSE,
      ) ? (
        <GalleryListPage />
      ) : (
        <Navigate to={ROUTES.STORIES_LIST} />
      ),
    },
    {
      path: ROUTES.GALLERIES_CREATE,
      element: userHasAccess(
        featureFlags,
        [FEATURE_FLAGS.STATIC_GALLERY, FEATURE_FLAGS.DYNAMIC_GALLERY],
        FEATURE_FLAG_COMPARISON_TYPES.LOOSE,
      ) ? (
        <GalleryCreatePage />
      ) : (
        <Navigate to={ROUTES.STORIES_LIST} />
      ),
    },
    {
      path: ROUTES.GALLERIES_EDIT,
      element: userHasAccess(
        featureFlags,
        [FEATURE_FLAGS.STATIC_GALLERY, FEATURE_FLAGS.DYNAMIC_GALLERY],
        FEATURE_FLAG_COMPARISON_TYPES.LOOSE,
      ) ? (
        <GalleryEditPageConnect />
      ) : (
        <Navigate to={ROUTES.STORIES_LIST} />
      ),
    },
    {
      path: ROUTES.STORIES_LIST,
      element: <StoriesListPage />,
    },
    {
      path: ROUTES.STORIES_MANAGE,
      element: <StoryManageConnect />,
    },
    {
      path: ROUTES.ADMIN,
      element: isUserInternal ? <AdminPage /> : <Navigate to={ROUTES.HOME} />,
    },
    { path: ROUTES.NOT_FOUND, element: <div>not found skeleton</div> },
    {
      path: ROUTES.ACCOUNT_SETTINGS,
      element: isAccountSettingsEnabled ? (
        <AccountSettingsPage />
      ) : (
        <Navigate to={ROUTES.STORIES_LIST} />
      ),
    },
  ];
};

const Routing: VoidFunctionComponent = () => {
  const featureFlags = useSelector(selectCurrentPublisherFeatureFlags);
  const isUserInternal = useSelector(selectIsUserInternal);
  const isAccountSettingsEnabled = useSelector(selectIsAccountSettingsEnabled);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(TOSUserAcceptanceCheck());
  }, [dispatch]);
  const isUserAccepted = useSelector(selectUserAcceptance);
  if (!isUserAccepted) {
    return <TermsOfService />;
  }

  return useRoutes(
    routeMapping(featureFlags, isUserInternal, isAccountSettingsEnabled),
  );
};
interface AppProps extends LocalizeContextProps {
  languageIsLoaded?: boolean;
}
export class App extends PureComponent<AppProps> {
  constructor(props: AppProps) {
    super(props);
    const language = getLanguage(window.navigator);
    const {
      initialize,
      addTranslationForLanguage,
      setActiveLanguage,
    } = this.props;
    initialize(initializePayload);
    if (language === LANGUAGE_CODE.FRENCH) {
      addTranslationForLanguage(translations, LANGUAGE_CODE.FRENCH);
      setActiveLanguage(LANGUAGE_CODE.FRENCH);
    }
  }

  handleChange = () => {
    window.open('https://support.crowdriff.com/hc/en-us/requests/new');
  };

  render(): ReactElement {
    return (
      <LoadStateData
        actionTypes={GET_USER_TYPES}
        loading={<LoadingGif size="large" />}
        thunk={getUser}
        failure={
          <Translate>
            {({ translate }) => {
              return (
                <ErrorPage
                  errorImg={<NoAccess />}
                  title={`${translate('general.error.title')}`}
                  description={`${translate('general.error.body')}`}
                  CTA={[
                    <Button
                      onClick={this.handleChange}
                      color={BUTTON_COLOR_VARIANT.SECONDARY}
                    >
                      {translate('general.error.cta')}
                    </Button>,
                  ]}
                />
              );
            }}
          </Translate>
        }
      >
        <Navigation />
        <Routing />
      </LoadStateData>
    );
  }
}

export const LocalizedApp = withLocalize(App);
ReactDOM.render(
  <Router>
    <AppProviders>
      <React.Fragment>
        <LocalizedApp />
        <ToastContainer
          position="bottom-right"
          limit={SNACKBAR_LIMIT}
          hideProgressBar={true}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          closeOnClick={true}
          className="toastContainer"
        />
      </React.Fragment>
    </AppProviders>
  </Router>,
  document.getElementById('root'),
);
