import { connect } from 'react-redux';
import { AppState } from '../../../types';
import { ReportingComponent } from './reporting.component';
import { selectReportUrl } from '../../../redux/reports/reports.selectors';

const mapStateToProps = (state: AppState) => {
  return {
    reportUrl: selectReportUrl(state),
  };
};

export const Reporting = connect(mapStateToProps)(ReportingComponent);
