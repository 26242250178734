import React, { FunctionComponent } from 'react';
import { StepLabel, Step } from '@material-ui/core';
import classNames from 'classnames';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import LeftChevron from '../../../assets/left-chevron.svg';
import CloseIcon from '../../../assets/close-icon.svg';
import LocalhoodLogoBlack from '../../../assets/localhood-logo-black.svg';
import { HorizontalStepper } from '../stepper/stepper.component';
import { Button, BUTTON_VARIANT } from '../button';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';

import { Header } from './header.component';
import './navbar.scss';

export interface CustomClasses {
  root?: string;
}
export interface NavbarProps extends LocalizeContextProps {
  titlesForEachStep: string[];
  numberOfSteps: number;
  currentStepIndex?: number;
  totalSteps?: number;
  classes?: CustomClasses;
  handleClose?: () => void;
  handleBack?: () => void;
}

const NavbarComponent: FunctionComponent<NavbarProps> = ({
  currentStepIndex = 0,
  numberOfSteps,
  classes = {},
  titlesForEachStep,
  handleClose,
  handleBack,
  translate,
  ...props
}) => {
  const getTranslation = useTranslateStrings(translate);

  const BackComponent = () => {
    if (currentStepIndex === 0) {
      return <LocalhoodLogoBlack />;
    }
    return (
      <Button
        className="navbar__button"
        ariaLabel={getTranslation('general.buttons.back')}
        onClick={handleBack}
        variant={BUTTON_VARIANT.GHOST}
      >
        <LeftChevron className="navbar__button icon" />
        <span className="navbar__button-description">
          {getTranslation('general.buttons.back')}
        </span>
      </Button>
    );
  };

  const steps = () => {
    const individualSteps: JSX.Element[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < numberOfSteps; i++) {
      individualSteps.push(
        <Step key={i}>
          <StepLabel />
        </Step>,
      );
    }
    return individualSteps;
  };

  return (
    <Header>
      <div className="navbar" {...props}>
        <div className={classNames('navbar__root', classes.root)}>
          <BackComponent />
          <div className="navbar-stepper__container">
            <span className="navbar-stepper__title">
              {titlesForEachStep[currentStepIndex]}
            </span>
            <HorizontalStepper currentStepIndex={currentStepIndex}>
              {steps()}
            </HorizontalStepper>
          </div>
          <Button
            ariaLabel={getTranslation('general.buttons.close')}
            onClick={handleClose}
            variant={BUTTON_VARIANT.GHOST}
          >
            <CloseIcon className="navbar__button icon" />
            <span className="navbar__button-description">Close</span>
          </Button>
        </div>
      </div>
    </Header>
  );
};

export const Navbar = withLocalize(NavbarComponent);
