import React, { ReactElement } from 'react';
import './navigation.scss';
import { Translate } from 'react-localize-redux';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CrowdriffLogo from '../../../assets/crowdriff-light-letters.svg';
import { ROUTES } from '../../../types';
import { logout } from '../../../http/logout.thunk';
import { UtilityMenu } from '../../features/utility_menu/utility_menu.connect';
import { triggerHydrateAction } from '../../pages/galleries_list/galleries_list.actions';
import { AuthWrapper } from '../authwrapper/auth_wrapper.connect';
import {
  FEATURE_FLAGS,
  FEATURE_FLAG_COMPARISON_TYPES,
} from '../authwrapper/auth_wrapper.types';
import { Reporting } from '../../features/reporting';

export enum NAVIGATION_STATE {
  ACTIVE = 'active',
}

export const Navigation = (): ReactElement => {
  const dispatch = useDispatch();
  return (
    <nav id="navigation" className="navigation">
      <div className="navigation__innerWrapper">
        <div className="navigation__innerWrapper--left">
          <figure className="navigation__innerWrapper--left--logo">
            <CrowdriffLogo />
          </figure>
          <ul>
            <AuthWrapper featureFlags={FEATURE_FLAGS.LANDING_PAGE}>
              <NavLink to={ROUTES.HOME} end={true}>
                <li>
                  <p>
                    <Translate id="general.navigation.network" />
                  </p>
                </li>
              </NavLink>
            </AuthWrapper>
            <NavLink to={ROUTES.STORIES_LIST}>
              <li>
                <p>
                  <Translate id="general.navigation.stories" />
                </p>
              </li>
            </NavLink>
            <AuthWrapper
              featureFlags={[
                FEATURE_FLAGS.STATIC_GALLERY,
                FEATURE_FLAGS.DYNAMIC_GALLERY,
              ]}
              comparisonType={FEATURE_FLAG_COMPARISON_TYPES.LOOSE}
            >
              <NavLink
                to={ROUTES.GALLERIES_LIST}
                onClick={() => dispatch(triggerHydrateAction())}
              >
                <li>
                  <p>
                    <Translate id="general.navigation.galleries" />
                  </p>
                </li>
              </NavLink>
            </AuthWrapper>
            <Reporting />
          </ul>
        </div>
        <UtilityMenu logout={logout} />
      </div>
    </nav>
  );
};
