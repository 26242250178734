import React, {
  useEffect,
  useState,
  VoidFunctionComponent,
  Fragment,
} from 'react';
import classNames from 'classnames';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useMemoOne } from 'use-memo-one';
import '../story_list.scss';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { DetailedStoryModerationAndStatus } from '../../detailed_story/detailed_story_stories_page';
import { Search } from '../../../blocks/search/search.component';
import { StoriesSearchFilters } from '../../stories_search_filters/stories_search_filters.connect';
import {
  getStoriesSearch,
  STORY_SEARCH_LOAD_TYPES,
} from '../../../../http/stories_search.thunks';
import { storiesSearchStateToFilterParams } from '../../stories_search_form.component/stories_search_form.utils';
import {
  clearStoriesSearchQuery,
  storiesClearSearchFiltersAction,
  storiesResetFilterTabAction,
  storiesSearchQuery,
} from '../../../../redux/stories/stories.actions';
import { useModal } from '../../../hooks/use_modal/use_modal';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
} from '../../../blocks/button';
import { StoryList } from '../story_list.connect';
import { scrollToTopGenerator } from '../../../../utils';
import { StoryListModerationAndStatusProps } from './story_list_moderation_and_status.types';
import { selectCurrentPublisherId } from '../../../../redux/user/user.selectors';
import { StoryListEmptyState } from '../story_list_empty_state/story_list_empty_state.component';
import { Link, LINK_VARIANTS } from '../../../blocks/link';
import { StoryListTabsBar } from '../story_list_tabs_bar';
import { AuthWrapper } from '../../../blocks/authwrapper/auth_wrapper.connect';
import { FEATURE_FLAGS } from '../../../blocks/authwrapper/auth_wrapper.types';

const StoryListModerationAndStatusComponent: VoidFunctionComponent<StoryListModerationAndStatusProps> = ({
  translate,
  stories,
  loadingType,
  isChallengeModerator,
  mainListFilterData,
  searchQuery,
  selectedFilterTab,
  scrollElement,
}) => {
  const dispatch = useDispatch();
  const [queryValue, setQueryValue] = useState('');
  const publisherId = useSelector(selectCurrentPublisherId);
  const [Modal, toggle, isOpen] = useModal();
  const getTranslation = useTranslateStrings(translate);
  useEffect(() => {
    dispatch(storiesClearSearchFiltersAction());
  }, []);

  const handleClearSearch = () => {
    const searchFilters = storiesSearchStateToFilterParams(
      undefined,
      mainListFilterData,
      selectedFilterTab,
    );
    dispatch(clearStoriesSearchQuery());
    setQueryValue('');
    dispatch(
      getStoriesSearch({
        searchFilters,
        pagination: { pageSize: 20 },
        loadingType: STORY_SEARCH_LOAD_TYPES.SEARCH,
      }),
    );
  };
  const handleSearch = (searchValue: string) => {
    dispatch(storiesSearchQuery(searchValue));
    dispatch(
      getStoriesSearch({
        searchFilters: storiesSearchStateToFilterParams(
          searchValue,
          mainListFilterData,
          selectedFilterTab,
        ),
        pagination: { pageSize: 20 },
        loadingType: STORY_SEARCH_LOAD_TYPES.SEARCH,
      }),
    );
  };

  const handleScrollToTop = scrollToTopGenerator(window);

  const showEmptyState =
    loadingType === STORY_SEARCH_LOAD_TYPES.INIT && stories.length === 0;

  const filterData = useMemoOne(() => {
    return storiesSearchStateToFilterParams(
      searchQuery,
      mainListFilterData,
      selectedFilterTab,
    );
  }, [searchQuery, mainListFilterData, selectedFilterTab]);

  return (
    <div
      className={classNames('story_list', {
        empty_state: showEmptyState,
      })}
    >
      {showEmptyState ? (
        <StoryListEmptyState
          title={getTranslation('stories.emptyState.title')}
          description={getTranslation('stories.emptyState.description')}
        />
      ) : (
        <Fragment>
          <div className="story_list--actions">
            <div className="story_list--search_actions">
              <Search
                id="story_search"
                ariaLabel={getTranslation('stories.search.ariaLabel')}
                placeholder={getTranslation('stories.search.placeholder')}
                onClearSearch={handleClearSearch}
                onSearch={handleSearch}
              />
              <StoriesSearchFilters />
            </div>
            <div className="story_list--buttons">
              <Link
                className="story_list--buttons__create_story"
                href={`${
                  process.env.STORY_CREATOR_URL
                }/editor/new?publisher-slug=${encodeURIComponent(publisherId)}`}
                target="_self"
                rel="noreferrer"
                linkContent={
                  <Button
                    variant={BUTTON_VARIANT.SOLID}
                    color={BUTTON_COLOR_VARIANT.PRIMARY}
                    ariaLabel={getTranslation(
                      'stories.button.createStoryLabel',
                    )}
                  >
                    {getTranslation('stories.button.createStory')}
                  </Button>
                }
                variant={LINK_VARIANTS.PRIMARY}
              />
            </div>
          </div>

          <AuthWrapper featureFlags={FEATURE_FLAGS.SHOW_CREATOR_CONTENT}>
            <StoryListTabsBar />
          </AuthWrapper>

          <StoryList
            stories={stories}
            StoryCard={DetailedStoryModerationAndStatus}
            handleScrollToTop={handleScrollToTop}
            filterData={filterData}
            scrollElement={scrollElement}
          />
        </Fragment>
      )}
    </div>
  );
};

export const StoryListModerationAndStatus = withLocalize(
  StoryListModerationAndStatusComponent,
);
