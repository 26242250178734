export const numFormatter = (num: number) => {
  if (num > 999 && num < 1000000) {
    return `${(num / 1000).toFixed(1)}K`; // convert to K for num from > 1000 < 1 million
  }
  if (num > 1000000) {
    return `${(num / 1000000).toFixed(1)}M`; // convert to M for num from > 1 million
  }
  if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
  return num;
};

export const statFormatterFn = (
  stat: number | string,
  isVertical: boolean = true,
) => {
  if (!stat) {
    return '--';
  }
  if (typeof stat === 'string') {
    return stat;
  }
  return isVertical
    ? stat.toLocaleString('en-US')
    : numFormatter(stat).toString();
};
