import React, { ReactChild, ReactNode } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {
  TOAST_TYPES,
  TOAST_VARIANTS,
  SNACKBAR_AUTO_CLOSE_MS,
  Toast,
} from '../../blocks/toast';
import { TEXT_VARIANTS, Text } from '../../blocks/text';
import { BUTTON_VARIANT, Button } from '../../blocks/button';
import { ICON_NAMES, ICON_SIZES, Icon } from '../../blocks/icon';

export interface UseToastProps {
  variant?: TOAST_VARIANTS;
  type?: TOAST_TYPES;
  key?: string;
  message: string;
  description?: string;
  status?: string | ReactChild;
  imgUrl?: string;
  closeText?: string;
  autoClose?: number | false | undefined;
  onClose?: () => void;
  role?: string;
  customActionCTA?: ReactNode;
}

export const useToast = () => {
  const showToast = ({
    variant = TOAST_VARIANTS.BASIC,
    type,
    key = uuidv4(),
    message,
    description,
    status,
    imgUrl,
    autoClose = SNACKBAR_AUTO_CLOSE_MS,
    onClose,
    role = 'alert',
    closeText,
    customActionCTA,
  }: UseToastProps) => {
    const CloseElement = (
      <Button variant={BUTTON_VARIANT.GHOST} onClick={() => toast.dismiss(key)}>
        {closeText ? (
          <Text variant={TEXT_VARIANTS.H4}>{closeText}</Text>
        ) : (
          <Icon iconKey={ICON_NAMES.CLOSE} size={ICON_SIZES.MED} />
        )}
      </Button>
    );

    const ToastComponent = (
      <Toast
        imgUrl={imgUrl}
        description={description}
        message={message}
        status={status}
        customActionCTA={customActionCTA}
      />
    );

    const icon = variant === TOAST_VARIANTS.BASIC;
    const hideProgressBar = variant === TOAST_VARIANTS.BANNER;

    if (toast.isActive(key)) {
      toast.update(key, {
        type,
        render: ToastComponent,
        autoClose,
        onClose,
        closeButton: CloseElement,
        hideProgressBar,
      });
    } else {
      toast(ToastComponent, {
        type,
        toastId: key,
        autoClose,
        onClose,
        closeButton: CloseElement,
        role,
        icon,
        position: 'bottom-right',
        hideProgressBar,
      });
    }
  };

  const removeToast = (key: string) => {
    toast.dismiss(key);
  };
  return { showToast, removeToast };
};
