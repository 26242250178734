import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Button from '../Button/Button';
import { tooltipPlacements } from './DynamicTooltip.constants';
import Icon from '../Icon/Icon';

var propTypes = {
  buttonColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  disableFocusListener: PropTypes.bool,
  disableHoverListener: PropTypes.bool,
  disableTouchListener: PropTypes.bool,
  isInteractive: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  openOnClick: PropTypes.bool,
  placement: PropTypes.string,
  showCloseButton: PropTypes.bool,
  ariaLabel: PropTypes.string
};

var propDescriptions = {
  buttonColor: '(only for click tooltips) specifies the color prop of the tooltip trigger',
  children: 'The content to be wrapped by the tooltip',
  className: 'classnames to add to the root tooltip node',
  content: 'the content to be rendered inside the tooltip',
  disableFocusListener: 'disable the tooltip trigger on focus',
  disableHoverListener: 'disable the tooltip trigger on hover',
  disableTouchListener: 'disable the tooltip trigger on touch',
  isInteractive: 'if this is enabled the tooltip will remain open when hovered',
  openOnClick: 'Open the tooltip when the child content is clicked. Overrides focus, touch and hover listeners',
  placement: 'where to place the tooltip relative to the child content'
};

var defaultProps = {
  buttonColor: 'blue',
  placement: 'bottom'
};

/*
This component requires access to a ref to spread event handlers
to the child component. Class components need to spread ...rest
props on the top level element to handle hover/focus/touch events.
Functional components need to be wrapped in React.forwardRef in order
to handle a ref and the event props.
see https://material-ui.com/components/tooltips/#custom-child-element
& https://material-ui.com/guides/composition/#caveat-with-refs
for more information
*/

var DynamicTooltip = function (_React$Component) {
  _inherits(DynamicTooltip, _React$Component);

  function DynamicTooltip(props) {
    _classCallCheck(this, DynamicTooltip);

    var _this = _possibleConstructorReturn(this, (DynamicTooltip.__proto__ || _Object$getPrototypeOf(DynamicTooltip)).call(this, props));

    _this.handleTooltipClose = function () {
      var onClose = _this.props.onClose;

      if (onClose) onClose();
      _this.setState({ isOpen: false });
    };

    _this.handleTooltipOpen = function () {
      var onOpen = _this.props.onOpen;

      if (onOpen) onOpen();
      _this.setState({ isOpen: true });
    };

    _this.state = {
      isOpen: false
    };
    return _this;
  }

  _createClass(DynamicTooltip, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          buttonColor = _props.buttonColor,
          children = _props.children,
          className = _props.className,
          content = _props.content,
          disableFocusListener = _props.disableFocusListener,
          disableHoverListener = _props.disableHoverListener,
          disableTouchListener = _props.disableTouchListener,
          isInteractive = _props.isInteractive,
          openOnClick = _props.openOnClick,
          placement = _props.placement,
          showCloseButton = _props.showCloseButton,
          ariaLabel = _props.ariaLabel;
      var isOpen = this.state.isOpen;

      var tooltipClasses = classNames('cr-tooltip', className);
      if (process.env.NODE_ENV === 'development' && tooltipPlacements.indexOf(placement) === -1) {
        console.warn('Invalid flock tooltip placement: ' + placement);
        return null;
      }
      var childrenWithProps = React.isValidElement(children) ? React.cloneElement(children, { handleTooltipClose: this.handleTooltipClose }) : children;
      var contentWithProps = React.isValidElement(content) ? React.cloneElement(content, { handleTooltipClose: this.handleTooltipClose }) : content;
      var tooltipContent = showCloseButton ? React.createElement(
        'div',
        { className: 'cr-tooltip__content' },
        React.createElement(
          Button,
          {
            buttonStyle: 'link',
            className: 'cr-tooltip__close',
            color: 'grey',
            onClick: this.handleTooltipClose,
            iconPosition: 'center'
          },
          React.createElement(Icon, { type: 'close', size: 12 })
        ),
        contentWithProps
      ) : contentWithProps;

      return openOnClick ? React.createElement(
        ClickAwayListener,
        { onClickAway: this.handleTooltipClose },
        React.createElement(
          'div',
          { className: 'cr-tooltip__buttonWrapper' },
          React.createElement(
            Tooltip,
            {
              classes: { popper: tooltipClasses, tooltip: 'cr-tooltip__tooltip' },
              disableFocusListener: true,
              disableHoverListener: true,
              disableTouchListener: true,
              PopperProps: {
                disablePortal: true
              },
              interactive: showCloseButton || isInteractive,
              onClose: this.handleTooltipClose,
              onOpen: this.handleTooltipOpen,
              placement: placement,
              title: tooltipContent,
              open: isOpen
            },
            React.createElement(
              Button,
              {
                ariaLabel: ariaLabel,
                buttonStyle: 'link',
                color: buttonColor,
                onClick: isOpen ? this.handleTooltipClose : this.handleTooltipOpen
              },
              childrenWithProps
            )
          )
        )
      ) : React.createElement(
        Tooltip,
        {
          classes: { popper: tooltipClasses, tooltip: 'cr-tooltip__tooltip' },
          disableFocusListener: disableFocusListener,
          disableHoverListener: disableHoverListener,
          disableTouchListener: disableTouchListener,
          interactive: showCloseButton || isInteractive,
          onClose: this.handleTooltipClose,
          onOpen: this.handleTooltipOpen,
          placement: placement,
          title: tooltipContent,
          open: isOpen
        },
        children
      );
    }
  }]);

  return DynamicTooltip;
}(React.Component);

export default DynamicTooltip;


DynamicTooltip.propTypes = propTypes;
DynamicTooltip.propDescriptions = propDescriptions;
DynamicTooltip.defaultProps = defaultProps;