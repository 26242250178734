import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PersonCutout = function PersonCutout(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'PersonCutout__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M500,0C223.9,0,0,223.9,0,500c0,148,64.3,280.8,166.4,372.4c3-106.6,32.7-253,163.8-253c14.7,0,76.8,65.9,169.7,65.9\ts155-65.9,169.7-65.9c131.1,0,160.8,146.5,163.8,253C935.7,780.9,1000,648,1000,500C1000,223.9,776.1,0,500,0z M500,649.8c-100.5,0-182.1-81.6-182.1-182.1S399.4,285.6,500,285.6S682,367.2,682,467.7S600.5,649.8,500,649.8z' // eslint-disable-line max-len
    })
  );
};

PersonCutout.propTypes = propTypes;

export default PersonCutout;