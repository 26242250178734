import React from 'react';
import { IconProps } from '../icon.types';

export const PlusCircle = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 18.4736C14.6318 18.4736 18.4668 14.6304 18.4668 10.0068C18.4668 5.375 14.6235 1.54004 9.9917 1.54004C5.36816 1.54004 1.5332 5.375 1.5332 10.0068C1.5332 14.6304 5.37646 18.4736 10 18.4736ZM10 17.0625C6.08203 17.0625 2.95264 13.9248 2.95264 10.0068C2.95264 6.08887 6.07373 2.95117 9.9917 2.95117C13.9097 2.95117 17.0474 6.08887 17.0557 10.0068C17.064 13.9248 13.918 17.0625 10 17.0625ZM6.14844 10.0068C6.14844 10.4136 6.43066 10.6875 6.854 10.6875H9.29443V13.1362C9.29443 13.5513 9.57666 13.8418 9.9834 13.8418C10.3984 13.8418 10.689 13.5596 10.689 13.1362V10.6875H13.1377C13.5527 10.6875 13.8433 10.4136 13.8433 10.0068C13.8433 9.5918 13.5527 9.30127 13.1377 9.30127H10.689V6.86084C10.689 6.4375 10.3984 6.14697 9.9834 6.14697C9.57666 6.14697 9.29443 6.4375 9.29443 6.86084V9.30127H6.854C6.43066 9.30127 6.14844 9.5918 6.14844 10.0068Z" />
    </svg>
  );
};
