import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Search = function Search(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'search_icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M402.8,83.3c176.9,0,319.4,142.5,319.4,319.4S579.7,722.2,402.8,722.2S83.3,579.7,83.3,402.8S225.8,83.3,402.8,83.3zM402.8,0C180.8,0,0,180.8,0,402.8s180.8,402.8,402.8,402.8c96,0,184.1-33.9,253.5-90.3l272.6,272.6c16.3,16.3,42.7,16.3,59,0s16.3-42.3,0-58.6L715.2,656.6c56.5-69.3,90.3-157.8,90.3-253.9C805.5,180.8,624.7,0,402.8,0L402.8,0z' // eslint-disable-line max-len
    })
  );
};

Search.propTypes = propTypes;

export default Search;