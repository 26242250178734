import axios from './axios_config';
import { CRThunk, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { getErrorMessage } from '../utils';
import { urlBuilder } from './util/url_builder';
import {
  getUserAcceptanceFailureAction,
  getUserAcceptanceStartAction,
  getUserAcceptanceSuccessAction,
} from '../redux/user/user.actions';

export interface AcceptanceObject {
  hasValidAcceptance: boolean;
}

export interface TermsAcceptanceResponse {
  acceptance: AcceptanceObject;
}
/**
 * TOSUserAcceptanceCheck Fn calls TOS acceptance call from gateway
 * @returns - returns a boolean that determines whether a user has accepted the terms of service or not
 */
export const TOSUserAcceptanceCheck: CRThunk<void, boolean> = () => async (
  dispatch,
) => {
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.USER_ACCEPTANCE,
  });
  dispatch(getUserAcceptanceStartAction());
  try {
    const response = await axios.get<TermsAcceptanceResponse>(url);
    const acceptanceRes = response.data.acceptance.hasValidAcceptance;
    dispatch(getUserAcceptanceSuccessAction(acceptanceRes));
    return acceptanceRes;
  } catch (e) {
    dispatch(getUserAcceptanceFailureAction(e));
    throw new Error(
      getErrorMessage(e, 'Failed to call terms of service acceptance.'),
    );
  }
};
