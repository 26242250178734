import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Lock = function Lock(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'angledown__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 833.5 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M791.8,416.8h-41.6C750.2,186.6,600.9,0,416.7,0C232.6,0,83.3,186.6,83.3,416.8H41.7c-23,0-41.7,18.7-41.7,41.7v499.9c0,23,18.6,41.7,41.7,41.7h750.1c23,0,41.7-18.7,41.7-41.7V458.5C833.5,435.5,814.8,416.8,791.8,416.8z M416.7,83.4c137.9,0,250.1,149.6,250.1,333.4H166.6C166.6,232.9,278.8,83.4,416.7,83.4z' // eslint-disable-line max-len
    })
  );
};

Lock.propTypes = propTypes;

export default Lock;