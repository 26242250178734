import { connect } from 'react-redux';
import { AppState } from '../../../../types';
import {
  selectStoriesFilterTab,
  selectStoriesSearchFilters,
  selectStoriesSearchQuery,
} from '../../../../redux/stories/stories.selectors';
import StoryListTabsBarComponent from './story_list_tabs_bar.component';

const mapStateToProps = (state: AppState) => ({
  selectedFilterTab: selectStoriesFilterTab(state),
  appliedFilters: selectStoriesSearchFilters(state) ?? {},
  searchQuery: selectStoriesSearchQuery(state),
});

export const StoryListTabsBar = connect(mapStateToProps)(
  StoryListTabsBarComponent,
);
