import React from 'react';
import './gallery_list_grid.scss';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import {
  HydratedGallery,
  GALLERY_STATUS,
} from '../../../redux/galleries/galleries.types';
import { GalleryListGridCard } from '../gallery_list_grid_card';
import { AuthWrapper } from '../../blocks/authwrapper/auth_wrapper.connect';
import { FEATURE_FLAGS } from '../../blocks/authwrapper/auth_wrapper.types';
import { selectIsUserInternal } from '../../../redux/user/user.selectors';

export interface GalleryListGridProps {
  galleries: HydratedGallery[];
}

export const GalleryListGrid = ({ galleries }: GalleryListGridProps) => {
  const isUserInternal = useSelector(selectIsUserInternal);
  return (
    <div className="gallery_list_grid">
      <h5>
        <Translate id="galleries.listGrid.name" />
      </h5>
      <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_CAMPAIGN_FIELD}>
        <h5>
          <Translate id="galleries.listGrid.campaign" />
        </h5>
      </AuthWrapper>
      <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_THEME_FIELD}>
        <h5>
          <Translate id="galleries.listGrid.theme" />
        </h5>
      </AuthWrapper>
      <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_LOCATION_FIELD}>
        <h5>
          <Translate id="galleries.listGrid.location" />
        </h5>
      </AuthWrapper>
      <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_PERSONA_FIELD}>
        <h5>
          <Translate id="galleries.listGrid.persona" />
        </h5>
      </AuthWrapper>
      {/* The first list is visible only to employees of CrowdRiff */}
      {isUserInternal &&
        galleries
          .filter((g) => g.status === GALLERY_STATUS.INTERNAL)
          .map((gallery) => {
            return (
              <GalleryListGridCard gallery={gallery} key={`${gallery.id}`} />
            );
          })}
      {/* All Galleries that are not marked as internal */}
      {galleries
        .filter((g) => g.status !== GALLERY_STATUS.INTERNAL)
        .map((gallery) => {
          return (
            <GalleryListGridCard gallery={gallery} key={`${gallery.id}`} />
          );
        })}
    </div>
  );
};
