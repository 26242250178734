import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Album = function Album(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'album__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M416.7,166.7L291.7,0H0v1000h1000V166.7H416.7z M62.5,62.5h197.9l125,166.7h552.1V750L750,562.5L468.7,843.8L375,750L187.5,937.5h-125V62.5z M437.4,531.3c0,51.8-42,93.7-93.7,93.7S250,583,250,531.3s42-93.7,93.7-93.7S437.4,479.5,437.4,531.3z' // eslint-disable-line max-len
    })
  );
};

Album.propTypes = propTypes;

export default Album;