import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { CRThunkDispatch, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { AppState } from '../types';
import { selectCurrentNetworkId } from '../redux/user/user.selectors';
import { Location, LOCATION_TYPE } from '../redux/locations/locations.types';
import {
  locationTagsStartAction,
  locationTagsSuccessAction,
  locationTagsFailureAction,
} from '../redux/locations/locations.actions';
import { getErrorMessage } from '../utils';

interface GetLocationTagsResponse {
  locations: Location[];
}
type GetLocationsThunk = (
  locationType: LOCATION_TYPE,
) => (dispatch: CRThunkDispatch, getState: () => AppState) => Promise<void>;

export const getLocations: GetLocationsThunk = (
  locationType: LOCATION_TYPE,
) => async (dispatch: CRThunkDispatch, getState: () => AppState) => {
  const state: AppState = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.LOCATIONS,
    params: {
      networkId,
    },
  });

  dispatch(locationTagsStartAction());

  try {
    const res: AxiosResponse<GetLocationTagsResponse> = await axios.get<
      GetLocationTagsResponse
    >(url, {
      params: { page_size: '200', types: locationType ?? undefined },
    });

    dispatch(locationTagsSuccessAction(res.data.locations ?? []));
  } catch (err) {
    dispatch(locationTagsFailureAction(err));
    throw new Error(`Failed to get locations: ${getErrorMessage(err)}`);
  }
};
