import React, { VoidFunctionComponent, useState } from 'react';
import './gallery_preview.scss';
import {
  Translate,
  Action,
  withLocalize,
  LocalizeContextProps,
} from 'react-localize-redux';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
} from '../../blocks/button';
import { getGalleryPreview } from '../../../http/gallery_preview.thunks';
import { AppState } from '../../../types';
import { CarouselImage } from '../../blocks/carousel/carousel.types';
import { GalleryPreviewGenerate } from './gallery_preview_generate';
import { AVATAR_SIZES } from '../../blocks/avatar/avatar.types';
import { GALLERY_PREVIEW_STORIES } from './gallery_preview.types';
import { getGalleryFormSignals } from '../gallery_form/gallery_form.utils';
import { DynamicGalleryFormSubmitData } from '../gallery_form/gallery_form.types';
import { selectGalleriesToEdit } from '../../../redux/galleries/galleries.selectors';
import { selectCurrentPublisher } from '../../../redux/user/user.selectors';
import { isInstanceOfAxiosError } from '../../../types/typeguards';
import { useToast } from '../../hooks/use_toast/use_toast';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';
import { TOAST_TYPES } from '../../blocks/toast';

const GalleryPreviewComponent: VoidFunctionComponent<LocalizeContextProps> = ({
  translate,
}) => {
  const { getValues } = useFormContext();
  const [stories, setStories] = useState<CarouselImage[]>([]);
  const [totalMatchedStories, setTotalMatchedStories] = useState<number>(0);
  const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);
  const [isGenerated, setIsGenerated] = useState<boolean>(false);
  const [signalsCount, setSignalsCount] = useState<number>(0);
  const dispatch = useDispatch<ThunkDispatch<AppState, {}, Action>>();
  const getTranslation = useTranslateStrings(translate);
  const { showToast } = useToast();
  const galleryPublisherId =
    useSelector(selectGalleriesToEdit)?.publisher?.publisherId ??
    useSelector(selectCurrentPublisher).publisherId;
  const generatePreview = async () => {
    setIsLoadingPreview(true);
    const formData = getValues();
    const signals = Object.values(
      getGalleryFormSignals(formData as DynamicGalleryFormSubmitData),
    );

    try {
      const res = await dispatch(
        getGalleryPreview(
          formData as DynamicGalleryFormSubmitData,
          galleryPublisherId,
        ),
      );
      const storyGallery: CarouselImage[] =
        res.stories?.map((story) => ({
          src: story.image.url,
          previewSrc: story.story?.url ?? '',
          title: story.title,
          publisher: {
            logo: story.publisher.logoSrc,
            name: story.publisher.name,
            size: AVATAR_SIZES.SMALL,
          },
        })) ?? [];
      setTotalMatchedStories(res.matched_story_count);
      setIsGenerated(true);
      setStories(storyGallery.slice(0, 4));
      setSignalsCount(signals.length);
    } catch (e) {
      if (isInstanceOfAxiosError(e) && e.response?.status === 404) {
        setTotalMatchedStories(0);
        setIsGenerated(true);
        setSignalsCount(signals.length);
        setStories([]);
      } else {
        setIsGenerated(false);
        showToast({
          message: `${getTranslation('galleries.preview.failedToGenerate')}`,
          type: TOAST_TYPES.ERROR,
        });
      }
    } finally {
      setIsLoadingPreview(false);
    }
  };
  const noMatchedStories = isGenerated && totalMatchedStories === 0;
  return (
    <div className="gallery_preview">
      <h5 className="gallery_preview__title">
        <Translate id="galleries.preview.title" />
      </h5>
      <p className="gallery_preview__description">
        <Translate id="galleries.preview.description" />
      </p>
      <GalleryPreviewGenerate
        stories={stories}
        isLoadingPreview={isLoadingPreview}
        showNoStories={noMatchedStories}
      />
      <Button
        onClick={generatePreview}
        variant={BUTTON_VARIANT.OUTLINE}
        color={BUTTON_COLOR_VARIANT.SECONDARY}
      >
        {noMatchedStories ? (
          <Translate id="galleries.preview.refresh" />
        ) : (
          <Translate id="galleries.preview.generate" />
        )}
      </Button>
      {totalMatchedStories > 0 && (
        <p className="gallery_preview__matched_stories">
          <Translate
            id="galleries.preview.matchedStories"
            data={{
              displayedCount:
                totalMatchedStories >= GALLERY_PREVIEW_STORIES.MAX_MATCHED
                  ? GALLERY_PREVIEW_STORIES.MAX_MATCHED
                  : totalMatchedStories,
              totalCount: totalMatchedStories,
              matching: signalsCount > 0 ? 'matching' : '',
            }}
          />
        </p>
      )}
    </div>
  );
};

export const GalleryPreview = withLocalize(GalleryPreviewComponent);
