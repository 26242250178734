import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { NavigateFunction } from 'react-router-dom';
import { UtilityMenuComponent } from './utility_menu.component';
import { AppState, AppThunkDispatch, ROUTES } from '../../../types';
import {
  selectCurrentPublisher,
  selectOrganizationPublishers,
  selectUserId,
  selectIsPendoInitialized,
} from '../../../redux/user/user.selectors';
import { updatePublisherSwitchingAction } from '../../../redux/user/user.actions';
import { triggerHydrateAction } from '../../pages/galleries_list/galleries_list.actions';
import { getPublishers } from '../../../http/publishers_get.thunk';
import { Publisher } from '../../../redux/user/user.types';
import { getNetworkPublisherFeatureFlags } from '../../../http/feature_flags.thunks';
import {
  clearStoriesSearchQuery,
  storiesClearSearchFiltersAction,
} from '../../../redux/stories/stories.actions';
import { networkPublishersListClearAction } from '../../../redux/network/network.actions';

const mapStateToProps = (state: AppState, ownProps: { logout: () => void }) => {
  const currentPublisher = selectCurrentPublisher(state);
  const userId = selectUserId(state);
  const publishers = selectOrganizationPublishers(state);
  const isPendoInitialized = selectIsPendoInitialized(state);
  return {
    currentPublisher,
    publishers,
    logout: ownProps.logout,
    translate: getTranslate(state.localize),
    userId,
    isPendoInitialized,
  };
};

export const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  handlePublisherSwitching: async (
    publisher: Publisher,
    navigate: NavigateFunction,
  ) => {
    dispatch(updatePublisherSwitchingAction(publisher));
    // This needs to happen after the publisher switching action,
    // Otherwise the current publisher information will be wrong for
    // the feature flags call
    await dispatch(getNetworkPublisherFeatureFlags);
    dispatch(storiesClearSearchFiltersAction());
    dispatch(clearStoriesSearchQuery());

    dispatch(networkPublishersListClearAction());
    navigate(ROUTES.HOME);
  },
  triggerGetGalleries: () => {
    dispatch(triggerHydrateAction());
  },
  getPublishers: () => {
    dispatch(getPublishers);
  },
});

export const UtilityMenu = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UtilityMenuComponent);
