import { AppActions } from '../../types';
import {
  NetworkState,
  NETWORK_PUBLISHERS_TYPES,
  NETWORK_SETTINGS,
} from './network.types';

const initialState: Readonly<NetworkState> = {
  publishers: {},
  settings: null,
};
export const networkReducer = (
  state: Readonly<NetworkState> = initialState,
  action: AppActions,
): NetworkState => {
  switch (action.type) {
    case NETWORK_PUBLISHERS_TYPES.CLEAR: {
      return {
        ...state,
        publishers: {},
      };
    }
    case NETWORK_PUBLISHERS_TYPES.SUCCESS:
    case NETWORK_PUBLISHERS_TYPES.START: {
      return {
        ...state,
        publishers: action.payload,
      };
    }
    case NETWORK_SETTINGS.SUCCESS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
