import './checkbox.scss';
import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { CheckboxProps, CHECKBOX_VARIANTS } from './checkbox.types';
import { Icon, ICON_NAMES, ICON_SIZES } from '../icon';
import { HelperText } from '../helper/helperText.component';

export const Checkbox = ({
  variant = CHECKBOX_VARIANTS.SQUARE,
  label,
  id,
  name,
  className = '',
  isChecked = false,
  isDisabled = false,
  onCheckboxClick,
  alternateHoverState = false,
  required = false,
  invalid,
  error,
  description,
  onChange,
}: CheckboxProps): ReactElement => {
  let checkIcon;
  if (isChecked) {
    checkIcon = (
      <Icon
        iconKey={ICON_NAMES.CHECKMARK}
        size={
          variant === CHECKBOX_VARIANTS.CIRCLE
            ? ICON_SIZES.SMALL
            : ICON_SIZES.XS
        }
        className={`cr-checkbox__icon cr-checkbox__icon--checkmark--${variant}`}
      />
    );
  } else if (variant === CHECKBOX_VARIANTS.CIRCLE) {
    checkIcon = (
      <Icon
        iconKey={ICON_NAMES.PLUS}
        size={ICON_SIZES.LARGE}
        className={`cr-checkbox__icon cr-checkbox__icon--plus--${variant}`}
      />
    );
  }

  return (
    <div className={`cr-checkbox ${className}`}>
      <div
        className={`cr-checkbox__wrapper_box cr-checkbox__wrapper_${variant}`}
      >
        <input
          type="checkbox"
          name={name}
          id={`checkbox-${id}`}
          className={clsx(`cr-checkbox__input cr-checkbox__input_${variant}`, {
            alternate: alternateHoverState,
            invalid,
          })}
          checked={isChecked}
          disabled={isDisabled}
          onClick={onCheckboxClick}
          onChange={onChange}
        />
        {checkIcon}
      </div>
      <div
        className={clsx('cr-checkbox__label-wrapper', {
          invalid,
          required,
        })}
      >
        {label && (
          <label htmlFor={`checkbox-${id}`} className="cr-checkbox__label">
            {label}
          </label>
        )}
        {description && (
          <HelperText
            id={`${name}-help-text`}
            className={`${name}__help-text`}
            description={description}
          />
        )}
        {required && typeof required === 'string' && !invalid && (
          <HelperText id={`${name}-required`} description={required} />
        )}
        {invalid && error?.message && (
          <HelperText
            id={`${name}-error`}
            className="cr-checkbox__error-message"
            description={error.message}
          />
        )}
      </div>
    </div>
  );
};
