import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PhotoSmall = function PhotoSmall(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'PhotoSmall__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      null,
      React.createElement(
        'g',
        null,
        React.createElement('path', {
          d: 'M111.1,111.1v777.8h777.8V111.1H111.1z M166.7,166.7h666.7v532.6l-70.2-62.9L603.5,799.8l-58-59.4l-79,93H166.7V166.7z' // eslint-disable-line max-len
        })
      ),
      React.createElement(
        'g',
        null,
        React.createElement('path', {
          d: 'M493.6,484.5c0-30.7-24.3-55.6-54.3-55.6c-30,0-54.3,24.9-54.3,55.6c0,30.8,24.3,55.7,54.3,55.7C469.3,540.1,493.6,515.2,493.6,484.5z' // eslint-disable-line max-len
        })
      )
    )
  );
};

PhotoSmall.propTypes = propTypes;

export default PhotoSmall;