import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../';

var propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.element,
  isDisabled: PropTypes.bool,
  ariaLabel: PropTypes.string
};

var propDescriptions = {
  value: 'The "checked" status of the checkbox',
  onChange: 'Callback function that is fired to change the checkbox value',
  label: 'Optional label for the checkbox',
  name: 'HTML name value to associate with the checkbox',
  className: 'Optional classname for the checkbox',
  children: 'Optional additional element(s) to render with the box',
  isDisabled: 'Optional boolean to render a disabled, non-clickable checkbox'
};

var defaultProps = {
  label: '',
  className: '',
  isDisabled: false
};

export default function Checkbox(props) {
  var value = props.value,
      className = props.className,
      _onChange = props.onChange,
      label = props.label,
      name = props.name,
      children = props.children,
      isDisabled = props.isDisabled,
      ariaLabel = props.ariaLabel;

  return React.createElement(
    'div',
    {
      className: classNames('input checkbox', className, {
        disabled: isDisabled
      })
    },
    React.createElement('input', {
      id: name,
      name: name,
      type: 'checkbox',
      onChange: function onChange(e) {
        return _onChange(e.target.checked);
      },
      checked: value
    }),
    React.createElement(
      'label',
      { className: 'checkbox__label', htmlFor: name },
      React.createElement(
        'div',
        {
          className: 'checkbox__box ' + (value ? 'checked' : ''),
          role: 'checkbox',
          tabIndex: '0',
          'aria-label': ariaLabel,
          'aria-checked': value
        },
        React.createElement(
          'div',
          { className: 'checkbox__icon' },
          value && React.createElement(Icon, { type: 'check', size: 8 })
        )
      ),
      label,
      children
    )
  );
}

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;
Checkbox.propDescriptions = propDescriptions;