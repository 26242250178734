export enum FEATURE_FLAGS {
  REPORTS = 'reports',
  COPY_GALLERY_ID = 'copyGalleryId',
  PUBLISHER_FIELD = 'searchFilterByCreator',
  GALLERY_PERSONA_FIELD = 'galleryPersonaField',
  GALLERY_CAMPAIGN_FIELD = 'galleryCampaignField',
  GALLERY_LOCATION_FIELD = 'galleryLocationField',
  GALLERY_THEME_FIELD = 'galleryThemeField',
  LANDING_PAGE = 'landingPage',
  ENABLE_MODERATION = 'enableModeration',
  STORY_STATS = 'storyStats',
  STATIC_GALLERY = 'staticGallery',
  DYNAMIC_GALLERY = 'dynamicGallery',
  TOS_ACCEPTANCE = 'tosAcceptance',
  SINGLE_EMBED = 'storyEmbed',
  SHOW_CREATOR_CONTENT = 'showCreatorContent',
}

export enum FEATURE_FLAG_COMPARISON_TYPES {
  STRICT = 'strict',
  LOOSE = 'loose',
}
