import {
  FEATURE_FLAG_COMPARISON_TYPES,
  FEATURE_FLAGS,
} from './auth_wrapper.types';

export const userHasAccess = (
  featureFlags: FEATURE_FLAGS | FEATURE_FLAGS[],
  requestedFlags: FEATURE_FLAGS | FEATURE_FLAGS[],
  comparisonType?: FEATURE_FLAG_COMPARISON_TYPES,
  exclude?: boolean,
): boolean => {
  let hasAccess: boolean = false;
  if (!Array.isArray(requestedFlags)) {
    hasAccess = featureFlags.includes(requestedFlags);
  } else {
    const listOfIncludedTags = requestedFlags.filter((rb) => {
      return featureFlags.includes(rb);
    });
    hasAccess = listOfIncludedTags.length === requestedFlags.length;
    if (comparisonType === FEATURE_FLAG_COMPARISON_TYPES.LOOSE) {
      hasAccess = Boolean(listOfIncludedTags.length);
    }
  }
  if (exclude) {
    hasAccess = !hasAccess;
  }
  return hasAccess;
};
