import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PhotoLandscape = function PhotoLandscape(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'PhotoLandscape__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      null,
      React.createElement(
        'g',
        null,
        React.createElement('ellipse', { cx: '718.2', cy: '530.1', rx: '40.8', ry: '41.7' })
      ),
      React.createElement(
        'g',
        null,
        React.createElement('path', {
          d: 'M0,250v500h724.6H1000V250H0z M41.7,291.7h916.7v316.1l-52.7-47.2L785.9,683.2l-43.5-44.5l-59.3,69.7H41.7V291.7z' // eslint-disable-line max-len
        })
      )
    )
  );
};

PhotoLandscape.propTypes = propTypes;

export default PhotoLandscape;