import React from 'react';
import { IconProps } from '../icon.types';

export const Person = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 10.1562C11.9756 10.1562 13.5859 8.39648 13.5859 6.25488C13.5859 4.12988 11.9839 2.46143 10 2.46143C8.03271 2.46143 6.41406 4.16309 6.41406 6.27148C6.42236 8.40479 8.02441 10.1562 10 10.1562ZM10 8.90283C8.78809 8.90283 7.75049 7.74072 7.75049 6.27148C7.75049 4.82715 8.77148 3.71484 10 3.71484C11.2368 3.71484 12.2495 4.81055 12.2495 6.25488C12.2495 7.72412 11.2202 8.90283 10 8.90283ZM4.77881 17.8345H15.2129C16.5908 17.8345 17.2466 17.4194 17.2466 16.5063C17.2466 14.3315 14.499 11.1855 10 11.1855C5.49268 11.1855 2.74512 14.3315 2.74512 16.5063C2.74512 17.4194 3.40088 17.8345 4.77881 17.8345ZM4.38867 16.5811C4.17285 16.5811 4.08154 16.5229 4.08154 16.3486C4.08154 14.9873 6.18994 12.439 10 12.439C13.8018 12.439 15.9102 14.9873 15.9102 16.3486C15.9102 16.5229 15.8271 16.5811 15.6113 16.5811H4.38867Z" />
    </svg>
  );
};
