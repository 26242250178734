import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

var propDescriptions = {
  status: 'Alert Panel status. Options: info, fail',
  showIcon: 'Conditional boolean flag to show status icon in the panel',
  children: 'Content to be wrapped by the Alert Panel component'
};

var propTypes = {
  status: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  children: PropTypes.node
};

var defaultProps = {
  showIcon: true
};

var Title = function Title(_ref) {
  var children = _ref.children;

  return React.createElement(
    'div',
    null,
    React.createElement(
      'div',
      { className: 'cr-alertpanel__title' },
      children
    )
  );
};

var Description = function Description(_ref2) {
  var children = _ref2.children;

  return React.createElement(
    'div',
    { className: 'cr-alertpanel__description' },
    children
  );
};

var TextWithLink = function TextWithLink(_ref3) {
  var text = _ref3.text,
      linkText = _ref3.linkText,
      href = _ref3.href;

  return React.createElement(
    'span',
    { className: 'cr-alertpanel__textWithLink' },
    text,
    React.createElement(
      'a',
      {
        className: 'cr-alertpanel__textWithLink__link',
        href: href,
        target: '_blank',
        rel: 'noopener noreferrer'
      },
      linkText,
      React.createElement(Icon, { className: 'cr-alertpanel__textWithLink__link__icon', type: 'external-link', color: 'blue', size: 12 })
    )
  );
};

var Link = function Link(_ref4) {
  var children = _ref4.children,
      href = _ref4.href;

  return React.createElement(
    'div',
    { className: 'cr-alertpanel__link' },
    React.createElement(
      'a',
      { className: 'cr-alertpanel__link--link', href: href, target: '_blank', rel: 'noopener noreferrer' },
      children,
      React.createElement(Icon, { className: 'cr-alertpanel__link--icon', type: 'external-link', size: 12 })
    )
  );
};

var deriveIconType = function deriveIconType(status) {
  switch (status) {
    case 'info':
      return 'info';
    case 'success':
      return 'check';
    case 'fail':
      return 'close';
    default:
      return 'info';
  }
};

var AlertPanel = function AlertPanel(_ref5) {
  var status = _ref5.status,
      children = _ref5.children,
      showIcon = _ref5.showIcon;

  return React.createElement(
    'div',
    { className: classNames('cr-alertpanel', 'cr-alertpanel--' + status) },
    showIcon && React.createElement(Icon, { className: 'cr-alertpanel__title--icon', type: deriveIconType(status), size: 16 }),
    React.createElement(
      'div',
      { className: 'cr-alertpanel__title--content' },
      children
    )
  );
};

AlertPanel.propTypes = propTypes;
AlertPanel.defaultProps = defaultProps;
AlertPanel.propDescriptions = propDescriptions;

AlertPanel.Title = Title;
AlertPanel.Description = Description;
AlertPanel.Link = Link;
AlertPanel.TextWithLink = TextWithLink;

export default AlertPanel;

export { Title, Description, Link, TextWithLink };