import { ThunkAction } from 'redux-thunk';
import axios from './axios_config';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import {
  DynamicGalleryFormSubmitData,
  TransformedGalleryFormData,
} from '../react/features/gallery_form/gallery_form.types';
import { transformGalleryFormData } from '../react/features/gallery_form/gallery_form.utils';
import {
  galleryCreateStartAction,
  galleryCreateSuccessAction,
  galleryCreateFailedAction,
} from '../redux/galleries/gallery_form.actions';

import { AppActions, AppState } from '../types';
import {
  selectCurrentNetworkId,
  selectCurrentPublisherId,
} from '../redux/user/user.selectors';
import {
  GALLERY_TYPE,
  HydratedGallery,
} from '../redux/galleries/galleries.types';
import {
  saveStaticGalleryFailure,
  saveStaticGalleryStart,
  saveStaticGallerySuccess,
} from '../redux/galleries/galleries.actions';
import { selectStaticGallery } from '../redux/galleries/galleries.selectors';
import { getErrorMessage } from '../utils';

export type CreateGalleryThunkAction = ThunkAction<
  Promise<GalleryCreateEditResponse>,
  AppState,
  {},
  AppActions
>;
type CreateDynamicGalleryThunk = (
  formData: DynamicGalleryFormSubmitData | undefined,
) => CreateGalleryThunkAction;

export interface GalleryCreateEditResponse {
  gallery: HydratedGallery;
}

export type CreateStaticGalleryThunk = () => CreateGalleryThunkAction;

export const createDynamicGallery: CreateDynamicGalleryThunk = (
  formData: DynamicGalleryFormSubmitData | undefined,
) => async (dispatch, getState) => {
  if (typeof formData === 'undefined') {
    throw new Error(`Failed to edit gallery: Problem with formData`);
  }
  const state: AppState = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const publisherId = selectCurrentPublisherId(state);
  const reqBody: TransformedGalleryFormData = transformGalleryFormData(
    formData,
    publisherId,
  );
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.GALLERIES,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
    },
  });
  dispatch(galleryCreateStartAction);
  try {
    const res = await axios.post<GalleryCreateEditResponse>(url, reqBody);

    dispatch(galleryCreateSuccessAction(res.data));
    return res.data;
  } catch (err) {
    dispatch(galleryCreateFailedAction(err));
    throw new Error(`Failed to create gallery: ${getErrorMessage(err)}`);
  }
};

export const createStaticGallery: CreateStaticGalleryThunk = () => async (
  dispatch,
  getState,
) => {
  dispatch(saveStaticGalleryStart());
  const state: AppState = getState();
  const gallery = selectStaticGallery(state);
  const networkId: string = selectCurrentNetworkId(state);
  const publisherId = selectCurrentPublisherId(state);
  const staticGallery = { ...gallery, type: GALLERY_TYPE.STATIC };
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.GALLERIES,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
    },
  });
  try {
    const res = await axios.post<GalleryCreateEditResponse>(url, staticGallery);

    dispatch(saveStaticGallerySuccess(res.data.gallery));
    return res.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    dispatch(
      saveStaticGalleryFailure(errorMessage ?? 'Failed to create Gallery'),
    );
    throw new Error(`Failed to create gallery: ${errorMessage}`);
  }
};
