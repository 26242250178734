/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Flag = function Flag(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon',
      viewBox: '0 0 14 14',
      id: 'flag__icon'
    },
    React.createElement('path', { d: 'M0.25 14V1H1.75V14H0.25Z' }),
    React.createElement('path', { d: 'M0.25 0.25H13.6307L9.98781 4.5L13.6307 8.75H0.25V0.25ZM1.75 1.75V7.25H10.3693L8.01219 4.5L10.3693 1.75H1.75Z' })
  );
};

Flag.propTypes = propTypes;

export default Flag;