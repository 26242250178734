import { FEATURE_FLAGS } from './auth_wrapper.types';

export const TAXONOMY_FEATURE_FLAGS: FEATURE_FLAGS[] = [
  FEATURE_FLAGS.GALLERY_CAMPAIGN_FIELD,
  FEATURE_FLAGS.GALLERY_PERSONA_FIELD,
  FEATURE_FLAGS.GALLERY_THEME_FIELD,
];

export const CONTENT_CATEGORY_FEATURE_FLAGS: FEATURE_FLAGS[] = [
  FEATURE_FLAGS.GALLERY_CAMPAIGN_FIELD,
  FEATURE_FLAGS.GALLERY_PERSONA_FIELD,
  FEATURE_FLAGS.GALLERY_THEME_FIELD,
  FEATURE_FLAGS.GALLERY_LOCATION_FIELD,
];
