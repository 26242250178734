import { ActionCreator, Action } from 'redux';
import {
  LOCATIONS_TYPE_TYPES,
  GetLocationTypesStart,
  GetLocationTypesSuccess,
  GetLocationTypesFailure,
  LOCATIONS_ASYNC_TYPES,
  GetLocationTagsStart,
  GetLocationTagsFailure,
  GetLocationTagsSuccess,
  Location,
  LOCATION_TYPE,
} from './locations.types';

export const locationTagsStartAction: ActionCreator<Action> = (): GetLocationTagsStart => ({
  type: LOCATIONS_ASYNC_TYPES.START,
});

export const locationTagsFailureAction: ActionCreator<Action> = (
  error: string,
): GetLocationTagsFailure => ({
  type: LOCATIONS_ASYNC_TYPES.FAILURE,
  payload: error,
});

export const locationTagsSuccessAction: ActionCreator<Action> = (
  payload: Location[],
): GetLocationTagsSuccess => ({
  type: LOCATIONS_ASYNC_TYPES.SUCCESS,
  payload,
});

export const locationTypesStartAction: ActionCreator<Action> = (): GetLocationTypesStart => ({
  type: LOCATIONS_TYPE_TYPES.START,
});

export const locationTypesSuccessAction: ActionCreator<Action> = (
  payload: LOCATION_TYPE[],
): GetLocationTypesSuccess => ({
  type: LOCATIONS_TYPE_TYPES.SUCCESS,
  payload,
});

export const locationTypesFailureAction: ActionCreator<Action> = (
  payload: string,
): GetLocationTypesFailure => ({
  type: LOCATIONS_TYPE_TYPES.FAILURE,
  payload,
});
