import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var External = function External(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'External__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1001 1001',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M959.928 657.733C937.87 657.733 920.013 675.59 920.013 697.649V863.615L675.265 618.867C659.509 603.111 634.299 603.111 619.593 618.867C603.837 634.624 603.837 659.834 619.593 674.54L864.34 920.337H698.374C676.316 920.337 658.459 938.194 658.459 960.253C658.459 982.312 676.316 1000.17 698.374 1000.17H959.928C981.987 1000.17 999.844 982.312 999.844 960.253V697.649C999.844 675.59 981.987 657.733 959.928 657.733Z' // eslint-disable-line max-len
    }),
    React.createElement('path', {
      d: 'M137.532 80.0183H303.498C325.557 80.0183 343.414 62.1612 343.414 40.1024C343.414 18.0436 325.557 0.186523 303.498 0.186523H41.9442C19.8854 0.186523 2.02832 18.0436 2.02832 40.1024V301.656C2.02832 323.715 19.8854 341.572 41.9442 341.572C64.003 341.572 81.8601 323.715 81.8601 301.656V136.741L327.658 382.539C343.414 398.295 368.624 398.295 383.33 382.539C399.086 366.782 399.086 341.572 383.33 326.866L137.532 80.0183Z' // eslint-disable-line max-len
    }),
    React.createElement('path', {
      d: 'M326.542 618.898L80.7439 863.645V697.679C80.7439 675.62 62.8868 657.763 40.828 657.763C18.7692 657.763 0.912109 675.62 0.912109 697.679V959.233C0.912109 981.292 18.7692 999.149 40.828 999.149H302.382C324.441 999.149 342.298 981.292 342.298 959.233C342.298 937.174 324.441 919.317 302.382 919.317H137.466L383.264 673.519C399.02 657.763 399.02 632.553 383.264 617.847C367.508 603.141 342.298 603.141 326.542 618.898Z' // eslint-disable-line max-len
    }),
    React.createElement('path', {
      d: 'M959.928 1.23291H698.374C676.316 1.23291 658.459 19.09 658.459 41.1488C658.459 63.2076 676.316 81.0647 698.374 81.0647H864.34L619.593 325.812C603.837 341.568 603.837 366.778 619.593 381.484C635.349 397.24 660.559 397.24 675.265 381.484L921.063 136.737V302.703C921.063 324.762 938.92 342.619 960.979 342.619C983.038 342.619 1000.89 324.762 1000.89 302.703V41.1488C999.844 19.09 981.987 1.23291 959.928 1.23291Z' // eslint-disable-line max-len
    })
  );
};

External.propTypes = propTypes;

export default External;