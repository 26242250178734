import React from 'react';
import classNames from 'classnames';
import { Avatar } from '../../blocks/avatar/avatar.component';
import { AVATAR_SIZES } from '../../blocks/avatar/avatar.types';
import './publisher_label.scss';
import { PUBLISHER_ROLES } from './publisher_label.types';

export const PublisherLabel = ({
  avatarSrc,
  name,
  type,
}: {
  avatarSrc: string;
  name: string;
  type: PUBLISHER_ROLES;
}) => {
  return (
    <div
      className={classNames('publisher-label', {
        publisher: type === PUBLISHER_ROLES.PUBLISHER,
        partner: type === PUBLISHER_ROLES.PARTNER,
      })}
    >
      <Avatar url={avatarSrc} alt={name} size={AVATAR_SIZES.SMALL} />
      <p>{name}</p>
    </div>
  );
};
