import React from 'react';
import { IconProps } from '../icon.types';

export const SoundOff = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.1421 12.165V3.67334C14.1421 3.13379 13.7437 2.69385 13.1875 2.69385C12.7891 2.69385 12.54 2.87646 12.1001 3.2666L8.47266 6.4873L9.36084 7.38379L12.5981 4.44531C12.6396 4.40381 12.6895 4.37891 12.7393 4.37891C12.7974 4.37891 12.8472 4.42041 12.8472 4.49512V10.8701L14.1421 12.165ZM16.8398 18.208C17.0889 18.457 17.5039 18.457 17.7446 18.208C17.9937 17.9507 17.9937 17.5522 17.7446 17.3032L3.53369 3.09229C3.28467 2.84326 2.86963 2.84326 2.62061 3.09229C2.37158 3.33301 2.37158 3.75635 2.62061 3.99707L16.8398 18.208ZM5.7832 13.3853H8.15723C8.23193 13.3853 8.29834 13.4102 8.35645 13.46L12.1001 16.8135C12.5068 17.1704 12.8057 17.3364 13.2041 17.3364C13.5278 17.3364 13.7935 17.187 13.9844 16.9048L13.0215 15.9502L9.01221 12.356C8.84619 12.2065 8.74658 12.1733 8.53076 12.1733H5.84131C5.52588 12.1733 5.38477 12.0322 5.38477 11.7251V8.30518L4.38867 7.30908C4.19775 7.57471 4.08984 7.94824 4.08984 8.43799V11.6172C4.08984 12.8208 4.6543 13.3853 5.7832 13.3853Z" />
    </svg>
  );
};
