import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var DesktopGrid = function DesktopGrid(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'DesktopGrid__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1282 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M769.2,307.7H0V0h769.2V307.7z M435.8,359H0v256.4h435.8V359z M307.7,666.7H0V1000h307.7V666.7z M1282,0H820.5v307.7H1282V0\tz M974.3,359H487.1v256.4h487.1V359z M846.1,666.7H359V1000h487.1V666.7z M1282,359h-256.4v256.4H1282V359z M1282,666.7H897.4V1000H1282V666.7z' // eslint-disable-line max-len
    })
  );
};

DesktopGrid.propTypes = propTypes;

export default DesktopGrid;