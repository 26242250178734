/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var RightsExpired = function RightsExpired(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      'aria-hidden': true,
      height: size,
      viewBox: '0 0 1000 1000',
      fill: color,
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon rightsExpired',
      id: 'rightsExpired__icon'
    },
    React.createElement('path', { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M517.279 996.1C533.94 989 928.365 815.8 928.365 541.7H928.571V41.6997C928.571 18.8997 909.133 3.05176e-05 885.683 3.05176e-05H114.316C90.8669 3.05176e-05 71.4285 18.8997 71.4285 41.6997V541.7C71.4285 815.7 465.751 988.9 482.516 996.1C487.864 998.7 493.829 1000 499.897 1000C505.862 1000 511.93 998.7 517.279 996.1ZM546.149 526.076L580.357 114.286H419.643L453.851 526.076H546.149ZM560.994 628.573H439.651V749.915H560.994V628.573Z' })
  );
};

RightsExpired.propTypes = propTypes;

export default RightsExpired;