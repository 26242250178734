import React, { FunctionComponent } from 'react';
import './error_page.scss';
import { Text, TEXT_VARIANTS } from '../../blocks/text';

interface ErrorPageComponentProps {
  title: string;
  description: string;
  errorImg: React.ReactNode;
  CTA: JSX.Element[];
}

export const ErrorPage: FunctionComponent<ErrorPageComponentProps> = ({
  errorImg,
  CTA,
  description,
  title,
}) => {
  return (
    <div className="error_page">
      <div className="error_page__copy">
        <Text variant={TEXT_VARIANTS.H3} className="error_page__copy--title">
          {title}
        </Text>
        <Text variant={TEXT_VARIANTS.B1} className="error_page__copy--body">
          {description}
        </Text>
        <div className="error_page__button_container">{CTA}</div>
      </div>
      {errorImg}
    </div>
  );
};
