import React from 'react';
import { IconProps } from '../icon.types';

export const ArrowCircle = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 18.4736C14.6318 18.4736 18.4668 14.6304 18.4668 10.0068C18.4668 5.375 14.6235 1.54004 9.9917 1.54004C5.36816 1.54004 1.5332 5.375 1.5332 10.0068C1.5332 14.6304 5.37646 18.4736 10 18.4736ZM10 17.0625C6.08203 17.0625 2.95264 13.9248 2.95264 10.0068C2.95264 6.08887 6.07373 2.95117 9.9917 2.95117C13.9097 2.95117 17.0474 6.08887 17.0557 10.0068C17.064 13.9248 13.918 17.0625 10 17.0625ZM12.5898 12.0654C12.9551 12.0654 13.2041 11.7832 13.2041 11.4014V7.4834C13.2041 6.99365 12.9302 6.80273 12.5068 6.80273H8.57227C8.18213 6.80273 7.93311 7.04346 7.93311 7.40869C7.93311 7.77393 8.19043 8.01465 8.58887 8.01465H10.0996L11.3281 7.88184L10.0332 9.08545L6.97021 12.1484C6.854 12.2646 6.771 12.4307 6.771 12.5967C6.771 12.9702 7.02002 13.2109 7.38525 13.2109C7.58447 13.2109 7.73389 13.1362 7.8584 13.02L10.9131 9.96533L12.1084 8.68701L11.9839 9.98193V11.418C11.9839 11.8081 12.2246 12.0654 12.5898 12.0654Z" />
    </svg>
  );
};
