import { Translate } from 'react-localize-redux';
import React from 'react';
import './story_status.scss';
import classNames from 'classnames';
import {
  STORY_STATUS,
  STORY_VISIBILITY,
} from '../../features/story/story.types';

export const StoryStatus = ({
  status,
  Icon,
}: {
  status: STORY_STATUS | STORY_VISIBILITY;
  Icon: React.ReactNode;
}) => {
  const getStatus = () => {
    switch (status) {
      case STORY_STATUS.DRAFT:
        return 'stories.status.draft';
      case STORY_VISIBILITY.HIDDEN:
        return 'stories.status.hidden';
      default:
      case STORY_STATUS.PUBLISHED:
        return 'stories.status.published';
    }
  };
  return (
    <Translate>
      {({ translate }) => {
        return (
          <div
            className={classNames('story-status', {
              published: status === STORY_STATUS.PUBLISHED,
              draft: status === STORY_STATUS.DRAFT,
              hidden: status === STORY_VISIBILITY.HIDDEN,
            })}
          >
            {Icon}
            {`${translate(`${getStatus()}`)}`}
          </div>
        );
      }}
    </Translate>
  );
};
