import React from 'react';
import { IconProps } from '../icon.types';

export const Flag = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.229 18.1582C3.57764 18.1582 3.85986 17.8843 3.85986 17.5356V12.9204C4.06738 12.8291 4.84766 12.5303 6.06787 12.5303C9.13916 12.5303 11.0649 14.0327 13.9868 14.0327C15.2817 14.0327 15.7632 13.8916 16.394 13.6094C16.9668 13.3521 17.3403 12.9204 17.3403 12.1567V3.73145C17.3403 3.2832 16.9585 3.02588 16.4771 3.02588C16.0703 3.02588 15.3066 3.37451 13.8789 3.37451C10.957 3.37451 9.03955 1.87207 5.95996 1.87207C4.66504 1.87207 4.17529 2.01318 3.54443 2.29541C2.97168 2.55273 2.59814 2.98438 2.59814 3.73975V17.5356C2.59814 17.876 2.88867 18.1582 3.229 18.1582ZM13.9868 12.7793C11.2227 12.7793 9.26367 11.2769 6.06787 11.2769C5.15479 11.2769 4.32471 11.3848 3.85986 11.5757V3.78125C3.97607 3.50732 4.66504 3.12549 5.95996 3.12549C8.88184 3.12549 10.8325 4.62793 13.8789 4.62793C14.7837 4.62793 15.5308 4.52002 16.0869 4.3623V12.1235C15.9707 12.3975 15.2817 12.7793 13.9868 12.7793Z" />
    </svg>
  );
};
