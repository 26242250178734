import React from 'react';
import { IconProps } from '../icon.types';

export const TextAlignCenter = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.25537 4.08838H17.7197C18.0684 4.08838 18.3589 3.81445 18.3589 3.45752C18.3589 3.10889 18.0684 2.83496 17.7197 2.83496H2.25537C1.90674 2.83496 1.63281 3.10889 1.63281 3.45752C1.63281 3.81445 1.90674 4.08838 2.25537 4.08838ZM5.32666 8.43799H14.6567C15.0054 8.43799 15.2876 8.16406 15.2876 7.80713C15.2876 7.4585 15.0054 7.18457 14.6567 7.18457H5.32666C4.96973 7.18457 4.6958 7.4585 4.6958 7.80713C4.6958 8.16406 4.96973 8.43799 5.32666 8.43799ZM2.25537 12.7876H17.7197C18.0684 12.7876 18.3589 12.5054 18.3589 12.1567C18.3589 11.8081 18.0684 11.5342 17.7197 11.5342H2.25537C1.90674 11.5342 1.63281 11.8081 1.63281 12.1567C1.63281 12.5054 1.90674 12.7876 2.25537 12.7876ZM5.32666 17.1289H14.6567C15.0054 17.1289 15.2876 16.855 15.2876 16.5063C15.2876 16.1577 15.0054 15.8755 14.6567 15.8755H5.32666C4.96973 15.8755 4.6958 16.1577 4.6958 16.5063C4.6958 16.855 4.96973 17.1289 5.32666 17.1289Z" />
    </svg>
  );
};
