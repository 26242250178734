export enum TOAST_VARIANTS {
  BASIC = 'basic',
  BANNER = 'banner',
}

export enum TOAST_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEFAULT = 'default',
}

export const SNACKBAR_LIMIT = 6;
export const SNACKBAR_AUTO_CLOSE_MS = 5000;
