import React from 'react';
import { IconProps } from '../icon.types';

export const ASquareFilled = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.16667 0.5C3.48477 0.5 0.5 3.48477 0.5 7.16667V13.8333C0.5 17.5152 3.48477 20.5 7.16667 20.5H13.8333C17.5152 20.5 20.5 17.5152 20.5 13.8333V7.16667C20.5 3.48477 17.5152 0.5 13.8333 0.5H7.16667ZM8.21647 14.4795C8.11068 14.7806 7.94792 14.9108 7.6875 14.9108C7.37012 14.9108 7.16667 14.7318 7.16667 14.4388C7.16667 14.3086 7.19108 14.1784 7.23177 14.0645L9.97428 6.89486C10.1126 6.51237 10.3649 6.33333 10.7148 6.33333C11.0811 6.33333 11.3333 6.52051 11.4717 6.89486L14.2061 14.0645C14.2549 14.1947 14.2874 14.3167 14.2874 14.4388C14.2874 14.7236 14.0677 14.9108 13.7666 14.9108C13.4899 14.9108 13.3353 14.7887 13.2295 14.4795L12.3994 12.1602H9.04655L8.21647 14.4795ZM10.6579 7.62728L9.33952 11.3301H12.1064L10.7881 7.62728H10.6579Z"
      />
    </svg>
  );
};
