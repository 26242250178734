export const generateEmbedCode = (storyID: string): string => {
  return `
<!-- Begin amp.travel story code -->
<script>
(function(u, w, d, a, q, e) {
if (w.amptravel) return;
q = [];
a = w.amptravel = function(a, p) {
    w.amptravel.q.push({ a:a, p:p });
};
a.q = q;
a.v = "2";
e = d.createElement("script");
e.setAttribute("src", u + "/v" + a.v + "/js");
d.head.appendChild(e);
})(
"${process.env.GALLERY_EMBED_URL || 'https://g.amp.travel'}",
window,
document
);
</script>
<div 
  data-amp-travel-story-id="${storyID}"
  data-amp-travel="single-embed-container"
>
</div>
<!-- End amp.travel story code -->
`;
};
