import React, { ReactElement } from 'react';
import { PageTitle } from '../../blocks/pageTitle/pageTitle.component';
import './gallery_create.scss';
import { Page } from '../../blocks/page/page.component';
import { GALLERY_FORM_TYPES } from '../../features/gallery_form/gallery_form.types';
import { GalleryPreview } from '../../features/gallery_preview';
import { GalleryForm } from '../../features/gallery_form';
import { LoadStateData } from '../../blocks/load_data/load_state_data.component';
import {
  LOCATION_TYPE,
  LOCATIONS_TYPE_TYPES,
} from '../../../redux/locations/locations.types';
import { LoadingGif } from '../../blocks/LoadingGif/LoadingGif.component';
import { getLocationTypes } from '../../../http/location_types_list.thunks';
import { PageHeader } from '../../blocks/page_header/page_header.component';

export const GalleryCreatePage = (): ReactElement => {
  return (
    <Page className="gallery_create_page">
      <LoadStateData
        actionTypes={LOCATIONS_TYPE_TYPES}
        loading={<LoadingGif size="large" />}
        failure={<div>failed</div>}
        thunk={getLocationTypes}
      >
        <PageHeader>
          <PageTitle />
        </PageHeader>
        <div className="gallery_create_page__body">
          <GalleryForm
            type={GALLERY_FORM_TYPES.CREATE}
            defaultLocationType={LOCATION_TYPE.STATE}
          >
            <GalleryPreview />
          </GalleryForm>
        </div>
      </LoadStateData>
    </Page>
  );
};

GalleryCreatePage.displayName = 'GalleryCreatePage';
