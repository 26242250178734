import _defineProperty from 'babel-runtime/helpers/defineProperty';
import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon, Tooltip } from '../';

var propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    caption: PropTypes.string,
    icon: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string
  })).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  activeColour: PropTypes.string,
  className: PropTypes.string
};

var propDescriptions = {
  options: 'an array of objects with "caption", "icon", and "value" key values.  \n      caption can be an empty string, icon and value are required.',
  label: 'To display above the list to identify its purpose',
  value: 'The active value. Corresponds to a value in the options array. Can be false',
  onChange: 'Function to pass the selected option back up to the parent component',
  activeColour: 'The color the icon should be when it is selected. Defaults to blue',
  className: 'optional classname for the togglelist'
};

var defaultProps = {
  activeColour: 'blue',
  className: ''
};

var ToggleList = function (_React$Component) {
  _inherits(ToggleList, _React$Component);

  function ToggleList() {
    _classCallCheck(this, ToggleList);

    return _possibleConstructorReturn(this, (ToggleList.__proto__ || _Object$getPrototypeOf(ToggleList)).apply(this, arguments));
  }

  _createClass(ToggleList, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          options = _props.options,
          label = _props.label,
          value = _props.value,
          className = _props.className,
          onChange = _props.onChange;

      return React.createElement(
        'div',
        { className: 'toggleList ' + className },
        React.createElement(
          'span',
          { className: 'toggleList__label' },
          label
        ),
        React.createElement(
          'ul',
          { className: 'toggleList__list' },
          options.map(function (option) {
            var _classNames;

            var isActive = option.value === value;
            var listIcon = React.createElement(Icon, {
              type: option.icon,
              size: 20,
              className: classNames((_classNames = {}, _defineProperty(_classNames, _this2.props.activeColour, isActive), _defineProperty(_classNames, 'disabled-grey', !isActive && !!value), _defineProperty(_classNames, 'dark-grey', !isActive && !value), _classNames))
            });
            return React.createElement(
              'li',
              {
                key: option.icon + '-' + value,
                className: classNames('toggleList__list--item', {
                  isActive: isActive
                })
              },
              React.createElement(
                'button',
                {
                  onClick: function onClick() {
                    return onChange(isActive ? null : option.value);
                  },
                  className: 'toggleList__list--button',
                  'aria-label': option.ariaLabel
                },
                option.caption !== '' ? React.createElement(
                  Tooltip,
                  { text: option.caption, position: 'bottomCenter' },
                  listIcon
                ) : listIcon
              )
            );
          })
        )
      );
    }
  }]);

  return ToggleList;
}(React.Component);

export default ToggleList;


ToggleList.propTypes = propTypes;
ToggleList.defaultProps = defaultProps;
ToggleList.propDescriptions = propDescriptions;