import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var RightsHashtag = function RightsHashtag(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'RightsHashtag_icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 833.3 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M833.3,541.7c0,274.1-383.5,447.3-399.7,454.4c-5.2,2.6-11.1,3.9-16.9,3.9c-5.9,0-11.7-1.3-16.9-3.9 C383.4,988.9,0,815.7,0,541.7v-500C0,18.9,18.9,0,41.7,0h750c22.8,0,41.7,18.9,41.7,41.7V541.7z M708.3,125H416.7v740.2 c33.2-17.6,86.6-48.2,138.7-89.2c69.7-54.7,153-140,153-234.4V125z' // eslint-disable-line max-len
    })
  );
};

RightsHashtag.propTypes = propTypes;

export default RightsHashtag;