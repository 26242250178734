import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Pause = function Pause(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Pause__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M416.7,958.3c0,22.8-18.9,41.7-41.7,41.7H41.7C18.9,1000,0,981.1,0,958.3V41.7C0,18.9,18.9,0,41.7,0H375\t\t\tc22.8,0,41.7,18.9,41.7,41.7V958.3z M1000,958.3c0,22.8-18.9,41.7-41.7,41.7H625c-22.8,0-41.7-18.9-41.7-41.7V41.7\t\tC583.3,18.9,602.2,0,625,0h333.3c22.8,0,41.7,18.9,41.7,41.7V958.3z' // eslint-disable-line max-len
    })
  );
};

Pause.propTypes = propTypes;

export default Pause;