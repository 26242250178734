import React from 'react';
import { IconProps } from '../icon.types';

export const Circle = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 18.4736C14.6318 18.4736 18.4668 14.6304 18.4668 10.0068C18.4668 5.375 14.6235 1.54004 9.9917 1.54004C5.36816 1.54004 1.5332 5.375 1.5332 10.0068C1.5332 14.6304 5.37646 18.4736 10 18.4736ZM10 17.0625C6.08203 17.0625 2.95264 13.9248 2.95264 10.0068C2.95264 6.08887 6.07373 2.95117 9.9917 2.95117C13.9097 2.95117 17.0474 6.08887 17.0557 10.0068C17.064 13.9248 13.918 17.0625 10 17.0625Z" />
    </svg>
  );
};
