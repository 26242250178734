{
  "status": {
    "published": "Published",
    "draft": "Draft",
    "hidden": "Hidden"
  },
  "visibility": {
    "hidden": "Hidden from Network",
    "shown": "Shown to Network"
  },
  "creatorType": {
    "owner": "Network Owner",
    "partner": "Network Partner"
  },
  "contextMenu": {
    "manageStory": "Manage Story",
    "edit": "Edit in Story Creator",
    "ariaLabel": "Edit story in story creator application in new tab",
    "preview": "Preview",
    "copyEmbed": "Copy embed code",
    "delete": "Delete",
    "showStory": "Show to network",
    "hideStory": "Hide from network",
    "download": "Download"
  },
  "search": {
    "filter": "Filter",
    "filtersApplied": "${count} Filter${plural} Applied",
    "filterBy": "Filter by:",
    "filterDropdownAriaLabel": "select story filters",
    "applyFilters": "Apply Filters",
    "addFilter": "Add Filter",
    "status": "Status",
    "visibility": "Visibility",
    "creatorType": "Creator Type",
    "dynamicFilterTags": "Dynamic Filter Tags",
    "dynamicFilterTagsAriaLabel": "Filter stories list. Press Spacebar to add filter, press Enter to submit form.",
    "dynamicFilterTypesPlaceholder": "Select filter",
    "placeholder": "Search story by title",
    "ariaLabel": "Search for stories by name. Press enter to submit.",
    "clearFilters": "Clear Filters",
    "clearResults": "Clear search results",
    "bottomOfList": "You've reached the bottom of the list. ",
    "backToTop": "Go back to top.",
    "empty": {
      "title": "No results found",
      "description": "Try adjusting your search or filter to find what you’re looking for.",
      "alt": "Person searching with a magnifying glass, partially obscured by a bush"
    }
  },
  "storyFilterTabs": {
    "myNetworks": "My Network",
    "creatorStories": "Creator Stories",
    "all": "All"
  },
  "errors": {
    "oops": "Oops!",
    "generalMessage": "Something went wrong.",
    "galleryNotFound": "Story not found.",
    "refresh": "Please refresh the page and try again.",
    "noSearchResults": "Your search returned 0 results",
    "searchWithFilters": "There was a problem applying these filters. Please refresh and try again.",
    "copyEmbed": "Failed to copy story embed code. Please try again",
    "downloadLinkError": "Your Story couldn’t be downloaded. Please try again."
  },
  "emptyState": {
    "title": "Start Sharing Your Content",
    "description": "Create a web story and show off your destination. You can use a mix of video, photography, text, and actionable links.",
    "button": "Create Story",
    "graphicAlt": "Four stories, one showing a cafe serving counter, one showing a peaceful mountain lake, one showing a traveller walking down a narrow cobblestone street, and the last two showing beautifully plated dishes.",
    "form": {
      "storyDetails": "Story Details"
    }
  },
  "form": {
    "storyDetails": "Story Details",
    "status": "Status",
    "creator": "Creator",
    "createdAt": "Created",
    "lastUpdated": "Last updated",
    "location": "Location",
    "pages": "Pages",
    "useStory": {
      "title": "Use Story",
      "editDescription": "Finish editing then publish your Story to access the actions below.",
      "editButton": "Edit Story"
    },
    "taxonomy": {
      "title": "Network Tags",
      "description": "Setting the taxonomy for this story will allow it to be recommended for galleries with matching taxonomies.",
      "campaignLabel": "Campaign",
      "campaignPlaceholder": "Select campaigns",
      "themeLabel": "Themes",
      "themePlaceholder": "Select themes",
      "personaLabel": "Personas",
      "personaPlaceholder": "Select personas",
      "seasonLabel": "Seasons",
      "seasonPlaceholder": "Select seasons"
    },
    "statistics": {
      "completions": "Completion rate",
      "completionsCopy1": "The average completion rate for this story since it was first published.",
      "completionsCopy2": "A story is completed at 100% when a user views all story pages.",
      "clicks": "Total Clicks",
      "views": "Views",
      "viewsCopy1": "The number of views on this story since it was first published. ",
      "viewsCopy2": "A view is counted when a user clicks and opens a story. ",
      "clickthrough": "Click-through rate",
      "clickthroughCopy1": "The average CTR for this story since it was first published.",
      "clickthroughCopy2": "Clicks on both the “Visit Website” and “More Details” CTAs are included.",
      "impressions": "Impressions",
      "impressionsCopy1": "The number of impressions on this Story from Google search and Google Discover since it was first published.",
      "impressionsCopy2": "Impression data last updated on ${date}.",
      "impressionsAll": "Total impressions",
      "impressionsSearchLabel": "Impressions from Google search",
      "impressionsDiscoverLabel": "Impressions from Google Discover",
      "date": "Data last updated: ${date}"
    },
    "visibility": {
      "title": "Visibility",
      "hidden": "Hidden from network",
      "visible": "Shown to network",
      "hide": "Hide From Network",
      "show": "Show to Network",
      "shownStory": "When a story is shown to the network, it can be displayed in all network galleries and recommendations.",
      "hiddenStory": "When a story is hidden from the network:",
      "hiddenStoryList1": "If published, it will stay published and indexed by Google",
      "hiddenStoryList2": "It can only be shown in galleries created by ${publisher}",
      "hiddenStoryList3": "It can’t be shown in any other galleries"
    },
    "expiration": {
      "title": "Publishing Schedule",
      "expirationLabel": "Expiry date",
      "expirationLabelByline": "When set, this story will be unpublished on the selected date.",
      "expirationPlaceholder": "Set expiration date"
    },
    "hideStoryModal": {
      "title": "Hide Story From Network",
      "description": "You're about to hide the story <strong>${title}</strong> from the network",
      "detailsIntro": "Once hidden:",
      "details": "<ul><li>If published, it will stay published and indexed by Google</li><li>It can only be shown in galleries created by <strong>${publisher}</strong></li><li>It can’t be shown in any other galleries</li></ul>",
      "reject": "Keep Story in Network",
      "accept": "Hide Story From Network"
    },
    "showStoryModal": {
      "title": "Show Story to Network",
      "description": "You’re about to show the story <strong>${title}</strong> to the network.",
      "detailsIntro": "",
      "details": "This will allow the story to be shown in all galleries and recommendations.",
      "reject": "Keep Story Hidden",
      "accept": "Show Story to Network"
    },
    "hideStorySnackbar": {
      "success": "Story \"${title}\" successfully hidden",
      "successWithoutStoryTitle": "Story successfully hidden",
      "fail": "Something went wrong. Please try hiding story again."
    },
    "showStorySnackbar": {
      "success": "Story \"${title}\" successfully shown",
      "successWithoutStoryTitle": "Story successfully shown",
      "fail": "Something went wrong. Please try showing story again."
    }
  },
  "preview": "Preview",
  "previewArialabel": "Preview story titled '${title}'",
  "button": {
    "createStory": "Create Story",
    "createStoryLabel": "Create a new story in story creator application"
  },
  "menu": "story menu",
  "embed": {
    "title": "Embed Published Story",
    "description": "Copy and paste the embed code to share your Story on your websites.",
    "draft": "To access embedding, first publish your story from Studio.",
    "copied": "Story embed code copied to the clipboard.",
    "copy": "Copy Embed Code",
    "ariaLabel": "Embed code to be copied. Button to copy is below input.",
    "textarea": {
      "title": "Embed Story",
      "description": "Insert the following code into your website where you would like this gallery to appear."
    }
  },
  "modal": {
    "cancel": "Cancel",
    "galleryDirtyModal": {
      "title": "Are you sure you want to leave?",
      "description": "Leaving before completing the gallery you started will not save what you’ve done so far. Be sure, this action cannot be reversed.",
      "accept": "Leave",
      "reject": "Stay"
    },
    "storyDownload": {
      "title": "Download CrowdRiff Story",
      "descriptionPrepare": "You’re about to download <strong>${title}</strong>.",
      "descriptionConfirm": "Success! Your Story is ready to download. The download page will open in a new tab.",
      "detailsPrepare": "For each Story page, include:",
      "detailsOverlay": "Overlay text",
      "detailsLocation": "Location label",
      "detailsCredit": "Photo/video credit",
      "prepareDownload": "Prepare Download",
      "gotoDownload": "Go to Download Page",
      "downloadSectionTitle": "Download as Video",
      "downloadSectionBody": "Download your Story and repurpose it on your social media accounts.",
      "downloadAction": "Download",
      "ariaLabel": "Download story button"
    },
    "storyDelete": {
      "title": "Delete Localhood Story",
      "descriptionQuestion": "Are you sure you want to delete the Story <strong>${title}</strong>?",
      "descriptionQuestionNoTitle": "Are you sure you want to delete this Story?",
      "descriptionOutcome": "Once deleted, it will no longer appear in Galleries, embeds, or search results.",
      "deleteStory": "Delete Story"
    },
    "storyExport": {
      "title": "Download video",
      "description": "You are about to download <strong>${title}</strong>.",
      "descriptionMissingTitle": "You are about to download this video.",
      "downloadLocationHeader": "Download Location:",
      "overlayHeader": "Settings:",
      "locationThisDevice": "This device",
      "locationMobileDevice": "Mobile device (via QR code)",
      "detailsOverlay": "Include text elements",
      "detailsLocation": "Include location label",
      "detailsCredit": "Include visual credit",
      "detailsIndividual": "Download as individual pages",
      "detailsIndividualAlert": "Make sure your browser allows pop-ups to download individual pages.",
      "acceptAction": "Download"
    },
    "storyExportQRCode": {
      "description": "Scan the QR code to download this video on your mobile device."
    }
  },
  "toast": {
    "storyExport": {
      "pendingTitle": "Preparing to download",
      "pendingDescription": "Please do not close this window.",
      "pendingStatus": "Page ${pageCompleted} of ${pageCount} ready",
      "pendingCancelCta": "Cancel Download",
      "successTitle": "Downloading",
      "successDescription": "Your download should begin shortly.",
      "successStatus": "Ready for download",
      "errorTitle": "Download failed! Try again."
    }
  },
  "storyCard": {
    "close": "Delete",
    "drag": "Rearrange"
  },
  "challenges": {
    "name": "Challenge Name",
    "cancel": "Cancel",
    "networks": "Networks",
    "submit": "Submit to Challenge",
    "challenge": "Challenge",
    "requiredField": "This field is required",
    "assignStory": {
      "assignTitle": "Submit Story To Challenge",
      "cancel": "Cancel",
      "submitStory": "Submit Story",
      "success": "Story submitted to challenge.",
      "fail": "Story couldn’t be submitted to challenge. Please try again."
    }
  },
  "delete": {
    "success": "The Story \"${title}\" was deleted",
    "successNoStoryTitle": "The Story was deleted",
    "failure": "Story couldn't be deleted. Please try again."
  },
  "creator": {
    "snackbar": {
      "publishTitle": "Your Story is now live!",
      "publishMessage": "It'll soon be surfaced on Google search and Discover.",
      "unpublishMessage": "Your story is unpublished and is now a draft.",
      "draftMessage": "Your Story was saved as a draft. Edit your Story anytime to publish."
    }
  }
}
