import { GlobalTag } from '../../../redux/global_tags/global_tags.types';
import { Location } from '../../../redux/locations/locations.types';
import { NetworkTag } from '../../../redux/network_tags/network_tags.types';
import { StoryStat } from '../../../redux/stories/stories.types';

export enum STORY_STATUS {
  PUBLISHED = 'published',
  DRAFT = 'draft',
}

export enum STORY_VISIBILITY {
  HIDDEN = 'hidden',
  SHOWN = 'shown',
}
interface StoryData {
  url: string;
  canonicalUrl: string;
}

export interface Story {
  uuid: string;
  title: string;
  image: {
    url: string;
    width: number;
    height: number;
    focalPointX: number;
    focalPointY: number;
    thumbnailUrl: string;
  };
  publisher: {
    slug: string;
    name: string;
    logoSrc: string;
    type: string;
  };
  storyData?: StoryData;
  story?: StoryData;
  status: STORY_STATUS;
  createdAt: string;
  updatedAt: string;
  expiredAt?: string;
  pages?: number;
  locations?: Location[];
  globalTags?: GlobalTag[];
  networkTags?: NetworkTag[];
  stats?: StoryStat;
  // Gateway returns isHidden key on Story in 'story-get` response
  // vs is_hidden in 'story-search' response. One of these keys will be
  // removed after we update both these to be consistent
  is_hidden?: boolean;
  isHidden?: boolean;
  is_challenge_story?: boolean;
}

export enum PUBLISHER_TYPE {
  INDIVIDUAL = 'individual',
  ORGANIZATION = 'organization',
}

export interface LocationDetails {
  address_components: unknown[];
  formatted_address?: string;
  geometry: {
    location: Location;
  };
  place_id: string;
  types: string[];
  url: string;
  utc_offset: number;
  vicinity: string;
  website: string;
  description: string;
}

export interface StoryApiPublisher {
  slug: string;
  name: string;
  logo_src?: string;
  logoSrc?: string;
  type: PUBLISHER_TYPE;
  favicon_src?: string;
  org_id?: string;
  gallery_domain?: string;
  label?: string;
}

export interface VisualData {
  base_duration?: number;
  base_file_url?: string;
  base_height?: number;
  base_width?: number;
  created_at?: string;
  crop_bottom?: number;
  crop_left?: number;
  crop_right?: number;
  crop_top?: number;
  current_duration?: number;
  current_file_url?: string;
  current_height?: number;
  current_width?: number;
  error_message?: string;
  focal_point_x?: number;
  focal_point_y?: number;
  open_graph_img_url?: string;
  publisher_slug: string;
  raw_file_name?: string;
  raw_file_url?: string;
  sound_disabled?: true;
  status: string;
  thumbnail_url?: string;
  trim_end?: number;
  trim_start?: number;
  type: string;
  updated_at?: string;
  uuid: string;
}

export interface Page {
  created_at?: string;
  cta_book_url?: string;
  cta_learn_more_url?: string;
  location_details?: LocationDetails;
  page_index: number;
  story_uuid: string;
  swipe_up_more_info?: string;
  template_name: string;
  template_options?: unknown;
  text?: string;
  updated_at?: string;
  uuid: string;
  visual?: VisualData;
  visual_uuid?: string;
}

export type StoryStatus = 'draft' | 'published' | 'deleted' | 'moderated';

export interface StoryApiStory {
  author?: string;
  book_cta_url?: string;
  created_at?: string;
  created_by_id?: string;
  deleted_at?: string;
  expired_at?: string;
  first_published_at?: string;
  learn_more_cta_url?: string;
  location_description?: string;
  location_details?: unknown;
  location_place_id?: string;
  more_info?: string;
  pages: Page[];
  poster_data: VisualData;
  poster_portrait_src: string;
  poster_visual_uuid?: string;
  published_at?: string;
  publisher: StoryApiPublisher;
  publisher_slug: string;
  seo_title?: string;
  status: StoryStatus;
  story_type: string;
  title: string;
  updated_at?: string;
  uuid: string;
  version: number;
  themes?: GlobalTag[];
  personas?: NetworkTag[];
}
