import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Icon } from '@crowdriff/flock/lib/Icon';
import './search.scss';

export enum SEARCH_MODE {
  SUBMIT = 'submit',
  CHANGE = 'change',
}
export interface SearchProps {
  // isClearable: boolean;
  id: string;
  ariaLabel: string;
  placeholder: string;
  onSearch: (val: string) => void;
  onClearSearch?: () => void;
  searchMode?: SEARCH_MODE;
}

export const Search = ({
  id,
  ariaLabel,
  placeholder,
  onSearch,
  onClearSearch,
  searchMode = SEARCH_MODE.SUBMIT,
}: SearchProps): ReactElement => {
  const { register, reset, getValues } = useForm();
  const { name, ref, onChange, onBlur } = register(id);
  const [isClearable, setIsClearable] = useState(false);

  const handleClearSearch = () => {
    onClearSearch?.();
    reset();
    setIsClearable(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    onChange(event);

    if (val.length) {
      setIsClearable(true);
    } else {
      handleClearSearch();
    }

    if (searchMode === SEARCH_MODE.CHANGE) {
      onSearch(val);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const searchValue = getValues(id);

    if (searchMode === SEARCH_MODE.SUBMIT) {
      onSearch(searchValue);
    }
  };

  return (
    <form id={`search-component__${id}`} className="search__form" role="search" onSubmit={handleSubmit}>
      <div className="search">
        <Icon type="search" fill="#000000" size={14} />
        <input
          name={name}
          ref={ref}
          aria-label={ariaLabel}
          id={id}
          placeholder={placeholder}
          type="search"
          onChange={handleChange}
          onBlur={onBlur}
          className="search__input"
        />
        {isClearable && (
          <button
            type="button"
            className="search__btn-clear"
            onClick={handleClearSearch}
          >
            {/* THIS IS A TEMPORARY SOLUTION UNTIL WE CREATE OUR OWN ICONS LIBRARY */}
            <svg
              width="14"
              height="14"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 18.4736C14.6318 18.4736 18.4668 14.6304 18.4668 10.0068C18.4668 5.375 14.6235 1.54004 9.9917 1.54004C5.36816 1.54004 1.5332 5.375 1.5332 10.0068C1.5332 14.6304 5.37646 18.4736 10 18.4736ZM10 17.0625C6.08203 17.0625 2.95264 13.9248 2.95264 10.0068C2.95264 6.08887 6.07373 2.95117 9.9917 2.95117C13.9097 2.95117 17.0474 6.08887 17.0557 10.0068C17.064 13.9248 13.918 17.0625 10 17.0625ZM7.16113 13.5181C7.35205 13.5181 7.51807 13.4434 7.64258 13.3105L9.9917 10.9531L12.3574 13.3105C12.4819 13.4351 12.6396 13.5181 12.8306 13.5181C13.1958 13.5181 13.4946 13.2192 13.4946 12.8457C13.4946 12.6548 13.4282 12.5054 13.2954 12.3726L10.938 10.0151L13.3037 7.64111C13.4448 7.5 13.5029 7.35889 13.5029 7.17627C13.5029 6.81104 13.2041 6.51221 12.8389 6.51221C12.6646 6.51221 12.5234 6.57861 12.3823 6.71973L9.9917 9.08545L7.62598 6.72803C7.50146 6.59521 7.35205 6.52881 7.16113 6.52881C6.7959 6.52881 6.49707 6.81934 6.49707 7.18457C6.49707 7.36719 6.57178 7.5249 6.69629 7.64941L9.05371 10.0151L6.69629 12.3809C6.57178 12.5054 6.49707 12.6631 6.49707 12.8457C6.49707 13.2192 6.7959 13.5181 7.16113 13.5181Z"
                fill="#000000"
              />
            </svg>
          </button>
        )}
      </div>
    </form>
  );
};
