import _slicedToArray from 'babel-runtime/helpers/slicedToArray';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button/Button';

var propTypes = {
  activeColor: PropTypes.string,
  isBorderBottom: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onClick: PropTypes.func
  })),
  selectedValue: PropTypes.string,
  className: PropTypes.string
};

var propDescriptions = {
  activeColor: 'The color the border bottom should be when it is selected. Defaults to blue',
  options: 'An array of objects containing "label", "value" and "onClick" for each radio toggle.',
  isBorderBottom: 'An optional grey Border Bottom',
  className: 'A string of any additional classes to be added to the component',
  selectedValue: 'Default value for the active or selected item'
};

var defaultProps = {
  activeColor: 'blue'
};

var DynamicTabs = function DynamicTabs(_ref) {
  var options = _ref.options,
      activeColor = _ref.activeColor,
      selectedValue = _ref.selectedValue,
      className = _ref.className,
      isBorderBottom = _ref.isBorderBottom;

  var _useState = useState(selectedValue),
      _useState2 = _slicedToArray(_useState, 2),
      activeTab = _useState2[0],
      setActiveTab = _useState2[1];

  useEffect(function () {
    setActiveTab(selectedValue);
  }, [selectedValue]);

  var handleClick = function handleClick(option) {
    setActiveTab(option.value);
    option.onClick();
  };

  return React.createElement(
    'div',
    { className: classNames('dynamicTabs', className, {
        underline: isBorderBottom
      })
    },
    React.createElement(
      'div',
      { className: 'dynamicTabs__headerTabs' },
      options.map(function (option) {
        var dynamicTabsClasses = classNames('dynamicTabs__itemWrapper', className, activeColor, {
          'dynamicTabs__itemWrapper--isActive': activeTab === option.value
        });
        return React.createElement(
          Button,
          {
            key: option.value,
            onClick: function onClick() {
              return handleClick(option);
            },
            className: 'dynamicTabs__button'
          },
          React.createElement(
            'div',
            {
              className: dynamicTabsClasses,
              'data-text': option.label
            },
            option.label
          )
        );
      })
    )
  );
};

DynamicTabs.propTypes = propTypes;
DynamicTabs.defaultProps = defaultProps;
DynamicTabs.propDescriptions = propDescriptions;

export default DynamicTabs;