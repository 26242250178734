import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var QuestionMark = function QuestionMark(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'questionMark__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      null,
      React.createElement('path', {
        d: 'M500,1000C224,1000,0,776,0,500S224,0,500,0c276,0,500,224,500,500S776,1000,500,1000z M513,166.7c-106.1,0-185.5,45.6-241.5,138.7c-5.9,9.1-3.3,20.8,5.2,27.3l85.9,65.1c3.3,2.6,7.8,3.9,12.4,3.9c5.9,0,12.4-2.6,16.3-7.8c30.6-39.1,43.6-50.8,56-59.9c11.1-7.8,32.5-15.6,56-15.6c41.7,0,80.1,26.7,80.1,55.3c0,33.9-17.6,50.8-57.3,69c-46.2,20.8-109.4,74.9-109.4,138v23.4c0,11.7,9.1,20.8,20.8,20.8h125c11.7,0,20.8-9.1,20.8-20.8c0-15,18.9-46.9,49.5-64.5C682.3,511.7,750,474,750,375C750,255.9,625,166.7,513,166.7z M583.3,687.5c0-11.7-9.1-20.8-20.8-20.8h-125c-11.7,0-20.8,9.1-20.8,20.8v125c0,11.7,9.1,20.8,20.8,20.8h125c11.7,0,20.8-9.1,20.8-20.8V687.5z' // eslint-disable-line max-len
      })
    )
  );
};

QuestionMark.propTypes = propTypes;

export default QuestionMark;