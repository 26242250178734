import React from 'react';
import { IconProps } from '../icon.types';

export const ArrowClockwise = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 18.4736C14.6318 18.4736 18.4668 14.6304 18.4668 10.0068C18.4668 5.375 14.6235 1.54004 9.9917 1.54004C5.36816 1.54004 1.5332 5.375 1.5332 10.0068C1.5332 14.6304 5.37646 18.4736 10 18.4736ZM10 17.0625C6.08203 17.0625 2.95264 13.9248 2.95264 10.0068C2.95264 6.08887 6.07373 2.95117 9.9917 2.95117C13.9097 2.95117 17.0474 6.08887 17.0557 10.0068C17.064 13.9248 13.918 17.0625 10 17.0625ZM6.28955 10.3887C6.28955 12.4639 7.94141 14.124 9.9917 14.124C12.042 14.124 13.6938 12.4639 13.6938 10.4136C13.6938 10.1064 13.4282 9.84912 13.1211 9.84912C12.814 9.84912 12.5483 10.1064 12.5483 10.4136C12.5483 11.833 11.4111 12.9785 9.9917 12.9785C8.57227 12.9785 7.43506 11.8413 7.43506 10.4136C7.43506 8.99414 8.57227 7.85693 9.9917 7.85693C10.0996 7.85693 10.2158 7.86523 10.2988 7.88184L9.48535 8.67041C9.38574 8.77832 9.32764 8.90283 9.32764 9.06055C9.32764 9.35107 9.56006 9.5752 9.85059 9.5752C9.9917 9.5752 10.1245 9.50879 10.2158 9.42578L11.8262 7.81543C12.0337 7.61621 12.0171 7.24268 11.8262 7.04346L10.2324 5.4248C10.1411 5.3252 10 5.25879 9.85059 5.25879C9.55176 5.25879 9.32764 5.49121 9.32764 5.79004C9.32764 5.93115 9.38574 6.07227 9.47705 6.16357L10.0664 6.76123C10 6.76123 9.90869 6.74463 9.83398 6.74463C7.96631 6.74463 6.28955 8.34668 6.28955 10.3887Z" />
    </svg>
  );
};
