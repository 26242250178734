import { ActionCreator, Action } from 'redux';
import {
  UpdateCurrentPublisherAction,
  PUBLISHER_SWITCHING,
  GET_PUBLISHER_TYPES,
  GetPublisherActionStart,
  GetPublisherActionSuccess,
  GetPublisherActionFailure,
  GET_PUBLISHERS_TYPES,
  GetPublishersActionStart,
  GetPublishersActionSuccess,
  GetPublishersActionFailure,
  Publisher,
  GET_NP_FEATURE_FLAGS,
  GetNetworkPublishersFeatureFlagsActionStart,
  GetNetworkPublishersFeatureFlagsActionSuccess,
  GetNetworkPublishersFeatureFlagsActionFailure,
  InitPendoSuccessAction,
  INIT_PENDO,
  GET_ACCEPTANCE,
  GetUserAcceptanceActionSuccess,
  GetUserAcceptanceActionStart,
  GetUserAcceptanceActionFailure,
  SetUserAcceptanceActionFailure,
  SetUserAcceptanceActionStart,
  SetUserAcceptanceActionSuccess,
  SET_ACCEPTANCE,
  UPDATE_PUBLISHER_TYPES,
  UpdatePublisherActionStart,
  UpdatePublisherActionSuccess,
  UpdatePublisherActionFailure,
  UpdateLogoandFaviconActionStart,
  UpdateLogoandFaviconActionSuccess,
  UpdateLogoandFaviconActionFailure,
  UPDATE_LOGO_FAVICON_TYPES,
} from './user.types';
import { FEATURE_FLAGS } from '../../react/blocks/authwrapper/auth_wrapper.types';
import { CRActionCreator } from '../utils.types';
import { GetPublisherResponse } from '../../http/publisher_get.thunk';
import { UploadFilesResponse } from '../../http/account_update.thunk';

export const updatePublisherSwitchingAction: ActionCreator<Action> = (
  publisher: Publisher,
): UpdateCurrentPublisherAction => ({
  type: PUBLISHER_SWITCHING.UPDATE_PUBLISHER,
  payload: publisher,
});

export const getPublisherStartAction: ActionCreator<Action> = (
  payload: string,
): GetPublisherActionStart => ({
  type: GET_PUBLISHER_TYPES.START,
  payload,
});
export const getPublisherSuccessAction: ActionCreator<Action> = (
  payload: GetPublisherResponse,
): GetPublisherActionSuccess => ({
  type: GET_PUBLISHER_TYPES.SUCCESS,
  payload,
});
export const getPublisherFailureAction: ActionCreator<Action> = (
  error: string,
): GetPublisherActionFailure => ({
  type: GET_PUBLISHER_TYPES.FAILURE,
  payload: error,
});

export const getPublishersStartAction: ActionCreator<Action> = (): GetPublishersActionStart => ({
  type: GET_PUBLISHERS_TYPES.START,
  payload: {},
});
export const getPublishersSuccessAction: ActionCreator<Action> = (
  payload: Publisher[],
): GetPublishersActionSuccess => ({
  type: GET_PUBLISHERS_TYPES.SUCCESS,
  payload,
});
export const getPublishersFailureAction: ActionCreator<Action> = (
  error: string,
): GetPublishersActionFailure => ({
  type: GET_PUBLISHERS_TYPES.FAILURE,
  payload: error,
});

export const updatePublisherStartAction: ActionCreator<Action> = (): UpdatePublisherActionStart => ({
  type: UPDATE_PUBLISHER_TYPES.START,
  payload: {},
});
export const updatePublisherSuccessAction: ActionCreator<Action> = (
  payload: Publisher,
): UpdatePublisherActionSuccess => ({
  type: UPDATE_PUBLISHER_TYPES.SUCCESS,
  payload,
});
export const updatePublisherFailureAction: ActionCreator<Action> = (
  error: string,
): UpdatePublisherActionFailure => ({
  type: UPDATE_PUBLISHER_TYPES.FAILURE,
  payload: error,
});
export const getNetworkPublishersFeatureFlagsStartAction: CRActionCreator<GetNetworkPublishersFeatureFlagsActionStart> = () => ({
  type: GET_NP_FEATURE_FLAGS.START,
  payload: {},
});
export const getNetworkPublishersFeatureFlagsSuccessAction: CRActionCreator<GetNetworkPublishersFeatureFlagsActionSuccess> = (
  payload: FEATURE_FLAGS | FEATURE_FLAGS[],
) => ({
  type: GET_NP_FEATURE_FLAGS.SUCCESS,
  payload,
});
export const getNetworkPublishersFeatureFlagsFailureAction: CRActionCreator<GetNetworkPublishersFeatureFlagsActionFailure> = (
  error: Error,
) => ({
  type: GET_NP_FEATURE_FLAGS.FAILURE,
  payload: error,
});
export const isPendoInitializedSuccessAction: ActionCreator<Action> = (
  payload: boolean,
): InitPendoSuccessAction => ({
  type: INIT_PENDO.SUCCESS,
  payload,
});

export const getUserAcceptanceStartAction: CRActionCreator<GetUserAcceptanceActionStart> = () => ({
  type: GET_ACCEPTANCE.START,
  payload: {},
});

export const getUserAcceptanceSuccessAction: CRActionCreator<GetUserAcceptanceActionSuccess> = (
  payload: boolean,
) => ({
  type: GET_ACCEPTANCE.SUCCESS,
  payload,
});

export const getUserAcceptanceFailureAction: CRActionCreator<GetUserAcceptanceActionFailure> = (
  error: Error,
) => ({
  type: GET_ACCEPTANCE.FAILURE,
  payload: error,
});

export const setUserAcceptanceStartAction: CRActionCreator<SetUserAcceptanceActionStart> = () => ({
  type: SET_ACCEPTANCE.START,
  payload: {},
});

export const setUserAcceptanceSuccessAction: CRActionCreator<SetUserAcceptanceActionSuccess> = (
  payload: boolean,
) => ({
  type: SET_ACCEPTANCE.SUCCESS,
  payload,
});

export const setUserAcceptanceFailureAction: CRActionCreator<SetUserAcceptanceActionFailure> = (
  error: Error,
) => ({
  type: SET_ACCEPTANCE.FAILURE,
  payload: error,
});

export const updateLogoAndFaviconStartAction: ActionCreator<Action> = (): UpdateLogoandFaviconActionStart => ({
  type: UPDATE_LOGO_FAVICON_TYPES.START,
  payload: {},
});
export const updateLogoAndFaviconSuccessAction: ActionCreator<Action> = (
  payload: UploadFilesResponse,
): UpdateLogoandFaviconActionSuccess => ({
  type: UPDATE_LOGO_FAVICON_TYPES.SUCCESS,
  payload,
});
export const updateLogoAndFaviconFailureAction: ActionCreator<Action> = (
  error: string,
): UpdateLogoandFaviconActionFailure => ({
  type: UPDATE_LOGO_FAVICON_TYPES.FAILURE,
  payload: error,
});
