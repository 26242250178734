import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var SpeechBubble = function SpeechBubble(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      height: size,
      viewBox: '0 0 32 32',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg'
    },
    React.createElement('path', {
      d: 'M20.3766 23.8636C21.6783 23.6538 22.9011 23.2085 24.0001 22.5728L28.8001 23.4665L27.9063 18.6665C28.8139 17.0976 29.3334 15.276 29.3334 13.3332C29.3334 7.44213 24.5578 2.6665 18.6667 2.6665C13.2969 2.6665 8.85384 6.63448 8.10969 11.7983M10.0001 29.3332C14.0502 29.3332 17.3334 26.05 17.3334 21.9998C17.3334 17.9497 14.0502 14.6665 10.0001 14.6665C5.94999 14.6665 2.66675 17.9497 2.66675 21.9998C2.66675 23.3356 3.02385 24.5878 3.64781 25.6665L3.03341 28.9665L6.33341 28.3521C7.41205 28.9761 8.66436 29.3332 10.0001 29.3332Z',
      stroke: color,
      'stroke-width': '2',
      'stroke-linecap': 'round',
      'stroke-linejoin': 'round'
    })
  );
};

SpeechBubble.propTypes = propTypes;

export default SpeechBubble;