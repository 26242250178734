import { connect } from 'react-redux';
import { AppState } from '../../../types';
import { GalleryListPage as GalleryListPageComponent } from './galleries_list.component';
import { selectAllGalleries } from '../../../redux/galleries/galleries.selectors';

const mapStateToProps = (state: AppState) => ({
  galleries: selectAllGalleries(state),
});

export const mapDispatchToProps = () => ({});

export const GalleryListPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GalleryListPageComponent);
