import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../types';
import { StoriesListPageComponent } from './stories_list.component';
import {
  selectAllStories,
  selectStoriesFilterTabDefaultValue,
} from '../../../redux/stories/stories.selectors';
import { StoriesFilterTab } from '../../../redux/stories/stories.types';
import { storiesUpdateFilterTabAction } from '../../../redux/stories/stories.actions';

const mapStateToProps = (state: AppState) => ({
  stories: selectAllStories(state),
  defaultStoriesTab: selectStoriesFilterTabDefaultValue(state),
});

export const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  updateStoriesFilterTab: (tab: StoriesFilterTab) =>
    dispatch(storiesUpdateFilterTabAction(tab)),
});

export const StoriesListPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StoriesListPageComponent);
