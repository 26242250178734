import React from 'react';
import PropTypes from 'prop-types';

var propDescriptions = {
  title: 'Title of the card',
  description: 'Optional description line'
};

var propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node
};

var defaultProps = {};

var Card = function Card(_ref) {
  var title = _ref.title,
      description = _ref.description,
      children = _ref.children;

  return React.createElement(
    'div',
    { className: 'cr__card' },
    React.createElement(
      'div',
      { className: 'cr__card__title' },
      title
    ),
    description && React.createElement(
      'div',
      { className: 'cr__card__description' },
      description
    ),
    children
  );
};

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;
Card.propDescriptions = propDescriptions;

export default Card;