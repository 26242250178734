import { ThunkDispatch } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { HydratedGallery } from '../redux/galleries/galleries.types';
import { AppState, AppActions } from '../types';
import {
  selectCurrentNetworkId,
  selectCurrentPublisherId,
} from '../redux/user/user.selectors';
import { getErrorMessage } from '../utils';

export interface GalleryGetResponse {
  gallery: HydratedGallery;
}

export const getGallery = (galleryId: string) => async (
  dispatch: ThunkDispatch<AppState, {}, AppActions>,
  getState: () => AppState,
): Promise<GalleryGetResponse> => {
  const state = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const publisherId = selectCurrentPublisherId(state);
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.GALLERIES,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
      galleryId,
    },
  });
  try {
    const res: AxiosResponse<GalleryGetResponse> = await axios.get<
      GalleryGetResponse
    >(url);

    return res.data;
  } catch (err) {
    throw new Error(`Failed to get gallery: ${getErrorMessage(err)}`);
  }
};
