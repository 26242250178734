import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var QuestionMarkCircle = function QuestionMarkCircle(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'QuestionMarkCircle__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M498.8,0C222,1.2-1,225.3,0,501c1,277.2,225.3,500.2,501.6,499c276.1-1.2,500-225.9,498.4-500.4l0,0C998.4,221.6,774.9-1.2,498.8,0z M500.2,955.3C249.1,955.7,44.5,751.1,44.7,499.8C44.9,248.7,248.9,44.9,499.8,44.7v0c250.9-0.2,455.1,203.6,455.5,454.7C955.7,750.5,751.7,954.9,500.2,955.3z' // eslint-disable-line max-len
    }),
    React.createElement('path', {
      d: 'M526.2,651.8c-5.4-5.1-11.7-9.2-18.8-12.3c-7.2-3-14.9-4.5-23.2-4.5c-8.5,0-16.4,1.5-23.5,4.5c-7.2,3-13.4,7.1-18.7,12.3c-5.2,5.1-9.4,11.2-12.4,18.1c-3,7-4.5,14.5-4.5,22.6c0,8.1,1.5,15.6,4.5,22.7c3,7.1,7.2,13.2,12.4,18.3c5.3,5.1,11.5,9.2,18.7,12.1c7.2,2.9,15,4.4,23.5,4.4c8.3,0,16-1.4,23.2-4.4c7.2-2.9,13.5-6.9,18.8-12.1c5.4-5.1,9.6-11.3,12.6-18.3c3-7.1,4.5-14.6,4.5-22.7c0-8.1-1.5-15.6-4.5-22.6C535.8,663,531.6,657,526.2,651.8z' // eslint-disable-line max-len
    }),
    React.createElement('path', {
      d: 'M599.9,282.5C588,272,573.6,264,556.7,258.4c-16.9-5.6-35.9-8.4-57-8.4c-15.1,0-29,1.3-41.9,3.9c-12.9,2.6-25,6.2-36.1,10.8c-11.2,4.6-21.7,9.9-31.5,16c-9.7,6-18.8,12.6-27.1,19.5l27.6,42.4c4.7,8.3,11.9,12.5,21.5,12.5c4.3,0,8.5-1.2,12.8-3.7c4.3-2.5,9.3-5.2,14.9-8.2c5.7-3,12.6-5.7,20.5-8.2c8-2.5,17.9-3.7,29.8-3.7c15.5,0,27.6,4,36.5,11.9c8.8,8,13.2,18.7,13.2,32.1c0,11.2-2.4,20.9-7.1,29.1c-4.7,8.2-10.6,15.6-17.8,22.2c-7.2,6.6-14.9,12.8-23.2,18.5c-8.3,5.7-16,11.7-23.2,17.8c-7.2,6.2-13.1,12.9-17.8,20.3c-4.7,7.4-7.1,16-7.1,25.9c0,1.3,0.1,2.7,0.2,4c0.1,1.3,0.3,2.7,0.5,4l7.4,54.2h71.3l9.4-47.1c1.4-6.9,4.9-13.2,10.6-18.8c5.7-5.6,12.4-11.3,20.1-17.1c7.8-5.8,15.9-12.1,24.6-18.7c8.6-6.6,16.6-14.5,23.9-23.7c7.3-9.2,13.3-19.9,18-32.3c4.7-12.3,7.1-27.1,7.1-44.4c0-18-3.2-34.3-9.6-48.9C620.9,305.4,611.8,292.9,599.9,282.5z' // eslint-disable-line max-len
    })
  );
};

QuestionMarkCircle.propTypes = propTypes;

export default QuestionMarkCircle;