import React, { VoidFunctionComponent } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { SectionActionBar } from '../../static_galleries/section_action_bar';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { UnhydratedGallery } from '../../../../redux/galleries/galleries.types';
import { STORIES_SECTION_COPY_IDS } from './edit_stories_section.constants';
import './edit_stories_section.scss';
import { GallerySortableGrid } from '../../gallery_sortable_grid';
import { Story } from '../../story/story.types';
import { EmptyState } from '../../../blocks/empty_state';
import { GalleryAdditionalInfo } from '../../static_galleries/gallery_additional_info';
import { BUTTON_COLOR_VARIANT, BUTTON_VARIANT } from '../../../blocks/button';

export interface EditStoriesSectionProps extends LocalizeContextProps {
  updateStaticGallerySelectedStories: (sortedList: Story[]) => void;
  setManualGalleryCreationStep: (stepIndex: number) => void;
  gallery?: Partial<UnhydratedGallery> | null;
  selectedStories: Story[];
  handleSave: () => void;
  isSaving: boolean;
}

export const rootClass = 'edit_stories_section';

export const tooltipClasses = {
  root: `${rootClass}--tooltip`,
};

const EditStoriesSectionComponent: VoidFunctionComponent<EditStoriesSectionProps> = ({
  translate,
  updateStaticGallerySelectedStories,
  setManualGalleryCreationStep,
  gallery,
  selectedStories,
  handleSave,
  isSaving,
}) => {
  const getTranslation = useTranslateStrings(translate);

  return (
    <div className={rootClass}>
      <SectionActionBar
        title={getTranslation(STORIES_SECTION_COPY_IDS.TITLE)}
        descriptionContent={
          <p>{getTranslation(STORIES_SECTION_COPY_IDS.DESCRIPTION)}</p>
        }
        actionName={getTranslation(STORIES_SECTION_COPY_IDS.ACTION)}
        onActionClick={handleSave}
        isActionDisabled={selectedStories.length < 2}
        actionIsProcessing={isSaving}
        extraActionContent={
          <GalleryAdditionalInfo
            numberStories={Number(gallery?.story_ids?.length)}
          />
        }
      />
      {selectedStories.length ? (
        <GallerySortableGrid
          storyList={selectedStories}
          updateGalleryStories={updateStaticGallerySelectedStories}
        />
      ) : (
        <EmptyState
          className={`${rootClass}__empty_state`}
          title={getTranslation('galleries.emptyState.staticGalleryTitle')}
          description={getTranslation('galleries.emptyState.staticGalleryDesc')}
          buttonText={getTranslation(
            'galleries.emptyState.staticGalleryButton',
          )}
          onClick={() => setManualGalleryCreationStep(1)}
          actionVariant={BUTTON_VARIANT.OUTLINE}
          actionColorVariant={BUTTON_COLOR_VARIANT.SECONDARY}
        />
      )}
    </div>
  );
};

export const EditStoriesSection = withLocalize(EditStoriesSectionComponent);
