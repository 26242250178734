import React from 'react';
import moment from 'moment';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';

var propTypes = {
  date: PropTypes.string
};

var DateCell = function DateCell(_ref) {
  var date = _ref.date;

  return React.createElement(
    TableCell,
    null,
    React.createElement(
      'div',
      { className: 'cr-table__dateCell' },
      React.createElement(
        'span',
        { className: 'cr-table__dateCell__date' },
        moment(date).format('ll')
      ),
      React.createElement(
        'span',
        { className: 'cr-table__dateCell__time' },
        moment(date).format('LT')
      )
    )
  );
};

DateCell.propTypes = propTypes;

export default DateCell;