import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var AngleUp = function AngleUp(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'angleup__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M990,731.5l-50.1,50.1c-6,6-14,10-23,10c-8,0-17-4-23.1-10L500,387.8L106.2,781.6c-6,6-15,10-23,10c-8,0-17-4-23.1-10L10,731.5c-6-6-10-15-10-23.1c0-8,4-17,10-23l466.9-466.9c6-6,15-10,23-10c8,0,17,4,23.1,10L990,685.4c6,6,10,15,10,23C1000,716.4,996,725.4,990,731.5z' // eslint-disable-line max-len
    })
  );
};

AngleUp.propTypes = propTypes;

export default AngleUp;