

import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Exclamation = function Exclamation(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'cr__icon__exclamation',
      height: size,
      viewBox: '0 0 5 12',
      fill: color,
      xmlns: 'http://www.w3.org/2000/svg',
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', { d: 'M4.03111 0.867688C4.03111 0.79415 3.99437 0.696102 3.90864 0.634821C3.83516 0.57354 3.72494 0.5 3.61472 0.5H0.916387C0.806167 0.5 0.695945 0.57354 0.622466 0.634821C0.536739 0.696102 0.5 0.818667 0.5 0.892204L0.708196 6.50148C0.708196 6.66081 0.891893 6.78339 1.12458 6.78339H3.39428C3.61472 6.78339 3.79843 6.66081 3.81067 6.50148L4.03111 0.867688ZM3.83516 8.76277C3.83516 8.54215 3.66371 8.35831 3.44327 8.35831H1.08784C0.867399 8.35831 0.695948 8.54215 0.695948 8.76277V11.0955C0.695948 11.3162 0.867399 11.5 1.08784 11.5H3.44327C3.66371 11.5 3.83516 11.3162 3.83516 11.0955V8.76277Z' })
  );
};

Exclamation.propTypes = propTypes;

export default Exclamation;