import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var AngleLeft = function AngleLeft(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'angleleft__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 583.2 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M573.1,106.2L179.3,500l393.8,393.8c6,6,10,15,10,23.1c0,8-4,17-10,23L523.1,990c-6,6-15,10-23.1,10c-8,0-17-4-23-10L10,523c-6-6-10-15-10-23s4-17,10-23L477,10c6-6,15-10,23-10c8,0,17,4,23.1,10l50.1,50.1c6,6,10,14,10,23C583.2,91.2,579.2,100.2,573.1,106.2z' // eslint-disable-line max-len
    })
  );
};

AngleLeft.propTypes = propTypes;

export default AngleLeft;