import React from 'react';
import { Icon } from '@crowdriff/flock/lib/Icon';
import { Translate } from 'react-localize-redux';

export const GalleryPreviewEmpty = ({
  showNoStories,
}: {
  showNoStories: boolean;
}) => (
  <React.Fragment>
    <div className="gallery_preview__generate__floatingText">
      {showNoStories ? (
        <React.Fragment>
          <Icon type="warning" />
          <p>
            <Translate id="galleries.preview.noResults" />
          </p>
        </React.Fragment>
      ) : (
        <p>
          <Translate id="galleries.preview.clickGenerate" />
        </p>
      )}
    </div>

    <div className="gallery_preview__generate--empty-card" />
    <div className="gallery_preview__generate--empty-card" />
    <div className="gallery_preview__generate--empty-card" />
    <div className="gallery_preview__generate--empty-card" />
  </React.Fragment>
);
