import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Megaphone = function Megaphone(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'megaphone',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1166.7 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1166.7,416.7c0,46.2-37.1,83.3-83.3,83.3v250c0,45.6-37.7,83.3-83.3,83.3C884.2,737,697.3,604.9,471.4,586c-77.5,26-104.2,116.6-53.4,168.6c-45.6,74.9,13,127.6,82,181.6c-40.4,79.4-208.3,80.7-268.2,25.4C194,845.8,138,729.9,183.6,583.4h-79.4C46.9,583.4,0,536.5,0,479.2v-125C0,296.9,46.9,250,104.2,250h312.5c250,0,458.4-145.9,583.4-250c45.6,0,83.3,37.8,83.3,83.3v250C1129.7,333.4,1166.7,370.5,1166.7,416.7z M1000.1,105.5C830.2,235.7,665.4,310.6,500,328.8v175.8c165.4,18.2,330.1,91.8,500,222V105.5z' // eslint-disable-line max-len
    })
  );
};

Megaphone.propTypes = propTypes;

export default Megaphone;