import React from 'react';
import './file_upload.scss';
import { Button, BUTTON_COLOR_VARIANT, BUTTON_VARIANT } from '../button';
import { Text, TEXT_VARIANTS } from '../text';
import { Icon, ICON_NAMES, ICON_SIZES } from '../icon';

export interface FileUploadProps {
  name: string;
  title: string;
  onChange?: (file: File[]) => void;
  fileType: string;
  file?: File;
  isLoading?: boolean;
  invalid?: boolean;
}

export enum FILE_TYPES {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  FAVICON = 'image/x-icon',
}

export const FileUpload = ({
  name,
  title,
  onChange,
  fileType,
  file,
  isLoading = false,
  invalid = false,
}: FileUploadProps) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    hiddenFileInput.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!e.target.files) {
      return;
    }
    const numFiles = e.target.files?.length ?? 0;
    const filesUploaded: File[] = [];
    for (let i = 0; i < numFiles; i += 1) {
      filesUploaded.push(e.target.files[i]);
    }
    if (filesUploaded) {
      onChange?.(filesUploaded);
    }
  };

  return (
    <div className="file_upload">
      <input
        type="file"
        id={name}
        accept={fileType}
        className="hidden"
        ref={hiddenFileInput}
        onChange={handleChange}
        aria-invalid={invalid}
      />
      <Button
        variant={BUTTON_VARIANT.OUTLINE}
        color={BUTTON_COLOR_VARIANT.SECONDARY}
        onClick={handleClick}
        className="file_upload__cta"
        loading={isLoading}
      >
        {title}
      </Button>
      {file && (
        <div className="file_upload__file_info">
          <Icon
            iconKey={ICON_NAMES.CHECKMARK_CIRCLE}
            size={ICON_SIZES.MED}
            color="#008334"
            className="file_upload__file_info--icon"
          />
          <Text
            variant={TEXT_VARIANTS.B23}
            className="file_upload__file_info--name"
          >
            {file.name}
          </Text>
        </div>
      )}
    </div>
  );
};
