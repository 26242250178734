import React from 'react';
import { IconProps } from '../icon.types';

export const LightBulbCircle = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 18.4736C14.6318 18.4736 18.4668 14.6304 18.4668 10.0068C18.4668 5.375 14.6235 1.54004 9.9917 1.54004C5.36816 1.54004 1.5332 5.375 1.5332 10.0068C1.5332 14.6304 5.37646 18.4736 10 18.4736ZM8.38135 12.8955C8.22363 12.8955 8.12402 12.8042 8.12402 12.6548V11.9409C8.12402 10.8618 6.55518 10.1562 6.55518 8.23877C6.55518 6.19678 7.93311 4.83545 9.9917 4.83545C12.0503 4.83545 13.4282 6.19678 13.4282 8.23877C13.4282 10.1562 11.8594 10.8618 11.8594 11.9409V12.6548C11.8594 12.8042 11.7598 12.8955 11.6104 12.8955H8.38135ZM8.54736 14.0991C8.32324 14.0991 8.12402 13.9082 8.12402 13.6841C8.12402 13.4434 8.32324 13.269 8.54736 13.269H11.436C11.6685 13.269 11.8594 13.4434 11.8594 13.6841C11.8594 13.9082 11.6685 14.0991 11.436 14.0991H8.54736ZM9.9917 15.1782C9.37744 15.1782 8.9375 14.896 8.896 14.4561H11.0874C11.0376 14.896 10.606 15.1782 9.9917 15.1782Z" />
    </svg>
  );
};
