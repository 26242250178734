import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../types';
import {
  DOWNLOAD_ASSETS_BINDING_TYPES,
  ExportsPrepareOptions,
} from '../../../redux/exports/exports.types';
import {
  selectExportPrepareStatus,
  selectExportId,
  selectExportType,
  selectStoryId,
  selectDownload,
  selectDownloadLocation,
} from '../../../redux/exports/exports.selectors';
import { getStoryExportId } from '../../../http/exports_get.thunk';
import { downloadStories } from '../../../http/exports_assets_get.thunk';
import {
  exportsBindingAction,
  exportsResetAction,
  updateDownloadLocation,
} from '../../../redux/exports/exports.actions';
import { StoryExport } from './story_export.component';

const mapStateToProps = (state: AppState) => ({
  prepareStatus: selectExportPrepareStatus(state),
  storyId: selectStoryId(state),
  exportId: selectExportId(state),
  exportType: selectExportType(state),
  download: selectDownload(state),
  downloadLocation: selectDownloadLocation(state),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onExportPrepare: (
    {
      storyId,
      includeText,
      includeLocation,
      includeVisualCredit,
      isChallengeStory,
      source,
    }: ExportsPrepareOptions,
    downloadIndividualPages: boolean,
  ) => {
    dispatch(exportsBindingAction(downloadIndividualPages));
    dispatch(
      getStoryExportId(
        storyId,
        includeText,
        includeLocation,
        includeVisualCredit,
        isChallengeStory,
        source,
      ),
    );
  },
  onDownloadStart: (
    storyId: string,
    exportId: string | null,
    bindType: DOWNLOAD_ASSETS_BINDING_TYPES | null,
  ) => {
    dispatch(downloadStories(storyId, exportId, bindType));
  },
  onDownloadReset: (storyUuid: string) =>
    dispatch(exportsResetAction(storyUuid)),
  onUpdateDownloadLocation: (location: string) =>
    dispatch(updateDownloadLocation(location)),
});
export const StoryExportConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StoryExport);
