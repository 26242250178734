import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Layout = function Layout(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Layout__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M333.3,500H0V0h333.3V500z M1000,0H458.3v500H1000V0z M541.7,625H0v375h541.7V625z M1000,625H666.7v375H1000V625z' // eslint-disable-line max-len
    })
  );
};

Layout.propTypes = propTypes;

export default Layout;