import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Photos = function Photos(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'photos__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1000,400h-50v550H250V250h750v-50H200v800h800V400z M150,150h650v-50H100l0,0v550h50V150 M50,50h400V0H0l0,0v450h50V50M350,525c0-41.4,33.6-75,75-75s75,33.6,75,75s-33.6,75-75,75S350,566.4,350,525z M349.8,850l0.2-50l100-100l50,50l200-200l150,150v150H349.8z' // eslint-disable-line max-len
    })
  );
};

Photos.propTypes = propTypes;

export default Photos;