import React from 'react';
import { IconProps } from '../icon.types';

export const ChevronDown = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 14.4312C10.2075 14.4312 10.415 14.3481 10.5562 14.1904L16.981 7.60791C17.1221 7.4668 17.2051 7.28418 17.2051 7.07666C17.2051 6.64502 16.8813 6.31299 16.4497 6.31299C16.2422 6.31299 16.0513 6.396 15.9102 6.52881L10 12.5718L4.08154 6.52881C3.94873 6.396 3.75781 6.31299 3.54199 6.31299C3.11035 6.31299 2.78662 6.64502 2.78662 7.07666C2.78662 7.28418 2.86963 7.4668 3.01074 7.61621L9.43555 14.1904C9.59326 14.3481 9.78418 14.4312 10 14.4312Z" />
    </svg>
  );
};
