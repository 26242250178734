import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var TwitterLike = function TwitterLike(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'twitterLike__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 14 13',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M6.864 13C5.096 13 0 8.32 0 3.952 0 1.872 1.768 0 3.744 0c1.56 0 2.6 1.04 3.224 1.872C7.488 1.04 8.632 0 10.192 0c1.976 0 3.744 1.872 3.744 3.952C13.728 8.32 8.632 12.896 6.864 13zM3.64 1.04C2.184 1.04.936 2.392.936 3.952c0 3.952 4.784 7.904 5.824 8.008 1.04 0 5.824-4.056 5.824-8.008 0-1.56-1.248-2.912-2.704-2.912-1.768 0-2.704 1.976-2.704 2.08-.208.416-.832.416-.936 0 .104-.104-.832-2.08-2.6-2.08z' // eslint-disable-line max-len
    })
  );
};

TwitterLike.propTypes = propTypes;

export default TwitterLike;