import { Action, ActionCreator } from 'redux';
import {
  ClearStoriesSearchQueryAction,
  STORIES_LOAD_TYPES,
  STORIES_SEARCH_TYPES,
  StoriesFailureAction,
  StoriesSearchQueryAction,
  StoriesStartAction,
  StoriesSuccessAction,
  STORY_DELETE_TYPES,
  STORY_LOAD_TYPES,
  StoryDeleteFailureAction,
  StoryDeleteStartAction,
  StoryDeleteSuccessAction,
} from '../../react/pages/stories_list/stories_list.types';
import {
  StoryFailureAction,
  StoryStartAction,
  StorySuccessAction,
} from '../../react/pages/stories_manage/story_manage.types';
import {
  DOWNLOAD_STORY_TYPES,
  DownloadStoryFailureAction,
  DownloadStoryStartAction,
  DownloadStorySuccessAction,
  HIDE_STORY_TYPES,
  HideStoryFailureAction,
  HideStoryStartAction,
  HideStorySuccessAction,
  SearchFilters,
  SHOW_STORY_TYPES,
  ShowStoryFailureAction,
  ShowStoryStartAction,
  ShowStorySuccessAction,
  StoriesClearSearchFiltersAction,
  StoriesSearchFiltersAction,
  StoriesFilterTab,
  StoriesUpdateFilterTabAction,
  StoriesResetFilterTabAction,
} from './stories.types';
import { CRAction } from '../utils.types';

export const storiesSearchFiltersAction: ActionCreator<Action> = (
  payload: SearchFilters,
): StoriesSearchFiltersAction => ({
  type: STORIES_SEARCH_TYPES.SEARCH_FILTERS,
  payload,
});

export const storiesClearSearchFiltersAction: ActionCreator<Action> = (
  payload: SearchFilters,
): StoriesClearSearchFiltersAction => ({
  type: STORIES_SEARCH_TYPES.CLEAR_FILTERS,
  payload,
});

export const storiesUpdateFilterTabAction: ActionCreator<Action> = (
  payload: StoriesFilterTab,
): StoriesUpdateFilterTabAction => ({
  type: STORIES_SEARCH_TYPES.UPDATE_FILTER_TAB,
  payload,
});

export const storiesResetFilterTabAction: ActionCreator<Action> = (): StoriesResetFilterTabAction => ({
  type: STORIES_SEARCH_TYPES.RESET_FILTER_TAB,
});

export const storiesSuccessAction: ActionCreator<Action> = (
  payload,
): StoriesSuccessAction => ({
  type: STORIES_LOAD_TYPES.SUCCESS,
  payload,
});
export const storiesStartAction: ActionCreator<Action> = (
  payload,
): StoriesStartAction => ({
  type: STORIES_LOAD_TYPES.START,
  payload,
});
export const storiesFailureAction: ActionCreator<Action> = (
  payload: string,
): StoriesFailureAction => ({
  type: STORIES_LOAD_TYPES.FAILURE,
  payload,
});

export const storiesSearchQuery: ActionCreator<Action> = (
  payload: string,
): StoriesSearchQueryAction => ({
  type: STORIES_SEARCH_TYPES.SEARCH_QUERY,
  payload,
});

export const clearStoriesSearchQuery: ActionCreator<Action> = (
  payload: string,
): ClearStoriesSearchQueryAction => ({
  type: STORIES_SEARCH_TYPES.CLEAR_SEARCH_QUERY,
  payload,
});

export const storySuccessAction: ActionCreator<Action> = (
  payload,
): StorySuccessAction => ({
  type: STORY_LOAD_TYPES.SUCCESS,
  payload,
});
export const storyStartAction: ActionCreator<Action> = (): StoryStartAction => ({
  type: STORY_LOAD_TYPES.START,
  payload: {},
});
export const storyFailureAction: ActionCreator<Action> = (
  payload: string,
): StoryFailureAction => ({
  type: STORY_LOAD_TYPES.FAILURE,
  payload,
});

export const hideStoryStarted: ActionCreator<Action> = (
  payload,
): HideStoryStartAction => ({
  type: HIDE_STORY_TYPES.START,
  payload,
});
export const hideStorySucceeded: ActionCreator<Action> = (
  payload: string,
): HideStorySuccessAction => ({
  type: HIDE_STORY_TYPES.SUCCESS,
  payload,
});
export const hideStoryFailed: ActionCreator<Action> = (
  payload: Error,
): HideStoryFailureAction => ({
  type: HIDE_STORY_TYPES.FAILURE,
  payload,
});

export const showStoryStarted: ActionCreator<Action> = (
  payload,
): ShowStoryStartAction => ({
  type: SHOW_STORY_TYPES.START,
  payload,
});
export const showStorySucceeded: ActionCreator<Action> = (
  payload: string,
): ShowStorySuccessAction => ({
  type: SHOW_STORY_TYPES.SUCCESS,
  payload,
});
export const showStoryFailed: ActionCreator<Action> = (
  payload: Error,
): ShowStoryFailureAction => ({
  type: SHOW_STORY_TYPES.FAILURE,
  payload,
});

export const downloadStoryStarted = (
  payload = '',
): DownloadStoryStartAction => ({
  type: DOWNLOAD_STORY_TYPES.START,
  payload,
});
export const downloadStorySucceeded = (payload: {
  storyId: string;
  url: string;
}): DownloadStorySuccessAction => ({
  type: DOWNLOAD_STORY_TYPES.SUCCESS,
  payload,
});
export const downloadStoryFailed = (
  payload: string,
): DownloadStoryFailureAction => ({
  type: DOWNLOAD_STORY_TYPES.FAILURE,
  payload,
});

export const deleteStoryStarted: CRAction<StoryDeleteStartAction> = (
  payload,
) => ({
  type: STORY_DELETE_TYPES.START,
  payload,
});

export const deleteStorySuccess: CRAction<StoryDeleteSuccessAction> = (
  payload,
) => ({
  type: STORY_DELETE_TYPES.SUCCESS,
  payload,
});

export const deleteStoryFailure: CRAction<StoryDeleteFailureAction> = (
  payload,
) => ({
  type: STORY_DELETE_TYPES.FAILURE,
  payload,
});
