import React from 'react';
import { IconProps } from '../icon.types';

export const ChevronLeft = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.88672 10.0068C4.88672 10.2144 4.96143 10.397 5.11914 10.5547L11.7017 16.9878C11.8428 17.1372 12.0254 17.2119 12.2412 17.2119C12.6729 17.2119 13.0049 16.8882 13.0049 16.4565C13.0049 16.2407 12.9136 16.0581 12.7808 15.917L6.73779 10.0068L12.7808 4.09668C12.9136 3.95557 13.0049 3.76465 13.0049 3.55713C13.0049 3.12549 12.6729 2.80176 12.2412 2.80176C12.0254 2.80176 11.8428 2.87646 11.7017 3.01758L5.11914 9.45898C4.96143 9.6084 4.88672 9.79932 4.88672 10.0068Z"
        fill="black"
      />
    </svg>
  );
};
