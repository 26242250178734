import React from 'react';
import { IconProps } from '../icon.types';

export const HandCursor = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    // TODO: Make the viewbox be the same as other icons once we have a design solution
    // for stroke widths as icons scale
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        id="Vector"
        d="M7.16657 12.3143L5.6474 10.8676C5.52056 10.7469 5.3706 10.6531 5.20656 10.5918C5.04251 10.5305 4.86777 10.5031 4.69284 10.5111C4.51791 10.5191 4.34641 10.5625 4.18866 10.6385C4.03091 10.7145 3.89017 10.8216 3.7749 10.9535C3.56254 11.1958 3.44792 11.5085 3.45331 11.8308C3.4587 12.153 3.58372 12.4617 3.80407 12.6968L8.75573 17.9785C9.07157 18.3143 9.51073 18.5051 9.97157 18.5051H13.8332C15.8332 18.5051 17.1666 16.8385 17.1666 15.1718V8.0293"
        stroke={color}
        strokeWidth="1.21667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M17.168 8.02872C17.168 6.12372 14.668 6.12372 14.668 8.02872V8.50456V7.07622C14.668 5.17122 12.168 5.17122 12.168 7.07622V8.50456V6.42122C12.168 4.51622 9.66797 4.51622 9.66797 6.42122V8.50456V3.08706C9.66797 2.39622 9.10797 1.83789 8.41797 1.83789C8.08645 1.83789 7.76851 1.96959 7.53409 2.20401C7.29966 2.43843 7.16797 2.75637 7.16797 3.08789V12.3137"
        stroke={color}
        strokeWidth="1.21667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
