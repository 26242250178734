import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MetricComponent from '../Metric';

var propDescriptions = {
  layout: 'The direction of the metrics'
};

var propTypes = {
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  children: PropTypes.node
};

var defaultProps = {
  layout: 'horizontal'
};

var Metrics = function Metrics(_ref) {
  var layout = _ref.layout,
      children = _ref.children;

  var metricsArray = React.Children.toArray(children);
  var metricsNormalized = metricsArray.filter(function (child) {
    return child.type === MetricComponent && child;
  });

  return React.createElement(
    'div',
    { className: classNames('cr__metrics', 'cr__metrics--' + layout) },
    metricsNormalized
  );
};

Metrics.propTypes = propTypes;
Metrics.defaultProps = defaultProps;
Metrics.propDescriptions = propDescriptions;

export default Metrics;