import React, { useState, VoidFunctionComponent, Fragment } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { DetailedStory } from '../detailed_story.component';
import { StoryStatus } from '../../../blocks/story_status/story_status.component';
import { STORY_STATUS, STORY_VISIBILITY } from '../../story/story.types';
import { userHasAccess } from '../../../blocks/authwrapper/auth_wrapper.utils';
import { FEATURE_FLAGS } from '../../../blocks/authwrapper/auth_wrapper.types';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import {
  STORY_MODERATION_MODAL_TYPES,
  StoryModerationModal,
} from '../../story_moderation/story_moderation_modal.component';
import { useModal } from '../../../hooks/use_modal/use_modal';
import { handleHideStory, handleShowStory } from '../story.utils';
import { DetailedStoryModerationAndStatusProps } from './detailed_story_moderation_and_status.types';
import { Icon, ICON_NAMES } from '../../../blocks/icon';
import { useToast } from '../../../hooks/use_toast/use_toast';

export const DetailedStoryModerationAndStatusComponent: VoidFunctionComponent<DetailedStoryModerationAndStatusProps &
  LocalizeContextProps> = ({
  enabledFeatures,
  story,
  previewSrc,
  currentPublisherId,
  onHideStory,
  onShowStory,
  translate,
}) => {
  const [Modal, toggle] = useModal();
  const { showToast } = useToast();
  const [isUpdatingStoryVisibility, setIsUpdatingStoryVisibility] = useState(
    false,
  );

  const { status, is_hidden } = story;
  const getTranslation = useTranslateStrings(translate);
  const includeVisibility = userHasAccess(
    enabledFeatures,
    FEATURE_FLAGS.ENABLE_MODERATION,
  );

  const isStoryHidden = is_hidden
    ? STORY_VISIBILITY.HIDDEN
    : STORY_VISIBILITY.SHOWN;

  const buttonCopyId = story.is_hidden
    ? 'stories.contextMenu.showStory'
    : 'stories.contextMenu.hideStory';

  const onAcceptClick = () => {
    return story.is_hidden
      ? handleShowStory(
          story,
          setIsUpdatingStoryVisibility,
          showToast,
          onShowStory,
          translate,
        )
      : handleHideStory(
          story,
          setIsUpdatingStoryVisibility,
          showToast,
          onHideStory,
          translate,
        );
  };

  const extraMenuItems = includeVisibility
    ? [
        {
          id: 1,
          text: getTranslation(buttonCopyId),
          action: toggle,
        },
      ]
    : undefined;

  return (
    <Fragment>
      <DetailedStory
        story={story}
        previewSrc={previewSrc}
        currentPublisherId={currentPublisherId}
        includeDetailsContextMenu={true}
        extraMenuItems={extraMenuItems}
        loading={isUpdatingStoryVisibility}
      >
        <div className="story--status_wrapper">
          {status !== undefined &&
            Object.values(STORY_STATUS).includes(status) && (
              <StoryStatus
                status={status}
                Icon={<Icon iconKey={ICON_NAMES.CIRCLE} />}
              />
            )}
          {includeVisibility && isStoryHidden === STORY_VISIBILITY.HIDDEN && (
            <StoryStatus
              status={isStoryHidden}
              Icon={<Icon iconKey={ICON_NAMES.SLASH_CIRCLE} />}
            />
          )}
        </div>
      </DetailedStory>
      <StoryModerationModal
        Modal={Modal}
        story={story}
        onAcceptClick={onAcceptClick}
        type={
          story.is_hidden
            ? STORY_MODERATION_MODAL_TYPES.SHOW
            : STORY_MODERATION_MODAL_TYPES.HIDE
        }
      />
    </Fragment>
  );
};

export const DetailedStoryModerationAndStatus = withLocalize(
  DetailedStoryModerationAndStatusComponent,
);
