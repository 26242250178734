import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var DollarSign = function DollarSign(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'dollarSign',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 14 14',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', { d: 'M7.27734 8.05566C8.33691 8.32227 8.69922 8.61621 8.69922 9.19727C8.69922 9.80566 8.22754 10.2227 7.27734 10.3047V8.05566ZM6.49121 6.01172C5.5752 5.76562 5.17871 5.42383 5.17871 4.89062C5.17871 4.35742 5.62988 3.92676 6.49121 3.83105V6.01172ZM7.27734 6.18945V3.83789C8.04297 3.94727 8.55566 4.36426 8.65137 5.08887H10.4766C10.415 3.46191 9.17773 2.38867 7.27734 2.24512V1.14453H6.49121V2.24512C4.61133 2.36133 3.26465 3.43457 3.26465 5.0752C3.26465 6.56543 4.24902 7.37207 6.0332 7.77539L6.49121 7.87793V10.3047C5.56152 10.209 5.07617 9.7373 4.95996 9.06055H3.12793C3.18262 10.7695 4.57031 11.7812 6.49121 11.8975V12.9707H7.27734V11.8906C9.21875 11.7539 10.6064 10.7012 10.6064 8.97852C10.6064 7.47461 9.62891 6.71582 7.7627 6.29883L7.27734 6.18945Z' })
  );
};

DollarSign.propTypes = propTypes;

export default DollarSign;