import React, { ReactElement, useRef, useState } from 'react';
import clsx from 'clsx';
import './radio_group.scss';
import { ListItem } from '@material-ui/core';
import {
  RadioGroupProps,
  MappedRadioOption,
  KeyCodes,
} from './radio_group.types';
import { isNotNull } from '../../../types/typeguards';
import { TEXT_VARIANTS, Text } from '../text';

export const RadioGroup = ({
  name,
  label,
  options,
  labelAs = TEXT_VARIANTS.H2,
  value,
  onChange,
}: RadioGroupProps): ReactElement => {
  const radiogroup = useRef<HTMLDivElement>(null);
  const labelId = `${name}-label`;

  const mappedOptions: MappedRadioOption[] = options.map((o, i) => {
    return {
      ...o,
      value: o.value || o.label,
      id: `${name}-radio-${i}`,
    };
  });

  const [selectedValue, setSelectedValue] = useState<string | undefined>(value);

  const [focusedOptionId, setFocusedOptionId] = useState<string | undefined>(
    undefined,
  );

  const myStateRef: React.MutableRefObject<{
    selectedValue: string | undefined;
    focusedOptionId?: string;
  }> = useRef({ selectedValue, focusedOptionId });

  const updateSelectedValue = (val: string) => {
    myStateRef.current.selectedValue = val;
    setSelectedValue(myStateRef.current.selectedValue);
  };

  const updateFocusedId = (f_id: string | undefined) => {
    myStateRef.current.focusedOptionId = f_id;
    setFocusedOptionId(myStateRef.current.focusedOptionId);
  };

  const handleChange = (s_id: string, s_val: string) => {
    updateSelectedValue(s_val);
    updateFocusedId(s_id);
    onChange?.(s_val);

    if (isNotNull(radiogroup) && radiogroup.current) {
      radiogroup.current.focus();
    }
  };

  const handleInitialFocus = (e: React.FocusEvent) => {
    e.preventDefault();
    if (!myStateRef.current.focusedOptionId) {
      const selectedOptionId = mappedOptions.find(
        (o) => o.value === myStateRef.current.selectedValue,
      )?.id;

      if (selectedOptionId) {
        updateFocusedId(selectedOptionId);
      } else {
        updateFocusedId(mappedOptions[0].id);
      }
    }
  };

  const handleBlur = (e: React.FocusEvent) => {
    // Not triggered when swapping focus between children
    if (!e.currentTarget.contains(e.relatedTarget as Node | null)) {
      updateFocusedId(undefined);
    }
  };

  // Making the radio buttons keyboard accessible.
  // Users should be able to navigate the options
  // using navigation keys, and able to select using spacebar
  // This behavior is not provided by default
  const handleKeyDown = (e: React.KeyboardEvent) => {
    const focusedIndex = mappedOptions.findIndex(
      (o) => o.id === myStateRef.current.focusedOptionId,
    );

    if (Object.values(KeyCodes).includes(e.key as KeyCodes)) {
      e.preventDefault();
    }

    if (e.key === KeyCodes.DOWN || e.key === KeyCodes.RIGHT) {
      const nextOptionId = mappedOptions[focusedIndex + 1]?.id;
      if (nextOptionId) {
        updateFocusedId(nextOptionId);
      }
    } else if (e.key === KeyCodes.UP || e.key === KeyCodes.LEFT) {
      const prevOptionId = mappedOptions[focusedIndex - 1]?.id;
      if (prevOptionId) {
        updateFocusedId(prevOptionId);
      }
    } else if (e.key === KeyCodes.SPACEBAR) {
      const focusedOption = mappedOptions.find(
        (o) => o.id === myStateRef.current.focusedOptionId,
      );
      if (focusedOption && !focusedOption.isDisabled) {
        updateSelectedValue(focusedOption.value);
      }
    }
  };

  const preventInputFocusEvent = (e: React.FocusEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const radioElement = (
    isChecked: boolean,
    isFocused: boolean,
    option: MappedRadioOption,
    index: number,
  ) => (
    <ListItem key={index} className="radio-group__radio-container__list-item">
      <input
        type="radio"
        id={`${name}-radio-${index}`}
        checked={isChecked}
        aria-checked={isChecked}
        disabled={!!option.isDisabled}
        onChange={() => handleChange(option.id, option.value)}
        className={clsx('radio-group__radio-container__hidden-input', {
          checked: isChecked,
          disabled: option.isDisabled,
          focused: isFocused,
        })}
        value={option.value}
        tabIndex={-1}
        onFocus={(e) => preventInputFocusEvent(e)}
      />
      <label
        htmlFor={`${name}-radio-${index}`}
        className={clsx('radio-group__radio-container__label', {
          disabled: option.isDisabled,
          checked: isChecked,
        })}
      >
        <span>{option.label}</span>
      </label>
    </ListItem>
  );

  return (
    <div
      ref={radiogroup}
      role="radiogroup"
      aria-labelledby={labelId}
      id={name}
      className="radio-group"
      aria-activedescendant={focusedOptionId}
      tabIndex={0}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onFocus={handleInitialFocus}
      // Default action of mousedown is focus.
      // We don't want to focus on clicking the div
      // since that would trigger the onFocus handler.
      // onClick would still work!
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <div className="radio-group__label-container">
        <Text variant={labelAs}>{label}</Text>
      </div>
      <div className="radio-group__radio-container">
        {mappedOptions.map((o, i) => {
          const isChecked = o.value === selectedValue;
          const isFocused = o.id === focusedOptionId;

          return radioElement(isChecked, isFocused, o, i);
        })}
      </div>
    </div>
  );
};
