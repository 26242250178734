import { DATA_CELL_TYPE } from '../PaginatedTable.constants';

function desc(firstEl, secondEl, orderBy, cellType) {
  var A = firstEl;
  var B = secondEl;

  if (cellType === DATA_CELL_TYPE.LINK) {
    A = firstEl[orderBy].text;
    B = secondEl[orderBy].text;
  } else {
    A = firstEl[orderBy];
    B = secondEl[orderBy];
  }

  if (B < A) {
    return -1;
  }
  if (B > A) {
    return 1;
  }
  return 0;
}

export function stableSort(dataRows, compareFn) {
  var updatedArray = dataRows.map(function (el, index) {
    return [el, index];
  });
  updatedArray.sort(function (firstEl, secondEl) {
    var order = compareFn(firstEl[0], secondEl[0]);
    if (order !== 0) return order;
    return firstEl[1] - secondEl[1];
  });
  return updatedArray.map(function (el) {
    return el[0];
  });
}

export function getSorting(order, orderBy, cellType) {
  return order === 'desc' ? function (firstEl, secondEl) {
    return desc(firstEl, secondEl, orderBy, cellType);
  } : function (firstEl, secondEl) {
    return -desc(firstEl, secondEl, orderBy, cellType);
  };
}