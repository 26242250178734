import { Dispatch, SetStateAction } from 'react';
import {
  LocalizedElement,
  LocalizedElementMap,
  TranslateFunction,
  TranslatePlaceholderData,
  TranslateValue,
} from 'react-localize-redux';
// the React-router package is installed as part of react-router-dom
// eslint-disable-next-line import/no-extraneous-dependencies
import { NavigateFunction } from 'react-router';
import {
  DynamicGalleryFormSubmitData,
  TransformedGalleryFormData,
  GalleryFormSignals,
} from './gallery_form.types';
import { Location } from '../../../redux/locations/locations.types';
import {
  NetworkTag,
  NETWORK_TAG_TYPES,
} from '../../../redux/network_tags/network_tags.types';
import {
  GlobalTag,
  GLOBAL_TAG_TYPES,
} from '../../../redux/global_tags/global_tags.types';
import { RadioOption } from '../../blocks/radio_group';
import { Publisher } from '../../../redux/user/user.types';
import { GALLERY_FORM_DEFAULTS } from '../../../redux/galleries/gallery_form.selectors';
import { ROUTES } from '../../../types';
import { GalleryCreateEditResponse } from '../../../http/gallery_create.thunks';
import { GALLERY_TYPE } from '../../../redux/galleries/galleries.types';
import { SelectOption } from '../../blocks/select';
import { UseToastProps } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../blocks/toast';

export const extractTagId = (
  options: SelectOption[],
): (string | null | undefined)[] => {
  if (options.length) {
    return options.map((option: SelectOption) => option.value);
  }
  return [];
};

export const transformGalleryFormData = (
  formData: DynamicGalleryFormSubmitData,
  publisherId: string,
): TransformedGalleryFormData => {
  const { galleryName }: { galleryName: string } = formData;
  const globalTags: SelectOption[] = formData?.galleryThemes ?? [];
  const locations: SelectOption[] = formData?.galleryLocations ?? [];
  const campaigns: SelectOption[] = formData?.galleryCampaigns
    ? [formData?.galleryCampaigns]
    : [];
  const personas: SelectOption[] = formData?.galleryPersonas ?? [];
  const networkTags: SelectOption[] = [...campaigns, ...personas];
  const publisherSignals: string[] = formData?.galleryPublisherSignals
    ? [formData.galleryPublisherSignals]
    : [];
  const gallery: TransformedGalleryFormData = {
    label: galleryName,
    publisher_label: publisherId,
    global_tag_ids: extractTagId(globalTags),
    network_tag_ids: extractTagId(networkTags),
    location_ids: extractTagId(locations),
    type: GALLERY_TYPE.DYNAMIC,
  };
  if (
    publisherSignals.length &&
    publisherSignals[0] !== GALLERY_FORM_DEFAULTS.PUBLISHER_SIGNALS
  ) {
    gallery.publisher_signals = publisherSignals;
  }
  return gallery;
};

export const getGalleryFormSignals = (
  formData: DynamicGalleryFormSubmitData,
): GalleryFormSignals => {
  // Sending empty publisherId coz we don't
  // need publisher label, and making it an
  // optional param in transformGalleryFormData function
  // can mess up other places.
  const transformedData = transformGalleryFormData(formData, '');
  const signals: GalleryFormSignals = {
    global_tag_ids: transformedData.global_tag_ids,
    network_tag_ids: transformedData.network_tag_ids,
    location_ids: transformedData.location_ids,
  };
  if (
    transformedData?.publisher_signals?.[0] !==
    GALLERY_FORM_DEFAULTS.PUBLISHER_SIGNALS
  ) {
    signals.publisher_signals = transformedData?.publisher_signals;
  }
  return signals;
};

// This wonderful functions sorts alphabetically with numbers then special characters at the end
export const sortAlphaNumSymbol = (
  a: SelectOption,
  b: SelectOption,
): number => {
  const labelA = a.label;
  const labelB = b.label;

  const aIsLetter = labelA.charAt(0).match(/[a-z]/i);
  const bIsLetter = labelB.charAt(0).match(/[a-z]/i);
  const aIsNumber = labelA.charAt(0).match(/[0-9]/i);
  const bIsNumber = labelB.charAt(0).match(/[0-9]/i);

  // The purpose of this block is to push any characters that do not start with a letter to the bottom of the array
  // The first two lines push the special character cases to the bottom
  if (aIsLetter && !bIsLetter) return -1;
  if (!aIsLetter && bIsLetter) return 1;
  // These two lines push the numbers to the bottom
  if (aIsNumber && !bIsNumber) return -1;
  if (!aIsNumber && bIsNumber) return 1;
  // This is sorting everything else like normal
  return labelA === labelB
    ? 0
    : labelA.localeCompare(labelB, undefined, { numeric: true });
};

export const createOption = (
  tags: Location | NetworkTag | GlobalTag,
): SelectOption => {
  return {
    label: tags.label,
    value: tags.id,
  };
};

export const createTypeOption = (type: string): SelectOption => {
  return {
    label: type,
    value: type,
  };
};

export const createOptions = (
  tags: (Location | NetworkTag | GlobalTag | string)[],
): SelectOption[] => {
  return tags.map((tag) =>
    typeof tag === 'string' ? createTypeOption(tag) : createOption(tag),
  );
};

export const createSortedOptions = (
  tags: (Location | NetworkTag | GlobalTag | string)[],
): SelectOption[] => {
  return createOptions(tags).sort(sortAlphaNumSymbol);
};

export const createFilteredAndSortedOptions = (
  taxonomyData: (GlobalTag | NetworkTag)[],
  type: GLOBAL_TAG_TYPES | NETWORK_TAG_TYPES,
) => {
  return createSortedOptions(
    taxonomyData.filter((tag: GlobalTag | NetworkTag) => tag.type === type),
  );
};

export const createRadioOption = (
  publisher: Publisher,
  translate: (
    value: TranslateValue,
    data?: TranslatePlaceholderData | undefined,
  ) => LocalizedElement | LocalizedElementMap,
): RadioOption => {
  const label =
    publisher.publisherId === GALLERY_FORM_DEFAULTS.PUBLISHER_SIGNALS
      ? translate('galleries.form.publisherSignalAnyLabel')
      : translate('galleries.form.publisherSignalCurrentPublisherLabel', {
          currentPublisher: publisher.label,
        });

  return {
    label: label as string,
    value: publisher.publisherId,
  };
};

export const submitGalleryFormData = async (
  data: DynamicGalleryFormSubmitData,
  setIsSubmitting: Dispatch<SetStateAction<boolean>>,
  showToast: (props: UseToastProps) => void,
  handleGalleryFormSubmit: (
    formData?: DynamicGalleryFormSubmitData | undefined,
  ) => Promise<GalleryCreateEditResponse>,
  translate: TranslateFunction,
  isCreateForm: boolean,
  triggerGetGalleries: () => void,
  navigate: NavigateFunction,
) => {
  setIsSubmitting(true);
  try {
    await handleGalleryFormSubmit(data);
    showToast({
      message: `${translate('galleries.form.createSaveSuccess', {
        type: isCreateForm ? 'created' : 'updated',
      })}`,
      type: TOAST_TYPES.SUCCESS,
    });

    setIsSubmitting(false);
    if (isCreateForm) {
      triggerGetGalleries();
      navigate(ROUTES.GALLERIES_LIST);
    }
  } catch {
    setIsSubmitting(false);
    const copy = isCreateForm ? 'failedCreate' : 'failedSave';
    showToast({
      message: `${translate(`galleries.form.${copy}`)}`,
      type: TOAST_TYPES.ERROR,
    });
  }
};
