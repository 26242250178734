import React, { FunctionComponent } from 'react';
import './utility_menu.scss';

// We already have a Chip component with different styles but
// this temporary component was added because both the chips have different styles
// so design needs to alig on the variants and add the 'Chip' component to design system
// This work is captured in https://crowdriff.atlassian.net/browse/SNET-2503
export const UtilityMenuChip: FunctionComponent = () => {
  return <div className="creator_services__chip">Creator services</div>;
};
