import React, { CSSProperties, FunctionComponent } from 'react';
import clsx from 'clsx';
import { Icon } from '@crowdriff/flock';
import { Translate } from 'react-localize-redux';
import { Avatar } from '../../blocks/avatar/avatar.component';
import './story.scss';
import { AVATAR_SIZES } from '../../blocks/avatar/avatar.types';
import { HOVER_POSITIONS } from '../story_list/story_list.types';
import { STORY_STATUS, STORY_VISIBILITY } from './story.types';

interface StoryProps {
  publisher?: {
    logo: string;
    name: string;
    size?: AVATAR_SIZES;
  };
  storySrc: string;
  title: string;
  status?: STORY_STATUS;
  showTitle?: boolean;
  previewSrc?: string;
  visibility?: STORY_VISIBILITY;
  includeVisibility: boolean;
  onHover?: (mousePlacement: string) => void;
  linkClass?: string;
  textStyles?: CSSProperties;
}
const StoryContent: FunctionComponent<Partial<StoryProps>> = ({
  children,
  publisher,
  storySrc,
  title,
  showTitle = true,
  visibility,
  includeVisibility,
  textStyles,
}) => {
  // Fetch blur thumbnails for hidden stories
  // IF user has access to 'moderation'
  let storyImageUrl = storySrc;
  if (storySrc && includeVisibility && visibility === STORY_VISIBILITY.HIDDEN) {
    const storyURL = new URL(storySrc);
    storyURL.searchParams.append('blur', '50');
    storyImageUrl = storyURL.toString();
  }
  return (
    <React.Fragment>
      <div className="story--overlay">
        <div className="story--header">
          {publisher && (
            <Avatar
              url={publisher.logo}
              alt={publisher.name}
              size={publisher.size}
            />
          )}
          {children}
        </div>
        {showTitle && (
          <div className="story--description">
            <p
              style={textStyles}
              className="story__description_text"
              title={title}
            >
              {title}{' '}
            </p>
          </div>
        )}
      </div>
      {storyImageUrl ? (
        <img className="story--content" src={storyImageUrl ?? ''} alt={title} />
      ) : (
        <Icon size={63} type="photo-rectangle" />
      )}
    </React.Fragment>
  );
};

export const Story: FunctionComponent<Partial<StoryProps>> = (props) => {
  const {
    storySrc,
    title,
    includeVisibility,
    visibility,
    previewSrc,
    onHover,
    linkClass,
  } = props;

  const hiddenStory = visibility === STORY_VISIBILITY.HIDDEN;
  return (
    <div
      className={clsx('story', {
        'story--empty_daft': !storySrc,
        'story--hidden': includeVisibility && hiddenStory,
      })}
      onMouseEnter={() => onHover?.(HOVER_POSITIONS.IN)}
      onMouseLeave={() => onHover?.(HOVER_POSITIONS.OUT)}
    >
      {!previewSrc ? (
        <StoryContent {...props} />
      ) : (
        <Translate>
          {({ translate }) => {
            return (
              <a
                href={previewSrc}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${translate('stories.previewArialabel', {
                  title,
                })}`}
                className={clsx('story__visual_link', linkClass)}
              >
                <StoryContent {...props} />
              </a>
            );
          }}
        </Translate>
      )}
    </div>
  );
};

Story.displayName = 'Story';
