export var colours = {
  neutral0: '#FFFFFF',
  neutral50: '#F1F4F8',
  neutral200: '#e0e4eb',
  neutral400: '#A0A5AF',
  neutral500: '#72757E',
  neutral600: '#4C4F56',
  neutral700: '#2A2B30',
  neutral800: '#121315',

  primary0: '#f5fcff',
  primary50: '#ddf6ff',
  primary100: '#92e0fb',
  primary400: '#07a0d5',
  primary600: '#007ba8',
  primary800: '#004972',
  primary900: '#002C5A',

  positive100: '#ddf8e5',
  positive300: '#79d288',
  positive500: '#1b9648',
  positive600: '#0a662e',
  positive800: '#004520',

  warning100: '#fff1cd',
  warning300: '#f5ae3d',
  warning500: '#e07800',
  warning600: '#B85000',
  warning800: '#8a3200',

  error100: '#ffe6e6',
  error300: '#ff7b7b',
  error500: '#e20328',
  error600: '#b20019',
  error800: '#80000d',

  purple100: '#f1e8ff',
  purple500: '#8749df',
  purple800: '#4c2470'
};