import React, { ReactNode, Fragment } from 'react';

interface AuthWrapperComponentProps {
  hasAccess: boolean;
  children: ReactNode;
}
export const AuthWrapperComponent = ({
  hasAccess,
  children,
}: AuthWrapperComponentProps) => {
  if (!hasAccess) {
    return null;
  }
  return <Fragment>{children}</Fragment>;
};
