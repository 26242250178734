export enum EXPORTS_TYPES {
  EXPORT_PREPARE = 'EXPORTS/PREPARE',
  EXPORT_BINDING = 'EXPORTS/BINDING',
  EXPORT_STORY = 'EXPORTS/STORY',
  EXPORT_RESET = 'EXPORTS/RESET',
  UPDATE_DOWNLOAD_LOCATION = 'EXPORTS/UPDATE_DOWNLOAD_LOCATION',
}

export enum EXPORT_PREPARE_TYPES {
  START = 'EXPORT_PREPARE/START',
  SUCCESS = 'EXPORT_PREPARE/SUCCESS',
  FAILURE = 'EXPORT_PREPARE/FAILURE',
}

export enum EXPORT_STORY_TYPES {
  START = 'EXPORT_STORY/START',
  SUCCESS = 'EXPORT_STORY/SUCCESS',
  FAILURE = 'EXPORT_STORY/FAILURE',
}

export enum EXPORTS_PREPARE_RESPONSE_TYPES {
  START = 'pending',
  SUCCESS = 'success',
  FAILURE = 'error',
}

export enum DOWNLOAD_RESPONSE_TYPES {
  START = 'pending',
  SUCCESS = 'success',
  FAILURE = 'error',
}

export enum DOWNLOAD_ASSETS_BINDING_TYPES {
  INDIVIDUAL = 'individual',
  MERGE = 'merge',
}

export enum DOWNLOAD_LOCATION_TYPES {
  CURRENT_DEVICE = 'current_device',
  EXTERNAL = 'external',
}

export interface ExportsPrepareActionStart {
  type: EXPORT_PREPARE_TYPES.START;
  payload: string;
}

export interface ExportsPrepareActionSuccess {
  type: EXPORT_PREPARE_TYPES.SUCCESS;
  payload: string;
}

export interface ExportsPrepareActionFailure {
  type: EXPORT_PREPARE_TYPES.FAILURE;
}

export interface ExportsStoryActionStart {
  type: EXPORT_STORY_TYPES.START;
  payload: {
    storyUuid: string;
    exportId: string;
    bindType: DOWNLOAD_ASSETS_BINDING_TYPES;
  };
}

export interface ExportsStoryActionSuccess {
  type: EXPORT_STORY_TYPES.SUCCESS;
  payload: ExportsDownload;
}

export interface ExportsStoryActionFailure {
  type: EXPORT_STORY_TYPES.FAILURE;
  payload: string;
}

export interface ExportsDownloadResponse {
  export_id: string;
  status: DOWNLOAD_RESPONSE_TYPES | null;
  page_count: number;
  page_completed: number;
  pages_export_url: (string | null)[] | null;
  story_export_url: string | null;
  story_uuid: string;
}

export interface ExportsDownload {
  exportId: string;
  status: DOWNLOAD_RESPONSE_TYPES | null;
  pageCount: number;
  pageCompleted: number;
  pagesExportUrl: (string | null)[] | null;
  storyExportUrl: string | null;
  storyUuid: string;
}

export interface ExportsDownloadState extends ExportsDownload {
  bindType: DOWNLOAD_ASSETS_BINDING_TYPES | null;
}

export interface ExportsState {
  status: EXPORTS_PREPARE_RESPONSE_TYPES | null;
  type: DOWNLOAD_ASSETS_BINDING_TYPES | null;
  storyId: string | null;
  exportId: string | null;
  download: { [id: string]: ExportsDownloadState };
  location: DOWNLOAD_LOCATION_TYPES;
}

export interface ExportsPrepareOptions {
  storyId: string;
  includeText: boolean;
  includeLocation: boolean;
  includeVisualCredit: boolean;
  isChallengeStory: boolean;
  source: string;
}

export interface ExportsBindingAction {
  type: EXPORTS_TYPES.EXPORT_BINDING;
  payload: boolean;
}

export interface ExportsResetAction {
  type: EXPORTS_TYPES.EXPORT_RESET;
  payload: string;
}

export interface UpdateDownloadLocationAction {
  type: EXPORTS_TYPES.UPDATE_DOWNLOAD_LOCATION;
  payload: string;
}

export type ExportsActions =
  | ExportsBindingAction
  | ExportsPrepareActionStart
  | ExportsPrepareActionSuccess
  | ExportsPrepareActionFailure
  | ExportsStoryActionStart
  | ExportsStoryActionSuccess
  | ExportsStoryActionFailure
  | ExportsResetAction
  | UpdateDownloadLocationAction;
