import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var QuoteLeft = function QuoteLeft(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'quoteLeft__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1181.8 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M545.5,863.6c0,75.3-61.1,136.4-136.4,136.4H136.3C61.1,1000,0,938.9,0,863.6v-500C0,163.4,163.3,0,363.6,0h45.4 c24.8,0,45.5,20.6,45.5,45.5v90.9c0,24.8-20.6,45.5-45.5,45.5h-45.4c-100.2,0-181.8,81.7-181.8,181.8v22.7 c0,37.7,30.5,68.2,68.2,68.2h159.1c75.3,0,136.4,61.1,136.4,136.4V863.6z M1181.8,863.6c0,75.3-61.1,136.4-136.4,136.4H772.7 c-75.3,0-136.3-61.1-136.3-136.4v-500C636.4,163.4,799.7,0,1000,0h45.4c24.8,0,45.5,20.6,45.5,45.5v90.9 c0,24.8-20.6,45.5-45.5,45.5H1000c-100.2,0-181.8,81.7-181.8,181.8v22.7c0,37.7,30.5,68.2,68.2,68.2h159.1 c75.3,0,136.4,61.1,136.4,136.4V863.6z' // eslint-disable-line max-len
    })
  );
};

QuoteLeft.propTypes = propTypes;

export default QuoteLeft;