import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Upload = function Upload(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'upload__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('rect', { y: '875', width: '1000', height: '125' }),
    React.createElement('polygon', { points: '375,625 625,625 625,312.5 812.5,312.5 500,0 187.5,312.5 375,312.5' })
  );
};

Upload.propTypes = propTypes;

export default Upload;