import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Facebook = function Facebook(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Layer_1',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1000 55.3v889.3c0 30.6-24.7 55.3-55.3 55.3H690.1V612.6h129.6l19.5-151H690.1v-96.4c0-43.6 11.7-72.9 74.9-72.9l79.4-.7V156.9c-13.7-1.9-61.2-5.9-115.9-5.9-115.2 0-194.7 70.3-194.7 199.2v111.3H403.6v151h130.2V1000H55.3C24.7 1000 0 975.3 0 944.7V55.3C0 24.7 24.7 0 55.3 0h889.3c30.7 0 55.4 24.7 55.4 55.3z' // eslint-disable-line max-len
    })
  );
};

Facebook.propTypes = propTypes;

export default Facebook;