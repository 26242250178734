import React, { useRef, VoidFunctionComponent } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import './gallery_embed.scss';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
} from '../../blocks/button';
import { generateEmbedCode } from './gallery_embed.utils';
import { copyToClipboard } from '../gallery_list_grid_card/gallery_list_grid_card.utils';
import { AuthWrapper } from '../../blocks/authwrapper/auth_wrapper.connect';
import { FEATURE_FLAGS } from '../../blocks/authwrapper/auth_wrapper.types';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';
import { useToast } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../blocks/toast';

interface GalleryEmbedProps extends LocalizeContextProps {
  galleryId?: string;
}

const GalleryEmbedComponent: VoidFunctionComponent<GalleryEmbedProps> = ({
  galleryId = '',
  translate,
}) => {
  const getTranslation = useTranslateStrings(translate);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { showToast } = useToast();

  const embedCopy = {
    onSuccess: () =>
      showToast({
        message: `${getTranslation('galleries.embed.copied')}`,
        type: TOAST_TYPES.SUCCESS,
      }),
    onFailure: () =>
      showToast({
        message: `${getTranslation('general.copyFailed')}`,
        type: TOAST_TYPES.ERROR,
      }),
  };

  const copyGalleryId = {
    onSuccess: () =>
      showToast({
        message: `${getTranslation('galleries.embed.copiedId')}`,
        type: TOAST_TYPES.SUCCESS,
      }),
    onFailure: () =>
      showToast({
        message: `${getTranslation('galleries.embed.error')}`,
        type: TOAST_TYPES.ERROR,
      }),
  };

  const embedCode = generateEmbedCode(galleryId);
  return (
    <div className="gallery_embed">
      <h5 className="gallery_embed__title">
        {getTranslation('galleries.embed.title')}
      </h5>
      <p className="gallery_embed__description">
        {getTranslation('galleries.embed.description')}
        <AuthWrapper featureFlags={FEATURE_FLAGS.COPY_GALLERY_ID}>
          <span>{getTranslation('galleries.embed.descriptionVerbose')}</span>
        </AuthWrapper>
      </p>
      <div className="gallery_embed__buttons">
        <Button
          onClick={() => {
            if (textAreaRef && textAreaRef.current) {
              textAreaRef.current.select();
              copyToClipboard(null, embedCopy.onSuccess, embedCopy.onFailure);
            }
          }}
          variant={BUTTON_VARIANT.OUTLINE}
          color={BUTTON_COLOR_VARIANT.SECONDARY}
        >
          <Translate id="galleries.embed.copy" />
        </Button>
        <AuthWrapper featureFlags={FEATURE_FLAGS.COPY_GALLERY_ID}>
          <Button
            onClick={() =>
              copyToClipboard(
                galleryId,
                copyGalleryId.onSuccess,
                copyGalleryId.onFailure,
              )
            }
            variant={BUTTON_VARIANT.OUTLINE}
            color={BUTTON_COLOR_VARIANT.SECONDARY}
            className="gallery_embed__buttons--copy_id"
          >
            {translate('galleries.embed.copyId')}
          </Button>
        </AuthWrapper>
      </div>
      <textarea
        className="gallery_embed__code-area"
        aria-label={`${translate('galleries.embed.ariaLabel')}`}
        ref={textAreaRef}
        readOnly={true}
        rows={26}
        value={embedCode}
      />
    </div>
  );
};

export const GalleryEmbed = withLocalize(GalleryEmbedComponent);
