import { AvatarUploadReqProps } from './account_settings.types';

const validateImage = (
  imageDetails: AvatarUploadReqProps,
  req: AvatarUploadReqProps,
) => {
  const heightCheck = imageDetails.height <= req.height;
  const widthCheck = imageDetails.width <= req.width;
  const sizeCheck = imageDetails.sizeBytes <= req.sizeBytes;
  if (heightCheck && widthCheck && sizeCheck) {
    return true;
  }
  return false;
};

export const isUploadedImageValid = (
  uploadedFile: File,
  req: AvatarUploadReqProps,
) => {
  return new Promise<boolean>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(uploadedFile);
    fileReader.onload = (e) => {
      const mockImage = new Image();
      mockImage.src = e.target?.result as string;
      mockImage.onload = () =>
        resolve(
          validateImage(
            {
              height: mockImage.naturalHeight,
              width: mockImage.naturalWidth,
              sizeBytes: uploadedFile.size,
            },
            req,
          ),
        );
      mockImage.onerror = () => reject(new Error('upload error'));
    };
  });
};
