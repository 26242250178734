import { createSelector } from 'reselect';
import {
  LSE_OWNER_MAX_SELECTABLE_STORIES,
  MAX_NUMBER_SELECTED_STORIES,
} from '../../react/features/story_list/selectable/story_list_selectable.constants';
import { AppState } from '../../types';
import { getStoriesState } from '../stories/stories.selectors';
import { selectIsLocalhoodOwner } from '../user/user.selectors';

export const getGalleriesState = (state: AppState) => state.galleries;
export const selectAllGalleries = createSelector(
  getGalleriesState,
  (galleriesState) => galleriesState.data,
);

export const selectGalleriesToEdit = createSelector(
  getGalleriesState,
  (galleriesState) => galleriesState.galleryToEdit,
);

export const selectSearchedGalleries = createSelector(
  getGalleriesState,
  (galleriesState) => galleriesState.searchResults,
);

export const selectGetGalleryError = createSelector(
  getGalleriesState,
  (galleriesState) => galleriesState.error,
);

// MANUAL GALLERY CREATION SELECTORS

export const selectCurrentGalleryCreationStep = createSelector(
  getGalleriesState,
  (galleriesState) => galleriesState.staticGallery.activeStepIndex,
);

export const selectUnhydratedGalleryLabel = createSelector(
  getGalleriesState,
  (galleriesState) => galleriesState.staticGallery.gallery.label,
);

export const selectCurrentGalleryStories = createSelector(
  getGalleriesState,
  (galleriesState) => galleriesState.staticGallery.gallery.story_ids,
);

export const selectStaticGallery = createSelector(
  getGalleriesState,
  (galleriesState) => galleriesState.staticGallery.gallery,
);

export const selectStaticGallerySelectedStories = createSelector(
  getGalleriesState,
  (galleriesState) => galleriesState.staticGallery.selectedStories,
);

export const selectStaticGalleryLabelForCreate = createSelector(
  getGalleriesState,
  (galleriesState) => {
    return galleriesState.staticGallery.gallery.label;
  },
);

export const selectStaticGalleryLabelForEdit = createSelector(
  getGalleriesState,
  (galleriesState) => {
    return (
      galleriesState.staticGallery.gallery.label ??
      galleriesState.galleryToEdit?.label
    );
  },
);

export const selectStaticGalleryStoriesForEdit = createSelector(
  getGalleriesState,
  (galleriesState) => {
    return (
      galleriesState.staticGallery.selectedStories ??
      galleriesState.galleryToEdit?.stories
    );
  },
);

export const selectStaticGalleryNumberOfStories = createSelector(
  getStoriesState,
  (storiesState) => {
    return storiesState.data.length;
  },
);

export const selectStaticGalleryHasSearchQuery = createSelector(
  getStoriesState,
  (storiesState) => {
    return Boolean(storiesState.searchQuery);
  },
);

export const selectManualGalleryMaxSelectableStories = createSelector(
  selectIsLocalhoodOwner,
  (isLocalhoodOwner) => {
    return isLocalhoodOwner
      ? LSE_OWNER_MAX_SELECTABLE_STORIES
      : MAX_NUMBER_SELECTED_STORIES;
  },
);
