import { AppActions } from '../../types';
import {
  GLOBAL_TAG_ASYNC_TYPES,
  GlobalTagState,
  GlobalTag,
} from './global_tags.types';

const initialState: Readonly<GlobalTagState> = {
  data: [],
  ids: [],
};
export const globalTagsReducer = (
  state: Readonly<GlobalTagState> = initialState,
  action: AppActions,
): GlobalTagState => {
  switch (action.type) {
    case GLOBAL_TAG_ASYNC_TYPES.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        ids: action.payload.map((globalTag: GlobalTag) => globalTag.id),
      };
    }
    default: {
      return state;
    }
  }
};
