import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Link = function Link(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'link',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M947.9,859.7l-90.1,89.5c-33.1,33.1-77.8,50.9-124.4,50.9c-47.2,0-91.9-18.4-125-52.1L482.2,821.1 c-33.1-33.1-50.9-77.8-50.9-124.4c0-48.4,19.6-94.4,53.9-128.1l-53.9-53.9c-33.7,34.3-79,53.9-127.4,53.9 c-46.6,0-91.9-18.4-125-51.5L51.5,389.7C17.8,356,0,311.9,0,264.7c0-46.6,19-91.9,52.1-124.4l90.1-89.5C175.2,17.8,220,0,266.5,0 c47.2,0,91.9,18.4,125,52.1l126.2,126.8c33.1,33.1,50.9,77.8,50.9,124.4c0,48.4-19.6,94.4-53.9,128.1l53.9,53.9 c33.7-34.3,79-53.9,127.4-53.9c46.6,0,91.9,18.4,125,51.5l127.4,127.4c33.7,33.7,51.5,77.8,51.5,125 C1000,781.9,981,827.2,947.9,859.7z M434.4,261.6L308.2,134.8c-11-11-26.3-17.2-41.7-17.2c-15.3,0-30.6,6.1-41.7,16.5l-90.1,89.5 c-11,11-17.2,25.7-17.2,41c0,15.9,6.1,30.6,17.2,41.7l127.4,127.4c11,11,26.3,16.5,41.7,16.5c17.8,0,31.9-6.1,44.1-19 c-20.2-20.2-44.1-37.4-44.1-68.6c0-32.5,26.3-58.8,58.8-58.8c31.2,0,48.4,23.9,68.6,44.1c12.9-12.3,20.2-26.3,20.2-44.7 C451.6,288,445.5,272.7,434.4,261.6z M865.2,693.6L737.7,566.2c-11-11-26.3-17.2-41.7-17.2c-17.8,0-31.9,6.7-44.1,19.6 c20.2,20.2,44.1,37.4,44.1,68.6c0,32.5-26.3,58.8-58.8,58.8c-31.2,0-48.4-23.9-68.6-44.1c-12.9,12.3-20.2,26.3-20.2,44.7 c0,15.3,6.1,30.6,17.2,41.7l126.2,126.8c11,11,26.3,16.5,41.7,16.5c15.3,0,30.6-5.5,41.7-15.9l90.1-89.5c11-11,17.2-25.7,17.2-41 C882.4,719.4,876.2,704.7,865.2,693.6z' // eslint-disable-line max-len
    })
  );
};

Link.propTypes = propTypes;

export default Link;