import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var OpenNewWindow = function OpenNewWindow(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'openNewWindow__icon',
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M21 3H15M21 3L12 12M21 3V9',
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    }),
    React.createElement('path', {
      d: 'M19 13.8889V19.2222C19 20.2041 18.2041 21 17.2222 21H4.77778C3.79594 21 3 20.2041 3 19.2222V6.77778C3 5.79594 3.79594 5 4.77778 5H10.1111',
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round'
    })
  );
};

OpenNewWindow.propTypes = propTypes;

export default OpenNewWindow;