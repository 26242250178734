import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { networkMap } from './avatarIcon-map';
import { Icon } from '../Icon';

var propDescriptions = {
  className: 'A string of any additional classes to be added to the button',
  displayName: 'A string of the username associated with the avatar',
  isCompleted: 'shows a checkmark over the component',
  isSelected: 'When active will highlight the icon with a pink circle',
  network: 'The social network icon to be shown in miniature in the corner',
  onClick: 'an optional function to be fired on icon click',
  showDisplayName: 'show the full name or collapse to just the icon',
  size: 'A number declaring the radius of the avatar in pixels (default is 32px)',
  src: 'the url source of the avatar to be displayed',
  byline: 'A second line of text to display under the username'
};

var propTypes = {
  className: PropTypes.string,
  displayName: PropTypes.string,
  isCompleted: PropTypes.bool,
  isSelected: PropTypes.bool,
  network: PropTypes.string,
  onClick: PropTypes.func,
  showDisplayName: PropTypes.bool,
  size: PropTypes.number,
  src: PropTypes.string,
  byline: PropTypes.string
};

var defaultProps = {
  network: 'none',
  size: 32
};

var getFallbackInitials = function getFallbackInitials(label) {
  if (!label) return '';
  var words = label.split(' ');
  var firstTwoWords = words.slice(0, 2);
  var initialsArray = firstTwoWords.map(function (w) {
    return w.substring(0, 1);
  });
  return initialsArray.join('');
};

var AvatarIcon = function (_Component) {
  _inherits(AvatarIcon, _Component);

  function AvatarIcon(props) {
    _classCallCheck(this, AvatarIcon);

    var _this = _possibleConstructorReturn(this, (AvatarIcon.__proto__ || _Object$getPrototypeOf(AvatarIcon)).call(this, props));

    _this.onError = function () {
      var src = _this.props.src;

      if (process.env.NODE_ENV === 'development') {
        console.warn('Invalid flock AvatarIcon src: ' + src);
      }
      _this.setState({
        avatarError: true
      });
    };

    _this.handleClick = function (e) {
      var onClick = _this.props.onClick;

      if (typeof onClick !== 'function') return;
      onClick(e);
    };

    _this.renderAvatar = function () {
      var _this$props = _this.props,
          src = _this$props.src,
          displayName = _this$props.displayName,
          size = _this$props.size,
          network = _this$props.network;

      var networkKey = networkMap[network];
      var avatarError = _this.state.avatarError;

      if (src && displayName && !avatarError) {
        return React.createElement('img', {
          src: src,
          alt: displayName + '\'s avatar',
          onError: _this.onError,
          className: 'cr__avatar__image',
          style: {
            width: size,
            height: size
          }
        });
      }
      if (!src && displayName || avatarError && displayName) {
        return React.createElement(
          'div',
          {
            className: classNames('cr__avatar__fallback-initials', networkKey),
            style: {
              width: size,
              height: size
            }
          },
          getFallbackInitials(displayName)
        );
      }
      return React.createElement(Icon, { type: 'person-cutout', size: size, color: networkKey !== 'none' ? networkKey : 'grey' });
    };

    _this.state = {
      avatarError: false
    };
    return _this;
  }

  _createClass(AvatarIcon, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          displayName = _props.displayName,
          isCompleted = _props.isCompleted,
          isSelected = _props.isSelected,
          onClick = _props.onClick,
          network = _props.network,
          showDisplayName = _props.showDisplayName,
          size = _props.size,
          byline = _props.byline;

      var avatarWrapperClasses = classNames('cr__avatar__wrapper', className, {
        selected: isSelected,
        selectable: onClick
      });
      if (process.env.NODE_ENV === 'development' && !networkMap[network]) {
        console.warn('Invalid flock AvatarIcon network: ' + network);
        return null;
      }
      var networkKey = networkMap[network];
      return React.createElement(
        'div',
        { className: 'cr__avatar' },
        React.createElement(
          'button',
          { className: avatarWrapperClasses, onClick: this.handleClick },
          this.renderAvatar(),
          networkKey !== 'none' && React.createElement(
            'div',
            { className: 'cr__avatar__network' },
            React.createElement(Icon, {
              className: 'cr_avatar__networkIcon',
              type: networkKey,
              color: networkKey,
              size: 12
            })
          ),
          isCompleted && React.createElement(
            'div',
            {
              className: 'cr__avatar__complete',
              style: {
                width: size,
                height: size
              }
            },
            React.createElement(Icon, { type: 'check', color: 'success-green', size: 12 })
          )
        ),
        showDisplayName && displayName && React.createElement(
          'div',
          { className: 'cr__avatar__info' },
          React.createElement(
            'div',
            { className: 'cr__avatar__displayName' },
            displayName
          ),
          byline && React.createElement(
            'div',
            { className: 'cr__avatar__byline' },
            byline
          )
        )
      );
    }
  }]);

  return AvatarIcon;
}(Component);

export default AvatarIcon;


AvatarIcon.propDescriptions = propDescriptions;
AvatarIcon.propTypes = propTypes;
AvatarIcon.defaultProps = defaultProps;