import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.number,
  iconStyle: PropTypes.string,
  fillColor: PropTypes.string
};
/* eslint-disable max-len */
var Plane = function Plane(_ref) {
  var size = _ref.size,
      iconStyle = _ref.iconStyle,
      fillColor = _ref.fillColor;

  return React.createElement(
    'svg',
    {
      version: '1.1',
      xmlns: 'http://www.w3.org/2000/svg',
      x: '0px',
      y: '0px',
      viewBox: '0 0 124 124',
      style: { enableBackground: 'new 0 0 124 124' },
      height: size,
      fill: iconStyle !== 'outline-white' ? '#2A2F35' : '#FFF'
    },
    React.createElement(
      'g',
      null,
      iconStyle === 'fill' ? React.createElement(
        'g',
        { fillRule: 'evenodd', clipRule: 'evenodd', fill: fillColor },
        React.createElement('path', { d: 'M100.3,61.6c-0.1,0-0.2-0.1-0.3-0.2l0-4.6c0-0.2,0.1-0.3,0.3-0.3c0,0,10.2,0.4,12.6,0.1l1.4-0.1 c0.8-0.1,1.6,0.3,2,1.1c0.3,0.6,0.7,1.4,0.9,2.4c0.3,1.2-0.6,2.4-1.9,2.4L100.3,61.6z'
        }),
        React.createElement('path', { d: 'M91,61.6h-3c-0.6,0-1-0.4-1-1v-3c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v3C92,61.2,91.6,61.6,91,61.6z' }),
        React.createElement('path', { d: 'M80,61.6h-3c-0.6,0-1-0.4-1-1v-3c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v3C81,61.2,80.6,61.6,80,61.6z' }),
        React.createElement('path', { d: 'M69,61.6h-3c-0.6,0-1-0.4-1-1v-3c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v3C70,61.2,69.5,61.6,69,61.6z' }),
        React.createElement('path', { d: 'M85.1,40.6h-5.3l4.4,6h0.9c1.1,0,2-0.9,2-2v-2C87.1,41.5,86.2,40.6,85.1,40.6z' }),
        React.createElement('path', { d: 'M85.1,77.4h-0.9l-4.4,6h5.4c1.1,0,2-0.9,2-2v-2C87.1,78.3,86.2,77.4,85.1,77.4z' }),
        React.createElement('path', { d: 'M0.1,35.4c-0.3-0.6,0.1-1.3,0.8-1.3h12.3c0.7,0,1.4,0.4,1.8,1.1l7.6,14.3c0.3,0.7,1,1.1,1.8,1.1H7.8L0.1,35.4 z'
        })
      ) : null,
      React.createElement(
        'g',
        null,
        React.createElement('path', { d: 'M120.5,62.2c-0.2-0.2-0.3-0.5-0.4-0.8c-0.2-2.7-1.9-11.6-17.5-11.6H89.5l-1.8-2.5c1.3-0.3,2.2-1.4,2.2-2.7v-2 c0-1.5-1.2-2.8-2.8-2.8h-4.9L63,13.9c-0.3-0.4-0.8-0.7-1.3-0.7h-7.2c-0.6,0-1.1,0.3-1.4,0.7c-0.3,0.5-0.4,1-0.1,1.5l13.5,34.3 l-40,0v0c-0.5,0-0.9-0.3-1.1-0.7l-7.6-14.3c-0.5-0.9-1.4-1.5-2.4-1.5H2.9c-0.6,0-1.1,0.3-1.4,0.8c-0.3,0.5-0.3,1.1-0.1,1.6 l18.1,35.4c0.5,1,1.4,1.6,2.5,1.6c0,0,0,0,0,0l45.4,0l-14.5,35.9c-0.2,0.5-0.2,1.1,0.1,1.6c0.3,0.5,0.8,0.7,1.4,0.7h7.2 c0.5,0,1-0.2,1.3-0.7l19.1-26h5c1.5,0,2.8-1.2,2.8-2.8v-2c0-1.3-1-2.5-2.3-2.7l3-4.1l21.9,0c5.3,0,8.8-1.4,9.9-3.9 C123.2,66.8,122.6,64.5,120.5,62.2z M2.8,34.9c0,0,0.1-0.1,0.1-0.1h12.3c0.5,0,0.9,0.3,1.1,0.7l7.6,14.3H10.2L2.8,35 C2.7,35,2.8,34.9,2.8,34.9z M118.2,60.9c-0.2,0.3-0.6,0.5-0.9,0.5l-14.5-0.6l0-3.7c2.1,0.1,10,0.4,12.2,0.1l1.4-0.1 c0.5-0.1,1,0.2,1.2,0.6c0,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.3,0.6,0.4,0.9c0,0.1,0,0.1,0.1,0.2c0.1,0.3,0.2,0.6,0.3,0.9 c0,0.1,0,0.2,0.1,0.2C118.5,60.3,118.4,60.7,118.2,60.9z M87.1,41.4c0.7,0,1.2,0.6,1.2,1.2v2c0,0.7-0.6,1.2-1.2,1.2h-0.5l-3.3-4.5 H87.1z M54.3,15c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1h7.2c0,0,0.1,0,0.1,0.1l25.8,35l-19.6,0L54.3,15z M61.8,109.2 c0,0-0.1,0.1-0.1,0.1h-7.2c-0.1,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.2l16-39.6c0.2-0.5,0.6-0.8,1.2-0.8h17.7c0.5,0,0.9,0.3,1.1,0.7 c0.2,0.4,0.2,0.9-0.1,1.3L61.8,109.2z M87.1,78.1c0.7,0,1.2,0.6,1.2,1.2v2c0,0.7-0.6,1.2-1.2,1.2h-3.9l3.3-4.5H87.1z M121,68.1 c-0.8,1.9-4,3-8.5,3l-20.8,0c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2,0-0.2c0-0.2,0-0.3,0-0.5 c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.5-0.2-0.7c-0.4-0.7-1-1.2-1.7-1.4c-0.2-0.1-0.5-0.1-0.8-0.1H71.5c-1.1,0-2.1,0.7-2.6,1.7L68,71.1 l-46,0c0,0,0,0,0,0c-0.5,0-0.9-0.3-1.1-0.7L11,51.3l91.6,0c7.3,0,11.2,2,13.4,4.3l-1.3,0.1c-2.3,0.3-12.4-0.1-12.5-0.1 c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.7l0,4.7c0.1,0.5,0.5,0.9,1,0.9l15.1,0.6c0.6,0,1.1-0.2,1.5-0.6 c0.1,0.3,0.3,0.6,0.5,0.8C121,65,121.6,66.8,121,68.1z'
        }),
        React.createElement('path', { d: 'M93,55.9h-3c-1,0-1.8,0.8-1.8,1.8v3c0,1,0.8,1.8,1.8,1.8h3c1,0,1.8-0.8,1.8-1.8v-3C94.8,56.6,94,55.9,93,55.9 z M93.3,60.6c0,0.1-0.1,0.2-0.2,0.2h-3c-0.1,0-0.2-0.1-0.2-0.2v-3c0-0.1,0.1-0.2,0.2-0.2h3c0.1,0,0.2,0.1,0.2,0.2V60.6z'
        }),
        React.createElement('path', { d: 'M82,55.9h-3c-1,0-1.8,0.8-1.8,1.8v3c0,1,0.8,1.8,1.8,1.8h3c1,0,1.8-0.8,1.8-1.8v-3C83.8,56.6,83,55.9,82,55.9 z M82.3,60.6c0,0.1-0.1,0.2-0.2,0.2h-3c-0.1,0-0.2-0.1-0.2-0.2v-3c0-0.1,0.1-0.2,0.2-0.2h3c0.1,0,0.2,0.1,0.2,0.2V60.6z'
        }),
        React.createElement('path', { d: 'M71,55.9h-3c-1,0-1.8,0.8-1.8,1.8v3c0,1,0.8,1.8,1.8,1.8h3c1,0,1.8-0.8,1.8-1.8v-3C72.7,56.6,72,55.9,71,55.9 z M71.2,60.6c0,0.1-0.1,0.2-0.2,0.2h-3c-0.1,0-0.2-0.1-0.2-0.2v-3c0-0.1,0.1-0.2,0.2-0.2h3c0.1,0,0.2,0.1,0.2,0.2V60.6z'
        }),
        React.createElement('path', { d: 'M56.8,87.9c0-0.4-0.3-0.8-0.8-0.8h-8c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h8 C56.4,88.7,56.8,88.3,56.8,87.9z'
        }),
        React.createElement('path', { d: 'M42,87.2H26c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h16c0.4,0,0.8-0.3,0.8-0.8S42.4,87.2,42,87.2z' }),
        React.createElement('path', { d: 'M26.8,81.9c0-0.4-0.3-0.8-0.8-0.8h-4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h4 C26.4,82.7,26.8,82.3,26.8,81.9z'
        }),
        React.createElement('path', { d: 'M58,82.7c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H32c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8H58z' })
      )
    )
  );
};

Plane.propTypes = propTypes;

export default Plane;