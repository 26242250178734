import { useCallback, useRef } from 'react';

export const useDebounce = (method: Function, wait: number) => {
  const timeoutInstance = useRef<ReturnType<typeof setTimeout> | null>(null);
  return useCallback(
    (...args: unknown[]) => {
      if (timeoutInstance.current) {
        clearTimeout(timeoutInstance.current);
      }
      timeoutInstance.current = setTimeout(() => {
        method(...args);
      }, wait);
    },
    [timeoutInstance, wait, method],
  );
};
