import React from 'react';
import { IconProps } from '../icon.types';

export const PauseFilled = ({
  className,
  size,
  color = 'black',
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18.4V5.6C6 5.44087 6.06321 5.28826 6.17574 5.17574C6.28826 5.06321 6.44087 5 6.6 5H9.4C9.55913 5 9.71174 5.06321 9.82426 5.17574C9.93679 5.28826 10 5.44087 10 5.6V18.4C10 18.5591 9.93679 18.7117 9.82426 18.8243C9.71174 18.9368 9.55913 19 9.4 19H6.6C6.44087 19 6.28826 18.9368 6.17574 18.8243C6.06321 18.7117 6 18.5591 6 18.4V18.4ZM14 18.4V5.6C14 5.44087 14.0632 5.28826 14.1757 5.17574C14.2883 5.06321 14.4409 5 14.6 5H17.4C17.5591 5 17.7117 5.06321 17.8243 5.17574C17.9368 5.28826 18 5.44087 18 5.6V18.4C18 18.5591 17.9368 18.7117 17.8243 18.8243C17.7117 18.9368 17.5591 19 17.4 19H14.6C14.4409 19 14.2883 18.9368 14.1757 18.8243C14.0632 18.7117 14 18.5591 14 18.4V18.4Z"
        fill={color}
      />
    </svg>
  );
};
