import React from 'react';
import PropTypes from 'prop-types';

import CardComponent from '../Card';

var propDescriptions = {
  cardsPerRow: 'The number of cards to show on a row',
  children: 'Card components to be displayed in the row'
};

var propTypes = {
  cardsPerRow: PropTypes.number,
  children: PropTypes.node
};

var defaultProps = {
  cardsPerRow: 2
};

var Cards = function Cards(props) {
  var cardsArray = React.Children.toArray(props.children);
  var cardsNormalized = cardsArray.filter(function (child) {
    return child.type === CardComponent && child;
  });
  var cardsPerRow = props.cardsPerRow > 0 ? props.cardsPerRow : defaultProps.cardsPerRow;
  var cardRows = [];

  for (var i = 0; i < cardsNormalized.length; i += cardsPerRow) {
    cardRows.push(React.createElement(
      'div',
      { className: 'cr__cards__row', key: i },
      cardsNormalized.slice(i, i + cardsPerRow)
    ));
  }

  return React.createElement(
    'div',
    { className: 'cr__cards' },
    cardRows
  );
};

Cards.propTypes = propTypes;
Cards.defaultProps = defaultProps;
Cards.propDescriptions = propDescriptions;

export default Cards;