import React, { ReactNode } from 'react';
import { LINK_VARIANTS } from './link.types';
import './link.scss';

export interface LinkProps {
  variant: LINK_VARIANTS;
  className?: string;
  href: string;
  target?: string;
  rel?: string;
  linkContent: ReactNode;
}

export const Link: React.FunctionComponent<LinkProps> = ({
  variant,
  className = '',
  href,
  target,
  rel,
  linkContent,
}) => {
  return (
    <a
      href={href}
      className={`link link-${variant} ${className}`}
      target={target}
      rel={rel ?? ''}
    >
      {linkContent}
    </a>
  );
};
