import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var MediaReels = function MediaReels(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg'
    },
    React.createElement('path', {
      d: 'M10.7023 11.0672C10.3894 10.875 10 11.1179 10 11.5054V16.4946C10 16.8821 10.3894 17.125 10.7023 16.9327L14.7635 14.4381C15.0788 14.2444 15.0788 13.7556 14.7635 13.5619L10.7023 11.0672Z',
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    }),
    React.createElement('line', {
      x1: '3',
      y1: '7.25',
      x2: '21',
      y2: '7.25',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('line', {
      x1: '13.0284',
      y1: '2.72247',
      x2: '16.3552',
      y2: '7.47355',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('line', {
      x1: '7.31456',
      y1: '3.0617',
      x2: '10.4692',
      y2: '7.56704',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M3 15V9C3 5.68629 5.41766 3 8.4 3H15.6C18.5823 3 21 5.68629 21 9V15C21 18.3137 18.5823 21 15.6 21H8.4C5.41766 21 3 18.3137 3 15Z',
      stroke: color,
      strokeWidth: '1.5'
    })
  );
};

MediaReels.propTypes = propTypes;

export default MediaReels;