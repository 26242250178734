import React from 'react';
import QRCodeComponent from 'qrcode.react';
import { Translate, withLocalize } from 'react-localize-redux';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { ModalButtons, ModalContent, ModalTitle } from '../../../blocks/modal';
import {
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
  Button,
} from '../../../blocks/button';
import { DownloadToMobileModalProps } from '.';
import './download_to_mobile_modal.scss';
import { TEXT_VARIANTS, Text } from '../../../blocks/text';
import { ICON_NAMES, ICON_SIZES, Icon } from '../../../blocks/icon';

export const DownloadToMobileModalComponent = ({
  translate,
  Modal,
  onClose,
  exportId,
}: DownloadToMobileModalProps) => {
  const getTranslation = useTranslateStrings(translate);

  return (
    <Modal className="qr_code_modal">
      <ModalTitle label={<Translate id="stories.modal.storyExport.title" />} />
      <ModalContent>
        <>
          <Text
            variant={TEXT_VARIANTS.B1}
            className="qr_code_modal__description"
          >
            {getTranslation('stories.modal.storyExportQRCode.description')}
          </Text>
          <div className="qr_code_modal__content-wrapper">
            {exportId && (
              <QRCodeComponent
                value={`${process.env.EXPORT_URL}/${exportId}`}
                size={130}
              />
            )}
            <div className="qr_code_modal__content-wrapper__content-text-wrapper">
              <div className="qr_code_modal__content-wrapper__content-text">
                <Icon
                  iconKey={ICON_NAMES.CAMERA}
                  size={ICON_SIZES.LARGE}
                  color="#007BA8"
                  ariaHidden={true}
                />
                <Text variant={TEXT_VARIANTS.B2}>
                  Open your camera or QR scanner
                </Text>
              </div>
              <div className="qr_code_modal__content-wrapper__content-text">
                <Icon
                  iconKey={ICON_NAMES.QR_CODE_SCAN}
                  size={ICON_SIZES.LARGE}
                  color="#007BA8"
                  ariaHidden={true}
                />
                <Text variant={TEXT_VARIANTS.B2}>
                  Point your device at the code
                </Text>
              </div>
              <div className="qr_code_modal__content-wrapper__content-text">
                <Icon
                  iconKey={ICON_NAMES.HAND_CURSOR}
                  size={ICON_SIZES.LARGE}
                  color="#007BA8"
                  ariaHidden={true}
                />
                <Text variant={TEXT_VARIANTS.B2}>
                  Follow the link on your device
                </Text>
              </div>
            </div>
          </div>
        </>
      </ModalContent>

      <ModalButtons>
        <Button
          variant={BUTTON_VARIANT.OUTLINE}
          color={BUTTON_COLOR_VARIANT.SECONDARY}
          onClick={onClose}
        >
          <Translate id="general.buttons.close" />
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export const DownloadToMobileModal = withLocalize(
  DownloadToMobileModalComponent,
);
