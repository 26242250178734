import React from 'react';
import { IconProps } from '../icon.types';

export const ChevronRight = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.2974 10.0068C14.2974 9.79932 14.2144 9.6084 14.0566 9.45898L7.48242 3.01758C7.33301 2.87646 7.15039 2.80176 6.93457 2.80176C6.51123 2.80176 6.1792 3.12549 6.1792 3.55713C6.1792 3.76465 6.26221 3.95557 6.39502 4.09668L12.438 10.0068L6.39502 15.917C6.26221 16.0581 6.1792 16.2407 6.1792 16.4565C6.1792 16.8882 6.51123 17.2119 6.93457 17.2119C7.15039 17.2119 7.33301 17.1372 7.48242 16.9878L14.0566 10.5547C14.2144 10.397 14.2974 10.2144 14.2974 10.0068Z" />
    </svg>
  );
};
