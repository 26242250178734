import { renderToStaticMarkup } from 'react-dom/server';
import { onMissingTranslationFunction } from 'react-localize-redux';
import { LANGUAGE_CODE, supportedLanguages } from './copy.types';
import { translations } from './languages/en/translations';

const onMissingTranslation: onMissingTranslationFunction = ({
  translationId,
  languageCode,
}) => {
  return `missing translation key: ${translationId} for ${languageCode}`;
};

export const initializePayload = {
  languages: supportedLanguages,
  translation: translations,
  options: {
    defaultLanguage: LANGUAGE_CODE.ENGLISH,
    renderToStaticMarkup,
    renderInnerHtml: true,
    ignoreTranslateChildren: true,
    onMissingTranslation,
  },
};

export const getLanguage = (
  navigator: Navigator | NavigatorLanguage,
): string => {
  if (!navigator) {
    return LANGUAGE_CODE.ENGLISH;
  }
  const supportedLanguagesCodes: string[] = Object.values(LANGUAGE_CODE);
  const browserLanguagesCodes = navigator.languages;
  const supportedLanguage = (lang: string): string | undefined =>
    supportedLanguagesCodes.find((browserLang) => browserLang === lang);
  const languageCode =
    browserLanguagesCodes.find(supportedLanguage) ?? LANGUAGE_CODE.ENGLISH;

  return languageCode;
};
