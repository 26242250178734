import React from 'react';
import { IconProps } from '../icon.types';

export const InfoCircleFilled = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 18.4736C14.6318 18.4736 18.4668 14.6304 18.4668 10.0068C18.4668 5.375 14.6235 1.54004 9.9917 1.54004C5.36816 1.54004 1.5332 5.375 1.5332 10.0068C1.5332 14.6304 5.37646 18.4736 10 18.4736ZM9.92529 6.97705C9.30273 6.97705 8.80469 6.479 8.80469 5.84814C8.80469 5.21729 9.30273 4.71924 9.92529 4.71924C10.5479 4.71924 11.0376 5.21729 11.0376 5.84814C11.0376 6.479 10.5479 6.97705 9.92529 6.97705ZM8.50586 14.73C8.14893 14.73 7.875 14.4727 7.875 14.1157C7.875 13.7837 8.14893 13.5098 8.50586 13.5098H9.56006V9.6084H8.64697C8.29834 9.6084 8.02441 9.35107 8.02441 8.99414C8.02441 8.66211 8.29834 8.38818 8.64697 8.38818H10.2573C10.6973 8.38818 10.9297 8.70361 10.9297 9.16846V13.5098H11.9839C12.3408 13.5098 12.6147 13.7837 12.6147 14.1157C12.6147 14.4727 12.3408 14.73 11.9839 14.73H8.50586Z" />
    </svg>
  );
};
