{
    "modal":{
        "title":"Localhood Terms of Service",
        "description":{
            "line1":"Please accept our Terms of Service to continue to Localhood.",
            "line2":"You can read the full terms at ",
            "urlLink": "localhood.com/terms",
            "line3":"Here's a short summary:"
        },
        "terms":{
            "1": "You agree that all content you publish to Localhood includes either your original assets or assets you have permission to use",
            "2": "You acknowledge that you are responsible for content you publish to Localhood",
            "3": "You agree that it is your responsibility to evaluate any risks involved in using Localhood"
        },
        "links":{
            "readFullTerms":"Read full Terms of Service"
        },
        "checkbox":{
            "agree": "I agree to the Localhood Terms of Service",
            "error": "You must agree to the Localhood Terms of Service to continue"
        },
        "buttons": {
            "submit": "Submit Agreement",
            "logout":"Log out"
        },
        "errors":{
            "mustAgree": "You must agree to the Localhood Terms of Service to continue",
            "500snackbar": "Terms of Service acceptance couldn’t be set. Please try again."
        }
    }
}
