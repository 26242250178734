import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '..';

var propTypes = {
  playPauseVideo: PropTypes.func.isRequired,
  setTime: PropTypes.func.isRequired,
  muteButtonClick: PropTypes.func.isRequired,
  fullscreenButtonClick: PropTypes.func.isRequired,
  videoStates: PropTypes.object.isRequired,
  clientWidth: PropTypes.number
};
export default function VideoControls(props) {
  var _props$videoStates = props.videoStates,
      isPlaying = _props$videoStates.isPlaying,
      isMuted = _props$videoStates.isMuted,
      progress = _props$videoStates.progress,
      duration = _props$videoStates.duration,
      currentTime = _props$videoStates.currentTime;

  var activeBarStyle = { width: progress + '%' };
  return React.createElement(
    'div',
    { className: 'videoContainer__controls', style: { maxWidth: props.clientWidth } },
    React.createElement(
      'button',
      { className: 'control play-button', onClick: props.playPauseVideo },
      React.createElement(Icon, { type: isPlaying ? 'pause' : 'play' })
    ),
    React.createElement(
      'button',
      { className: 'progressbar', onClick: props.setTime },
      React.createElement('div', { className: 'active-bar', style: activeBarStyle }),
      React.createElement('div', { className: 'total-bar' })
    ),
    React.createElement(
      'div',
      { className: 'time' },
      currentTime + ' / ' + duration
    ),
    React.createElement(
      'button',
      { className: 'control mute-button', onClick: props.muteButtonClick },
      React.createElement(Icon, { type: isMuted ? 'volume-mute' : 'volume' })
    ),
    React.createElement(
      'button',
      { className: 'control fullscreen-button', onClick: props.fullscreenButtonClick },
      React.createElement(Icon, { type: 'fullscreen' })
    )
  );
}
VideoControls.propTypes = propTypes;