import React from 'react';
import { IconProps } from '../icon.types';

export const Ellipsis = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.51758 10.0151C5.51758 9.09375 4.77051 8.35498 3.83252 8.35498C2.91943 8.35498 2.17236 9.09375 2.17236 10.0151C2.17236 10.9365 2.91943 11.6753 3.83252 11.6753C4.77051 11.6753 5.51758 10.9365 5.51758 10.0151ZM11.6602 10.0151C11.6602 9.09375 10.9214 8.35498 10 8.35498C9.08691 8.35498 8.34814 9.09375 8.34814 10.0151C8.34814 10.9365 9.08691 11.6753 10 11.6753C10.9214 11.6753 11.6602 10.9365 11.6602 10.0151ZM17.8276 10.0151C17.8276 9.09375 17.0889 8.35498 16.1675 8.35498C15.2295 8.35498 14.4907 9.09375 14.4907 10.0151C14.4907 10.9365 15.2295 11.6753 16.1675 11.6753C17.0889 11.6753 17.8276 10.9365 17.8276 10.0151Z" />
    </svg>
  );
};
