import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Thumbtack = function Thumbtack(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'angledown__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 627.7 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M174.4,0c-32.2,0-58.1,25.9-58.1,58.1s25.9,58.1,58.1,58.1l-71.2,274.6C39.9,420.7,0,463.7,0,511.5h290.6v465c-0.1,6.2,2.3,12.2,6.7,16.7c4.4,4.4,10.4,6.9,16.6,6.9s12.2-2.5,16.6-6.9c4.4-4.4,6.8-10.4,6.7-16.7v-465h290.6c0-47.8-39.9-90.8-103.2-120.6l-71.2-274.6c32.2,0,58.1-25.9,58.1-58.1S485.5,0,453.3,0H174.4z' // eslint-disable-line max-len
    })
  );
};

Thumbtack.propTypes = propTypes;

export default Thumbtack;