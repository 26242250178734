import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Textbox = function Textbox(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Textbox',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M441.5,297.3c0-19.9,0-24.1-19.9-24.1h-51.5c-60.7,0-78.1,33.3-103,93.9c-10,5-25.8,3.3-30.8-7.4 c17.4-57.3,21.6-108.8,27.5-141.2c3.3-2.5,7.4-4.2,11.6-4.2c4.2,0,8.3,0.9,11.6,3.3c4.2,14.1,6.7,17.4,69.8,17.4h309 c54.8,0,67.2-0.8,74.7-18.3c3.3-1.6,5.8-2.5,10.8-2.5c4.9,0,10.8,3.3,12.4,5.8c-8.3,28.3-14.1,95.6-12.4,142.9 c-4.2,7.4-26.6,9.1-30.8,2.5c-14.1-53.2-25.7-92.2-88-92.2h-49.8c-19.9,0-22.5,2.5-22.5,24.1v366.3c0,77.2,5,83.9,42.3,88.9 l33.3,4.1c5.8,5,5.8,24.1,0,29.1c-54-1.7-95.6-2.5-132.9-2.5c-39.9,0-83.1,0.8-141.2,2.5c-6.7-5-6.7-24.1,0-29.1l37.3-4.1 c37.4-4.2,42.4-11.6,42.4-88.9V297.3z M928.6,71.4v857.1H71.4V71.4H928.6 M1000,0H0v1000h1000V0L1000,0z' // eslint-disable-line max-len
    })
  );
};

Textbox.propTypes = propTypes;

export default Textbox;