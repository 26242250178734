import { GalleryCreateEditResponse } from '../../../http/gallery_create.thunks';

export enum GALLERY_CREATE_TYPES {
  START = 'GALLERY_CREATE/START',
  SUCCESS = 'GALLERY_CREATE/SUCCESS',
  FAILURE = 'GALLERY_CREATE/FAILURE',
}

export interface GalleryCreateStartAction {
  type: GALLERY_CREATE_TYPES.START;
  payload: {};
}

export interface GalleryCreateSuccessAction {
  type: GALLERY_CREATE_TYPES.SUCCESS;
  payload: GalleryCreateEditResponse;
}

export interface GalleryCreateFailureAction {
  type: GALLERY_CREATE_TYPES.FAILURE;
  payload: Error;
}

export type GalleryCreateActions =
  | GalleryCreateStartAction
  | GalleryCreateSuccessAction
  | GalleryCreateFailureAction;
