import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var FacebookLike = function FacebookLike(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'facebookLike__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1023.1 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M970.8,646.4c9.4,20.3,11.9,41.3,7.6,63.2c-4.3,21.8-13.4,39.7-27.5,53.8c10.9,15.6,11.7,44.3,2.3,85.9 c-9.4,41.7-50.3,65.3-122.8,70.8H432.7c-14-0.8-26.7-4.1-38-9.9c-11.3-5.9-20.9-11.9-28.7-18.1c-2.3-1.5-5.5-2.7-9.4-3.5h-5.8v11.7 c0,12.4-3.7,25.2-11.1,38c-7.4,12.9-16.9,19.7-28.7,20.5h-269c-11.7-0.8-21.3-7.4-28.7-19.9C6,926.3,1.5,914.6,0,903.7V490.8 c0-13.2,4.3-23,12.9-29.2c8.6-6.2,19.9-9.4,33.9-9.4h295.9c7.8-0.8,15.6,0.4,23.4,3.5c16.4-10.9,40.9-43.5,73.7-97.7 c32.7-54.2,57.7-98.4,74.8-132.8c0-17.1-0.8-41.3-2.3-72.5l-2.3-51.5c-0.8-7,2.5-16.8,9.9-29.2c7.4-12.4,25.1-22.2,53.2-29.2 c21.8-3.1,41.9-1.5,60.2,4.7c18.3,6.3,30.6,13.6,36.8,22.2c2.3,3.9,10.9,22.4,25.7,55.6c14.8,33.2,24.6,69.2,29.2,108.2 c0,7-2.2,25.9-6.4,56.7c-4.3,30.8-8.8,57.5-13.4,80.1h233.9c45.2,3.1,72.1,22.6,80.7,58.5c8.6,35.9,0,68.2-25.7,97.1 c10.1,28.1,11.1,53,2.9,74.8C988.9,622.6,980.1,637.8,970.8,646.4z M810.5,841.7c40.5-3.1,63.9-16.6,70.2-40.3s-4.3-38.8-31.6-45 l4.7-8.2c26.5-3.9,43.7-17.1,51.5-39.7c7.8-22.6-4.3-39.4-36.3-50.3l3.5-7c27.3-4.7,45.2-18.3,53.8-40.9c8.6-22.6-3.9-39-37.4-49.1 l3.5-8.2c33.5-7,51.8-23.6,55-49.7c3.1-26.1-12.5-40.7-46.8-43.9H608.2l7-35.1c4.7-22.6,10.5-53.8,17.5-93.6 c7-39.7,11.7-67,14-81.9c-2.3-21.8-7.8-44.4-16.4-67.8c-8.6-23.4-16-42.1-22.2-56.1c-2.3,0-5.1-0.8-8.2-2.3 c-3.1-1.6-5.9-2.3-8.2-2.3h-11.7c0,17.2,0.8,38.2,2.3,63.2c1.6,25,2.3,42.9,2.3,53.8c-5.5,28.8-29.2,76.2-71.3,142.1 C471.3,445,441.7,487.3,424.6,506c-12.5,10.9-30.8,24.2-55,39.8v272.5c15.6,3.9,28.8,10.5,39.8,19.9c3.9,3.9,8.4,6.8,13.4,8.8 c5.1,2,9.5,2.9,13.4,2.9L810.5,841.7z' // eslint-disable-line max-len
    })
  );
};

FacebookLike.propTypes = propTypes;

export default FacebookLike;