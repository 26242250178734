import { ThunkDispatch } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import axios from './axios_config';
import {
  galleryDeleteFailed,
  galleryDeleteStarted,
  galleryDeleteSucceeded,
} from '../redux/galleries/galleries.actions';
import {
  selectCurrentNetworkId,
  selectCurrentPublisherId,
} from '../redux/user/user.selectors';
import { AppState, AppActions } from '../types';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { getErrorMessage } from '../utils';

export interface GalleryDeleteResponse {
  gallery_id: string;
}

export const deleteGallery = (galleryId: string) => async (
  dispatch: ThunkDispatch<AppState, {}, AppActions>,
  getState: () => AppState,
): Promise<GalleryDeleteResponse> => {
  const state = getState();
  const networkId = selectCurrentNetworkId(state);
  const publisherId = selectCurrentPublisherId(state);

  if (!galleryId) {
    throw new Error('Failed to delete gallery, no gallery id provided');
  }

  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.GALLERIES,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
      galleryId,
    },
  });

  try {
    dispatch(galleryDeleteStarted);
    const res: AxiosResponse<GalleryDeleteResponse> = await axios.delete<
      GalleryDeleteResponse
    >(url);
    dispatch(galleryDeleteSucceeded(galleryId));
    return res.data;
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    dispatch(galleryDeleteFailed(errorMessage ?? 'Failed to delete gallery'));
    throw new Error(`Failed to delete gallery: ${errorMessage}`);
  }
};
