import _extends from 'babel-runtime/helpers/extends';
import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../Icon';

var propTypes = {
  padding: PropTypes.number,
  assetWidth: PropTypes.number,
  assetHeight: PropTypes.number,
  mediaType: PropTypes.oneOf(['image', 'video']),
  text: PropTypes.string,
  editorial: PropTypes.string,
  source: PropTypes.string.isRequired,
  children: PropTypes.node,
  isActive: PropTypes.bool,
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  isAdjustable: PropTypes.bool,
  onLoad: PropTypes.func,
  style: PropTypes.object
};

var defaultProps = {
  mediaType: 'image',
  assetWidth: 0,
  assetHeight: 0,
  padding: 8,
  isActive: false,
  isHovered: false,
  isSelected: false,
  isAdjustable: false,
  style: {}
};

var propDescriptions = {
  mediaType: 'indicates if the Asset is an image or a video',
  padding: 'The amount of padding that will be subtracted from the assetHeight and assetWidth',
  assetWidth: 'A number used as the exact width of the asset',
  assetHeight: 'A number used as the exact height of the asset',
  children: 'Elements placed as a sibling of the img, do not use for conditionally hovered elements', // eslint-disable-line max-len
  isHovered: 'Boolean value indicatng if the asset is being hovered, adds classes to the Asset',
  isActive: 'Boolean value that adds class \'isActive\' to Asset',
  isSelected: 'Boolean value that adds class\'isSelected\' to Asset',
  text: 'String used as first option for alt text',
  editorial: 'String used as second option for alt text',
  source: 'String used as src attribute on img',
  isAdjustable: 'Whether the asset can be adjusted to add black space to the bottom/top or sides',
  style: 'Optional inline styles to attach to the style object'
};

var Asset = function (_React$PureComponent) {
  _inherits(Asset, _React$PureComponent);

  function Asset(props) {
    _classCallCheck(this, Asset);

    var _this = _possibleConstructorReturn(this, (Asset.__proto__ || _Object$getPrototypeOf(Asset)).call(this, props));

    _this.handleLoad = _this.handleLoad.bind(_this);
    _this.renderAdjustedImage = _this.renderAdjustedImage.bind(_this);
    _this.state = {
      isLoaded: false
    };
    return _this;
  }

  // when the photo loads, fade it in


  _createClass(Asset, [{
    key: 'handleLoad',
    value: function handleLoad() {
      if (this.props.onLoad) this.props.onLoad();
      this.setState({ isLoaded: true });
    }

    // if it's a video, render a play button

  }, {
    key: 'renderPlayButton',
    value: function renderPlayButton(type) {
      if (type !== 'video') return null;
      return React.createElement(
        'div',
        { className: 'asset__playButtonContainer' },
        React.createElement(Icon, { type: 'play-button', size: 80, color: 'white' })
      );
    }
  }, {
    key: 'renderAdjustedImage',
    value: function renderAdjustedImage(ratio) {
      var _props = this.props,
          assetWidth = _props.assetWidth,
          text = _props.text,
          editorial = _props.editorial,
          source = _props.source,
          style = _props.style;

      var isVertical = ratio > 2.33;
      var imageStyle = isVertical ? {
        width: '100%',
        maxWidth: '100%',
        height: 'auto'
      } : {
        height: '100%',
        maxHeight: '100%',
        width: assetWidth
      };
      return React.createElement(
        'div',
        {
          className: 'asset__adjuster',
          style: {
            width: '100%',
            height: '100%'
          }
        },
        React.createElement('div', {
          className: 'asset__adjusterBackground'
        }),
        React.createElement('img', {
          alt: text || editorial || 'no caption provided',
          className: 'asset__img',
          src: source,
          onLoad: this.handleLoad,
          style: _extends({}, imageStyle, style)
        })
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          editorial = _props2.editorial,
          text = _props2.text,
          mediaType = _props2.mediaType,
          assetWidth = _props2.assetWidth,
          assetHeight = _props2.assetHeight,
          padding = _props2.padding,
          source = _props2.source,
          isActive = _props2.isActive,
          isHovered = _props2.isHovered,
          isSelected = _props2.isSelected,
          isAdjustable = _props2.isAdjustable,
          style = _props2.style;
      var isLoaded = this.state.isLoaded;


      var thumbnailClasses = classNames('asset', {
        isSelected: isSelected,
        isLoaded: isLoaded,
        isHovered: isHovered,
        isActive: isActive
      });
      var ratio = assetWidth / assetHeight;
      var shouldAdjust = isAdjustable && (ratio < 0.5 || ratio > 2.33);
      var adjustedWidth = ratio > 2.33 ? assetHeight * 2.33 : assetHeight * 0.75;
      return React.createElement(
        'div',
        {
          className: thumbnailClasses,
          style: {
            height: assetHeight - padding,
            margin: padding / 2, // Use margin so overlays are contained within this div
            width: (shouldAdjust ? adjustedWidth : assetWidth) - padding
          }
        },
        this.renderPlayButton(mediaType),
        shouldAdjust ? this.renderAdjustedImage(ratio) : React.createElement('img', {
          alt: text || editorial || 'no caption provided',
          className: 'asset__img',
          src: source,
          onLoad: this.handleLoad,
          style: _extends({
            width: assetWidth - padding, // Account for margin and padding
            height: assetHeight - padding }, style)
        }),
        this.props.children
      );
    }
  }]);

  return Asset;
}(React.PureComponent);

export default Asset;


Asset.propTypes = propTypes;
Asset.defaultProps = defaultProps;
Asset.propDescriptions = propDescriptions;