import _extends from 'babel-runtime/helpers/extends';
import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import VideoControls from './VideoControls';
import { Icon } from '../';

var propTypes = {
  source: PropTypes.string.isRequired,
  poster: PropTypes.string,
  className: PropTypes.string,
  parentRef: PropTypes.func,
  isLooping: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  onLoad: PropTypes.func,
  showControls: PropTypes.bool
};

var propDescriptions = {
  source: 'the source location of the video',
  poster: 'a still image to be shown when the file loads',
  className: 'optional classname for the video player',
  parentRef: 'a reference to the video element to be passed up to the parent component',
  isLooping: 'if the video player should loop',
  height: 'the height of the video player in pixels',
  width: 'Optional prop to set width of video player in pixels, is not required',
  onLoad: 'Function that will fire when once the video is playable',
  showControls: 'Optional ability to show video controls below the video'
};

var defaultProps = {
  poster: '',
  height: 480,
  showControls: false
};

var VideoPlayer = function (_React$Component) {
  _inherits(VideoPlayer, _React$Component);

  function VideoPlayer(props) {
    _classCallCheck(this, VideoPlayer);

    var _this = _possibleConstructorReturn(this, (VideoPlayer.__proto__ || _Object$getPrototypeOf(VideoPlayer)).call(this, props));

    _this.componentDidMount = function () {
      var parentRef = _this.props.parentRef;

      if (parentRef) {
        parentRef(_this.video);
      }
    };

    _this.onPlay = function () {
      _this.setState({
        isPlaying: true
      });
    };

    _this.onPause = function () {
      _this.setState({
        isPlaying: false
      });
    };

    _this.onProgress = function (e) {
      _this.setState({
        currentTime: _this.convertTime(Math.floor(e.target.currentTime)),
        progress: e.target.currentTime / e.target.duration * 100
      });
    };

    _this.onLoadedMetadata = function (e) {
      _this.setState({
        isLoaded: true,
        duration: _this.convertTime(e.target.duration),
        videoWidth: _this.video.videoWidth
      });
    };

    _this.setTime = function (e) {
      var duration = _this.video.duration;

      var _e$target$getBounding = e.target.getBoundingClientRect(),
          left = _e$target$getBounding.left,
          right = _e$target$getBounding.right;

      var width = right - left;
      var scrubPosition = void 0;
      if (e.clientX > right) {
        scrubPosition = right;
      } else if (e.clientX < left) {
        scrubPosition = left;
      } else {
        scrubPosition = e.clientX;
      }
      var newTime = (scrubPosition - left) / width * duration;
      _this.video.currentTime = newTime;
      _this.video.play();
    };

    _this.muteButtonClick = function () {
      _this.video.muted = !_this.video.muted;
      _this.setState({
        isMuted: _this.video.muted
      });
    };

    _this.fullscreenButtonClick = function () {
      // IE
      if (_this.video.requestFullscreen) {
        _this.video.requestFullscreen();
        // Webkit
      } else if (_this.video.webkitRequestFullscreen) {
        _this.video.webkitRequestFullscreen();
        // Mozilla
      } else if (_this.video.mozRequestFullscreen) {
        _this.video.mozRequestFullscreen();
      }
    };

    _this.playPauseVideo = function () {
      var isPlaying = _this.state.isPlaying;

      if (!isPlaying) {
        _this.video.play();
        _this.setState({ isPlaying: true });
      } else {
        _this.video.pause();
        _this.setState({ isPlaying: false });
      }
    };

    _this.state = {
      isPlaying: false,
      isMuted: false,
      progress: 0,
      duration: 0,
      currentTime: _this.convertTime(0),
      isLoaded: false,
      videoWidth: 0
    };
    return _this;
  }

  _createClass(VideoPlayer, [{
    key: 'convertTime',
    value: function convertTime(time) {
      var minutes = Math.floor(time / 60);
      var seconds = Math.floor(time % 60);
      return minutes + ':' + (seconds < 10 ? '0' + seconds : seconds);
    }
  }, {
    key: 'renderVideo',
    value: function renderVideo() {
      var _this2 = this;

      var _props = this.props,
          source = _props.source,
          poster = _props.poster,
          isLooping = _props.isLooping,
          showControls = _props.showControls,
          width = _props.width;


      var controlProps = showControls ? {
        onClick: this.playPauseVideo,
        onPlay: this.onPlay,
        onPause: this.onPause,
        onTimeUpdate: this.onProgress,
        onLoadedMetadata: this.onLoadedMetadata
      } : {};
      return React.createElement(
        'video',
        _extends({
          poster: poster,
          loop: isLooping,
          draggable: true,
          ref: function ref(video) {
            _this2.video = video;
          },
          onLoadStart: this.props.onLoad || null,
          style: { width: width }
        }, controlProps),
        React.createElement('source', { src: source }),
        React.createElement('track', { kind: 'captions' }),
        'Video not supported'
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          className = _props2.className,
          height = _props2.height,
          width = _props2.width,
          showControls = _props2.showControls;
      var isPlaying = this.state.isPlaying;

      var PADDING = 42;
      var calculatedHeight = showControls ? { height: height + PADDING } : { height: height };
      var controlWidth = width || this.state.videoWidth;
      if (showControls) {
        var videoStates = {
          isPlaying: this.state.isPlaying,
          isMuted: this.state.isMuted,
          progress: this.state.progress,
          duration: this.state.duration,
          currentTime: this.state.currentTime,
          isLoaded: this.state.isLoaded
        };
        return React.createElement(
          'div',
          {
            className: 'videoContainer videoContainer__withControls',
            style: calculatedHeight
          },
          React.createElement(
            'div',
            { className: 'videoContainer__withControls-inner', style: calculatedHeight },
            this.renderVideo(),
            React.createElement(VideoControls, {
              videoStates: videoStates,
              playPauseVideo: this.playPauseVideo,
              setTime: this.setTime,
              muteButtonClick: this.muteButtonClick,
              fullscreenButtonClick: this.fullscreenButtonClick,
              videoContainerWidth: controlWidth
            })
          )
        );
      }
      return React.createElement(
        'button',
        {
          className: 'videoContainer ' + className,
          style: { width: width },
          onClick: this.playPauseVideo
        },
        !isPlaying ? React.createElement(Icon, {
          type: 'play-button',
          className: 'videoContainer__icon',
          color: 'white',
          size: height / 2
        }) : null,
        this.renderVideo()
      );
    }
  }]);

  return VideoPlayer;
}(React.Component);

export default VideoPlayer;


VideoPlayer.propTypes = propTypes;
VideoPlayer.defaultProps = defaultProps;
VideoPlayer.propDescriptions = propDescriptions;