import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Edit = function Edit(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'edit',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1038.5 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M571.2,0H0v1000h1000V428.8l-71.4,71.4v428.3H71.4V71.4h428.3L571.2,0z M857.1,0L1000,142.9L428.7,714.2H285.8V571.3 L857.1,0z' // eslint-disable-line max-len
    })
  );
};

Edit.propTypes = propTypes;

export default Edit;