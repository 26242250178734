import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { CRThunkDispatch, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { AppState } from '../types';
import { selectCurrentNetworkId } from '../redux/user/user.selectors';
import { getGalleryFormSignals } from '../react/features/gallery_form/gallery_form.utils';
import {
  galleryPreviewSuccessAction,
  galleryPreviewFailureAction,
} from '../react/features/gallery_preview/gallery_preview.actions';
import { Story } from '../react/features/story/story.types';
import {
  GalleryFormSignals,
  DynamicGalleryFormSubmitData,
} from '../react/features/gallery_form/gallery_form.types';
import { getErrorMessage } from '../utils';

type GalleryPreviewThunk = (
  formData: DynamicGalleryFormSubmitData,
  publisherId: string,
) => (
  dispatch: CRThunkDispatch,
  getState: () => AppState,
) => Promise<GalleryPreviewResponse>;

export interface GalleryPreviewResponse {
  stories: Story[];
  matched_story_count: number;
}

export const getGalleryPreview: GalleryPreviewThunk = (
  formData: DynamicGalleryFormSubmitData,
  publisherId: string,
) => async (dispatch: CRThunkDispatch, getState: () => AppState) => {
  const state: AppState = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.GALLERIES_PREVIEW,
    params: {
      networkId,
    },
  });

  const params: GalleryFormSignals = getGalleryFormSignals(formData);
  try {
    const response: AxiosResponse<GalleryPreviewResponse> = await axios.post<
      GalleryPreviewResponse
    >(url, {
      ...params,
      publisher_id: publisherId,
    });
    dispatch(galleryPreviewSuccessAction(response.data));
    return response.data;
  } catch (err) {
    dispatch(
      galleryPreviewFailureAction(
        getErrorMessage(err, 'Gallery form signal get failed.'),
      ),
    );
    throw err;
  }
};
