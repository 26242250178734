import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Hashtag = function Hashtag(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Hashtag__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1124.7 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1087.7,401c-2.6,9.1-10.4,15.6-20.2,15.6H854.6L813,583.3h202.5c6.5,0,12.4,3.3,16.3,7.8c3.9,5.2,5.9,11.7,3.9,18.2\t\tl-36.4,145.8c-2,9.1-10.4,15.6-20.2,15.6H766.1l-52.7,213.6c-2.6,9.1-11.1,15.6-20.2,15.6H547.3c-6.5,0-13-3.3-16.9-7.8\t\tc-3.9-5.2-5.2-11.7-3.9-18.2l50.8-203.1H411.9l-52.7,213.6c-2.6,9.1-11,15.6-20.2,15.6H192.5c-5.9,0-12.4-3.3-16.3-7.8\t\tc-3.9-5.2-5.2-11.7-3.9-18.2l50.8-203.1H20.7c-6.5,0-12.4-3.2-16.3-7.8c-3.9-5.2-5.2-11.7-3.9-18.3L36.9,599\t\tc2.6-9.1,10.4-15.6,20.2-15.6H270l41.7-166.7H109.2c-6.5,0-12.4-3.3-16.3-7.8c-3.9-5.2-5.9-11.7-3.9-18.2l36.5-145.8\t\tc2-9.1,10.4-15.6,20.2-15.6h212.9l52.7-213.6c2.6-9.1,11-15.6,20.8-15.6H578c5.9,0,12.4,3.3,16.3,7.8c3.9,5.2,5.2,11.7,3.9,18.2\t\tl-50.8,203.1h165.4l52.7-213.6C768,6.5,776.5,0,786.3,0h145.8c5.9,0,12.4,3.3,16.3,7.8c3.9,5.2,5.2,11.7,3.9,18.2l-50.8,203.1H1104\tc6.5,0,12.4,3.2,16.3,7.8c3.9,5.2,5.2,11.7,3.9,18.3L1087.7,401z M665.9,416.7H500.5l-41.7,166.7h165.4L665.9,416.7z' // eslint-disable-line max-len
    })
  );
};

Hashtag.propTypes = propTypes;

export default Hashtag;