import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PaintBrush = function PaintBrush(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'paintbrush',
      height: size,
      viewBox: '0 0 1000 1000',
      fill: color,
      'aria-hidden': true,
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M394.444 577.778c13.889 27.778 33.334 52.778 58.334 72.222 25 19.444 52.778 33.333 83.333 41.667v38.889C538.889 811.111 513.889 875 463.889 925c-50 50-113.889 75-194.445 75-44.444 0-86.111-8.333-122.222-25-36.111-16.667-63.889-41.667-86.11-72.222C38.888 872.222 27.777 838.889 16.666 800 5.556 763.889 0 722.222 0 677.778c2.778 2.778 11.111 8.333 22.222 16.666 13.89 8.334 25 16.667 33.334 25 11.11 8.334 22.222 13.889 33.333 19.445C100 744.444 111.11 750 116.667 750c13.889 0 25-5.556 30.555-19.444 8.334-25 19.445-47.223 30.556-63.889 11.111-16.667 25-30.556 38.889-41.667 13.889-11.111 30.555-19.444 50-27.778 19.444-5.555 38.889-11.111 58.333-13.889 19.444-2.777 41.667-5.555 69.444-5.555zM900 0c25 0 50 8.333 69.445 25C988.889 41.667 1000 63.889 1000 91.667c0 22.222-8.333 52.777-25 83.333-122.222 233.333-211.111 375-258.333 419.444-36.111 33.334-77.778 50-122.223 50-47.222 0-86.111-16.666-119.444-52.777-33.333-33.334-50-75-50-122.223 0-47.222 16.667-86.111 50-119.444L827.778 30.556C850 11.11 875 0 900 0z' // eslint-disable-line max-len
    })
  );
};

PaintBrush.propTypes = propTypes;

export default PaintBrush;