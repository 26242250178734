import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var TwitterReply = function TwitterReply(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'twitterReply__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 13 13',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M7.9 0H5.1C2.3 0 0 2.3 0 5.1 0 7.8 2.1 9.9 4.9 10v2.5c0 .1 0 .2.1.3.1.1.2.2.4.2.1 0 .2 0 .3-.1.2-.1 4.3-2.7 5.3-3.6 1.3-1.1 2-2.6 2-4.2C13 2.3 10.7 0 7.9 0zm2.5 8.5c-.7.6-3.2 2.2-4.5 3.1V9.5c0-.3-.2-.5-.5-.5h-.3C2.7 9 1 7.4 1 5.1S2.8 1 5.1 1h2.7C10.2 1 12 2.8 12 5.1c0 1.3-.6 2.6-1.6 3.4z' // eslint-disable-line max-len
    })
  );
};

TwitterReply.propTypes = propTypes;

export default TwitterReply;