import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

/* eslint-disable max-len */

var Ellipsis = function Ellipsis(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'ellipsis',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M200,500c0,55.2-44.8,100-100,100S0,555.2,0,500s44.8-100,100-100S200,444.8,200,500z M500,400c-55.2,0-100,44.8-100,100 s44.8,100,100,100s100-44.8,100-100S555.2,400,500,400z M900,400c-55.2,0-100,44.8-100,100s44.8,100,100,100s100-44.8,100-100 S955.2,400,900,400z'
    })
  );
};

Ellipsis.propTypes = propTypes;

export default Ellipsis;