import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { AppState, AppThunkDispatch } from '../../../../types';
import { StoryListSelectableComponent } from './story_list_selectable.component';
import {
  selectStoriesLoadingType,
  selectAllStories,
} from '../../../../redux/stories/stories.selectors';
import { hideStory } from '../../../../http/story_hide.thunk';
import { showStory } from '../../../../http/story_show.thunk';
import { selectIsLocalhoodOwner } from '../../../../redux/user/user.selectors';

const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.localize),
  stories: selectAllStories(state),
  loadingType: selectStoriesLoadingType(state),
  isLocalhoodOwner: selectIsLocalhoodOwner(state),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onHideStory: (story_uuid: string) => dispatch(hideStory(story_uuid)),
  onShowStory: (story_uuid: string) => dispatch(showStory(story_uuid)),
});

export const StoryListSelectable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StoryListSelectableComponent);
