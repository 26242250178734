import _extends from 'babel-runtime/helpers/extends';
import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

var propTypes = {
  backgroundUrl: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  padding: PropTypes.number,
  styles: PropTypes.object
};

var propDescriptions = {
  backgroundUrl: 'A string containing the location of the background image',
  children: 'A node specifying the contents of the button',
  className: 'A string of any additional classes to be added to the header',
  padding: 'A number of pixels of padding to apply to the header',
  styles: 'optional inline styles'
};

var defaultProps = {
  padding: 16
};

var HeroHeader = function (_React$Component) {
  _inherits(HeroHeader, _React$Component);

  function HeroHeader(props) {
    _classCallCheck(this, HeroHeader);

    return _possibleConstructorReturn(this, (HeroHeader.__proto__ || _Object$getPrototypeOf(HeroHeader)).call(this, props));
  }

  _createClass(HeroHeader, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          children = _props.children,
          backgroundUrl = _props.backgroundUrl,
          padding = _props.padding,
          styles = _props.styles;

      var heroHeaderClasses = classNames('heroHeader', className);
      return React.createElement(
        'div',
        {
          className: heroHeaderClasses,
          style: _extends({
            backgroundImage: 'url("' + backgroundUrl + '")',
            padding: padding + 'px'
          }, styles)
        },
        children
      );
    }
  }]);

  return HeroHeader;
}(React.Component);

export default HeroHeader;


HeroHeader.propTypes = propTypes;
HeroHeader.defaultProps = defaultProps;
HeroHeader.propDescriptions = propDescriptions;