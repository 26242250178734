import { LocalizeContextProps } from 'react-localize-redux';
import { Publisher } from '../../../redux/user/user.types';

export enum ACCOUNT_FIELD_MASK {
  GALLERY_DOMAIN = 'gallery_domain',
  NAME = 'name',
}

export interface PublisherUpdateDetails {
  gallery_domain?: string;
  // TODO: Update file input to be of type single/multiple
  avatar?: File[];
  favicon?: File[];
  name?: string;
}

export enum FORM_INPUT_NAMES {
  NAME = 'name',
  WEBSITE = 'gallery_domain',
  AVATAR = 'avatar',
  FAVICON = 'favicon',
}

export type FORM_INPUTS = {
  [FORM_INPUT_NAMES.WEBSITE]: string;
  [FORM_INPUT_NAMES.NAME]: string;
  [FORM_INPUT_NAMES.AVATAR]: File[];
  [FORM_INPUT_NAMES.FAVICON]: File[];
};

export interface AccountEditFormProps extends LocalizeContextProps {
  isSaving: boolean;
  publisher: Publisher;
  onSubmit: (data: PublisherUpdateDetails) => void;
  onReject: () => void;
}

export interface StoryAppearanceSectionProps extends LocalizeContextProps {
  avatarUrl: string;
  avatarImg?: File;
  isAvatarLoading?: boolean;
  displayName?: string;
}

export interface WebSettingsSectionProps extends LocalizeContextProps {
  faviconUrl: string;
  faviconImg?: File;
  isFaviconLoading?: boolean;
}
