import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var AngleDownDouble = function AngleDownDouble(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Angledowndouble__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1033.1 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', { d: 'M947.2,397.5c9,0,16.9,3.5,23.9,10.4l51.7,51.8c6.9,6.9,10.4,14.8,10.4,23.8c0,9-3.4,16.9-10.4,23.8L540.4,989.6\t\tc-6.9,6.9-14.9,10.4-23.9,10.4c-9,0-16.9-3.4-23.8-10.4L10.3,507.2C3.4,500.4,0,492.4,0,483.4c0-9,3.4-16.9,10.3-23.8l51.8-51.8\t\tc6.8-6.9,14.8-10.4,23.8-10.4c9.1,0,16.9,3.5,23.9,10.4l406.8,406.8l406.8-406.8C930.3,401,938.2,397.5,947.2,397.5z M947.2,0\t\tc9,0,16.9,3.4,23.9,10.3l51.7,51.8c6.9,6.9,10.4,14.8,10.4,23.8c0,9-3.4,16.9-10.4,23.8L540.4,592.1c-6.9,6.9-14.9,10.4-23.9,10.4\t\tc-9,0-16.9-3.4-23.8-10.4L10.3,109.7C3.4,102.8,0,94.9,0,85.9c0-9,3.4-16.9,10.3-23.8l51.8-51.8C68.9,3.4,76.9,0,85.8,0\tc9.1,0,16.9,3.4,23.9,10.3l406.8,406.8L923.3,10.3C930.3,3.4,938.2,0,947.2,0z' })
  );
};

AngleDownDouble.propTypes = propTypes;

export default AngleDownDouble;