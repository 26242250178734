export enum LANGUAGE_CODE {
  ENGLISH = 'en',
  FRENCH = 'fr',
}

export enum LANGUAGE_NAME {
  ENGLISH = 'English',
  FRENCH = 'French',
}

export interface Language {
  code: LANGUAGE_CODE;
  name: LANGUAGE_NAME;
  active?: boolean;
}

export const supportedLanguages = [
  { name: LANGUAGE_NAME.ENGLISH, code: LANGUAGE_CODE.ENGLISH },
  { name: LANGUAGE_NAME.FRENCH, code: LANGUAGE_CODE.FRENCH },
];
