import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var StoryNetwork = function StoryNetwork(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'storynetwork__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 17 17',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M0.578979 4.24317V13.3809C0.578979 13.6855 0.699976 13.9776 0.915351 14.193C1.13073 14.4084 1.42284 14.5294 1.72742 14.5294H2.05276V3.09473H1.72742C1.42284 3.09473 1.13073 3.21572 0.915351 3.4311C0.699976 3.64647 0.578979 3.93858 0.578979 4.24317Z' // eslint-disable-line max-len
    }),
    React.createElement('path', {
      d: 'M15.418 3.09473H15.0927V14.5294H15.418C15.5691 14.5299 15.7188 14.5005 15.8586 14.443C15.9983 14.3855 16.1253 14.301 16.2324 14.1943C16.3394 14.0876 16.4243 13.9608 16.4822 13.8213C16.5402 13.6817 16.57 13.5321 16.57 13.381V4.24318C16.57 4.09206 16.5402 3.94243 16.4822 3.80286C16.4243 3.66329 16.3394 3.53653 16.2324 3.42984C16.1253 3.32315 15.9983 3.23863 15.8586 3.18113C15.7188 3.12362 15.5691 3.09426 15.418 3.09473Z' // eslint-disable-line max-len
    }),
    React.createElement('path', {
      d: 'M12.9558 0.812073H4.18959C3.90669 0.812073 3.63538 0.924453 3.43534 1.12449C3.23531 1.32453 3.12292 1.59584 3.12292 1.87874V15.7454C3.12292 16.0283 3.23531 16.2996 3.43534 16.4997C3.63538 16.6997 3.90669 16.8121 4.18959 16.8121H12.9558C13.2387 16.8121 13.51 16.6997 13.7101 16.4997C13.9101 16.2996 14.0225 16.0283 14.0225 15.7454V1.88763C14.0237 1.74681 13.9969 1.60714 13.9438 1.4767C13.8908 1.34626 13.8124 1.22762 13.7132 1.12763C13.6141 1.02764 13.4961 0.94827 13.3661 0.89411C13.2361 0.83995 13.0966 0.812068 12.9558 0.812073Z' // eslint-disable-line max-len
    })
  );
};

StoryNetwork.propTypes = propTypes;

export default StoryNetwork;