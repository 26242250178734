import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../types';
import { GalleryEditContent } from './gallery_edit_content.component';
import {
  selectGalleriesToEdit,
  selectStaticGallery,
  selectStaticGalleryLabelForEdit,
} from '../../../redux/galleries/galleries.selectors';
import { editStaticGallery } from '../../../http/gallery_edit.thunk';

const mapStateToProps = (state: AppState) => ({
  gallery: selectGalleriesToEdit(state),
  galleryEdits: selectStaticGallery(state),
  label: selectStaticGalleryLabelForEdit(state),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  updateGallery: () => dispatch(editStaticGallery()),
});

export const ConnectedGalleryEditContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GalleryEditContent);
