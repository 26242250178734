import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { AppState } from '../types';
import { selectCurrentNetworkId } from '../redux/user/user.selectors';
import { LOCATION_TYPE } from '../redux/locations/locations.types';
import {
  locationTypesStartAction,
  locationTypesSuccessAction,
  locationTypesFailureAction,
} from '../redux/locations/locations.actions';
import { getErrorMessage } from '../utils';

interface GetLocationTypesResponse {
  location_types: LOCATION_TYPE[];
}

export const getLocationTypes = async (
  dispatch: ThunkDispatch<AppState, {}, Action>,
  getState: () => AppState,
): Promise<LOCATION_TYPE[]> => {
  const state: AppState = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.LOCATIONS_TYPES,
    params: {
      networkId,
    },
  });

  dispatch(locationTypesStartAction());

  try {
    const res: AxiosResponse<GetLocationTypesResponse> = await axios.get<
      GetLocationTypesResponse
    >(url, {
      params: { page_size: '100' },
    });

    dispatch(locationTypesSuccessAction(res.data.location_types ?? []));
    return res.data.location_types ?? [];
  } catch (err) {
    dispatch(locationTypesFailureAction(err));
    throw new Error(`Failed to get locations: ${getErrorMessage(err)}`);
  }
};
