import { STORY_SEARCH_LOAD_TYPES } from '../../../http/stories_search.thunks';
import { Story } from '../../features/story/story.types';

export enum STORIES_LOAD_TYPES {
  START = 'STORIES/START',
  SUCCESS = 'STORIES/SUCCESS',
  FAILURE = 'STORIES/FAILURE',
  TRIGGER_HYDRATE = 'STORIES/TRIGGER_HYDRATE',
}
export enum STORY_LOAD_TYPES {
  START = 'STORY/START',
  SUCCESS = 'STORY/SUCCESS',
  FAILURE = 'STORY/FAILURE',
}

export enum STORIES_SEARCH_TYPES {
  SEARCH_FILTERS = 'STORIES/SEARCH_FILTERS',
  CLEAR_FILTERS = 'STORIES/CLEAR_FILTERS',
  SEARCH_QUERY = 'STORIES/SEARCH_QUERY',
  CLEAR_SEARCH_QUERY = 'STORIES/CLEAR_SEARCH_QUERY',
  UPDATE_FILTER_TAB = 'STORIES/UPDATE_FILTER_TAB',
  RESET_FILTER_TAB = 'STORIES/RESET_FILTER_TAB',
}

export enum STORY_DELETE_TYPES {
  START = 'STORY_DELETE/START',
  SUCCESS = 'STORY_DELETE/SUCCESS',
  FAILURE = 'STORY_DELETE/FAILURE',
}

export interface StoriesStartAction {
  type: STORIES_LOAD_TYPES.START;
  payload: {
    loadingType: STORY_SEARCH_LOAD_TYPES;
  };
}

export interface StoriesSuccessAction {
  type: STORIES_LOAD_TYPES.SUCCESS;
  payload: {
    stories: Story[];
    nextPageToken: string;
  };
}

export interface StoriesFailureAction {
  type: STORIES_LOAD_TYPES.FAILURE;
  payload: string;
}

export interface StoriesTriggerHydrateAction {
  type: STORIES_LOAD_TYPES.TRIGGER_HYDRATE;
  payload: {};
}

export interface StoriesSearchQueryAction {
  type: STORIES_SEARCH_TYPES.SEARCH_QUERY;
  payload: string;
}

export interface ClearStoriesSearchQueryAction {
  type: STORIES_SEARCH_TYPES.CLEAR_SEARCH_QUERY;
  payload: {};
}

export interface StoryDeleteStartAction {
  type: STORY_DELETE_TYPES.START;
  payload: { story: Story };
}

export interface StoryDeleteSuccessAction {
  type: STORY_DELETE_TYPES.SUCCESS;
  payload: { story: Story };
}

export interface StoryDeleteFailureAction {
  type: STORY_DELETE_TYPES.FAILURE;
  payload: { story: Story };
}

export type StoriesListActions =
  | StoriesStartAction
  | StoriesSuccessAction
  | StoriesFailureAction
  | StoriesTriggerHydrateAction
  | StoriesSearchQueryAction
  | ClearStoriesSearchQueryAction
  | StoryDeleteStartAction
  | StoryDeleteSuccessAction
  | StoryDeleteFailureAction;
