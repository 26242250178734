import { ActionCreator, Action } from 'redux';
import {
  GetNetworkPublishersListClearAction,
  GetNetworkPublishersListFailureAction,
  GetNetworkPublishersListStartAction,
  GetNetworkPublishersListSuccessAction,
  GetNetworkSettingsSuccessAction,
  NetworkSettings,
  NETWORK_PUBLISHERS_TYPES,
  NETWORK_SETTINGS,
} from './network.types';
import { Publisher } from '../user/user.types';

export const networkPublishersListClearAction: ActionCreator<Action> = (): GetNetworkPublishersListClearAction => ({
  type: NETWORK_PUBLISHERS_TYPES.CLEAR,
  payload: {},
});
export const getNetworkPublishersListStartAction: ActionCreator<Action> = (): GetNetworkPublishersListStartAction => ({
  type: NETWORK_PUBLISHERS_TYPES.START,
  payload: {},
});
export const getNetworkPublishersListSuccessAction: ActionCreator<Action> = (payload: {
  [id: string]: Publisher;
}): GetNetworkPublishersListSuccessAction => ({
  type: NETWORK_PUBLISHERS_TYPES.SUCCESS,
  payload,
});
export const getNetworkPublishersListFailureAction: ActionCreator<Action> = (
  error: string,
): GetNetworkPublishersListFailureAction => ({
  type: NETWORK_PUBLISHERS_TYPES.FAILURE,
  payload: error,
});

export const getNetworkSettingsSuccessAction: ActionCreator<Action> = (
  payload: NetworkSettings,
): GetNetworkSettingsSuccessAction => ({
  type: NETWORK_SETTINGS.SUCCESS,
  payload,
});
