import { connect } from 'react-redux';
import { StoriesSearchFiltersComponent } from './stories_search_filters.component';
import {
  selectShowClearFilterButtons,
  selectStoriesSearchFilterCount,
} from './stories_search_filters.selectors';
import { PUBLISHER_ROLES } from '../publisher_label/publisher_label.types';
import { selectCurrentPublisherRole } from '../../../redux/user/user.selectors';
import { AppState } from '../../../types';
import {
  selectStoriesFilterTab,
  selectStoriesSearchQuery,
} from '../../../redux/stories/stories.selectors';

const mapStateToProps = (state: AppState) => {
  return {
    queryValue: selectStoriesSearchQuery(state),
    selectedFilterTab: selectStoriesFilterTab(state),
    filterCount: selectStoriesSearchFilterCount(state) ?? 0,
    showClearFilterButtons: selectShowClearFilterButtons(state),
    isNetworkOwner: selectCurrentPublisherRole(state) === PUBLISHER_ROLES.OWNER,
  };
};

export const StoriesSearchFilters = connect(mapStateToProps)(
  StoriesSearchFiltersComponent,
);
