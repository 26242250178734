import React from 'react';
import { IconProps } from '../icon.types';

export const AddToGrid = ({
  className,
  size,
  color = 'black',
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        d="M11.3281 11.3335V13.3335M9.32812 11.3335H11.3281H9.32812ZM13.3281 11.3335H11.3281H13.3281ZM11.3281 11.3335V9.3335V11.3335Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.6665 6.2665V3.0665C2.6665 2.84559 2.84559 2.6665 3.0665 2.6665H6.2665C6.48742 2.6665 6.6665 2.84559 6.6665 3.0665V6.2665C6.6665 6.48742 6.48742 6.6665 6.2665 6.6665H3.0665C2.84559 6.6665 2.6665 6.48742 2.6665 6.2665Z" />
      <path d="M2.6665 12.9335V9.7335C2.6665 9.51256 2.84559 9.3335 3.0665 9.3335H6.2665C6.48742 9.3335 6.6665 9.51256 6.6665 9.7335V12.9335C6.6665 13.1544 6.48742 13.3335 6.2665 13.3335H3.0665C2.84559 13.3335 2.6665 13.1544 2.6665 12.9335Z" />
      <path d="M9.3335 6.2665V3.0665C9.3335 2.84559 9.51256 2.6665 9.7335 2.6665H12.9335C13.1544 2.6665 13.3335 2.84559 13.3335 3.0665V6.2665C13.3335 6.48742 13.1544 6.6665 12.9335 6.6665H9.7335C9.51256 6.6665 9.3335 6.48742 9.3335 6.2665Z" />
    </svg>
  );
};
