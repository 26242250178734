import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  generatePath,
  Link,
  NavigateFunction,
  useNavigate,
} from 'react-router-dom';
import { Translate, TranslateFunction } from 'react-localize-redux';
import classNames from 'classnames';
import { Publisher } from '../../../redux/user/user.types';
import { ROUTES } from '../../../types';
import { Search, SEARCH_MODE } from '../../blocks/search/search.component';
import { UtilityMenuChip } from './utility_menu_chip.component';

interface UtilityMenuContentProps {
  publishers: Publisher[];
  currentPublisher: Publisher;
  logout: () => void;
  handlePublisherSwitching: (
    publisher: Publisher,
    navigate: NavigateFunction,
  ) => Promise<void>;
  triggerGetGalleries: () => void;
  translate: TranslateFunction;
  handleUtilityMenuClose?: () => void;
  isUserInternal: boolean;
  isAccountSettingsEnabled: boolean;
}

export const UtilityMenuContentComponent = ({
  publishers,
  logout,
  handlePublisherSwitching,
  triggerGetGalleries,
  translate,
  currentPublisher,
  handleUtilityMenuClose,
  isUserInternal,
  isAccountSettingsEnabled,
}: UtilityMenuContentProps) => {
  const navigate = useNavigate();
  const [publisherList, setPublisherList] = useState(publishers);
  const onSubmitFn = (val: string) => {
    setPublisherList(
      publishers.filter((p: Publisher) =>
        p.label?.toLowerCase().includes(val.toLowerCase()),
      ),
    );
  };

  const onSearchTermClear = () => {
    setPublisherList(publishers);
  };

  const onLinkClick = () => {
    if (handleUtilityMenuClose) {
      handleUtilityMenuClose();
    }
  };

  const createRegularMenuItem = (
    name: string,
    path: ROUTES,
    localeId: string,
  ) => {
    return (
      <li className={`utility_menu--${name}`}>
        <div className={`navigation__innerWrapper--${name}`}>
          <Link to={generatePath(path)} onClick={onLinkClick}>
            <Translate id={localeId} />
          </Link>
        </div>
      </li>
    );
  };

  return (
    <ul className="utility_menu--dropdown">
      {isUserInternal && (
        <li className="utility_menu__publisher-search">
          <Search
            id="admin_publisher"
            ariaLabel="general.navigation.search"
            placeholder={`${translate('general.navigation.search')}`}
            onClearSearch={onSearchTermClear}
            onSearch={onSubmitFn}
            searchMode={SEARCH_MODE.CHANGE}
          />
        </li>
      )}
      <li className="utility_menu--publishers">
        <ul>
          {publisherList &&
            publisherList
              .sort((a, b) => (a?.label ?? '').localeCompare(b?.label ?? ''))
              .map((publisher) => {
                return (
                  <li
                    key={`publishers-${uuidv4()}`}
                    className={classNames('utility_menu--publisher', {
                      selected:
                        `${publisher.label}-${publisher.networkId}` ===
                        `${currentPublisher?.label}-${currentPublisher?.networkId}`,
                    })}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        handlePublisherSwitching(publisher, navigate);
                        window.localStorage.setItem(
                          'currentPublisher',
                          JSON.stringify(publisher),
                        );
                        triggerGetGalleries();
                        handleUtilityMenuClose?.();
                      }}
                    >
                      <div className="utility_menu--publisher_label">
                        {publisher.label}
                        {publisher.isCreatorServices && <UtilityMenuChip />}
                      </div>
                    </button>
                  </li>
                );
              })}
        </ul>
      </li>
      {isAccountSettingsEnabled &&
        createRegularMenuItem(
          'account',
          ROUTES.ACCOUNT_SETTINGS,
          'general.navigation.accountSettings',
        )}
      <li className="utility_menu--support">
        <div className="navigation__innerWrapper--support">
          <a
            href="https://support.crowdriff.com/hc/en-us/categories/360004295474-Story-Network"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`${translate('general.navigation.supportLabel')}`}
            onClick={onLinkClick}
          >
            <Translate id="general.navigation.support" />
          </a>
        </div>
      </li>
      {isUserInternal &&
        createRegularMenuItem('admin', ROUTES.ADMIN, 'admin.navigation.admin')}
      <li className="utility_menu--logout">
        <button
          type="button"
          className="navigation__innerWrapper--logout"
          onClick={() => {
            logout();
          }}
        >
          <Translate id="general.navigation.logout" />
        </button>
      </li>
    </ul>
  );
};

UtilityMenuContentComponent.displayName = 'UtilityMenuContent';
