import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Advancedrights = function Advancedrights(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'advancedRights__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 833.3 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M820.8,12.5C812.5,4.2,802.5,0,791.7,0h-750C30,0,20.8,4.2,12.5,12.5C4.2,20.8,0,30.8,0,41.7v500c0,37.5,7.5,74.2,21.7,110.8c14.2,36.7,32.5,69.2,54.2,98.3c21.7,28.3,46.7,55.8,76.7,82.5c30,26.7,57.5,49.2,82.5,67.5c25,17.5,51.7,34.2,78.3,50c27.5,15.8,46.7,26.7,58.3,32.5c11.7,5.8,20.8,10,27.5,13.3c5.8,2.5,11.7,3.3,17.5,3.3c5.8,0,11.7-0.8,16.7-3.3c6.7-3.3,16.7-7.5,27.5-13.3c11.7-5.8,30.8-16.7,58.3-32.5c27.5-15.8,54.2-32.5,79.2-50c25-18.3,52.5-40.8,82.5-67.5c29.2-26.7,55-54.2,76.7-82.5c21.7-29.2,39.2-61.7,54.2-98.3c14.2-36.7,21.7-73.3,21.7-110.8v-500C833.3,30.8,829.2,20.8,820.8,12.5z M750.1,417.5H541.7V750H375V417.5H166.7V250.8H375V83.3h166.7v167.5h208.3V417.5z' // eslint-disable-line max-len
    })
  );
};

Advancedrights.propTypes = propTypes;

export default Advancedrights;