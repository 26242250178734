import React, { Fragment } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import './story_details.scss';
import clsx from 'clsx';
import { useModal } from '../../hooks/use_modal/use_modal';
import { Story, STORY_STATUS } from '../story/story.types';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../blocks/icon';
import { PublisherLabel } from '../publisher_label/publisher_label';
import { PUBLISHER_ROLES } from '../publisher_label/publisher_label.types';
import {
  DISPLAY_DATE_TYPES,
  displayDate,
} from '../../pages/stories_manage/story_manage.utils';
import { FEATURE_FLAGS } from '../../blocks/authwrapper/auth_wrapper.types';
import { AuthWrapper } from '../../blocks/authwrapper/auth_wrapper.connect';
import { Tooltip } from '../../blocks/tooltip/tool_tip.component';
import { StoryStatus } from '../../blocks/story_status/story_status.component';
import {
  STORY_MODERATION_MODAL_TYPES,
  StoryModerationModal,
} from '../story_moderation/story_moderation_modal.component';
import { Button, BUTTON_VARIANT } from '../../blocks/button';
import { TOOLTIP_PLACEMENT } from '../../blocks/tooltip/tool_tip.types';

interface StoryDetailsProps extends LocalizeContextProps {
  story: Story;
  onHideStory: () => Promise<void>;
  onShowStory: () => Promise<void>;
}

export const StoryDetailsComponent = ({
  translate,
  story,
  onHideStory,
  onShowStory,
}: StoryDetailsProps) => {
  const { status } = story;
  const createdAt = displayDate(story.createdAt, DISPLAY_DATE_TYPES.DEFAULT);
  const updatedAt = displayDate(story.updatedAt, DISPLAY_DATE_TYPES.DEFAULT);
  const expiredAt = story?.expiredAt
    ? `${displayDate(story.expiredAt, DISPLAY_DATE_TYPES.TIMEZONE)}`
    : null;

  const [Modal, toggle] = useModal();

  const detailStatus = clsx('story_details__row__value', {
    result: !expiredAt,
  });

  return (
    <Fragment>
      <StoryModerationModal
        Modal={Modal}
        story={story}
        onAcceptClick={story.is_hidden ? onShowStory : onHideStory}
        type={
          story.is_hidden
            ? STORY_MODERATION_MODAL_TYPES.SHOW
            : STORY_MODERATION_MODAL_TYPES.HIDE
        }
      />
      <div className="story_details">
        <h2>{translate('stories.form.storyDetails')}</h2>
        <div className="story_details__container">
          <div className="story_details__row--status">
            <p className="story_details__label--status">
              {translate('stories.form.status')}
            </p>
            {!!status && Object.values(STORY_STATUS).includes(status) && (
              <StoryStatus
                status={status}
                Icon={<Icon iconKey={ICON_NAMES.CIRCLE} />}
              />
            )}
          </div>
          <div className="story_details__row--creator">
            <div className="story_details__label--creator">
              {translate('stories.form.creator')}
            </div>
            <div className="story_details__value--creator">
              <PublisherLabel
                avatarSrc={story.publisher.logoSrc}
                name={story.publisher.name}
                type={PUBLISHER_ROLES.PUBLISHER}
              />
            </div>
          </div>
          <AuthWrapper featureFlags={FEATURE_FLAGS.ENABLE_MODERATION}>
            <div className="story_details__row">
              <p className="story_details__label">
                {translate('stories.form.visibility.title')}
              </p>
              <div className="story_details__visibility">
                <p className="story_details__visibility--visibility_text">
                  {story.is_hidden
                    ? `${translate('stories.form.visibility.hidden')}`
                    : `${translate('stories.form.visibility.visible')}`}
                </p>
                <div className="tooltip">
                  <Tooltip
                    id="visibility_tooltip"
                    placement={TOOLTIP_PLACEMENT.TOP}
                    content={
                      story.is_hidden ? (
                        <div>
                          <p className="story_details__heading">
                            {translate('stories.form.visibility.hide')}
                          </p>
                          <p className="story_details__tooltip_content">
                            {translate('stories.form.visibility.hiddenStory')}
                          </p>
                          <ul className="story_details__tooltip__list_container">
                            <li>
                              {translate(
                                'stories.form.visibility.hiddenStoryList1',
                              )}
                            </li>
                            <li>
                              {translate(
                                'stories.form.visibility.hiddenStoryList2',
                                { publisher: story.publisher.name },
                              )}
                            </li>
                            <li>
                              {translate(
                                'stories.form.visibility.hiddenStoryList3',
                              )}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div>
                          <p className="story_details__heading">
                            {translate('stories.form.visibility.show')}
                          </p>
                          <p className="story_details__tooltip_content">
                            {translate('stories.form.visibility.shownStory')}
                          </p>
                        </div>
                      )
                    }
                  >
                    <div>
                      <Icon
                        className="tooltip_icon"
                        iconKey={ICON_NAMES.INFO_CIRCLE}
                        size={ICON_SIZES.SMALL}
                      />
                    </div>
                  </Tooltip>
                </div>
                <Button onClick={toggle} variant={BUTTON_VARIANT.GHOST}>
                  {story.is_hidden
                    ? `${translate('stories.form.visibility.show')}`
                    : `${translate('stories.form.visibility.hide')} `}
                </Button>
              </div>
            </div>
          </AuthWrapper>
          <div className="story_details__row--created_at">
            <p className="story_details__label--created_at">
              {translate('stories.form.createdAt')}
            </p>
            <p className="story_details__value--created_at">{createdAt}</p>
          </div>
          <div className="story_details__row--last_updated">
            <p className="story_details__label--last_updated">
              {translate('stories.form.lastUpdated')}
            </p>
            <p className="story_details__value--last_updated">{updatedAt}</p>
          </div>
          <div className="story_details__row--expiry">
            <div className="story_details__label--expiry">
              <p>{translate('stories.form.expiration.expirationLabel')}</p>
            </div>
            {expiredAt ? (
              <p className="story_details__value--expiry">{expiredAt}</p>
            ) : (
              <p className={detailStatus}>--</p>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export const StoryDetails = withLocalize(StoryDetailsComponent);
