import React from 'react';
import './spinner.scss';

export const Spinner = () => {
  return (
    <div className="spinner">
      <div className="spinner__bars bar1" />
      <div className="spinner__bars bar2" />
      <div className="spinner__bars bar3" />
      <div className="spinner__bars bar4" />
      <div className="spinner__bars bar5" />
      <div className="spinner__bars bar6" />
      <div className="spinner__bars bar7" />
      <div className="spinner__bars bar8" />
    </div>
  );
};
