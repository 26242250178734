import { STORY_STATUS } from '../story/story.types';

export enum STORIES_SEARCH_FILTER {
  QUERY = 'query',
  PUBLISHERS = 'creators',
  THEMES = 'themes',
  CAMPAIGNS = 'campaigns',
  PERSONAS = 'personas',
  STATUS = 'status',
  CREATOR_TYPE = 'creatorType',
  VISIBILITY = 'visibility',
  IS_CHALLENGE_STORY = 'is_challenge_story',
}

export interface StaticFilterDisplayData {
  id: string;
  label: string;
  iconType: string;
  value: boolean;
}

export interface StoriesSearchFilterParams {
  query?: string | null;
  status?: STORY_STATUS[] | null;
  publishers?: string[] | null;
  global_tag_ids?: string[] | null;
  network_tag_ids?: string[] | null;
  is_hidden?: boolean;
  is_challenge_story?: boolean;
}

export type StaticFilterMap = {
  [key in
    | STORIES_SEARCH_FILTER.STATUS
    | STORIES_SEARCH_FILTER.VISIBILITY
    | STORIES_SEARCH_FILTER.CREATOR_TYPE]: StaticFilterDisplayData[];
};
