import { isNotNull } from '../../types/typeguards';
import { STORY_NETWORK_GATEWAY_URLS } from '../http.types';

export type ParamKeys =
  | 'networkId'
  | 'publisherId'
  | 'galleryId'
  | 'uuid' // use this param for story id
  | 'challengeId'
  | 'exportId';

export const replaceUrlParamsWithValues = (
  route: string,
  params: Partial<Record<ParamKeys, string | null>>,
): string => {
  Object.entries(params).forEach(([key, value]) => {
    if (route.indexOf(`/:${key}`) > -1 && isNotNull(value)) {
      route = route.replaceAll(`:${key}`, value);
    }
  });
  return route;
};

export const urlBuilder = ({
  routeString,
  params,
}: {
  routeString: string;
  params?: Partial<Record<ParamKeys, string | null>>;
}): string => {
  let route = routeString;
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!params) {
    route = replaceUrlParamsWithValues(route, params);
    // In some cases, the presence of a parameter can change which handler is called.
    // e.g.
    // /v1/networks/:networkId/publishers/:publisherId/galleries --> calls galleryListHandler
    // /v1/networks/:networkId/publishers/:publisherId/galleries/:galleryId --> calls galleryGetHandler
    // The conditions below adjust the returned URL based on which parameters are included in the request.
    if (
      routeString === STORY_NETWORK_GATEWAY_URLS.GALLERIES &&
      !params.galleryId
    ) {
      route = route.replaceAll('/:galleryId', '');
    }
  }

  return `${process.env.GATEWAY_URL}${route}`;
};
