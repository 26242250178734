import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PlayFill = function PlayFill(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'playFill__icon',
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M6.90588 4.53689C6.50592 4.29987 6 4.58815 6 5.05306V18.9471C6 19.412 6.50592 19.7003 6.90588 19.4633L18.629 12.5163C19.0211 12.2839 19.0211 11.7163 18.629 11.4839L6.90588 4.53689Z',
      fill: color,
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    })
  );
};

PlayFill.propTypes = propTypes;

export default PlayFill;