import { TaxonomyTag } from '../../react/features/taxonomy_section/taxonomy.types';

export interface GlobalTag extends TaxonomyTag {
  type: GLOBAL_TAG_TYPES;
}
export interface GlobalTagState {
  data: GlobalTag[];
  ids: string[];
}
export enum GLOBAL_TAG_TYPES {
  THEME = 'theme',
  SEASON = 'season',
  DURATION = 'duration',
  MARKETS = 'markets',
}
export enum GLOBAL_TAG_ASYNC_TYPES {
  START = 'GLOBAL_TAG/START',
  SUCCESS = 'GLOBAL_TAG/SUCCESS',
  FAILURE = 'GLOBAL_TAG/FAILURE',
}

export interface GetGlobalTagsStart {
  type: GLOBAL_TAG_ASYNC_TYPES.START;
}
export interface GetGlobalTagsSuccess {
  type: GLOBAL_TAG_ASYNC_TYPES.SUCCESS;
  payload: GlobalTag[];
}
export interface GetGlobalTagsFailure {
  type: GLOBAL_TAG_ASYNC_TYPES.FAILURE;
  payload: string;
}

export type GlobalTagActions =
  | GetGlobalTagsStart
  | GetGlobalTagsSuccess
  | GetGlobalTagsFailure;
