import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Code = function Code(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'angledown__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1325.8 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M380.1,856.3c-9.5,9.5-24.1,9.5-33.5,0L7.1,516.7c-9.5-9.5-9.5-24,0-33.5l339.5-339.5c9.5-9.5,24-9.5,33.5,0l36.4,36.4c9.5,9.5,9.5,24,0,33.5L130.2,500l286.3,286.3c9.5,9.5,9.5,24,0,33.5L380.1,856.3z M575.4,983c-3.6,12.4-16.7,19.7-28.4,16l-45.2-12.4c-12.4-3.6-19.7-16.7-16-29.1L757.5,17c3.6-12.4,16.7-19.7,28.4-16l45.1,12.4c12.4,3.6,19.7,16.7,16,29.1L575.4,983zM986.3,856.3c-9.5,9.5-24.1,9.5-33.5,0l-36.4-36.4c-9.5-9.5-9.5-24,0-33.5L1202.6,500L916.3,213.7c-9.5-9.5-9.5-24,0-33.5l36.4-36.4c9.5-9.5,24-9.5,33.5,0l339.5,339.5c9.5,9.5,9.5,24,0,33.5L986.3,856.3z' // eslint-disable-line max-len
    })
  );
};

Code.propTypes = propTypes;

export default Code;