import React, { useEffect } from 'react';
import { DynamicTooltip } from '@crowdriff/flock/lib/DynamicTooltip';
import { Icon } from '@crowdriff/flock/lib/Icon';
import { TranslateFunction } from 'react-localize-redux';
import './utility_menu.scss';
import { useDispatch } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { Avatar } from '../../blocks/avatar/avatar.component';
import { AVATAR_SIZES } from '../../blocks/avatar/avatar.types';
import { Publisher } from '../../../redux/user/user.types';
import { UtilityMenuContent } from './utility_menu_content.connect';
import { UtilityMenuChip } from './utility_menu_chip.component';
import {
  identifyUser,
  initializeUser,
} from '../../../analytics/analytics.utils';

interface UtilityMenuProps {
  publishers: Publisher[];
  currentPublisher: Publisher;
  logout: () => void;
  handlePublisherSwitching: (
    publisher: Publisher,
    navigate: NavigateFunction,
  ) => Promise<void>;
  triggerGetGalleries: () => void;
  translate: TranslateFunction;
  getPublishers: () => void;
  userId: string;
  isPendoInitialized: boolean;
}
export const UtilityMenuComponent = ({
  currentPublisher,
  publishers = [],
  logout,
  handlePublisherSwitching,
  triggerGetGalleries,
  translate,
  getPublishers,
  userId,
  isPendoInitialized,
}: UtilityMenuProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPublishers = async () => {
      await getPublishers();
    };
    fetchPublishers();
  }, []);

  // REQ: (Re-)Identify user when publisher is switched
  useEffect(() => {
    if (currentPublisher.collectionId) {
      if (!isPendoInitialized) {
        initializeUser({
          id: userId,
          collectionId: currentPublisher.collectionId,
          dispatch,
        });
      } else {
        identifyUser({
          id: userId,
          collectionId: currentPublisher.collectionId,
        });
      }
    }
  }, [currentPublisher?.collectionId]);

  return (
    <DynamicTooltip
      content={
        <UtilityMenuContent
          publishers={publishers}
          logout={logout}
          triggerGetGalleries={triggerGetGalleries}
          handlePublisherSwitching={handlePublisherSwitching}
          translate={translate}
          currentPublisher={currentPublisher}
        />
      }
      openOnClick={true}
      placement="bottom-end"
    >
      <div className="utility_menu--button_container">
        <Avatar
          url={currentPublisher.avatarUrl ?? ''}
          alt={`${translate(
            'general.navigation.logoFor',
          )} ${currentPublisher?.label ?? ''}`}
          size={AVATAR_SIZES.SMALL}
        />
        <p className="utility_menu--button">{currentPublisher?.label ?? ''}</p>
        {currentPublisher.isCreatorServices && <UtilityMenuChip />}
      </div>

      <Icon size={12} type="angle-down" fill="white" />
    </DynamicTooltip>
  );
};

UtilityMenuComponent.displayName = 'UtilityMenu';
