import React, { Fragment, VoidFunctionComponent } from 'react';
import './gallery_addtional_info.scss';
import { withLocalize } from 'react-localize-redux';
import WarningIcon from '../../../../assets/warning.svg';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { LANGUAGE_BASE } from '../static_galleries.constants';
import { GalleryAdditionalInfoProps } from './gallery_additional_info.types';
import { rootClass } from './gallery_additional_info.constants';
import { Tooltip } from '../../../blocks/tooltip/tool_tip.component';
import {
  TOOLTIP_ACTIONS,
  TOOLTIP_PLACEMENT,
  TOOLTIP_VARIANT,
} from '../../../blocks/tooltip/tool_tip.types';
import Info from '../../../../assets/info.svg';
import GalleryPreview2 from '../../../../assets/stories-gif-2.mp4';
import GalleryPreview3 from '../../../../assets/stories-gif-3.mp4';
import GalleryPreview4 from '../../../../assets/stories-gif-4.mp4';
import GalleryPreview5 from '../../../../assets/stories-gif-5.mp4';
import GalleryPreview6 from '../../../../assets/stories-gif-6.mp4';
import GalleryPreview7 from '../../../../assets/stories-gif-7.mp4';
import GalleryPreview8 from '../../../../assets/stories-gif-8.mp4';

const GalleryAdditionalInfoComponent: VoidFunctionComponent<GalleryAdditionalInfoProps> = ({
  translate,
  numberStories,
}) => {
  const getTranslation = useTranslateStrings(translate);
  if (numberStories === 0) {
    return <Fragment />;
  }
  if (numberStories < 2) {
    return (
      <div className={`${rootClass}`}>
        <WarningIcon className={`${rootClass}__icon`} />
        <p className={`${rootClass}__text`}>
          {getTranslation(
            `${LANGUAGE_BASE}.galleryDetails.minimumStoryWarning`,
          )}
        </p>
      </div>
    );
  }

  const gifSource = (numberOfStoriesSelected: number) => {
    switch (numberOfStoriesSelected) {
      case 2:
        return GalleryPreview2;
      case 3:
        return GalleryPreview3;
      case 4:
        return GalleryPreview4;
      case 5:
        return GalleryPreview5;
      case 6:
        return GalleryPreview6;
      case 7:
        return GalleryPreview7;
      case 8:
      default:
        return GalleryPreview8;
    }
  };

  return (
    <div className="gallery_preview_tooltip">
      <Tooltip
        id="gallery_preview_tooltip"
        placement={TOOLTIP_PLACEMENT.BOTTOM_END}
        variant={TOOLTIP_VARIANT.GIF_DESKTOP}
        content={gifSource(numberStories)}
        triggerAction={TOOLTIP_ACTIONS.HOVER}
        className={`gallery_preview_tooltip__content`}
        arrow={false}
      >
        <Info className="gallery_preview_tooltip--icon" />
        <p className="gallery_preview_tooltip--description">
          {translate(`${LANGUAGE_BASE}.general.previewTooltip`, {
            numStories: numberStories,
          })}
        </p>
      </Tooltip>
    </div>
  );
};

export const GalleryAdditionalInfo = withLocalize(
  GalleryAdditionalInfoComponent,
);
