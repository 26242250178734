import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import './modal.scss';
import { Dialog, DialogProps } from '@material-ui/core';

export const Modal: FunctionComponent<DialogProps> = (props) => {
  return (
    // eslint-disable-next-line react/destructuring-assignment
    <Dialog {...props} className={clsx(props.className, 'modal')}>
      {
        // eslint-disable-next-line react/destructuring-assignment
        props.children
      }
    </Dialog>
  );
};
