import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PhotoSquare = function PhotoSquare(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'PhotoSquare__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      null,
      React.createElement(
        'g',
        null,
        React.createElement('ellipse', { cx: '509.9', cy: '571.7', rx: '40.8', ry: '41.7' })
      ),
      React.createElement(
        'g',
        null,
        React.createElement('path', {
          d: 'M208.3,208.3v583.3h307.9h275.4V208.3H208.3z M250,250h500v399.5l-52.7-47.2L577.6,724.8l-43.5-44.5L474.8,750H250V250z' // eslint-disable-line max-len
        })
      )
    )
  );
};

PhotoSquare.propTypes = propTypes;

export default PhotoSquare;