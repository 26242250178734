import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var AngleLeftDouble = function AngleLeftDouble(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Angleleftdouble__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 968 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M573.2,939.9L523.1,990c-6,6-15,10-23,10c-8,0-17-4-23.1-10L10,523c-6-6-10-15-10-23s4-17,10-23L477,10c6-6,15-10,23.1-10\t\tc8,0,17,4,23,10l50.1,50.1c6,6,10,15,10,23c0,8-4,17-10,23.1L179.4,500l393.8,393.8c6,6,10,15,10,23.1\t\tC583.2,924.9,579.2,933.9,573.2,939.9z M958,939.9L907.9,990c-6,6-15,10-23,10c-8,0-17-4-23.1-10L394.8,523c-6-6-10-15-10-23s4-17,10-23L861.8,10c6-6,15-10,23.1-10c8,0,17,4,23,10L958,60.1c6,6,10,15,10,23c0,8-4,17-10,23.1L564.2,500L958,893.8\tc6,6,10,15,10,23.1C968,924.9,964,933.9,958,939.9z' // eslint-disable-line max-len
    })
  );
};

AngleLeftDouble.propTypes = propTypes;

export default AngleLeftDouble;