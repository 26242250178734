import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Copy = function Copy(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'copy',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1000,267.9v678.6c0,29.6-24,53.6-53.6,53.6H410.7c-29.6,0-53.6-24-53.6-53.6V785.7H53.6C24,785.7,0,761.7,0,732.1v-375 c0-29.6,17.3-70.9,37.9-91.5L265.6,38c20.6-20.7,61.9-38,91.5-38h232.1c29.6,0,53.6,24,53.6,53.6v183 c21.8-12.8,49.7-22.3,71.4-22.3h232.1C976,214.3,1000,238.3,1000,267.9z M571.4,303.6V71.4H357.1v232.1c0,29.6-24,53.6-53.6,53.6 H71.4v357.1h285.7V571.4c0-29.6,17.3-70.9,37.9-91.5L571.4,303.6z M118.9,285.7h166.9V118.9L118.9,285.7z M928.6,285.7H714.3v232.1 c0,29.6-24,53.6-53.6,53.6H428.6v357.1h500V285.7z M476,500h166.9V333.1L476,500z' // eslint-disable-line max-len
    })
  );
};

Copy.propTypes = propTypes;

export default Copy;