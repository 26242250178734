import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../';

var propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string
  })).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  brandColor: PropTypes.string,
  className: PropTypes.string
};

var propDescriptions = {
  options: 'an array of objects with "label", "icon", and "value" key values.  \n      caption can be an empty string, icon and value are required.',
  value: 'The active value. Corresponds to a value in the options array. Can be false',
  onChange: 'Function to pass the selected option back up to the parent component',
  brandColor: 'The color the checkbox should be when it is selected. Defaults to blue',
  className: 'optional classname for the radio list'
};

var defaultProps = {
  brandColor: '#007BA8',
  className: ''
};

var RadioList = function (_React$Component) {
  _inherits(RadioList, _React$Component);

  function RadioList(props) {
    _classCallCheck(this, RadioList);

    var _this = _possibleConstructorReturn(this, (RadioList.__proto__ || _Object$getPrototypeOf(RadioList)).call(this, props));

    _this.renderCheckboxStyles = _this.renderCheckboxStyles.bind(_this);
    return _this;
  }

  _createClass(RadioList, [{
    key: 'renderCheckboxStyles',
    value: function renderCheckboxStyles(isActive, value) {
      var brandColor = this.props.brandColor;

      if (isActive) {
        return {
          borderColor: brandColor,
          backgroundColor: brandColor
        };
      }
      if (!isActive && !!value) {
        return {
          borderColor: '#c5c8ca'
        };
      }
      return {
        borderColor: '#8a8c90'
      };
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          options = _props.options,
          value = _props.value,
          className = _props.className,
          onChange = _props.onChange;

      return React.createElement(
        'div',
        { className: 'radioList ' + className },
        React.createElement(
          'ul',
          { className: 'radioList__list' },
          options.map(function (option) {
            var isActive = option.value === value;
            var listIcon = React.createElement(Icon, {
              type: option.icon,
              size: 20,
              className: classNames('radioList__optionIcon', {
                'disabled-grey': !isActive && !!value,
                'dark-grey': !isActive && !value || isActive
              })
            });
            return React.createElement(
              'button',
              {
                key: option.icon + '-' + value,
                onClick: function onClick() {
                  return onChange(isActive ? null : option.value);
                },
                className: 'radioList__list--item',
                'aria-label': option.ariaLabel
              },
              React.createElement(
                'div',
                {
                  className: classNames('radioList__itemWrapper', {
                    'disabled-grey': !isActive && !!value
                  })
                },
                option.label,
                React.createElement(
                  'div',
                  { className: 'radioList__iconWrapper' },
                  listIcon,
                  React.createElement(
                    'div',
                    {
                      className: 'radioList__checkbox',
                      style: _this2.renderCheckboxStyles(isActive, value)
                    },
                    isActive && React.createElement(Icon, {
                      type: 'check',
                      size: 8,
                      className: 'white'
                    })
                  )
                )
              )
            );
          })
        )
      );
    }
  }]);

  return RadioList;
}(React.Component);

export default RadioList;


RadioList.propTypes = propTypes;
RadioList.defaultProps = defaultProps;
RadioList.propDescriptions = propDescriptions;