import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PaintRoller = function PaintRoller(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'PaintRoller__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M781.43,100V50a50.15,50.15,0,0,0-50-50h-600a50.15,50.15,0,0,0-50,50V250a50.15,50.15,0,0,0,50,50h600a50.15,50.15,0,0,0,50-50V200h50V400h-500V950a50.15,50.15,0,0,0,50,50h100a50.15,50.15,0,0,0,50-50V500h400V100Z' // eslint-disable-line max-len
    })
  );
};

PaintRoller.propTypes = propTypes;

export default PaintRoller;