import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { CRThunkDispatch, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { AppState } from '../types';
import {
  getPublishersStartAction,
  getPublishersSuccessAction,
  getPublishersFailureAction,
} from '../redux/user/user.actions';
import { selectPublisherIds } from '../redux/user/user.selectors';
import { getErrorMessage } from '../utils';

export interface PublisherResponse {
  id: string;
  collection_id?: string;
  label?: string;
  avatar_url: string;
}

export interface PublishersGetHandlerResponse {
  publishers: PublisherResponse[];
}

export const getPublishers = async (
  dispatch: CRThunkDispatch,
  getState: () => AppState,
) => {
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.PUBLISHERS,
  });
  const state: AppState = getState();

  const publisherIds = selectPublisherIds(state).join();
  dispatch(getPublishersStartAction());
  try {
    const res: AxiosResponse<PublishersGetHandlerResponse> = await axios.get<
      PublishersGetHandlerResponse
    >(url, {
      params: {
        publisher_ids: publisherIds,
      },
    });
    const { data } = res;
    const shapedResponse = data.publishers.map((p) => ({
      publisherId: p.id,
      collectionId: p.collection_id,
      avatarUrl: p.avatar_url,
      label: p.label,
    }));
    dispatch(getPublishersSuccessAction(shapedResponse ?? []));
    return shapedResponse;
  } catch (err) {
    dispatch(getPublishersFailureAction(err));
    throw new Error(`Failed to list publisher : ${getErrorMessage(err)}`);
  }
};
