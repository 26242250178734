import { AppActions } from '../../types';
import {
  DOWNLOAD_ASSETS_BINDING_TYPES,
  EXPORTS_PREPARE_RESPONSE_TYPES,
  DOWNLOAD_RESPONSE_TYPES,
  EXPORTS_TYPES,
  EXPORT_PREPARE_TYPES,
  EXPORT_STORY_TYPES,
  ExportsState,
  DOWNLOAD_LOCATION_TYPES,
} from './exports.types';

const initialState: Readonly<ExportsState> = {
  status: null,
  storyId: null,
  exportId: null,
  type: DOWNLOAD_ASSETS_BINDING_TYPES.MERGE,
  download: {},
  location: DOWNLOAD_LOCATION_TYPES.CURRENT_DEVICE,
};

export const exportsReducer = (
  state: Readonly<ExportsState> = initialState,
  action: AppActions,
): ExportsState => {
  switch (action.type) {
    case EXPORTS_TYPES.EXPORT_BINDING: {
      return {
        ...state,
        type: action.payload
          ? DOWNLOAD_ASSETS_BINDING_TYPES.INDIVIDUAL
          : DOWNLOAD_ASSETS_BINDING_TYPES.MERGE,
      };
    }
    case EXPORT_PREPARE_TYPES.START: {
      return {
        ...state,
        status: EXPORTS_PREPARE_RESPONSE_TYPES.START,
        storyId: action.payload,
      };
    }
    case EXPORT_PREPARE_TYPES.SUCCESS: {
      return {
        ...state,
        status: EXPORTS_PREPARE_RESPONSE_TYPES.SUCCESS,
        exportId: action.payload,
      };
    }
    case EXPORT_PREPARE_TYPES.FAILURE: {
      return {
        ...state,
        status: EXPORTS_PREPARE_RESPONSE_TYPES.FAILURE,
        exportId: null,
      };
    }
    case EXPORT_STORY_TYPES.START: {
      const newDownloadState = { ...state.download };
      newDownloadState[action.payload.storyUuid] = {
        ...newDownloadState[action.payload.storyUuid],
        storyUuid: action.payload.storyUuid,
        exportId: action.payload.exportId,
        status: DOWNLOAD_RESPONSE_TYPES.START,
        bindType: action.payload.bindType,
      };
      return {
        ...state,
        storyId: null,
        exportId: null,
        download: newDownloadState,
      };
    }
    case EXPORT_STORY_TYPES.SUCCESS: {
      const newDownloadState = { ...state.download };
      newDownloadState[action.payload.storyUuid] = {
        ...newDownloadState[action.payload.storyUuid],
        ...action.payload,
      };
      return {
        ...state,
        download: newDownloadState,
      };
    }
    case EXPORT_STORY_TYPES.FAILURE: {
      const newDownloadState = { ...state.download };
      newDownloadState[action.payload] = {
        ...newDownloadState[action.payload],
        status: DOWNLOAD_RESPONSE_TYPES.FAILURE,
      };
      return {
        ...state,
        download: newDownloadState,
      };
    }
    case EXPORTS_TYPES.EXPORT_RESET: {
      const newDownloadState = { ...state.download };
      delete newDownloadState[action.payload];
      return {
        ...state,
        status: null,
        storyId: null,
        exportId: null,
        type: null,
        download: newDownloadState,
      };
    }
    case EXPORTS_TYPES.UPDATE_DOWNLOAD_LOCATION: {
      return {
        ...state,
        location: action.payload as DOWNLOAD_LOCATION_TYPES,
      };
    }
    default: {
      return state;
    }
  }
};
