import React, { VoidFunctionComponent } from 'react';
import { Translate } from 'react-localize-redux';
import './gallery_card_chips.scss';
import {
  HydratedGallery,
  GALLERY_STATUS,
  GALLERY_TYPE,
} from '../../../redux/galleries/galleries.types';
import { FEATURE_FLAGS } from '../../blocks/authwrapper/auth_wrapper.types';
import { Chip } from '../../blocks/chip/chip.component';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../blocks/icon';

interface GalleryChipProps {
  featureFlags: FEATURE_FLAGS | FEATURE_FLAGS[];
  gallery: HydratedGallery;
}

export const GalleryCardChips: VoidFunctionComponent<GalleryChipProps> = ({
  featureFlags,
  gallery,
}) => {
  const chips = [];

  if (
    featureFlags.includes(FEATURE_FLAGS.DYNAMIC_GALLERY) &&
    featureFlags.includes(FEATURE_FLAGS.STATIC_GALLERY)
  ) {
    if (gallery.type === GALLERY_TYPE.DYNAMIC) {
      chips.push(
        <Chip>
          <Icon
            iconKey={ICON_NAMES.REFRESH}
            size={ICON_SIZES.MED}
            ariaHidden={true}
          />
          <Translate id="general.automatic" />
        </Chip>,
      );
    }

    if (gallery.type === GALLERY_TYPE.STATIC) {
      chips.push(
        <Chip>
          <Icon
            iconKey={ICON_NAMES.ADD_TO_GRID}
            size={ICON_SIZES.MED}
            ariaHidden={true}
          />
          <Translate id="general.manual" />
        </Chip>,
      );
    }
  }

  if (gallery.status === GALLERY_STATUS.INTERNAL) {
    chips.push(
      <Chip isDark={true}>
        <Icon
          iconKey={ICON_NAMES.LOCK}
          size={ICON_SIZES.SMALL}
          ariaHidden={true}
        />
        <Translate id="general.crowdRiffAdmin" />
      </Chip>,
    );
  }

  return <div className="gallery_card_chip--container">{chips}</div>;
};
