import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var SquareArrowUpRight = function SquareArrowUpRight(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      height: size,
      viewBox: '0 0 16 16',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg'
    },
    React.createElement('path', {
      d: 'M14 2H14.5C14.5 1.72386 14.2761 1.5 14 1.5V2ZM10 1.5C9.72386 1.5 9.5 1.72386 9.5 2C9.5 2.27614 9.72386 2.5 10 2.5V1.5ZM7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355C7.84171 8.54882 8.15829 8.54882 8.35355 8.35355L7.64645 7.64645ZM13.5 6C13.5 6.27614 13.7239 6.5 14 6.5C14.2761 6.5 14.5 6.27614 14.5 6H13.5ZM13.1667 9.25925C13.1667 8.9831 12.9428 8.75925 12.6666 8.75926C12.3905 8.75927 12.1667 8.98314 12.1667 9.25928L13.1667 9.25925ZM12.6668 13.5302H13.1668V13.5302L12.6668 13.5302ZM6.74074 3.83333C7.01688 3.83333 7.24074 3.60948 7.24074 3.33333C7.24074 3.05719 7.01688 2.83333 6.74074 2.83333V3.83333ZM14 1.5H10V2.5H14V1.5ZM13.6464 1.64645L7.64645 7.64645L8.35355 8.35355L14.3536 2.35355L13.6464 1.64645ZM13.5 2V6H14.5V2H13.5ZM12.1667 9.25928L12.1668 13.5302L13.1668 13.5302L13.1667 9.25925L12.1667 9.25928ZM12.1668 13.5302C12.1668 13.5276 12.1671 13.5276 12.1667 13.5288C12.1663 13.53 12.1666 13.5282 12.17 13.5242C12.1739 13.5198 12.1815 13.5128 12.1935 13.5071C12.2064 13.501 12.2167 13.5 12.2195 13.5V14.5C12.8435 14.5 13.1668 13.9633 13.1668 13.5302H12.1668ZM12.2195 13.5H2.41598V14.5H12.2195V13.5ZM2.41598 13.5C2.43489 13.5 2.44771 13.5037 2.45534 13.5068C2.46331 13.51 2.47048 13.5145 2.47703 13.5206C2.48403 13.5272 2.49059 13.536 2.49514 13.5463C2.49996 13.5573 2.5 13.5642 2.5 13.563H1.5C1.5 14.1441 1.9742 14.5 2.41598 14.5V13.5ZM2.5 13.563V3.81385H1.5V13.563H2.5ZM2.5 3.81385C2.5 3.82508 2.49834 3.82487 2.5012 3.81656C2.5038 3.80899 2.50713 3.80368 2.50834 3.8021C2.50939 3.80072 2.5039 3.80851 2.48805 3.81731C2.47993 3.82182 2.46996 3.82607 2.45856 3.82908C2.44705 3.83213 2.4363 3.83333 2.42734 3.83333V2.83333C2.10128 2.83333 1.85886 3.00537 1.71426 3.19428C1.57676 3.37391 1.5 3.60022 1.5 3.81385H2.5ZM2.42734 3.83333H6.74074V2.83333H2.42734V3.83333Z',
      fill: color
    })
  );
};

SquareArrowUpRight.propTypes = propTypes;

export default SquareArrowUpRight;