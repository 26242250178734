import React, { VoidFunctionComponent } from 'react';
import {
  TranslateFunction,
  LocalizeContextProps,
  withLocalize,
} from 'react-localize-redux';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';
import { HydratedGallery } from '../../../redux/galleries/galleries.types';
import './gallery_list_grid_card.scss';
import {
  displayGalleryTags,
  copyToClipboard,
} from './gallery_list_grid_card.utils';
import { AppState, ROUTES } from '../../../types';
import { NETWORK_TAG_TYPES } from '../../../redux/network_tags/network_tags.types';
import { generateEmbedCode } from '../gallery_embed/gallery_embed.utils';
import { AuthWrapper } from '../../blocks/authwrapper/auth_wrapper.connect';
import { FEATURE_FLAGS } from '../../blocks/authwrapper/auth_wrapper.types';
import { triggerHydrateAction } from '../../pages/galleries_list/galleries_list.actions';
import { copyGallery } from '../../../http/gallery_copy.thunk';
import { GalleryListGridCardDeleteModal } from './delete_modal/gallery_list_grid_card_delete_modal.connect';
import { useModal } from '../../hooks/use_modal/use_modal';
import { Button, BUTTON_VARIANT } from '../../blocks/button';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../blocks/icon';
import { Menu, MenuItem } from '../../blocks/menu';
import { userHasAccess } from '../../blocks/authwrapper/auth_wrapper.utils';
import { GalleryCardChips } from '../gallery_card_chips/gallery_card_chips.component';
import { useToggle } from '../../hooks/useToggle';
import { useToast } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../blocks/toast';

export interface GalleryListGridCardProps extends LocalizeContextProps {
  translate: TranslateFunction;
  gallery: HydratedGallery;
  currentPublisherFeatureFlags: FEATURE_FLAGS | FEATURE_FLAGS[];
}

const GalleryListGridCardComponent: VoidFunctionComponent<GalleryListGridCardProps> = ({
  gallery,
  translate,
  currentPublisherFeatureFlags,
}) => {
  const dispatch = useDispatch<ThunkDispatch<AppState, {}, AnyAction>>();
  const { showToast } = useToast();
  const embedCodeText = generateEmbedCode(gallery.id);
  const [Modal, toggle] = useModal();
  const getTranslation = useTranslateStrings(translate);
  const navigate = useNavigate();
  const {
    toggleState: menuState,
    SetStateToggle: toggleMenu,
    SetStateFalse: closeMenu,
  } = useToggle(false);

  const embedCopy = {
    onSuccess: () =>
      showToast({
        message: `${getTranslation('galleries.embed.copied')}`,
        type: TOAST_TYPES.SUCCESS,
      }),
    onFailure: () =>
      showToast({
        message: `${getTranslation('general.copyFailed')}`,
        type: TOAST_TYPES.ERROR,
      }),
  };

  const copyGalleryId = {
    onSuccess: () =>
      showToast({
        message: `${getTranslation('galleries.embed.copiedId')}`,
        type: TOAST_TYPES.SUCCESS,
      }),
    onFailure: () =>
      showToast({
        message: `${getTranslation('galleries.embed.error')}`,
        type: TOAST_TYPES.ERROR,
      }),
  };

  const onCopyGalleryId = async () => {
    try {
      const newGallery = await dispatch(copyGallery(gallery.id));
      const newGalleryURL = `${ROUTES.GALLERIES_LIST}/${newGallery.gallery_id}`;

      showToast({
        type: TOAST_TYPES.SUCCESS,
        message: getTranslation(`galleries.embed.copiedGallery`, {
          galleryLabel: gallery.label,
        }),
        customActionCTA: (
          <Button
            onClick={() => navigate(newGalleryURL)}
            variant={BUTTON_VARIANT.GHOST}
          >
            {getTranslation('galleries.embed.copiedGalleryURL')}
          </Button>
        ),
      });
      dispatch(triggerHydrateAction());
    } catch (err) {
      showToast({
        message: `${getTranslation('galleries.embed.error')}`,
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  const featureFlags:
    | FEATURE_FLAGS
    | FEATURE_FLAGS[] = currentPublisherFeatureFlags;

  const isCopyGalleryEnabled = userHasAccess(
    featureFlags,
    FEATURE_FLAGS.COPY_GALLERY_ID,
  );
  let copyGalleryMenuItem: MenuItem[] = [];
  if (isCopyGalleryEnabled) {
    copyGalleryMenuItem = [
      {
        id: 3,
        text: getTranslation('galleries.embed.copyId'),
        action: () =>
          copyToClipboard(
            gallery.id,
            copyGalleryId.onSuccess,
            copyGalleryId.onFailure,
          ),
      },
    ];
  }

  const menuItems: MenuItem[] = [
    {
      id: 1,
      text: getTranslation('galleries.listGrid.editGallery'),
      url: generatePath(ROUTES.GALLERIES_EDIT, {
        id: gallery.id,
      }),
    },
    {
      id: 2,
      text: getTranslation('galleries.listGrid.copyEmbedCode'),
      action: () =>
        copyToClipboard(
          embedCodeText,
          embedCopy.onSuccess,
          embedCopy.onFailure,
        ),
    },
    ...copyGalleryMenuItem,
    {
      id: 4,
      text: getTranslation('galleries.listGrid.copyGallery'),
      action: onCopyGalleryId,
    },
    {
      id: 5,
      text: getTranslation('galleries.listGrid.deleteGallery'),
      action: toggle,
    },
  ];

  return (
    <React.Fragment>
      <GalleryListGridCardDeleteModal
        gallery={gallery}
        Modal={Modal}
        toggle={toggle}
      />
      <div className="gallery_list_grid_card">
        <div className="gallery_list_grid_card--column">
          <Link
            aria-label={`${translate('galleries.listGrid.nameAriaLabel', {
              name: gallery.label,
            })}`}
            to={generatePath(ROUTES.GALLERIES_EDIT, {
              id: gallery.id,
            })}
            className="gallery_list_grid_card--gallery_title"
          >
            {gallery.label}
          </Link>
          <GalleryCardChips featureFlags={featureFlags} gallery={gallery} />
        </div>
        <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_CAMPAIGN_FIELD}>
          <p className="gallery_list_grid_card--column">
            {displayGalleryTags(
              gallery.type,
              gallery.network_tags.filter(
                (tag) => tag.type === NETWORK_TAG_TYPES.CAMPAIGN,
              ),
              getTranslation('galleries.listGrid.none'),
            )}
          </p>
        </AuthWrapper>
        {/* Themes */}
        <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_THEME_FIELD}>
          <p className="gallery_list_grid_card--column">
            {displayGalleryTags(
              gallery.type,
              gallery.global_tags,
              getTranslation('galleries.listGrid.any'),
            )}
          </p>
        </AuthWrapper>
        {/* Locations */}
        <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_LOCATION_FIELD}>
          <p className="gallery_list_grid_card--column">
            {displayGalleryTags(
              gallery.type,
              gallery.locations,
              getTranslation('galleries.listGrid.any'),
            )}
          </p>
        </AuthWrapper>
        {/* Personas */}
        <AuthWrapper featureFlags={FEATURE_FLAGS.GALLERY_PERSONA_FIELD}>
          <p className="gallery_list_grid_card--column">
            {displayGalleryTags(
              gallery.type,
              gallery.network_tags.filter(
                (tag) => tag.type === NETWORK_TAG_TYPES.PERSONA,
              ),
              getTranslation('galleries.listGrid.none'),
            )}
          </p>
        </AuthWrapper>
        <Menu
          list={menuItems}
          isOpen={menuState}
          onClose={closeMenu}
          className="gallery_list_grid_card--menu_wrapper gallery_list_grid_card--column"
        >
          <Button variant={BUTTON_VARIANT.GHOST} onClick={toggleMenu}>
            <Icon iconKey={ICON_NAMES.ELLIPSIS} size={ICON_SIZES.LARGE} />
          </Button>
        </Menu>
      </div>
    </React.Fragment>
  );
};

export const GalleryListGridCard = withLocalize(GalleryListGridCardComponent);
