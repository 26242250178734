import React from 'react';
import { IconProps } from '../icon.types';

export const House = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.0888672 8.78662C0.0888672 9.12695 0.354492 9.45068 0.802734 9.45068C1.03516 9.45068 1.22607 9.32617 1.40039 9.18506L9.70947 2.2124C9.89209 2.04639 10.1162 2.04639 10.2988 2.2124L18.6079 9.18506C18.7739 9.32617 18.9648 9.45068 19.1973 9.45068C19.5874 9.45068 19.9028 9.20996 19.9028 8.81152C19.9028 8.5625 19.8198 8.39648 19.6455 8.24707L17.3213 6.28809V2.96777C17.3213 2.60254 17.0889 2.37012 16.7236 2.37012H15.8354C15.4785 2.37012 15.2378 2.60254 15.2378 2.96777V4.53662L11.0044 0.983887C10.3901 0.460938 9.62646 0.460938 9.00391 0.983887L0.354492 8.24707C0.171875 8.39648 0.0888672 8.5957 0.0888672 8.78662ZM2.67871 16.1909C2.67871 17.3447 3.37598 18.0254 4.54639 18.0254H15.4619C16.6323 18.0254 17.3213 17.3447 17.3213 16.1909V9.6167L10.498 3.89746C10.1826 3.63184 9.80908 3.64014 9.50195 3.89746L2.67871 9.6167V16.1909ZM12.1333 16.689H7.8501V11.2021C7.8501 10.8203 8.10742 10.5713 8.48926 10.5713H11.5024C11.8843 10.5713 12.1333 10.8203 12.1333 11.2021V16.689Z" />
    </svg>
  );
};
