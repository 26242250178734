import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import './header.scss';

export interface HeaderProps {
  className?: string;
}
export const Header: FunctionComponent<HeaderProps> = ({
  children,
  className,
}) => {
  return (
    <div className={clsx('header__root', className)}>
      <div className="header__content">{children}</div>
    </div>
  );
};
