import axios from './axios_config';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { getErrorMessage } from '../utils';

interface LogoutResponse {
  status: string;
}

export const logout: () => Promise<void> = async () => {
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.LOGOUT,
  });

  try {
    await axios.post(url).then(() => {
      window.location.href = `${process.env.ID_URL}/?${encodeURIComponent(
        `redirect`,
      )}=${encodeURIComponent(`${process.env.APP_URL}`)}`;
    });
  } catch (err) {
    throw new Error(`Failed to logout: ${getErrorMessage(err)}`);
  }
};
