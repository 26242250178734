import React from 'react';
import { IconProps } from '../icon.types';

export const Star = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.31396 18.0752C4.6543 18.3408 5.08594 18.2495 5.60059 17.876L9.9917 14.647L14.3911 17.876C14.9058 18.2495 15.3291 18.3408 15.6777 18.0752C16.0181 17.8179 16.0928 17.3945 15.8853 16.7886L14.1504 11.6255L18.583 8.43799C19.0977 8.07275 19.3052 7.69092 19.1724 7.27588C19.0396 6.87744 18.6494 6.67822 18.0103 6.68652L12.5732 6.71973L10.9214 1.53174C10.7222 0.91748 10.4233 0.610352 9.9917 0.610352C9.56836 0.610352 9.26953 0.91748 9.07031 1.53174L7.41846 6.71973L1.98145 6.68652C1.34229 6.67822 0.952148 6.87744 0.819336 7.27588C0.678223 7.69092 0.894043 8.07275 1.40869 8.43799L5.84131 11.6255L4.10645 16.7886C3.89893 17.3945 3.97363 17.8179 4.31396 18.0752Z" />
    </svg>
  );
};
