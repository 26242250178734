import React from 'react';
import { IconProps } from '../icon.types';

export const TextAlignLeft = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.25537 4.08838H17.7197C18.0684 4.08838 18.3589 3.81445 18.3589 3.45752C18.3589 3.10889 18.0684 2.83496 17.7197 2.83496H2.25537C1.90674 2.83496 1.63281 3.10889 1.63281 3.45752C1.63281 3.81445 1.90674 4.08838 2.25537 4.08838ZM2.25537 8.43799H11.5938C11.9424 8.43799 12.2246 8.16406 12.2246 7.80713C12.2246 7.4585 11.9424 7.18457 11.5938 7.18457H2.25537C1.90674 7.18457 1.63281 7.4585 1.63281 7.80713C1.63281 8.16406 1.90674 8.43799 2.25537 8.43799ZM2.25537 12.7876H17.7197C18.0684 12.7876 18.3589 12.5054 18.3589 12.1567C18.3589 11.8081 18.0684 11.5342 17.7197 11.5342H2.25537C1.90674 11.5342 1.63281 11.8081 1.63281 12.1567C1.63281 12.5054 1.90674 12.7876 2.25537 12.7876ZM2.25537 17.1289H11.5938C11.9424 17.1289 12.2246 16.855 12.2246 16.5063C12.2246 16.1577 11.9424 15.8755 11.5938 15.8755H2.25537C1.90674 15.8755 1.63281 16.1577 1.63281 16.5063C1.63281 16.855 1.90674 17.1289 2.25537 17.1289Z" />
    </svg>
  );
};
