import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

var propTypes = {
  color: PropTypes.string,
  rightPad: PropTypes.bool,
  noPad: PropTypes.bool,
  className: PropTypes.string
};

var defaultProps = {
  color: 'white',
  className: ''
};

var propDescriptions = {
  color: 'String that will be converted into a className that dictates the color of the spinner, must be CR branded color', // eslint-disable-line
  rightPad: 'Adds padding to the right side of the spinner',
  noPad: 'Removes all padding from the spinner',
  className: 'String that adds a custom class name'
};

/**
* A little spinner to show inline on buttons while a thing is happening
*
* color - the color the spinner should be (make sure it's in the css)
* rightPad - a boolean convenience function to add the right-pad class
*/
export default function Spinner(props) {
  var color = props.color,
      rightPad = props.rightPad,
      noPad = props.noPad,
      className = props.className;

  return React.createElement('div', {
    className: classNames('spinnerLoader', className, color, {
      'spinnerLoader--right-pad': rightPad,
      'spinnerLoader--no-pad': noPad
    })
  });
}

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;
Spinner.propDescriptions = propDescriptions;