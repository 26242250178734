import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var ArrowDiagonal = function ArrowDiagonal(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'arrow__diagonal',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1061.26 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M365.16,12.4a38.73,38.73,0,0,0,30.8,65L924.73,82.2,12.61,932.56C.72,945.37-2.43,959.81,1.77,973a38.76,38.76,0,0,0,65.61,14.32L979.49,137,984,666.15a38.77,38.77,0,1,0,77.44,3.85l-.21-629.3A38.72,38.72,0,0,0,1020.57.05L392.11,0A38.73,38.73,0,0,0,365.16,12.4Z' // eslint-disable-line max-len
    })
  );
};

ArrowDiagonal.propTypes = propTypes;

export default ArrowDiagonal;