import { connect } from 'react-redux';
import { TOSUserAcceptanceUpdate } from '../../../http/tos-acceptance-set.thunks';
import { AppThunkDispatch } from '../../../types';
import { TermsOfServiceModal } from './terms_of_service_modal.component';

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onAcceptTOS: () => dispatch(TOSUserAcceptanceUpdate()),
});
export const TermsOfServiceConnect = connect(
  null,
  mapDispatchToProps,
)(TermsOfServiceModal);
