import { connect } from 'react-redux';
import { selectCurrentPublisherFeatureFlags } from '../../../redux/user/user.selectors';
import { AuthWrapperComponent } from './auth_wrapper';
import { AppState } from '../../../types';
import { userHasAccess } from './auth_wrapper.utils';
import {
  FEATURE_FLAG_COMPARISON_TYPES,
  FEATURE_FLAGS,
} from './auth_wrapper.types';

interface FeatureFlagsProps {
  featureFlags: FEATURE_FLAGS | FEATURE_FLAGS[];
  comparisonType?: FEATURE_FLAG_COMPARISON_TYPES;
  exclude?: boolean;
}

const mapStateToProps = (state: AppState, ownProps: FeatureFlagsProps) => ({
  hasAccess: userHasAccess(
    selectCurrentPublisherFeatureFlags(state),
    ownProps.featureFlags,
    ownProps.comparisonType,
    ownProps.exclude,
  ),
});

export const AuthWrapper = connect(mapStateToProps)(AuthWrapperComponent);
