import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { CRThunkDispatch, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import { AppState } from '../types';
import {
  globalTagsStartAction,
  globalTagsSuccessAction,
  globalTagsFailureAction,
} from '../redux/galleries/gallery_form.actions';
import { selectCurrentNetworkId } from '../redux/user/user.selectors';
import { GlobalTag } from '../redux/global_tags/global_tags.types';
import { getErrorMessage } from '../utils';

interface GetGlobalTagsResponse {
  global_tags: GlobalTag[];
}

export const getGlobalTags = () => async (
  dispatch: CRThunkDispatch,
  getState: () => AppState,
): Promise<void> => {
  const state: AppState = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.GLOBAL_TAGS,
    params: {
      networkId,
    },
  });
  dispatch(globalTagsStartAction());
  try {
    const res: AxiosResponse<GetGlobalTagsResponse> = await axios.get<
      GetGlobalTagsResponse
    >(url, {
      params: { page_size: '300' },
    });

    dispatch(globalTagsSuccessAction(res.data.global_tags ?? []));
  } catch (err) {
    dispatch(globalTagsFailureAction(err));
    throw new Error(`Failed to get global tags: ${getErrorMessage(err)}`);
  }
};
