import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

var propDescriptions = {
  children: 'A node specifying the contents of the button',
  onClick: 'A function to be fired on button click',
  className: 'A string of any additional classes to be added to the button',
  isDisabled: 'Disables the button if true',
  isCard: 'Attach this prop for a card style button',
  isLink: 'If the button is a link',
  isSecondary: 'Attach this prop for a secondary-style button',
  styles: 'an Object containing any inline styles to be attached to the button',
  type: 'A string specifying if it is a button button or submit button',
  ariaLabel: 'A string that will be added as an aria label'
};

var propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSecondary: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  ariaLabel: PropTypes.string
};

var defaultProps = {
  type: 'button'
};

var Button = function (_React$Component) {
  _inherits(Button, _React$Component);

  function Button(props) {
    _classCallCheck(this, Button);

    var _this = _possibleConstructorReturn(this, (Button.__proto__ || _Object$getPrototypeOf(Button)).call(this, props));

    _this.handleClick = _this.handleClick.bind(_this);
    return _this;
  }

  _createClass(Button, [{
    key: 'handleClick',
    value: function handleClick(e) {
      var _props = this.props,
          onClick = _props.onClick,
          type = _props.type,
          isDisabled = _props.isDisabled;
      // if it's a submit button, let the form submit handle it.

      if (type === 'submit') return;
      if (isDisabled) return;
      if (!onClick) return;
      e.preventDefault();
      onClick(e);
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          type = _props2.type,
          isDisabled = _props2.isDisabled,
          children = _props2.children,
          className = _props2.className,
          ariaLabel = _props2.ariaLabel;


      var buttonClasses = classNames('button', className, {
        disabled: isDisabled,
        secondary: this.props.isSecondary
      });

      return React.createElement(
        'button',
        {
          type: type,
          className: buttonClasses,
          disabled: isDisabled,
          onClick: this.handleClick,
          'aria-label': ariaLabel
        },
        children
      );
    }
  }]);

  return Button;
}(React.Component);

export default Button;


Button.propDescriptions = propDescriptions;
Button.propTypes = propTypes;
Button.defaultProps = defaultProps;