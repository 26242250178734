import React, { VoidFunctionComponent } from 'react';
import './gallery_empty_state.scss';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import ExampleGallery from '../../../../assets/example-gallery.jpg';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { EmptyState } from '../../../blocks/empty_state';
import { CreateGalleryBlock } from '../../gallery_create';

export interface GalleryEmptyStateProps extends LocalizeContextProps {
  staticGalleryCreateClassName?: string;
}
const GalleryEmptyStateComponent: VoidFunctionComponent<GalleryEmptyStateProps> = ({
  translate,
}) => {
  const getTranslation = useTranslateStrings(translate);
  const emptyStateCommonProps = {
    title: getTranslation('galleries.emptyState.title'),
    description: getTranslation('galleries.emptyState.description'),
    graphic: ExampleGallery,
    graphicAlt: getTranslation('galleries.emptyState.graphicAlt'),
    className: 'gallery_list--empty_state',
  };

  return (
    <EmptyState
      {...emptyStateCommonProps}
      actionContent={<CreateGalleryBlock />}
    />
  );
};

export const GalleryEmptyState = withLocalize(GalleryEmptyStateComponent);
