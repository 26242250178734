import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Home = function Home(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Home__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 999.1 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M999.1,499.5L499.5,0L0,499.5L29.5,529l53.8-53.8L82.5,1000h0.8h332.8V666.4h166.8V1000h333.6V475.9l53.1,53.1L999.1,499.5z M874.8,958.3H624.6V624.7H374.4v333.6H125V433.5L499.5,58.9l375.3,375.3V958.3z' // eslint-disable-line max-len
    })
  );
};

Home.propTypes = propTypes;

export default Home;