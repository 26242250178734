import { AppActions } from '../../types';
import {
  LOCATIONS_ASYNC_TYPES,
  Location,
  LocationState,
  LOCATIONS_TYPE_TYPES,
} from './locations.types';

const initialState: Readonly<LocationState> = {
  data: [],
  ids: [],
  types: [],
};
export const locationsReducer = (
  state: Readonly<LocationState> = initialState,
  action: AppActions,
): LocationState => {
  switch (action.type) {
    case LOCATIONS_ASYNC_TYPES.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        ids: action.payload.map((location: Location) => location.id),
      };
    }
    case LOCATIONS_TYPE_TYPES.SUCCESS: {
      return {
        ...state,
        types: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
