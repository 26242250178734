import _extends from 'babel-runtime/helpers/extends';
import _objectWithoutProperties from 'babel-runtime/helpers/objectWithoutProperties';
import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// file naming: first letter is captial, no hyphens or camel casing
import * as Icons from './icons';
// list of availble icons
import { iconMap, legacyMap } from './icon-map';

var propDescriptions = {
  size: 'Number that dictates the height of an icon in pixels.',
  fill: 'String that indicates the color of the icon, either fill or stroke value. Must be a valid css color or value for the "fill" style rule. Overrides any css colors', // eslint-disable-line max-len
  color: 'String that will be used as a classname on the wrapping div - matches a color variable',
  className: 'String that will be used as a classname on the wrapping div. There are many default class names in place for colour (ie \'red\') that can be used to quickly set both, see icon.scss for more details.', // eslint-disable-line max-len
  type: 'string specifying which icon should be displayed'
};

var propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.string
};

var defaultProps = {
  size: 16,
  fill: 'currentColor'
};

var IconWrapper = function (_PureComponent) {
  _inherits(IconWrapper, _PureComponent);

  function IconWrapper(props) {
    _classCallCheck(this, IconWrapper);

    return _possibleConstructorReturn(this, (IconWrapper.__proto__ || _Object$getPrototypeOf(IconWrapper)).call(this, props));
  }

  _createClass(IconWrapper, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          type = _props.type,
          size = _props.size,
          color = _props.color,
          className = _props.className,
          fill = _props.fill,
          rest = _objectWithoutProperties(_props, ['type', 'size', 'color', 'className', 'fill']);

      // Warn on invalid icon type in development


      if (process.env.NODE_ENV === 'development' && !iconMap[type] && !legacyMap[type]) {
        console.warn('Invalid flock icon type: ' + type);
        return null;
      }

      var iconKey = legacyMap[type] ? iconMap[legacyMap[type]] : iconMap[type];

      if (process.env.NODE_ENV === 'development' && legacyMap[type]) {
        console.warn('Deprecated flock icon type: ' + type + '. Use ' + legacyMap[type] + ' instead');
      }

      var Icon = Icons[iconKey];
      // Avoid runtime errors by returning null for an invalid icon type
      if (!Icon) return null;
      return React.createElement(
        'div',
        _extends({
          className: classNames('iconWrapper', className, color, 'cr__icon-' + type)
        }, rest),
        React.createElement(Icon, {
          size: size + 'px',
          color: fill
        })
      );
    }
  }]);

  return IconWrapper;
}(PureComponent);

export default IconWrapper;


IconWrapper.propTypes = propTypes;
IconWrapper.defaultProps = defaultProps;
IconWrapper.propDescriptions = propDescriptions;