export enum TOOLTIP_VARIANT {
  TEXT = 'text',
  GIF_DESKTOP = 'gif-desktop',
  GIF_MOBILE = 'gif-mobile',
}

export enum TOOLTIP_PLACEMENT {
  BOTTOM_END = 'bottom-end',
  BOTTOM_START = 'bottom-start',
  BOTTOM = 'bottom',
  LEFT_END = 'left-end',
  LEFT_START = 'left-start',
  LEFT = 'left',
  RIGHT_END = 'right-end',
  RIGHT_START = 'right-start',
  RIGHT = 'right',
  TOP_END = 'top-end',
  TOP_START = 'top-start',
  TOP = 'top',
}

export enum TOOLTIP_ACTIONS {
  CLICK = 'click',
  HOVER = 'hover',
}

export enum OPEN_ACTION_TYPE {
  TOGGLE = 'toggle',
  CLOSE = 'close',
}
