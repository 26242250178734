

import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var InfoI = function InfoI(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'cr__icon__info-i',
      height: size,
      fill: color,
      viewBox: '0 0 3 14',
      'aria-hidden': true,
      className: 'cr__icon',
      xmlns: 'http://www.w3.org/2000/svg'
    },
    React.createElement('path', { d: 'M2.71053 4.51676V13.5H0.289474V4.51676H2.71053ZM3 1.96381C3 2.16711 2.95906 2.3559 2.87719 2.53016C2.79532 2.70442 2.68421 2.85836 2.54386 2.99196C2.40936 3.12556 2.24854 3.23012 2.0614 3.30563C1.88012 3.38114 1.68421 3.4189 1.47368 3.4189C1.27485 3.4189 1.0848 3.38114 0.903509 3.30563C0.72807 3.23012 0.573099 3.12556 0.438597 2.99196C0.304094 2.85836 0.195906 2.70442 0.114035 2.53016C0.0380117 2.3559 0 2.16711 0 1.96381C0 1.7605 0.0380117 1.57172 0.114035 1.39745C0.195906 1.21738 0.304094 1.06055 0.438597 0.926944C0.573099 0.793342 0.72807 0.688785 0.903509 0.613271C1.0848 0.537757 1.27485 0.5 1.47368 0.5C1.68421 0.5 1.88012 0.537757 2.0614 0.613271C2.24854 0.688785 2.40936 0.793342 2.54386 0.926944C2.68421 1.06055 2.79532 1.21738 2.87719 1.39745C2.95906 1.57172 3 1.7605 3 1.96381Z' })
  );
};

InfoI.propTypes = propTypes;

export default InfoI;