import { Dispatch } from 'react';
import { OPEN_ACTION_TYPE } from './tool_tip.types';

/**
 * Returns the props for the tooltip trigger button which are conditional on the
 * tooltip trigger action type.
 * @param interactive True if the tooltip is a hover based tooltip.
 * @param onToggle Handler for toggling tooltip state
 * @param dispatch Dispatch for updating internal tooltip state
 */
export const getExtraButtonProps = (
  interactive: boolean,
  onToggle: (state: boolean) => void,
  dispatch: Dispatch<OPEN_ACTION_TYPE>,
) => {
  if (interactive) {
    return {};
  }
  return {
    // This needs to be fired off async or it is possible for the click away listener
    // to fire after this change is made, which leads to race conditions and unpredictable
    // results
    onClick: () => setTimeout(() => dispatch(OPEN_ACTION_TYPE.TOGGLE), 0),
  };
};

/**
 * Returns the props for the tooltip component which are conditional on the tooltip
 * trigger action type.
 * @param interactive True if the tooltip is a hover based tooltip.
 * @param open The internal state describing if the tooltip is currently open.
 * @param onOpen Handler which runs on tooltip open.
 * @param onClose Handler which runs on tooltip close.
 */
export const getExtraTooltipProps = (
  interactive: boolean,
  open: boolean,
  onOpen?: () => void,
  onClose?: () => void,
) => {
  if (interactive) {
    return { onOpen, onClose };
  }
  return { open, interactive: true };
};

/**
 * Reducer for updating internal tooltip 'open' state
 * @param state The state value on dispatch fire.
 * @param action The type of action to perform as well as any side effect
 * functions to run.
 */
export const openReducer = (state: boolean, action: OPEN_ACTION_TYPE) => {
  switch (action) {
    case 'toggle':
      return !state;
    case 'close':
      return false;
    default:
      return state;
  }
};
