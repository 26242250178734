import React from 'react';
import { IconProps } from '../icon.types';

export const ArrowDown = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 2.1709C9.56836 2.1709 9.26123 2.46973 9.26123 2.90137V13.8335L9.32764 15.6099L6.8374 12.8789L4.94482 11.0195C4.82031 10.8867 4.62109 10.8203 4.42188 10.8203C4.00684 10.8203 3.70801 11.1357 3.70801 11.5425C3.70801 11.7417 3.78271 11.916 3.94043 12.082L9.44385 17.6021C9.60156 17.7681 9.79248 17.8511 10 17.8511C10.2075 17.8511 10.3984 17.7681 10.5562 17.6021L16.0679 12.082C16.2256 11.916 16.292 11.7417 16.292 11.5425C16.292 11.1357 15.9932 10.8203 15.5781 10.8203C15.3789 10.8203 15.188 10.8867 15.0552 11.0195L13.1626 12.8789L10.6807 15.6016L10.7388 13.8335V2.90137C10.7388 2.46973 10.4316 2.1709 10 2.1709Z" />
    </svg>
  );
};
