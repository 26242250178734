import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var ArrowUpRightArrowDownLeft = function ArrowUpRightArrowDownLeft(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg'
    },
    React.createElement('path', {
      d: 'M15 9L20 4M20 4V8M20 4H16M9 15L4 20M4 20V16M4 20H8',
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    })
  );
};

ArrowUpRightArrowDownLeft.propTypes = propTypes;

export default ArrowUpRightArrowDownLeft;