import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Trash = function Trash(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'trash__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 833.3 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M645.1,877.6h-91.8v-545h91.8V877.6z M461.5,877.6h-91.8v-545h91.8V877.6z M277.8,877.6H186v-545h91.8V877.6zM613.7,106.8V0H218.5v106.8H0v214.9h81.4V1000H752V321.7h81.4V106.8H613.7z' // eslint-disable-line max-len
    })
  );
};

Trash.propTypes = propTypes;

export default Trash;