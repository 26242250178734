import { GlobalTag } from '../global_tags/global_tags.types';
import { NetworkTag } from '../network_tags/network_tags.types';
import { Location } from '../locations/locations.types';
import { Publisher } from '../user/user.types';
import { Story } from '../../react/features/story/story.types';
import { GALLERIES_LOAD_TYPES } from '../../react/pages/galleries_list/galleries_list.types';

export enum GALLERY_STATUS {
  INTERNAL = 'internal',
}

export enum GALLERY_TYPE {
  DYNAMIC = 'dynamic',
  STATIC = 'static',
}
export interface UnhydratedGallery {
  id: string;
  label: string; // aka gallery name
  network_id?: string;
  publisher_id?: string;
  publisher_label?: string;
  global_tag_ids?: string[];
  network_tag_ids?: string[];
  publisher_signals?: string[];
  location_ids?: string[];
  story_ids?: string[]; // list of the IDs of selected stories
  type?: GALLERY_TYPE;
  created_at?: string;
  updated_at?: string;
  new_tab?: boolean;
}

export interface HydratedGallery extends UnhydratedGallery {
  publisher: Publisher;
  global_tags: GlobalTag[];
  network_tags: NetworkTag[];
  locations: Location[];
  publisher_signals: string[];
  stories?: Story[];
  status: string;
}

export type EditableStaticGalleryFields = Pick<
  UnhydratedGallery,
  'label' | 'story_ids'
>;

export interface GalleriesState {
  data: HydratedGallery[];
  staticGallery: {
    selectedStories?: Story[];
    gallery: Partial<EditableStaticGalleryFields>;
    activeStepIndex?: number;
  };
  ids: string[];
  searchResults: HydratedGallery[] | null;
  galleryToEdit: HydratedGallery | null;
  error?: string;
  status: STATUS_TYPES;
}

export enum STATUS_TYPES {
  IDLE = 'idle',
  DELETING = 'deleting',
  COPYING = 'copying',
  UNDELETING = 'undeleting',
}

// Manual Gallery Types

export enum STATIC_GALLERY_ACTIONS {
  SAVE_STATIC_GALLERY_SUCCESS = 'STATIC_GALLERY_ACTION/SAVE_STATIC_GALLERY_SUCCESS',
  SAVE_STATIC_GALLERY_FAILURE = 'STATIC_GALLERY_ACTION/SAVE_STATIC_GALLERY_FAILURE',
  SAVE_STATIC_GALLERY_START = 'STATIC_GALLERY_ACTION/SAVE_STATIC_GALLERY_START',
  EDIT_STATIC_GALLERY_START = 'STATIC_GALLERY_ACTION/EDIT_STATIC_GALLERY_START',
  EDIT_STATIC_GALLERY_SUCCESS = 'STATIC_GALLERY_ACTION/EDIT_STATIC_GALLERY_SUCCESS',
  EDIT_STATIC_GALLERY_FAILURE = 'STATIC_GALLERY_ACTION/EDIT_STATIC_GALLERY_FAILURE',
  ADD_STORY_CREATE = 'STATIC_GALLERY_ACTION/ADD_STORY_CREATE',
  REMOVE_STORY_CREATE = 'STATIC_GALLERY_ACTION/REMOVE_STORY_CREATE',
  ADD_STORY_EDIT = 'STATIC_GALLERY_ACTION/ADD_STORY_EDIT',
  REMOVE_STORY_EDIT = 'STATIC_GALLERY_ACTION/REMOVE_STORY_EDIT',
  UPDATE_SELECTED_STATIC_GALLERY = 'UPDATE_SELECTED_STATIC_GALLERY',
  GALLERY_UPDATE_LABEL = 'STATIC_GALLERY_ACTION/GALLERY_UPDATE_LABEL',
  RESET_GALLERY_STATE = 'STATIC_GALLERY_ACTION/RESET_STATE',
}

export enum STATIC_GALLERY_CREATION {
  SET_STATIC_GALLERY_CREATION_VALUE = 'STATIC_GALLERY_CREATION/SET_STATIC_GALLERY_CREATION_VALUE',
  SET_STATIC_GALLERY_CREATION_STEP = 'STATIC_GALLERY_CREATION/SET_STATIC_GALLERY_CREATION_STEP',
  SET_STATIC_GALLERY_OPEN = 'STATIC_GALLERY_CREATION/SET_STATIC_GALLERY_OPEN',
}

export interface UpdateStaticGalleryCreationValue {
  type: STATIC_GALLERY_CREATION.SET_STATIC_GALLERY_CREATION_VALUE;
  payload: Partial<UnhydratedGallery>;
}

export interface SetNextManualGalleryCreationStepAction {
  type: STATIC_GALLERY_CREATION.SET_STATIC_GALLERY_CREATION_STEP;
  payload: number;
}

export interface StaticGalleryCreateAddStoryAction {
  type: STATIC_GALLERY_ACTIONS.ADD_STORY_CREATE;
  payload: Story;
}

export interface StaticGalleryCreateRemoveStoryAction {
  type: STATIC_GALLERY_ACTIONS.REMOVE_STORY_CREATE;
  payload: string;
}

export interface SaveStaticGallerySuccessAction {
  type: STATIC_GALLERY_ACTIONS.SAVE_STATIC_GALLERY_SUCCESS;
  payload: HydratedGallery;
}

export interface SaveStaticGalleryFailureAction {
  type: STATIC_GALLERY_ACTIONS.SAVE_STATIC_GALLERY_FAILURE;
  payload: string;
}

export interface SaveStaticGalleryStartAction {
  type: STATIC_GALLERY_ACTIONS.SAVE_STATIC_GALLERY_START;
  payload: undefined;
}

export interface GalleriesLoadSuccessAction {
  type: GALLERIES_LOAD_TYPES.SUCCESS;
  payload: { galleries: HydratedGallery[] };
}

export interface UpdateSelectedStaticGallery {
  type: STATIC_GALLERY_ACTIONS.UPDATE_SELECTED_STATIC_GALLERY;
  payload: Story[];
}

export interface EditStaticGalleryLabelAction {
  type: STATIC_GALLERY_ACTIONS.GALLERY_UPDATE_LABEL;
  payload: string;
}

export interface StaticEditGalleryAddStoryAction {
  type: STATIC_GALLERY_ACTIONS.ADD_STORY_EDIT;
  payload: Story;
}

export interface StaticEditGalleryRemoveStoryAction {
  type: STATIC_GALLERY_ACTIONS.REMOVE_STORY_EDIT;
  payload: string;
}

export interface ResetStaticGalleryStateAction {
  type: STATIC_GALLERY_ACTIONS.RESET_GALLERY_STATE;
  payload: undefined;
}

export interface StaticGalleryEditStartAction {
  type: STATIC_GALLERY_ACTIONS.EDIT_STATIC_GALLERY_START;
  payload: undefined;
}

export interface StaticGalleryEditFailureAction {
  type: STATIC_GALLERY_ACTIONS.EDIT_STATIC_GALLERY_FAILURE;
  payload: string;
}

export interface StaticGalleryEditSuccessAction {
  type: STATIC_GALLERY_ACTIONS.EDIT_STATIC_GALLERY_SUCCESS;
  payload: HydratedGallery;
}

export type StaticGalleryActions =
  | UpdateStaticGalleryCreationValue
  | SetNextManualGalleryCreationStepAction
  | StaticGalleryCreateAddStoryAction
  | StaticGalleryCreateRemoveStoryAction
  | StaticEditGalleryAddStoryAction
  | StaticEditGalleryRemoveStoryAction
  | SaveStaticGallerySuccessAction
  | SaveStaticGalleryFailureAction
  | SaveStaticGalleryStartAction
  | StaticGalleryEditStartAction
  | StaticGalleryEditSuccessAction
  | StaticGalleryEditFailureAction
  | GalleriesLoadSuccessAction
  | EditStaticGalleryLabelAction
  | UpdateSelectedStaticGallery
  | ResetStaticGalleryStateAction;
