export enum BUTTON_VARIANT {
  SOLID = 'solid',
  OUTLINE = 'outline',
  GHOST = 'ghost',
}

export enum BUTTON_COLOR_VARIANT {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DESTRUCTIVE = 'destructive',
}

export enum BUTTON_TYPES {
  SUBMIT = 'submit',
  BUTTON = 'button',
  RESET = 'reset',
}
