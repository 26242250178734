import { connect } from 'react-redux';
import {
  selectIsAccountSettingsEnabled,
  selectIsUserInternal,
} from '../../../redux/user/user.selectors';
import { AppState } from '../../../types';

import { UtilityMenuContentComponent } from './utility_menu_content.component';

const mapStateToProps = (
  state: AppState,
  ownProps: {
    handleTooltipClose?: () => void;
  },
) => {
  return {
    ...ownProps,
    handleUtilityMenuClose: ownProps.handleTooltipClose,
    isUserInternal: selectIsUserInternal(state),
    isAccountSettingsEnabled: selectIsAccountSettingsEnabled(),
  };
};
export const UtilityMenuContent = connect(mapStateToProps)(
  UtilityMenuContentComponent,
);
