import React from 'react';
import { IconProps } from '../icon.types';

export const ArrowRight = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.7031 10.0068C17.7031 9.79932 17.6201 9.6084 17.4541 9.45068L11.9507 3.96387C11.7681 3.78125 11.5938 3.71484 11.3945 3.71484C10.9878 3.71484 10.6724 4.01367 10.6724 4.42871C10.6724 4.62793 10.7388 4.81885 10.8716 4.95166L12.731 6.84424L15.52 9.39258L13.5195 9.26807H3.01904C2.5874 9.26807 2.28857 9.5752 2.28857 10.0068C2.28857 10.4385 2.5874 10.7456 3.01904 10.7456H13.5195L15.5283 10.6211L12.731 13.1694L10.8716 15.062C10.7388 15.1865 10.6724 15.3857 10.6724 15.585C10.6724 16 10.9878 16.2988 11.3945 16.2988C11.5938 16.2988 11.7681 16.2241 11.9341 16.0664L17.4541 10.563C17.6201 10.4053 17.7031 10.2144 17.7031 10.0068Z" />
    </svg>
  );
};
