import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Instagram = function Instagram(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Layer_1',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M996.7 706.4c-3.9 80.1-22.1 151-80.7 209.6-58.6 58.6-129.5 76.8-209.6 80.7-69 3.9-137.4 3.3-206.4 3.3s-137.4.7-206.4-3.3c-80.1-3.9-151-22.1-209.6-80.7C25.4 857.4 7.2 786.4 3.3 706.4-.6 637.4 0 569 0 500s-.7-137.4 3.3-206.4c3.9-80 22.1-151 80.7-209.6S213.6 7.2 293.6 3.3C362.6-.6 431 0 500 0s137.4-.7 206.4 3.3c80 3.9 151 22.1 209.6 80.7 58.6 58.6 76.8 129.5 80.7 209.6 3.9 69 3.3 137.4 3.3 206.4s.6 137.4-3.3 206.4zM205.1 110.1c-22.8 9.1-39.7 20.2-57.3 37.7-17.6 17.6-28.6 34.5-37.7 57.3-26 65.7-20.2 222-20.2 294.9S84 729.2 110.1 794.9c9.1 22.8 20.2 39.7 37.7 57.3 17.6 17.6 34.5 28.6 57.3 37.7 65.8 26 222 20.2 294.9 20.2s229.1 5.9 294.9-20.2c22.8-9.1 39.7-20.2 57.3-37.7 17.6-17.6 28.6-34.5 37.7-57.3 26-65.7 20.2-222 20.2-294.9s5.9-229.2-20.2-294.9c-9.1-22.8-20.2-39.7-37.7-57.3-17.6-17.6-34.5-28.6-57.3-37.7-65.8-26-222-20.2-294.9-20.2S270.9 84 205.1 110.1zM500 756.5c-141.9 0-256.5-114.6-256.5-256.5S358.1 243.5 500 243.5 756.5 358.1 756.5 500 641.9 756.5 500 756.5zm0-423.2c-91.8 0-166.7 74.8-166.7 166.7S408.2 666.7 500 666.7 666.7 591.8 666.7 500 591.8 333.3 500 333.3zM766.9 293c-33.2 0-59.9-26.7-59.9-59.9s26.7-59.9 59.9-59.9 59.9 26.7 59.9 59.9-26.7 59.9-59.9 59.9z' // eslint-disable-line max-len
    })
  );
};

Instagram.propTypes = propTypes;

export default Instagram;