import React from 'react';
import { IconProps } from '../icon.types';

export const CheckmarkCircle = ({
  className,
  size,
  color = 'black',
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      stroke={color}
      aria-hidden={ariaHidden}
      role={role}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2309_5025)">
        <path
          d="M4.66669 8.33317L6.66669 10.3332L11.3334 5.6665"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99998 14.6668C11.682 14.6668 14.6666 11.6822 14.6666 8.00016C14.6666 4.31816 11.682 1.3335 7.99998 1.3335C4.31798 1.3335 1.33331 4.31816 1.33331 8.00016C1.33331 11.6822 4.31798 14.6668 7.99998 14.6668Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2309_5025">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
