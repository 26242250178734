import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var GridAdd = function GridAdd(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'gridAdd__icon',
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M13.9922 17H16.9922M19.9922 17H16.9922M16.9922 17V14M16.9922 17V20',
      stroke: color,
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    }),
    React.createElement('path', {
      d: 'M6.57619 10.8184L3.18208 7.42426C2.94776 7.18995 2.94776 6.81005 3.18208 6.57574L6.57619 3.18162C6.8105 2.94731 7.1904 2.94731 7.42472 3.18162L10.8188 6.57574C11.0531 6.81005 11.0531 7.18995 10.8188 7.42426L7.42472 10.8184C7.1904 11.0527 6.8105 11.0527 6.57619 10.8184Z',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M4 19.4V14.6C4 14.2686 4.26863 14 4.6 14H9.4C9.73137 14 10 14.2686 10 14.6V19.4C10 19.7314 9.73137 20 9.4 20H4.6C4.26863 20 4 19.7314 4 19.4Z',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M14 9.4V4.6C14 4.26863 14.2686 4 14.6 4H19.4C19.7314 4 20 4.26863 20 4.6V9.4C20 9.73137 19.7314 10 19.4 10H14.6C14.2686 10 14 9.73137 14 9.4Z',
      stroke: color,
      strokeWidth: '1.5'
    })
  );
};

GridAdd.propTypes = propTypes;

export default GridAdd;