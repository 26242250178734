import _slicedToArray from 'babel-runtime/helpers/slicedToArray';
import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon/Icon';

var propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  menuPosition: PropTypes.string,
  className: PropTypes.string,
  tooltipLabel: PropTypes.string,
  tooltipPosition: PropTypes.string,
  triggerButton: PropTypes.element
};

var propDescriptions = {
  menuItems: 'Array of objects with the following properties: label (required), onClick, icon, hasBottomDivider',
  menuPosition: 'The position of the context menu',
  className: 'class names to add to the root contextMenu node',
  tooltipLabel: 'The label to be displayed in the tooltip. The tooltip will not display if this not provided',
  tooltipPosition: 'The position of the tooltip.',
  triggerButton: 'The button component that triggers the context menu'
};

var defaultProps = {
  className: '',
  tooltipLabel: '',
  tooltipPosition: 'right',
  menuPosition: 'alignLeft'
};

export default function ContextMenu(props) {
  var menuItems = props.menuItems,
      menuPosition = props.menuPosition,
      className = props.className,
      tooltipLabel = props.tooltipLabel,
      tooltipPosition = props.tooltipPosition,
      triggerButton = props.triggerButton;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isOpen = _useState2[0],
      setIsOpen = _useState2[1];

  var contextMenu = React.createElement(
    React.Fragment,
    null,
    React.createElement(
      'div',
      { role: 'menu', onClick: function onClick() {
          return setIsOpen(!isOpen);
        } },
      triggerButton
    ),
    isOpen && React.createElement(
      'div',
      { className: 'cr-contextMenu__listWrap' },
      menuItems.map(function (item) {
        var label = item.label,
            icon = item.icon,
            _onClick = item.onClick,
            hasBottomDivider = item.hasBottomDivider;

        return React.createElement(
          React.Fragment,
          { key: label.toString() },
          React.createElement(
            'button',
            {
              type: 'button',
              className: 'cr-contextMenu__listItem',
              onClick: function onClick() {
                _onClick();
                setIsOpen(false);
              }
            },
            icon && React.createElement(Icon, { type: icon.type, size: icon.size }),
            label
          ),
          hasBottomDivider && React.createElement('div', { className: 'cr-contextMenu__divider' })
        );
      })
    )
  );

  return React.createElement(
    ClickAwayListener,
    { onClickAway: function onClickAway() {
        return setIsOpen(false);
      } },
    React.createElement(
      'div',
      { className: classNames('cr-contextMenu', className, menuPosition) },
      tooltipLabel ? React.createElement(
        Tooltip,
        { text: tooltipLabel, position: tooltipPosition },
        contextMenu
      ) : contextMenu
    )
  );
}

ContextMenu.propDescriptions = propDescriptions;
ContextMenu.propTypes = propTypes;
ContextMenu.defaultProps = defaultProps;