import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};
// Play button always has a shadow
var Warning = function Warning(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'plus_icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1076.8 1000',
      height: size,
      fill: color,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1067.2,886.4c13.2,23.4,12.6,52.3-1.2,75.7c-13.8,23.4-39.1,37.9-66.1,37.9H76.9c-27,0-52.3-14.4-66.1-37.9c-13.8-23.4-14.4-52.3-1.2-75.7L471.1,40.3C484.3,15.6,510.2,0,538.4,0c28.2,0,54.1,15.6,67.3,40.3L1067.2,886.4z M624.9,325.7c0-3.6-1.8-8.4-6-11.4c-3.6-3-9-6.6-14.4-6.6H472.3c-5.4,0-10.8,3.6-14.4,6.6c-4.2,3-6,9-6,12.6l10.2,274.6c0,7.8,9,13.8,20.4,13.8h111.2c10.8,0,19.8-6,20.4-13.8L624.9,325.7z M615.3,712.1c0-10.8-8.4-19.8-19.2-19.8H480.7c-10.8,0-19.2,9-19.2,19.8v114.2c0,10.8,8.4,19.8,19.2,19.8h115.4c10.8,0,19.2-9,19.2-19.8V712.1z' // eslint-disable-line max-len
    })
  );
};

Warning.propTypes = propTypes;

export default Warning;