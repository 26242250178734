import React, { useContext } from 'react';
import { ModalContext } from '../../hooks/use_modal/use_modal';
import '../modal/modal.scss';
import { Button, BUTTON_COLOR_VARIANT, BUTTON_VARIANT } from '../button';

export interface ModalButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  ariaLabel?: string;
  className?: string;
  variant?: BUTTON_VARIANT;
  loading?: boolean;
  color?: BUTTON_COLOR_VARIANT;
}

export const ConnectedModalButton = ({
  onClick,
  children,
  ariaLabel,
  className,
  variant,
  loading,
  color,
}: ModalButtonProps) => {
  const { toggle } = useContext(ModalContext);

  const handleClick = () => {
    onClick?.();
    toggle();
  };

  return (
    <Button
      className={className}
      onClick={handleClick}
      ariaLabel={ariaLabel}
      variant={variant}
      loading={loading}
      color={color}
    >
      {children}
    </Button>
  );
};
