import React, { FunctionComponent } from 'react';
import {
  Button as FlockButton,
  ButtonProps as FlockButtonProps,
} from '@crowdriff/flock';
import clsx from 'clsx';
import { BUTTON_COLOR_VARIANT, BUTTON_TYPES, BUTTON_VARIANT } from './index';
import { Spinner } from '../spinner/spinner.component';
import './button.scss';

interface ButtonProps {
  variant?: BUTTON_VARIANT;
  children: React.ReactNode;
  onClick?: (e: React.SyntheticEvent) => void;
  color?: BUTTON_COLOR_VARIANT;
  type?: BUTTON_TYPES;
  className?: string;
  isDisabled?: boolean;
  ariaLabel?: string;
  loading?: boolean;
}

export const Button: FunctionComponent<ButtonProps> = ({
  variant = BUTTON_VARIANT.SOLID,
  children,
  onClick,
  color,
  type = BUTTON_TYPES.BUTTON,
  className,
  isDisabled,
  ariaLabel,
  loading,
}) => {
  if (type === BUTTON_TYPES.SUBMIT && Boolean(onClick)) {
    throw new Error('onClick method not permitted on submit type buttons.');
  }

  let buttonColor: FlockButtonProps['color'];

  switch (color) {
    case BUTTON_COLOR_VARIANT.SECONDARY:
      buttonColor = 'neutral-50';
      break;
    case BUTTON_COLOR_VARIANT.DESTRUCTIVE:
      buttonColor = 'red';
      break;
    default:
    case BUTTON_COLOR_VARIANT.PRIMARY:
      buttonColor = 'blue';
      break;
  }

  let buttonStyle: FlockButtonProps['buttonStyle'];
  switch (variant) {
    case BUTTON_VARIANT.GHOST:
      buttonStyle = 'link';
      break;
    case BUTTON_VARIANT.OUTLINE:
      buttonStyle = 'outline';
      break;
    default:
    case BUTTON_VARIANT.SOLID:
      buttonStyle = 'fill';
      break;
  }
  return (
    <FlockButton
      type={type}
      isDisabled={isDisabled || loading}
      buttonStyle={buttonStyle}
      color={buttonColor}
      {...(!(type === BUTTON_TYPES.SUBMIT) && { onClick })}
      aria-label={ariaLabel}
      className={clsx('btn', className)}
    >
      {loading && <Spinner />}
      {children}
    </FlockButton>
  );
};
