import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var FacebookComment = function FacebookComment(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'facebookComment__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1012.9 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1002.3,10.5c7,7,10.5,15.2,10.5,24.6v546.2c0,10.1-3.5,19.1-10.5,26.9c-7,7.8-16.4,11.7-28.1,11.7h-117v250.3 c0,3.9-1.6,7-4.7,9.4c-3.1,2.3-6.6,1.6-10.5-2.3l-101.8-97.1H393L170.8,998.8c-2.3,1.5-5.3,1.5-8.8,0c-3.5-1.6-5.7-4.3-6.4-8.2 V780.1H42.1c-11.7,0-21.6-4.3-29.8-12.8C4.1,758.7,0,748.5,0,736.9V273.7C0,262,3.5,252.5,10.5,245c7-7.4,16.4-11.1,28.1-11.1h117 V39.8c0.8-10.1,4.9-19.1,12.3-26.9C175.2,5.1,184,0.8,194.1,0h780.1C986,0,995.3,3.5,1002.3,10.5z M935.7,70.2H233.9V538h381.3 l23.4,23.4l140.3,145V538h156.7V70.2z' // eslint-disable-line max-len
    })
  );
};

FacebookComment.propTypes = propTypes;

export default FacebookComment;