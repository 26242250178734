import React, { ReactElement } from 'react';
import { Translate } from 'react-localize-redux';
import { AuthWrapper } from '../../blocks/authwrapper/auth_wrapper.connect';
import { FEATURE_FLAGS } from '../../blocks/authwrapper/auth_wrapper.types';

interface ReportingComponentProps {
  reportUrl?: string;
}

export const ReportingComponent = ({
  reportUrl,
}: ReportingComponentProps): ReactElement => {
  return (
    <AuthWrapper featureFlags={FEATURE_FLAGS.REPORTS}>
      {reportUrl && (
        <a href={reportUrl} rel="noopener noreferrer" target="_blank">
          <li>
            <p>
              <Translate id="general.navigation.reports" />
            </p>
          </li>
        </a>
      )}
    </AuthWrapper>
  );
};
