import { createSelector } from 'reselect';
import { AppState } from '../../types';
import { LOCATION_TYPE } from './locations.types';

export const selectNetworkLocationTypes = (state: AppState) =>
  state.taxonomy.locations.types;

export const selectDefaultLocationType = createSelector(
  selectNetworkLocationTypes,
  (locationTypes) => {
    if (locationTypes && locationTypes.length >= 1) {
      return locationTypes[0];
    }
    return LOCATION_TYPE.STATE;
  },
);
