import { connect } from 'react-redux';
import { AppState } from '../../../../types';
import { DetailedStoryModerationAndStatus } from './detailed_story_moderation_and_status.component';
import { selectCurrentPublisherFeatureFlags } from '../../../../redux/user/user.selectors';

const mapStateToProps = (state: AppState) => ({
  enabledFeatures: selectCurrentPublisherFeatureFlags(state),
});

const mapDispatchToProps = () => ({});

export const DetailedStoryModerationAndStatusConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailedStoryModerationAndStatus);
