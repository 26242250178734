import React, { useEffect, VoidFunctionComponent } from 'react';
import classNames from 'classnames';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';
import { HydratedGallery } from '../../../redux/galleries/galleries.types';
import { GalleryListGrid } from '../gallery_list_grid/gallery_list_grid.component';
import './gallery_list.scss';
import { clearSearchResults } from '../../pages/galleries_list/galleries_list.actions';
import { ScrollToTopButton } from '../../blocks/ScrollToTopButton/ScrollToTopButton';
import { Search, SEARCH_MODE } from '../../blocks/search/search.component';
import { GalleryEmptyState } from './gallery_empty_state';
import { SearchEmptyState } from '../search';
import { CreateGalleryBlock } from '../gallery_create';

export interface GalleryListProps extends LocalizeContextProps {
  galleries: HydratedGallery[];
  searchResults: HydratedGallery[] | null;
  handleClearStoriesSearch: () => void;
  handleSearchChange: (val: string) => void;
}

const GalleryListComponent: VoidFunctionComponent<GalleryListProps> = ({
  translate,
  galleries,
  handleClearStoriesSearch,
  searchResults,
  handleSearchChange,
}) => {
  const dispatch = useDispatch();
  const getTranslation = useTranslateStrings(translate);
  useEffect(() => {
    dispatch(clearSearchResults());
  }, [dispatch]);

  return (
    <div
      className={classNames('gallery_list', {
        emptyState: galleries.length === 0,
      })}
    >
      {galleries.length > 0 ? (
        <React.Fragment>
          <div className="gallery_list--actions">
            <Search
              id="gallery_search"
              ariaLabel="galleries.searchAriaLabel"
              placeholder={getTranslation('galleries.searchPlaceholder')}
              onSearch={handleSearchChange}
              onClearSearch={() => handleClearStoriesSearch()}
              searchMode={SEARCH_MODE.CHANGE}
            />
            <CreateGalleryBlock />
          </div>
          {searchResults?.length === 0 ? (
            <SearchEmptyState />
          ) : (
            <ScrollToTopButton>
              <GalleryListGrid galleries={searchResults ?? galleries} />
            </ScrollToTopButton>
          )}
        </React.Fragment>
      ) : (
        <GalleryEmptyState />
      )}
    </div>
  );
};

export const GalleryList = withLocalize(GalleryListComponent);
