import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var SquarePhotoCollage = function SquarePhotoCollage(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      height: size,
      viewBox: '0 0 32 32',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg'
    },
    React.createElement('path', {
      d: 'M13.3333 16.0002V5.3335M18.6666 26.6668V16.0002M5.33325 16.0002H26.6666M25.8666 26.6668H6.13325C5.69143 26.6668 5.33325 26.3087 5.33325 25.8668V6.1335C5.33325 5.69167 5.69143 5.3335 6.13325 5.3335H25.8666C26.3085 5.3335 26.6666 5.69167 26.6666 6.1335V25.8668C26.6666 26.3087 26.3085 26.6668 25.8666 26.6668Z',
      stroke: color,
      'stroke-width': '2'
    })
  );
};

SquarePhotoCollage.propTypes = propTypes;

export default SquarePhotoCollage;