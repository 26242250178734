import admin from './admin.json';
import forms from './forms.json';
import galleries from './galleries.json';
import general from './general.json';
import network from './network.json';
import scrollToTopButton from './scrollToTopButton.json';
import stories from './stories.json';
import termsOfService from './termsOfService.json';
import settings from './accountSettings.json';

export const translations = {
  admin,
  forms,
  galleries,
  general,
  network,
  scrollToTopButton,
  stories,
  termsOfService,
  settings,
};
