import React, { FunctionComponent, ReactNode } from 'react';
import './section_action_bar.scss';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
} from '../../../blocks/button';

export interface SectionActionBarProps {
  title: string;
  descriptionContent?: ReactNode;
  actionVariant?: BUTTON_VARIANT;
  actionName: string;
  onActionClick: () => void;
  extraActionContent?: ReactNode;
  actionIsProcessing?: boolean;
  isActionDisabled?: boolean;
  isButtonRequired?: boolean;
  actionColorVariant?: BUTTON_COLOR_VARIANT;
}

export const SectionActionBar: FunctionComponent<SectionActionBarProps> = ({
  title,
  descriptionContent,
  actionName,
  actionVariant = BUTTON_VARIANT.SOLID,
  onActionClick,
  extraActionContent,
  actionIsProcessing = false,
  isActionDisabled = false,
  isButtonRequired = true,
  actionColorVariant = BUTTON_COLOR_VARIANT.PRIMARY,
}) => {
  return (
    <div className="static_gallery_section_action_bar">
      <div className="static_gallery_section_action_bar__info">
        <h2 className="static_gallery_section_action_bar__title">{title}</h2>
        <div className="static_gallery_section_action_bar__description">
          {descriptionContent}
        </div>
      </div>
      <div className="static_gallery_section_action_bar__actions">
        {extraActionContent}
        {isButtonRequired && (
          <Button
            loading={actionIsProcessing}
            onClick={onActionClick}
            isDisabled={isActionDisabled}
            variant={actionVariant}
            color={actionColorVariant}
          >
            {actionName}
          </Button>
        )}
      </div>
    </div>
  );
};
