import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { withController } from '../../../../hooks/withController';
import { Input, InputProps } from '../../../../blocks/input';
import { Text, TEXT_ELEMENTS, TEXT_VARIANTS } from '../../../../blocks/text';
import {
  FileUpload,
  FileUploadProps,
  FILE_TYPES,
} from '../../../../blocks/file_upload/file_upload.component';
import { StoryHeaderPreview } from '../../story_header_preview';
import { useTranslateStrings } from '../../../../hooks/useTranslateStrings';
import { specialCharacterValidationRegex } from '../../../../../utils';
import { isUploadedImageValid } from '../../account_settings.utils';
import {
  AVATAR_UPLOAD_REQ,
  isDisplayAvatarUploadEnabled,
  isDisplayNameEnabled,
} from '../../account_settings.constants';
import { StoryAppearanceSectionProps } from '../../../../pages/account_settings/account_settings.types';

const ControlledInput = withController<string, InputProps>(Input);
const ControlledFileUpload = withController<File[], FileUploadProps>(
  FileUpload,
);

const StoryAppearanceSectionComponent = ({
  translate,
  avatarUrl,
  avatarImg,
  isAvatarLoading = false,
  displayName,
}: StoryAppearanceSectionProps) => {
  const getTranslation = useTranslateStrings(translate);
  return (
    <div className="account_edit__appearance">
      <Text variant={TEXT_VARIANTS.H3}>
        {getTranslation('settings.storyAppearance.title')}
      </Text>
      {(isDisplayNameEnabled() || isDisplayAvatarUploadEnabled()) && (
        <StoryHeaderPreview avatarUrl={avatarUrl} displayName={displayName} />
      )}
      {isDisplayNameEnabled() && (
        <div className="account_edit__appearance--display_name">
          <ControlledInput
            name="name"
            label={`${translate('settings.storyAppearance.displayName.label')}`}
            placeholder={`${translate(
              'settings.storyAppearance.displayName.label',
            )}`}
            description={getTranslation(
              'settings.storyAppearance.displayName.condition',
            )}
            validationRules={{
              pattern: {
                value: specialCharacterValidationRegex,
                message: getTranslation(
                  'settings.storyAppearance.displayName.invalid',
                ),
              },
              required: getTranslation(
                'settings.storyAppearance.displayName.required',
              ),
            }}
          />
        </div>
      )}
      {isDisplayAvatarUploadEnabled() && (
        <div>
          <Text
            className="account_edit__appearance--avatar_title"
            variant={TEXT_VARIANTS.B1}
          >
            {getTranslation('settings.storyAppearance.avatar.title')}
          </Text>
          <Text
            className="account_edit__appearance--avatar_description"
            variant={TEXT_VARIANTS.C1}
          >
            {getTranslation('settings.storyAppearance.avatar.description')}
          </Text>
          <Text
            className="account_edit__appearance--avatar_body"
            element={TEXT_ELEMENTS.UNORDERED_LIST}
          >
            <Text element={TEXT_ELEMENTS.LIST} variant={TEXT_VARIANTS.C1}>
              {translate('settings.storyAppearance.avatar.req1', undefined)}
            </Text>
            <Text element={TEXT_ELEMENTS.LIST} variant={TEXT_VARIANTS.C1}>
              {translate('settings.storyAppearance.avatar.req2', undefined)}
            </Text>
            <Text element={TEXT_ELEMENTS.LIST} variant={TEXT_VARIANTS.C1}>
              {translate('settings.storyAppearance.avatar.req3', undefined)}
            </Text>
          </Text>
          <ControlledFileUpload
            name="avatar"
            title={
              avatarImg
                ? `${translate('settings.formActions.replaceIMG')}`
                : `${translate('settings.formActions.uploadIMG')}`
            }
            fileType={`${FILE_TYPES.PNG}, ${FILE_TYPES.JPEG}`}
            file={avatarImg}
            isLoading={isAvatarLoading}
            validationRules={{
              validate: async (files) => {
                return (
                  !files || isUploadedImageValid(files?.[0], AVATAR_UPLOAD_REQ)
                );
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export const StoryAppearanceSection = withLocalize(
  StoryAppearanceSectionComponent,
);
