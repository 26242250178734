import React from 'react';
import { IconProps } from '../icon.types';

export const TextAlignRight = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.25537 4.08838H17.7197C18.0684 4.08838 18.3589 3.81445 18.3589 3.45752C18.3589 3.10889 18.0684 2.83496 17.7197 2.83496H2.25537C1.90674 2.83496 1.63281 3.10889 1.63281 3.45752C1.63281 3.81445 1.90674 4.08838 2.25537 4.08838ZM8.38965 8.43799H17.7197C18.0684 8.43799 18.3589 8.16406 18.3589 7.80713C18.3589 7.4585 18.0684 7.18457 17.7197 7.18457H8.38965C8.03271 7.18457 7.75879 7.4585 7.75879 7.80713C7.75879 8.16406 8.03271 8.43799 8.38965 8.43799ZM2.25537 12.7876H17.7197C18.0684 12.7876 18.3589 12.5054 18.3589 12.1567C18.3589 11.8081 18.0684 11.5342 17.7197 11.5342H2.25537C1.90674 11.5342 1.63281 11.8081 1.63281 12.1567C1.63281 12.5054 1.90674 12.7876 2.25537 12.7876ZM8.38965 17.1289H17.7197C18.0684 17.1289 18.3589 16.855 18.3589 16.5063C18.3589 16.1577 18.0684 15.8755 17.7197 15.8755H8.38965C8.03271 15.8755 7.75879 16.1577 7.75879 16.5063C7.75879 16.855 8.03271 17.1289 8.38965 17.1289Z" />
    </svg>
  );
};
