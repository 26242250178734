import React from 'react';
import { IconProps } from '../icon.types';

export const QuestionMarkCircle = ({
  className,
  size,
  color,
  ariaHidden,
  role,
  ...otherProps
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      {...otherProps}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 18.4736C14.6318 18.4736 18.4668 14.6304 18.4668 10.0068C18.4668 5.375 14.6235 1.54004 9.9917 1.54004C5.36816 1.54004 1.5332 5.375 1.5332 10.0068C1.5332 14.6304 5.37646 18.4736 10 18.4736ZM10 17.0625C6.08203 17.0625 2.95264 13.9248 2.95264 10.0068C2.95264 6.08887 6.07373 2.95117 9.9917 2.95117C13.9097 2.95117 17.0474 6.08887 17.0557 10.0068C17.064 13.9248 13.918 17.0625 10 17.0625ZM9.82568 11.7251C10.2407 11.7251 10.498 11.4595 10.498 11.1357V11.0361C10.498 10.5713 10.7637 10.2725 11.3447 9.89062C12.1499 9.35938 12.7227 8.87793 12.7227 7.88184C12.7227 6.50391 11.4941 5.75684 10.083 5.75684C8.65527 5.75684 7.71729 6.4375 7.49316 7.20117C7.45166 7.33398 7.42676 7.4668 7.42676 7.60791C7.42676 7.98145 7.71729 8.18066 7.99121 8.18066C8.27344 8.18066 8.45605 8.04785 8.60547 7.84863L8.75488 7.64941C9.04541 7.16797 9.47705 6.88574 10.0332 6.88574C10.7886 6.88574 11.2783 7.31738 11.2783 7.94824C11.2783 8.5127 10.9297 8.78662 10.2075 9.29297C9.60986 9.70801 9.16162 10.1479 9.16162 10.9614V11.0693C9.16162 11.501 9.40234 11.7251 9.82568 11.7251ZM9.80908 14.1987C10.2905 14.1987 10.7056 13.8169 10.7056 13.3354C10.7056 12.854 10.2988 12.4722 9.80908 12.4722C9.31934 12.4722 8.9126 12.8623 8.9126 13.3354C8.9126 13.8086 9.32764 14.1987 9.80908 14.1987Z" />
    </svg>
  );
};
