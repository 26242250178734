import { AxiosResponse } from 'axios';
import { selectCurrentNetworkId } from '../redux/user/user.selectors';
import { AppState } from '../types';
import Err from '../types/err';
import { CRThunkDispatch, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import axios from './axios_config';
import { NetworkSettings } from '../redux/network/network.types';
import { getErrorMessage } from '../utils';

type GetNetworkSettingsResponse = {
  network_setting: NetworkSettings;
};
export const getNetworkSettings = async (
  _: CRThunkDispatch,
  getState: () => AppState,
): Promise<GetNetworkSettingsResponse['network_setting']> => {
  const state: AppState = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.SETTINGS,
    params: {
      networkId,
    },
  });
  try {
    const response: AxiosResponse<GetNetworkSettingsResponse> = await axios.get<
      GetNetworkSettingsResponse
    >(url);
    return response.data.network_setting;
  } catch (err) {
    throw new Err(
      `Failed to get network settings: ${getErrorMessage(err)}`,
      'Network Settings not found.',
    );
  }
};
