import React from 'react';
import { IconProps } from '../icon.types';

export const CheckMark = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    // TODO: Make the viewbox be the same as other icons once we have a design solution
    // for stroke widths as icons scale
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.14502 8.73047C4.38989 8.73047 4.58081 8.63501 4.71362 8.43579L8.59839 2.42603C8.698 2.27661 8.73535 2.1438 8.73535 2.01514C8.73535 1.68726 8.49463 1.45068 8.15845 1.45068C7.92603 1.45068 7.78491 1.53369 7.6438 1.75781L4.12842 7.32764L2.32715 5.04492C2.19019 4.87476 2.04907 4.80005 1.8457 4.80005C1.50537 4.80005 1.2605 5.04077 1.2605 5.3728C1.2605 5.51807 1.3103 5.65503 1.43066 5.80029L3.58057 8.44824C3.73828 8.64331 3.9126 8.73047 4.14502 8.73047Z" />
    </svg>
  );
};
