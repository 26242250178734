import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var BoxStack = function BoxStack(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      height: size,
      viewBox: '0 0 16 16',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M2.00016 10.9333L2.00016 2.40001C2.00016 2.29392 2.04231 2.19218 2.11732 2.11717C2.19233 2.04215 2.29408 2.00001 2.40016 2.00001L10.9335 2.00001C11.0396 2.00001 11.1413 2.04215 11.2163 2.11717C11.2914 2.19218 11.3335 2.29392 11.3335 2.40001L11.3335 10.9333C11.3335 11.0394 11.2914 11.1412 11.2163 11.2162C11.1413 11.2912 11.0396 11.3333 10.9335 11.3333L2.40016 11.3333C2.29408 11.3333 2.19233 11.2912 2.11732 11.2162C2.0423 11.1412 2.00016 11.0394 2.00016 10.9333V10.9333Z',
      stroke: color,
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    }),
    React.createElement('path', {
      d: 'M4.00016 13.3333L12.9335 13.3333C13.0396 13.3333 13.1413 13.2912 13.2163 13.2162C13.2914 13.1412 13.3335 13.0394 13.3335 12.9333L13.3335 4.00001',
      stroke: color,
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    })
  );
};

BoxStack.propTypes = propTypes;

export default BoxStack;