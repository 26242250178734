import { ThunkDispatch } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { selectCurrentNetworkId } from '../redux/user/user.selectors';
import { AppState, AppActions } from '../types';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import {
  showStoryStarted,
  showStorySucceeded,
  showStoryFailed,
} from '../redux/stories/stories.actions';

export interface StoryShowResponse {
  error?: Error;
}

export const showStory = (storyId: string) => async (
  dispatch: ThunkDispatch<AppState, {}, AppActions>,
  getState: () => AppState,
): Promise<StoryShowResponse> => {
  const state = getState();
  const networkId = selectCurrentNetworkId(state);

  if (!storyId) {
    throw new Error('No story Id provided');
  }

  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.STORY_SHOW,
    params: {
      networkId,
      uuid: storyId,
    },
  });

  try {
    dispatch(showStoryStarted);
    const res: AxiosResponse<StoryShowResponse> = await axios.get<
      StoryShowResponse
    >(url);

    dispatch(showStorySucceeded(storyId));
    return res.data;
  } catch (e) {
    dispatch(showStoryFailed(e));
    throw new Error(
      `Failed to show story: ${e instanceof Error ? e.message : e}`,
    );
  }
};
