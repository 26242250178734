import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Collector = function Collector(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'collector__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M500 1000c276.142 0 500-223.858 500-500S776.142 0 500 0 0 223.858 0 500s223.858 500 500 500zm78.124-453.126h-156.25V351.562H304.687L499.999 156.25l195.313 195.312H578.124v195.312zM187.5 781.25v-78.125h625v78.125h-625z' // eslint-disable-line max-len
    })
  );
};

Collector.propTypes = propTypes;

export default Collector;