import { createSelector } from 'reselect';
import {
  selectStoriesSearchStaticFilterMap,
  selectStoriesSearchDynamicFilterMap,
} from '../../../redux/stories/stories.selectors';
import {
  StaticFilterDisplayData,
  StaticFilterMap,
} from '../stories_search_form.component/stories_search_form.types';
import { DynamicFilterType } from '../stories_search_dynamic_filters/stories_search_dynamic_filters.types';

export const selectStoriesSearchFilterCount = createSelector(
  selectStoriesSearchStaticFilterMap,
  selectStoriesSearchDynamicFilterMap,
  (
    staticFilters: StaticFilterMap | null | undefined,
    dynamicFilters: DynamicFilterType[] | null,
  ) => {
    let count = 0;
    if (staticFilters) {
      const sfByCategory: StaticFilterDisplayData[][] = Object.values(
        staticFilters,
      );
      const sfCount = sfByCategory.reduce((acc: number, curr) => {
        return curr.some((filter) => filter.value === true) ? acc + 1 : acc;
      }, 0);
      count += sfCount;
    }
    if (dynamicFilters) {
      const dfCount =
        dynamicFilters.filter(
          (filter) => filter.filterTags && filter.filterTags?.length > 0,
        ).length ?? 0;
      count += dfCount;
    }
    return count;
  },
);

export const selectShowClearFilterButtons = createSelector(
  selectStoriesSearchFilterCount,
  (filterCount) => {
    return filterCount > 0;
  },
);
