import axios from './axios_config';
import { selectCurrentNetworkId } from '../redux/user/user.selectors';
import { CRThunk, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import {
  deleteStoryStarted,
  deleteStoryFailure,
  deleteStorySuccess,
} from '../redux/stories/stories.actions';
import { getErrorMessage } from '../utils';
import { Story } from '../react/features/story/story.types';

export type DeleteStoryResponse = Story;

export interface DeleteStoryArgs {
  story: Story | null;
}

export const deleteStory: CRThunk<DeleteStoryArgs, DeleteStoryResponse> = ({
  story,
}) => async (dispatch, getState) => {
  const state = getState();
  const networkId = selectCurrentNetworkId(state);

  if (!story) {
    throw new Error('Story data not present');
  }

  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.STORY_DELETE,
    params: {
      networkId,
      uuid: story.uuid,
    },
  });

  dispatch(deleteStoryStarted({ story }));
  try {
    await axios.delete<undefined>(url);

    dispatch(deleteStorySuccess({ story }));
    return story;
  } catch (e) {
    dispatch(deleteStoryFailure({ story }));
    throw new Error(getErrorMessage(e, 'Failed to get delete story.'));
  }
};
