import _defineProperty from 'babel-runtime/helpers/defineProperty';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

var propTypes = {
  numSelected: PropTypes.number
};

var useToolbarStyles = makeStyles(function (theme) {
  return {
    root: {
      paddingLeft: '13px',
      paddingRight: theme.spacing(1),
      minHeight: 0
    },
    spacer: {
      flex: '1 1 100%'
    },
    actions: {
      color: theme.palette.text.secondary
    },
    title: {
      flex: '0 0 auto'
    }
  };
});

var EnhancedTableToolbar = function EnhancedTableToolbar(_ref) {
  var numSelected = _ref.numSelected;

  var classes = useToolbarStyles();

  return React.createElement(
    Toolbar,
    {
      className: classNames(classes.root, _defineProperty({}, classes.highlight, numSelected > 0))
    },
    React.createElement(
      'div',
      { className: classes.title },
      numSelected > 0 && React.createElement(
        Typography,
        { color: 'inherit', variant: 'subtitle1' },
        numSelected,
        ' rows selected'
      )
    ),
    React.createElement('div', { className: classes.spacer })
  );
};

EnhancedTableToolbar.propTypes = propTypes;

export default EnhancedTableToolbar;