import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Video = function Video(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'video_icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('polygon', {
      points: '1000,312.5 750,437.8 750,250.1 0,250.1 0,749.9 750,749.9 750,562.2 1000,687.5'
    })
  );
};

Video.propTypes = propTypes;

export default Video;