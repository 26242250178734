import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { localizeReducer } from 'react-localize-redux';
import thunk from 'redux-thunk';
import { galleriesReducer } from './galleries/galleries.reducer';
import { userReducer } from './user/user.reducer';
import { locationsReducer } from './locations/locations.reducer';
import { globalTagsReducer } from './global_tags/global_tags.reducer';
import { networkTagsReducer } from './network_tags/network_tags.reducer';
import { storiesReducer } from './stories/stories.reducer';
import { networkReducer } from './network/network.reducer';
import { exportsReducer } from './exports/exports.reducer';

const rootReducer = combineReducers({
  galleries: galleriesReducer,
  localize: localizeReducer,
  network: networkReducer,
  taxonomy: combineReducers({
    globalTags: globalTagsReducer,
    locations: locationsReducer,
    networkTags: networkTagsReducer,
  }),
  stories: storiesReducer,
  user: userReducer,
  exports: exportsReducer,
});

const enhancers = composeWithDevTools(applyMiddleware(thunk));
export const store = createStore(rootReducer, enhancers);
