import React, { useState, VoidFunctionComponent } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
} from '../../../../blocks/button';
import { EDIT_STORIES_LANGUAGE_BASE } from '../edit_stories_section.constants';
import { useTranslateStrings } from '../../../../hooks/useTranslateStrings';
import { copyToClipboard } from '../../../gallery_list_grid_card/gallery_list_grid_card.utils';

export interface CopyEmbedButtonProps extends LocalizeContextProps {
  embedCode: string | null;
  variant?: BUTTON_VARIANT;
  color?: BUTTON_COLOR_VARIANT;
}

export enum COPY_STATUS {
  SUCCESS,
  FAILURE,
}

const CopyEmbedButtonComponent: VoidFunctionComponent<CopyEmbedButtonProps> = ({
  embedCode,
  translate,
  variant = BUTTON_VARIANT.OUTLINE,
  color = BUTTON_COLOR_VARIANT.SECONDARY,
}) => {
  const [status, setStatus] = useState<COPY_STATUS | null>(null);
  const getTranslation = useTranslateStrings(translate);
  const buttonTextId =
    status === COPY_STATUS.SUCCESS
      ? 'copyEmbedButtonSuccess'
      : 'copyEmbedButton';
  const onClick = () =>
    copyToClipboard(
      embedCode,
      () => setStatus(COPY_STATUS.SUCCESS),
      () => setStatus(COPY_STATUS.FAILURE),
    );
  return (
    <React.Fragment>
      <Button variant={variant} onClick={onClick} color={color}>
        {getTranslation(`${EDIT_STORIES_LANGUAGE_BASE}.${buttonTextId}`)}
      </Button>
    </React.Fragment>
  );
};

export const CopyEmbedButton = withLocalize(CopyEmbedButtonComponent);
