import '../detailed_story.scss';
import React, { useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { Checkbox, CHECKBOX_VARIANTS } from '../../../blocks/checkbox';
import { DetailedStory } from '../detailed_story.component';
import { HOVER_POSITIONS } from '../../story_list/story_list.types';
import { DetailedStorySelectableProps } from './detailed_story_selectable.types';

const DetailedStorySelectableComponent = ({
  story,
  previewSrc,
  currentPublisherId,
  checkStorySelected,
  handleOnCheckboxClick,
}: DetailedStorySelectableProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const onHover = (mousePlacement: string) => {
    if (mousePlacement === HOVER_POSITIONS.IN) setIsHovered(true);
    if (mousePlacement === HOVER_POSITIONS.OUT) setIsHovered(false);
  };

  const isSelected = checkStorySelected(story.uuid);

  return (
    <DetailedStory
      story={story}
      includeDetailsContextMenu={false}
      onHover={onHover}
      previewSrc={previewSrc}
      currentPublisherId={currentPublisherId}
    >
      <Checkbox
        variant={CHECKBOX_VARIANTS.CIRCLE}
        id={story.uuid}
        name="detailed-story__card"
        onCheckboxClick={(e) => {
          e.preventDefault();
          handleOnCheckboxClick(story, isSelected);
        }}
        isChecked={isSelected}
        alternateHoverState={isHovered}
      />
    </DetailedStory>
  );
};

export const DetailedStorySelectable = withLocalize(
  DetailedStorySelectableComponent,
);
