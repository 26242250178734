import React from 'react';
import { IconProps } from '../icon.types';

export const QrCodeScan = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    // TODO: Make the viewbox be the same as other icons once we have a design solution
    // for stroke widths as icons scale
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        d="M5.49982 10.1709H7.99982M12.9998 10.1709V12.6709M10.4998 15.1709H12.9998M10.4998 10.1801L10.5082 10.1709M15.4998 10.1801L15.5082 10.1709M10.4998 12.6801L10.5082 12.6709M15.4998 12.6801L15.5082 12.6709M15.4998 15.1801L15.5082 15.1709M10.4998 7.68006L10.5082 7.6709M10.4998 5.18007L10.5082 5.1709M15.4998 2.6709H17.9998V5.1709M15.4998 17.6709H17.9998V15.1709M5.49982 2.6709H2.99982V5.1709M5.49982 17.6709H2.99982V15.1709M7.99982 5.6709V7.1709C7.99982 7.30351 7.94714 7.43068 7.85337 7.52445C7.7596 7.61822 7.63242 7.6709 7.49982 7.6709H5.99982C5.86721 7.6709 5.74003 7.61822 5.64626 7.52445C5.5525 7.43068 5.49982 7.30351 5.49982 7.1709V5.6709C5.49982 5.53829 5.5525 5.41111 5.64626 5.31734C5.74003 5.22358 5.86721 5.1709 5.99982 5.1709H7.49982C7.63242 5.1709 7.7596 5.22358 7.85337 5.31734C7.94714 5.41111 7.99982 5.53829 7.99982 5.6709V5.6709ZM7.99982 13.1709V14.6709C7.99982 14.8035 7.94714 14.9307 7.85337 15.0245C7.7596 15.1182 7.63242 15.1709 7.49982 15.1709H5.99982C5.86721 15.1709 5.74003 15.1182 5.64626 15.0245C5.5525 14.9307 5.49982 14.8035 5.49982 14.6709V13.1709C5.49982 13.0383 5.5525 12.9111 5.64626 12.8173C5.74003 12.7236 5.86721 12.6709 5.99982 12.6709H7.49982C7.63242 12.6709 7.7596 12.7236 7.85337 12.8173C7.94714 12.9111 7.99982 13.0383 7.99982 13.1709V13.1709ZM15.4998 5.6709V7.1709C15.4998 7.30351 15.4471 7.43068 15.3534 7.52445C15.2596 7.61822 15.1324 7.6709 14.9998 7.6709H13.4998C13.3672 7.6709 13.24 7.61822 13.1463 7.52445C13.0525 7.43068 12.9998 7.30351 12.9998 7.1709V5.6709C12.9998 5.53829 13.0525 5.41111 13.1463 5.31734C13.24 5.22358 13.3672 5.1709 13.4998 5.1709H14.9998C15.1324 5.1709 15.2596 5.22358 15.3534 5.31734C15.4471 5.41111 15.4998 5.53829 15.4998 5.6709V5.6709Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
