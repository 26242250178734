import React from 'react';
import { IconProps } from '../icon.types';

export const ExclamationTriangleFilled = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.47559 17.8511H16.5161C17.7612 17.8511 18.5415 16.9546 18.5415 15.8257C18.5415 15.4854 18.4585 15.145 18.2759 14.8296L11.7432 3.16699C11.3696 2.49463 10.6807 2.1377 10 2.1377C9.31104 2.1377 8.62207 2.49463 8.24854 3.16699L1.72412 14.8379C1.5498 15.1533 1.4502 15.4854 1.4502 15.8257C1.4502 16.9546 2.23047 17.8511 3.47559 17.8511ZM10 12.3062C9.56836 12.3062 9.33594 12.0571 9.32764 11.6172L9.21143 7.10156C9.20312 6.66162 9.52686 6.34619 9.9917 6.34619C10.4482 6.34619 10.7886 6.66992 10.7803 7.10986L10.6641 11.6172C10.6558 12.0654 10.415 12.3062 10 12.3062ZM10 15.0869C9.51025 15.0869 9.07861 14.6885 9.07861 14.1987C9.07861 13.7007 9.50195 13.3022 10 13.3022C10.5063 13.3022 10.9297 13.6924 10.9297 14.1987C10.9297 14.6968 10.498 15.0869 10 15.0869Z" />
    </svg>
  );
};
