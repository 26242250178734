import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var ArrowChunky = function ArrowChunky(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'arrow_chunky',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 14 14',
      height: size,
      fill: color
    },
    React.createElement('path', {
      d: 'M4.2 14H9.8V7H14L7 0L0 7H4.2V14Z', fill: color
    })
  );
};

ArrowChunky.propTypes = propTypes;

export default ArrowChunky;