import React, {
  useState,
  useEffect,
  useReducer,
  VoidFunctionComponent,
} from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import './static_gallery_edit.scss';
import { isStoryListPristine } from './static_gallery_edit.utils';
import { Input } from '../../blocks/input';
import { GALLERY_DETAILS_LANGUAGE_BASE } from '../gallery_edit_content/gallery_edit_content.constants';
import { EditError } from '../gallery_edit_content/gallery_edit_content.types';
import { Story } from '../story/story.types';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
} from '../../blocks/button';
import { useModal } from '../../hooks/use_modal/use_modal';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';
import { MODAL_TYPES } from '../../blocks/modal';
import { LoadStateData } from '../../blocks/load_data/load_state_data.component';
import { STORIES_LOAD_TYPES } from '../../pages/stories_list/stories_list.types';
import { LoadingGif } from '../../blocks/LoadingGif/LoadingGif.component';
import {
  getStoriesSearch,
  STORY_SEARCH_LOAD_TYPES,
} from '../../../http/stories_search.thunks';
import { SelectStoriesSection } from '../static_galleries/select_stories_section';
import { Header } from '../../blocks/full_screen_modal/header.component';
import CloseIcon from '../../../assets/close-icon.svg';
import { GallerySortableGrid } from '../gallery_sortable_grid';
import { SectionActionBar } from '../static_galleries/section_action_bar';
import { EmptyState } from '../../blocks/empty_state';
import { GalleryEmbed } from '../gallery_embed';
import { HydratedGallery } from '../../../redux/galleries/galleries.types';
import { scrollToTopGenerator } from '../../../utils';
import { useCallbackRef } from '../../hooks/useCallbackRef';
import { GalleryAdditionalInfo } from '../static_galleries/gallery_additional_info';
import { DirtyStateModal } from '../static_galleries/dirty_state_modal';
import { ControlError } from '../../blocks/controls.types';

export interface StaticGalleryEditProps extends LocalizeContextProps {
  selectedStories?: Story[];
  modifiedStories: Story[];
  selectedGallery: HydratedGallery | null;
  updateGalleryLabel: (label: string) => void;
  updateGalleryList: (storyList: Story[]) => void;
  error: EditError;
  label?: string;
  resetGalleryState: () => void;
  updateSelectedList: (stories: Story[]) => void;
  maxSelectableStories: number;
}

export const INPUT_NAME = 'galleryNameField';

export enum ACTION_TYPES {
  ADD = 'add',
  REMOVE = 'remove',
  RESET = 'reset',
  RE_INSTANTIATE = 're-instantiate',
}

export interface ActionProp {
  type: string;
  story?: Story;
  story_id?: string;
}

const StaticGalleryEditComponent: VoidFunctionComponent<StaticGalleryEditProps> = ({
  selectedStories = [],
  modifiedStories,
  selectedGallery,
  updateGalleryLabel,
  updateGalleryList,
  error,
  label,
  translate,
  resetGalleryState,
  updateSelectedList,
  maxSelectableStories,
}) => {
  const [modalRefValue, setModalRef] = useCallbackRef<HTMLElement>();
  const [showDirtyModal, setShowDirtyModal] = useState(false);
  const [Modal, toggle] = useModal({ ref: setModalRef });

  const selectedStoriesReducer = (state: Story[], action: ActionProp) => {
    switch (action.type) {
      case ACTION_TYPES.ADD:
        return action.story ? [...state, action.story] : state;
      case ACTION_TYPES.REMOVE:
        return state.filter((story) => {
          return story.uuid !== action.story_id;
        });
      case ACTION_TYPES.RESET:
      default:
        return selectedStories;
    }
  };

  const [tempUpdatedSelectedStories, dispatch] = useReducer(
    selectedStoriesReducer,
    selectedStories,
  );

  const isSelectedStoryListPristine = isStoryListPristine(
    tempUpdatedSelectedStories,
    selectedStories,
  );
  const getTranslation = useTranslateStrings(translate);
  const numberGalleryStories = selectedStories?.length ?? 0;

  useEffect(() => {
    return () => resetGalleryState();
  }, []);

  const [inputError, setInputError] = useState<ControlError | undefined>(
    undefined,
  );

  useEffect(() => {
    if (error) {
      setInputError({
        message: getTranslation(error.translateId),
      });
    }
  }, [error]);

  const handleSave = () => {
    updateSelectedList(tempUpdatedSelectedStories);
    toggle();
  };

  const handleToggle = () => {
    dispatch({ type: ACTION_TYPES.RESET });
    toggle();
  };

  const handleModalClose = () => {
    if (!isSelectedStoryListPristine) {
      return setShowDirtyModal(true);
    }
    return handleToggle();
  };

  const handleModalOpen = () => {
    dispatch({ type: ACTION_TYPES.RE_INSTANTIATE });
    toggle();
  };

  const handleConfirmModalAccept = () => {
    setShowDirtyModal(false);
    handleToggle();
  };

  const getDescriptionContent = () => (
    <p>
      {getTranslation(`${GALLERY_DETAILS_LANGUAGE_BASE}.storyListDescription`)}
    </p>
  );

  const handleScrollToTop = scrollToTopGenerator(modalRefValue);

  return (
    <div className="static-gallery-edit-content">
      <div className="static-gallery-edit-content__name">
        <Input
          name={INPUT_NAME}
          label={getTranslation(`${GALLERY_DETAILS_LANGUAGE_BASE}.nameLabel`)}
          placeholder={getTranslation(
            `${GALLERY_DETAILS_LANGUAGE_BASE}.nameLabel`,
          )}
          onChange={updateGalleryLabel}
          value={label}
          error={inputError}
        />
      </div>
      <SectionActionBar
        title={getTranslation(
          `${GALLERY_DETAILS_LANGUAGE_BASE}.storyListTitle`,
        )}
        descriptionContent={getDescriptionContent()}
        actionName={getTranslation(
          `${GALLERY_DETAILS_LANGUAGE_BASE}.storyListActionName`,
        )}
        actionVariant={BUTTON_VARIANT.OUTLINE}
        actionColorVariant={BUTTON_COLOR_VARIANT.SECONDARY}
        extraActionContent={
          <GalleryAdditionalInfo numberStories={numberGalleryStories ?? 0} />
        }
        isButtonRequired={modifiedStories.length > 0}
        onActionClick={handleModalOpen}
      />
      {selectedStories?.length ? (
        <GallerySortableGrid
          storyList={selectedStories}
          updateGalleryStories={updateGalleryList}
        />
      ) : (
        <EmptyState
          className="static-gallery-edit-content__empty_state"
          title={getTranslation('galleries.emptyState.staticGalleryTitle')}
          description={getTranslation('galleries.emptyState.staticGalleryDesc')}
          buttonText={getTranslation(
            'galleries.emptyState.staticGalleryButton',
          )}
          onClick={handleModalOpen}
          actionVariant={BUTTON_VARIANT.OUTLINE}
          actionColorVariant={BUTTON_COLOR_VARIANT.SECONDARY}
        />
      )}
      <GalleryEmbed galleryId={selectedGallery?.id} />
      <Modal
        type={MODAL_TYPES.FULL_SCREEN}
        className="static_gallery"
        disableEscapeKeyDown={true}
      >
        <LoadStateData
          actionTypes={STORIES_LOAD_TYPES}
          loading={<LoadingGif size="large" />}
          failure={<div>error</div>}
          thunk={getStoriesSearch({
            searchFilters: null,
            pagination: { pageSize: 20 },
            loadingType: STORY_SEARCH_LOAD_TYPES.INIT,
          })}
        >
          <Header>
            <div className="static-gallery-edit-content__header__content">
              <Button
                onClick={handleModalClose}
                ariaLabel="close modal"
                variant={BUTTON_VARIANT.GHOST}
              >
                <CloseIcon className="navbar__button__icon" />
                <span className="navbar__button-description">
                  {getTranslation('general.buttons.close')}
                </span>
              </Button>
            </div>
          </Header>
          <div className="static-gallery-edit-content__select-stories-container">
            <SelectStoriesSection
              handleNextStep={handleSave}
              actionButtonName={getTranslation(
                'galleries.manualGalleries.galleryDetails.updateGalleryStories.confirmButton',
              )}
              description={getTranslation(
                'galleries.manualGalleries.galleryDetails.updateGalleryStories.description',
                {
                  numStories: maxSelectableStories,
                },
              )}
              selectedStoriesSource={tempUpdatedSelectedStories}
              addStory={(story: Story) =>
                dispatch({ type: ACTION_TYPES.ADD, story })
              }
              removeStory={(story_id: string) =>
                dispatch({ type: ACTION_TYPES.REMOVE, story_id })
              }
              handleScrollToTop={handleScrollToTop}
              actionDisabled={isSelectedStoryListPristine}
              scrollElement={modalRefValue}
            />
          </div>
          <DirtyStateModal
            open={showDirtyModal}
            handleClose={() => setShowDirtyModal(false)}
            handleAccept={handleConfirmModalAccept}
          />
        </LoadStateData>
      </Modal>
    </div>
  );
};

export const StaticGalleryEdit = withLocalize(StaticGalleryEditComponent);
