import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import './download_story.scss';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import {
  Button,
  BUTTON_COLOR_VARIANT,
  BUTTON_VARIANT,
} from '../../../blocks/button';
import { TEXT_VARIANTS, Text } from '../../../blocks/text';
import { useModal } from '../../../hooks/use_modal/use_modal';
import { Tooltip } from '../../../blocks/tooltip/tool_tip.component';
import {
  TOOLTIP_ACTIONS,
  TOOLTIP_PLACEMENT,
  TOOLTIP_VARIANT,
} from '../../../blocks/tooltip/tool_tip.types';
import DownloadStoryGif from '../../../../assets/gif_downloadStory.mp4';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../../blocks/icon';
import { StoryExport } from '../../story_export';
import { Story } from '../../story/story.types';

interface DownloadStoryProps extends LocalizeContextProps {
  story: Story;
  isChallengeStory: boolean;
}
const DownloadStoryComponent = ({
  story,
  isChallengeStory,
  translate,
}: DownloadStoryProps) => {
  const getTranslation = useTranslateStrings(translate);
  const [Modal, toggle, isExportOpen] = useModal();

  return (
    <>
      <StoryExport
        story={story}
        isChallengeStory={isChallengeStory}
        Modal={Modal}
        toggle={toggle}
        isOpen={isExportOpen}
      />
      <div className="download_story">
        <div className="download_story__title_container">
          <Text className="download_story__title" variant={TEXT_VARIANTS.B1}>
            {getTranslation('stories.modal.storyDownload.downloadSectionTitle')}
          </Text>
          <Tooltip
            id="story_embed__tooltip"
            placement={TOOLTIP_PLACEMENT.BOTTOM}
            variant={TOOLTIP_VARIANT.GIF_MOBILE}
            content={DownloadStoryGif}
            triggerAction={TOOLTIP_ACTIONS.HOVER}
            arrow={false}
          >
            <Icon
              className="story_embed__tooltip_icon"
              iconKey={ICON_NAMES.INFO_CIRCLE}
              size={ICON_SIZES.LARGE}
            />
          </Tooltip>
        </div>

        <Text
          className="download_story__description"
          variant={TEXT_VARIANTS.B2}
        >
          {getTranslation('stories.modal.storyDownload.downloadSectionBody')}
        </Text>

        <Button
          variant={BUTTON_VARIANT.OUTLINE}
          color={BUTTON_COLOR_VARIANT.SECONDARY}
          onClick={toggle}
          ariaLabel={getTranslation('stories.modal.storyDownload.ariaLabel')}
        >
          {getTranslation('stories.modal.storyDownload.downloadAction')}
        </Button>
      </div>
    </>
  );
};

export const DownloadStory = withLocalize(DownloadStoryComponent);
