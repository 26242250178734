import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Info = function Info(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Info__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M958.3,41.7v916.7H41.7V0H0v1000h1000V0l0,0H208.3v41.7H958.3 M541.7,750h-83.3V416.7h83.3V750z M541.7,333.3h-83.3V250h83.3V333.3z' // eslint-disable-line max-len
    })
  );
};

Info.propTypes = propTypes;

export default Info;