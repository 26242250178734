import React from 'react';
import { LoadingGif } from '../../blocks/LoadingGif/LoadingGif.component';
import { Carousel } from '../../blocks/carousel/carousel.component';
import { GalleryPreviewEmpty } from './gallery_preview_empty.component';
import { CarouselImage } from '../../blocks/carousel/carousel.types';

interface GalleryPreviewGenerateProps {
  isLoadingPreview: boolean;
  stories: CarouselImage[];
  showNoStories: boolean;
}

const LoadingOverlay = () => {
  return (
    <div className="gallery_preview__loading">
      <LoadingGif size="small" />
    </div>
  );
};

export const GalleryPreviewGenerate = ({
  isLoadingPreview,
  stories,
  showNoStories,
}: GalleryPreviewGenerateProps) => (
  <div className="gallery_preview__generate">
    {isLoadingPreview ? (
      <LoadingOverlay />
    ) : (
      <React.Fragment>
        {stories.length ? (
          <Carousel imageList={stories} showTitle={true} />
        ) : (
          <GalleryPreviewEmpty showNoStories={showNoStories} />
        )}
      </React.Fragment>
    )}
  </div>
);

GalleryPreviewGenerate.displayName = 'GalleryPreviewGenerate';
