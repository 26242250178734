import { createSelector } from 'reselect';
import { PUBLISHER_ROLES } from '../../react/features/publisher_label/publisher_label.types';
import { AppState } from '../../types';
import { Publisher, PUBLISHER_TYPES, USER_TYPE } from './user.types';
import { FEATURE_FLAGS } from '../../react/blocks/authwrapper/auth_wrapper.types';

export const selectUserState = (state: AppState) => state.user;
export const selectUserId = (state: AppState) => state.user.userId;

export const selectCurrentPublisher = (state: AppState) =>
  state.user.currentPublisher;

export const selectCurrentPublisherId = (state: AppState) =>
  state.user.currentPublisher?.publisherId ?? '';

export const selectCurrentNetworkId = (state: AppState) =>
  state.user.currentPublisher?.networkId ?? '';

export const selectPublisherIds = (state: AppState) => state.user.publisherIds;

export const selectNetworkPublisherData = (state: AppState) => state.user.data;

export const selectPublisherData = createSelector(
  selectNetworkPublisherData,
  (data) => {
    return Object.values(data).reduce((acc: Publisher[], currentNetwork) => {
      return [...acc, ...currentNetwork.publishers];
    }, []);
  },
);

export const selectOrganizationPublishers = createSelector(
  selectNetworkPublisherData,
  (data) => {
    const organizationPublishers = Object.values(data).reduce(
      (acc: Publisher[], currentNetwork) => {
        const orgPublishers = currentNetwork.publishers.filter(
          (p) => p.publisherType === PUBLISHER_TYPES.ORGANIZATION,
        );
        return [...acc, ...orgPublishers];
      },
      [],
    );

    const duplicatePublishersLookup = organizationPublishers.reduce<
      Record<string, number>
    >((duplicatePublishers, currentPublisher) => {
      duplicatePublishers[currentPublisher.publisherId] =
        // eslint-disable-next-line no-plusplus
        ++duplicatePublishers[currentPublisher.publisherId] || 0;
      return duplicatePublishers;
    }, {});

    // For cities that are partners but also use creator services
    // belong to multiple networks. Hence they show up multiple times
    // in the publisher dropdown. We need to find these duplicate entries
    // and mark the entries with role 'owner' as 'isCreatorServices'
    // so that users can differentiate the accounts
    const duplicatePublishers = organizationPublishers
      .filter((p) => duplicatePublishersLookup[p.publisherId])
      .map((p) => {
        if (p.role === PUBLISHER_ROLES.OWNER) {
          p.isCreatorServices = true;
        }
        return p;
      });

    const uniquePublishers = organizationPublishers.filter(
      (p) => !duplicatePublishers.includes(p),
    );

    return [...uniquePublishers, ...duplicatePublishers];
  },
);

// A user that ONLY has access to individual/localhood publishers
export const selectIsLocalhoodCreator = createSelector(
  selectOrganizationPublishers,
  (data) => {
    return !data.length;
  },
);

export const selectCurrentPublisherFeatureFlags = createSelector(
  selectCurrentPublisher,
  (currentPublisher: Publisher): FEATURE_FLAGS[] | FEATURE_FLAGS =>
    currentPublisher.featureFlags ?? [],
);

export const selectCurrentPublisherRole = createSelector(
  selectCurrentNetworkId,
  selectCurrentPublisherId,
  selectNetworkPublisherData,
  (currentNetworkId, currentPublisherId, networkPublisherData) => {
    const currentPublishers = networkPublisherData[currentNetworkId].publishers;
    const currentPublisherData = currentPublishers.filter(
      (publisher: Publisher) => publisher.publisherId === currentPublisherId,
    );
    return currentPublisherData?.length >= 1
      ? currentPublisherData[0].role
      : undefined;
  },
);

export const selectIsPendoInitialized = (state: AppState) =>
  state.user.isPendoInitialized;

export const selectIsUserNetworkOwner = createSelector(
  selectCurrentPublisherRole,
  (role) => role === PUBLISHER_ROLES.OWNER,
);

export const selectIsUserInternal = createSelector(
  selectUserState,
  (user) => user.userType === USER_TYPE.INTERNAL,
);

export const selectIsLocalhoodOwner = createSelector(
  selectIsUserInternal,
  selectCurrentPublisherId,
  (isInternal, currentPublisherId) => {
    return (
      isInternal &&
      currentPublisherId === process.env.CHALLENGE_MODERATOR_PUBLISHER
    );
  },
);

export const selectUserAcceptance = (state: AppState) => state.user.acceptedToS;

// Doesn't belong here but we currently have no other
// way of propagating state
export const selectIsAccountSettingsEnabled = () =>
  process.env.ENABLE_ACCOUNT_SETTINGS === 'true';
