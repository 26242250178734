import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var FacebookShare = function FacebookShare(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      id: 'facebookShare__icon',
      height: size,
      fill: color,
      className: 'cr__icon',
      'aria-hidden': true,
      viewBox: '0 0 14 14'
    },
    React.createElement('path', {
      d: 'M8.4 9.168v3.802L14 6.474 8.4 0v3.693C2.957 4.628.787 9.255 0 13.904c1.942-3.258 4.515-4.736 8.4-4.736z' // eslint-disable-line max-len
    }),
    React.createElement('path', {
      d: 'M8.4 9.168v3.802L14 6.474 8.4 0v3.693C2.957 4.628.787 9.255 0 13.904c1.942-3.258 4.515-4.736 8.4-4.736z' // eslint-disable-line max-len
    })
  );
};

FacebookShare.propTypes = propTypes;

export default FacebookShare;