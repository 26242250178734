import { AxiosResponse } from 'axios';
import axios from './axios_config';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import {
  PublisherResponse,
  UserResponse,
  GetUserResponse,
  NetworkPublisher,
  NetworkResponse,
  Network,
} from '../redux/user/user.types';
import { getErrorMessage } from '../utils';
import { PUBLISHER_ROLES } from '../react/features/publisher_label/publisher_label.types';

export const getUser = async (): Promise<GetUserResponse> => {
  const cachedPublisher = localStorage.getItem('currentPublisher');
  const { publisherId, networkId } = JSON.parse(cachedPublisher || '{}');
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.ME,
  });
  try {
    const response: AxiosResponse<UserResponse> = await axios.get<UserResponse>(
      url,
      {
        params: {
          cached_publisher_id: publisherId,
          cached_network_id: networkId,
        },
      },
    );
    const { data } = response;

    const publisherIds =
      data?.publishers.map(
        (publisher: PublisherResponse) => publisher.publisher_id,
      ) ?? [];

    const isCreatorServices =
      publisherIds.filter((pId) => pId === data?.current_publisher.id).length >
      1
        ? data?.current_publisher.role === PUBLISHER_ROLES.OWNER
        : false;

    const shapedResponse: GetUserResponse = {
      userId: data?.user_id ?? '',
      userType: data?.user_type ?? '',
      currentPublisher: {
        publisherId: data?.current_publisher.id ?? '',
        networkId: data?.current_publisher.network_id ?? '',
        label: data?.current_publisher.label ?? '',
        avatarUrl: data?.current_publisher.avatar_url ?? '',
        featureFlags: data?.current_publisher.feature_flags ?? [],
        publisherType: data?.current_publisher.publisher_type,
        isCreatorServices,
      },
      publisherIds,
      publishers:
        data?.publishers.map(
          (publisher: PublisherResponse): NetworkPublisher => ({
            networkId: publisher.network_id ?? '',
            publisherId: publisher.publisher_id,
            role: publisher.role,
            publisherType: publisher.publisher_type,
          }),
        ) ?? [],
      networks:
        data?.networks.map(
          (network: NetworkResponse): Network => ({
            networkId: network.network_id ?? '',
            publishers: network.publishers.map((publisher) => ({
              publisherId: publisher.publisher_id,
              role: publisher.role,
              publisherType: data?.publishers.find(
                (p) => p.publisher_id === publisher.publisher_id,
              )?.publisher_type,
            })),
          }),
        ) ?? [],
    };
    return shapedResponse;
  } catch (err) {
    throw new Error(`Failed to get user: ${getErrorMessage(err)}`);
  }
};
