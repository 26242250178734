import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var GalleryFullscreenDetail = function GalleryFullscreenDetail(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'GalleryFullscreenDetail__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1496.4 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1496.4,996h-432.6V663.4h432.6V996z M1492.9,0h-432.5v598.8h432.5V0z M998.1,1.9V1000H0V1.9H998.1z M49.9,950.1h149.8\t\t\tl174-199.6l99.9,99.8l274.7-274.5l199.8,174.7V51.8H49.9V950.1z M318.3,413.9c-51.7,0-93.5,41.8-93.5,93.5s41.9,93.5,93.5,93.5\t\ts93.5-41.8,93.5-93.5S369.9,413.9,318.3,413.9z' // eslint-disable-line max-len
    })
  );
};

GalleryFullscreenDetail.propTypes = propTypes;

export default GalleryFullscreenDetail;