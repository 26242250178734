import { NavigateFunction } from 'react-router';
import { ROUTES } from '../../../types';
import { DeleteStoryResponse } from '../../../http/story_delete.thunk';
import { Story } from '../story/story.types';
import { UseToastProps } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../blocks/toast';

export const getHandleStoryDelete = (
  story: Story | null,
  onDeleteStory: (story: Story | null) => Promise<DeleteStoryResponse>,
  setLoading: (isLoading: boolean) => void,
  showToast: (props: UseToastProps) => void,
  navigate: NavigateFunction,
  successMessage: string,
  failureMessage: string,
) => {
  return async () => {
    setLoading(true);
    try {
      await onDeleteStory(story);
      navigate(ROUTES.STORIES_LIST, { state: { deletedUUID: story?.uuid } });
      showToast({ message: successMessage, type: TOAST_TYPES.SUCCESS });
    } catch (error) {
      setLoading(false);
      showToast({ message: failureMessage, type: TOAST_TYPES.ERROR });
    }
  };
};
