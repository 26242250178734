import React, { VoidFunctionComponent } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useTranslateStrings } from '../../../../hooks/useTranslateStrings';
import { Story } from '../../../story/story.types';
import { DetailedStorySelectable } from '../../../detailed_story/detailed_story_selectable';
import { OverloadedDetailedStorySelectableProps } from './overloaded_selectable_story_card.types';
import { useToast } from '../../../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../../../blocks/toast';

const OverloadedDetailedStorySelectableComponent: VoidFunctionComponent<OverloadedDetailedStorySelectableProps> = (
  props,
) => {
  const {
    maxNumberStoriesSelected,
    addStory,
    removeStory,
    selectedStories,
    translate,
  } = props;
  const getTranslation = useTranslateStrings(translate);
  const { showToast } = useToast();

  const handleOnCheckboxClick = (story: Story, isSelected: boolean) => {
    if (isSelected) {
      return removeStory(story.uuid);
    }

    if (maxNumberStoriesSelected) {
      return showToast({
        message: `${getTranslation(
          'galleries.manualGalleries.selectStoriesSection.storiesSelectedErrorTooMany',
          { numStories: selectedStories.length },
        )}`,
        type: TOAST_TYPES.WARNING,
      });
    }

    return addStory(story);
  };

  const checkStorySelected = (story_uuid: string) => {
    return selectedStories.some((story) => {
      return story.uuid === story_uuid;
    });
  };

  return (
    <DetailedStorySelectable
      {...props}
      checkStorySelected={checkStorySelected}
      handleOnCheckboxClick={handleOnCheckboxClick}
    />
  );
};

export const OverloadedDetailedStorySelectable = withLocalize(
  OverloadedDetailedStorySelectableComponent,
);
