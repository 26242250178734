import React from 'react';
import './use_story.scss';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { Text, TEXT_VARIANTS } from '../../../blocks/text';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { Story } from '../../story/story.types';
import { StoryEmbed } from '../story_embed/story_embed.component';
import { DownloadStory } from '../download_story';
import { AuthWrapper } from '../../../blocks/authwrapper/auth_wrapper.connect';
import { FEATURE_FLAGS } from '../../../blocks/authwrapper/auth_wrapper.types';

interface UseStoryProps extends LocalizeContextProps {
  story: Story;
  currentPublisherId: string;
}

export const UseStoryComponent = ({
  translate,
  story,
  currentPublisherId,
}: UseStoryProps) => {
  const getTranslation = useTranslateStrings(translate);
  const isDraft = story.status === 'draft';
  const isStoryByCurrPublisher = currentPublisherId === story?.publisher.slug;
  const isChallengeStory = story.is_challenge_story;

  return (
    <div className="use_story">
      <Text variant={TEXT_VARIANTS.H3}>
        {getTranslation('stories.form.useStory.title')}
      </Text>
      <div className="use_story__container">
        <AuthWrapper featureFlags={FEATURE_FLAGS.SINGLE_EMBED}>
          <StoryEmbed storyId={story.uuid} isDraft={isDraft} />
        </AuthWrapper>
        {(isStoryByCurrPublisher || isChallengeStory) && (
          <DownloadStory
            story={story}
            isChallengeStory={isChallengeStory ?? false}
          />
        )}
      </div>
    </div>
  );
};

export const UseStorySection = withLocalize(UseStoryComponent);
