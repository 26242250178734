import React from 'react';
import { IconProps } from '../icon.types';

export const Plus = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.15186 10.0068C3.15186 10.4136 3.49219 10.7456 3.89062 10.7456H9.26123V16.1162C9.26123 16.5146 9.59326 16.855 10 16.855C10.4067 16.855 10.7471 16.5146 10.7471 16.1162V10.7456H16.1094C16.5078 10.7456 16.8481 10.4136 16.8481 10.0068C16.8481 9.6001 16.5078 9.25977 16.1094 9.25977H10.7471V3.89746C10.7471 3.49902 10.4067 3.15869 10 3.15869C9.59326 3.15869 9.26123 3.49902 9.26123 3.89746V9.25977H3.89062C3.49219 9.25977 3.15186 9.6001 3.15186 10.0068Z" />
    </svg>
  );
};
