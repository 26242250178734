import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { StoryListTabsBarProps } from './story_list_tabs_bar.types';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { StoriesFilterTab } from '../../../../redux/stories/stories.types';
import {
  getStoriesSearch,
  STORY_SEARCH_LOAD_TYPES,
} from '../../../../http/stories_search.thunks';
import { storiesSearchStateToFilterParams } from '../../stories_search_form.component/stories_search_form.utils';
import { storiesUpdateFilterTabAction } from '../../../../redux/stories/stories.actions';
import { TabsBar } from '../../../blocks/tabs_bar';

const StoryListTabsBarComponent = ({
  translate,
  selectedFilterTab,
  appliedFilters,
  searchQuery,
}: StoryListTabsBarProps) => {
  const dispatch = useDispatch();
  const getTranslation = useTranslateStrings(translate);

  const handleTabClick = (value: StoriesFilterTab) => {
    // return out of the handler if selectedTab is clicked again
    if (value === selectedFilterTab) {
      return;
    }

    const searchFilters = storiesSearchStateToFilterParams(
      searchQuery,
      appliedFilters,
      value,
    );

    dispatch(storiesUpdateFilterTabAction(value));
    dispatch(
      getStoriesSearch({
        searchFilters,
        pagination: { pageSize: 20 },
        loadingType: STORY_SEARCH_LOAD_TYPES.SEARCH,
      }),
    );
  };

  const options = [
    {
      label: getTranslation('stories.storyFilterTabs.myNetworks'),
      value: StoriesFilterTab.MY_NETWORK,
      onClick: () => handleTabClick(StoriesFilterTab.MY_NETWORK),
    },
    {
      label: getTranslation('stories.storyFilterTabs.creatorStories'),
      value: StoriesFilterTab.CREATOR_STORIES,
      onClick: () => handleTabClick(StoriesFilterTab.CREATOR_STORIES),
    },
    {
      label: getTranslation('stories.storyFilterTabs.all'),
      value: StoriesFilterTab.ALL,
      onClick: () => handleTabClick(StoriesFilterTab.ALL),
    },
  ];

  return <TabsBar options={options} selectedValue={selectedFilterTab} />;
};

export default withLocalize(StoryListTabsBarComponent);
