import React, { VoidFunctionComponent } from 'react';
import { Translate } from 'react-localize-redux';
import { LoadStateData } from '../../blocks/load_data/load_state_data.component';
import { LoadingGif } from '../../blocks/LoadingGif/LoadingGif.component';
import './galleries_list.scss';
import { GALLERIES_LOAD_TYPES } from './galleries_list.types';
import { Page } from '../../blocks/page/page.component';
import { GalleryList } from '../../features/gallery_list';
import { getGalleries } from '../../../http/galleries_list.thunks';
import { GalleryListErrorState } from '../../features/gallery_list_error_state/galllery_list_error_state.connect';
import { PageHeader } from '../../blocks/page_header/page_header.component';
import { PageTitle } from '../../blocks/pageTitle/pageTitle.component';
import { HydratedGallery } from '../../../redux/galleries/galleries.types';

export interface GalleryListPageProps {
  galleries: HydratedGallery[];
}

export const GalleryListPage: VoidFunctionComponent<GalleryListPageProps> = ({
  galleries,
}) => {
  return (
    <Page className="galleryListPage">
      <LoadStateData
        actionTypes={GALLERIES_LOAD_TYPES}
        loading={<LoadingGif size="large" />}
        failure={
          <GalleryListErrorState
            rifficonType="searching"
            size={150}
            errorTitle={<Translate id="galleries.errors.oops" />}
          />
        }
        thunk={getGalleries}
      >
        <PageHeader>
          <PageTitle />
        </PageHeader>
        <GalleryList />
      </LoadStateData>
    </Page>
  );
};

GalleryListPage.displayName = 'GalleryListPage';
