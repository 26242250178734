import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Icon from '../Icon/Icon';

var propDescriptions = {
  id: 'HTML id to link label.',
  icon: 'Icon in toggle button.',
  iconPosition: 'String indicating the icon position for the toggle button.',
  isDisabled: 'Boolean value of the toggle button for disabled state.',
  label: 'Label for the toggle button.',
  onChange: 'Callback fired on button click.',
  value: 'Boolean value of the toggle button for on/off state.',
  className: 'Optional className'
};

var propTypes = {
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  iconPosition: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired
};

var defaultProps = {
  isDisabled: false,
  className: '',
  icon: '',
  iconPosition: 'left'
};

var ToggleButton = function (_React$Component) {
  _inherits(ToggleButton, _React$Component);

  function ToggleButton(props) {
    _classCallCheck(this, ToggleButton);

    var _this = _possibleConstructorReturn(this, (ToggleButton.__proto__ || _Object$getPrototypeOf(ToggleButton)).call(this, props));

    _this.handleChange = _this.handleChange.bind(_this);
    return _this;
  }

  _createClass(ToggleButton, [{
    key: 'handleChange',
    value: function handleChange(e) {
      var _props = this.props,
          isDisabled = _props.isDisabled,
          onChange = _props.onChange;

      if (isDisabled) return;
      onChange(e.target.checked);
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          id = _props2.id,
          icon = _props2.icon,
          iconPosition = _props2.iconPosition,
          isDisabled = _props2.isDisabled,
          label = _props2.label,
          value = _props2.value,
          className = _props2.className;

      var buttonClasses = classNames('toggleButton', className, {
        'toggleButton--checked': value,
        disabled: isDisabled,
        'icon-left': iconPosition === 'left',
        'icon-right': iconPosition === 'right'
      });
      return React.createElement(
        'div',
        { className: buttonClasses },
        React.createElement('input', {
          type: 'checkbox',
          id: id,
          className: 'toggleButton__input',
          checked: value,
          onChange: this.handleChange
        }),
        React.createElement(
          'label',
          {
            htmlFor: id,
            className: classNames('toggleButton__label', { 'toggleButton--checked': value })
          },
          icon ? React.createElement(Icon, { className: 'toggleButton__icon', type: icon }) : null,
          label
        )
      );
    }
  }]);

  return ToggleButton;
}(React.Component);

export default ToggleButton;


ToggleButton.propDescriptions = propDescriptions;
ToggleButton.propTypes = propTypes;
ToggleButton.defaultProps = defaultProps;