import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useTranslateStrings } from '../../../hooks/useTranslateStrings';
import { copyToClipboard } from '../../gallery_list_grid_card/gallery_list_grid_card.utils';
import { generateEmbedCode } from '../story_embed/story_embed.utils';
import { DetailedStoryMenuProps } from './detailed_story_menu.types';
import { useModal } from '../../../hooks/use_modal/use_modal';
import { useToggle } from '../../../hooks/useToggle';
import { StoryExport } from '../../story_export';
import { Menu, MenuItem } from '../../../blocks/menu';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../../blocks/icon';
import { Button, BUTTON_VARIANT } from '../../../blocks/button';
import { AssignStoryModal } from '../../challenges/assign_story_modal.component';
import { userHasAccess } from '../../../blocks/authwrapper/auth_wrapper.utils';
import { FEATURE_FLAGS } from '../../../blocks/authwrapper/auth_wrapper.types';
import { selectCurrentPublisherFeatureFlags } from '../../../../redux/user/user.selectors';
import { useToast } from '../../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../../blocks/toast';
import './detailed_story_menu.scss';

const DetailedStoryMenuComponent = ({
  translate,
  story,
  previewSrc,
  publisherId,
  currentPublisherId,
  extraMenuItems,
  status,
  isChallengeModerator,
  isChallengeStory,
}: DetailedStoryMenuProps) => {
  const { showToast } = useToast();
  const { uuid } = story;
  const embedCodeText = generateEmbedCode(uuid);
  const getTranslation = useTranslateStrings(translate);
  const [Modal, toggle, isExportOpen] = useModal();
  const [challengeModal, challengeToggle, isOpen] = useModal();
  const {
    toggleState: menuState,
    SetStateToggle: toggleMenu,
    SetStateFalse: closeMenu,
  } = useToggle(false);

  const copyStoryId = {
    onSuccess: () =>
      showToast({
        message: `${getTranslation('stories.embed.copied')}`,
        type: TOAST_TYPES.SUCCESS,
      }),
    onFailure: () =>
      showToast({
        message: `${getTranslation('stories.errors.copyEmbed')}`,
        type: TOAST_TYPES.ERROR,
      }),
  };

  const ExportModal = (
    <StoryExport
      story={story}
      isChallengeStory={isChallengeStory}
      Modal={Modal}
      toggle={toggle}
      isOpen={isExportOpen}
    />
  );

  const challengeModeratorItem = isChallengeModerator
    ? [
        {
          id: 1,
          text: getTranslation('stories.challenges.submit'),
          action: challengeToggle,
        },
      ]
    : [];

  const storyEditItem =
    currentPublisherId === publisherId || isChallengeModerator
      ? [
          {
            id: 3,
            text: getTranslation('stories.contextMenu.edit'),
            url: `${
              process.env.STORY_CREATOR_URL
            }/editor/${uuid}?publisher-slug=${encodeURIComponent(publisherId)}`,
            args: {
              rel: 'noopener noreferrer',
            },
          },
        ]
      : [];

  const featureFlags = useSelector(selectCurrentPublisherFeatureFlags);

  const includeEmbed = userHasAccess(featureFlags, FEATURE_FLAGS.SINGLE_EMBED);
  let embedCodeMenuItem: MenuItem[] = [];

  if (status === 'published' && includeEmbed) {
    embedCodeMenuItem = [
      {
        id: 5,
        text: getTranslation('stories.contextMenu.copyEmbed'),
        action: () =>
          copyToClipboard(
            embedCodeText,
            copyStoryId.onSuccess,
            copyStoryId.onFailure,
          ),
      },
    ];
  }

  let extraItems: MenuItem[] = [];
  if (extraMenuItems) {
    const id = 6;
    extraItems = extraMenuItems.map((item, index) => {
      return { ...item, id: id + index };
    });
  }

  let downloadMenuItem: MenuItem[] = [];
  const storyDownloadRequired =
    currentPublisherId === publisherId || isChallengeStory;
  if (storyDownloadRequired) {
    downloadMenuItem = [
      {
        id: 9,
        text: getTranslation('stories.contextMenu.download'),
        action: toggle,
      },
    ];
  }

  const menuItems: MenuItem[] = [
    ...challengeModeratorItem,
    {
      id: 2,
      text: getTranslation('stories.contextMenu.manageStory'),
      url: `/stories/${uuid}`,
    },
    ...storyEditItem,
    {
      id: 4,
      text: getTranslation('stories.contextMenu.preview'),
      url: previewSrc,
      args: {
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    },
    ...embedCodeMenuItem,
    ...extraItems,
    ...downloadMenuItem,
  ];

  return (
    <React.Fragment>
      <Menu
        list={menuItems}
        isOpen={menuState}
        onClose={closeMenu}
        className="detailed_story--menu_wrapper"
      >
        <Button variant={BUTTON_VARIANT.GHOST} onClick={toggleMenu}>
          <Icon iconKey={ICON_NAMES.ELLIPSIS} size={ICON_SIZES.LARGE} />
        </Button>
      </Menu>
      {storyDownloadRequired && ExportModal}
      {isChallengeModerator && (
        <AssignStoryModal
          Modal={challengeModal}
          storyId={uuid}
          toggle={challengeToggle}
          isOpen={isOpen}
        />
      )}
    </React.Fragment>
  );
};

export const DetailedStoryMenu = withLocalize(DetailedStoryMenuComponent);
