import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

var propTypes = {
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isAutoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.node,
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
  size: PropTypes.number
};

var propDescriptions = {
  onChange: 'Hook for when the input\'s onChange event is called',
  onBlur: 'Optional function to be called when onblur event is triggered',
  onFocus: 'Optional function to be called when onfocus event is triggered',
  className: 'ClassName to be put on the container div',
  id: 'Id to be used for the input',
  type: 'Type of input to render: ex. text',
  isDisabled: 'Determines whether or not to render the input disabled',
  isRequired: 'Determines whether or not the input is required',
  isReadOnly: 'Determines whether or not the input can be edited',
  isAutoFocus: 'Determines whether or not the input should be autofocused on load. \n      This feature should be used sparingly, only in cases when it does not affect accessibility.',
  placeholder: 'Placeholder text for the input',
  error: 'Error message for the input',
  value: 'Value for the input',
  label: 'Label for the input',
  children: 'Any elements to be shown in the inputContainer div',
  ariaLabel: 'Optional prop that adds an aria-label to the input element itself',
  size: 'the height of the input in pixels (defaults to 32)'
};

var defaultProps = {
  type: 'text',
  value: '',
  size: 44
};

var Input = function (_React$Component) {
  _inherits(Input, _React$Component);

  function Input(props) {
    _classCallCheck(this, Input);

    var _this = _possibleConstructorReturn(this, (Input.__proto__ || _Object$getPrototypeOf(Input)).call(this, props));

    _this.handleChange = _this.handleChange.bind(_this);
    return _this;
  }

  _createClass(Input, [{
    key: 'handleChange',
    value: function handleChange(e) {
      this.props.onChange(e.target.value);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          children = _props.children,
          className = _props.className,
          isDisabled = _props.isDisabled,
          error = _props.error,
          id = _props.id,
          isRequired = _props.isRequired,
          placeholder = _props.placeholder,
          isReadOnly = _props.isReadOnly,
          isAutoFocus = _props.isAutoFocus,
          type = _props.type,
          value = _props.value,
          label = _props.label,
          onBlur = _props.onBlur,
          onFocus = _props.onFocus,
          ariaLabel = _props.ariaLabel,
          size = _props.size;

      var containerClasses = classNames('inputContainer', className, {
        required: isRequired,
        'inputContainer--error': error,
        'inputContainer--has-button': children
      });
      var inputClasses = classNames('inputContainer__input', {
        'inputContainer__input--has-value': !!value.length && !isDisabled,
        'inputContainer__input--readOnly': isReadOnly,
        'inputContainer__input--disabled': isDisabled
      });

      return React.createElement(
        'div',
        { className: containerClasses },
        label ? React.createElement(
          'label',
          { className: 'inputContainer__label', htmlFor: id },
          label,
          isRequired ? React.createElement(
            'span',
            { className: 'required' },
            ' *'
          ) : null
        ) : null,
        error ? React.createElement(
          'span',
          { className: 'inputContainer__error' },
          error
        ) : null,
        React.createElement('input', {
          'aria-label': ariaLabel,
          readOnly: isReadOnly,
          className: inputClasses,
          ref: function ref(c) {
            _this2.input = c;
          },
          id: id,
          name: id,
          type: type,
          onChange: this.handleChange,
          onBlur: onBlur,
          onFocus: onFocus,
          value: value,
          placeholder: placeholder,
          style: {
            height: size + 'px'
          },
          autoFocus: isAutoFocus // eslint-disable-line jsx-a11y/no-autofocus
        }),
        this.props.children && React.createElement(
          'span',
          { className: 'inputContainer__child' },
          this.props.children
        )
      );
    }
  }]);

  return Input;
}(React.Component);

export default Input;


Input.propTypes = propTypes;
Input.defaultProps = defaultProps;
Input.propDescriptions = propDescriptions;