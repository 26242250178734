import React from 'react';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { selectCurrentPublisherId } from '../../../redux/user/user.selectors';
import { PageTitle } from '../../blocks/pageTitle/pageTitle.component';
import { Button } from '../../blocks/button';
import { Page } from '../../blocks/page/page.component';
import { PageHeader } from '../../blocks/page_header/page_header.component';
import { NetworkGallery } from '../../features/network_gallery/network_gallery.component';
import { CreateGalleryBlock } from '../../features/gallery_create';

import './network.scss';

export const NetworkPage = () => {
  const publisherId = useSelector(selectCurrentPublisherId);
  const redirectToStoryCreator = () => {
    window.location.assign(
      `${
        process.env.STORY_CREATOR_URL
      }/editor/new?publisher-slug=${encodeURIComponent(publisherId)}`,
    );
  };
  return (
    <Page className="network-page">
      <PageHeader>
        <PageTitle />
        <div className="page-header__network-page__button">
          <Translate>
            {({ translate }) => {
              return (
                <React.Fragment>
                  <CreateGalleryBlock />
                  <Button onClick={redirectToStoryCreator}>
                    {translate('stories.button.createStory')}
                  </Button>
                </React.Fragment>
              );
            }}
          </Translate>
        </div>
      </PageHeader>
      <NetworkGallery />
    </Page>
  );
};

NetworkPage.displayName = 'networkPage';
