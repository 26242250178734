import React from 'react';
import { IconProps } from '../icon.types';

export const LocationFill = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.54834 11.5464L8.59961 11.5713C8.72412 11.5713 8.76562 11.6128 8.76562 11.7373L8.78223 17.7388C8.78223 18.9756 10.2681 19.2661 10.8242 18.0625L16.9585 4.87256C17.5146 3.66064 16.5601 2.85547 15.3979 3.40332L2.1333 9.5542C1.0708 10.0439 1.27832 11.5381 2.54834 11.5464Z" />
    </svg>
  );
};
