import { isEqual } from 'lodash';
import {
  UnhydratedGallery,
  HydratedGallery,
  GALLERY_TYPE,
  EditableStaticGalleryFields,
} from '../../../redux/galleries/galleries.types';
import { initialStaticGalleryState } from '../../../redux/galleries/galleries.reducer';

export const isDynamicGallery = (
  gallery: HydratedGallery | UnhydratedGallery,
) => {
  return !gallery.type || gallery.type === GALLERY_TYPE.DYNAMIC;
};

export const isGalleryStatePristine = (
  gallery: Partial<EditableStaticGalleryFields>,
) => isEqual(gallery, initialStaticGalleryState.gallery);
