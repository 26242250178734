import React, { useState, VoidFunctionComponent } from 'react';
import { isEmpty, isEqual, isNil } from 'lodash';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import {
  EditableStaticGalleryFields,
  HydratedGallery,
} from '../../../redux/galleries/galleries.types';
import { GalleryForm } from '../gallery_form';
import { GalleryPreview } from '../gallery_preview';
import { GalleryEmbed } from '../gallery_embed';
import { isDynamicGallery } from '../static_galleries_create';
import { GALLERY_FORM_TYPES } from '../gallery_form/gallery_form.types';
import { StaticGalleryEdit } from '../static_galleries_edit';
import { PageHeader } from '../../blocks/page_header/page_header.component';
import { PageTitle } from '../../blocks/pageTitle/pageTitle.component';
import { Button } from '../../blocks/button';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';
import { EditError } from './gallery_edit_content.types';
import { staticGalleryErrors } from './gallery_edit_content.utils';
import { LANGUAGE_BASE } from '../static_galleries/static_galleries.constants';
import { GalleryCreateEditResponse } from '../../../http/gallery_create.thunks';
import { useCustomPrompt } from '../../hooks/useCustomPrompt';
import {
  LOCATIONS_TYPE_TYPES,
  LOCATION_TYPE,
} from '../../../redux/locations/locations.types';
import { LoadStateData } from '../../blocks/load_data/load_state_data.component';
import { LoadingGif } from '../../blocks/LoadingGif/LoadingGif.component';
import { getLocationTypes } from '../../../http/location_types_list.thunks';
import { useToast } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../blocks/toast';

export interface GalleryEditContentProps extends LocalizeContextProps {
  gallery?: HydratedGallery | null;
  galleryEdits: Partial<EditableStaticGalleryFields>;
  updateGallery: () => Promise<GalleryCreateEditResponse>;
  label?: string;
}

export const checkGalleryPristine = (
  gallery: HydratedGallery,
  galleryEdits: Partial<EditableStaticGalleryFields>,
) => {
  if (isEmpty(galleryEdits)) {
    return true;
  }
  return Object.entries(galleryEdits).every((entry) => {
    const [key, value] = entry;
    return (
      isNil(value) ||
      isEqual(value, gallery[key as keyof EditableStaticGalleryFields])
    );
  });
};

export const GalleryEditContentComponent: VoidFunctionComponent<GalleryEditContentProps> = ({
  translate,
  gallery,
  galleryEdits,
  updateGallery,
  label,
}) => {
  if (!gallery) {
    throw new Error('No gallery present to edit');
  }
  const [isSaving, setIsSaving] = useState(false);
  const isDynamic = isDynamicGallery(gallery);
  const getTranslation = useTranslateStrings(translate);
  let isPristine = true;
  let error: EditError = null;
  const { showToast } = useToast();

  if (!isDynamic) {
    // let's not do this potentially expensive calculation when we aren't even using it
    isPristine = checkGalleryPristine(gallery, galleryEdits);
    error = staticGalleryErrors(isPristine, label);
  }

  const handleOnClick = async () => {
    const editedStoriesList = galleryEdits.story_ids ?? [];
    if (editedStoriesList.length < 2) {
      return showToast({
        message: `${getTranslation(
          `${LANGUAGE_BASE}.selectStoriesSection.storiesSelectedErrorTooFew`,
        )}`,
        type: TOAST_TYPES.ERROR,
      });
    }
    // Let's not kick off the async loading spinner until we know for sure we're taking
    // an async action
    setIsSaving(true);
    try {
      await updateGallery();
      setIsSaving(false);
      return showToast({
        message: `${getTranslation(`${LANGUAGE_BASE}.general.updateSuccess`, {
          galleryName: label,
        })}`,
        type: TOAST_TYPES.SUCCESS,
      });
    } catch (err) {
      setIsSaving(false);
      return showToast({
        message: `${getTranslation(`${LANGUAGE_BASE}.general.updateFailure`, {
          galleryName: label,
        })}`,
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  useCustomPrompt(
    getTranslation(`${LANGUAGE_BASE}.general.preventNavigation.description`),
    !isPristine,
  );
  return (
    <React.Fragment>
      <LoadStateData
        actionTypes={LOCATIONS_TYPE_TYPES}
        loading={<LoadingGif size="large" />}
        failure={<div> {getTranslation('galleries.errors.fail')}</div>}
        thunk={getLocationTypes}
      >
        <PageHeader className="gallery_edit_page__header">
          <PageTitle />
          {!isDynamic && (
            <Button
              loading={isSaving}
              onClick={handleOnClick}
              isDisabled={isPristine || Boolean(error)}
            >
              {getTranslation(
                'galleries.manualGalleries.galleryDetails.updateButton',
              )}
            </Button>
          )}
        </PageHeader>
        <div className="gallery_edit_page__body">
          {isDynamic && (
            <GalleryForm
              id={gallery.id}
              type={GALLERY_FORM_TYPES.EDIT}
              defaultLocationType={LOCATION_TYPE.STATE}
            >
              <div className="gallery_edit_page__body__col-right">
                <GalleryPreview />
                <GalleryEmbed galleryId={gallery.id} />
              </div>
            </GalleryForm>
          )}
          {!isDynamic && <StaticGalleryEdit error={error} />}
        </div>
      </LoadStateData>
    </React.Fragment>
  );
};

export const GalleryEditContent = withLocalize(GalleryEditContentComponent);
