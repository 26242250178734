import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Feed = function Feed(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'feed',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1061.26 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      { id: 'R1' },
      React.createElement(
        'g',
        { id: 'R1---Final' },
        React.createElement(
          'g',
          { id: 'Group-33' },
          React.createElement(
            'g',
            { id: 'Rectangle-7' },
            React.createElement('rect', { x: '30.63', y: '250', width: '1000', height: '375' })
          ),
          React.createElement(
            'g',
            { id: 'Rectangle-7-Copy-2' },
            React.createElement('rect', { x: '30.63', width: '1000', height: '187.5' })
          ),
          React.createElement(
            'g',
            { id: 'Rectangle-7-Copy-3' },
            React.createElement('rect', { x: '30.63', y: '687.5', width: '1000', height: '312.5' })
          )
        )
      )
    )
  );
};

Feed.propTypes = propTypes;

export default Feed;