import React from 'react';
import { IconProps } from '../icon.types';

export const Location = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.24707 8.5127C1.10156 9.04395 1.41699 10.5796 2.67871 10.5879L8.72998 10.6128C8.82959 10.6128 8.85449 10.6377 8.85449 10.7373L8.87109 16.7388C8.87939 18.0503 10.4399 18.2827 10.9961 17.0791L17.1304 3.88916C17.6948 2.66064 16.7319 1.78906 15.5117 2.36182L2.24707 8.5127ZM3.69141 9.29297C3.6582 9.29297 3.6499 9.25977 3.69141 9.24316L15.6196 3.78125C15.6777 3.75635 15.7026 3.77295 15.6777 3.83936L10.1826 15.7593C10.1743 15.7925 10.1411 15.7842 10.1411 15.751L10.1826 9.88232C10.1826 9.51709 9.92529 9.25977 9.55176 9.25977L3.69141 9.29297Z" />
    </svg>
  );
};
