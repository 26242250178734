import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PhotoMedium = function PhotoMedium(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'PhotoMedium__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement(
      'g',
      null,
      React.createElement(
        'g',
        null,
        React.createElement('path', {
          d: 'M55.6,55.6v888.9h888.9V55.6H55.6z M111.1,111.1h777.8v611.5l-87.1-78l-198,202.6l-72-73.7l-98,115.3H111.1V111.1z' // eslint-disable-line max-len
        })
      ),
      React.createElement(
        'g',
        null,
        React.createElement('path', {
          d: 'M467.5,456.2c0-38.1-30.2-69-67.4-69c-37.2,0-67.4,30.9-67.4,69c0,38.1,30.2,69,67.4,69C437.3,525.2,467.5,494.3,467.5,456.2z' // eslint-disable-line max-len
        })
      )
    )
  );
};

PhotoMedium.propTypes = propTypes;

export default PhotoMedium;