import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';
import { Icon } from '../';

var propDescriptions = {
  autofocus: 'Whether to autofocus the textarea',
  autoResize: 'Whether to disable the autoresizing of the textarea',
  className: 'optional className',
  children: 'Any children to float inside of the textarea',
  label: 'Optional form label',
  maxLength: 'Optional max length variable',
  onChange: 'Callback used to change the value of the component',
  placeholder: 'Placeholder text',
  rows: 'Number of rows to start the textarea size at',
  value: 'Value of the textarea',
  id: 'html5 id to link the label and textarea',
  readOnly: 'Whether the textarea is readonly',
  onFocus: 'a callback function fired on focus of the textarea',
  onBlur: 'a callback function fired on blur of the textarea',
  hasClearBtn: 'Whether to enable an in-textbox clear button'
};

var propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.element,
  rows: PropTypes.number,
  autofocus: PropTypes.bool,
  autoResize: PropTypes.bool,
  readOnly: PropTypes.bool,
  hasClearBtn: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number
};

var defaultProps = {
  className: '',
  label: '',
  placeholder: '',
  rows: 2,
  autoResize: true,
  hasClearBtn: false
};

var Textarea = function (_Component) {
  _inherits(Textarea, _Component);

  function Textarea(props) {
    _classCallCheck(this, Textarea);

    var _this = _possibleConstructorReturn(this, (Textarea.__proto__ || _Object$getPrototypeOf(Textarea)).call(this, props));

    _this.handleChange = _this.handleChange.bind(_this);
    return _this;
  }

  _createClass(Textarea, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _props = this.props,
          autofocus = _props.autofocus,
          autoResize = _props.autoResize;

      if (autofocus && !autoResize) {
        this.textarea.focus();
      }
    }
  }, {
    key: 'handleChange',
    value: function handleChange(e) {
      this.props.onChange(e.target.value);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props2 = this.props,
          autoResize = _props2.autoResize,
          className = _props2.className,
          label = _props2.label,
          maxLength = _props2.maxLength,
          placeholder = _props2.placeholder,
          rows = _props2.rows,
          value = _props2.value,
          id = _props2.id,
          readOnly = _props2.readOnly,
          autofocus = _props2.autofocus,
          children = _props2.children,
          onBlur = _props2.onBlur,
          onFocus = _props2.onFocus,
          hasClearBtn = _props2.hasClearBtn;


      var textareaClasses = classNames('textarea', className, {
        disabled: readOnly
      });

      var textareaContainerClasses = classNames('textarea__inputContainer', {
        'textarea__inputContainer--withClear': hasClearBtn
      });

      return React.createElement(
        'div',
        {
          className: 'textarea__container'
        },
        label ? React.createElement(
          'label',
          { htmlFor: this.props.id },
          label
        ) : null,
        React.createElement(
          'div',
          { className: textareaContainerClasses },
          !autoResize || readOnly ? React.createElement('textarea', {
            ref: function ref(c) {
              _this2.textarea = c;
            },
            className: textareaClasses,
            rows: rows,
            value: value,
            onChange: this.handleChange,
            placeholder: placeholder,
            id: id,
            readOnly: readOnly,
            onBlur: onBlur,
            onFocus: onFocus,
            maxLength: maxLength
          }) : React.createElement(TextareaAutosize, {
            ref: function ref(c) {
              _this2.textarea = c;
            },
            autoFocus: autofocus,
            className: textareaClasses,
            rows: rows,
            value: value,
            onChange: this.handleChange,
            placeholder: placeholder,
            classID: id,
            onBlur: onBlur,
            onFocus: onFocus,
            maxLength: maxLength
          }),
          hasClearBtn && value && React.createElement(
            'span',
            {
              onClick: function onClick() {
                return _this2.props.onChange('');
              },
              onKeyPress: function onKeyPress() {
                return _this2.props.onChange('');
              },
              role: 'button',
              tabIndex: 0
            },
            React.createElement(Icon, { type: 'close-circle', fill: '#8a8c90' })
          )
        ),
        children
      );
    }
  }]);

  return Textarea;
}(Component);

export default Textarea;


Textarea.propTypes = propTypes;
Textarea.defaultProps = defaultProps;
Textarea.propDescriptions = propDescriptions;