import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Albums = function Albums(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Albums__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M389.1,604.2c0,34.5-28,62.5-62.5,62.5s-62.5-28-62.5-62.5s28-62.5,62.5-62.5S389.1,569.7,389.1,604.2z M416.7,291.7H875\tV1000H0V125h291.7L416.7,291.7z M833.3,333.3H395.8l-125-166.7H41.7v791.7h139.1l145.8-145.8l104.2,104.2l270.8-270.8l131.7,131.7V333.3z M500,166.7L375,0H166.7v41.7h187.5l125,166.7h479.2v625h41.7V166.7H500z' // eslint-disable-line max-len
    })
  );
};

Albums.propTypes = propTypes;

export default Albums;