import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../../types';
import { DetailedStoryMenu } from './detailed_story_menu.component';
import { selectIsLocalhoodOwner } from '../../../../redux/user/user.selectors';

const mapStateToProps = (state: AppState) => ({
  isChallengeModerator: selectIsLocalhoodOwner(state),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});
export const DetailedStoryMenuConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailedStoryMenu);
