import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var TwitterRetweet = function TwitterRetweet(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'twitterRetweet__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 18 12',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M17.535 8.276a.5.5 0 0 0-.725 0l-1.758 1.758V2.586A2.561 2.561 0 0 0 12.466 0H7.914c-.31 0-.517.207-.517.517 0 .31.206.517.517.517h4.552c.827 0 1.551.725 1.551 1.552v7.448L12.26 8.276a.5.5 0 0 0-.725 0 .5.5 0 0 0 0 .724l2.587 2.586c.103.104.207.104.31.104.104 0 .31-.104.31-.104L17.328 9c.413-.207.413-.517.207-.724zM9.672 10.862H5.121c-.828 0-1.552-.724-1.552-1.552V1.966l1.759 1.758c.103.104.206.104.31.104.103 0 .31 0 .31-.104a.5.5 0 0 0 0-.724L3.466.31a.5.5 0 0 0-.725 0L.155 2.897a.5.5 0 0 0 0 .724.5.5 0 0 0 .724 0l1.759-1.759v7.552A2.561 2.561 0 0 0 5.224 12h4.448c.31 0 .518-.207.518-.517 0-.31-.207-.62-.518-.62z' // eslint-disable-line max-len
    })
  );
};

TwitterRetweet.propTypes = propTypes;

export default TwitterRetweet;