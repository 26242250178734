import { ActionCreator, Action } from 'redux';
import {
  GALLERY_EDIT_TYPES,
  GalleryEditFailureAction,
  GalleryEditSuccessAction,
  GalleryEditStartAction,
} from '../../react/pages/galleries_edit/gallery_edit.types';
import {
  GALLERY_CREATE_TYPES,
  GalleryCreateFailureAction,
  GalleryCreateSuccessAction,
  GalleryCreateStartAction,
} from '../../react/pages/galleries_create/gallery_create.types';
import { GalleryCreateEditResponse } from '../../http/gallery_create.thunks';

import {
  GetGlobalTagsStart,
  GetGlobalTagsFailure,
  GetGlobalTagsSuccess,
  GLOBAL_TAG_ASYNC_TYPES,
  GlobalTag,
} from '../global_tags/global_tags.types';
import {
  NETWORK_TAG_ASYNC_TYPES,
  NetworkTag,
  GetNetworkTagsStart,
  GetNetworkTagsFailure,
  GetNetworkTagsSuccess,
} from '../network_tags/network_tags.types';

export const galleryEditStartAction = (): GalleryEditStartAction => ({
  type: GALLERY_EDIT_TYPES.START,
  payload: {},
});
export const galleryEditSuccessAction: ActionCreator<GalleryEditSuccessAction> = (
  response: GalleryCreateEditResponse,
) => ({
  type: GALLERY_EDIT_TYPES.SUCCESS,
  payload: response,
});
export const galleryEditFailedAction: ActionCreator<Action> = (
  error: Error,
): GalleryEditFailureAction => ({
  type: GALLERY_EDIT_TYPES.FAILURE,
  payload: error,
});
export const galleryCreateStartAction: ActionCreator<Action> = (): GalleryCreateStartAction => ({
  type: GALLERY_CREATE_TYPES.START,
  payload: {},
});
export const galleryCreateSuccessAction: ActionCreator<Action> = (
  response: GalleryCreateEditResponse,
): GalleryCreateSuccessAction => ({
  type: GALLERY_CREATE_TYPES.SUCCESS,
  payload: response,
});
export const galleryCreateFailedAction: ActionCreator<Action> = (
  error: Error,
): GalleryCreateFailureAction => ({
  type: GALLERY_CREATE_TYPES.FAILURE,
  payload: error,
});

export const globalTagsStartAction: ActionCreator<Action> = (): GetGlobalTagsStart => ({
  type: GLOBAL_TAG_ASYNC_TYPES.START,
});
export const globalTagsFailureAction: ActionCreator<Action> = (
  error: string,
): GetGlobalTagsFailure => ({
  type: GLOBAL_TAG_ASYNC_TYPES.FAILURE,
  payload: error,
});
export const globalTagsSuccessAction: ActionCreator<Action> = (
  payload: GlobalTag[],
): GetGlobalTagsSuccess => ({
  type: GLOBAL_TAG_ASYNC_TYPES.SUCCESS,
  payload,
});
export const networkTagsStartAction: ActionCreator<Action> = (): GetNetworkTagsStart => ({
  type: NETWORK_TAG_ASYNC_TYPES.START,
  payload: {},
});
export const networkTagsFailureAction: ActionCreator<Action> = (
  error: string,
): GetNetworkTagsFailure => ({
  type: NETWORK_TAG_ASYNC_TYPES.FAILURE,
  payload: error,
});
export const networkTagsSuccessAction: ActionCreator<Action> = (
  payload: NetworkTag[],
): GetNetworkTagsSuccess => ({
  type: NETWORK_TAG_ASYNC_TYPES.SUCCESS,
  payload,
});
