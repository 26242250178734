import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import './story_stats.scss';
import { Story } from '../story/story.types';
import { statFormatterFn } from './story_stats.utils';
import { ICON_NAMES } from '../../blocks/icon';
import { Text, TEXT_VARIANTS } from '../../blocks/text';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';
import { VerticalStoryStatContent } from './vertical_story_stat/vertical_story_stat.component';
import { HorizontalStatContent } from './horizontal_story_stat/horizontal_story_stat.component';
import { TIME_FORMATS } from '../../pages/stories_manage/story_manage.utils';

export interface StoryStatsProps extends LocalizeContextProps {
  story: Story;
  orientation: string;
  onStatOpen?: () => void;
  onStatClose?: () => void;
}

export const StoryStatsComponent = ({
  story,
  orientation,
  onStatOpen,
  onStatClose,
  translate,
}: StoryStatsProps) => {
  const { stats } = story;
  const isVertical = orientation === 'vertical';
  const storyCompletions = stats?.completions;
  const storyViews = stats?.views ?? 0;
  const learnMoreClicks = stats?.learn_more_clicks ?? 0;
  const bookClicks = stats?.book_clicks ?? 0;

  const statsDate = new Date().toLocaleDateString('en-us', TIME_FORMATS.basic);
  const impressionsDate = new Date(
    Date.now() - 3 * 24 * 60 * 60 * 1000,
  ).toLocaleDateString('en-us', TIME_FORMATS.basic);

  const impressionsGoogleSearch = stats?.web_impressions ?? 0;
  const impressionsGoogleDiscover = stats?.discover_impressions ?? 0;

  const totalImpressions = impressionsGoogleSearch + impressionsGoogleDiscover;

  const completionRates =
    storyCompletions && storyViews
      ? `${((storyCompletions / storyViews) * 100).toFixed(2)}%`
      : 0;

  const clickThroughRate =
    storyViews && (learnMoreClicks || bookClicks)
      ? `${(((learnMoreClicks + bookClicks) / storyViews) * 100).toFixed(2)}%`
      : 0;

  const getTranslation = useTranslateStrings(translate);

  const statLastUpdated = (data?: string | null) => {
    return (
      <Text variant={TEXT_VARIANTS.C1} className="date_details">
        {getTranslation('stories.form.statistics.date', {
          date: data,
        })}
      </Text>
    );
  };

  const toolTipImpressionsContent = (dateData?: string | null) => {
    return [
      getTranslation('stories.form.statistics.impressionsCopy1'),
      getTranslation('stories.form.statistics.impressionsCopy2', {
        date: dateData,
      }),
    ];
  };

  return (
    <React.Fragment>
      {isVertical ? (
        <div className="story_stats__container">
          <div className="story_stats vertical">
            <div className="story_stats__content">
              {/* Total Impressions */}
              <VerticalStoryStatContent
                data={statFormatterFn(totalImpressions)}
                onOpen={onStatOpen}
                onClose={onStatClose}
                title={getTranslation('stories.form.statistics.impressionsAll')}
                tooltipContent={toolTipImpressionsContent(impressionsDate)}
              />
              {/* Search Impressions */}
              <VerticalStoryStatContent
                data={statFormatterFn(impressionsGoogleSearch)}
                title={getTranslation(
                  'stories.form.statistics.impressionsSearchLabel',
                )}
              />
              {/* Discovery Impressions */}
              <VerticalStoryStatContent
                data={statFormatterFn(impressionsGoogleDiscover)}
                title={getTranslation(
                  'stories.form.statistics.impressionsDiscoverLabel',
                )}
              />
            </div>
            {statLastUpdated(impressionsDate)}
          </div>
          <div className="story_stats vertical">
            <div className="story_stats__content impressions">
              {/* Completion Rate */}
              <VerticalStoryStatContent
                data={statFormatterFn(completionRates)}
                title={getTranslation('stories.form.statistics.completions')}
                tooltipContent={[
                  getTranslation('stories.form.statistics.completionsCopy1'),
                  getTranslation('stories.form.statistics.completionsCopy2'),
                ]}
              />
              {/* Views */}
              <VerticalStoryStatContent
                data={statFormatterFn(storyViews)}
                title={getTranslation('stories.form.statistics.views')}
                tooltipContent={[
                  getTranslation('stories.form.statistics.viewsCopy1'),
                  getTranslation('stories.form.statistics.viewsCopy2'),
                ]}
              />
              {/* Click-through Rate */}
              <VerticalStoryStatContent
                data={statFormatterFn(clickThroughRate)}
                title={getTranslation('stories.form.statistics.clickthrough')}
                tooltipContent={[
                  getTranslation('stories.form.statistics.clickthroughCopy1'),
                  getTranslation('stories.form.statistics.clickthroughCopy2'),
                ]}
              />
            </div>
            {statLastUpdated(statsDate)}
          </div>
        </div>
      ) : (
        // Horizontal
        <div className="story_stats horizontal">
          {/* Impressions */}
          <HorizontalStatContent
            data={statFormatterFn(totalImpressions, isVertical)}
            title={getTranslation('stories.form.statistics.impressions')}
            tooltipContent={toolTipImpressionsContent(impressionsDate)}
            iconType={ICON_NAMES.SQUARE_STACK}
          />
          {/* Views */}
          <HorizontalStatContent
            data={statFormatterFn(storyViews, isVertical)}
            title={getTranslation('stories.form.statistics.views')}
            tooltipContent={[
              getTranslation('stories.form.statistics.viewsCopy1'),
              getTranslation('stories.form.statistics.viewsCopy2'),
            ]}
            iconType={ICON_NAMES.EYE}
          />
          {/* Click-through Rate */}
          <HorizontalStatContent
            data={statFormatterFn(clickThroughRate, isVertical)}
            title={getTranslation('stories.form.statistics.clickthrough')}
            tooltipContent={[
              getTranslation('stories.form.statistics.clickthroughCopy1'),
              getTranslation('stories.form.statistics.clickthroughCopy2'),
            ]}
            iconType={ICON_NAMES.CURSOR_CLICK}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export const StoryStats = withLocalize(StoryStatsComponent);
