import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var CameraPlus = function CameraPlus(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Cameraplus__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1061.3 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M619.1,675.8c0,48.9-39.6,88.4-88.4,88.4c-48.9,0-88.4-39.6-88.4-88.4c0-48.9,39.6-88.4,88.4-88.4C579.5,587.3,619.1,626.9,619.1,675.8 M737,675.8c0-115-91.4-206.3-206.3-206.3s-206.3,91.4-206.3,206.3s91.4,206.3,206.3,206.3S737,790.7,737,675.8z M1061.3,439.9v471.7c0,47.1-38.3,88.4-88.4,88.4H88.4C38.3,1000,0,958.7,0,911.6V439.9c0-50.1,38.3-88.4,88.4-88.4h235.8l59-117.9H678l59,117.9h235.9C1023,351.5,1061.3,389.8,1061.3,439.9z M1004,123.5c0.3-0.9,0.6-1.9,0.6-2.8c0-14.1-11.4-25.5-25.5-25.5h-69.4V25.5c0-14.1-11.4-25.5-25.4-25.5c-14.1,0-25.5,11.4-25.5,25.5h-0.1v69.3h-69.3c-14,0-25.4,11.4-25.4,25.5c-0.2,1.1-0.2,2.2,0,3.3c1.5,13,12.8,22.6,25.9,22.2h69.3v69.3c0,14.1,11.4,25.5,25.5,25.5c14.1,0,25.5-11.4,25.5-25.5v-69.3h68.7C991.6,145.8,1002.5,136.3,1004,123.5L1004,123.5z' // eslint-disable-line max-len
    })
  );
};

CameraPlus.propTypes = propTypes;

export default CameraPlus;