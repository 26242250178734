import { LocalizeContextProps } from 'react-localize-redux';
import { VoidFunctionComponent } from 'react';
import { STORY_SEARCH_LOAD_TYPES } from '../../../http/stories_search.thunks';
import { StoryHideResponse } from '../../../http/story_hide.thunk';
import { StoryShowResponse } from '../../../http/story_show.thunk';
import { Story } from '../story/story.types';
import { StoriesSearchFilterParams } from '../stories_search_form.component/stories_search_form.types';

export enum STORY_DIMENSIONS {
  HEIGHT = 485,
  WIDTH = 220,
}

export enum HOVER_POSITIONS {
  IN = 'in',
  OUT = 'out',
}

export interface StoryCardProps {
  story: Story;
  previewSrc: string;
  currentPublisherId: string;
  onHideStory: (story_uuid: string) => Promise<StoryHideResponse>;
  onShowStory: (story_uuid: string) => Promise<StoryShowResponse>;
}

export interface StoryListProps extends LocalizeContextProps {
  loadingType: STORY_SEARCH_LOAD_TYPES | null;
  isLoading: boolean;
  stories: Story[];
  nextPageToken: string | null;
  networkId: string;
  currentPublisherId: string;
  onHideStory: (story_uuid: string) => Promise<StoryHideResponse>;
  onShowStory: (story_uuid: string) => Promise<StoryShowResponse>;
  StoryCard: VoidFunctionComponent<StoryCardProps>;
  handleScrollToTop: () => void;
  filterData: StoriesSearchFilterParams;
  searchQuery: string | null;
  scrollElement?: Element | typeof window;
}
