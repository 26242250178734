import React, { RefObject } from 'react';
import {
  useFormContext,
  Controller,
  RefCallBack,
  UseControllerProps,
  Control,
} from 'react-hook-form';
import { Translate } from 'react-localize-redux';
import { useTranslateStrings } from './useTranslateStrings';

export type ComponentRequiredProps<V> = {
  onChange?: (value: V) => void;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  value?: V;
  name: string;
  label?: string;
  placeholder?: string;
  ref?: RefCallBack | RefObject<HTMLElement>;
};

export interface ControllerOnlyProps {
  control?: Control;
  validationRules?: UseControllerProps['rules'];
}

export const withController = <V, P extends ComponentRequiredProps<V>>(
  Component: React.FC<P>,
) => (props: P & ControllerOnlyProps) => {
  const { name, onChange: componentOnChange, validationRules, control } = props;
  const RHFControl = control ?? useFormContext().control;

  const required = !!validationRules?.required;
  return (
    <Controller
      name={name}
      control={RHFControl}
      rules={validationRules}
      render={({ field, fieldState }) => {
        const { ref, onChange, ...fieldProps } = field;

        const handleChange = (val: V) => {
          onChange(val);
          componentOnChange?.(val);
        };

        return (
          <Translate>
            {({ translate }) => {
              const getTranslation = useTranslateStrings(translate);
              return (
                <Component
                  {...props}
                  {...fieldProps}
                  {...fieldState}
                  onChange={handleChange}
                  required={required ? getTranslation('forms.required') : false}
                  innerRef={ref}
                />
              );
            }}
          </Translate>
        );
      }}
    />
  );
};
