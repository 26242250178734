import React, { ReactElement } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { ROUTES } from '../../../types';
import { Text, TEXT_ELEMENTS, TEXT_VARIANTS } from '../text';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';

type PageTitlesTranslationMap = {
  [_ in ROUTES]: string;
};

const PageTitles: PageTitlesTranslationMap = {
  [ROUTES.ADMIN]: 'general.crowdRiffAdmin',
  [ROUTES.HOME]: 'network.title',
  [ROUTES.GALLERIES_LIST]: 'general.navigation.galleries',
  [ROUTES.GALLERIES_CREATE]: 'general.navigation.createGalleries',
  [ROUTES.GALLERIES_EDIT]: 'general.navigation.editGalleries',
  [ROUTES.STORIES_LIST]: 'general.navigation.stories',
  [ROUTES.STORIES_MANAGE]: 'general.navigation.manageStories',
  [ROUTES.ACCOUNT_SETTINGS]: 'general.navigation.accountSettings',
  [ROUTES.NOT_FOUND]: '',
};

const PageTitleComponent = ({
  translate,
}: LocalizeContextProps): ReactElement => {
  const location = useLocation();
  let pathname = location.pathname as ROUTES;
  const { id, story_uuid } = useParams();
  if (id) {
    pathname = ROUTES.GALLERIES_EDIT;
  }
  if (story_uuid) {
    pathname = ROUTES.STORIES_MANAGE;
  }

  return (
    <Text variant={TEXT_VARIANTS.H2} element={TEXT_ELEMENTS.H1}>
      {useTranslateStrings(translate)(PageTitles[pathname])}
    </Text>
  );
};

export const PageTitle = withLocalize(PageTitleComponent);
