import {
  GET_USER_TYPES,
  UserState,
  Publisher,
  PUBLISHER_SWITCHING,
  GET_PUBLISHERS_TYPES,
  GET_NP_FEATURE_FLAGS,
  INIT_PENDO,
  GET_ACCEPTANCE,
  SET_ACCEPTANCE,
  UPDATE_PUBLISHER_TYPES,
  GET_PUBLISHER_TYPES,
  UPDATE_LOGO_FAVICON_TYPES,
} from './user.types';
import { AppActions } from '../../types';

const initialState: Readonly<UserState> = {
  isPendoInitialized: false,
  acceptedToS: true,
  userId: '',
  userType: '',
  currentPublisher: {
    networkId: '',
    publisherId: '',
    label: '',
    avatarUrl: '',
    featureFlags: [],
    gallery_domain: '',
  },
  publisherIds: [],
  data: {},
};
export const userReducer = (
  state: Readonly<UserState> = initialState,
  action: AppActions,
): UserState => {
  switch (action.type) {
    case GET_USER_TYPES.SUCCESS: {
      return {
        ...state,
        userId: action.payload.userId,
        userType: action.payload.userType,
        currentPublisher: action.payload.currentPublisher,
        publisherIds: action.payload.publisherIds,
        data: action.payload.networks.reduce(
          (
            networkData: {
              [networkId: string]: {
                publishers: Publisher[];
              };
            },
            network,
          ) => {
            networkData[network.networkId] = {
              publishers: (network.publishers ?? []).map((p) => ({
                ...p,
                networkId: network.networkId,
              })),
            };
            return networkData;
          },
          {},
        ),
      };
    }

    case GET_PUBLISHERS_TYPES.SUCCESS: {
      const hydratePublisher = (publisher: Publisher) => {
        const displayData = action.payload.find(
          (p) => publisher.publisherId === p.publisherId,
        );
        return {
          ...publisher,
          label: displayData?.label ?? '',
          avatarUrl: displayData?.avatarUrl ?? '',
          // If the publisher does not have a collectionId,
          // assigning a dummy value for analytics purposes.
          collectionId: displayData?.collectionId ?? '-5',
        };
      };

      const data = Object.entries(state.data).reduce(
        (accumulator, [key, value]) => {
          const publishersArray = value.publishers;
          const hydratedPublishersArray = publishersArray.map((publisher) => {
            return hydratePublisher(publisher);
          });
          return {
            ...accumulator,
            [key]: { publishers: hydratedPublishersArray },
          };
        },
        {},
      );

      return {
        ...state,
        currentPublisher: hydratePublisher(state.currentPublisher),
        data,
      };
    }

    case PUBLISHER_SWITCHING.UPDATE_PUBLISHER: {
      return {
        ...state,
        currentPublisher: action.payload,
      };
    }
    case GET_NP_FEATURE_FLAGS.SUCCESS: {
      return {
        ...state,
        currentPublisher: {
          ...state.currentPublisher,
          featureFlags: action.payload,
        },
      };
    }
    case INIT_PENDO.SUCCESS: {
      return {
        ...state,
        isPendoInitialized: true,
      };
    }
    case GET_NP_FEATURE_FLAGS.FAILURE: {
      return {
        ...state,
        currentPublisher: {
          ...state.currentPublisher,
          featureFlags: [],
        },
      };
    }
    case GET_ACCEPTANCE.SUCCESS: {
      return {
        ...state,
        acceptedToS: action.payload,
      };
    }
    case SET_ACCEPTANCE.SUCCESS: {
      if (action.payload) {
        return {
          ...state,
          acceptedToS: true,
        };
      }
    }
    // eslint-disable-next-line no-fallthrough
    case GET_PUBLISHER_TYPES.START:
    case GET_PUBLISHER_TYPES.FAILURE:
    case UPDATE_PUBLISHER_TYPES.START:
    case UPDATE_PUBLISHER_TYPES.FAILURE:
    case UPDATE_LOGO_FAVICON_TYPES.FAILURE: {
      return {
        ...state,
      };
    }
    case UPDATE_LOGO_FAVICON_TYPES.SUCCESS: {
      console.log(action.payload);
      return {
        ...state,
        currentPublisher: {
          ...state.currentPublisher,
          avatarUrl: action.payload.logo_src,
          faviconUrl: action.payload.favicon_src,
        },
      };
    }
    case GET_PUBLISHER_TYPES.SUCCESS: {
      return {
        ...state,
        currentPublisher: {
          ...state.currentPublisher,
          ...action.payload,
        },
      };
    }
    case UPDATE_PUBLISHER_TYPES.SUCCESS: {
      return {
        ...state,
        currentPublisher: {
          ...state.currentPublisher,
          ...action.payload,
        },
      };
    }
    case GET_ACCEPTANCE.FAILURE:
    case SET_ACCEPTANCE.FAILURE:
    default: {
      return state;
    }
  }
};
