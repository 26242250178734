import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Photowall = function Photowall(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Photowall__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M937.5,0h-875C28,0,0,28,0,62.5v625C0,722,28,750,62.5,750h375v187.5h-250c-17.3,0-31.2,14-31.2,31.2s14,31.2,31.2,31.2h625\tc17.3,0,31.2-14,31.2-31.2s-14-31.2-31.2-31.2h-250V750h375c34.5,0,62.5-28,62.5-62.5v-625C1000,28,972,0,937.5,0z M937.5,687.5H625H375H62.5v-625h875V687.5z' // eslint-disable-line max-len
    })
  );
};

Photowall.propTypes = propTypes;

export default Photowall;