import React from 'react';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../../blocks/icon';
import './web_preview.scss';

export interface WebPreviewProps {
  faviconUrl: string;
}

export const WebPreview = ({
  faviconUrl,
}: WebPreviewProps) => {
  return (
    <div className="web_preview__wrapper">
      <div className="web_preview__browser_icons">
        <span className="web_preview__browser_icon" />
        <span className="web_preview__browser_icon" />
        <span className="web_preview__browser_icon" />
      </div>
      <div className="web_preview__tab">
        <img src={faviconUrl} alt={`brand icon`} className="web_preview__tab_logo" />
        <span className="web_preview__tab_name" />
        <Icon iconKey={ICON_NAMES.CLOSE} size={ICON_SIZES.MED} color='#C4C4C6' className="web_preview__tab_icon" />
      </div>
    </div>
  );
};
