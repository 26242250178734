import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../types';
import { StaticGalleryEdit } from './static_gallery_edit.component';
import {
  editStaticGalleryLabel,
  removeStoryGalleryEdit,
  addStoryGalleryEdit,
  resetStaticGalleryState,
  updateStaticGallerySelectedList,
} from '../../../redux/galleries/galleries.actions';
import {
  selectStaticGalleryLabelForEdit,
  selectStaticGalleryStoriesForEdit,
  selectStaticGallerySelectedStories,
  selectGalleriesToEdit,
  selectManualGalleryMaxSelectableStories,
} from '../../../redux/galleries/galleries.selectors';
import { Story } from '../story/story.types';

const mapStateToProps = (state: AppState) => ({
  modifiedStories: selectStaticGallerySelectedStories(state) ?? [],
  selectedStories: selectStaticGalleryStoriesForEdit(state) ?? [],
  selectedGallery: selectGalleriesToEdit(state),
  label: selectStaticGalleryLabelForEdit(state),
  maxSelectableStories: selectManualGalleryMaxSelectableStories(state),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  updateGalleryLabel: (label: string) =>
    dispatch(editStaticGalleryLabel(label)),
  updateGalleryList: (storyList: Story[]) =>
    dispatch(updateStaticGallerySelectedList(storyList)),
  addStory: (story: Story) => dispatch(addStoryGalleryEdit(story)),
  removeStory: (uuid: string) => dispatch(removeStoryGalleryEdit(uuid)),
  resetGalleryState: () => dispatch(resetStaticGalleryState()),
  updateSelectedList: (stories: Story[]) =>
    dispatch(updateStaticGallerySelectedList(stories)),
});

export const ConnectedStaticGalleryEdit = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StaticGalleryEdit);
