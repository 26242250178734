import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var MobileGrid = function MobileGrid(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'MobileGrid__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 589.7 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M589.7,307.7H0V0h589.7V307.7z M282,359H0v260.2h282V359z M230.8,670.5H0V1000h230.8V670.5z M589.7,359H333.4v260.2h256.3V359z M589.7,670.5H282.1V1000h307.6V670.5z' // eslint-disable-line max-len
    })
  );
};

MobileGrid.propTypes = propTypes;

export default MobileGrid;