import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import Icon from '../../Icon/Icon';

var propTypes = {
  children: PropTypes.node,
  href: PropTypes.string
};

var LinkCell = function LinkCell(_ref) {
  var children = _ref.children,
      href = _ref.href;

  return React.createElement(
    TableCell,
    null,
    React.createElement(
      'a',
      {
        className: 'cr-table__link',
        href: href,
        target: '_blank',
        rel: 'noopener noreferrer'
      },
      children,
      React.createElement(Icon, {
        className: 'cr-table__link--icon',
        type: 'external-link',
        color: 'blue',
        size: 12
      })
    )
  );
};

LinkCell.propTypes = propTypes;

export default LinkCell;