import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PauseFill = function PauseFill(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'pauseFill__icon',
      height: size,
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M6.00006 18.4V5.6C6.00006 5.26863 6.26869 5 6.60006 5H9.40006C9.73143 5 10.0001 5.26863 10.0001 5.6V18.4C10.0001 18.7314 9.73143 19 9.40006 19H6.60006C6.26869 19 6.00006 18.7314 6.00006 18.4Z',
      fill: color
    }),
    React.createElement('path', {
      d: 'M14.0001 18.4V5.6C14.0001 5.26863 14.2687 5 14.6001 5H17.4001C17.7315 5 18.0001 5.26863 18.0001 5.6V18.4C18.0001 18.7314 17.7315 19 17.4001 19H14.6001C14.2687 19 14.0001 18.7314 14.0001 18.4Z',
      fill: color
    }),
    React.createElement('path', {
      d: 'M6.00006 18.4V5.6C6.00006 5.26863 6.26869 5 6.60006 5H9.40006C9.73143 5 10.0001 5.26863 10.0001 5.6V18.4C10.0001 18.7314 9.73143 19 9.40006 19H6.60006C6.26869 19 6.00006 18.7314 6.00006 18.4Z',
      stroke: color,
      strokeWidth: '1.5'
    }),
    React.createElement('path', {
      d: 'M14.0001 18.4V5.6C14.0001 5.26863 14.2687 5 14.6001 5H17.4001C17.7315 5 18.0001 5.26863 18.0001 5.6V18.4C18.0001 18.7314 17.7315 19 17.4001 19H14.6001C14.2687 19 14.0001 18.7314 14.0001 18.4Z',
      stroke: color,
      strokeWidth: '1.5'
    })
  );
};

PauseFill.propTypes = propTypes;

export default PauseFill;