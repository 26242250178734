// This file should ideally exist in a dedicated util function, as it's not really specific to story details.
// See https://crowdriff.atlassian.net/browse/SNET-567

export enum DISPLAY_DATE_TYPES {
  DEFAULT = 'basic',
  TIMEZONE = 'timezone',
}

export const TIME_FORMATS = {
  [DISPLAY_DATE_TYPES.DEFAULT]: {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
  [DISPLAY_DATE_TYPES.TIMEZONE]: {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  },
} as const;

export const displayDate = (
  date: string,
  format: DISPLAY_DATE_TYPES = DISPLAY_DATE_TYPES.DEFAULT,
) => {
  const localeString = 'en-us';
  const formattedDate = new Date(date);
  switch (format) {
    case DISPLAY_DATE_TYPES.TIMEZONE: {
      return `${formattedDate.toLocaleString(
        localeString,
        TIME_FORMATS[format],
      )}`;
    }
    default: {
      return formattedDate.toLocaleDateString(
        localeString,
        TIME_FORMATS[format],
      );
    }
  }
};
