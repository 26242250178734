/* eslint-disable no-shadow */
import React from 'react';
import { GLOBAL_TAG_TYPES } from '../../../redux/global_tags/global_tags.types';
import { NETWORK_TAG_TYPES } from '../../../redux/network_tags/network_tags.types';
import { TaxonomyTag } from './taxonomy.types';

export interface TaxonomyContainerProps {
  taxonomyTags: TaxonomyTag[];
  tagtype: NETWORK_TAG_TYPES | GLOBAL_TAG_TYPES;
}
export const TaxonomyContainer = ({
  taxonomyTags,
  tagtype,
}: TaxonomyContainerProps) => {
  const filteringTags = (
    taxonomyTags: TaxonomyTag[],
    tagtype: NETWORK_TAG_TYPES | GLOBAL_TAG_TYPES,
  ) => {
    return taxonomyTags.filter((tag: TaxonomyTag) => tag.type === tagtype);
  };

  return (
    <div className="taxonomy__section__container">
      {filteringTags(taxonomyTags, tagtype).length >= 1 ? (
        filteringTags(taxonomyTags, tagtype).map((tag) => {
          return (
            <div className="taxonomy__section__container__tag_container">
              <p className="taxonomy__section__container__tag_container--tagLabel">
                {tag.label}
              </p>
            </div>
          );
        })
      ) : (
        <p className="taxonomy__section__container__tag_container--empty">--</p>
      )}
    </div>
  );
};
