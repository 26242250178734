export enum STATUS_TYPES {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface AsyncActionTypes {
  START: string;
  SUCCESS: string;
  FAILURE: string;
}

export interface Loading {
  status: STATUS_TYPES.LOADING;
  err: null;
  res: null;
}

export interface Failure<E> {
  status: STATUS_TYPES.FAILURE;
  res: null;
  err: E;
}

export interface Success<R> {
  status: STATUS_TYPES.SUCCESS;
  res: R;
  err: null;
}
