import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

var propDescriptions = {
  minValue: 'Minimum value for the input',
  maxValue: 'Maximum value for the input',
  stepValue: 'Number by which to increment/decrement by',
  className: 'Optional className for the component',
  id: 'HTML id to link the form and label',
  onChange: 'Callback function that is fired with the corresponding value the input is changed to',
  value: 'Active value of the input',
  isDisplayingValue: 'A boolean that determines whether the active value is display',
  label: 'Label for the input',
  pendoId: 'A string that will be added to the data-pendo attribute for pendo'
};

var propTypes = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  stepValue: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  isDisplayingValue: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  pendoId: PropTypes.string
};

var defaultProps = {
  className: '',
  stepValue: 1
};

var Slider = function (_React$Component) {
  _inherits(Slider, _React$Component);

  function Slider(props) {
    _classCallCheck(this, Slider);

    var _this = _possibleConstructorReturn(this, (Slider.__proto__ || _Object$getPrototypeOf(Slider)).call(this, props));

    _this.handleChange = _this.handleChange.bind(_this);
    return _this;
  }

  _createClass(Slider, [{
    key: 'handleChange',
    value: function handleChange(e) {
      var numberValue = parseInt(e.target.value, 10);
      this.props.onChange(numberValue);
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          id = _props.id,
          minValue = _props.minValue,
          maxValue = _props.maxValue,
          stepValue = _props.stepValue,
          value = _props.value,
          isDisplayingValue = _props.isDisplayingValue,
          label = _props.label,
          pendoId = _props.pendoId;

      return React.createElement(
        'div',
        { className: classNames('rangeSlider__container', className) },
        React.createElement(
          'label',
          { className: 'rangeSlider__label', htmlFor: 'id' },
          label
        ),
        React.createElement(
          'div',
          { className: 'rangeSlider__wrapper' },
          React.createElement('input', {
            id: id,
            type: 'range',
            className: 'rangeSlider',
            onChange: this.handleChange,
            min: minValue,
            max: maxValue,
            step: stepValue,
            value: value,
            'data-pendo': pendoId
          }),
          isDisplayingValue ? React.createElement(
            'p',
            { className: 'rangeSlider__value' },
            value
          ) : null
        )
      );
    }
  }]);

  return Slider;
}(React.Component);

export default Slider;


Slider.propTypes = propTypes;
Slider.defaultProps = defaultProps;
Slider.propDescriptions = propDescriptions;