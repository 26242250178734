import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Tag = function Tag(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Tag',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M975.6,650.8L651.5,975.6c-15.8,15.2-37.6,24.4-60.1,24.4c-22.4,0-44.2-9.2-59.4-24.4L60.1,503C26.4,470,0,406,0,359.1 V84.5C0,38.3,38.3,0,84.5,0h274.6C406,0,470,26.4,503.6,60.1l472,471.3c15.2,15.8,24.4,37.6,24.4,60.1 C1000,613.9,990.8,635.6,975.6,650.8z M211.2,126.7c-46.9,0-84.5,37.6-84.5,84.5s37.6,84.5,84.5,84.5s84.5-37.6,84.5-84.5 S258.1,126.7,211.2,126.7z' // eslint-disable-line max-len
    })
  );
};

Tag.propTypes = propTypes;

export default Tag;