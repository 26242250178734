import React from 'react';
import './modal.scss';
import clsx from 'clsx';
import { LocalizedElement, LocalizedElementMap } from 'react-localize-redux';

interface ModalTitleProps {
  label: LocalizedElement | LocalizedElementMap;
  className?: string;
  id?: string;
}

export const ModalTitle = ({ className, label, id }: ModalTitleProps) => {
  return (
    <h3 id={id} className={clsx([className, 'modal__title'])}>
      {label}
    </h3>
  );
};
