import React from 'react';
import { IconProps } from '../icon.types';

export const ShareFilled = ({
  className,
  size,
  color = 'black',
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.03109 8.91694L19.5081 4.58294C19.5936 4.55903 19.6839 4.5583 19.7697 4.58084C19.8556 4.60338 19.9339 4.64837 19.9966 4.71119C20.0594 4.77401 20.1042 4.85239 20.1266 4.93829C20.149 5.02418 20.1481 5.11449 20.1241 5.19994L15.7911 20.6759C15.7635 20.7755 15.7058 20.8641 15.6259 20.9295C15.546 20.9949 15.4478 21.034 15.3447 21.0413C15.2417 21.0487 15.1389 21.0239 15.0505 20.9705C14.9622 20.9171 14.8925 20.8376 14.8511 20.7429L11.6031 13.3609C11.5529 13.2462 11.4616 13.1545 11.3471 13.1039L3.96509 9.85594C3.87043 9.81464 3.79089 9.74505 3.73738 9.65672C3.68388 9.56839 3.65903 9.46566 3.66627 9.36264C3.6735 9.25963 3.71245 9.16137 3.77778 9.08139C3.8431 9.0014 3.93159 8.94361 4.03109 8.91594V8.91694Z"
        fill={color}
      />
    </svg>
  );
};
