import React from 'react';
import { IconProps } from '../icon.types';

export const ExternalLink = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.2655 8.51761L18.2453 2.73853C18.2453 2.3035 17.962 2 17.5068 2H11.7277C11.2927 2 11.0094 2.32374 11.0094 2.68794C11.0094 3.06227 11.323 3.36577 11.6973 3.36577H11.5075L16.1714 3.24437L14.3908 4.86306L9.21245 10.198C9.07082 10.3397 9 10.5116 9 10.6836C9 11.0478 9.33386 11.3918 9.70818 11.3918C9.88016 11.3918 10.0521 11.3311 10.1938 11.1794L15.3822 5.85451L17.0009 4.07395L16.8795 8.24208V8.54796C16.8795 8.91217 17.183 9.24602 17.5675 9.24602C17.9418 9.24602 18.2655 8.9324 18.2655 8.51761Z" />
      <path d="M4.98446 18.065H12.2001C13.9706 18.065 14.9823 17.0533 14.9823 15.1109V8.88663L13.7278 10.131V15.04C13.7278 16.2136 13.0803 16.8105 12.1698 16.8105H5.0047C3.87161 16.8105 3.25449 16.2136 3.25449 15.04V8.10763C3.25449 6.9442 3.87161 6.33719 5.0047 6.33719H9.99467L11.2492 5.0827H4.98446C3.01168 5.0827 2 6.08427 2 8.04693V15.1109C2 17.0634 3.01168 18.065 4.98446 18.065Z" />
    </svg>
  );
};
