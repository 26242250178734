import React from 'react';
import './modal.scss';

export interface ModalButtonsProps {
  children: React.ReactNode;
}

export const ModalButtons = ({ children }: ModalButtonsProps) => {
  return <div className="modal__buttons-container">{children}</div>;
};
