import React from 'react';
import { IconProps } from '../icon.types';

export const TextAlignJustify = ({
  className,
  size,
  color,
  ariaHidden,
  role,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      aria-hidden={ariaHidden}
      role={role}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.22217 7.02686H17.7612C18.1099 7.02686 18.3921 6.75293 18.3921 6.4043C18.3921 6.05566 18.1099 5.77344 17.7612 5.77344H2.22217C1.87354 5.77344 1.59961 6.05566 1.59961 6.4043C1.59961 6.75293 1.87354 7.02686 2.22217 7.02686ZM2.22217 10.646H17.7612C18.1099 10.646 18.3921 10.3721 18.3921 10.0234C18.3921 9.6665 18.1099 9.38428 17.7612 9.38428H2.22217C1.87354 9.38428 1.59961 9.6665 1.59961 10.0234C1.59961 10.3721 1.87354 10.646 2.22217 10.646ZM2.22217 14.2734H17.7612C18.1099 14.2734 18.3921 13.9912 18.3921 13.6343C18.3921 13.2856 18.1099 13.0117 17.7612 13.0117H2.22217C1.87354 13.0117 1.59961 13.2856 1.59961 13.6343C1.59961 13.9912 1.87354 14.2734 2.22217 14.2734Z" />
    </svg>
  );
};
