import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var AngleUpDouble = function AngleUpDouble(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Angleupdouble__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1033.1 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M86,602.5c-9,0-16.9-3.5-23.9-10.4l-51.7-51.8C3.4,533.5,0,525.5,0,516.6c0-9,3.4-16.9,10.4-23.8L492.8,10.4\t\tC499.7,3.4,507.6,0,516.6,0c9,0,16.9,3.4,23.8,10.4l482.5,482.4c6.8,6.9,10.3,14.8,10.3,23.8c0,9-3.4,16.9-10.3,23.8L971,592.1\t\tc-6.8,6.9-14.8,10.4-23.8,10.4c-9.1,0-16.9-3.5-23.9-10.4L516.6,185.3L109.8,592.1C102.9,599,94.9,602.5,86,602.5z M86,1000\t\tc-9,0-16.9-3.4-23.9-10.3l-51.7-51.8C3.4,931,0,923.1,0,914.1c0-9,3.4-16.9,10.4-23.8l482.4-482.4c6.9-6.9,14.9-10.4,23.9-10.4\t\tc9,0,16.9,3.4,23.8,10.4l482.5,482.4c6.8,6.9,10.3,14.8,10.3,23.8c0,9-3.4,16.9-10.3,23.8L971,989.7c-6.8,6.9-14.8,10.3-23.8,10.3\tc-9.1,0-16.9-3.4-23.9-10.3L516.6,582.8L109.8,989.7C102.9,996.6,94.9,1000,86,1000z' // eslint-disable-line max-len
    })
  );
};

AngleUpDouble.propTypes = propTypes;

export default AngleUpDouble;