import React, { useState, FocusEventHandler } from 'react';
import ReactSelect, { IndicatorProps } from 'react-select';
import makeAnimated from 'react-select/animated';
import './select.scss';
import clsx from 'clsx';
import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue';
import { SelectOption, SelectProps, SelectValue } from './select.types';
import { HelperText } from '../helper/helperText.component';
import { Icon, ICON_NAMES, ICON_SIZES } from '../icon';

const rootClass = 'select';

export const Select = <IsMulti extends boolean>({
  name,
  isMulti,
  isDisabled = false,
  isClearable = true,
  label,
  options,
  value,
  description,
  placeholder,
  onChange,
  invalid,
  error,
  onFocus,
  onBlur,
  required = false,
  ...otherProps
}: SelectProps<IsMulti>) => {
  const [focused, setFocused] = useState(false);

  const handleFocus: FocusEventHandler<HTMLElement> = (e) => {
    onFocus?.(e);
    setFocused(true);
  };
  const handleBlur: FocusEventHandler<HTMLElement> = (e) => {
    onBlur?.(e);
    setFocused(false);
  };
  const handleChange = (val: SelectValue<IsMulti>) => {
    onChange?.(val);
  };

  // temporarily added the svgs in till we have our own icon library
  const DropdownIndicator = () => {
    return (
      <Icon
        size={ICON_SIZES.LARGE}
        className="dropdown-indicator"
        iconKey={ICON_NAMES.CHEVRON_DOWN}
      />
    );
  };

  const MultiValueRemove = ({
    innerProps,
  }: MultiValueRemoveProps<SelectOption>) => {
    return (
      <Icon
        {...innerProps}
        size={ICON_SIZES.SMALL}
        className="clear-indicator"
        iconKey={ICON_NAMES.CLOSE}
      />
    );
  };

  // Only clearable when multi-select
  const ClearIndicator = isMulti
    ? ({ innerProps }: IndicatorProps<SelectOption, IsMulti>) => (
        <Icon
          size={ICON_SIZES.LARGE}
          className="clear-indicator"
          iconKey={ICON_NAMES.CLOSE_CIRCLE}
          {...innerProps}
        />
      )
    : null;

  // Override some basic react-select styles
  const customStyles = {
    control: (styles: { [key: string]: string }) => ({
      ...styles,
      border: 'none',
      backgroundColor: 'transparent',
    }),
  };

  return (
    <div
      className={clsx(rootClass, {
        invalid,
        disabled: isDisabled,
        focused,
      })}
    >
      {label && (
        <label
          id={`${name}-label`}
          htmlFor={name}
          className={clsx(`${rootClass}__label`, {
            animated:
              focused ||
              (Array.isArray(value) ? Boolean(value.length) : Boolean(value)),
            required,
          })}
        >
          {label}
        </label>
      )}
      <ReactSelect<SelectOption, IsMulti>
        {...otherProps}
        // when inputs don't have a label don't show aria-labelledby
        aria-labelledby={label ? `${name}-label` : undefined}
        aria-label={label ? undefined : placeholder}
        aria-invalid={invalid}
        aria-errormessage={`${name}-error`}
        className="rs__select-component"
        classNamePrefix="rs__select-component__inner"
        components={{
          ...makeAnimated(),
          DropdownIndicator,
          // Need to send null value to remove the default react-select separator
          IndicatorSeparator: null,
          MultiValueRemove,
          ClearIndicator,
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isDisabled={isDisabled}
        id={name}
        inputId={`${name}-id`}
        isMulti={isMulti}
        placeholder={placeholder}
        isClearable={isClearable}
        options={options}
        onChange={handleChange}
        value={value}
        styles={customStyles}
      />

      {/* REQ: Override the 'required' text with error message for invalid inputs */}
      {required && typeof required === 'string' && !invalid && (
        <HelperText id={`${name}-required`} description={required} />
      )}

      {invalid && error?.message && (
        <HelperText
          id={`${name}-error`}
          className={`${rootClass}__error-message`}
          description={error.message}
        />
      )}

      {description && (
        <HelperText
          id={`${name}-help-text`}
          className={`${rootClass}__help-text`}
          description={description}
        />
      )}
    </div>
  );
};
