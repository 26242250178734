import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var CursorClick = function CursorClick(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'CursorClick__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 999.1 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M71.4,428.2c0-196.8,160.1-356.8,356.8-356.8c194,0,351.7,155.9,356,348.9l71.3,24.9c0.2-5.7,0.9-11.3,0.9-17\t\tC856.4,191.7,664.7,0,428.2,0S0,191.7,0,428.2s191.7,428.2,428.2,428.2c5.4,0,10.7-0.6,16-0.8l-24.8-71.4\tC226.8,779.4,71.4,622,71.4,428.2z' // eslint-disable-line max-len
    }),
    React.createElement('path', {
      d: 'M991,852.6L788.9,650.5l118.7-59.9c22.6-11.8,20.6-44.1-3.9-53L390.7,358.2c-2.9-1-5.7-1.4-8.4-1.4c-16.9,0-29.9,16.9-23.9,33.8l178.5,514c4.2,12.6,15.8,19.3,27.4,19.3c10,0,20.1-5,25.5-15.4l59.9-118.7l202.1,202.1c5.4,5.4,12.8,8.1,20.1,8.1s14.7-2.7,20.1-8.1l99.1-99.1C1001.8,882,1001.8,863.4,991,852.6z' // eslint-disable-line max-len
    })
  );
};

CursorClick.propTypes = propTypes;

export default CursorClick;