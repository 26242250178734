import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var InstagramLike = function InstagramLike(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'InstagramLike__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1178.9 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M858.3,62.5c108.2,0,258.1,99.6,258.1,286.6c0,222.1-417.6,525-527,583.1c-109.4-58.1-527-360.9-527-583.1\tc0-187,149.9-286.6,258.1-286.6c139.9,0,213.9,106.5,216.7,110.7l51.6,77.4l52.4-77.1C644.5,169,718.5,62.5,858.3,62.5 M858.3,0\tC681.1,0,589.5,138.7,589.5,138.7S497.8,0,320.6,0C177.1,0,0,129.6,0,349.1C0,645.7,558.9,1000,589.5,1000s589.5-354.3,589.5-650.9C1178.9,129.6,1001.8,0,858.3,0L858.3,0z' // eslint-disable-line max-len
    })
  );
};

InstagramLike.propTypes = propTypes;

export default InstagramLike;