import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Gallery = function Gallery(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Gallery__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M312.5,62.5v250h-250v-250H312.5 M375,0H0v375h375V0L375,0z M1000,0H625v375h375V0z M937.5,687.5v250h-250v-250H937.5 M1000,625H625v375h375V625L1000,625z M375,625H0v375h375V625z' // eslint-disable-line max-len
    })
  );
};

Gallery.propTypes = propTypes;

export default Gallery;