import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Conversations = function Conversations(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Conversations__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1272.6 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M499.9,727.2c-43.3,0-85.2-4.3-125-11.4c-58.9,41.9-125.7,72.4-197.4,90.9c-19.2,5-39.8,8.5-61.1,11.4c-0.7,0-1.4,0-2.1,0\t\tc-10.7,0-20.6-8.6-22.7-20.6c-2.9-13.5,6.4-22,14.2-31.2c27.7-31.3,58.9-58.9,83.1-117.9C73.8,581.6,0,478.6,0,363.6\t\tC0,162.6,223.7,0,499.9,0s499.9,162.6,499.9,363.6C999.9,564.5,776.2,727.2,499.9,727.2z M1083.7,830.1\t\tc24.2,58.9,55.4,86.6,83.1,117.9c7.8,9.2,17,17.7,14.2,31.2c-2.8,12.8-13.5,22-24.8,20.6c-21.3-2.9-41.9-6.4-61.1-11.4\t\tc-71.7-18.5-138.5-49-197.4-90.9c-39.8,7.1-81.7,11.4-125,11.4c-128.5,0-246.4-35.5-335.2-93.8c20.6,1.4,41.9,2.9,62.5,2.9\t\tc152.7,0,296.8-44,406.9-123.6c118.6-86.7,183.9-203.8,183.9-330.9c0-36.9-5.7-73.2-16.3-107.9c120,66.1,198.1,171.1,198.1,289.7\tC1272.6,661.1,1198.7,763.4,1083.7,830.1z' // eslint-disable-line max-len
    })
  );
};

Conversations.propTypes = propTypes;

export default Conversations;