import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../types';
import { GalleryList as GalleryListComponent } from './gallery_list.component';
import {
  selectAllGalleries,
  selectSearchedGalleries,
} from '../../../redux/galleries/galleries.selectors';

import {
  clearSearchResults,
  handleSearchChange,
} from '../../pages/galleries_list/galleries_list.actions';

const mapStateToProps = (state: AppState) => ({
  galleries: selectAllGalleries(state),
  searchResults: selectSearchedGalleries(state),
});

export const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  handleSearchChange: (val: string) => dispatch(handleSearchChange(val)),
  handleClearStoriesSearch: () => dispatch(clearSearchResults),
});

export const GalleryList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GalleryListComponent);
