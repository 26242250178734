import React, { useState, useEffect } from 'react';
import './avatar.scss';
import classNames from 'classnames';
import fallbackAvatar from '../../../assets/default-instagram-avatar.jpg';
import { AVATAR_SIZES } from './avatar.types';

export const Avatar = ({
  url,
  alt,
  size = AVATAR_SIZES.MEDIUM,
}: {
  url: string;
  alt: string;
  size?: AVATAR_SIZES;
}) => {
  const [avatarUrl, setAvatarUrl] = useState(url);
  useEffect(() => {
    setAvatarUrl(url);
  }, [url]);
  return (
    <div
      className={classNames('avatar-component', {
        small: size === AVATAR_SIZES.SMALL,
        medium: size === AVATAR_SIZES.MEDIUM,
        large: size === AVATAR_SIZES.LARGE,
      })}
    >
      <img
        src={avatarUrl}
        alt={alt}
        onError={() => {
          setAvatarUrl(fallbackAvatar);
        }}
      />
    </div>
  );
};
