import { ActionCreator } from 'redux';
import {
  GALLERY_PREVIEW_TYPES,
  GalleryPreviewStartAction,
  GalleryPreviewSuccessAction,
  GalleryPreviewFailureAction,
} from './gallery_preview.types';
import { GalleryPreviewResponse } from '../../../http/gallery_preview.thunks';

export const galleryPreviewStartAction: ActionCreator<GalleryPreviewStartAction> = (): GalleryPreviewStartAction => ({
  type: GALLERY_PREVIEW_TYPES.START,
  payload: {},
});

export const galleryPreviewSuccessAction: ActionCreator<GalleryPreviewSuccessAction> = (
  stories: GalleryPreviewResponse,
): GalleryPreviewSuccessAction => ({
  type: GALLERY_PREVIEW_TYPES.SUCCESS,
  payload: stories,
});
export const galleryPreviewFailureAction: ActionCreator<GalleryPreviewFailureAction> = (
  err: string,
): GalleryPreviewFailureAction => ({
  type: GALLERY_PREVIEW_TYPES.FAILURE,
  payload: err,
});
