import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var PhotoWallGrid = function PhotoWallGrid(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'PhotoWallGrid__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1533.3 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M0,0h333.3v333.3H0V0z M400,333.3h333.3V0H400V333.3z M800,566.7h333.3V0H800V566.7z M1200,333.3h333.3V0H1200V333.3z\t\t M0,666.7h333.3V400H0V666.7z M400,800h333.3V400H400V800z M1200,733.3h333.3V400H1200V733.3z M0,1000h333.3V733.3H0V1000z\t M400,1000h333.3V866.7H400V1000z M800,1000h333.3V633.3H800V1000z M1200,1000h333.3V800H1200V1000z' // eslint-disable-line max-len
    })
  );
};

PhotoWallGrid.propTypes = propTypes;

export default PhotoWallGrid;