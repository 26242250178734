import { AppActions } from '../../types';
import {
  NetworkTagState,
  NetworkTag,
  NETWORK_TAG_ASYNC_TYPES,
} from './network_tags.types';

const initialState: Readonly<NetworkTagState> = {
  data: [],
  ids: [],
};
export const networkTagsReducer = (
  state: Readonly<NetworkTagState> = initialState,
  action: AppActions,
): NetworkTagState => {
  switch (action.type) {
    case NETWORK_TAG_ASYNC_TYPES.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        ids: action.payload.map((networkTag: NetworkTag) => networkTag.id),
      };
    }
    default: {
      return state;
    }
  }
};
