import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Return = function Return(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'return__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1000 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M500,1000c-149.1,0-289.7-65.7-384.8-180.3c-6.5-8.5-5.9-20.8,1.3-28l89.2-89.9c4.6-3.9,10.4-5.9,16.3-5.9c5.9,0.7,11.7,3.3,15,7.8c63.8,82.7,159.5,129.6,263,129.6c183.6,0,333.3-149.7,333.3-333.3S683.6,166.7,500,166.7c-85.3,0-166,32.6-226.6,89.2l89.2,89.9c12.4,11.7,15.6,30,9.1,44.9c-6.5,15.6-21.5,26-38.4,26H41.7C18.9,416.7,0,397.8,0,375V83.3c0-16.9,10.4-31.9,26-38.4C41,38.4,59.2,41.7,71,54l84.6,84C247.4,51.4,371.7,0,500,0c275.4,0,500,224.6,500,500S775.4,1000,500,1000z' // eslint-disable-line max-len
    })
  );
};

Return.propTypes = propTypes;

export default Return;