import { useForm } from 'react-hook-form';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import React, { ReactElement, useState, useEffect } from 'react';
import './story_manage.scss';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { PageTitle } from '../../blocks/pageTitle/pageTitle.component';
import { LoadStateData } from '../../blocks/load_data/load_state_data.component';
import { LoadingGif } from '../../blocks/LoadingGif/LoadingGif.component';
import { Page } from '../../blocks/page/page.component';
import { STORY_LOAD_TYPES } from '../stories_list/stories_list.types';
import { StoryDetails } from '../../features/story_details/story_details.component';
import { GetStoryResponse } from '../../../http/story_get.thunks';
import { DefaultFormVals, STORY_STATUS } from './story_manage.types';
import { Story } from '../../features/story/story.types';
import { StoryPreviewComponent } from '../../features/story_preview/story_preview.component';
import { PageHeader } from '../../blocks/page_header/page_header.component';
import { StoryStats } from '../../features/story_stats/story_stats.component';
import { TaxonomySection } from '../../features/taxonomy_section/taxonomy_section.component';
import { StoryHideResponse } from '../../../http/story_hide.thunk';
import { StoryShowResponse } from '../../../http/story_show.thunk';
import {
  handleHideStory,
  handleShowStory,
} from '../../features/detailed_story/story.utils';
import { DeleteStoryResponse } from '../../../http/story_delete.thunk';
import { getHandleStoryDelete } from '../../features/story_details/story-details.utils';
import { useTranslateStrings } from '../../hooks/useTranslateStrings';
import { useModal } from '../../hooks/use_modal/use_modal';
import {
  Button,
  BUTTON_VARIANT,
  BUTTON_COLOR_VARIANT,
} from '../../blocks/button';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../blocks/icon';
import { UseStory } from '../../features/detailed_story/use_story';
import { Link, LINK_VARIANTS } from '../../blocks/link';
import { StoryEmbedTextArea } from '../../features/detailed_story/story_embed/story_embed_text_area';
import { AuthWrapper } from '../../blocks/authwrapper/auth_wrapper.connect';
import { FEATURE_FLAGS } from '../../blocks/authwrapper/auth_wrapper.types';
import { useToast } from '../../hooks/use_toast/use_toast';
import { TOAST_TYPES } from '../../blocks/toast';

interface StoryManagePageProps extends LocalizeContextProps {
  currentPublisherId: string;
  defaultFormValues: DefaultFormVals;
  getStory: (story_uuid: string) => Promise<GetStoryResponse>;
  onDeleteStory: (story: Story | null) => Promise<DeleteStoryResponse>;
  onHideStory: (story_uuid: string) => Promise<StoryHideResponse>;
  onShowStory: (story_uuid: string) => Promise<StoryShowResponse>;
  story: Story | null;
}

export const StoryManagePageComponent = ({
  translate,
  currentPublisherId,
  defaultFormValues,
  getStory,
  onDeleteStory,
  onHideStory,
  onShowStory,
  story,
}: StoryManagePageProps): ReactElement => {
  const { story_uuid } = useParams();
  const navigate = useNavigate();
  const getTranslation = useTranslateStrings(translate);
  const [Modal, toggle] = useModal();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { showToast } = useToast();
  const [isNotify, setIsNotify] = useState(false);

  if (!story_uuid) {
    throw new Error('Story uuid required');
  }

  const [isUpdatingStoryVisibility, setIsUpdatingStoryVisibility] = useState(
    false,
  );

  const methods = useForm({ defaultValues: defaultFormValues });

  const handleStoryDelete = getHandleStoryDelete(
    story,
    onDeleteStory,
    setLoading,
    showToast,
    navigate,
    getTranslation(
      story?.title
        ? 'stories.delete.success'
        : 'stories.delete.successNoStoryTitle',
      { title: story?.title },
    ),
    getTranslation('stories.delete.failure'),
  );

  useEffect(() => {
    methods.reset(defaultFormValues);
  }, [defaultFormValues]);

  useEffect(() => {
    const status = searchParams.get('status');
    if (story?.story?.url && status && !isNotify) {
      switch (status) {
        case STORY_STATUS.PUBLISH:
          showToast({
            description: getTranslation(
              'stories.creator.snackbar.publishMessage',
            ),
            type: TOAST_TYPES.SUCCESS,
            message: getTranslation('stories.creator.snackbar.publishTitle'),
          });
          break;
        case STORY_STATUS.UNPUBLISH:
          showToast({
            message: `${translate(
              'stories.creator.snackbar.unpublishMessage',
            )}`,
            type: TOAST_TYPES.SUCCESS,
          });
          break;
        default:
          showToast({
            message: `${translate('stories.creator.snackbar.draftMessage')}`,
            type: TOAST_TYPES.INFO,
          });
          break;
      }
      setIsNotify(true);
    }
  }, [story, showToast, getTranslation, searchParams, translate]);

  const descriptionCopy = story?.title ? (
    <Translate
      id="stories.modal.storyDelete.descriptionQuestion"
      data={{ title: story.title }}
    />
  ) : (
    <Translate id="stories.modal.storyDelete.descriptionQuestionNoTitle" />
  );

  const isStoryByCurrPublisher = currentPublisherId === story?.publisher.slug;

  const isDraft = story?.status === 'draft';

  return (
    <Page className="story_manage_page">
      <PageHeader>
        <PageTitle />
        <div className="story_manage_page--menu_wrapper">
          {story && (
            <Link
              variant={LINK_VARIANTS.ICON}
              href={`${story.story?.url ?? ''}`}
              target="_blank"
              linkContent={
                <Icon iconKey={ICON_NAMES.EYE} size={ICON_SIZES.XL} />
              }
            />
          )}
          {isStoryByCurrPublisher && (
            <React.Fragment>
              <Link
                variant={LINK_VARIANTS.ICON}
                href={`${process.env.STORY_CREATOR_URL}/editor/${
                  story.uuid
                }?publisher-slug=${encodeURIComponent(story.publisher.slug)}`}
                linkContent={
                  <Icon iconKey={ICON_NAMES.PENCIL} size={ICON_SIZES.XL} />
                }
              />
              <Button variant={BUTTON_VARIANT.GHOST} onClick={toggle}>
                <Icon iconKey={ICON_NAMES.TRASH} size={ICON_SIZES.XL} />
              </Button>
            </React.Fragment>
          )}
        </div>
        {isStoryByCurrPublisher && (
          <Modal>
            <h2 className="delete_story_modal--title">
              <Translate id="stories.modal.storyDelete.title" />
            </h2>
            <div>
              <p className="delete_story_modal--description">
                {descriptionCopy}
              </p>
              <p className="delete_story_modal--description">
                <Translate id="stories.modal.storyDelete.descriptionOutcome" />
              </p>
            </div>
            <div className="delete_story_modal--cta_container">
              <Button
                variant={BUTTON_VARIANT.OUTLINE}
                onClick={toggle}
                color={BUTTON_COLOR_VARIANT.SECONDARY}
              >
                <Translate id="stories.modal.cancel" />
              </Button>
              <Button
                onClick={handleStoryDelete}
                loading={loading}
                variant={BUTTON_VARIANT.SOLID}
                color={BUTTON_COLOR_VARIANT.DESTRUCTIVE}
              >
                <Translate id="stories.modal.storyDelete.deleteStory" />
              </Button>
            </div>
          </Modal>
        )}
      </PageHeader>
      {isUpdatingStoryVisibility ? (
        <LoadingGif size="large" />
      ) : (
        <LoadStateData
          actionTypes={STORY_LOAD_TYPES}
          loading={<LoadingGif size="large" />}
          thunk={() => getStory(story_uuid)}
          failure={<div>error</div>}
        >
          <div className="story_manage_page__body">
            {!!story && (
              <div className="story_manage_page__body__col">
                <UseStory story={story} />
                <StoryDetails
                  story={story}
                  onHideStory={() =>
                    handleHideStory(
                      story,
                      setIsUpdatingStoryVisibility,
                      showToast,
                      onHideStory,
                      translate,
                    )
                  }
                  onShowStory={() =>
                    handleShowStory(
                      story,
                      setIsUpdatingStoryVisibility,
                      showToast,
                      onShowStory,
                      translate,
                    )
                  }
                />
                <TaxonomySection story={story} />
                <AuthWrapper featureFlags={FEATURE_FLAGS.SINGLE_EMBED}>
                  <StoryEmbedTextArea storyId={story.uuid} isDraft={isDraft} />
                </AuthWrapper>
              </div>
            )}
            <div className="story_manage_page__body__col--story_col">
              {!!story && (
                <React.Fragment>
                  <div className="story_manage_page__body__col__story_preview">
                    <div className="story_manage_page__body__col__story_preview__image">
                      <StoryPreviewComponent story={story} />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {!!story && <StoryStats orientation="vertical" story={story} />}
            </div>
          </div>
        </LoadStateData>
      )}
    </Page>
  );
};

export const StoryManagePage = withLocalize(StoryManagePageComponent);
