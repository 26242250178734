import { AxiosResponse } from 'axios';
import { urlBuilder } from './util/url_builder';
import { CRThunkDispatch, STORY_NETWORK_GATEWAY_URLS } from './http.types';
import axios from './axios_config';
import { selectCurrentNetworkId } from '../redux/user/user.selectors';
import { AppState } from '../types';
import {
  getNetworkPublishersListFailureAction,
  getNetworkPublishersListStartAction,
  getNetworkPublishersListSuccessAction,
} from '../redux/network/network.actions';
import { Publisher, PublisherResponse } from '../redux/user/user.types';
import { getErrorMessage } from '../utils';

interface GetNetworkPublishersListResponse {
  publishers: { [id: string]: Publisher };
}
interface GatewayResponse {
  publishers: PublisherResponse[];
}

export const getNetworkPublishersList = async (
  dispatch: CRThunkDispatch,
  getState: () => AppState,
): Promise<void> => {
  const state: AppState = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const url = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.NETWORK_PUBLISHERS_LIST,
    params: {
      networkId,
    },
  });
  dispatch(getNetworkPublishersListStartAction());
  try {
    const res: AxiosResponse<GatewayResponse> = await axios.get<
      GatewayResponse
    >(url, {
      params: { page_size: '200' },
    });
    const publisherObj: { [id: string]: Publisher } = (
      res.data?.publishers ?? []
    ).reduce(
      (
        publisherList: { [id: string]: Publisher },
        publisher: PublisherResponse,
      ) => {
        publisherList[publisher.publisher_id] = {
          publisherId: publisher.publisher_id,
          networkId: publisher.network_id,
          label: publisher.label,
        };
        return publisherList;
      },
      {},
    );
    dispatch(getNetworkPublishersListSuccessAction(publisherObj));
  } catch (err) {
    dispatch(getNetworkPublishersListFailureAction(err));
    throw new Error(`Failed to get feature flags: ${getErrorMessage(err)}`);
  }
};
