import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var JournalFlipped = function JournalFlipped(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'journalFlipped__icon',
      height: size,
      className: 'cr__icon',
      viewBox: '0 0 24 24',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg'
    },
    React.createElement('path', {
      d: 'M7.33333 17.3332H17.3333M7.33333 13.9998L17.3333 13.9998M12.3333 10.6665H17.3333M12.3333 7.33317H17.3333M4 4.49984L4 20.1665C4 20.2991 4.05268 20.4263 4.14645 20.5201C4.24021 20.6138 4.36739 20.6665 4.5 20.6665L20.1667 20.6665C20.2993 20.6665 20.4265 20.6138 20.5202 20.5201C20.614 20.4263 20.6667 20.2991 20.6667 20.1665L20.6667 4.49984C20.6667 4.36723 20.614 4.24005 20.5202 4.14628C20.4265 4.05252 20.2993 3.99984 20.1667 3.99984L4.5 3.99984C4.36739 3.99984 4.24021 4.05252 4.14645 4.14628C4.05268 4.24005 4 4.36723 4 4.49984Z',
      stroke: color,
      strokeWidth: '1.46',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    }),
    React.createElement('path', {
      d: 'M8 11.0003V7.66699H9.66667V11.0003H8Z',
      fill: color,
      stroke: color,
      strokeWidth: '1.46',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    })
  );
};

JournalFlipped.propTypes = propTypes;

export default JournalFlipped;