import React, { ReactElement, VoidFunctionComponent } from 'react';
import clsx from 'clsx';
import { Button, BUTTON_COLOR_VARIANT, BUTTON_VARIANT } from '../button';
import './empty_state.scss';
import { Text, TEXT_VARIANTS } from '../text';

export interface EmptyStateStaticProps {
  className?: string;
  graphic?: string;
  graphicAlt?: string;
  title: string;
  description: string;
  buttonText?: string;
  onClick?: () => void;
  actionVariant?: BUTTON_VARIANT;
  actionContent?: ReactElement;
  actionColorVariant?: BUTTON_COLOR_VARIANT;
}

export const EmptyState: VoidFunctionComponent<EmptyStateStaticProps> = ({
  className,
  graphic,
  graphicAlt,
  title,
  description,
  buttonText,
  onClick,
  actionVariant = BUTTON_VARIANT.SOLID,
  actionContent,
  actionColorVariant = BUTTON_COLOR_VARIANT.PRIMARY,
}) => {
  const hasActionHandler = !actionContent && Boolean(onClick);
  return (
    <div className={clsx('empty_state', className)}>
      <div className="empty_state__content">
        {graphic && (
          <img
            src={graphic}
            alt={graphicAlt}
            className="empty_state__graphic"
          />
        )}
        <Text variant={TEXT_VARIANTS.H3}>{title}</Text>
        <Text className="empty_state__desc">{description}</Text>
        {actionContent && (
          <div className="empty_state__action_content">{actionContent}</div>
        )}
        {hasActionHandler && (
          <Button
            variant={actionVariant}
            color={actionColorVariant}
            className="empty_state__cta"
            onClick={onClick}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};
