import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var People = function People(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'People__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1071.4 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M183,570.3h-74.8C52.5,570.3,0,543.5,0,481.6c0-45.2-1.7-197,69.2-197c11.7,0,69.8,47.4,145.1,47.4c25.7,0,50.2-4.5,74.2-12.8c-1.7,12.3-2.8,24.6-2.8,36.8c0,50.8,16.2,101,45.2,142.9C272.9,500.5,220.4,525.6,183,570.3z\t\t\t M214.3,284.6c-78.7,0-142.9-64.2-142.9-142.9S135.6-1.1,214.3-1.1S357.1,63,357.1,141.7S293,284.6,214.3,284.6z M779.6,998.9\t\t\tH291.9c-89.3,0-149-54.1-149-144.5c0-126.1,29.6-319.8,193.1-319.8c19,0,88.2,77.6,199.8,77.6s180.8-77.6,199.8-77.6\t\t\tc163.5,0,193.1,193.6,193.1,319.8C928.6,944.7,868.9,998.9,779.6,998.9z M535.7,570.3c-118.3,0-214.3-96-214.3-214.3\t\t\ts96-214.3,214.3-214.3S750,237.7,750,356S654,570.3,535.7,570.3z M857.1,284.6c-78.7,0-142.9-64.2-142.9-142.9\t\t\tS778.5-1.1,857.1-1.1S1000,63,1000,141.7S935.8,284.6,857.1,284.6z M963.2,570.3h-74.8c-37.4-44.6-89.8-69.8-147.9-71.4\t\t\tc29-41.9,45.2-92.1,45.2-142.9c0-12.3-1.1-24.6-2.8-36.8c24,8.4,48.5,12.8,74.2,12.8c75.3,0,133.4-47.4,145.1-47.4\t\tc70.9,0,69.2,151.8,69.2,197C1071.4,543.5,1019,570.3,963.2,570.3z' // eslint-disable-line max-len
    })
  );
};

People.propTypes = propTypes;

export default People;