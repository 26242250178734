import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from '../../../types';
import { CreateGalleryBlock } from './create_gallery_block.component';
import {
  setManualGalleryCreationStep,
  updateStaticGalleryCreationValue,
  addStoryCreateStaticGallery,
  removeStoryCreateStaticGallery,
  resetStaticGalleryState,
} from '../../../redux/galleries/galleries.actions';
import {
  selectCurrentGalleryCreationStep,
  selectStaticGallery,
  selectStaticGalleryHasSearchQuery,
  selectStaticGalleryLabelForCreate,
  selectStaticGalleryNumberOfStories,
  selectStaticGallerySelectedStories,
} from '../../../redux/galleries/galleries.selectors';
import { UnhydratedGallery } from '../../../redux/galleries/galleries.types';
import { Story } from '../story/story.types';
import { createStaticGallery } from '../../../http/gallery_create.thunks';
import { getGalleries } from '../../../http/galleries_list.thunks';

const mapStateToProps = (state: AppState) => ({
  activeStepIndex: Number(selectCurrentGalleryCreationStep(state)),
  selectedStories: selectStaticGallerySelectedStories(state) ?? [],
  label: selectStaticGalleryLabelForCreate(state),
  gallery: selectStaticGallery(state),
  numberOfStories: selectStaticGalleryNumberOfStories(state) ?? 0,
  hasSearchQuery: selectStaticGalleryHasSearchQuery(state) ?? false,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  setManualGalleryCreationStep: (step: number) =>
    dispatch(setManualGalleryCreationStep(step)),
  updateStaticGalleryCreationValue: (val: Partial<UnhydratedGallery>) =>
    dispatch(updateStaticGalleryCreationValue(val)),
  addStory: (story: Story) => dispatch(addStoryCreateStaticGallery(story)),
  removeStory: (uuid: string) => dispatch(removeStoryCreateStaticGallery(uuid)),
  resetGalleryState: () => dispatch(resetStaticGalleryState()),
  saveGallery: () => dispatch(createStaticGallery()),
  updateGalleryList: () => dispatch(getGalleries),
});

export const CreateGalleryBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateGalleryBlock);
