import { VoidFunctionComponent } from 'react';

export enum ICON_SIZES {
  XS = '10px',
  SMALL = '14px',
  MED = '16px',
  LARGE = '20px',
  XL = '24px',
  XXL = '30px',
}

export interface IconProps {
  size?: string;
  color?: string;
  className?: string;
  ariaHidden?: boolean;
  role?: string;
}

export enum ICON_NAMES {
  ADD_TO_GRID = 'AddToGrid',
  ARROW_CIRCLE = 'ArrowCircle',
  ARROW_CLOCKWISE = 'ArrowClockwise',
  ARROW_DOWN = 'ArrowDown',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_UP = 'ArrowUp',
  ASQUARE = 'ASquare',
  ASQUARE_FILLED = 'ASquareFilled',
  CAMERA = 'Camera',
  CHECKMARK = 'Checkmark',
  CHECKMARK_CIRCLE = 'CheckmarkCircle',
  CHECKMARK_CIRCLE_FILLED = 'CheckmarkCircleFilled',
  CHEVRON_DOWN = 'ChevronDown',
  CHEVRON_LIGHT = 'ChevronLeft',
  CHEVRON_RIGHT = 'ChevronRight',
  CIRCLE = 'Circle',
  CLOSE = 'Close',
  CLOSE_CIRCLE = 'CloseCircle',
  CROP = 'Crop',
  CURSOR_CLICK = 'CursorClick',
  ELLIPSIS = 'Ellipsis',
  EXCLAMATION_CIRCLE = 'ExclamationCircle',
  EXCLAMATION_CIRCLE_FILLED = 'ExclamationCircleFilled',
  EXCLAMATION_TRIANGLE_FILLED = 'ExclamationTriangleFilled',
  EXTERNAL_LINK = 'ExternalLink',
  EYE = 'Eye',
  FILTERS = 'Filters',
  FLAG = 'Flag',
  HAND_CURSOR = 'HandCursor',
  HOUSE = 'House',
  INFO_CIRCLE = 'InfoCircle',
  INFO_CIRCLE_FILLED = 'InfoCircleFilled',
  LIGHT_BULB_CIRCLE = 'LightBulbCircle',
  LINK = 'Link',
  LOCALHOOD_LOGO_BLACK = 'LocalhoodLogoBlack',
  LOCATION = 'Location',
  LOCATION_FILLED = 'LocationFilled',
  LOCK = 'Lock',
  PAUSE_FILLED = 'PauseFilled',
  PENCIL = 'Pencil',
  PERSON = 'Person',
  PHOTO = 'Photo',
  PLUS = 'Plus',
  PLUS_CIRCLE = 'PlusCircle',
  PLUS_CIRCLE_FILLED = 'PlusCircleFilled',
  QR_CODE_SCAN = 'QrCodeScan',
  QUESTION_MARK_CIRCLE = 'QuestionMarkCircle',
  REFRESH = 'Refresh',
  SEARCH = 'Search',
  SHARE_FILLED = 'ShareFilled',
  SLASH_CIRCLE = 'SlashCircle',
  SOUNDOFF = 'SoundOff',
  SPEAKER = 'Speaker',
  SQUARE_STACK = 'SquareStack',
  STAR = 'Star',
  TEXT_ALIGN_CENTER = 'TextAlignCenter',
  TEXT_ALIGN_JUSTIFY = 'TextAlignJustify',
  TEXT_ALIGN_LEFT = 'TextAlignLeft',
  TEXT_ALIGN_RIGHT = 'TextAlignRight',
  TEXT_FORMAT = 'TextFormat',
  TRASH = 'Trash',
  WARNING = 'Warning',
}

export type ICON_TYPE = Record<ICON_NAMES, VoidFunctionComponent<IconProps>>;
