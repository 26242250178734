import classNames from 'classnames';
import React from 'react';
import { AVATAR_SIZES } from '../../blocks/avatar/avatar.types';
import { Story as StoryComponent } from '../story/story.component';
import { Story } from '../story/story.types';
import './story_preview.scss';

interface StoryPreviewProps {
  story: Story;
  className?: string;
}

export const StoryPreviewComponent = ({
  story,
  className = '',
}: StoryPreviewProps) => {
  const { title } = story;
  const { logoSrc, name } = story.publisher;
  return (
    <div className={classNames('story_preview', className)}>
      <StoryComponent
        publisher={{
          logo: logoSrc,
          name,
          size: AVATAR_SIZES.MEDIUM,
        }}
        title={title}
        storySrc={story.image.url}
        includeVisibility={false}
      />
    </div>
  );
};
