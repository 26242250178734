import React from 'react';
import { ICON_NAMES, iconKeyMap, IconProps as Icons } from '.';

interface IconProps extends Icons {
  iconKey: ICON_NAMES;
}

export const Icon = ({
  iconKey,
  color,
  size,
  className,
  ariaHidden,
  role,
  ...otherProps
}: IconProps) => {
  const IconType = iconKeyMap[iconKey];

  return (
    <div className={className}>
      <IconType
        size={size}
        color={color}
        ariaHidden={ariaHidden}
        role={role}
        {...otherProps}
      />
    </div>
  );
};
