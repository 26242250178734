export interface LocationState {
  data: Location[];
  ids: string[];
  types: LOCATION_TYPE[];
}

export enum LOCATION_TYPE {
  CITY = 'city',
  STATE = 'state',
  COUNTRY = 'country',
  REGION = 'region',
  SUBREGION = 'subregion',
  COUNTY = 'county',
  TOWNSHIP = 'township',
  WARD = 'ward',
  DISTRICT = 'district',
}
export interface Location {
  id: string;
  label: string;
  type: LOCATION_TYPE;
  city?: string;
  county?: string;
  township?: string;
  ward?: string;
  district?: string;
  subregion?: string;
  region?: string;
  state: string;
  country: string;
  created_at: string;
  updated_at: string;
}
export enum LOCATIONS_ASYNC_TYPES {
  START = 'LOCATION_TAG/START',
  SUCCESS = 'LOCATION_TAG/SUCCESS',
  FAILURE = 'LOCATION_TAG/FAILURE',
}
export enum LOCATIONS_TYPE_TYPES {
  START = 'LOCATIONS_TYPE/START',
  SUCCESS = 'LOCATIONS_TYPE/SUCCESS',
  FAILURE = 'LOCATIONS_TYPE/FAILURE',
}

export interface GetLocationTypesStart {
  type: LOCATIONS_TYPE_TYPES.START;
}

export interface GetLocationTypesSuccess {
  type: LOCATIONS_TYPE_TYPES.SUCCESS;
  payload: LOCATION_TYPE[];
}

export interface GetLocationTypesFailure {
  type: LOCATIONS_TYPE_TYPES.FAILURE;
  payload: string;
}

export interface GetLocationTagsStart {
  type: LOCATIONS_ASYNC_TYPES.START;
}
export interface GetLocationTagsSuccess {
  type: LOCATIONS_ASYNC_TYPES.SUCCESS;
  payload: Location[];
}
export interface GetLocationTagsFailure {
  type: LOCATIONS_ASYNC_TYPES.FAILURE;
  payload: string;
}
export type LocationActions =
  | GetLocationTagsStart
  | GetLocationTagsSuccess
  | GetLocationTagsFailure
  | GetLocationTypesStart
  | GetLocationTypesSuccess
  | GetLocationTypesFailure;
