import React from 'react';
import PropTypes from 'prop-types';

var propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

var Star = function Star(_ref) {
  var size = _ref.size,
      color = _ref.color;

  return React.createElement(
    'svg',
    {
      id: 'Star__icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 1048.5 1000',
      height: size,
      fill: color,
      'aria-hidden': true,
      className: 'cr__icon'
    },
    React.createElement('path', {
      d: 'M1032.1,417.8L803.4,640.8l54.2,315.1c0.6,4.4,0.6,8.2,0.6,12.6c0,16.4-7.6,31.5-25.8,31.5c-8.8,0-17.6-3.2-25.2-7.6 L524.3,843.7L241.3,992.4c-8.2,4.4-16.4,7.6-25.2,7.6c-18.3,0-26.5-15.1-26.5-31.5c0-4.4,0.6-8.2,1.3-12.6l54.2-315.1L15.8,417.8 C8.2,409.6,0,398.9,0,387.5c0-18.9,19.5-26.5,35.3-29l316.3-46L493.4,25.8c5.7-12,16.4-25.8,30.9-25.8c14.5,0,25.2,13.9,30.9,25.8 l141.8,286.7l316.3,46c15.1,2.5,35.3,10.1,35.3,29C1048.5,398.9,1040.3,409.6,1032.1,417.8z' // eslint-disable-line max-len
    })
  );
};

Star.propTypes = propTypes;

export default Star;