import React from 'react';
import './horizontal_story_stat.scss';
import { Text, TEXT_VARIANTS } from '../../../blocks/text';
import { Tooltip } from '../../../blocks/tooltip/tool_tip.component';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../../blocks/icon';
import { TOOLTIP_PLACEMENT } from '../../../blocks/tooltip/tool_tip.types';
import { Button, BUTTON_VARIANT } from '../../../blocks/button';

interface HorizontalStatContentProps {
  title: string;
  data: string;
  iconType: ICON_NAMES;
  tooltipContent?: string[];
  onOpen?: () => void;
  onClose?: () => void;
}

export const HorizontalStatContent = ({
  data,
  title,
  tooltipContent,
  onOpen,
  onClose,
  iconType,
}: HorizontalStatContentProps) => {
  const openHandlerProps = {
    onOpen,
    onClose,
  };
  return (
    <Tooltip
      {...openHandlerProps}
      id={`${title.toLowerCase()}_tooltip`}
      placement={TOOLTIP_PLACEMENT.TOP}
      content={
        <div className="story_stats__tooltip">
          <Text variant={TEXT_VARIANTS.B12}>{title}</Text>
          {tooltipContent?.map((content) => (
            <Text variant={TEXT_VARIANTS.B2}>{content}</Text>
          ))}
        </div>
      }
    >
      <Button variant={BUTTON_VARIANT.GHOST}>
        <Icon
          className="story_stats__tooltip_icon"
          iconKey={iconType}
          size={ICON_SIZES.SMALL}
        />
        <Text variant={TEXT_VARIANTS.C12}>{data}</Text>
      </Button>
    </Tooltip>
  );
};
