import React from 'react';
import './toggle_button.scss';
import { Icon } from '@crowdriff/flock/lib/Icon';
import { LocalizedElement } from 'react-localize-redux';
import { UseFormRegister, FieldValues } from 'react-hook-form';

interface ToggleButtonProps {
  register: UseFormRegister<FieldValues>;
  value?: boolean;
  id: string;
  labelKey: LocalizedElement;
  iconType: string;
  handleChange: () => void;
  label: string;
  name: string;
}
export const ToggleButton = ({
  label,
  labelKey,
  id,
  register,
  value = false,
  iconType,
  handleChange,
  name,
}: ToggleButtonProps) => {
  return (
    <div className="toggle_button__container">
      <label htmlFor={id} className="toggle_button__label" key={label}>
        <input
          {...register(name)}
          type="checkbox"
          id={id}
          value={label}
          defaultChecked={value}
          onChange={handleChange}
        />
        <div className="toggle_button">
          <Icon size={12} type={iconType} className="toggle_button__icon" />
          <span>{labelKey}</span>
        </div>
      </label>
    </div>
  );
};

ToggleButton.displayName = 'ToggleButton';
