import React, { ReactElement } from 'react';
import { Rifficon } from '@crowdriff/flock/lib/Rifficon';
import { RIFFICON_TYPES } from '@crowdriff/flock/types/Rifficon';
import { Translate } from 'react-localize-redux';
import './error_state.scss';

export interface ErrorStateProps {
  rifficonType: RIFFICON_TYPES;
  size: number;
  errorTitle: React.ReactNode;
  errorMessage?: string;
}

export const ErrorState = ({
  rifficonType,
  size,
  errorTitle,
  errorMessage,
}: ErrorStateProps): ReactElement => {
  return (
    <React.Fragment>
      <div className="error_state--error">
        <Rifficon
          iconStyle="fill"
          size={size}
          type={rifficonType}
          fillColor="#FFCCAA"
        />
        <div className="error_state--error_message">
          <h1>{errorTitle}</h1>

          {errorMessage && (
            <React.Fragment>
              <p>{errorMessage}</p>
              <p>
                <Translate id="galleries.errors.refresh" />
              </p>
            </React.Fragment>
          )}
        </div>
        <div className="error_state--ellipsis" />
      </div>
    </React.Fragment>
  );
};
