import { ThunkDispatch } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { AppState, AppActions } from '../types';
import { STORY_NETWORK_GATEWAY_URLS } from './http.types';
import { urlBuilder } from './util/url_builder';
import {
  updatePublisherStartAction,
  updatePublisherSuccessAction,
  updatePublisherFailureAction,
  updateLogoAndFaviconSuccessAction,
  updateLogoAndFaviconStartAction,
  updateLogoAndFaviconFailureAction,
} from '../redux/user/user.actions';
import { getAxiosRequestPromise, getErrorMessage } from '../utils';
import {
  selectCurrentNetworkId,
  selectCurrentPublisherId,
} from '../redux/user/user.selectors';
import {
  ACCOUNT_FIELD_MASK,
  PublisherUpdateDetails,
} from '../react/pages/account_settings/account_settings.types';
import { Publisher } from '../redux/user/user.types';

interface UpdatePublisherResponse {
  publisher: Publisher;
}

export interface UploadFilesResponse {
  logo_src: string;
  favicon_src: string;
}

export const AccountUpdateErrorMessages = {
  UploadFailed: 'Publisher files update failed',
  UpdateFailed: 'Publisher information update failed',
};

export const updateAccountSettings = (
  data: PublisherUpdateDetails,
  fieldMask: ACCOUNT_FIELD_MASK[],
) => async (
  dispatch: ThunkDispatch<AppState, {}, AppActions>,
  getState: () => AppState,
): Promise<void> => {
  const state: AppState = getState();
  const networkId: string = selectCurrentNetworkId(state);
  const publisherId: string = selectCurrentPublisherId(state);

  const accountUpdatePromises = [];

  const publisherUpdateUrl = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.ACCOUNT_SETTINGS,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
    },
  });

  const fileUploadUrl = urlBuilder({
    routeString: STORY_NETWORK_GATEWAY_URLS.UPLOAD_LOGO_FAVICON,
    params: {
      networkId,
      publisherId: encodeURIComponent(publisherId),
    },
  });

  // publisher info updated
  if (data.gallery_domain || data.name) {
    dispatch(updatePublisherStartAction(''));

    accountUpdatePromises.push(
      getAxiosRequestPromise<UpdatePublisherResponse>({
        url: publisherUpdateUrl,
        method: 'PATCH',
        data: {
          publisher: data,
          field_mask: fieldMask.join(','),
        },
        onSuccess: (response: AxiosResponse<UpdatePublisherResponse>) => {
          dispatch(updatePublisherSuccessAction(response.data.publisher));
          return response.data.publisher;
        },
        onReject: (e) => {
          dispatch(updatePublisherFailureAction(e));
          throw new Error(AccountUpdateErrorMessages.UpdateFailed);
        },
      }),
    );
  }

  // file(s) uploaded
  if (data.avatar?.length || data.favicon?.length) {
    dispatch(updateLogoAndFaviconStartAction(''));

    const formData = new FormData();

    if (data.avatar?.length) {
      formData.append('logo', data.avatar[0], data.avatar[0].name);
    }

    if (data.favicon?.length) {
      formData.append('favicon', data.favicon[0], data.favicon[0].name);
    }

    accountUpdatePromises.push(
      getAxiosRequestPromise<UploadFilesResponse>({
        url: fileUploadUrl,
        method: 'POST',
        data: formData,
        onSuccess: (response: AxiosResponse<UploadFilesResponse>) => {
          dispatch(updateLogoAndFaviconSuccessAction(response.data));
          return response.data;
        },
        onReject: (e) => {
          dispatch(updateLogoAndFaviconFailureAction(e));
          throw new Error(AccountUpdateErrorMessages.UploadFailed);
        },
      }),
    );
  }

  const isRejected = (
    input: PromiseSettledResult<unknown>,
  ): input is PromiseRejectedResult => input.status === 'rejected';

  try {
    const responses = await Promise.allSettled(accountUpdatePromises);
    const errors = responses.filter(isRejected).map((r) => r.reason?.message);
    if (errors.length > 0) {
      throw new Error(errors.join(','));
    }
  } catch (e) {
    throw new Error(getErrorMessage(e, 'Failed to update account details'));
  }
};
