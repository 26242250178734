import React from 'react';
import { Icon, ICON_NAMES, ICON_SIZES } from '../../../blocks/icon';
import { Text, TEXT_VARIANTS } from '../../../blocks/text';
import './story_header_preview.scss';

export interface StoryHeaderPreviewProps {
  avatarUrl: string;
  displayName?: string;
}

export const StoryHeaderPreview = ({
  avatarUrl,
  displayName,
}: StoryHeaderPreviewProps) => {
  return (
    <div className="story_header_preview__wrapper">
      <img
        src={avatarUrl}
        alt={`${displayName ?? 'default'} logo`}
        className="story_header_preview__logo"
      />
      <Text variant={TEXT_VARIANTS.H4} className="story_header_preview__name">
        {displayName}
      </Text>
      <div className="story_header_preview__icons">
        <Icon
          iconKey={ICON_NAMES.PAUSE_FILLED}
          size={ICON_SIZES.XL}
          className="story_header_preview__icon"
          color="#E4E4E6"
        />
        <Icon
          iconKey={ICON_NAMES.SHARE_FILLED}
          size={ICON_SIZES.XL}
          className="story_header_preview__icon"
          color="#E4E4E6"
        />
      </div>
    </div>
  );
};
