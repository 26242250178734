import { ActionCreator } from 'redux';
import {
  GalleriesSearchAction,
  GALLERIES_SEARCH_TYPES,
  GALLERIES_LOAD_TYPES,
  GalleriesTriggerHydrateAction,
} from './galleries_list.types';

export const handleSearchChange = (val: string): GalleriesSearchAction => ({
  type: GALLERIES_SEARCH_TYPES.SEARCH,
  payload: {
    value: val,
  },
});
export const clearSearchResults = () => ({
  type: GALLERIES_SEARCH_TYPES.CLEAR_SEARCH,
  payload: null,
});

export const triggerHydrateAction: ActionCreator<GalleriesTriggerHydrateAction> = () => ({
  type: GALLERIES_LOAD_TYPES.TRIGGER_HYDRATE,
  payload: {},
});
